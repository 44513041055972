import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from '../../../components/modal/BasicModal';
import { fecharModalAdapter } from '../redux/OltActions';

const ModalWarning = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);

    const checkedNovoAdapter = localStorage.getItem('checkedNovoAdapter') === "false" ? false : true;
    const firstLogin = localStorage.getItem('firstLogin') === "false" ? false : true;

    return (
        <BasicModal
            close={() => [
                localStorage.setItem('checkedNovoAdapter', JSON.stringify(true)),
                localStorage.setItem('ask', "23/04/2024"),
                dispatch(fecharModalAdapter())
            ]}
            containerModal={{
                title: defaultText.modalWarningTitle,
                size: 'medium',
                closeText: defaultText.btn.cancelar,
                content:
                    <>
                        <div className='createLocationText' style={{ textAlign: 'justify' }}>
                            {checkedNovoAdapter === false && firstLogin === false || (checkedNovoAdapter === true && localStorage.getItem('ask') != "23/04/2024") ?
                                <>
                                    <p>
                                        {defaultText.mensagem.warningMessage}
                                    </p>
                                </>
                                :
                                <p>
                                    {defaultText.mensagem.warningMessage}
                                </p>}
                        </div>
                    </>
            }}
        />
    );
};

export default ModalWarning;