import React from 'react';
import '../TitleContainer/titleContainer.css';

const TitleContainer = props => {

    return (
        <div className='titleContainer'>
            <h1>{props.label}</h1>
        </div>
    );
};

export default TitleContainer;