import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

//import { listarOlts, atualizarCampoPesquisa, abrirModalCriarOlt, limparCampoPesquisa } from './redux/OltActions'

import InputInbox from '../../components/form/inputInBox/InputInbox';
import { IcoMais, IcoSearch } from '../../components/icones';

import Button from '../../components/button/Button';

import { abrirModalNovaLoc, atualizarCampoPesquisa, limparCampoPesquisa, listarLocais } from './redux/LocaisActions';
import Row from '../../components/row/row';
import { getPagePermissions } from '../../utils/utils';

export default () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);
    const campoPesquisa = useSelector(state => state.locaisState.campoPesquisa);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    return (
        <div>
            <Row style={{ justifyContent: "space-between" }}>
                <div className="col-md-6 div-filters-button">
                    <div className='campo-pesquisa'>
                        <InputInbox
                            name='pesquisar'
                            label={defaltText.pesquisar.text}
                            value={campoPesquisa}
                            //VERIFICAR
                            changeInput={e => dispatch(atualizarCampoPesquisa(e))}
                            search={{
                                //action: e => console.log("Pesquisa!"),
                                action: () => dispatch(limparCampoPesquisa()),
                                text: defaltText.btn.fechar
                            }}
                        />

                        <Button
                            id="btnPesquisar"
                            titulo={defaltText.pesquisar.text}
                            //Verificar
                            /* action={() => console.log("Clicou em Pesquisar!")} */
                            action={() => dispatch(listarLocais({ pesquisa: campoPesquisa }))}
                            children={<IcoSearch title={defaltText.pesquisar.text} />}
                            color='secondary'
                            type='btn circle'
                        />
                    </div>
                </div>
                <div className="col-md-2 div-new-button">
                    <Button
                        hasAccess={getPagePermissions(permissions, "locais", "create")}
                        id="btnAdicionar"
                        titulo={defaltText.pesquisar.novo}
                        action={() => dispatch(abrirModalNovaLoc())}
                        texto={<IcoMais title={defaltText.pesquisar.novo} />}
                        color='success'
                        type='btn circle'
                    />
                </div>
            </Row>
        </div>
    );
};
