import React from 'react';
import Row from '../row/row';
import './button.css';

const Button = ({
    hasAccess = true,
    tooltip = true,
    id = "",
    children,
    action,
    type = 'btn',
    color = '',
    variant = 'normal',
    size = 'medium',
    titulo = '',
    texto = '',
    disabled = false,
    value = "",
    onMouseOver,
    onMouseOut
}) => {
    return (
        hasAccess ? (
            <button
                id={id}
                className={`${type} ${color} ${variant} ${size} `}
                onClick={action}
                title={tooltip ? (titulo ? titulo : children) : null}
                disabled={disabled}
                value={value}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            >
                {texto === '' ?
                    <div className="btn-content">
                        {children}
                    </div> :
                    <div className="btn-content">
                        <p>{texto}</p>
                        {children}
                    </div>
                }



                {/*                     <div className=''>
                    {children}
                    </div> */}

            </button>
        ) : null
    );

};
export default Button;
