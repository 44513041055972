import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './sidebarMenuNovo.css';
import IconeSideBar from '../iconeSideBar/IconeSideBar';
import { sendSyncRequest } from '../../../modulos/olt/redux/OltActions';
const SubMenu = props => {
    return props.map(item => {
        return (
            <li key={item.item}>
                <Link to={`/${item.item}`}>
                    {item.icone}
                    {item.text}
                </Link>
            </li>
        );
    });
};

const SideBarMenuNovo = props => {
    const { MenuJson, action, open } = props;
    const [menuNav, setMenuNav] = useState({});
    const [menuIndex, setMenuIndex] = useState();
    const [syncButtonStatus, setSyncButtonStatus] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../nls/${locales}.json`);
    const isActive = address => address.includes(location.pathname);

    const changeMenu = (item, index) => {
        setMenuNav({ ...menuNav, [item]: !menuNav[item], [index]: index });
    };
    const user = useSelector(state => state.authentication.user);

    useEffect(() => {
        MenuJson.map((item, index) => {
            if (isActive(`/${item.item}`)) {
                setMenuIndex(index);
            }
        });
    });

    const handleSyncButtonStatus = () => {
        if (!syncButtonStatus) {
            setSyncButtonStatus(true);
            dispatch(sendSyncRequest());
            setTimeout(() => {
                setSyncButtonStatus(false);
            }, 120000);
        }
    };

    return (
        <ul className={(open ? 'opened' : 'closed') + ' box-menu-layout'} >
            <div className='menu-scroll-line' >
                <div className='borda-menu'>
                    <div className='scroll-line'
                        style={{
                            transform: `translateY(${menuIndex * (143) + 20}%)`,
                            height: `calc(100%/${MenuJson.lenght})`,
                            opacity: `${(open ? "0" : "1")}`
                        }}></div>
                </div>
                {MenuJson.map((item, index) => {
                    if (item.subMenu) {
                        return (
                            <li key={item.item} className={menuNav[item.item] ? 'sub-nav active' : 'sub-nav'}>
                                <button type='button' onClick={() => changeMenu(item.item, index)}>
                                    {item.icone}
                                    {item.text}
                                </button>
                                <ul>{SubMenu(item.subMenu)}</ul>
                            </li>
                        );
                    }
                    return (

                        <li key={item.item} className={(menuIndex === index && 'active ')/*  + (isActive(`/${item.item}`) ? `active ${item.itemStatus}` : `${item.itemStatus}`) */ + (open ? " opened" : " closed")}>
                            <Link onClick={() => action} to={`/${item.item}`}>
                                <div className={(!open && 'closed-item') + " menu-item"} >
                                    <IconeSideBar title={item.text} icoType={item.item} open={open} active={isActive(`/${item.item}`)} />

                                    <p className={open ? 'opened' : 'closed'}>
                                        {item.text}
                                    </p>
                                </div>

                            </Link>
                        </li>
                    );
                })}


            </div>
            {/* <div style={{ marginTop: "70px", opacity: !syncButtonStatus ? 1 : 0.5 }}>
                <div style={{ width: `${open ? "95%" : "85%"}`, backgroundColor: "#FFF", height: "1px" }}></div>
                <li key="refresh-sync" className={(open ? " opened" : " closed")}>
                    <Link onClick={() => handleSyncButtonStatus()} style={{ cursor: `${syncButtonStatus ? "default" : "pointer"}` }}>
                        <div className={(!open && 'closed-item') + " menu-item"} >
                            <IconeSideBar title={"Sincronizar"} icoType={"refresh"} open={open} />
                            <p className={open ? 'opened' : 'closed'}>
                                {defaultText.sincronizar}
                            </p>
                        </div>

                    </Link>
                </li>
            </div> */}
        </ul >
    );
};

export default SideBarMenuNovo;
