import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import ConteudoAddLocModal from './conteudoAdicionarLoc';

import { criarLoc, limparCamposNovaLoc, fecharModalNovaLoc } from '../redux/LocaisActions';




const AdicionarLoc = () => {

    const dispatch = useDispatch();

    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const statusModal = useSelector((state) => state.locaisState.statusModal);
    const user = useSelector(state => state.authentication.user);
    const novaLoc = useSelector(state => state.locaisState.novaLoc);

    const handleCriarLoc = () => {

        dispatch(criarLoc({ email: user.email, aplicacao: { ...novaLoc } }));

        if (novaLoc.nomeLocal.trim()) {
            dispatch(fecharModalNovaLoc());
        }

        dispatch(limparCamposNovaLoc());
    };

    const handleCancelarNovaLoc = () => {

        dispatch(fecharModalNovaLoc());
        dispatch(limparCamposNovaLoc());

    };



    const title = defaltText.localizacao.titulo;
    return (
        <BasicModal
            open={statusModal}
            close={() => handleCancelarNovaLoc()}
            containerModal={{
                title,
                size: 'medium',
                content: <ConteudoAddLocModal />,
                closeText: defaltText.btn.fechar,
                action: {
                    btnId: "btnCriar",
                    acao: () => handleCriarLoc(),
                    //acao: () => console.log(olt),
                    texto: defaltText.localizacao.criar,
                    class: 'success'
                }
            }}
        />
    );



};


export default AdicionarLoc;