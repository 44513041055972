import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { validarCampoAction } from '../../../validation/validationActions';

import { criarLoc, limparCamposNovaLoc, atualizarCamposNovaLoc, fecharModalNovaLoc } from '../redux/LocaisActions';

import InputInbox from '../../../components/form/inputInBox/InputInbox';

import { TextareaInbox } from '../../../components/form//textareaInBox/TextareaInbox';

import Button from '../../../components/button/Button';

import Row from '../../../components/row/row';

import '../../usuario/usuario.css';

const ConteudoAddLocModal = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);

    const locaisState = useSelector(state => state.locaisState);

    const user = useSelector(state => state.authentication.user);

    const mensagemErro = useSelector(state => state.validacaoState);

    const defaltText = require(`../nls/${locales}.json`);

    const handleCriarLoc = () => {

        dispatch(criarLoc({ email: user.email, aplicacao: { ...locaisState.novaLoc } }));

        if (locaisState.novaLoc.nomeLocal) {
            dispatch(fecharModalNovaLoc());
        }

        dispatch(limparCamposNovaLoc());
    };
    const handleCancelarNovaLoc = () => {

        dispatch(fecharModalNovaLoc());
        dispatch(limparCamposNovaLoc());

    };
    /*   useEffect(() => {
      dispatch(listarLocalizacoes());
    }, [dispatch]); */
    return (
        <div>
            <Row>
                <div className='col-md-12 scroll'>
                    <InputInbox
                        name='nomeLocal'
                        label={defaltText.localizacao.nome}
                        value={locaisState.novaLoc.nomeLocal}
                        changeInput={e => dispatch(atualizarCamposNovaLoc(e))}
                        //VERIFICAR
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.nomeLocal ? defaltText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '30'
                        }}
                    />

                    <TextareaInbox
                        name='descricaoLocal'
                        label={defaltText.localizacao.descricao}
                        value={locaisState.novaLoc.descricaoLocal}
                        changeInput={e => dispatch(atualizarCamposNovaLoc(e))}
                        length={{
                            max: '50'
                        }}
                    />
                </div>


                {/*           <div className='filho1'>
            <Button
              value='campoCancelarNovaLoc'
              titulo={defaltText.localizacao.cancelar}
              action={() => handleCancelarNovaLoc()}
              children={defaltText.localizacao.cancelar}
              color='secondary'
              type='btn'
            />
          </div>
          <div className='filho2'>
            <Button
              value='campoCriar'
              titulo={defaltText.localizacao.criar}
              action={() => handleCriarLoc()}
              children={defaltText.localizacao.criar}
              color='primary'
              type='btn'
            />
          </div> */}

            </Row>
        </div>
    );
};

export default ConteudoAddLocModal;
