import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    atualizarConteudoServicoModal,
    atualizarConteudoServicoExistenteModal,
    listarServicosSemAssinante,
} from '../redux/ServicoActions';
import { SelectInBox } from '../../../components/form';
import CheckboxSwitch from '../../../components/form/checkboxSwitch/CheckboxSwitch';
import Row from '../../../components/row/row';

const ConteudoVincularServicoModal = () => {
    const dispatch = useDispatch();

    const quantidadePorPagina = 3;
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const servico = useSelector((state) => state.servicosState.servico);
    const servicos = useSelector((state) => state.servicosState.servicosSemAssinante.servicos);
    const paginacaoServicosSemAssinantes = useSelector(state => state.servicosState.servicosSemAssinante.paginacao);

    const assinante = useSelector((state) => state.assinantesState.assinante);

    useEffect(() => {
        dispatch(listarServicosSemAssinante({ quantidadePorPagina }));
    }, [dispatch]);
    console.log(servicos);
    return (
        <section className="box-criar-editar-servicos">
            {assinante && assinante.id ? (
                <>
                    <Row key="assinante">
                        <div className="col-md-12">
                            <label htmlFor="*">{defaltText.inputs.assinante}</label>
                            <p>
                                {assinante.nContrato} - {assinante.nome}
                            </p>
                        </div>
                    </Row>
                </>
            ) : null}
            <Row>
                <div className="col-md-12">
                    <CheckboxSwitch
                        id={`servicoStatus-${servico.id}`}
                        name="servicoStatus"
                        label={`${defaltText.inputs.status} ${defaltText.inputs[servico.servicoStatus ? 'ativo' : 'inativo']
                            }`}
                        checked={servico.servicoStatus}
                        changeInput={(e) => dispatch(atualizarConteudoServicoModal(e))}
                    />
                </div>
            </Row>
            <Row>
                <div className="col-md-12">
                    <SelectInBox
                        id="servico"
                        label={defaltText.inputs.servico}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            text: defaltText.select.selecionado.nenhum,
                        }}
                        selectFilter={{
                            action: (e) => dispatch(listarServicosSemAssinante({ pesquisa: e, quantidadePorPagina })),
                            actionLimpar: () => dispatch(listarServicosSemAssinante({ quantidadePorPagina })),
                            texto: defaltText.select.pesquisar.texto,
                            limpar: defaltText.select.pesquisar.limpar,
                        }}
                        select={{
                            action: (e) => dispatch(atualizarConteudoServicoExistenteModal(e, assinante)),
                            options: servicos,
                            selected: servico
                        }}
                        moreOptions={{
                            page: paginacaoServicosSemAssinantes,
                            action: e => dispatch(listarServicosSemAssinante({ pagina: paginacaoServicosSemAssinantes.pageNumber + 1, pesquisa: e, quantidadePorPagina })),
                            texto: defaltText.btn.maisItens
                        }}
                    />
                </div>
            </Row>
        </section>
    );
};

export default ConteudoVincularServicoModal;
