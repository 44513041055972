import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BasicModal from '../../../../components/modal/BasicModal';
import { fecharSegundaModal, reiniciarOlt } from '../../redux/OltActions';

const ModalConfirmarReiniciar = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../../nls/${locales}.json`);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const olt = useSelector(state => state.oltsState.olt);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharSegundaModal(statusModal))}
            containerModal={{
                title: defaultText.tituloModalReiniciar,
                size: 'medium',
                content: (
                    <>
                        <p>{defaultText.mensagem.reiniciarConfimacao}</p>
                        {/* <p style={{ fontWeight: "bold" }}>{olt.nome}</p> */}
                    </>
                ),
                closeText: defaultText.btn.fechar,
                removeConfirmAction: {
                    acao: () => dispatch(reiniciarOlt(olt)),
                    texto: defaultText.btn.reiniciar,
                    class: "success"
                }
            }}
        />
    );
};

export default ModalConfirmarReiniciar;