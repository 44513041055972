import React from 'react';

export function IcoInfo(props) {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            style={props.style ? `${props.style}font-size:1.5em` : { fontSize: '1.5em' }}
        >
            <title>{props.title ? props.title : null}</title>
            <g transform="translate(-3.375 -3.375)">
                <path d="M16.552,12.008a1.457,1.457,0,0,1,2.9,0,1.456,1.456,0,0,1-2.9,0Zm.1,2.331h2.686v9.41H16.65Z" transform="translate(-2.125 -1.567)"  fill="#fff" />
                <path d="M15.875,5.058a10.813,10.813,0,1,1-7.65,3.167,10.746,10.746,0,0,1,7.65-3.167m0-1.683a12.5,12.5,0,1,0,12.5,12.5,12.5,12.5,0,0,0-12.5-12.5Z"
                    transform="translate(0)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}
