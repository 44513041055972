import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IcoEditar, IcoRemoveLine, IcoGlobal } from '../../components/icones';

import List from '../../components/list/List';
import Paginate from '../../components/pagination/Pagination';
import Row from '../../components/row/row';
import CheckBox from '../../components/form/checkbox/Checkbox';
import CheckboxSwitch from '../../components/form/checkboxSwitch/CheckboxSwitch';
import Card from '../../components/card/Card';
import Button from '../../components/button/Button';

import Limiares from './limiares/limiares';

import ApagarHistorico from './apagarHistorico/apagarHistorico';

import { abrirModalLimiares, abrirModalApagarHistorico, atualizarTiposAlarmes, listarAlarmes } from './redux/AlarmesActions';

import './alarmes.css';

const Assinates = ({ history }) => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);
    const Mock = require(`./mock.json`);
    const alarmesMock = Mock.alarmes;
    const alarmesState = useSelector((state) => state.alarmesState);
    const statusModal = useSelector((state) => state.alarmesState.statusModal);
    const listHead = [
        {
            colunm: 'dataHora',
            text: defaltText.inputs.dataHora,
            className: 'text-center',
        },
        {
            colunm: 'tipo',
            text: defaltText.inputs.tipo,
            className: 'text-center',
        },
        {
            colunm: 'equipamento',
            text: defaltText.inputs.equipamento,
            className: 'text-center',
        },
        {
            colunm: 'descricao',
            text: defaltText.inputs.descricao,
            className: 'text-center',
        }
    ];

    useEffect(() => {
        dispatch(listarAlarmes());
    }, [dispatch]);

    const modalAlarmes = (e) => {
        switch (e) {
            case 'abrirLimiares':
                /*  return console.log('Abrir limeares') */
                return <Limiares />;
            case 'apagar':
                return <ApagarHistorico />;
        }
    };


    return (
        <div>
            <h2>{defaltText.title}</h2>
            <div className='alarme-container col-md-12'>
                <Row>
                    <div className='col-md-3'>

                        <Card
                            title="Tipos de alarmes"

                            container={
                                <div className='col-md-12'>
                                    {/*                                     <Row>
                                         <h5>Tipos de alarmes</h5> 


                                                                               <CheckBox
                                            changeInput={(e) => dispatch(atualizarTiposAlarmes(e))}
                                            checked={alarmesState.checkAtivo}
                                            name='checkAtivo'
                                            text={defaltText.inputs.ativo}
                                        />
                                    </Row> */}


                                    <div className='col-md-12'>
                                        <CheckboxSwitch
                                            id='checkAlarmeAtivo'
                                            name='checkAtivo'
                                            label={alarmesState.checkAtivo ? defaltText.inputs.ativo : defaltText.inputs.inativo}
                                            checked={alarmesState.checkAtivo}
                                            changeInput={(e) => dispatch(atualizarTiposAlarmes(e))}
                                        />
                                        <Row>
                                            <div className='col-md-7'>
                                                <div className='margem'>
                                                    <CheckBox
                                                        changeInput={(e) => dispatch(atualizarTiposAlarmes(e))}
                                                        checked={alarmesState.checkUplink}
                                                        name='checkUplink'
                                                        text={defaltText.inputs.uplinkLinkdown}
                                                        disabled={alarmesState.checkAtivo ? false : true}
                                                    />
                                                </div>
                                                <div className='margem'>
                                                    <CheckBox
                                                        changeInput={(e) => dispatch(atualizarTiposAlarmes(e))}
                                                        checked={alarmesState.checkONU}
                                                        name='checkONU'
                                                        text={defaltText.inputs.onuLinkdown}
                                                        disabled={alarmesState.checkAtivo ? false : true}
                                                    />
                                                </div>
                                                <div>
                                                    <CheckBox
                                                        changeInput={(e) => dispatch(atualizarTiposAlarmes(e))}
                                                        checked={alarmesState.checkPotenciaSinal}
                                                        name='checkPotenciaSinal'
                                                        text={defaltText.inputs.potenciaSinal}
                                                        disabled={alarmesState.checkAtivo ? false : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-5 borda canto-direito'>
                                                <Button
                                                    id="btnLimieares"
                                                    titulo={defaltText.btn.limiares}
                                                    action={() => dispatch(abrirModalLimiares())}
                                                    children={defaltText.btn.limiares}
                                                    color="secondary"
                                                    type="btn "
                                                    disabled={alarmesState.checkPotenciaSinal && alarmesState.checkAtivo ? false : true}
                                                />
                                            </div>
                                        </Row>
                                    </div>
                                    <Row>
                                        <div className='col-md-12 canto-direito'>
                                            <Button
                                                id="btnApagarHistorico"
                                                titulo={defaltText.btn.apagarHistorico}
                                                action={() => dispatch(abrirModalApagarHistorico())}
                                                children={defaltText.btn.apagarHistorico}
                                                color="secondary"
                                                type="btn "
                                            />
                                        </div>
                                    </Row>
                                </div>
                            } />

                    </div>

                    <div className='col-md-9'>
                        <h5>{defaltText.titleHistorico}</h5>
                        <List
                            data={{ head: listHead, content: alarmesMock }}
                            listActions={(val) => listActions(val)}
                        />
                    </div>
                </Row>
            </div>
            {modalAlarmes(statusModal)}
        </div>

    );

};

export default Assinates;
