import React, { useEffect, useState } from 'react';
import { IcoFecharNovo, IcoFechar } from '../icones';
import { useSelector } from 'react-redux';
import Button from '../button/Button';
import Portal from '../potal/portal';
import './modal.css';
import olt from '../../modulos/olt/olt';

const Modal = ({ close, containerModal }) => {

    return (
        <Portal name='modal'>
            <>
                {/* <div className={`box-modal ${containerModal.direction ? containerModal.direction : ''}`}>
                    <div className={`size-${containerModal.size}`}>
                        {containerModal.title ? (
                            <div className='modal-header'>
                                <h5>{containerModal.title}</h5>

                                <div className={`${containerModal && containerModal.action ? "header-buttons" : "header-button"}`} style={{ justifyContent: "flex-end" }}>

                                    {containerModal.aditionalAction ? (
                                        <Button
                                            hasAccess={containerModal.aditionalAction.hasAccess}
                                            id={containerModal.aditionalAction.btnId}
                                            titulo={containerModal.aditionalAction.texto}
                                            action={containerModal.aditionalAction.acao}
                                            color={containerModal.aditionalAction.class ? containerModal.aditionalAction.class : 'secondary'}
                                            texto={containerModal.aditionalAction.texto}
                                            disabled={containerModal.aditionalAction.disabled}
                                            size='medium'
                                        />
                                    ) : null}

                                    {containerModal.action ? (
                                        <Button
                                            hasAccess={containerModal.action.hasAccess}
                                            id={containerModal.action.btnId}
                                            titulo={containerModal.action.texto}
                                            action={containerModal.action.acao}
                                            color={containerModal.action.class ? containerModal.action.class : 'secondary'}
                                            texto={containerModal.action.texto}
                                            disabled={containerModal.action.disabled}
                                            size='medium'
                                        />
                                    ) : null}
                                    <Button
                                        id="defaultBtnFechar"
                                        titulo={containerModal.closeText}
                                        action={close}
                                        texto={<IcoFechar title={containerModal.closeText} />}
                                        color='modal-close'
                                        type='btn circle'
                                    />
                                </div>
                            </div>
                        ) : null}
                        {containerModal.content ? <div className='modal-content'>{containerModal.content}</div> : null}
                    </div>
                </div> */}

                {/* 
                NOVO LAYOUT
                */}
                <div className={`box-modal ${containerModal.direction ? containerModal.direction : ''}`}>
                    <div className={`size-${containerModal.size}`}>
                        <div className='modal-close-header'>
                            <Button
                                id="defaultBtnFechar"
                                titulo={containerModal.closeText}
                                action={close}
                                children={<IcoFecharNovo title={containerModal.closeText} />}
                                color='modal-close'
                                type='btn circle'
                            />
                        </div>

                        {containerModal.title ? (
                            <div className='modal-header'>
                                <h5>{containerModal.title}</h5>
                            </div>

                        ) : null}

                        {containerModal.content ? <div className='modal-content'>{containerModal.content}</div> : null}
                        <footer >
                            {containerModal.action ? (
                                <div className='modal-footer-button'>
                                    <Button
                                        id="btn-cancelar"
                                        titulo={containerModal.closeText}
                                        action={close}
                                        children={containerModal.closeText}
                                        color='secondary'
                                        size='medium'
                                        variant='modal'
                                    />
                                    <Button
                                        hasAccess={containerModal.action.hasAccess}
                                        id={containerModal.action.btnId}
                                        titulo={containerModal.action.texto}
                                        action={containerModal.action.acao}
                                        color={containerModal.action.class ? containerModal.action.class : 'secondary'}
                                        children={containerModal.action.texto}
                                        disabled={containerModal.action.disabled}
                                        size='medium'
                                        variant='modal'
                                    />

                                    {containerModal.aditionalAction ? (
                                        <Button
                                            hasAccess={containerModal.aditionalAction.hasAccess}
                                            id={containerModal.aditionalAction.btnId}
                                            titulo={containerModal.aditionalAction.texto}
                                            action={containerModal.aditionalAction.acao}
                                            color={containerModal.aditionalAction.class ? containerModal.aditionalAction.class : 'secondary'}
                                            children={containerModal.aditionalAction.texto}
                                            disabled={containerModal.aditionalAction.disabled}
                                            size='medium'
                                            variant='modal'
                                        />
                                    ) : null}

                                </div>
                            ) : null}

                            {containerModal.removeConfirmAction ? (
                                <div className='modal-footer-button'>
                                    <Button
                                        hasAccess={containerModal.removeConfirmAction.hasAccess}
                                        id={containerModal.removeConfirmAction.btnId}
                                        titulo={containerModal.removeConfirmAction.texto}
                                        action={containerModal.removeConfirmAction.acao}
                                        color={containerModal.removeConfirmAction.class ? containerModal.removeConfirmAction.class : 'secondary'}
                                        children={containerModal.removeConfirmAction.texto}
                                        disabled={containerModal.removeConfirmAction.disabled}
                                        size='medium'
                                        variant='modal'
                                    />
                                    <Button
                                        id="btn-cancelar"
                                        titulo={containerModal.closeText}
                                        action={close}
                                        children={containerModal.closeText}
                                        color='secondary'
                                        size='medium'
                                        variant='modal'
                                    />
                                </div>
                            ) : null}
                        </footer>
                    </div>
                </div>
            </>
        </Portal>
    );
};

export default Modal;
