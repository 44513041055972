import React from 'react';
import { resetarCamposAction, validarAction, validarCamposAction } from '../../../validation/validationActions';

import axios from 'axios';
import { toast } from 'react-toastify';
import { checkResponseErrorType } from '../../../utils/utils';
import { CorrectIcon } from '../../../layouts/components/icons';
import { fecharModalCpe } from '../../cpe/redux/CpeActions';
import { setListPlanoInicial } from '../../planos/redux/PlanosActions';

const idiomaText = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userLocales = user.preferencia.linguagem;
    const locales = userLocales ? (userLocales === 'EN_US' ? 'en-US' : 'pt-BR') : null;
    const defaltText = require(`../nls/${locales ? locales : 'pt-BR'}.json`);
    return defaltText.mensagem;
};
export const FETCH_SERVICO_OLT_DASHBOARD = "FETCH_SERVICO_OLT_DASHBOARD";
const fetchServicoOltDashboard = data => ({
    type: FETCH_SERVICO_OLT_DASHBOARD,
    payload: data
});
export const ATUALIZAR_CONTEUDO_SERVICO_MODAL = 'ATUALIZAR_CONTEUDO_SERVICO_MODAL';
export const atualizarConteudoServicoModal = evento => ({
    type: ATUALIZAR_CONTEUDO_SERVICO_MODAL,
    payload: evento
});
export const ATUALIZAR_CONTEUDO_SERVICO_EXISTENTE_MODAL = 'ATUALIZAR_CONTEUDO_SERVICO_EXISTENTE_MODAL';
/* export const atualizarConteudoServicoExistenteModal = (evento, assinante) => {
  const conteudo = {
    target: { ...evento.target, value: { ...evento.target.value, assinante } }
  }
  return {
    type: ATUALIZAR_CONTEUDO_SERVICO_EXISTENTE_MODAL,
    payload: conteudo
  }
} */

export const atualizarConteudoServicoExistenteModal = (e, assinante) => ({
    type: ATUALIZAR_CONTEUDO_SERVICO_EXISTENTE_MODAL,
    payload: { e, assinante }
});

export const RESETAR_CPE_SELECIONADA = "RESETAR_CPE_SELECIONADA";
export const resetarCpeSelecionada = () => ({
    type: RESETAR_CPE_SELECIONADA
});

export const SET_TIPO_MODAL_SERVICO = 'SET_TIPO_MODAL_SERVICO';
const setTipoModalServico = tipo => ({
    type: SET_TIPO_MODAL_SERVICO,
    payload: tipo
});

export const SET_SERVICO = 'SET_SERVICO';
const setServico = servico => ({
    type: SET_SERVICO,
    payload: servico
});

export const SET_SERVICO_INICIAL = 'SET_SERVICO_INICIAL';
export const setServicoInicial = () => ({
    type: SET_SERVICO_INICIAL
});

export const ABRIR_MODAL_CRIAR_SERVICO = 'ABRIR_MODAL_CRIAR_SERVICO';
export const abrirModalCriarServico = () => [resetarCamposAction(), setServicoInicial(), setTipoModalServico('criando')];

export const OPEN_SERVICE_INFO_MODAL = "OPEN_SERVICE_INFO_MODAL";
export const openServiceInfoModal = (servico) => [
    setServico(servico),
    setTipoModalServico("serviceInfo")
];

export const ABRIR_MODAL_EDITAR_SERVICO = 'ABRIR_MODAL_EDITAR_SERVICO';
export const abrirModalEditarServico = servico => [
    resetarCamposAction(),
    setServico(servico),
    setTipoModalServico('editando')
];

export const ABRIR_MODAL_VINCULAR_SERVICO = 'ABRIR_MODAL_VINCULAR_SERVICO';
export const abrirModalVincularServico = servico => [
    resetarCamposAction(),
    setServico(servico),
    setTipoModalServico('vinculando')
];

export const ABRIR_MODAL_DESVINCULAR_SERVICO = 'ABRIR_MODAL_DESVINCULAR_SERVICO';
export const abrirModalDesvincularServico = servico => [
    resetarCamposAction(),
    setServico(servico),
    setTipoModalServico('desvincular')
];

export const ABRIR_MODAL_DELETAR_SERVICO = 'ABRIR_MODAL_DELETAR_SERVICO';
export const abrirModalDeletarServico = servico => [setServico(servico), setTipoModalServico('deletando')];

export const ABRIR_MODAL_ERRO_SERVICO = 'ABRIR_MODAL_ERRO_SERVICO';
export const abrirModalErroServico = servico => [setServico(servico), setTipoModalServico('erro')];

export const ABRIR_MODAL_INFORMACAO = 'ABRIR_MODAL_INFORMACAO';
export const abrirModalInformacaoServico = servico => [setServico(servico), setTipoModalServico('informacoes')];

export const ABRIR_MODAL_INTERNET = 'ABRIR_MODAL_INTERNET';
export const abrirModalInternetServico = servico => [
    resetarCamposAction(),
    setServico(servico),
    setTipoModalServico('internet')
];

export const ABRIR_MODAL_TELEFONE = 'ABRIR_MODAL_TELEFONE';
export const abrirModalTelefoneServico = servico => [
    resetarCamposAction(),
    setServico(servico),
    setTipoModalServico('telefone')
];

export const FECHAR_MODAL_SERVICO = 'FECHAR_MODAL_SERVICO';
export const fecharModalServico = () => [setServicoInicial(), setTipoModalServico(''), setListPlanoInicial()];

export const LIMPAR_CAMPO_PESQUISA_SERVICO = 'LIMPAR_CAMPO_PESQUISA_SERVICO';
export const limparCampoPesquisa = () => [
    {
        type: LIMPAR_CAMPO_PESQUISA_SERVICO
    },
    listarServicos()
];

export const LIMPAR_CAMPO_PESQUISA_GENERICO = 'LIMPAR_CAMPO_PESQUISA_GENERICO';
export const limparCampoPesquisaGenerico = (event) => [
    {
        type: LIMPAR_CAMPO_PESQUISA_GENERICO,
        payload: event
    },
    /* listarServicos() */
];

export const LIMPAR_FORM_PESQUISA = 'LIMPAR_FORM_PESQUISA';
export const limparFormPesquisa = () => [
    {
        type: LIMPAR_FORM_PESQUISA,
    },
    listarServicos()
];

export const ATUALIZAR_CAMPO_PESQUISA_SERVICO = 'ATUALIZAR_CAMPO_PESQUISA_SERVICO';
export const atualizarCampoPesquisa = e => ({
    type: ATUALIZAR_CAMPO_PESQUISA_SERVICO,
    payload: e.target.value
});

//Teste FILTRO AVANÇADO
export const ATUALIZAR_FORMULARIO_PESQUISA_SERVICO = 'ATUALIZAR_FORMULARIO_PESQUISA_SERVICO';
export const atualizarFormularioDePesquisa = e => ({
    type: ATUALIZAR_FORMULARIO_PESQUISA_SERVICO,
    payload: e
});

//Teste FILTRO AVANÇADO

export const CARREGANDO_SERVICOS = 'CARREGANDO_SERVICOS';
const carregandoServicos = () => ({
    type: CARREGANDO_SERVICOS
});

export const SERVICOS_CARREGADOS_COM_SUCESSO = 'SERVICOS_CARREGADOS_COM_SUCESSO';
const servicosCarregadosComSuceso = servicos => ({
    type: SERVICOS_CARREGADOS_COM_SUCESSO,
    payload: servicos
});

export const SERVICOS_SEM_ASSINANTE_CARREGADOS_COM_SUCESSO = 'SERVICOS_SEM_ASSINANTE_CARREGADOS_COM_SUCESSO';
const servicosSemAssinanteCarregadosComSuceso = (servicos) => ({
    type: SERVICOS_SEM_ASSINANTE_CARREGADOS_COM_SUCESSO,
    payload: servicos
});

export const SERVICOS_CARREGADOS_COM_ERRO = 'SERVICOS_CARREGADOS_COM_ERRO';
const servicosCarregadosComErro = erro => ({
    type: SERVICOS_CARREGADOS_COM_ERRO,
    payload: erro
});

export const SERVICOS_ATUALIZADOS_COM_SUCESSO = "SERVICOS_ATUALIZADOS_COM_SUCESSO";
export const servicosAtualizadosComSucesso = (servico) => ({
    type: SERVICOS_ATUALIZADOS_COM_SUCESSO,
    payload: servico
});

export const listarServicos = e => {
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        pesquisa: `&pesquisa=${e && e.pesquisa ? e.pesquisa.formPesquisa.campoPesquisa : ''}`,
        plano: `&plano=${e && e.pesquisa && e.pesquisa.formPesquisa && e.pesquisa.formPesquisa.plano && e.pesquisa.formPesquisa.plano.nome ? e.pesquisa.formPesquisa.plano.nome : ''}`,
        olt: `&olt=${e && e.pesquisa && e.pesquisa.formPesquisa && e.pesquisa.formPesquisa.olt && e.pesquisa.formPesquisa.olt.nome ? e.pesquisa.formPesquisa.olt.nome : ''}`,
        onu: `&onu=${e && e.pesquisa && e.pesquisa.formPesquisa && e.pesquisa.formPesquisa.cpe && e.pesquisa.formPesquisa.cpe.identificador ? e.pesquisa.formPesquisa.cpe.identificador : ''}`,
        nome: `&nome=${e && e.pesquisa ? e.pesquisa.formPesquisa.nome : ''}`,
        cpf: `&cpf=${e && e.pesquisa ? e.pesquisa.formPesquisa.cpf : ''}`,
        nContrato: `&nContrato=${e && e.pesquisa ? e.pesquisa.formPesquisa.nContrato : ''}`
    };
    return dispatch => {
        dispatch(handleIsLoadingServicesStatus(true));
        dispatch(carregandoServicos());
        axios
            .get(`/api/servico?${params.pagina + params.pesquisa + params.plano + params.olt + params.onu + params.nome + params.cpf + params.nContrato}`)
            .then(response => dispatch(servicosCarregadosComSuceso(response.data)))
            .catch(error => {
                dispatch(servicosCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
                dispatch(handleIsLoadingServicesStatus(false));
            });
    };
};

export const listarServicosSemAssinante = (e) => {
    let params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        qtdRegistros: `&qtdRegistros=${e && e.qtdRegistros ? e.qtdRegistros : '10'}`,
        pesquisa: `&pesquisa=${e && e.pesquisa ? e.pesquisa : ''}`
    };
    return dispatch => {
        dispatch(carregandoServicos());
        axios
            .get(`/api/servico-sem-assinante?${params.pagina + params.pesquisa + params.qtdRegistros}`)
            .then(response => {
                e && e.quantidadePorPagina && e.pagina >= 1
                    ? dispatch(maisServicosCarregadosComSucesso(response.data))
                    : dispatch(servicosSemAssinanteCarregadosComSuceso(response.data));
            })
            .catch(error => {
                dispatch(servicosCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
            });
    };
};
export const MAIS_SERVICOS_CARREGADOS_COM_SUCESSO = 'MAIS_SERVICOS_CARREGADOS_COM_SUCESSO';
const maisServicosCarregadosComSucesso = (olts) => ({
    type: MAIS_SERVICOS_CARREGADOS_COM_SUCESSO,
    payload: olts,
});

export const listarServicosAssinante = id => {
    return dispatch => {
        dispatch(carregandoServicos());
        axios
            .get(`/api/servico/assinante/${id}`)
            .then(response => dispatch(servicosCarregadosComSuceso(response.data)))
            .catch(error => {
                dispatch(servicosCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarServicosAssinanteErro);
            });
    };
};

export const CARREGANDO_SERVICO = 'CARREGANDO_SERVICO';
const carregandoServico = () => ({
    type: CARREGANDO_SERVICO
});

export const SERVICO_CARREGADOS_COM_SUCESSO = 'SERVICO_CARREGADOS_COM_SUCESSO';
const servicoCarregadosComSuceso = servicos => ({
    type: SERVICO_CARREGADOS_COM_SUCESSO,
    payload: servicos
});

export const SERVICO_CARREGADOS_COM_ERRO = 'SERVICO_CARREGADOS_COM_ERRO';
const servicoCarregadosComErro = erro => ({
    type: SERVICO_CARREGADOS_COM_ERRO,
    payload: erro
});

export const listarServico = tipo => {
    const tipoConfig = tipo || '';
    return dispatch => {
        dispatch(carregandoServico());
        axios
            .get(`/api/servico/${tipoConfig}`)
            .then(response => dispatch(servicoCarregadosComSuceso(response.data)))
            .catch(error => {
                dispatch(servicoCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().dadosServicoErro);
            });
    };
};

export const ADICIONANDO_SERVICO = 'ADICIONANDO_SERVICO';
const adicionandoServico = () => ({
    type: ADICIONANDO_SERVICO
});

export const SERVICO_ADICIONADO_COM_SUCESSO = 'SERVICO_ADICIONADO_COM_SUCESSO';
export const servicoAdicionadoComSucesso = servico => ({
    type: SERVICO_ADICIONADO_COM_SUCESSO,
    payload: servico
});

export const SERVICO_NAO_ADICIONADO = 'SERVICO_NAO_ADICIONADO';
const servicoNaoAdicionado = erro => ({
    type: SERVICO_NAO_ADICIONADO,
    payload: erro
});

const servicoRequired = {
    nome: '',
    plano: {},
    olt: {},
    cpe: {}
};

export const adicionarServico = (servico, page, cpeState) => {
    const servicoJson = { ...servico, cpe: { ...servico.cpe, modelo: servico.cpe.modelo.model ? servico.cpe.modelo.model : servico.cpe.modelo } };
    const routerCounter = servico &&
        servico.plano &&
        servico.plano.listaConfiguracoes &&
        (servico.plano.listaConfiguracoes.filter((config) => config.tipoServico === "PPPOE").length > 0) ?
        servico.plano.listaConfiguracoes.filter((config) => config.tipoServico === "PPPOE").length : 0;
        const servicoRequireds = servico.pppoeActive ? { ...servicoRequired, usuarioPPPoE: '', senhaPPPoE: '' } : { ...servicoRequired}
    const servicoVal = {
        formRequired: routerCounter > 0 ? servicoRequireds : servicoRequired,
        formValues: servicoJson
    };

    return dispatch => {
        const mensagemDeErro = validarCamposAction(servicoVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        (cpeState.includes('addService') && dispatch(fecharModalCpe(cpeState)));
        dispatch(fecharModalServico());
        dispatch(adicionandoServico());
        axios
            .post('/api/servico', servicoJson)
            .then(response => {
                //dispatch(servicoAdicionadoComSucesso(response.data)) //comentar quando o Websocket foir implementado
                dispatch(listarServicos({ pagina: page }));
                toast.success((idiomaText().adicionarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(servicoNaoAdicionado(erro));
                checkResponseErrorType(erro, idiomaText().adicionarErro);
            });
    };
};

export const ATUALIZANDO_SERVICO = 'ATUALIZANDO_SERVICO';
const atualizandoServico = () => ({
    type: ATUALIZANDO_SERVICO
});

export const SERVICO_ATUALIZADO_COM_SUCESSO = 'SERVICO_ATUALIZADO_COM_SUCESSO';
export const servicoAtualizadoComSucesso = servico => ({
    type: SERVICO_ATUALIZADO_COM_SUCESSO,
    payload: servico
});

export const SERVICO_NAO_ATUALIZADO = 'SERVICO_NAO_ATUALIZADO';
const servicoNaoAtualizado = erro => ({
    type: SERVICO_NAO_ATUALIZADO,
    payload: erro
});

export const atualizarServico = (servico, page) => {
    const serviceEditRequired = { nome: '' };
    const servicoJson = servico;
    const servicoVal = {
        /* formRequired: servico.plano && servico.plano.nome && servico.plano.nome.toLowerCase().includes("router") ? { ...servicoRequired, usuarioPPPoE: '', senhaPPPoE: '' } : servicoRequired, */
        formRequired: serviceEditRequired,
        formValues: servicoJson
    };

    return dispatch => {
        const mensagemDeErro = validarCamposAction(servicoVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalServico());
        dispatch(atualizandoServico());
        axios
            .put('/api/servico', servicoJson)
            .then(response => {
                //dispatch(servicoAtualizadoComSucesso(response.data))
                dispatch(listarServicos({ pagina: page }));
                toast.success((idiomaText().atualizarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(servicoNaoAtualizado(erro));
                checkResponseErrorType(erro, idiomaText().atualizarErro);
            });
    };
};

export const DELETANDO_SERVICO = 'DELETANDO_SERVICO';
const deletandoServico = () => ({
    type: DELETANDO_SERVICO
});

export const SERVICO_DELETADO_COM_SUCESSO = 'SERVICO_DELETADO_COM_SUCESSO';
export const servicoDeletadoComSucesso = servico => ({
    type: SERVICO_DELETADO_COM_SUCESSO,
    payload: servico
});

export const SERVICO_NAO_DELETADO = 'SERVICO_NAO_DELETADO';
const servicoNaoDeletado = erro => ({
    type: SERVICO_NAO_DELETADO,
    payload: erro
});

export const deletarServico = (servico, page) => {
    return dispatch => {
        dispatch(fecharModalServico());
        dispatch(deletandoServico());
        axios
            .delete(`/api/servico/${servico.id}`)
            .then(() => {
                //dispatch(servicoDeletadoComSucesso(servico.id)) //Comentar novamente quando o Websocket for implementado
                //dispatch(listarServicos({ pagina: page }));
                toast.success((idiomaText().deletarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(servicoNaoDeletado(erro));
                checkResponseErrorType(erro, idiomaText().deletarErro);
            });
    };
};

export const REINICIANDO_SERVICO = 'REINICIANDO_SERVICO';
const reiniciandoServico = () => ({
    type: REINICIANDO_SERVICO
});


export const SERVICO_REINICIADO_COM_SUCESSO = 'SERVICO_REINICIADO_COM_SUCESSO';
const servicoReiniciadoComSucesso = servico => ({
    type: SERVICO_REINICIADO_COM_SUCESSO,
    payload: servico
});

export const SERVICO_NAO_REINICIADO = 'SERVICO_NAO_REINICIADO';
const servicoNaoReiniciado = erro => ({
    type: SERVICO_NAO_REINICIADO,
    payload: erro
});

export const reenviarServico = (servico, page) => {
    return dispatch => {
        dispatch(fecharModalServico());
        dispatch(reiniciandoServico());
        axios
            .post('/api/servico/reenviar', servico)
            .then(() => {
                dispatch(listarServicos({ pagina: page }));
                toast.success((idiomaText().reenviarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(servicoNaoReiniciado(erro));
                checkResponseErrorType(erro, idiomaText().reenviarErro);
            });
    };
};

export const RESETAR_CAMPOS_PPPOE = "RESETAR_CAMPOS_PPPOE";
export const resetarCamposPppoe = () => ({
    type: RESETAR_CAMPOS_PPPOE
});

export const RESET_SERVICE_STATE = "RESET_SERVICE_STATE";
export const resetServiceState = () => ({
    type: RESET_SERVICE_STATE,
});

export const HANDLE_IS_LOADING_SERVICES_STATUS = "HANDLE_IS_LOADING_SERVICES_STATUS";
export const handleIsLoadingServicesStatus = (status) => ({
    type: HANDLE_IS_LOADING_SERVICES_STATUS,
    payload: status
});

export const OK_OLTS_LOADED_SUCCESSFULLY = 'OK_OLTS_LOADED_SUCCESSFULLY';
const okOltsLoadedSuccessfully = (olts) => ({
    type: OK_OLTS_LOADED_SUCCESSFULLY,
    payload: olts,
});

export const MORE_OK_OLTS_LOADED_SUCCESSFULLY = 'MORE_OK_OLTS_LOADED_SUCCESSFULLY';
const moreOkOltsLoadedSuccessfully = (olts) => ({
    type: MORE_OK_OLTS_LOADED_SUCCESSFULLY,
    payload: olts,
});

export const OK_OLTS_LOADED_ERROR = 'OK_OLTS_LOADED_ERROR';
const okOltsLoadedError = (error) => ({
    type: OK_OLTS_LOADED_ERROR,
    payload: error,
});

export const loadOkOlts = (e) => {
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        pesquisa: `&nome=${e && e.pesquisa ? e.pesquisa : ''}`,
        quantidadePorPagina: `&quantidadePorPagina=${e && e.quantidadePorPagina ? e.quantidadePorPagina : '10'}`
    };
    return (dispatch) => {
        axios
            .get(`/api/olt/ok?${params.pagina + params.pesquisa + params.quantidadePorPagina}`)
            .then((response) => {
                e && e.quantidadePorPagina && e.pagina >= 1
                    ? dispatch(moreOkOltsLoadedSuccessfully(response.data))
                    : dispatch(okOltsLoadedSuccessfully(response.data));
            })
            .catch((error) => {
                dispatch(okOltsLoadedError(error));
                checkResponseErrorType(error, idiomaText().listarErro);
            });
    };
};

export const fetchServicosOlt = (cpe) => {
   
    return (dispatch) => {
        axios
            .get('/api/servico/dashboard/fetchServicoInfo')
            .then((response) => {
                dispatch(fetchServicoOltDashboard(response.data));
            })
            .catch((error) => {
                dispatch(servicosCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
            });
    };
};
export const CLEAR_SERVICO_FIELD = "CLEAR_SERVICO_FIELD";
export const clearServicoField = (e) => ({
    type: CLEAR_SERVICO_FIELD,
    payload: e
});

export const SET_MODAL_ADVANCED_FILTER_SERVICE = 'SET_MODAL_ADVANCED_FILTER_SERVICE';
export const setModalAdvancedFilterService = (type) => ({
    type: SET_MODAL_ADVANCED_FILTER_SERVICE,
    payload: type
});

export const SET_FECHAR_MODAL_ADVANCED_FILTER = 'SET_FECHAR_MODAL_ADVANCED_FILTER';
export const setFecharModalAdvancedFilter = (type) => ({
    type: SET_FECHAR_MODAL_ADVANCED_FILTER,
    payload: type
});

export const IS_SEARCH = 'servico.IS_SEARCH';
export const isSearch = () => ({
    type: IS_SEARCH
});

