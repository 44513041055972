import React from 'react';

export function CorrectIcon(props) {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            style={props.style ? props.style : {}}
        >
            <title>{props.title ? props.title : null}</title>
            <path d="M21.563,11.063a10.5,10.5,0,1,1-10.5-10.5A10.5,10.5,0,0,1,21.563,11.063ZM9.848,16.622l7.79-7.79a.677.677,0,0,0,0-.958l-.958-.958a.677.677,0,0,0-.958,0L9.369,13.269,6.4,10.3a.677.677,0,0,0-.958,0l-.958.958a.677.677,0,0,0,0,.958l4.4,4.4A.677.677,0,0,0,9.848,16.622Z"
                transform="translate(-0.563 -0.563)"
            />
        </svg>
    );
}