import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BrazilIcon, UsaIcon } from '../components/icons';
import { IcoInfo } from '../../components/icones';


const idiomaText = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userLocales = user?.preferencia.linguagem;
    const locales = userLocales ? (userLocales === 'EN_US' ? 'en-US' : 'pt-BR') : null;
    const defaltText = require(`../nls/${locales ? locales : 'pt-BR'}.json`);
    return defaltText.mensagem;
};

export const SET_TIPO_MODAL = 'SET_TIPO_MODAL';
const setTipoModal = tipo => ({
    type: SET_TIPO_MODAL,
    payload: tipo
});

export const ABRIR_TOOLTIP = 'ABRIR_TOOLTIP';
export const abrirTooltip = (posicao, local) => ({
    type: ABRIR_TOOLTIP,
    payload: {
        posicao,
        local
    }
});

export const FECHAR_TOOLTIP = 'FECHAR_TOOLTIP';
export const fecharTooltip = () => ({
    type: FECHAR_TOOLTIP
});

export const SIDEBAR_LEFT = 'SIDEBAR_LEFT';
export const sidebarLeft = e => ({
    type: SIDEBAR_LEFT,
    payload: e
});

export const SIDEBAR_RIGHT = 'SIDEBAR_RIGHT';
export const sidebarRight = e => ({
    type: SIDEBAR_RIGHT,
    payload: e
});

export const abrirModalSair = () => setTipoModal('sair');

export const abrirModalReleaseNotes = () => setTipoModal('mostrarAtualizacao');

export const fecharModal = () => setTipoModal('');

export const ajustIdioma = e => {
    if (e === 'EN_US' || e === 'en-US' || e === 'en-GB') {
        return { id: 'EN_US', use: 'en-US', nome: 'English', resource: <UsaIcon /> };
    } else {
        return { id: 'PT_BR', use: 'pt-BR', nome: 'Português', resource: <BrazilIcon /> };
    }
};

export const ATUALIZAR_TEMA = "ATUALIZAR_TEMA";
export const atualizarTema = (tema) => ({ type: ATUALIZAR_TEMA, payload: tema });

export const CARREGANDO_IDIOMAS = 'CARREGANDO_IDIOMAS';
const carregandoIdiomas = () => ({
    type: CARREGANDO_IDIOMAS
});

export const IDIOMAS_CARREGADOS_COM_SUCESSO = 'IDIOMAS_CARREGADOS_COM_SUCESSO';
const idiomasCarregadosComSuceso = idiomas => ({
    type: IDIOMAS_CARREGADOS_COM_SUCESSO,
    payload: idiomas
});

export const listarIdiomas = () => {
    return dispatch => {
        dispatch(carregandoIdiomas());
        axios
            .get('/api/linguagem/obterLinguagens')
            .then(response => dispatch(idiomasCarregadosComSuceso(response.data)))
            .catch(() => {
                toast.error((idiomaText()?.erroListarIdioma), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });

            });
    };
};

export const ATUALIZANDO_PREFERENCIA = 'ATUALIZANDO_PREFERENCIA';
const atualizandoPreferencia = () => ({
    type: ATUALIZANDO_PREFERENCIA
});

export const PREFERENCIA_ATUALIZADA_COM_SUCESSO = 'PREFERENCIA_ATUALIZADA_COM_SUCESSO';
export const preferenciaAtualizadaComSuceso = servicos => ({
    type: PREFERENCIA_ATUALIZADA_COM_SUCESSO,
    payload: servicos
});

export const atualizarPreferencia = preferencia => {
    localStorage.setItem("user", JSON.stringify(preferencia));
    return dispatch => {
        dispatch(atualizandoPreferencia());
        axios
            .put('/api/usuario/editarUsuario', preferencia)
            .then(response => {
                dispatch(preferenciaAtualizadaComSuceso(response.data));
                //VERIFICAR
                //dispatch(LogInUser())
            })
            .catch(erro => {
                toast.error((idiomaText().erroAtualizarPreferencia), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });

            });
    };
};

export const atualizarPreferenciaOffline = preferencia => {
    preferenciaAtualizadaComSuceso(preferencia);
};

export const SET_MODAL_HEADER_TYPE = "SET_MODAL_HEADER_TYPE";
export const setModalHeaderType = (type) => ({
    type: SET_MODAL_HEADER_TYPE,
    payload: type
});