import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/button/Button';
import List from '../../../components/list/List';
import Row from '../../../components/row/row';
import history from '../../../history';
import ModalSFP from './modalSFP/exibirModalSFP';
import { abrirModalConfirmarSalvar, abrirModalInfoUtilizacaoOlt, abrirModalReiniciarOlt, abrirModalSFPInfo, listarOlt, listarOlts, openConfirmUpdateModal, reiniciarOlt } from '../redux/OltActions';
import ModalReiniciarOlt from './modalReiniciarOlt/modalReiniciarOlt';
import useWindowDimensions, { getPagePermissions } from '../../../utils/utils';
import "./informacoes.css";
import Table from '../../../layouts/components/Table/Table';
import Down from '../../../layouts/components/icons/Down';
import Up from '../../../layouts/components/icons/Up';
import SigCloudLoader from '../../../layouts/components/Loader/SigCloudLoader';
import TitleContainer from '../../../layouts/components/TitleContainer/TitleContainer';
import { DownloadIcon } from '../../../components/icones';
import UploadIcon from '../../../components/icones/upload';
import SaveIcon from '../../../components/icones/save'
import RebootIcon from '../../../components/icones/reboot';
import UpdateFirmware from '../updateFirmware/updateFirmware';
import ModalConfirmarReiniciar from './modalReiniciarOlt/reiniciarConfirmacao';
import { InfoIcon } from '../../../layouts/components/icons';
import ExibirModalInfoUtilizacaoOlt from './infoUtilizacaoOlt/infoUtilizacaoOlt';
import ExibirModalConfirmar from '../informacoes/modalSalvarConfig/modalSalvarConfig'
import Tooltip from '../../../components/tooltip/Tooltip';

const InformacoesOlt = () => {
    const dispatch = useDispatch();
    const statusModal = useSelector((state) => state.oltsState.statusModal);
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const olt = useSelector((state) => state.oltsState.olt);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const isLoading = useSelector((state) => state.oltsState.isLoading);
    const [posicaoMessage, setPosicaoMessage] = useState({ top: 0, left: 0, right: 0, mensagem: null })
    const [statusTooltip, setStatusTooltip] = useState(true);
    const screenSize = useWindowDimensions();
    const lastSoftware = olt.modelo === "OLT_8820I" ? "2.106" : "V100R001B01D002P003SP6_intelbras"

    useEffect(() => {
        const oltId = window.location.pathname.split('/')[3];
        dispatch(listarOlt(oltId));
        dispatch(listarOlts());
    }, [dispatch]);

    function openTooltip(e, mensagem) {
        const pos = e.currentTarget.getBoundingClientRect()
        mensagem ? setStatusTooltip(true) : setStatusTooltip(false);
        setPosicaoMessage({ top: pos?.top - 10, left: (e.clientX), right: pos?.right, mensagem: mensagem, width: '150px' })
    }
    function closeTooltip() {
        setStatusTooltip(false);
    }

    const modalSFP = (e, i) => {
        switch (e[i]) {
            case 'exibindoModalSFP':
                return [<ModalSFP />, modalSFP(e, i + 1)];
            case 'modalReiniciarOLT':
                return [<ModalReiniciarOlt />, modalSFP(e, i + 1)];
            case 'atualizandoFirmware':
                return [<UpdateFirmware />, modalSFP(e, i + 1)];
            case 'modalConfirmaReiniciar':
                return [<ModalConfirmarReiniciar />, modalSFP(e, i + 1)];
            case 'modalInfoUtilizacaoOlt':
                return [<ExibirModalInfoUtilizacaoOlt />, modalSFP(e, i + 1)];
            case 'modalSalvarConfig':
                return [<ExibirModalConfirmar />, modalSFP(e, i + 1)];
            default:
                return;
        }
    };

    const listActions = (val) => {
        switch (val.item) {
            case 'actions':
                return (
                    <>
                        <Button
                            id="btnAbrirModalSFP"
                            titulo={defaultText.btn.editar}
                            action={() => dispatch(abrirModalSFPInfo(val.itens))}
                            children={defaultText.btn.sfpInfo}
                            color="secondary"
                            type="btn"
                        />
                    </>
                );
            case 'link':
                return (
                    val.itens.link === "down" ? <div className="table-column-info-with-icon"><Down />{(val.itens.link)}</div> : <div className="table-column-info-with-icon"><Up />{val.itens.link}</div>
                );
            default:
                return;
        }
    };

    const labels = [
        { text: defaultText.informacoes.interface, field: "nomeInterface" },
        { text: defaultText.informacoes.status, field: "link" },
        { text: defaultText.informacoes.speed, field: "speed" },
        { text: defaultText.inputs.acoes, field: "actions" },
    ];

    return (<>
        <SigCloudLoader visible={isLoading} />
        <Tooltip caso={1} estado={statusTooltip} posicaoMessage={posicaoMessage} />
        <div className="olt-informacao">

            <TitleContainer
                label={defaultText.titleInformacoes + '-' + olt.nome}
            />
            {/* Testes */}

            <div className='box-conteudo'>
                <Row>
                    <div className="col-md-1">
                        <label>{defaultText.inputs.oltModelo}</label>
                        <p>{olt.modelo && olt.modelo.replace('OLT_', '')}</p>
                    </div>

                    <div className="section-divisor"></div>

                    {olt.modelo !== "OLT_8820I" ?
                        <>
                            <div className="col-md-3">
                                <label>{defaultText.inputs.oltSoftware}</label>
                                <p>{olt.softwareVersion}</p>
                            </div>
                            <div className="section-divisor"></div>
                            <div className="col-md-1">
                                <label>{defaultText.inputs.oltHardware}</label>
                                <p>{olt.hardwareVersion}</p>
                            </div>
                            <div className="section-divisor"></div>
                        </>
                        :
                        <>
                            <div className="col-md-4">
                                <label>{defaultText.inputs.oltSoftware}</label>
                                <p>{olt.softwareVersion}</p>
                            </div>
                        </>
                    }

                    <div className={olt.modelo !== "OLT_8820I" ? "col-md-6 buttons" : "col-md-5 buttons"}>
                        {olt.modelo === "OLT_8820I" ? <div className="section-divisor"></div> : null}
                        <Button
                            tooltip={false}
                            hasAccess={getPagePermissions(permissions, "olts", "update")}
                            id="btnAtualizarFirmware"
                            onMouseOver={e => openTooltip(e, olt.softwareVersion === lastSoftware ? defaultText.informacoes.OLTATUALIZADA : defaultText.informacoes.OLTDESATUALIZADA)}
                            onMouseOut={_ => closeTooltip()}
                            action={() => dispatch(openConfirmUpdateModal(olt))}
                            texto={screenSize.width > '1692' ? defaultText.btn.atualizarFirmwareOlt : ''}
                            children={<UploadIcon title={defaultText.btn.atualizarFirmwareOlt} />}
                            color='success'
                            type='btn'
                            disabled={olt.softwareVersion === lastSoftware ? true : false}
                        />

                        <div className="section-divisor"></div>

                        <Button
                            hasAccess={permissions ? getPagePermissions(permissions, "olts", "update") : false}
                            titulo={defaultText.btn.reiniciarOlt}
                            texto={screenSize.width > '1692' ? defaultText.btn.reiniciarOlt : ''}
                            action={() => dispatch(abrirModalReiniciarOlt(olt))}
                            color='success'
                            disabled={(olt.status !== "OK") ? true : false}
                            children={<RebootIcon title={defaultText.btn.reiniciarOlt} />}
                            size='medium'
                        />
                        <div className="section-divisor"></div>

                        <Button
                            hasAccess={permissions ? getPagePermissions(permissions, "olts") : false}
                            titulo={defaultText.btn.informacoes}
                            texto={screenSize.width > '1692' ? defaultText.btn.informacoes : ''}
                            action={() => dispatch(abrirModalInfoUtilizacaoOlt(olt))}
                            color='success'
                            disabled={(olt.status !== "OK") ? true : false}
                            children={<InfoIcon title={defaultText.btn.informacoes} />}
                            size='medium'
                        />
                        {olt.modelo !== "OLT_8820I" ?
                            <>
                                <div className="section-divisor"></div>
                                <Button
                                    hasAccess={permissions ? getPagePermissions(permissions, "olts") : false}
                                    titulo={defaultText.btn.salvarConfig}
                                    texto={screenSize.width > '1692' ? defaultText.btn.salvarConfig : ''}
                                    action={() => dispatch(abrirModalConfirmarSalvar(olt))}
                                    color='success'
                                    disabled={(olt.status !== "OK") ? true : false}
                                    children={<SaveIcon title={defaultText.btn.informacoes} />}
                                    size='medium'
                                />
                            </>
                            : null
                        }

                    </div>
                </Row>
            </div>
            <h6>{defaultText.titleInterface}</h6>
            <Table
                itens={olt.portas10GE.concat(olt.portasG).concat(olt.portasE)}
                labels={labels}
                listActions={(val) => listActions(val)}
            />
            <div>
                <Row>
                    <div className="col-md-12">
                        <div className="flex-end">
                            <Button
                                titulo={defaultText.btn.voltar}
                                action={() => history.push(`/olts`)}
                                color="secondary"
                                children={defaultText.btn.voltar}
                                size="medium"
                            />
                        </div>
                    </div>
                </Row>
            </div>

            {modalSFP(statusModal, 0)}
        </div>
    </>
    );
};

export default InformacoesOlt;

const OltInfoArea = ({ defaultText, data }) => {
    return (
        <Row>
            <div className="col-md-1">
                <label>{defaultText.inputs.oltModelo}</label>
                <p>{data.modelo}</p>
            </div>
            <div className="col-md-4">
                <label>{defaultText.inputs.oltSoftware}</label>
                <p>{data.softwareVersion}</p>
            </div>
            <div className="col-md-2">
                <label>{defaultText.inputs.oltHardware}</label>
                <p>{data.hardwareVersion}</p>
            </div>
            <div className="col-md-2">
                <label>{defaultText.inputs.oltBootrom}</label>
                <p>{data.bootromVersion}</p>
            </div>
            <div className="col-md-2">
                <Button
                    hasAccess={getPagePermissions(permissions, "olts", "create")}
                    id="btnAdicionar"
                    titulo={defaultText.btn.atualizarFirmwareOlt}
                    action={() => dispatch(abrirModalCriarOlt())}
                    texto={defaultText.btn.atualizarFirmwareOlt}
                    children={<DownloadIcon title={defaultText.btn.atualizarFirmwareOlt} />}
                    color='success'
                    type='btn'
                />
            </div>
        </Row>
    );
};