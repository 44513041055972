import React from 'react';
import { resetarCamposAction, validarAction, validarCamposAction } from '../../../validation/validationActions'

import axios from 'axios'
import { toast } from 'react-toastify'
import { CorrectIcon } from '../../../layouts/components/icons'
import { IcoInfo } from '../../../components/icones';

const idiomaText = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userLocales = user.preferencia.linguagem;
  const locales = userLocales ? (userLocales === 'EN_US' ? 'en-US' : 'pt-BR') : null;
  const defaltText = require(`../nls/${locales ? locales : 'pt-BR'}.json`);
  return defaltText.mensagem;
};

export const ajustModulos = e => {
  const locales = document.getElementById('idioma')
  const defaltText = require(`../nls/${locales.value}.json`)
  switch (e) {
    case 'ASSINANTES':
      return { id: e, nome: defaltText.modulos.assinantes }
    case 'COLABORADORES':
      return { id: e, nome: defaltText.modulos.colaboradores }
    case 'OLT':
      return { id: e, nome: defaltText.modulos.olt }
    case 'PERFIS':
      return { id: e, nome: defaltText.modulos.perfis }
    case 'PLANOS':
      return { id: e, nome: defaltText.modulos.planos }
    case 'SERVICOS':
      return { id: e, nome: defaltText.modulos.servicos }
    case 'SERVICOS_CONFIG':
      return { id: e, nome: defaltText.modulos.servicosConfig }
    default:
      return { id: e, nome: e }
  }
}

export const ATUALIZAR_CONTEUDO_PERFIL_MODAL = 'ATUALIZAR_CONTEUDO_PERFIL_MODAL'
export const atualizarConteudoPerfilModal = e => {
  if (e.target.type === 'multiselect') {
    const valor = { target: { name: e.target.name, value: [] } }

    Object.entries(e.target.value).forEach(([key, value]) => {
      valor.target.value.push(`${value.id ? value.id : value}`)
    })
    return {
      type: ATUALIZAR_CONTEUDO_PERFIL_MODAL,
      payload: valor
    }
  }
  return {
    type: ATUALIZAR_CONTEUDO_PERFIL_MODAL,
    payload: e
  }
}

export const SET_TIPO_MODAL_PERFIL = 'SET_TIPO_MODAL_PERFIL'
const setTipoModalPerfil = tipo => ({
  type: SET_TIPO_MODAL_PERFIL,
  payload: tipo
})

export const SET_PERFIL = 'SET_PERFIL'
const setPerfil = perfil => ({
  type: SET_PERFIL,
  payload: perfil
})

export const SET_PERFIL_INICIAL = 'SET_PERFIL_INICIAL'
const setPerfilInicial = () => ({
  type: SET_PERFIL_INICIAL
})

export const ABRIR_MODAL_CRIAR_PERFIL = 'ABRIR_MODAL_CRIAR_PERFIL'
export const abrirModalCriarPerfil = () => [resetarCamposAction(), setPerfilInicial(), setTipoModalPerfil('criando')]

export const ABRIR_MODAL_EDITAR_PERFIL = 'ABRIR_MODAL_EDITAR_PERFIL'
export const abrirModalEditarPerfil = perfil => [
  resetarCamposAction(),
  setPerfil(perfil),
  setTipoModalPerfil('editando')
]

export const ABRIR_MODAL_DELETAR_PERFIL = 'ABRIR_MODAL_DELETAR_PERFIL'
export const abrirModalDeletarPerfil = perfil => [setPerfil(perfil), setTipoModalPerfil('deletando')]

export const ABRIR_MODAL_INFORMACAO = 'ABRIR_MODAL_INFORMACAO'
export const abrirModalInformacaoPerfil = perfil => [setPerfil(perfil), setTipoModalPerfil('informacoes')]

export const FECHAR_MODAL_PERFIL = 'FECHAR_MODAL_PERFIL'
export const fecharModalPerfil = () => [setPerfilInicial(), setTipoModalPerfil('')]

export const LIMPAR_CAMPO_PESQUISA_PERFIL = 'LIMPAR_CAMPO_PESQUISA_PERFIL'
export const limparCampoPesquisa = () => [
  {
    type: LIMPAR_CAMPO_PESQUISA_PERFIL
  },
  listarPerfis()
]

export const ATUALIZAR_CAMPO_PESQUISA_PERFIL = 'ATUALIZAR_CAMPO_PESQUISA_PERFIL'
export const atualizarCampoPesquisa = e => ({
  type: ATUALIZAR_CAMPO_PESQUISA_PERFIL,
  payload: e.target.value
})

export const CARREGANDO_PERMISSOES = 'CARREGANDO_PERMISSOES'
const carregandoPermissoes = () => ({
  type: CARREGANDO_PERMISSOES
})

export const PERMISSOES_CARREGADAS_COM_SUCESSO = 'PERMISSOES_CARREGADAS_COM_SUCESSO'
const permissoesCarregadosComSuceso = perfis => ({
  type: PERMISSOES_CARREGADAS_COM_SUCESSO,
  payload: perfis
})

export const PERMISSOES_CARREGADAS_COM_ERRO = 'PERMISSOES_CARREGADAS_COM_ERRO'
const permissoesCarregadosComErro = erro => ({
  type: PERMISSOES_CARREGADAS_COM_ERRO,
  payload: erro
})
export const listarPermissoes = () => {
  return dispatch => {
    dispatch(carregandoPermissoes())
    axios
      .get('/api/permissao/obterPermissoes')
      .then(response => dispatch(permissoesCarregadosComSuceso(response.data)))
      .catch(error => {
        dispatch(permissoesCarregadosComErro(error))
        toast.error((idiomaText().listarPermissoesErro), {
          icon: <div className='divIconToastError'><IcoInfo /></div>
        })
      })
  }
}

export const CARREGANDO_PERFIS = 'CARREGANDO_PERFIS'
const carregandoPerfis = () => ({
  type: CARREGANDO_PERFIS
})

export const PERFIS_CARREGADOS_COM_SUCESSO = 'PERFIS_CARREGADOS_COM_SUCESSO'
const perfisCarregadosComSuceso = perfis => ({
  type: PERFIS_CARREGADOS_COM_SUCESSO,
  payload: perfis
})

export const PERFIS_CARREGADOS_COM_ERRO = 'PERFIS_CARREGADOS_COM_ERRO'
const perfisCarregadosComErro = erro => ({
  type: PERFIS_CARREGADOS_COM_ERRO,
  payload: erro
})

export const listarPerfis = e => {
  const params = {
    pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
    pesquisa: `&nome=${e && e.pesquisa ? e.pesquisa : ''}`
  }
  return dispatch => {
    dispatch(listarPermissoes())
    dispatch(carregandoPerfis())
    axios
      .get(`/api/perfil/pesquisarPerfilPorNome?${params.pagina + params.pesquisa}`)
      .then(response => dispatch(perfisCarregadosComSuceso(response.data)))
      .catch(error => {
        dispatch(perfisCarregadosComErro(error))
        toast.error((idiomaText().listarPerfisErro), {
          icon: <div className='divIconToastError'><IcoInfo /></div>
        })
      })
  }
}

export const ADICIONANDO_PERFIL = 'ADICIONANDO_PERFIL'
const adicionandoPerfil = () => ({
  type: ADICIONANDO_PERFIL
})

export const PERFIL_ADICIONADO_COM_SUCESSO = 'PERFIL_ADICIONADO_COM_SUCESSO'
const perfilAdicionadoComSucesso = perfil => ({
  type: PERFIL_ADICIONADO_COM_SUCESSO,
  payload: perfil
})

export const PERFIL_NAO_ADICIONADO = 'PERFIL_NAO_ADICIONADO'
const perfilNaoAdicionado = erro => ({
  type: PERFIL_NAO_ADICIONADO,
  payload: erro
})

const perfilRequired = {
  nome: '',
  permissoes: ''
}

export const adicionarPerfil = perfil => {
  const perfilJson = perfil
  const perfilVal = {
    formRequired: perfilRequired,
    formValues: perfilJson
  }
  return dispatch => {
    const mensagemDeErro = validarCamposAction(perfilVal)
    if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro)
    dispatch(fecharModalPerfil())
    dispatch(adicionandoPerfil())
    axios
      .post('/api/perfil/adicionarPerfil', perfilJson)
      .then(response => {
        dispatch(perfilAdicionadoComSucesso(response.data))
        toast.success((idiomaText().adicionarSuccess), {
          icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
        })
      })
      .catch(erro => {
        dispatch(perfilNaoAdicionado(erro))
        toast.error((idiomaText().adicionarErro), {
          icon: <div className='divIconToastError'><IcoInfo /></div>
        })
      })
  }
}

export const ATUALIZANDO_PERFIL = 'ATUALIZANDO_PERFIL'
const atualizandoPerfil = () => ({
  type: ATUALIZANDO_PERFIL
})

export const PERFIL_ATUALIZADO_COM_SUCESSO = 'PERFIL_ATUALIZADO_COM_SUCESSO'
const perfilAtualizadoComSucesso = perfil => ({
  type: PERFIL_ATUALIZADO_COM_SUCESSO,
  payload: perfil
})

export const PERFIL_NAO_ATUALIZADO = 'PERFIL_NAO_ATUALIZADO'
const perfilNaoAtualizado = erro => ({
  type: PERFIL_NAO_ATUALIZADO,
  payload: erro
})

export const atualizarPerfil = perfil => {
  const perfilJson = perfil
  const perfilVal = {
    formRequired: perfilRequired,
    formValues: perfilJson
  }

  return dispatch => {
    const mensagemDeErro = validarCamposAction(perfilVal)
    if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro)
    dispatch(fecharModalPerfil())
    dispatch(atualizandoPerfil())
    axios
      .put('/api/perfil/editarPerfil', perfilJson)
      .then(response => {
        dispatch(perfilAtualizadoComSucesso(response.data))
        toast.success((idiomaText().atualizarSuccess), {
          icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
        })
      })
      .catch(erro => {
        dispatch(perfilNaoAtualizado(erro))
        toast.error((idiomaText().atualizarErro), {
          icon: <div className='divIconToastError'><IcoInfo /></div>
        })
      })
  }
}

export const DELETANDO_PERFIL = 'DELETANDO_PERFIL'
const deletandoPerfil = () => ({
  type: DELETANDO_PERFIL
})

export const PERFIL_DELETADO_COM_SUCESSO = 'PERFIL_DELETADO_COM_SUCESSO'
const perfilDeletadoComSucesso = perfil => ({
  type: PERFIL_DELETADO_COM_SUCESSO,
  payload: perfil
})

export const PERFIL_NAO_DELETADO = 'PERFIL_NAO_DELETADO'
const perfilNaoDeletado = erro => ({
  type: PERFIL_NAO_DELETADO,
  payload: erro
})

export const deletarPerfil = perfil => {
  return dispatch => {
    dispatch(fecharModalPerfil())
    dispatch(deletandoPerfil())
    axios
      .delete(`/api/perfil/removerPerfil/${perfil.id}`)
      .then(() => {
        dispatch(perfilDeletadoComSucesso(perfil.id))
        toast.success((idiomaText().deletarSuccess), {
          icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
        })
      })
      .catch(erro => {
        dispatch(perfilNaoDeletado(erro))
        toast.error((idiomaText().deletarErro), {
          icon: <div className='divIconToastError'><IcoInfo /></div>
        })
      })
  }
}
