import React from 'react';
import { useSelector } from 'react-redux';
import Deletar from './deletarNotificacao/notificacaoDeletar';
import Informacao from './informacoes/informacoes';

function ModalNotificacao() {
    const statusModal = useSelector(state => state.notificacoesState.statusModal);

    switch (statusModal) {
        case 'deletando':
            return <Deletar />;
        case 'informacao':
            return <Informacao />;
        default:
            return null;
    };
}

export default ModalNotificacao;