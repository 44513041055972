import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IcoIntelbras, SigCloudLogo } from '../../../components/icones';
import { InputInboxSenha, SelectInBox } from '../../../components/form';
import Row from '../../../components/row/row';
import Button from '../../../components/button/Button';

import { ajustIdioma, listarIdiomas } from '../../../layouts/redux/LayoutActions';
import { enviarEmailEsqueciSenha, resetarRedefinirSenha } from '../login/redux/UserActions';
import { resetarCamposAction, validarCampoAction } from '../../../validation/validationActions';

import InputInbox from '../../../components/form/inputInBox/InputInbox';

import '../usuario.css';
import './esqueciSenha.css';
import ThemeSelector from '../../../components/ThemeSelector/ThemeSelector';
import CustomSelect from '../../../layouts/components/Select/Select';
import Input from '../../../layouts/components/Input/Input';
import { UserIcon } from '../../../layouts/components/icons';

const EsqueciSenha = ({ history }) => {
    const dispatch = useDispatch();
    const [preferencia, setPreferencia] = useState([]);
    const listarPreferencias = useSelector(state => state.layoutState);
    const emailEnviado = useSelector(state => state.authentication.emailEnviado);
    const emailRecuperaSenha = useSelector(state => state.authentication.emailRecuperaSenha);
    const mensagemErro = useSelector(state => state.validacaoState);
    const defaltText = preferencia.linguagem
        ? require(`../nls/${preferencia.linguagem}.json`)
        : require(`../nls/pt-BR.json`);

    const [inputs, setInputs] = useState({
        email: ''
    });

    const handleChange = e => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    };
    const handleClearField = (e) => {
        setInputs({ ...inputs, [e.target.name]: "" });
    };
    const handleChangePreferencia = e => {
        setPreferencia({
            ...preferencia,
            [e.target.name]: e.target.value.use
        });
    };
    const handlePressEnter = (e) => {
        if (e.key === "Enter" && inputs.email !== "") {
            dispatch(enviarEmailEsqueciSenha(inputs.email));
        }
    };

    useEffect(() => {
        dispatch(resetarCamposAction());
        dispatch(resetarRedefinirSenha());
        dispatch(listarIdiomas());
    }, [dispatch]);

    return (
        <div id="login-bg" className="auth-recover-container">
            <div className="content first-content">
                {/* <div className='box-flutuante-conteudo'>


                    <div className='box-idioma'>
                        <SelectInBox
                            id='linguagem'
                            label={defaltText.linguagem}
                            selectDefault={{
                                id: 'id',
                                label: 'nome'
                            }}
                            select={{
                                action: e => handleChangePreferencia(e),
                                options: listarPreferencias.linguagens,
                                selected: ajustIdioma(preferencia.linguagem)
                            }}
                        />

                        <ThemeSelector
                            label={defaltText.inputs.tema}
                        />
                    </div>
                    {!emailEnviado ?

                        <div>
                            <h3>{defaltText.titleEsqueciSenha}</h3>
                            <Row>
                                <div className='col-md-12'>
                                    <InputInbox
                                        name='email'
                                        label={defaltText.inputs.email}
                                        value={inputs.email}
                                        changeInput={e => handleChange(e)}
                                        onPressEnter={(e) => handlePressEnter(e)}
                                        validate={{
                                            action: e => dispatch(validarCampoAction(e)),
                                            erro: mensagemErro && mensagemErro.email ? defaltText.mensagem.obrigatorio : ''
                                        }}
                                        length={{
                                            max: '50'
                                        }}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className='col-md-12 borda'>
                                    <Button
                                        id='btnEnviar'
                                        titulo={defaltText.btn.enviar}
                                        //NOVO
                                        action={() => dispatch(enviarEmailEsqueciSenha(inputs.email))}
                                        color='primary'
                                        texto={defaltText.btn.enviar}
                                    />
                                </div>
                            </Row>
                        </div> :
                        <div>
                            <h3>{defaltText.titleEmailEnviado}</h3>
                            <div className='texto-email-enviado'>
                                {defaltText.emailEnviado1}{emailRecuperaSenha}{defaltText.emailEnviado2}
                            </div>

                        </div>
                    }

                </div> */}
                <div className="content-header">
                    <div className="logo">
                        <IcoIntelbras style={{ width: "165", height: "35" }} />
                    </div>

                    <div className="configs">
                        <div className="language-config">
                            <SelectInBox
                                id='linguagem'
                                /* label={defaltText.linguagem} */
                                selectDefault={{
                                    id: 'id',
                                    label: 'nome',
                                    minWidth: '100%',
                                }}
                                select={{
                                    action: (e) => handleChangePreferencia(e),
                                    options: listarPreferencias.linguagens,
                                    selected: ajustIdioma(preferencia.linguagem),
                                }}
                            />
                        </div>
                        <div className="theme-config">
                            <ThemeSelector
                                label={defaltText.inputs.tema}
                            />
                        </div>
                    </div>
                </div>

                <div className="second-column">
                    {!emailEnviado ? (
                        <div className="form">
                            <SigCloudLogo />
                            <h5>{defaltText.titleEsqueciSenha}</h5>
                            <Input
                                id='emailEsqueciSenha'
                                name='email'
                                label={defaltText.inputs.email}
                                value={inputs.email}
                                changeInput={e => handleChange(e)}
                                onPressEnter={(e) => handlePressEnter(e)}
                                validate={{
                                    action: e => dispatch(validarCampoAction(e)),
                                    erro: mensagemErro && mensagemErro.email ? defaltText.mensagem.obrigatorio : ''
                                }}
                                length={{
                                    max: '50'
                                }}
                                clearField={{
                                    action: (e) => handleClearField(e),
                                    text: "Limpar"
                                }}
                                leftButton={{
                                    action: () => { },
                                    icon: <UserIcon />
                                }}
                            />
                            <button onClick={() => dispatch(enviarEmailEsqueciSenha(inputs.email))} className="btn">{defaltText.btn.enviar}</button>

                            <div>
                                <p className="texto-fora">{defaltText.temLoginSenha}
                                    <span onClick={() => history.push('/login')} className='link-logar bold-text'>
                                        {defaltText.entreAqui}
                                    </span>
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="form email-sended">
                            <h5>{defaltText.titleEmailEnviado}</h5>
                            <div className='texto-email-enviado'>
                                <p>{defaltText.emailEnviado1}<span className="bold-text">{emailRecuperaSenha}</span>{defaltText.emailEnviado2}</p>
                            </div>

                            <div>
                                <p className="texto-fora">{defaltText.temLoginSenha}
                                    <span onClick={() => history.push('/login')} className='link-logar bold-text'>
                                        {defaltText.entreAqui}
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default EsqueciSenha;
