import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/button/Button';
import Checkbox from '../../../components/form/checkbox/Checkbox';
import { DownloadIcon } from '../../../components/icones';
import BasicModal from '../../../components/modal/BasicModal';
import { downloadAdapterWindow } from '../../locais/redux/LocaisActions';
import { fecharModalAdapter } from '../redux/OltActions';

const ModalAdapter = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    
    const checkedNovoAdapter = localStorage.getItem('checkedNovoAdapter') === "false" ? false : true;
    const firstLogin = localStorage.getItem('firstLogin') === "false" ? false : true;

    const [checkAdapter, setCheckAdapter] = useState({
        checkNovoAdapter: false,
    });

    const handleCheckValue = (e) => {
        setCheckAdapter({
            [e.target.name]: !checkAdapter[e.target.name]
        });
    };

    return (
        <BasicModal
            //close={() => dispatch(fecharModalAdapter())}
            close={() => [
                checkAdapter.checkNovoAdapter === true ? localStorage.setItem('checkedNovoAdapter', JSON.stringify(true)) : '',
                checkAdapter.checkNovoAdapter === true ? localStorage.setItem('ask', "14/08/2023") : '',
                dispatch(fecharModalAdapter())
            ]}
            containerModal={{
                title: defaultText.modalAdapterTittle,
                size: 'medium',
                closeText: defaultText.btn.cancelar,
                //closeText: defaultText.btn.fechar,
                content:
                    <> 
                        <div className='createLocationText'>
                            {/* <p>
                                {defaultText.mensagem.messageAdapterModal}
                                <span className="hintAdapter">
                                    {defaultText.mensagem.hintAdapter}
                                    <span className="tooltip">{defaultText.mensagem.tooltip}</span>
                                </span>
                                {defaultText.mensagem.messageAdapterModalContinue}
                            </p> */}
                            {checkedNovoAdapter === false && firstLogin === false || (checkedNovoAdapter === true && localStorage.getItem('ask') != "14/08/2023")?
                                <>
                                    <p>
                                        {defaultText.mensagem.novoAdapter}
                                        <span className="hintAdapter">
                                            {defaultText.mensagem.hintAdapter}
                                            <span className="tooltip">{defaultText.mensagem.tooltip}</span>
                                        </span>
                                        <span>{defaultText.mensagem.messageNovoAdapterContinue}</span>
                                    </p>
                                    <br></br>
                                    <Checkbox
                                        name="checkNovoAdapter"
                                        text={defaultText.mensagem.concordoAdapterNovo}
                                        checked={checkAdapter.checkNovoAdapter}
                                        value={checkAdapter.checkNovoAdapter}
                                        changeInput={(e) => [
                                            handleCheckValue(e),
                                           // localStorage.setItem('checkedNovoAdapter', JSON.stringify(!checkedNovoAdapter))
                                        ]}
                                    />
                                </>
                                :
                                <p>
                                    {defaultText.mensagem.messageAdapterModal}
                                    <span className="hintAdapter">
                                        {defaultText.mensagem.hintAdapter}
                                        <span className="tooltip">{defaultText.mensagem.tooltip}</span>
                                    </span>
                                    {defaultText.mensagem.messageAdapterModalContinue}
                                </p>} 
                        </div>
                        <br></br>
                        <span>{defaultText.mensagem.messageInstalarNovoAdapter}</span>
                        <div className="downloadModalAdapter">
                            <div>
                                <Button
                                    id='btnDownloadIcon'
                                    value='campoDownload'
                                    titulo={defaultText.downloadWindows}
                                    action={() => [downloadAdapterWindow('windows'), dispatch(fecharModalAdapter())]}
                                    color='primary'
                                    type='btn'
                                    children={<DownloadIcon />}
                                />
                                <p>Windows</p>
                                <p>v4.1.1-ST</p>
                            </div>
                            {/* <span></span>
                            <div>
                                <Button
                                    id='btnDownload'
                                    value='campoDownload'
                                    titulo={defaultText.downloadLinux}
                                    action={() => [dispatch(baixarAdapter('linux')), dispatch(fecharModalAdapter())]}
                                    type='btn'
                                    children={<DownloadIcon />}
                                />
                                <p>Linux</p>
                            </div> */}
                        </div>
                    </>
            }}
        />
    );
};

export default ModalAdapter;