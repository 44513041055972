import React from 'react';

const Up = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <title>{props.title ? props.title : null}</title>
            <path id="Icon_awesome-arrow-alt-circle-up" data-name="Icon awesome-arrow-alt-circle-up" d="M.563,7.562a7,7,0,1,1,7,7A7,7,0,0,1,.563,7.562ZM8.8,10.837V7.562h2a.339.339,0,0,0,.24-.579L7.8,3.758a.336.336,0,0,0-.477,0L4.079,6.984a.339.339,0,0,0,.24.579h2v3.274a.34.34,0,0,0,.339.339H8.466A.34.34,0,0,0,8.8,10.837Z" transform="translate(-0.563 -0.563)" fill="#00a335" />
        </svg>
    );
};

export default Up;