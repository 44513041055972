import {
    ADICIONANDO_OLT, ADICIONANDO_VLAN, ATUALIZANDO_OLT, ATUALIZANDO_VLAN, ATUALIZAR_CAMPOS_VLAN, ATUALIZAR_CAMPO_PESQUISA_OLT, ATUALIZAR_CONTEUDO_OLT_MODAL, ATUALIZAR_OPERATION_MODE_OLT, CARREGANDO_CPES, CARREGANDO_OLTS, CLEAR_CRIAR_EDITAR_OLT_FIELD, CPES_CARREGADOS_COM_ERRO,
    CPES_CARREGADOS_COM_SUCESSO, DELETANDO_OLT, DELETANDO_VLAN_INTERFACE, FECHAR_SEGUNDA_MODAL, FECHAR_TODAS_MODAL, HANDLE_ACCEPTED_MODE_CHANGE_TERMS_STATE, HANDLE_IS_LOADING_OLTS_STATUS, HANDLE_IS_LOADING_VLANS_STATUS, INTERFACES_CARREGADAS_COM_ERRO, INTERFACES_CARREGADAS_COM_SUCESSO, IS_SEARCH, LIMPAR_CAMPO_PESQUISA_OLT, LIMPAR_VLANS_DA_INTERFACE, MAIS_CPES_CARREGADOS_COM_SUCESSO,
    MAIS_OLTS_CARREGADOS_COM_SUCESSO, MODO_INTERFACE_ALTERADO_COM_SUCESSO, OLTS_ATUALIZADAS_COM_SUCESSO, OLTS_CARREGADOS_COM_ERRO, FETCH_OLT_DASHBOARD,
    OLTS_CARREGADOS_COM_SUCESSO, OLT_ADICIONADO_COM_SUCESSO, OLT_ATUALIZADO_COM_SUCESSO, OLT_CARREGADA_COM_ERRO, OLT_CARREGADA_COM_SUCESSO, OLT_DELETADO_COM_SUCESSO, OLT_NAO_ADICIONADO, OLT_NAO_ATUALIZADO, OLT_NAO_DELETADO, OLT_NAO_REINICIADA, OLT_REINICIADO_COM_SUCESSO, REINICIANDO_OLT, RESET_ACCEPTED_MODE_CHANGE_TERMS, RESET_OLT_STATE, SET_INTERFACE, SET_MODE_TAG_ACCESS, SET_MODO_ESCOLHIDO_INTERFACE, SET_OLT, SET_OLT_E_INTERFACE, SET_OLT_INICIAL, SET_SELECTED_VLAN, SET_SFP_INICIAL, SET_TIPO_MODAL, SET_VLAN, SET_VLAN_INICIAL, SFP_DA_INTERFACE_CARREGADA_COM_ERRO, SFP_DA_INTERFACE_CARREGADA_COM_SUCESSO, VLANS_CARREGADAS_COM_ERRO, VLANS_CARREGADAS_COM_SUCESSO, ATUALIZAR_VLAN_COM_SUCESSO, VLANS_DA_INTERFACE_CARREGADAS_COM_ERRO, VLAN_ADICIONADA_COM_SUCESSO, VLAN_INTERFACE_DELETADO_COM_SUCESSO,
    VLAN_INTERFACE_NAO_DELETADO, VLAN_NAO_ADICIONADA
} from './OltActions';

const oltInicial = {
    nome: '',
    ip: '',
    porta: '',
    usuario: '',
    senha: '',

    telnetChecked: true,
    protocol: 'telnet',
    telnetUser: "admin",
    telnetPassword: "admin",
    telnetPort: 23,

    sshChecked: false,
    sshUser: "admin",
    sshPassword: "admin",
    sshPort: 22,

    snmpChecked: false,
    snmpReadingCommunity: "public",
    snmpWritingCommunity: "private",
    snmpPort: 161,
    portas10GE: [],
    portasE: [],
    portasG: [],

    //operationModes: {},
    operationMode: "",
    indexVlan: "1000",
    uplinkAutoConfig: true
};

const paginacaoDefault = {
    totalPages: 0,
    totalElements: 0,
    pageNumber: 0,
};
const vlanInitial = {
    numeroVlan: '',
    idOlt: '',
    portaNome: '',
    descricao: '',
    taggedVlan: true,
    untaggedVlan: false,
    isPVID: true,
    type: 'uplink'
};
const initialState = {
    campoPesquisa: '',
    statusModal: [],
    isLoading: false,
    isLoadingVlans: false,
    olts: [],
    paginacao: paginacaoDefault,
    olt: oltInicial,
    cpes: [],
    statusDataOlt: [],
    paginacaoCpes: paginacaoDefault,
    previousSelectedInterface: {},
    selectedInterface: '',
    selectedVlan: {},
    sfpInfo: '',
    objInterfaceListE: [],
    objInterfaceList: [],
    vlans: {
        tagVlans: [],
        utVlans: []
    },
    //Mudar quando integrar tabelas de portas e vlans do back
    allVlans: [],
    vlan: vlanInitial,
    acceptedModeChangeTerms: false,
    isSearch: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CARREGANDO_OLTS:
            return { ...state };
        case OLTS_CARREGADOS_COM_ERRO:
            return { ...state };

        case VLANS_CARREGADAS_COM_ERRO:
            return { ...state };
        case OLTS_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                olts: payload.content ? payload.content : payload,
                paginacao: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number,
                },
                isLoading: false
            };

        case CARREGANDO_CPES:
            return { ...state };
        case CPES_CARREGADOS_COM_ERRO:
            return { ...state };
        case CPES_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                cpes: payload.content ? payload.content : payload,
                paginacaoCpes: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number,
                },
            };
        case MAIS_CPES_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                cpes: state.cpes.concat(payload.content ? payload.content : payload),
                paginacaoCpes: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number,
                },
            };

        case MAIS_OLTS_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                olts: state.olts.concat(payload.content ? payload.content : payload),
                paginacao: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number,
                }
            };


        case ADICIONANDO_OLT:
            return { ...state };

        case ADICIONANDO_VLAN:
            return { ...state };

        case VLAN_NAO_ADICIONADA:
            return { ...state, descricaoErro: payload };

        case OLT_NAO_ADICIONADO:
            return { ...state, descricaoErro: payload };

        case OLT_ADICIONADO_COM_SUCESSO:
            return { ...state, olts: [...state.olts, payload] };

        case VLAN_ADICIONADA_COM_SUCESSO:
            return {
                ...state,
                allVlans: [
                    ...state.allVlans,
                    payload
                ]
            };

        case ATUALIZANDO_OLT:
            return { ...state };
        case ATUALIZANDO_VLAN:
            return { ...state };
        case OLT_NAO_ATUALIZADO:
            return { ...state };
        case OLT_ATUALIZADO_COM_SUCESSO:
            return {
                ...state,
                olts: state.olts.map((olt) => (olt.id === payload.id ? payload : olt)),
            };

        case DELETANDO_OLT:
            return { ...state, statusModal: [] };
        case OLT_NAO_DELETADO:
            return { ...state };
        case OLT_DELETADO_COM_SUCESSO:
            return {
                ...state,
                olts: state.olts.filter((olt) => olt.id !== payload),
            };

        case DELETANDO_VLAN_INTERFACE:
            return { ...state };
        case VLAN_INTERFACE_NAO_DELETADO:
            return { ...state };
        case VLAN_INTERFACE_DELETADO_COM_SUCESSO:
            return {
                ...state,
                allVlans: state.allVlans.filter((vlan) => vlan.id !== payload.id),
            };

        case SET_TIPO_MODAL:
            if (payload === '' && state["statusModal"].length === 1) {
                return { ...state, statusModal: [] };
            }
            else if (payload === '') {
                return {
                    ...state,
                    statusModal: state.statusModal.filter((value) => value !== state.statusModal[state.statusModal.length - 1])
                };
            }
            else {
                return { ...state, statusModal: [...state.statusModal, payload] };
            }

        case SET_OLT:
            return { ...state, olt: payload };

        case SET_OLT_INICIAL:
            return { ...state, olt: oltInicial };

        case SET_VLAN_INICIAL:
            return { ...state, vlan: vlanInitial };
        case SET_VLAN:
            return { ...state, vlan: payload };
        case ATUALIZAR_CAMPO_PESQUISA_OLT:
            return { ...state, campoPesquisa: payload };

        case LIMPAR_CAMPO_PESQUISA_OLT:
            return {
                ...state,
                campoPesquisa: '',
                isSearch: false
            };

        case ATUALIZAR_CONTEUDO_OLT_MODAL:
            var caseProtocol = state.olt.telnetChecked;
            var port = state.olt.telnetPort
            if (payload.target.name === "protocol") {
                caseProtocol = payload.target.value === "telnet" ? true : false
                port = caseProtocol ? 23 : 22
            }
            return {
                ...state,
                olt: {
                    ...state.olt,
                    telnetPort: port,
                    usuario: payload.target.name === "telnetUser" ? payload.target.value : state.olt.telnetUser, //remover após alinhamento com backend
                    senha: payload.target.name === "telnetPassword" ? payload.target.value : state.olt.telnetPassword, //remover após alinhamento com backend
                    telnetChecked: caseProtocol,
                    sshChecked: !caseProtocol,
                    [payload.target.name]: payload.target.type === "checkbox" ? payload.target.checked : payload.target.value,
                },
            };

        case ATUALIZAR_CAMPOS_VLAN:
            if (payload.e.target.type === "number" || payload.e.target.type === "text") {
                if (payload.e.target.id === "editar") {
                    return {
                        ...state,
                        selectedVlan: {
                            ...state.selectedVlan,
                            [payload.e.target.name]: payload.e.target.value
                        }
                    };
                } else {
                    return {
                        ...state,
                        vlan: {
                            ...state.vlan,
                            [payload.e.target.name]: payload.e.target.value
                        }
                    };
                }
            }
            else if (payload.e.target.type === "radio") {
                if (payload.e.target.name === "tipo") {
                    return {
                        ...state,
                        vlan: {
                            ...state.vlan,
                            type: payload.e.target.value
                        }
                    };
                }
                return {
                    ...state,
                    vlan: {
                        ...state.vlan,
                        taggedVlan: payload.e.target.value === "TAGGED" ? true : false,
                        untaggedVlan: payload.e.target.value === "TAGGED" ? false : true,
                    }
                };
            }
            else {
                const novo_state = {
                    ...state,
                    vlan: {
                        ...state.vlan,
                        [payload.e.target.name]: payload.e.target.checked,
                        untaggedVlan: true ? true : false,
                    }
                };
                return {
                    ...state,
                    vlan: {
                        ...state.vlan,
                        taggedVlan: payload.e.target.checked,
                        untaggedVlan: payload.e.target.checked ? false : true,
                    }
                };
            }

        case REINICIANDO_OLT:
            return { ...state };

        case OLT_REINICIADO_COM_SUCESSO:
            return { ...state, olts: state.olts.map((olt) => (olt.id === payload.id ? payload : olt)) };

        case OLTS_ATUALIZADAS_COM_SUCESSO:
            if (typeof payload === "object") {
                if (state.olts.find((olt) => olt.id === payload.id) !== undefined) {
                    return {
                        ...state,
                        olts: state.olts.map((olt) => (olt.id === payload.id ? payload : olt)),
                        olt: state.olt.id === payload.id ? {
                            ...state.olt,
                            progressoModoDeOperacao: (state.olt.id === payload.id) ? payload.progressoModoDeOperacao : state.olt.progressoModoDeOperacao,
                            upTime: (state.olt.id === payload.id) ? payload.upTime : state.olt.upTime
                        } : {
                            ...state.olt,
                        }
                    };
                }
                return state;
                /* else {
                    if ((state.paginacao.totalElements + 1) / (12 * state.paginacao.totalPages) > 1) {
                        return {
                            ...state,
                            paginacao: {
                                ...state.paginacao,
                                totalElements: state.paginacao.totalElements + 1,
                                totalPages: state.paginacao.totalPages + 1
                            }
                        };
                    }
                    return {
                        ...state,
                        olts: [...state.olts, payload],
                        paginacao: {
                            ...state.paginacao,
                            totalElements: state.paginacao.totalElements + 1
                        }
                    };
                } */
            }
            else {
                return {
                    ...state,
                    olts: state.olts.map((olt) => {
                        if (olt.id === payload) {
                            return {
                                ...olt,
                                status: "DELETADO"
                            };
                        }
                        else {
                            return olt;
                        }
                    }),
                    /* paginacao: {
                        ...state.paginacao,
                        totalElements: state.paginacao.totalElements - 1,
                        totalPages: ((state.paginacao.totalElements - 1) / (12 * (state.paginacao.totalPages - 1)) > 1)
                            ? state.paginacao.totalPages
                            : state.paginacao.totalPages - 1
                    } */
                };
            }

        case OLT_NAO_REINICIADA:
            return { ...state };

        case ATUALIZAR_OPERATION_MODE_OLT:
            return {
                ...state,
                olt: {
                    ...state.olt,
                    operationMode: payload
                }
            };

        case INTERFACES_CARREGADAS_COM_ERRO:
            return { ...state };
        case INTERFACES_CARREGADAS_COM_SUCESSO:
            return {
                ...state,
                objInterfaceListE: payload.objInterfaceListE,
                objInterfaceList: payload.objInterfaceList
            };
        case SET_INTERFACE:
            return {
                ...state,
                selectedInterface: payload
            };
        case SET_MODO_ESCOLHIDO_INTERFACE:
            return {
                ...state,
                previousSelectedInterface: { ...state.selectedInterface },
                selectedInterface: {
                    ...state.selectedInterface,
                    mode: payload.mode
                }
            };

        case SET_OLT_E_INTERFACE:
            return {
                ...state,
                vlan: { ...state.vlan, idOlt: payload.idOlt, portaNome: payload.selectedInterface.nome }
            };

        case SFP_DA_INTERFACE_CARREGADA_COM_SUCESSO:
            return {
                ...state,
                sfpInfo: payload
            };
        case SFP_DA_INTERFACE_CARREGADA_COM_ERRO:
            return {
                ...state
            };
        case SET_SFP_INICIAL:
            return {
                ...state,
                sfpInfo: ''
            };
        case OLT_CARREGADA_COM_SUCESSO:
            return {
                ...state,
                olt: payload,
                isLoading: false
            };
        case OLT_CARREGADA_COM_ERRO:
            return {
                ...state
            };

        case VLANS_CARREGADAS_COM_SUCESSO:
            return {
                ...state,
                allVlans: payload,
                vlans: {
                    tagVlans: payload.filter((vlan) => vlan.taggedVlan === true),
                    utVlans: payload.filter((vlan) => vlan.untaggedVlan)
                },
                isLoadingVlans: false
            };

        case ATUALIZAR_VLAN_COM_SUCESSO:
            if (payload.taggedVlan) {
                return {
                    ...state,
                    vlans: {
                        ...state.vlans,
                        tagVlans: state.vlans.tagVlans.map((vlan) => {
                            if (vlan.portaNome === payload.portaNome &&
                                vlan.idOlt === payload.idOlt &&
                                vlan.numeroVlan === payload.numeroVlan) {
                                return payload
                            } else {
                                return vlan
                            }
                        })
                    }
                };
            } else {
                return {
                    ...state,
                    vlans: {
                        ...state.vlans,
                        utVlans: state.vlans.utVlans.map((vlan) => {
                            if (vlan.portaNome === payload.portaNome &&
                                vlan.idOlt === payload.idOlt &&
                                vlan.numeroVlan === payload.numeroVlan) {
                                return payload
                            } else {
                                return vlan
                            }
                        })
                    }
                };
            }

        case VLANS_DA_INTERFACE_CARREGADAS_COM_ERRO:
            return {
                ...state,
                descricaoErro: payload
            };

        case LIMPAR_VLANS_DA_INTERFACE:
            return {
                ...state,
                vlans: initialState.vlans
            };

        case MODO_INTERFACE_ALTERADO_COM_SUCESSO:
            return {
                ...state,
                selectedInterface: payload
            };
        case SET_SELECTED_VLAN:
            return {
                ...state,
                selectedVlan: payload
            };
        case RESET_OLT_STATE:
            return initialState;
        case HANDLE_IS_LOADING_OLTS_STATUS:
            return {
                ...state,
                isLoading: payload
            };
        case HANDLE_IS_LOADING_VLANS_STATUS:
            return {
                ...state,
                isLoadingVlans: payload
            };

        case CLEAR_CRIAR_EDITAR_OLT_FIELD:
            return {
                ...state,
                olt: {
                    ...state.olt,
                    porta: payload.currentTarget.name === "telnetPort" ? payload.currentTarget.value : state.olt.telnetPort, //remover após alinhamento com backend
                    usuario: payload.currentTarget.name === "telnetUser" ? payload.currentTarget.value : state.olt.telnetUser, //remover após alinhamento com backend
                    senha: payload.currentTarget.name === "telnetPassword" ? payload.currentTarget.value : state.olt.telnetPassword, //remover após alinhamento com backend
                    [payload.currentTarget.name]: "",
                }
            };
        case RESET_ACCEPTED_MODE_CHANGE_TERMS:
            return {
                ...state,
                acceptedModeChangeTerms: false
            };
        case HANDLE_ACCEPTED_MODE_CHANGE_TERMS_STATE:
            return {
                ...state,
                acceptedModeChangeTerms: !state.acceptedModeChangeTerms
            };
        case IS_SEARCH:
            return {
                ...state,
                isSearch: state.campoPesquisa !== "" ? true : false
            };
        case FECHAR_SEGUNDA_MODAL:
            return {
                ...state,
                statusModal: []
            };
        case SET_MODE_TAG_ACCESS:
            return {
                ...state,
                vlan: {
                    ...state.vlan,
                    taggedVlan: false,
                    untaggedVlan: true,
                }
            };
        case FECHAR_TODAS_MODAL:
            return { ...state, statusModal: initialState.statusModal };

        case FETCH_OLT_DASHBOARD:
            return {
                ...state,
                statusDataOlt: payload
            };
        default:
            return state;
    }
};
