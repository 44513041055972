import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect } from 'react';

import InputInbox from '../../../../components/form/inputInBox/InputInbox';

import RadioButton from '../../../../components/form/radioButton/RadioButton';
import { validarCampoAction } from '../../../../validation/validationActions';

import { MaskVlanId } from '../../../../components/mask/Mask';
import { atualizarCamposVlan, setModeTagAccess, setOltEInterface } from '../../redux/OltActions';

const ConteudoCriarEditarVlan = ({id}) => {

    const dispatch = useDispatch();

    const locales = useSelector((state) => state.layoutState.linguagem);
    const oltsState = useSelector(state => state.oltsState);
    //Criar state para vlan
    var portas = useSelector(state => state.oltsState.vlan.portas);
    const selectedInterface = useSelector(state => state.oltsState.selectedInterface);
    const mensagemErro = useSelector(state => state.validacaoState);
    const defaltText = require(`../../nls/${locales}.json`);
    const vlanModes = useSelector((state) => state.planosState.vlanModes);
    const idOlt = window.location.pathname.split('/')[3];
    const setOlteInterfacee = { selectedInterface, idOlt };

    useEffect(() => {
        handleChangeModeTag(oltsState.vlan);
        dispatch(setOltEInterface(setOlteInterfacee));
    }, [dispatch]);

    const modeInterface = selectedInterface.mode;

    const handleChangeModeTag = () => {
        if (modeInterface === "Access") {
            dispatch(setModeTagAccess());
        }
    };
    const typeModes = [
        { id: "uplink", text: "UPLINK" },
        { id: "tls", text: "TLS" },
        { id: "intralink", text: "INTRALINK" }
    ]
    return (
        <div className="modal-create-vlan">
            {id === "abrindoCriarVlan" ? 
                <InputInbox
                    name='numeroVlan'
                    label={defaltText.informacoes.vlanId}
                    value={oltsState.vlan.numeroVlan}
                    changeInput={e => [
                        e.target.value = MaskVlanId(e.target.value),
                        dispatch(atualizarCamposVlan(e, ''))
                    ]}
                    validate={{
                        action: e => dispatch(validarCampoAction(e)),
                        erro: mensagemErro.vlanId ? defaltText.mensagem.obrigatorio : ''
                    }}
                    length={{
                        max: '4'
                    }}
                />
            : null}
            <InputInbox
                name='descricao'
                label={defaltText.informacoes.descricao}
                value={id === "abrindoCriarVlan" ? oltsState.vlan.descricao : oltsState.selectedVlan.descricao}
                changeInput={e => dispatch(atualizarCamposVlan(e))}
                id={id}
                length={{
                    max: '80'
                }}
            />

            {id === "abrindoCriarVlan" ? 
                <> 
                    <RadioButton
                        name='taggedVlan'
                        changeInput={(e) => dispatch(atualizarCamposVlan(e))}
                        defaultChecked={selectedInterface.mode.toUpperCase() === 'ACCESS' ? oltsState.vlan.untaggedVlan : oltsState.vlan.taggedVlan}
                        selected={oltsState.vlan.taggedVlan ? "TAGGED" : "UNTAGGED"}
                        options={vlanModes}
                        disabled={selectedInterface.mode.toUpperCase() === 'ACCESS' || selectedInterface.mode.toUpperCase() === 'TRUNK' ? true : false}
                    />
                    {oltsState.olt.modelo === "OLT_8820I"
                        ? <RadioButton
                            name='tipo'
                            defaultChecked={typeModes[0].id}
                            changeInput={(e) => dispatch(atualizarCamposVlan(e))}
                            selected={oltsState.vlan?.type ? oltsState.vlan?.type : typeModes[0].id}
                            options={typeModes}
                        />
                        : null
                    }
                </>
            : null }
        </div>
        /* <div className='col-md-4 tagged'>
                                <Checkbox
                changeInput={(e) => dispatch(atualizarCamposVlan(e))}
                checked={oltsState.vlan.isPVID}
                name='isPVID'
                text={defaltText.informacoes.pvid}
            />
            <br></br>
            <CheckboxSwitch
                id={`taggedVlan`}
                name='taggedVlan'
                label={oltsState.vlan.taggedVlan ? defaltText.informacoes.tagged : defaltText.informacoes.untagged}
                checked={oltsState.vlan.taggedVlan}
                changeInput={(e) => dispatch(atualizarCamposVlan(e))}
            />
        </div> */
    );
};

export default ConteudoCriarEditarVlan;
