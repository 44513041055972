import {
    ATUALIZAR_CAMPO_PESQUISA_PLANO,
    LIMPAR_CAMPO_PESQUISA_PLANO,
    ATUALIZAR_CONTEUDO_PLANO_MODAL,
    ATUALIZAR_CONTEUDO_SERVICOS_PLANO_MODAL,
    PLANOS_CARREGADOS_COM_ERRO,
    PLANOS_CARREGADOS_COM_SUCESSO,
    CARREGANDO_PLANOS,
    ADICIONANDO_PLANO,
    PLANO_NAO_ADICIONADO,
    PLANO_ADICIONADO_COM_SUCESSO,
    ATUALIZANDO_PLANO,
    PLANO_NAO_ATUALIZADO,
    PLANO_ATUALIZADO_COM_SUCESSO,
    DELETANDO_PLANO,
    PLANO_NAO_DELETADO,
    PLANO_DELETADO_COM_SUCESSO,
    SET_TIPO_MODAL_PLANO,
    SET_PLANO,
    SET_PLANO_INICIAL,
    SET_TIPO_MODAL,
    ATUALIZAR_CONTEUDO_PPPOE_MODAL,
    ADICIONAR_SERVICO_AO_PLANO,
    SET_USUARIO_E_SENHA_PADRAO_PPPOE,
    ATUALIZAR_SERVICE_TYPES,
    ATUALIZAR_CONTEUDO_BRIDGE_MODAL,
    ATUALIZAR_CONTEUDO_WIFI_MODAL,
    ADICIONAR_CONFIGURACAO_AO_PLANO,
    SET_SSID_E_SENHA_PADRAO_WIFI,
    ATUALIZAR_CONFIGURATION_OPTIONS,
    DELETAR_SERVICO_OU_CONFIGURACAO,
    SET_SERVICO_OU_CONFIGURACAO_SELECIONADO,
    RESETAR_CAMPOS_MODAIS,
    RESETAR_CAMPOS_PLANO,
    HANDLE_IS_LOADING_PLANS_STATUS,
    CLEAR_PLANO_FIELD,
    CLEAR_BRIDGE_FIELD,
    CLEAR_PPPOE_FIELD,
    CANCEL_SERVICE_TYPE,
    IS_SEARCH,
    DBA_LISTADO_COM_SUCESSO,
    SET_PLANO_CONFIG,
    MAIS_PLANOS_CARREGADOS_COM_SUCESSO,
    SET_LIST_PLANO_INICIAL
} from './PlanosActions';

const pppoeInitial = {
    tipoServico: "PPPOE",
    categoria: "servico",
    indexVlan: '',
    upload: {},
    download: '',
    advancedOptionPPPoE: false,
    controleDeBandaPPPoE: false
};
const bridgeInitial = {
    tipoServico: "BRIDGE",
    categoria: "servico",
    indexVlan: '',
    eth1: true,
    eth2: false,
    eth3: false,
    eth4: false,
    vlanTaggedChecked: "UNTAGGED",
    upload: '',
    download: '',
    advancedOptionBridge: false,
    controleDeBandaBridge: false
};
const wifiInitial = {
    tipoServico: "WiFi",
    categoria: "configuracao"
};

const planoInicial = {
    nome: '',
    descricao: '',
    listaConfiguracoes: [],
    vlanId: "",
    pppoe: pppoeInitial,
    bridge: bridgeInitial,
    wifi: wifiInitial,
    serviceType: {}
};

const initialServiceTypes = [
    {
        id: 1,
        serviceType: "BRIDGE",
        quantidade: 0,
        quantidadeMaxima: 16
    },
    {
        id: 2,
        serviceType: "PPPOE",
        quantidade: 0,
        quantidadeMaxima: 1
    }
];

const initialState = {
    campoPesquisa: '',
    statusModal: [],
    isLoading: false,
    planos: [],
    plano: planoInicial,
    serviceTypes: initialServiceTypes,
    configurationOptions: [
        {
            id: 1,
            configurationName: "WiFi",
            quantidade: 0,
            quantidadeMaxima: 1
        }
    ],
    vlanModes: [
        {
            id: 'TAGGED',
            text: 'Tagged'
        },
        {
            id: 'UNTAGGED',
            text: 'Untagged'
        }
    ],
    /* bandwidthControlProfiles: [
        {
            id: 1,
            perfilName: "DBA_Exemplo"
        }
    ] */
    uploadControl: {
        dbas: [],
        paginacao: {
            totalPages: 0,
            totalElements: 0,
            pageNumber: 0
        }
    },
    downloadControl: [
        {
            id: 1,
            perfilName: "Download_Exemplo"
        }
    ],
    wifiProfiles: [
        {
            id: 1,
            perfilName: "PROFILE_WIFI"
        }
    ],
    isSearch: false,
    planoConfig: []
};

const pppoeUsuarioESenhaPadrao = {
    pppoeUsuario: 'admin',
    pppoeSenha: 'admin',
};
const wifiSSIDESenhaPadrao = {
    ssid: 'admin',
    senha: 'admin',
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CARREGANDO_PLANOS:
            return { ...state };
        case PLANOS_CARREGADOS_COM_ERRO:
            return { ...state };
        case PLANOS_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                planos: payload.content ? payload.content : payload,
                paginacao: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number
                },
                isLoading: false
            };

        case ADICIONANDO_PLANO:
            return { ...state };
        case PLANO_NAO_ADICIONADO:
            return { ...state };
        case PLANO_ADICIONADO_COM_SUCESSO:
            return { ...state, planos: [...state.planos, payload] };

        case ATUALIZANDO_PLANO:
            return { ...state };
        case PLANO_NAO_ATUALIZADO:
            return { ...state };
        case PLANO_ATUALIZADO_COM_SUCESSO:
            return {
                ...state,
                planos: state.planos.map(plano => (plano.id === payload.id ? payload : plano))
            };

        case DELETANDO_PLANO:
            return { ...state };
        case PLANO_NAO_DELETADO:
            return { ...state };
        case PLANO_DELETADO_COM_SUCESSO:
            return {
                ...state,
                planos: state.planos.filter(plano => plano.id !== payload)
            };
        case SET_TIPO_MODAL_PLANO:
            if (payload === '' && state["statusModal"].length === 1) {
                return { ...state, statusModal: [] };
            }
            else if (payload === '') {
                return {
                    ...state,
                    statusModal: state.statusModal.filter((value) => value !== state.statusModal[state.statusModal.length - 1])
                };
            }
            else {
                return { ...state, statusModal: [...state.statusModal, payload] };
            }

        case SET_PLANO:
            return { ...state, plano: payload };

        case SET_PLANO_CONFIG:
            return { ...state, planoConfig: payload };

        case SET_PLANO_INICIAL:
            return { ...state, plano: planoInicial };

        case ATUALIZAR_CAMPO_PESQUISA_PLANO:
            return { ...state, campoPesquisa: payload };

        case LIMPAR_CAMPO_PESQUISA_PLANO:
            return {
                ...state,
                campoPesquisa: '',
                isSearch: false
            };

        case ATUALIZAR_CONTEUDO_PLANO_MODAL:
            return {
                ...state,
                plano: { ...state.plano, [payload.target.name]: payload.target.value }
            };

        case ATUALIZAR_CONTEUDO_SERVICOS_PLANO_MODAL:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    listaConfiguracoes: {
                        ...state.plano.listaConfiguracoes,
                        [payload.target.name]: payload.target.value
                    }
                }
            };
        case SET_TIPO_MODAL:
            if (payload === '' && state["statusModal"].length === 1) {
                return { ...state, statusModal: [] };
            }
            else if (payload === '') {
                return {
                    ...state,
                    statusModal: state.statusModal.filter((value) => value !== state.statusModal[state.statusModal.length - 1])
                };
            }
            else {
                return { ...state, statusModal: [...state.statusModal, payload] };
            }
        case SET_USUARIO_E_SENHA_PADRAO_PPPOE:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    pppoe: {
                        ...state.plano.pppoe,
                        pppoeUsuario: pppoeUsuarioESenhaPadrao.pppoeUsuario,
                        pppoeSenha: pppoeUsuarioESenhaPadrao.pppoeSenha
                    }
                }
            };
        case ATUALIZAR_CONTEUDO_PPPOE_MODAL:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    pppoe: {
                        ...state.plano.pppoe,
                        [payload.target.name]: payload.target.type === "checkbox" ? payload.target.checked : payload.target.value,
                    }
                }
            };
        case ADICIONAR_SERVICO_AO_PLANO:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    serviceType: {},
                    listaConfiguracoes: [
                        ...state.plano.listaConfiguracoes,
                        payload
                    ],
                    [payload.tipoServico.toLowerCase()]:
                        (payload.tipoServico.toLowerCase() === 'bridge' ? bridgeInitial :
                            (payload.tipoServico.toLowerCase() === 'pppoe' ? pppoeInitial : wifiInitial))

                }
            };
        case ADICIONAR_CONFIGURACAO_AO_PLANO:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    configuration: {},
                    listaConfiguracoes: [
                        ...state.plano.listaConfiguracoes,
                        payload
                    ],
                    [payload.tipoServico.toLowerCase()]: { tipoServico: payload.tipoServico, categoria: payload.categoria }
                }
            };
        case ATUALIZAR_SERVICE_TYPES:
            return {
                ...state,
                serviceTypes: state.serviceTypes.map((service) => {
                    if (service.serviceType.toLowerCase() === payload.serviceType.toLowerCase()) {
                        return {
                            ...service,
                            quantidade: payload.operationType === "+" ? service.quantidade + 1 : (service.quantidade > 0 ? service.quantidade - 1 : 0)
                        };
                    }
                    else {
                        return service;
                    }
                })
            };
        case ATUALIZAR_CONFIGURATION_OPTIONS:
            return {
                ...state,
                configurationOptions: state.configurationOptions.map((configuration) => {
                    if (configuration.configurationName.toLowerCase() === payload.configurationName.toLowerCase()) {
                        return {
                            ...configuration,
                            quantidade: payload.operationType === "+" ? configuration.quantidade + 1 : (configuration.quantidade > 0 ? configuration.quantidade - 1 : 0)
                        };
                    }
                    else {
                        return configuration;
                    }
                })
            };
        case ATUALIZAR_CONTEUDO_BRIDGE_MODAL:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    bridge: {
                        ...state.plano.bridge,
                        eth1: payload.target.type === "radio" ? (state.plano.bridge.vlanTaggedChecked === "UNTAGGED" ? false : true) : (payload.target.type === "checkbox" && payload.target.name === "eth1") ? payload.target.checked : state.plano.bridge.eth1,
                        eth2: payload.target.type === "radio" ? (state.plano.bridge.vlanTaggedChecked === "UNTAGGED" ? false : true) : (payload.target.type === "checkbox" && payload.target.name === "eth2") ? payload.target.checked : state.plano.bridge.eth2,
                        eth3: payload.target.type === "radio" ? (state.plano.bridge.vlanTaggedChecked === "UNTAGGED" ? false : true) : (payload.target.type === "checkbox" && payload.target.name === "eth3") ? payload.target.checked : state.plano.bridge.eth3,
                        eth4: payload.target.type === "radio" ? (state.plano.bridge.vlanTaggedChecked === "UNTAGGED" ? false : true) : (payload.target.type === "checkbox" && payload.target.name === "eth4") ? payload.target.checked : state.plano.bridge.eth4,
                        [payload.target.name]: payload.target.type === "checkbox" ? payload.target.checked : payload.target.value,
                    }
                }
            };
        case ATUALIZAR_CONTEUDO_WIFI_MODAL:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    wifi: {
                        ...state.plano.wifi,
                        [payload.target.name]: payload.target.type === "checkbox" ? payload.target.checked : payload.target.value,
                    }
                }
            };
        case SET_SSID_E_SENHA_PADRAO_WIFI:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    wifi: {
                        ...state.plano.wifi,
                        ssid: wifiSSIDESenhaPadrao.ssid,
                        senha: wifiSSIDESenhaPadrao.senha
                    }
                }
            };
        case DELETAR_SERVICO_OU_CONFIGURACAO:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    servico: {},
                    listaConfiguracoes: state.plano.listaConfiguracoes.filter((servico) => JSON.stringify(servico) !== JSON.stringify(payload))
                }
            };
        case SET_SERVICO_OU_CONFIGURACAO_SELECIONADO:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    servico: payload
                }
            };
        case RESETAR_CAMPOS_MODAIS:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    pppoe: pppoeInitial,
                    bridge: bridgeInitial,
                    wifi: wifiInitial
                }
            };
        case RESETAR_CAMPOS_PLANO:
            return {
                ...state,
                plano: planoInicial,
                serviceTypes: initialServiceTypes
            };
        case HANDLE_IS_LOADING_PLANS_STATUS:
            return {
                ...state,
                isLoading: payload
            };
        case CLEAR_PLANO_FIELD:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    [payload.currentTarget.name]: ""
                }
            };
        case CLEAR_BRIDGE_FIELD:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    bridge: {
                        ...state.plano.bridge,
                        [payload.currentTarget.name]: ""
                    }
                }
            };
        case CLEAR_PPPOE_FIELD:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    pppoe: {
                        ...state.plano.pppoe,
                        [payload.currentTarget.name]: ""
                    }
                }
            };
        case CANCEL_SERVICE_TYPE:
            return {
                ...state,
                plano: {
                    ...state.plano,
                    serviceType: {},
                    pppoe: pppoeInitial,
                    bridge: bridgeInitial,
                    wifi: wifiInitial
                }
            };
        case IS_SEARCH:
            return {
                ...state,
                isSearch: state.campoPesquisa !== "" ? true : false
            };
        case DBA_LISTADO_COM_SUCESSO:
            return {
                ...state,
                uploadControl: {
                    dbas: payload.content ? payload.content : payload,
                    paginacao: {
                        totalPages: payload.totalPages,
                        totalElements: payload.totalElements,
                        pageNumber: payload.number
                    }
                },
                plano: {
                    ...state.plano,
                    pppoe: {
                        ...state.plano.pppoe,
                        upload: payload.content.filter(dba => dba.nome === "DBA_PADRAO")[0]
                    },
                    bridge: {
                        ...state.plano.bridge,
                        upload: payload.content.filter(dba => dba.nome === "DBA_PADRAO")[0]
                    }
                }
            };
        case MAIS_PLANOS_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                planos: state.planos.concat(payload.content ? payload.content : payload),
                paginacao: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number,
                },
            };
        case SET_LIST_PLANO_INICIAL:
            return {
                ...state,
                planos: [],
                paginacao: {}
            };
        default:
            return state;
    }
};
