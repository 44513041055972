import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InputInbox from '../../components/form/inputInBox/InputInbox';
import { IcoSearch } from '../../components/icones';

import Button from '../../components/button/Button';
import { atualizarCampoPesquisa, isSearch, limparCampoPesquisa, listarNotificacoes } from './redux/NotificacoesActions';
import Row from '../../components/row/row';
import Input from '../../layouts/components/Input/Input';
//import Language from '../../components/translate/translate'

export default () => {
    const dispatch = useDispatch();

    //const defaltText = require(`${Language()}`)
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);
    const campoPesquisa = useSelector(state => state.notificacoesState.campoPesquisa);
    const notificacao = useSelector((state) => state.notificacoesState);

    const handlePressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(listarNotificacoes({ pesquisa: campoPesquisa }));
        }
    };

    return (
        <div>
            <Row style={{ justifyContent: "space-between" }}>
                <div className="col-md-6">
                    <div className='campo-pesquisa'>
                        <Input
                            id="pesquisar"
                            name='pesquisar'
                            label={defaltText.pesquisar.text}
                            value={campoPesquisa}
                            changeInput={e => dispatch(atualizarCampoPesquisa(e))}
                            onPressEnter={(e) => handlePressEnter(e)}
                            clearField={{
                                action: (e) => dispatch(limparCampoPesquisa(e)),
                                text: defaltText.btn.limparCampo
                            }}
                            leftButton={{
                                action: () => { dispatch(listarNotificacoes({ pesquisa: campoPesquisa })), dispatch(isSearch()); },
                                text: defaltText.pesquisar.text,
                                icon: <IcoSearch title={defaltText.pesquisar.text} />
                            }}
                        />
                    </div>
                </div>
                {/* <div className="col-md-2 div-new-button">
          <Button
            titulo={defaltText.btn.filtro}
            action={() => setFiltro(!filtro)}
            color='secondary'
            type='btn'
            children={<IcoFilter title={defaltText.btn.filtro} />}
          />
        </div> */}
            </Row>
            {notificacao.isSearch === true && campoPesquisa !== '' ?
                <p className='qntRegisters'>
                    <span className='boldText'>
                        {notificacao.paginacao.totalElements}
                    </span> {defaltText.itens} <span className='boldText'>
                        "{campoPesquisa}"
                    </span>
                </p> : null}
            {notificacao.isSearch === true && campoPesquisa !== '' ? <div className='divisorLine'></div> : null}
        </div>
    );
};
