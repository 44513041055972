import React from 'react';

export function IcoFilter(props) {
  return (
    <svg
      className="icon"
      viewBox="0 0 48 48"
      style={props.style ? props.style : {}}
    >
      <title>{props.title ? props.title : null}</title>
      <path d="M24 0c-13.255 0-24 3.358-24 7.5v4.5l18 18v15c0 1.657 2.686 3 6 3s6-1.343 6-3v-15l18-18v-4.5c0-4.142-10.745-7.5-24-7.5zM4.424 6.507c1.122-0.64 2.699-1.248 4.56-1.757 4.122-1.129 9.455-1.75 15.016-1.75s10.893 0.622 15.016 1.75c1.861 0.509 3.438 1.117 4.56 1.757 0.74 0.422 1.14 0.779 1.326 0.993-0.186 0.213-0.586 0.57-1.326 0.993-1.122 0.64-2.699 1.248-4.56 1.757-4.122 1.129-9.455 1.75-15.016 1.75s-10.893-0.622-15.016-1.75c-1.861-0.509-3.438-1.117-4.56-1.757-0.74-0.422-1.14-0.779-1.326-0.993 0.186-0.213 0.586-0.57 1.326-0.993z"></path>
    </svg>
  );
}
