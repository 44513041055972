import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InputInbox from '../../components/form/inputInBox/InputInbox';
import { IcoGear, IcoMais, IcoSearch } from '../../components/icones';

import Button from '../../components/button/Button';
import { abrirModalCriarPlano, atualizarCampoPesquisa, isSearch, limparCampoPesquisa, listarPlanos } from './redux/PlanosActions';
import history from '../../history';
import Row from '../../components/row/row';
import { getPagePermissions } from '../../utils/utils';
import Input from '../../layouts/components/Input/Input';

export default () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);
    const campoPesquisa = useSelector(state => state.planosState.campoPesquisa);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const plano = useSelector((state) => state.planosState);

    const handleOnPressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(listarPlanos({ pesquisa: campoPesquisa }));
        }
    };

    return (
        <div>
            <Row style={{ justifyContent: "space-between" }}>
                <div className="col-md-6">
                    <div className='campo-pesquisa'>
                        <Input
                            id="pesquisar"
                            name='pesquisar'
                            label={defaltText.pesquisar.text}
                            value={campoPesquisa}
                            changeInput={e => dispatch(atualizarCampoPesquisa(e))}
                            onPressEnter={(e) => handleOnPressEnter(e)}
                            clearField={{
                                action: (e) => dispatch(limparCampoPesquisa(e)),
                                text: defaltText.btn.limparCampo
                            }}
                            leftButton={{
                                action: () => { dispatch(listarPlanos({ pesquisa: campoPesquisa })), dispatch(isSearch()); },
                                text: defaltText.pesquisar.text,
                                icon: <IcoSearch title={defaltText.pesquisar.text} />
                            }}
                        />
                    </div>
                </div>

                {/* <div className="col-md-2 div-new-button">
                    <Button
                        hasAccess={getPagePermissions(permissions, "planos", "create")}
                        id="btnAdicionar"
                        titulo={defaltText.btn.adicionarPlano}
                        action={() => history.push('planos/configuracao')}
                        texto={defaltText.btn.adicionarPlano}
                        children={<IcoMais className="btn-border" title={defaltText.btn.adicionarPlano} />}
                        color="success"
                        type="btn"
                    />
                </div> */}
                <div className="col-md-2 div-new-button">
                    <Button
                        hasAccess={getPagePermissions(permissions, "planos", "create")}
                        id="btnAdicionar"
                        titulo={defaltText.btn.adicionarPlano}
                        action={() => dispatch(abrirModalCriarPlano())}
                        texto={defaltText.btn.adicionarPlano}
                        children={<IcoMais className="btn-border" title={defaltText.btn.adicionarPlano} />}
                        color="success"
                        type="btn"
                    />
                </div>
            </Row>
            {plano.isSearch === true ?
                <p className='qntRegisters'>
                    <span className='boldText'>
                        {plano.paginacao.totalElements}
                    </span> {defaltText.itens} <span className='boldText'>
                        "{campoPesquisa}"
                    </span>
                </p> : null}
            {plano.isSearch === true ? <div className='divisorLine'></div> : null}
        </div>
    );
};
