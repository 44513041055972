import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import BasicModal from '../../../components/modal/BasicModal'

import ConteudoLimiares from './conteudoLimiares'

import { fecharModal, atualizarLimiares } from '../redux/AlarmesActions'



const Limiares = () => {

    const dispatch = useDispatch()

    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const statusModal = useSelector((state) => state.alarmesState.statusModal);

    const limiares = useSelector((state) => state.alarmesState.limiares)

    const title = defaltText.inputs.limiares
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModal())}
            containerModal={{
                title,
                size: 'medium',
                content: <ConteudoLimiares />,
                closeText: "Fechar",
                action: {
                    btnId: "btnSalvar",
                    acao: () => dispatch(atualizarLimiares(limiares)),
                    //acao: () => console.log(olt),
                    texto: defaltText.btn.salvar,
                    class: 'success'
                }
            }}

        />
    )


}


export default Limiares