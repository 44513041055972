import {
    SET_TIPO_MODAL,
    SET_NOTIFICACAO,
    LIMPAR_CAMPO_PESQUISA_NOTIFICACOES,
    ATUALIZAR_CAMPO_PESQUISA_NOTIFICACOES,
    CARREGANDO_NOTIFICACOES,
    NOTIFICACOES_CARREGADAS_COM_SUCESSO,
    NOTIFICACOES_CARREGADAS_COM_ERRO,
    RESET_NOTIFICATIONS_STATE,
    HANDLE_IS_LOADING_NOTIFICATIONS_STATUS,
    IS_SEARCH,
    NOTIFICACOES_NAO_VISTAS,
    NOTIFICACAO_VISUALIZADA_COM_SUCESSO,
    NOTIFICACAO_VISUALIZADA_ERRO,
    NOTIFICACAO_ATUALIZADOS_COM_SUCESSO,
    NOTIFICACAO_DELETADO_COM_SUCESSO,
    NOTIFICACAO_NAO_DELETADO
} from './NotificacoesActions';

const notificacaoInicial = {
    nome: '',
    ip: '',
    porta: ''
};
const initialState = {
    campoPesquisa: '',
    statusModal: '',
    notificacoes: [],
    paginacao: {
        totalPages: 0,
        totalElements: 0,
        pageNumber: 0
    },
    notificacao: notificacaoInicial,
    isSearch: false,
    qntNotificacaoNaoVista: 0
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_TIPO_MODAL:
            return { ...state, statusModal: payload };
        case SET_NOTIFICACAO:
            return { ...state, notificacao: payload };

        case LIMPAR_CAMPO_PESQUISA_NOTIFICACOES:
            return {
                ...state,
                campoPesquisa: '',
                isSearch: false
            };
        case ATUALIZAR_CAMPO_PESQUISA_NOTIFICACOES:
            return { ...state, campoPesquisa: payload };

        case CARREGANDO_NOTIFICACOES:
            return { ...state };
        case NOTIFICACOES_CARREGADAS_COM_ERRO:
            return { ...state };
        case NOTIFICACOES_CARREGADAS_COM_SUCESSO:
            return {
                ...state,
                notificacoes: payload.content ? payload.content : payload,
                paginacao: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number
                },
                servicos: payload.servicos,
                paginacaoServicos: payload.paginacaoServicos,
                isLoading: false
            };
        case RESET_NOTIFICATIONS_STATE:
            return initialState;
        case HANDLE_IS_LOADING_NOTIFICATIONS_STATUS:
            return {
                ...state,
                isLoading: payload
            };
        case IS_SEARCH:
            return {
                ...state,
                isSearch: state.campoPesquisa !== "" ? true : false
            };
        case NOTIFICACOES_NAO_VISTAS:
            return {
                ...state,
                qntNotificacaoNaoVista: payload === 0 ? '' : payload
            };
        case NOTIFICACAO_VISUALIZADA_COM_SUCESSO:
            return {
                ...state,
                notificacoes: state.notificacoes.filter(notificacoes => notificacoes.id !== payload)
            };
        case NOTIFICACAO_VISUALIZADA_ERRO:
            return { ...state };
        case NOTIFICACAO_ATUALIZADOS_COM_SUCESSO:
            if (typeof payload === "object") {
                if (state.notificacoes.find((notificacao) => notificacao.id === payload.id) !== undefined) {
                    return {
                        ...state,
                        notificacoes: state.notificacoes.map((notificacao) => (notificacao.id === payload.id ? payload : notificacao))
                    };
                } else {
                    return {
                        notificacoes: [
                            ...state.notificacoes, 
                            payload
                        ]
                    }
                }
            }
            else {
                return {
                    ...state,
                    notificacoes: state.notificacoes.map((notificacao) => {
                        if (notificacao.id === payload) {
                            return {
                                ...notificacao,
                                status: "DELETADO"
                            };
                        }
                        else {
                            return notificacao;
                        }
                    }),
                };
            }
        case NOTIFICACAO_DELETADO_COM_SUCESSO:
            return {
                ...state,
                notificacoes: state.notificacoes.filter(notificacao => notificacao.id !== payload)
            }
        case NOTIFICACAO_NAO_DELETADO:
            return { ...state }
        default:
            return state;
    }
};
