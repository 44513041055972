import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const ThemeProvider = ({ children }) => {
    const selectedTheme = useSelector((state) => state.layoutState.selectedTheme);
    useEffect(() => {
        localStorage.setItem("tema", JSON.stringify(selectedTheme));
        //document.getElementById("root").setAttribute("class", selectedTheme.id);
        document.getElementsByTagName("body")[0].setAttribute("class", selectedTheme.id);
    }, [selectedTheme]);
    return <>{children}</>
}

ThemeProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired
    ])
};

export default ThemeProvider;