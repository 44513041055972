import {
    ATUALIZAR_CAMPO_PESQUISA_ASSINANTE,
    LIMPAR_CAMPO_PESQUISA_ASSINANTE,
    ATUALIZAR_CONTEUDO_ASSINANTE_MODAL,
    ATUALIZAR_CONTEUDO_ASSINANTE_SERVICO_MODAL,
    ASSINANTES_CARREGADOS_COM_ERRO,
    ASSINANTES_CARREGADOS_COM_SUCESSO,
    CARREGANDO_ASSINANTES,
    ADICIONANDO_ASSINANTE,
    ASSINANTE_NAO_ADICIONADO,
    ASSINANTE_ADICIONADO_COM_SUCESSO,
    ATUALIZANDO_ASSINANTE,
    ASSINANTE_NAO_ATUALIZADO,
    ASSINANTE_ATUALIZADO_COM_SUCESSO,
    DELETANDO_ASSINANTE,
    ASSINANTE_NAO_DELETADO,
    ASSINANTE_DELETADO_COM_SUCESSO,
    SET_TIPO_MODAL_ASSINANTE,
    SET_ASSINANTE,
    SET_ASSINANTE_INICIAL,
    CARREGANDO_ASSINANTE,
    ASSINANTE_CARREGADOS_COM_ERRO,
    ASSINANTE_CARREGADOS_COM_SUCESSO,
    SERVICOS_ASSINANTE_CARREGADOS_COM_SUCESSO,
    ADICIONANDO_ASSINANTE_SERVICO,
    ASSINANTE_SERVICO_NAO_ADICIONADO,
    ASSINANTE_SERVICO_ADICIONADO_COM_SUCESSO,
    ATUALIZANDO_ASSINANTE_SERVICO,
    ASSINANTE_SERVICO_ATUALIZADO_COM_SUCESSO,
    ASSINANTE_SERVICO_NAO_ATUALIZADO,
    DELETANDO_ASSINANTE_SERVICO,
    ASSINANTE_SERVICO_DELETADO_COM_SUCESSO,
    ASSINANTE_SERVICO_NAO_DELETADO,
    RESET_CLIENTS_STATE,
    HANDLE_IS_LOADING_CLIENTSS_STATUS,
    CLIENT_AND_SERVICES_LOADED_SUCCESSFULLY,
    CLEAR_CRIAR_EDITAR_ASSINANTE_FIELD,
    IS_SEARCH
} from './AssinanteActions';

const assinanteInicial = {
    nContrato: '',
    documento: '',
    nome: '',
    telefone: '',
    email: '',
    cep: '',
    endereco: '',
    cidade: '',
    estado: '',
    bairro: '',
    complemento: '',
    servicos: []
};
const initialState = {
    campoPesquisa: '',
    statusModal: [],
    isLoading: false,
    assinantes: [],
    assinante: assinanteInicial,
    isSearch: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CARREGANDO_ASSINANTES:
            return { ...state };
        case ASSINANTES_CARREGADOS_COM_ERRO:
            return { ...state };
        case ASSINANTES_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                assinantes: payload.content ? payload.content : payload,
                paginacao: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number
                },
                isLoading: false
            };

        case CARREGANDO_ASSINANTE:
            return { ...state };
        case ASSINANTE_CARREGADOS_COM_ERRO:
            return { ...state };
        case ASSINANTE_CARREGADOS_COM_SUCESSO:
            return { ...state, assinante: payload };

        case SERVICOS_ASSINANTE_CARREGADOS_COM_SUCESSO:
            return { ...state, assinante: { ...state.assinante, servicos: payload } };

        case ADICIONANDO_ASSINANTE:
            return { ...state };
        case ASSINANTE_NAO_ADICIONADO:
            return { ...state };
        case ASSINANTE_ADICIONADO_COM_SUCESSO:
            return { ...state, assinantes: [...state.assinantes, payload] };

        case ATUALIZANDO_ASSINANTE:
            return { ...state };
        case ASSINANTE_NAO_ATUALIZADO:
            return { ...state };
        case ASSINANTE_ATUALIZADO_COM_SUCESSO:
            if (typeof payload === "object") {
                if (state.assinantes.find((assinante) => assinante.id === payload.id) !== undefined) {
                    return {
                        ...state,
                        assinantes: state.assinantes.map((assinante) => (assinante.id === payload.id ? payload : assinante))
                    };
                }
                return state;
                /* else {
                    if ((state.paginacao.totalElements + 1) / (12 * state.paginacao.totalPages) > 1) {
                        return {
                            ...state,
                            paginacao: {
                                ...state.paginacao,
                                totalElements: state.paginacao.totalElements + 1,
                                totalPages: state.paginacao.totalPages + 1
                            }
                        };
                    }
                    return {
                        ...state,
                        assinantes: [...state.assinantes, payload],
                        paginacao: {
                            ...state.paginacao,
                            totalElements: state.paginacao.totalElements + 1
                        }
                    };
                } */
            }
            else {
                return {
                    ...state,
                    assinantes: state.assinantes.map((assinante) => {
                        if (assinante.id === payload) {
                            return {
                                ...assinante,
                                status: "DELETADO"
                            };
                        }
                        else {
                            return assinante;
                        }
                    }),
                    /* paginacao: {
                        ...state.paginacao,
                        totalElements: state.paginacao.totalElements - 1,
                        totalPages: ((state.paginacao.totalElements - 1) / (12 * (state.paginacao.totalPages - 1)) > 1)
                            ? state.paginacao.totalPages
                            : state.paginacao.totalPages - 1
                    } */
                };
            }

        case DELETANDO_ASSINANTE:
            return { ...state };
        case ASSINANTE_NAO_DELETADO:
            return { ...state };
        case ASSINANTE_DELETADO_COM_SUCESSO:
            return {
                ...state,
                assinantes: state.assinantes.filter(assinante => assinante.id !== payload)
            };

        case ADICIONANDO_ASSINANTE_SERVICO:
            return { ...state };
        case ASSINANTE_SERVICO_NAO_ADICIONADO:
            return { ...state };
        case ASSINANTE_SERVICO_ADICIONADO_COM_SUCESSO:
            return {
                ...state,
                assinante: {
                    ...state.assinante,
                    servicos: [...state.assinante.servicos, payload]
                }
            };

        case ATUALIZANDO_ASSINANTE_SERVICO:
            return { ...state };
        case ASSINANTE_SERVICO_NAO_ATUALIZADO:
            return { ...state };
        case ASSINANTE_SERVICO_ATUALIZADO_COM_SUCESSO:
            return {
                ...state,
                /*         assinante: {
                          ...state.assinante,
                          servicos: state.assinante.servicos.map(servico => (servico.id === payload.id ? payload : servico))
                        } */
            };

        case DELETANDO_ASSINANTE_SERVICO:
            return { ...state };
        case ASSINANTE_SERVICO_NAO_DELETADO:
            return { ...state };
        case ASSINANTE_SERVICO_DELETADO_COM_SUCESSO:
            return {
                ...state,
                assinante: {
                    ...state.assinante,
                    servicos: state.assinante.servicos.filter(servico => servico.id !== payload)
                }
            };

        case SET_TIPO_MODAL_ASSINANTE:
            if (payload === '' && state["statusModal"].length === 1) {
                return { ...state, statusModal: [] };
            }
            else if (payload === '') {
                return {
                    ...state,
                    statusModal: state.statusModal.filter((value) => value !== state.statusModal[state.statusModal.length - 1])
                };
            }
            else {
                return { ...state, statusModal: [...state.statusModal, payload] };
            }

        case SET_ASSINANTE:
            return { ...state, assinante: payload };

        case SET_ASSINANTE_INICIAL:
            return { ...state, assinante: assinanteInicial };

        case ATUALIZAR_CAMPO_PESQUISA_ASSINANTE:
            return { ...state, campoPesquisa: payload };

        case LIMPAR_CAMPO_PESQUISA_ASSINANTE:
            return {
                ...state,
                campoPesquisa: '',
                isSearch: false
            };

        case ATUALIZAR_CONTEUDO_ASSINANTE_MODAL:
            return {
                ...state,
                assinante: {
                    ...state.assinante,
                    [payload.target.name]: payload.target.type === 'checkbox' ? payload.target.checked : payload.target.value
                }
            };

        case ATUALIZAR_CONTEUDO_ASSINANTE_SERVICO_MODAL:
            return {
                ...state,
                assinante: {
                    ...state.assinante,
                    planos: {
                        ...state.assinante.planos,
                        [payload.plano]: {
                            ...state.assinante.planos[payload.plano],
                            [payload.evento.target.name]:
                                payload.evento.target.type === 'checkbox' ? payload.evento.target.checked : payload.evento.target.value
                        }
                    }
                }
            };
        case RESET_CLIENTS_STATE:
            return initialState;
        case HANDLE_IS_LOADING_CLIENTSS_STATUS:
            return {
                ...state,
                isLoading: payload
            };
        case CLIENT_AND_SERVICES_LOADED_SUCCESSFULLY:
            return {
                ...state,
                assinante: {
                    ...payload.assinante,
                    servicos: payload.servicosDoAssinante
                },
                isLoading: false
            };
        case CLEAR_CRIAR_EDITAR_ASSINANTE_FIELD:
            return {
                ...state,
                assinante: {
                    ...state.assinante,
                    [payload.currentTarget.name]: ""
                }
            };
        case IS_SEARCH:
            return {
                ...state,
                isSearch: state.campoPesquisa !== "" ? true : false
            };
        default:
            return state;
    }
};
