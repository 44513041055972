import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClick from '../../../components/useOusideClick/useoutsideclick';
import { fecharModalNovaLoc, setTipoModal } from '../../../modulos/locais/redux/LocaisActions';
import { setModalHeaderType } from '../../redux/LayoutActions';
import "./modalHeader.css";

const ModalHeader = ({ children, classAnchor }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const statusModal = useSelector(state => state.locaisState.statusModal);
    const layoutStatusModal = useSelector(state => state.layoutState.statusModal);

    useOutsideClick(ref, (e) => {
        if (!statusModal.includes("abrindoNovaLoc") &&
            !layoutStatusModal.includes("sair") &&
            !layoutStatusModal.includes("mostrarAtualizacao") &&
            !layoutStatusModal.includes("removeLoc")) {
            if(statusModal != 'deletando')
                dispatch(setModalHeaderType(""));
        }
    });

    if (children === null) {
        return null;
    }
    else {
        return (
            <div
                ref={ref}
                className={`modal-header ${classAnchor}`}
            >
                {children}
            </div>
        );
    }
};

export default ModalHeader;