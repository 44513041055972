import {
    ATUALIZAR_CONTEUDO_REGISTRAR,
    ADICIONANDO_REGISTRO,
    REGISTRO_ADICIONADO_COM_SUCESSO,
    REGISTRO_NAO_ADICIONADO,
    RESET_REGISTER_FIELDS,
    CLEAR_REGISTRAR_FIELD
} from './RegistrarActions';

const initialState = {
    nome: '',
    email: '',
    senha: '',
    confirmsenha: '',
    telefone: '55'
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ATUALIZAR_CONTEUDO_REGISTRAR:
            return {
                ...state,
                [payload.target.name]: payload.target.type === 'checkbox' ? payload.target.checked : payload.target.value
            };

        case ADICIONANDO_REGISTRO:
            return { ...state };
        case REGISTRO_NAO_ADICIONADO:
            return { ...state };
        case REGISTRO_ADICIONADO_COM_SUCESSO:
            return initialState;
        case RESET_REGISTER_FIELDS:
            return initialState;
        case CLEAR_REGISTRAR_FIELD:
            return {
                ...state,
                [payload.currentTarget.name]: ""
            };
        default:
            return state;
    }
};
