import React, { useState } from 'react';
import { ChevronDownIcon } from '../icons/ChevronDown';
import "./table.css";
import _ from 'lodash';

const Table = ({ itens, labels, listActions, subTable }) => {

    const [itemSelected, setItemSelected] = useState();
    const handleSubTableOpen = (item, itemIndex) => {
        if (subTable && itemIndex != itemSelected) {
            subTable.action({ itens: item });
        }
        if (itemIndex == itemSelected) {
            setItemSelected(null);
        }
        else {
            setItemSelected(itemIndex);
        }
    };

    return (
        <div className="modern-table">
            <div className="table-header">
                <div className="table-row">
                    {labels.map((label) => {
                        if (label.field.includes("actions") && listActions) {
                            return <div className="table-column">{label.text}</div>;
                        }
                        else {
                            if (!label.field.includes("actions")) {
                                return <div className="table-column">{label.text}</div>;
                            }
                            else {
                                return null;
                            }
                        }
                    })}
                </div>
            </div>

            <div className="table-body">
                {itens && itens.length > 0 && itens.map((item, itemIndex) => (
                    <div className={`table-content ${itemSelected === itemIndex && "focus"}`}  >
                        <div className={`table-row ${itemIndex === 0 && " first-item"} ${subTable && " pointer"}`} onClick={() => handleSubTableOpen(item, itemIndex)}>

                            {labels.map((label, labelIndex) => {
                                if (label.field.includes("actions") || label.field.includes("link") && listActions) {
                                    return <div className="table-column actions">{listActions({ item: label.field, itens: item })}<hr /></div>;
                                }
                                else {
                                    if (!label.field.includes("actions")) {
                                        if (labelIndex === 0 && subTable) {
                                            return <div className="table-column" >
                                                <div className="table-item">
                                                    <ChevronDownIcon className="vlan-dropdown" />
                                                    {Array.isArray(item[label.field]) ?
                                                        <div>{_.get(item, label.field).join(", ")}</div>
                                                        :
                                                        <div>{_.get(item, label.field)}</div>
                                                    }
                                                </div>
                                                <hr />
                                            </div>;
                                        }
                                        else {
                                            return (
                                                <div className="table-column">
                                                    {Array.isArray(item[label.field]) ? _.get(item, label.field).join(", ")
                                                        : (_.get(item, label.field) ? _.get(item, label.field) : "--")}
                                                    <hr />
                                                </div>
                                            );
                                        }
                                    }

                                }
                            })}
                        </div>
                        {subTable && subTable.loader}
                        {
                            subTable && itemIndex === itemSelected ?
                                <div className="subtable-body opened">
                                    {subTable.loader}
                                    {subTable.content}
                                </div> : <div className="subtable-body closed">
                                    {subTable && subTable.loader}
                                    {subTable && subTable.content}
                                </div>
                        }
                    </div>

                ))}
            </div>
        </div>
    );
};

export default Table;