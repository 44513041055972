import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from '../../../components/modal/BasicModal';
import AddEditCollaboratorModalContent from './adicionarColaboradorConteudo';
import { fecharModalColaborador } from '../redux/ColaboradorActions';

const AddEditCollaboratorModal = ({ action }) => {
    const dispatch = useDispatch();
    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const collaborator = useSelector(state => state.colaboradoresState.colaborador);
    const statusModal = useSelector(state => state.colaboradoresState.statusModal);
    const title = collaborator.id ? defaltText.tituloModalEditar : defaltText.tituloModalNovo;

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalColaborador())}
            containerModal={{
                title,
                size: 'medium',
                content: <AddEditCollaboratorModalContent />,
                closeText: defaltText.btn.fechar,
                action: {
                    acao: action,
                    texto: defaltText.btn.salvar,
                    class: 'success'
                }
            }}
        />
    );
};

export default AddEditCollaboratorModal;
