import React from 'react';

export function UsaIcon(props) {
    return (
        <svg id={props.id} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 18 18">
            <circle id="Elipse_1" data-name="Elipse 1" cx="9" cy="9" r="9" fill="#f0f0f0" />
            <g id="Grupo_66" data-name="Grupo 66" transform="translate(0.299 1.884)">
                <path id="Caminho_40" data-name="Caminho 40" d="M244.87,191.478h9.043a8.676,8.676,0,0,0-.3-2.261H244.87Z" transform="translate(-236.879 -184.695)" fill="#d80027" />
                <path id="Caminho_41" data-name="Caminho 41" d="M244.87,57.913h7.771a8.713,8.713,0,0,0-2-2.261H244.87Z" transform="translate(-236.879 -55.652)" fill="#d80027" />
                <path id="Caminho_42" data-name="Caminho 42" d="M102.039,458.232a8.629,8.629,0,0,0,5.395-1.884H96.644A8.629,8.629,0,0,0,102.039,458.232Z" transform="translate(-93.671 -442.783)" fill="#d80027" />
                <path id="Caminho_43" data-name="Caminho 43" d="M9.792,325.043H24.582a8.616,8.616,0,0,0,.973-2.261H8.819A8.616,8.616,0,0,0,9.792,325.043Z" transform="translate(-8.819 -313.739)" fill="#d80027" />
            </g>
            <path id="Caminho_44" data-name="Caminho 44" d="M4.015,1.353H4.8l-.735.534.281.864-.735-.534-.735.534L3.124,2A8.714,8.714,0,0,0,1.443,3.878H1.7l-.468.34q-.109.182-.21.37l.223.687-.417-.3Q.67,5.3.542,5.645L.788,6.4H1.7l-.735.534.281.864L.507,7.266l-.44.32A8.75,8.75,0,0,0,0,8.667H8.667V0A8.626,8.626,0,0,0,4.015,1.353ZM4.35,7.8l-.735-.534L2.881,7.8l.281-.864L2.427,6.4h.908l.281-.864L3.9,6.4H4.8l-.735.534ZM4.07,4.412l.281.864-.735-.534-.735.534.281-.864-.735-.534h.908l.281-.864.281.864H4.8ZM7.459,7.8l-.735-.534L5.99,7.8l.281-.864L5.536,6.4h.908l.281-.864L7,6.4h.908l-.735.534ZM7.178,4.412l.281.864-.735-.534-.735.534.281-.864-.735-.534h.908l.281-.864L7,3.878h.908Zm0-2.525.281.864-.735-.534-.735.534.281-.864-.735-.534h.908L6.724.49,7,1.353h.908Z" fill="#0052b4" />
        </svg>
    );
}