import React, { useEffect } from 'react';
import Button from '../button/Button';
import './card.css';
import { IcoAttention, IcoCorrect, IcoSync } from '../icones';
import { getOltCardActions } from './getCardButtons';


export default ({ content, buttons, labels, headerTitle, getCardActions, backgroundColor, getHeaderButton }) => {
    /*     const getHeaderIcon = (status,) => {
            if (status === 'OK') {
                return <Button type="btn circle" children={<IcoCorrect title={status} />} />;
            }
            else {
                if (status.includes("ERRO")) {
                    return <Button type="btn circle" color="success" children={<IcoAttention title={status} />} action={headerButtonAction} />;
                    //return getOltCardActions({ content, buttonId: "btnStatus" });
                }
                else {
                    return <Button type="btn circle" children={<IcoSync className="spin" title={status} />} />;
                }
            }
        }; */

    const fieldIsValid = (value) => {
        return value !== "" && value !== null && value !== undefined && JSON.stringify(value) !== '{}';
    };

    const hasValidLabels = (labels, content) => {
        let validLabels = false;
        labels.map((label) => {
            if (label.field !== headerTitle && label.field !== "actions") {
                if (fieldIsValid(content[label.field])) {
                    validLabels = true;
                }
            }
        });
        return validLabels;
    };

    return (
        <div className={`card ${backgroundColor && backgroundColor}`}>
            <div className="card-header">
                <h5 className='card-title'>{labels.filter((label) => label.field === headerTitle)[0].text}: {content[headerTitle]}</h5>
                {/* <p>{getHeaderIcon(content.status)}</p> */}
                {getHeaderButton && <p>{getHeaderButton(content)}</p>}
            </div>
            {hasValidLabels(labels, content) && labels.filter((label) => label.field !== headerTitle && label.field !== 'status' && label.field !== 'actions').length > 0 && (
                <div className="card-body">
                    {labels.map((label) => label.field !== headerTitle && label.field !== "actions" && fieldIsValid(content[label.field]) && (
                        <p>{label.text}: {Array.isArray(content[label.field]) ? content[label.field].join() : content[label.field]}</p>
                    ))}
                </div>
            )}
            {buttons && buttons.length > 0 && (
                <div className="card-footer">
                    <div className="card-buttons">
                        {buttons.map((buttonId) => getCardActions({ content, buttonId }))}
                    </div>
                </div>
            )}
        </div>
    );
};

