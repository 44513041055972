import React from 'react';
import { validarAction, validarCamposAction, validarSenha } from '../../../../validation/validationActions';

import axios from 'axios';
import history from '../../../../history';
import { toast } from 'react-toastify';
import { localizacoesCarregadasComSucesso, selectAtualizadoComSucesso } from '../../../locais/redux/LocaisActions';
import { atualizarPreferencia } from '../../../../layouts/redux/LayoutActions';
import { CorrectIcon } from '../../../../layouts/components/icons';
import { IcoInfo } from '../../../../components/icones';

const menuJsonPtBr = require(`../../../../layouts/menu-json/menu-pt-BR.json`);
const menuConfigJsonPtBr = require(`../../../../layouts/menu-configuracao-json/menu-pt-BR.json`);

const defaultPreferences = {
    linguagem: navigator.language,
    tema: 'ESCURO_CINZA'
};

const menuJson = menuJsonPtBr.concat(menuConfigJsonPtBr);

const idiomaText = () => {
    const preferencia = null;
    try {
        preferencia = JSON.parse(localStorage.getItem('theme'));
    } finally {
        const defaltText = require(`../../nls/${preferencia !== null ? preferencia.linguagem : 'pt-BR'}.json`);
        return defaltText.mensagem;
    }
};
export const USUARIO_LOGADO_COM_SUCESSO = 'USUARIO_LOGADO_COM_SUCESSO';
export const usuarioLogadoSucesso = registro => ({
    type: USUARIO_LOGADO_COM_SUCESSO,
    payload: registro
});

export const USUARIO_NAO_LOGADO_COM_SUCESSO = 'USUARIO_NAO_LOGADO_COM_SUCESSO';
export const usuarioNaoLogadoSucesso = erro => ({
    type: USUARIO_NAO_LOGADO_COM_SUCESSO,
    payload: erro
});
//NOVO
export const ABRIR_MODAL_SEL_LOC = 'auth.ABRIR_MODAL_SEL_LOC';
export const abrirModalSelLoc = () => setTipoModalSelLoc('abrindo');

export const FECHAR_MODAL_SEL_LOC = 'auth.FECHAR_MODAL_SEL_LOC';
export const fecharModalSelLoc = () => setTipoModalSelLoc('attentionMessage');

export const fecharModalAttention = () => setTipoModalSelLoc('');

export const backModalSelLoc = () => setTipoModalSelLoc('abrindo');

export const SET_TIPO_MODAL_SEL_LOC = 'auth.SET_TIPO_MODAL_SEL_LOC';
const setTipoModalSelLoc = tipo => ({
    type: SET_TIPO_MODAL_SEL_LOC,
    payload: tipo
});

//VERIFICAR
export const ATUALIZAR_CONTEUDO_SEL_LOC_MODAL = 'ATUALIZAR_CONTEUDO_SEL_LOC_MODAL';
export const atualizarConteudoOltModal = evento => ({
    type: ATUALIZAR_CONTEUDO_SEL_LOC_MODAL,
    payload: evento
});

const userRequired = {
    email: '',
    senha: ''
};

//VERIFICAR
export const ListarAplicacoes = user => {
    const userJson = user;
    const userVal = {
        formRequired: userRequired,
        formValues: userJson
    };

    return dispatch => {
        const mensagemDeErro = validarCamposAction(userVal);
        if (validarAction(mensagemDeErro)) {
            toast.error((idiomaText().preenchaErro), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
            return dispatch(mensagemDeErro);
        }
        axios
            .post('/api/aplicacao/web/listar-aplicacoes', userJson)
            .then(response => {
                dispatch(localizacoesCarregadasComSucesso(response.data));
                if (response.data.length < 1) {
                    dispatch(setTipoModalSelLoc('abrindo'));
                }
                else {
                    localStorage.setItem('aplicacao', JSON.stringify(response.data[0]));
                    dispatch(Logar({ ...user, aplicacao: response.data[0] }));
                }
            })
            .catch(erro => {
                dispatch(usuarioNaoLogadoSucesso(erro));
                toast.error((idiomaText().usuarioSenhaErro), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
            });
    };
};

const LocRequired = {
    aplicacao: {}
};

const HandleLoginRouter = _ => history.push("/olts");

export const Logar = (user) => {
    const userJson = user;
    const locVal = {
        formRequired: LocRequired,
        formValues: userJson
    };

    return dispatch => {
        const mensagemDeErro = validarCamposAction(locVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        axios
            .post('/api/autorizacao/login', userJson)
            .then((response) => {
                const userInfo = {
                    ...response.data,
                    preferencia: response.data.preferencia ? response.data.preferencia : defaultPreferences
                };
                dispatch(usuarioLogadoSucesso(userInfo));
                localStorage.setItem('user', JSON.stringify(userInfo));
                localStorage.setItem('aplicacao', JSON.stringify(userInfo.aplicacaoLogado));
                //localStorage.setItem('firstLogin', JSON.stringify(true)); 
                let storedTheme = localStorage.getItem("tema");
                if (storedTheme) {
                    storedTheme = JSON.parse(storedTheme);
                    dispatch(atualizarPreferencia({ ...userInfo, preferencia: { ...userInfo.preferencia, tema: storedTheme } }));
                }
                
                let userLocalStorage = localStorage.getItem("user");
                userLocalStorage = (userLocalStorage != null ? JSON.parse(localStorage.getItem("user")) : null);
                userLocalStorage.firstLogin === true;
                console.log(userLocalStorage.s); 
                !localStorage.getItem('checkedNovoAdapter') ? localStorage.setItem('checkedNovoAdapter', JSON.stringify(false)) : '';

                HandleLoginRouter(userInfo);
            })
            .catch((error) => {
                dispatch(usuarioNaoLogadoSucesso(error));
            });
    };
};

export const deslogar = () => {
    localStorage.removeItem('user');
    return (dispatch) => {
        dispatch(resetarUsuarioLogado());
    };
};

export const RESETAR_USUARIO_LOGADO = "RESETAR_USUARIO_LOGADO";
const resetarUsuarioLogado = () => ({
    type: RESETAR_USUARIO_LOGADO
});

export const EMAIL_ENVIADO_COM_SUCESSO = 'EMAIL_ENVIADO_COM_SUCESSO';
const emailEnviadoComSucesso = (email) => ({
    type: EMAIL_ENVIADO_COM_SUCESSO,
    payload: email
});
export const EMAIL_ENVIADO_COM_ERRO = 'EMAIL_ENVIADO_COM_ERRO';
const emailEnviadoComErro = () => ({
    type: EMAIL_ENVIADO_COM_ERRO,
});

export const RESETAR_REDEFINIR_SENHA = 'RESETAR_REDEFINIR_SENHA';
export const resetarRedefinirSenha = () => ({
    type: RESETAR_REDEFINIR_SENHA
});
const esqueciSenhaRequired = {
    email: ''
};
export const enviarEmailEsqueciSenha = (email) => {
    const userEmail = { email };
    const userVal = {
        formRequired: esqueciSenhaRequired,
        formValues: userEmail
    };
    return (dispatch) => {
        const mensagemDeErro = validarCamposAction(userVal);
        if (validarAction(mensagemDeErro)) {
            toast.error((idiomaText().insiraEmailConta), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
            return dispatch(mensagemDeErro);
        }
        axios
            .post(`/api/usuario/sendEmail?email=${email}`, email)
            .then(response => {
                dispatch(emailEnviadoComSucesso(email));
                if(response.data === false){
                    toast.error((idiomaText().enviarEmailNaoCadastrado), {
                        icon: <div className='divIconToastError'><IcoInfo /></div>
                    });
                    dispatch(emailEnviadoComErro());
                }else{
                    toast.success((idiomaText().enviarEmailSucesso), {
                        icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                    });
                }
            })
            .catch(erro => {
                toast.error((idiomaText().enviarEmailErro), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
                dispatch(emailEnviadoComErro());
            });
    };
};
const redefinirSenhaRequired = {
    senha: '',
    confirmarSenha: ''
};

export const enviarAlteracaoSenha = (inputsNovaSenha) => {
    const alteracaoSenhaJson = inputsNovaSenha;
    const alteracaoSenhaVal = {
        formRequired: redefinirSenhaRequired,
        formValues: alteracaoSenhaJson
    };
    return (dispatch) => {
        let mensagemDeErro = validarCamposAction(alteracaoSenhaVal);
        mensagemDeErro = validarSenha(inputsNovaSenha.senha);
        if (inputsNovaSenha.senha !== inputsNovaSenha.confirmarSenha) {
            toast.error((idiomaText().senhaConfirmarDiferenteErro), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
            return dispatch(mensagemDeErro);
        }
        if (validarAction(mensagemDeErro)) {
            toast.error((idiomaText().senhaInvalida), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
            return dispatch(mensagemDeErro);
        }
        axios
            .post('/api/usuario/resetPassword', { newPassword: inputsNovaSenha.senha, token: inputsNovaSenha.hashUsuario })
            .then(response => {
                history.push('/login');
                toast.success((idiomaText().senhaAlteradaSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                toast.error((idiomaText().senhaAlteradaErro), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
            });
    };
};



export const SET_HASH_VALIDADO = "SET_HASH_VALIDADO";
const setHashValidado = (e) => ({
    type: SET_HASH_VALIDADO,
    payload: e
});

const hashDisponiveis = {
    hash1: "ce85b99cc46752fffee35cab9a7b0278abb4c2d2055cff685af4912c49490f8d",
    hash2: "e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855",
    hash3: "30d87bfcfb2738db64b3b9640218d28690305b30ed99e39707259156b175d117"
};
export const reconhecerHash = (hash) => {
    const backPronto = true;
    return (dispatch) => {
        dispatch(setHashValidado("hashValidada"));
        /* if (backPronto) {
            {
                axios
                    .post('/api/reconhecerHash', { hash })
                    .then(response => {
                        dispatch(hashReconhecidocomSucesso(response.data));
                        dispatch(setHashValidado("hashValidada"));
                    })
                    .catch(erro => {
                        dispatch(setHashValidado("hashNaoReconhecido"));
                        dispatch(hashNaoReconhecido(erro));
                        toast.error(idiomaText().senhaAlteradaErro);
                    });
            }
        }
        else {
            const validaHash = Object.values(hashDisponiveis).filter(hashes => hashes === hash);
            if (validaHash.length > 0) {
                dispatch(setHashValidado("hashValidada"));
            }
            else {
                dispatch(setHashValidado("hashNaoReconhecido"));
            }

            //alert(`Verificar hash: ${hash} no back`)
        } */

    };
};

export const SET_LOCAL = "SET_LOCAL";
export const setLocal = (local) => ({
    type: SET_LOCAL,
    payload: local
});

export const ATUALIZAR_MEUS_DADOS_INPUT = "ATUALIZAR_MEUS_DADOS_INPUT";
export const atualizarMeusDadosInput = (input) => ({
    type: ATUALIZAR_MEUS_DADOS_INPUT,
    payload: input
});

export const LIMPAR_CAMPOS_MEUS_DADOS = "LIMPAR_CAMPOS_MEUS_DADOS_INPUT";
export const limparCamposMeusDados = () => ({
    type: LIMPAR_CAMPOS_MEUS_DADOS
});


const mudarSenhaRequired = {
    senhaAtual: '',
    novaSenha: ''
};

export const trocarSenhaUsuario = (redefinirSenha) => {
    const alteracaoSenhaJson = redefinirSenha;
    const alteracaoSenhaVal = {
        formRequired: mudarSenhaRequired,
        formValues: alteracaoSenhaJson
    };
    return (dispatch) => {
        let mensagemDeErro = validarCamposAction(alteracaoSenhaVal);
        mensagemDeErro = validarSenha(redefinirSenha.novaSenha);
        if (redefinirSenha.novaSenha !== redefinirSenha.confirmarSenha) {
            toast.error((idiomaText().senhaConfirmarDiferenteErro), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
            return dispatch(mensagemDeErro);
        }
        if (redefinirSenha.novaSenha === redefinirSenha.senhaAtual) {
            toast.error((idiomaText().senhaAntigaIgualNova), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
            return dispatch(mensagemDeErro);
        }
        if (validarAction(mensagemDeErro)) {
            toast.error((idiomaText().senhaInvalida), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
            return dispatch(mensagemDeErro);
        }
        axios
            .post('/api/usuario/changePassword', { oldPassword: redefinirSenha.senhaAtual, newPassword: redefinirSenha.novaSenha })
            .then(response => {
                dispatch(limparCamposMeusDados());
                history.push('/olts');
                toast.success((idiomaText().senhaAlteradaSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                toast.error((idiomaText().senhaAlteradaErro), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
            });
    };
};