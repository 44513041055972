import React from 'react';

export function NaoProvisIcon(props) {
    return (
        <svg id={props.id} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <title>{props.title ? props.title : null}</title>
            <path id="Icon_awesome-exclamation-circle" data-name="Icon awesome-exclamation-circle" d="M21.563,11.063a10.5,10.5,0,1,1-10.5-10.5A10.5,10.5,0,0,1,21.563,11.063Zm-10.5,2.117a1.948,1.948,0,1,0,1.948,1.948A1.948,1.948,0,0,0,11.063,13.179Zm-1.849-7,.314,5.758a.508.508,0,0,0,.507.48H12.09a.508.508,0,0,0,.507-.48l.314-5.758a.508.508,0,0,0-.507-.536H9.721A.508.508,0,0,0,9.213,6.179Z" transform="translate(-0.563 -0.563)" fill="#507888" />
        </svg>
    );
}
