import {
    CPES_CARREGADOS_COM_ERRO,
    CPES_CARREGADOS_COM_SUCESSO,
    CARREGANDO_CPES,
    SET_TIPO_MODAL_CPE,
    SET_CPE,
    ATUALIZAR_CONTEUDO_CPE_MODAL,
    CPE_ATUALIZADO_COM_SUCESSO,
    FETCH_CPE_DASHBOARD,
    SERVICOS_CPE_CARREGADOS_COM_SUCESSO,
    CPE_SERVICO_NAO_ATUALIZADO,
    CPE_SERVICO_ATUALIZADO_COM_SUCESSO,
    ATUALIZANDO_CPE_SERVICO,
    ATUALIZAR_CONTEUDO_CPE_SERVICO_MODAL,
    CPE_CARREGADOS_COM_SUCESSO,
    CPE_CARREGADOS_COM_ERRO,
    CARREGANDO_CPE,
    CPES_ATUALIZADOS_COM_SUCESSO,
    SET_CPE_INICIAL,
    CPE_ADICIONADO_COM_SUCESSO,
    CPE_DELETADO_COM_SUCESSO,
    REINICIANDO_CPE,
    CPE_REINICIADA_COM_SUCESSO,
    CPE_NAO_REINICIADA,
    RESETAR_TODAS_COMBOS_NOVO_CPE,
    RESETAR_SLOT_PON_NOVO_CPE,
    ATUALIZAR_FORMULARIO_PESQUISA_DE_CPE,
    LIMPAR_CAMPO_FORMULARIO_PESQUISA_CPE,
    RESETAR_CAMPOS_FORMULARIO_PESQUISA_CPE,
    UPDATE_MODAL_MANAGE_CPE_CONTENT,
    RESET_CPE_STATE,
    HANDLE_IS_LOADING_CPES_STATUS,
    CPE_AND_SERVICES_LOADED_SUCCESSFULLY,
    CLEAR_CPE_FIELD,
    SET_MODAL_ADVANCED_FILTER_CPE,
    SET_FECHAR_MODAL_ADVANCED_FILTER,
    IS_SEARCH
} from "./CpeActions";

const cpeInicial = {
    nome: "",
    modelo: "",
    identificador: "",
    slot: "",
    porta: "",
    status: "",
    cto: "",
    oltVinculada: "",
    potencia: "",
    rx: "",
    tx: "",
    mtu: 1500,
    mppe: false, // false = disable
    senhaWiFi: "",
    ssid: "",
    plano: [],
    pppoeActive: false,
    senhaPPPoE: '',
    usuarioPPPoE: '',
    conexao: "downlink"
};

const initialState = {
    formPesquisa: {
        pesquisaGeral: '',
        olt: '',
        identificador: '',
        modelo: '',
        porta: '',
        estado: '',
        status: '',
        plano: '',
        descricao: '',
    },
    statusModal: [],
    isLoading: false,
    paginacao: {
        totalPages: 0,
        totalElements: 0,
        pageNumber: 0,
    },
    cpes: [],
    statusDataCpe: [],
    cpe: cpeInicial,
    modalAdvancedFilter: '',
    isSearch: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CARREGANDO_CPES:
            return { ...state };

        case CPES_CARREGADOS_COM_ERRO:
            return { ...state };

        case CPES_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                cpes: payload.content ? payload.content : payload,
                paginacao: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number
                },
                isLoading: false
            };

        case SET_TIPO_MODAL_CPE:
            if (payload === '' && state["statusModal"].length === 1) {
                return { ...state, statusModal: [] };
            }
            else if (payload === '') {
                return {
                    ...state,
                    statusModal: state.statusModal.filter((value) => value !== state.statusModal[state.statusModal.length - 1])
                };
            }
            else {
                return { ...state, statusModal: [...state.statusModal, payload] };
            };

        case SET_CPE:
            return {
                ...state,
                cpe: {
                    ...state.cpe,
                    ...payload
                },
            };

        case CARREGANDO_CPE:
            return { ...state };
        case CPE_CARREGADOS_COM_ERRO:
            return { ...state };
        case CPE_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                cpe: payload
            };

        case SERVICOS_CPE_CARREGADOS_COM_SUCESSO:
            return { ...state, cpe: { ...state.cpe, servicos: payload } };

        case ATUALIZANDO_CPE_SERVICO:
            return { ...state };
        case CPE_SERVICO_NAO_ATUALIZADO:
            return { ...state };
        case CPE_SERVICO_ATUALIZADO_COM_SUCESSO:
            return {
                ...state,
                cpe: {
                    ...state.cpe,
                    servicos: state.cpe.servicos.map((servico) =>
                        servico.id === payload.id ? payload : servico,
                    ),
                },
            };

        case SET_TIPO_MODAL_CPE:
            return { ...state, statusModal: payload };

        case SET_CPE:
            return { ...state, cpe: payload };

        case ATUALIZAR_CONTEUDO_CPE_MODAL:
            var casePPPoe = state.cpe.pppoeActive

            if (payload.target.name === 'pppoeActive') {
                casePPPoe = payload.target.value === 'ativar' ? true : false
            }

            return {
                ...state,
                cpe: {
                    ...state.cpe,
                    [payload.target.name]: payload.target.value,
                    senhaPPPoE: payload.target.name === "plano" && payload.target.value.id !== undefined ?
                        (payload.target.value.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE").length > 0 ?
                            (payload.target.value.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE")[0].pppoeSenha || '') : state.cpe.senhaPPPoE) :
                        payload.target.name === 'senhaPPPoE' ? payload.target.value : state.cpe.senhaPPPoE,

                    usuarioPPPoE: payload.target.name === "plano" && payload.target.value.id !== undefined ?
                        (payload.target.value.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE").length > 0 ?
                            (payload.target.value.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE")[0].pppoeUsuario || '') : state.cpe.usuarioPPPoE) :
                        payload.target.name === 'usuarioPPPoE' ? payload.target.value : state.cpe.usuarioPPPoE,
                    pppoeActive: casePPPoe
                },
            };

        case ATUALIZAR_CONTEUDO_CPE_SERVICO_MODAL:
            return {
                ...state,
                cpe: {
                    ...state.cpe,
                    planos: {
                        ...state.cpe.planos,
                        [payload.plano]: {
                            ...state.cpe.planos[payload.plano],
                            [payload.evento.target.name]:
                                payload.evento.target.type === 'checkbox'
                                    ? payload.evento.target.checked
                                    : payload.evento.target.value,
                        },
                    },
                },
            };

        case CPES_ATUALIZADOS_COM_SUCESSO:
            if (typeof payload === "object") {
                let newState = { ...state };
                if (state.cpes.find((cpe) => cpe.id === payload.id) !== undefined) {
                    newState = {
                        ...newState,
                        cpes: state.cpes.map((cpe) => (cpe.id === payload.id ? payload : cpe))
                    };
                } if (state.cpe.id === payload.id) {
                    newState = {
                        ...newState,
                        cpe: payload
                    };
                }
                return newState;
            }
            else {
                return {
                    ...state,
                    cpes: state.cpes.map((cpe) => {
                        if (cpe.id === payload) {
                            return {
                                ...cpe,
                                status: "DELETADO"
                            };
                        }
                        else {
                            return cpe;
                        }
                    }),
                    /* paginacao: {
                        ...state.paginacao,
                        totalElements: state.paginacao.totalElements - 1,
                        totalPages: ((state.paginacao.totalElements - 1) / (12 * (state.paginacao.totalPages - 1)) > 1)
                            ? state.paginacao.totalPages
                            : state.paginacao.totalPages - 1
                    } */
                };
            }

        case SET_CPE_INICIAL:
            return {
                ...state,
                cpe: cpeInicial
            };

        case CPE_ADICIONADO_COM_SUCESSO:
            return {
                ...state,
                cpes: [...state.cpes, payload]
            };

        case CPE_ATUALIZADO_COM_SUCESSO:
            return {
                ...state,
                cpes: state.cpes.map((cpe) => (cpe.id === payload.id ? payload : cpe)),
            };

        case CPE_DELETADO_COM_SUCESSO:
            return {
                ...state,
                cpes: state.cpes.filter((cpe) => cpe.id !== payload),
            };
        case REINICIANDO_CPE:
            return { ...state };
        case CPE_REINICIADA_COM_SUCESSO:
            return {
                ...state,
                cpes: state.cpes.map((cpe) => (cpe.id === payload.id ? payload : cpe)),
            };
        case CPE_NAO_REINICIADA:
            return { ...state };
        case RESETAR_TODAS_COMBOS_NOVO_CPE:
            return {
                ...state,
                //cpe: (JSON.stringify(payload.target.value) === '{}' || payload.target.name === "olt") ? { ...cpeInicial, olt: payload.target.value, identificador: state.cpe.identificador } : state.cpe
                cpe: {
                    ...cpeInicial,
                    olt: payload.target.value,
                    identificador: state.cpe.identificador
                }
            };
        case RESETAR_SLOT_PON_NOVO_CPE:
            return {
                ...state,
                cpe: {
                    ...state.cpe,
                    slot: {}
                }
            };

        //Refatoração de pesquisa de CPE
        case ATUALIZAR_FORMULARIO_PESQUISA_DE_CPE:
            return {
                ...state,
                formPesquisa: {
                    ...state.formPesquisa,
                    [payload.target.name]: Object.keys(payload.target.value).length !== 0 ? payload.target.value : ''
                    //Verificando se o valor do objeto é vazio
                }
            };
        case LIMPAR_CAMPO_FORMULARIO_PESQUISA_CPE:
            return {
                ...state,
                formPesquisa: {
                    ...state.formPesquisa,
                    [payload.currentTarget.name]: ''
                },
                isSearch: false
            };
        case RESETAR_CAMPOS_FORMULARIO_PESQUISA_CPE:
            return {
                ...state,
                formPesquisa: initialState.formPesquisa,
                isSearch: false
            };

        case UPDATE_MODAL_MANAGE_CPE_CONTENT:
            return {
                ...state,
                cpe: {
                    ...state.cpe,
                    [payload.target.name]: payload.target.value
                }
            };
        case RESET_CPE_STATE:
            return initialState;

        case HANDLE_IS_LOADING_CPES_STATUS:
            return {
                ...state,
                isLoading: payload
            };
        case CPE_AND_SERVICES_LOADED_SUCCESSFULLY:
            return {
                ...state,
                cpe: {
                    ...payload.cpe,
                    servicos: payload.servicosDoCpe
                },
                isLoading: false
            };
        case CLEAR_CPE_FIELD:
            return {
                ...state,
                cpe: {
                    ...state.cpe,
                    [payload.currentTarget.name]: ""
                }
            };
        case SET_MODAL_ADVANCED_FILTER_CPE:
            return {
                ...state,
                modalAdvancedFilter: payload
            };
        case SET_FECHAR_MODAL_ADVANCED_FILTER:
            return {
                ...state,
                modalAdvancedFilter: '',
                formPesquisa: initialState.formPesquisa
            };
        case IS_SEARCH:
            return {
                ...state,
                isSearch: Object.values(state.formPesquisa).join("").trim() !== "" ? true : false
            };
        case FETCH_CPE_DASHBOARD:
            return {
                ...state,
                statusDataCpe: payload,
            };
        default:
            return state;
    }
};
