import React from "react"
import { useDispatch, useSelector } from "react-redux"

import BasicModal from "../../../components/modal/BasicModal"

import ConteudoVincularServicoModal from "./conteudoVincularServicoModal"

import { fecharModalServico } from "../redux/ServicoActions"

const Vincular = ({ action }) => {
  const dispatch = useDispatch()

  const locales = useSelector(state => state.layoutState.linguagem)
  const defaltText = require(`../nls/${locales}.json`)

  const statusModal = useSelector(state => state.servicosState.statusModal)

  return (
    <BasicModal
      open={statusModal}
      close={() => dispatch(fecharModalServico())}
      containerModal={{
        title: defaltText.tituloModalVincular,
        size: "medium",
        content: <ConteudoVincularServicoModal />,
        closeText: defaltText.btn.fechar,
        action: {
          acao: action,
          texto: defaltText.btn.vincular,
          class: "success",
        },
      }}
    />
  )
}

export default Vincular
