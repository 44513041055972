import {
    ATUALIZAR_FORMULARIO_PESQUISA_SERVICO,
    ATUALIZAR_CAMPO_PESQUISA_SERVICO,
    LIMPAR_CAMPO_PESQUISA_SERVICO,
    FETCH_SERVICO_OLT_DASHBOARD,
    LIMPAR_CAMPO_PESQUISA_GENERICO,
    LIMPAR_FORM_PESQUISA,
    ATUALIZAR_CONTEUDO_SERVICO_MODAL,
    ATUALIZAR_CONTEUDO_SERVICO_EXISTENTE_MODAL,
    SERVICOS_CARREGADOS_COM_ERRO,
    SERVICOS_CARREGADOS_COM_SUCESSO,
    CARREGANDO_SERVICOS,
    SERVICO_CARREGADOS_COM_ERRO,
    SERVICO_CARREGADOS_COM_SUCESSO,
    CARREGANDO_SERVICO,
    ADICIONANDO_SERVICO,
    SERVICO_NAO_ADICIONADO,
    SERVICO_ADICIONADO_COM_SUCESSO,
    ATUALIZANDO_SERVICO,
    SERVICO_NAO_ATUALIZADO,
    SERVICO_ATUALIZADO_COM_SUCESSO,
    DELETANDO_SERVICO,
    SERVICO_NAO_DELETADO,
    SERVICO_DELETADO_COM_SUCESSO,
    SET_TIPO_MODAL_SERVICO,
    SET_SERVICO,
    SET_SERVICO_INICIAL,
    REINICIANDO_SERVICO,
    SERVICO_REINICIADO_COM_SUCESSO,
    SERVICO_NAO_REINICIADO,
    SERVICOS_ATUALIZADOS_COM_SUCESSO,
    RESETAR_CPE_SELECIONADA,
    RESETAR_CAMPOS_PPPOE,
    HANDLE_IS_LOADING_SERVICES_STATUS,
    RESET_SERVICE_STATE,
    MORE_OLTS_LOADED_SUCCESSFULLY,
    MORE_OK_OLTS_LOADED_SUCCESSFULLY,
    OK_OLTS_LOADED_SUCCESSFULLY,
    OK_OLTS_LOADED_ERROR,
    CLEAR_SERVICO_FIELD,
    SET_MODAL_ADVANCED_FILTER_SERVICE,
    SET_FECHAR_MODAL_ADVANCED_FILTER,
    MAIS_SERVICOS_CARREGADOS_COM_SUCESSO,
    SERVICOS_SEM_ASSINANTE_CARREGADOS_COM_SUCESSO,
    IS_SEARCH,
    ADVANCED_OPTION
} from './ServicoActions';

const servicoInicial = {
    servicoStatus: true,
    nome: '',
    cpe: {},
    plano: {},
    assinante: {},
    olt: {},
    vlans:[],
    senhaPPPoE: '',
    usuarioPPPoE: '',
    pppoeActive: true
};
const initialState = {
    //campoPesquisa: '',
    //Adições para filtro avançado
    //nome: '',
    //cpf: '',
    //nContrato: '',
    formPesquisa: {
        campoPesquisa: '',
        nome: '',
        cpf: '',
        nContrato: ''
    },
    okOlts: {
        olts: [],
        paginacao: {
            totalPages: 0,
            totalElements: 0,
            pageNumber: 0
        }
    },
    statusModal: [],
    isLoading: false,
    paginacao: {
        totalPages: 0,
        totalElements: 0,
        pageNumber: 0
    },
    servicos: [],
    servicosOlt: [],
    servico: servicoInicial,
    modalAdvancedFilter: '',
    servicosSemAssinante: {
        servicos: [],
        paginacao: {
            totalPages: 0,
            totalElements: 0,
            pageNumber: 0
        }
    },
    isSearch: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CARREGANDO_SERVICOS:
            return { ...state };
        case SERVICOS_CARREGADOS_COM_ERRO:
            return { ...state };
        case SERVICOS_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                servicos: payload.content ? payload.content : payload,
                paginacao: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number
                },
                isLoading: false
            };

        case CARREGANDO_SERVICO:
            return { ...state };
        case SERVICO_CARREGADOS_COM_ERRO:
            return { ...state };
        case SERVICO_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                lista: {
                    ...state.lista,
                    [payload[0].tipo]: payload
                }
            };

        case ADICIONANDO_SERVICO:
            return { ...state };
        case SERVICO_NAO_ADICIONADO:
            return { ...state };
        case SERVICO_ADICIONADO_COM_SUCESSO:
            return { ...state, servicos: [...state.servicos, payload] };

        case ATUALIZANDO_SERVICO:
            return { ...state };
        case SERVICO_NAO_ATUALIZADO:
            return { ...state };
        case SERVICO_ATUALIZADO_COM_SUCESSO:
            return {
                ...state,
                servicos: state.servicos.map((servico) => (servico.id === payload.id ? payload : servico))
            };

        case DELETANDO_SERVICO:
            return { ...state };
        case SERVICO_NAO_DELETADO:
            return { ...state };
        case SERVICO_DELETADO_COM_SUCESSO:
            return {
                ...state,
                servicos: state.servicos.filter(servico => servico.id !== payload)
            };
        case SET_TIPO_MODAL_SERVICO:
            if (payload === '' && state["statusModal"].length === 1) {
                return { ...state, statusModal: [] };
            }
            else if (payload === '') {
                return {
                    ...state,
                    statusModal: state.statusModal.filter((value) => value !== state.statusModal[state.statusModal.length - 1])
                };
            }
            else {
                return { ...state, statusModal: [...state.statusModal, payload] };
            }

        case SET_SERVICO:
            return { ...state, servico: payload };

        case SET_SERVICO_INICIAL:
            return { ...state, servico: servicoInicial };

        case ATUALIZAR_CAMPO_PESQUISA_SERVICO:
            return { ...state, campoPesquisa: payload };

        //Teste filtro avançado
        case ATUALIZAR_FORMULARIO_PESQUISA_SERVICO:
            return {
                ...state,
                formPesquisa: {
                    ...state.formPesquisa,
                    [payload.target.name]: Object.keys(payload.target.value).length !== 0 ? payload.target.value : ''
                }
            };


        case LIMPAR_CAMPO_PESQUISA_SERVICO:
            return {
                ...state,
                formPesquisa: {
                    ...state.formPesquisa,
                    campoPesquisa: '',
                    isSearch: false
                    /* nome: '',
                    cpf: '',
                    nContrato: '', */ //Descomentar este bloco, fará com que todo o formulário de pesquisa seja limpo!
                }
            };
        //Teste filtro avançado

        case ATUALIZAR_CONTEUDO_SERVICO_MODAL:
            var casePPPoe = state.servico.pppoeActive

            if (payload.target.name === 'pppoeActive') {
                casePPPoe = payload.target.value === 'ativar' ? true : false
            }

            return {
                ...state,
                servico: {
                    ...state.servico,
                    [payload.target.name !== 'modelo' ? payload.target.name : '']: payload.target.type === 'checkbox' ? payload.target.checked : payload.target.name === 'modelo' ? '' : payload.target.value,
                    senhaPPPoE: payload.target.name === "plano" && payload.target.value.id !== undefined ?
                        (payload.target.value.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE").length > 0 ?
                            (payload.target.value.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE")[0].pppoeSenha || '') : state.servico.senhaPPPoE) :
                        payload.target.name === 'senhaPPPoE' ? payload.target.value : state.servico.senhaPPPoE,

                    usuarioPPPoE: payload.target.name === "plano" && payload.target.value.id !== undefined ?
                        (payload.target.value.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE").length > 0 ?
                            (payload.target.value.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE")[0].pppoeUsuario || '') : state.servico.usuarioPPPoE) :
                        payload.target.name === 'usuarioPPPoE' ? payload.target.value : state.servico.usuarioPPPoE,
                    pppoeActive: casePPPoe,
                    cpe: {
                        ...state.servico.cpe,
                        ...payload.target.name === 'cpe' ? payload.target.value : state.servico.cpe,
                        modelo: payload.target.name === 'modelo' ? payload.target.value : payload.target.name === 'cpe' ? payload.target.value.modelo : state.servico.cpe.modelo
                    },
                }
            };
        case ATUALIZAR_CONTEUDO_SERVICO_EXISTENTE_MODAL:
            return {
                ...state,
                servico: {
                    //...[payload.e.target.value],
                    ...payload.e.target.value,
                    idAssinante: payload.assinante.id
                }
                //servico: payload.target.type === 'checkbox' ? payload.target.checked : payload.target.value
            };

        case REINICIANDO_SERVICO:
            return { ...state };

        case SERVICO_REINICIADO_COM_SUCESSO:
            return { ...state, servicos: state.servicos.map(servico => (servico.id === payload.id ? payload : servico)) };

        case SERVICO_NAO_REINICIADO:
            return { ...state };

        case SERVICOS_ATUALIZADOS_COM_SUCESSO:
            if (typeof payload === "object") {
                if (state.servicos.find((servico) => servico.id === payload.id) !== undefined) {
                    return {
                        ...state,
                        servicos: state.servicos.map((servico) => (servico.id === payload.id ? payload : servico))
                    };
                }
                return state;
            }
            else {
                return {
                    ...state,
                    servicos: state.servicos.map((servico) => {
                        if (servico.id === payload) {
                            return {
                                ...servico,
                                status: "DELETADO"
                            };
                        }
                        else {
                            return servico;
                        }
                    }),
                    /* paginacao: {
                        ...state.paginacao,
                        totalElements: state.paginacao.totalElements - 1,
                        totalPages: ((state.paginacao.totalElements - 1) / (12 * (state.paginacao.totalPages - 1)) > 1)
                            ? state.paginacao.totalPages
                            : state.paginacao.totalPages - 1
                    } */
                };
            }

        case LIMPAR_CAMPO_PESQUISA_GENERICO:
            return {
                ...state,
                formPesquisa: {
                    ...state.formPesquisa,
                    [payload.currentTarget.name]: ''
                },
                isSearch: false
            };

        case LIMPAR_FORM_PESQUISA:
            return {
                ...state,
                formPesquisa: initialState.formPesquisa,
                isSearch: false
            };
        case RESETAR_CPE_SELECIONADA:
            return {
                ...state,
                formPesquisa: {
                    ...state.formPesquisa,
                    cpe: {}
                },
                servico: {
                    ...state.servico,
                    cpe: {}
                }
            };

        case RESETAR_CAMPOS_PPPOE:
            /* Object.keys(payload).forEach(key => {
                if (key.includes("senha") || key.includes("usuario")) {
                    payload[key] = '';
                }
            }); */
            return {
                ...state,
                servico: {
                    ...state.servico,
                    senhaPPPoE: '',
                    usuarioPPPoE: ''
                }
            };
        case HANDLE_IS_LOADING_SERVICES_STATUS:
            return {
                ...state,
                isLoading: payload
            };
        case RESET_SERVICE_STATE:
            return initialState;

        case OK_OLTS_LOADED_SUCCESSFULLY:
            return {
                ...state,
                okOlts: {
                    olts: payload.content ? payload.content : payload,
                    paginacao: {
                        totalPages: payload.totalPages,
                        totalElements: payload.totalElements,
                        pageNumber: payload.number,
                    }
                }
            };

        case MORE_OK_OLTS_LOADED_SUCCESSFULLY:
            return {
                ...state,
                okOlts: {
                    olts: state.okOlts.olts.concat(payload.content ? payload.content : payload),
                    paginacao: {
                        totalPages: payload.totalPages,
                        totalElements: payload.totalElements,
                        pageNumber: payload.number,
                    }
                }
            };
        case OK_OLTS_LOADED_ERROR:
            return { ...state };
        case CLEAR_SERVICO_FIELD:
            return {
                ...state,
                servico: {
                    ...state.servico,
                    [payload.currentTarget.name]: ""
                }
            };
        case SET_MODAL_ADVANCED_FILTER_SERVICE:
            return {
                ...state,
                modalAdvancedFilter: payload
            };
        case SET_FECHAR_MODAL_ADVANCED_FILTER:
            return {
                ...state,
                modalAdvancedFilter: ''
            };
        case SERVICOS_SEM_ASSINANTE_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                servicosSemAssinante: {
                    servicos: payload.content ? payload.content : payload,
                    paginacao: {
                        totalPages: payload.totalPages,
                        totalElements: payload.totalElements,
                        pageNumber: payload.number,
                    }
                }
            };
        case MAIS_SERVICOS_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                servicosSemAssinante: {
                    servicos: state.servicosSemAssinante.servicos.concat(payload.content ? payload.content : payload),
                    paginacao: {
                        totalPages: payload.totalPages,
                        totalElements: payload.totalElements,
                        pageNumber: payload.number,
                    }
                }
            };
        case IS_SEARCH:
            return {
                ...state,
                isSearch: Object.values(state.formPesquisa).join("").trim() !== "" ? true : false
            };
        case FETCH_SERVICO_OLT_DASHBOARD:
            return {
                ...state, 
                servicosOlt: payload
            };
        default:
            return state;
    }
};
