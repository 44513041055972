import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../components/modal/BasicModal";
import { fecharModalPlano } from "../redux/PlanosActions";
import InfoPlanosConteudo from "./infomacoesConteudo";

const InfoPlano = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const statusModal = useSelector((state) => state.planosState.statusModal);
    const plano = useSelector((state) => state.planosState.plano);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalPlano())}
            containerModal={{
                title: (defaultText.tituloModalInformacoes + ' - ' + plano.nome),
                size: "large",
                content: (
                    <>
                        <InfoPlanosConteudo />
                    </>
                ),
                closeText: defaultText.btn.fechar,
            }}
        />
    );
};

export default InfoPlano;