import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import { fecharModalOlt, deletarOlt, listarOlts, abrirModalConfirmaDeletar } from '../redux/OltActions';

const Remover = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const olt = useSelector(state => state.oltsState.olt);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const oltLocal = useSelector((state) => state.authentication.user.aplicacaoLogado.nomeLocal);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalOlt())}
            containerModal={{
                title: defaltText.tituloModalRemover,
                size: 'medium',
                content: (
                    <>
                        <p>{defaltText.mensagem.remover}
                            <span style={{ fontWeight: "bold" }}>{olt.nome} </span>
                            {defaltText.mensagem.in}
                            {oltLocal}
                        </p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    acao: () => dispatch(abrirModalConfirmaDeletar(olt)),
                    texto: defaltText.btn.remover,
                    class: "success"
                }
            }}
        />
    );
};

export default Remover;
