import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import ConteudoCpeModal from './conteudoCriarEditarCpe';

import { fecharModalCpe, adicionarCpe, atualizarCpe } from '../redux/CpeActions';

const CriarEditar = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const statusModal = useSelector(state => state.cpesState.statusModal);
    const cpe = useSelector(state => state.cpesState.cpe);
    const [oltSelected, setOltSelected] = useState();
    const title = cpe.id ? defaltText.tituloModalEditar : defaltText.tituloModalNovo;

    const action = cpe.id ? atualizarCpe : adicionarCpe;
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalCpe())}
            containerModal={{
                title,
                size: 'medium',
                content: <ConteudoCpeModal setOltSelected={setOltSelected} oltSelected={oltSelected}/>,
                closeText: defaltText.btn.fechar,
                action: {
                    
                    btnId: "btnSalvar",
                    acao: () => dispatch(action(cpe, oltSelected, 0)),
                    texto: defaltText.btn.salvar,
                    class: 'success',
                    disabled: !oltSelected?.id
                }
            }}
        />
    );
};

export default CriarEditar;
