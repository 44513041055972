import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import './pagamentos.css';
import Titulo from './Titulo';
import CriarEditar from './modal/CriarEditar';
import Remover from './modal/Remover';
import List from '../../components/list/List';
import { IcoEditar, IcoRemoveLine } from '../../components/icones';

import { listarPagamentos, abrirModalEditarPagamento, abrirModalDeletarPagamento } from './redux/PagamentoActions';

const Pagamentos = props => {
    const { listarPagamentos } = props;

    useEffect(() => {
        listarPagamentos();
    }, [listarPagamentos]);

    const defaltText = require(`./nls/${props.locales}.json`);

    const listHead = [
        {
            colunm: 'nContrato',
            text: defaltText.inputs.contrato,
            className: 'cal-1'
        },
        {
            colunm: 'nome',
            text: defaltText.inputs.cliente,
            className: 'text-left'
        },
        {
            colunm: 'situacao',
            text: '',
            className: 'cal-1'
        },
        {
            colunm: 'actions',
            text: '',
            className: 'actions'
        }
    ];

    const listActions = val => {
        val.actions = (
            <>
                <button
                    title={defaltText.editar}
                    className='btn-secondary'
                    onClick={() => props.abrirModalEditarPagamento(val)}
                >
                    {' '}
                    <IcoEditar title={defaltText.editar} />
                </button>
                <button title={defaltText.remover} className='btn-danger' onClick={() => props.abrirModalDeletarPagamento(val)}>
                    {' '}
                    <IcoRemoveLine title={defaltText.remover} />
                </button>
            </>
        );
    };

    return (
        <section className='box-pagamentos'>
            <Titulo title={defaltText.title} />
            <List data={{ head: listHead, content: props.pagamentos }} listActions={val => listActions(val)} />
            {props.statusModal === 'criando' || props.statusModal === 'editando' ? <CriarEditar /> : null}
            {props.statusModal === 'deletando' ? <Remover /> : null}
        </section>
    );
};

const mapStateToProps = store => {
    return {
        locales: store.authentication.user.preferencia.language,
        pagamentos: store.pagamentosState.pagamentos,
        statusModal: store.pagamentosState.statusModal
    };
};

const mapDispatchToProps = {
    listarPagamentos,
    abrirModalEditarPagamento,
    abrirModalDeletarPagamento
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagamentos);
