import { useDispatch, useSelector } from 'react-redux'

import InputInbox from '../../../../components/form/inputInBox/InputInbox'
import { MultiSelectInBox } from '../../../../components/form'
import React from 'react'
import { atualizarConteudoPerfilModal } from '../../redux/PerfilActions'
import { validarCampoAction } from '../../../../validation/validationActions'
import Row from '../../../../components/row/row'

const ConteudoPerfilModal = () => {
  const dispatch = useDispatch()

  const locales = useSelector(state => state.layoutState.linguagem)
  const perfil = useSelector(state => state.perfisState.perfil)
  const telas = useSelector(state => state.perfisState.telas)

  const defaltText = require(`../../nls/${locales}.json`)
  const mensagemErro = useSelector(state => state.validacaoState)

  return (
    <section className='box-criar-editar-perfis'>
      <Row>
        <div className='col-md-12'>
          <InputInbox
            name='nome'
            label={defaltText.inputs.nome}
            value={perfil.nome}
            changeInput={e => dispatch(atualizarConteudoPerfilModal(e))}
            validate={{
              action: e => dispatch(validarCampoAction(e)),
              erro: mensagemErro.nome ? defaltText.mensagem.obrigatorio : ''
            }}
            length={{
              max: '50'
            }}
          />
        </div>
      </Row>
      <Row>
        <div className='col-md-12'>
          <MultiSelectInBox
            id='permissoes'
            label={defaltText.inputs.permissoes}
            selectDefault={{
              id: 'id',
              label: 'nome',
              text: defaltText.select
            }}
            select={{
              action: e => dispatch(atualizarConteudoPerfilModal(e)),
              options: telas,
              selected: perfil.permissoes
            }}
          />
        </div>
      </Row>
    </section>
  )
}

export default ConteudoPerfilModal
