import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import marked from 'marked';
import './releaseNotes.css';
import releaseNotesEN from './en-US.md';
import releaseNotesPT from './pt-BR.md';

const ReleaseNotesConteudo = () => {
    const locales = useSelector((state) => state.layoutState.linguagem);
    const [markdown, setMarkdown] = useState('');
    const notes = locales == 'pt-BR' ? releaseNotesPT : releaseNotesEN;

    //pega o arquivo .md e faz a conversão para HTML utilizando o component 'marked'
    useEffect(() => {
        fetch(notes)
            .then(response => {
                return response.text();
            })
            .then(text => {
                setMarkdown(marked(text));
            });
    }, [locales]);

    //retorna o html que foi gerado anteriormente, para essa tag 'article'
    return (
        <div className="releaseNotes">
            <article dangerouslySetInnerHTML={{ __html: markdown }}></article>
        </div >
    );
};

export default ReleaseNotesConteudo;