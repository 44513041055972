import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InputInbox from '../../components/form/inputInBox/InputInbox';
import { IcoMais, IcoSearch, IcoSandwichMenu, IcoFilter } from '../../components/icones';

import Button from '../../components/button/Button';
import {
    abrirModalCriarServico,
    atualizarCampoPesquisa,
    atualizarFormularioDePesquisa,
    isSearch,
    limparCampoPesquisa,
    limparCampoPesquisaGenerico,
    limparFormPesquisa,
    listarServicos,
    resetarCpeSelecionada,
    setModalAdvancedFilterService
} from './redux/ServicoActions';

//Modificações Silvio, para filtro de serviços
import './barraDePesquisa.css';
import { SelectInBox } from '../../components/form';
import { atualizarConteudoServicoModal } from './redux/ServicoActions';
import { listarPlanos } from '../planos/redux/PlanosActions';
import { listarOlts, listarCpes } from '../olt/redux/OltActions';
import Row from '../../components/row/row';
import { IcoFilterClose } from '../../components/icones/filter-close';
import { getPagePermissions } from '../../utils/utils';
import Input from '../../layouts/components/Input/Input';
import AdvancedFilter from '../../layouts/components/AdvancedFilter/AdvancedFilter';
import AdvancedFilterServices from './advancedFilter/advancedFilterService';

export default () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);

    const planos = useSelector(state => state.planosState.planos);
    const olts = useSelector(state => state.oltsState.olts);
    const cpes = useSelector(state => state.oltsState.cpes);
    const paginacaoCpes = useSelector(state => state.oltsState.paginacaoCpes);
    const servico = useSelector(state => state.servicosState.servico);
    const formularioPesquisa = useSelector(state => state.servicosState);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const modalAdvancedFilter = useSelector((state) => state.servicosState.modalAdvancedFilter);

    const handleChangeModalAdvancedFilter = (modalAdvancedFilter) => {
        dispatch(setModalAdvancedFilterService(modalAdvancedFilter));
        dispatch(listarPlanos(0));
        dispatch(listarOlts(0));
        dispatch(listarCpes(0));
    };

    const getModalAdvancedFilterOpen = (modalAdvancedFilter) => {
        switch (modalAdvancedFilter) {
            case 'advancedFilter':
                return <AdvancedFilter classAnchor='modal-Advanced-Services' children={<AdvancedFilterServices />} />;
            default:
                return;
        }
    };

    useEffect(() => {
        return function cleanup() {
            dispatch(limparFormPesquisa());
        };

    }, [dispatch]);

    const ListCpes = e => {
        dispatch([atualizarConteudoServicoModal(e), listarCpes({ olt: e.target.value.id })]);
    };

    const handleOnPressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(listarServicos({ pesquisa: formularioPesquisa }));
        }
    };

    let pesquisaItens = {
        ...(formularioPesquisa.formPesquisa.campoPesquisa !== '' && { campoPesquisa: ` "${formularioPesquisa.formPesquisa.campoPesquisa}"` }),
        ...(formularioPesquisa.formPesquisa.cpf !== '' && { cpf: ` "${formularioPesquisa.formPesquisa.cpf}"` }),
        ...(formularioPesquisa.formPesquisa.nContrato !== '' && { nContrato: ` "${formularioPesquisa.formPesquisa.nContrato}"` }),
        ...(formularioPesquisa.formPesquisa.nome !== '' && { nome: ` "${formularioPesquisa.formPesquisa.nome}"` }),
        ...(formularioPesquisa.formPesquisa.olt && { oltNome: ` "${formularioPesquisa.formPesquisa.olt.nome}"` }),
        ...((formularioPesquisa.formPesquisa.cpe && JSON.stringify(formularioPesquisa.formPesquisa.cpe) !== '{}') && { cpe: ` "${formularioPesquisa.formPesquisa.cpe.identificador}"` }),
        ...(formularioPesquisa.formPesquisa.plano && { plano: ` "${formularioPesquisa.formPesquisa.plano.nome}"` }),
    };

    return (
        <div className="box-servicos">
            <Row style={{ justifyContent: "space-between" }}>
                <div className="col-md-10">
                    <div className={`campo-pesquisa`} name="campoPesquisa">
                        <Input
                            id="campoPesquisa"
                            name='campoPesquisa'
                            label={defaltText.pesquisar.text}
                            value={formularioPesquisa.formPesquisa.campoPesquisa}
                            changeInput={e => dispatch(atualizarFormularioDePesquisa(e))}
                            onPressEnter={(e) => handleOnPressEnter(e)}
                            clearField={{
                                action: (e) => dispatch(limparCampoPesquisaGenerico(e)),
                                text: defaltText.btn.limparCampo
                            }}
                            leftButton={{
                                action: () => { dispatch(listarServicos({ pesquisa: formularioPesquisa })), dispatch(isSearch()); },
                                text: defaltText.pesquisar.text,
                                icon: <IcoSearch title={defaltText.pesquisar.text} />
                            }}
                        />
                        <Button
                            value="campoPesquisa"
                            titulo={defaltText.pesquisar.advancedFilter}
                            action={() => handleChangeModalAdvancedFilter('advancedFilter')}
                            texto={defaltText.pesquisar.advancedFilter}
                            children={modalAdvancedFilter === 'advancedFilter' ? <IcoFilterClose title={defaltText.pesquisar.advancedFilter} /> : <IcoFilter title={defaltText.pesquisar.advancedFilter} />}
                            color="primary"
                            type='btn'
                        />
                    </div>

                </div>
                <div className="col-md-2 div-new-button">
                    <Button
                        hasAccess={getPagePermissions(permissions, "servicos", "create")}
                        titulo={defaltText.btn.adicionarServico}
                        action={() => dispatch(abrirModalCriarServico())}
                        texto={defaltText.btn.adicionarServico}
                        children={<IcoMais className="btn-border" title={defaltText.btn.adicionarServico} />}
                        color='success'
                        type='btn'
                    />
                </div>
            </Row>
            {getModalAdvancedFilterOpen(modalAdvancedFilter)}
            {formularioPesquisa.isSearch === true ?
                <p className='qntRegisters'>
                    <span className='boldText'>
                        {formularioPesquisa.paginacao.totalElements}
                    </span> {defaltText.itens}
                    <span className='boldText'>
                        {Object.values(pesquisaItens).join(", ")}
                    </span>
                </p> : null}
            {formularioPesquisa.isSearch === true ? <div className='divisorLine'></div> : null}

        </div>
    );
};
