import React from 'react';
import "./cards.css";
import { IcoEditar, IcoRemoveLine, } from '../../../components/icones';
import CardButton from '../cardButton/CardButton';
import history from '../../../history';
import { abrirModalDeletarOlt, abrirModalEditarOlt, abrirModalErroOlt } from '../../../modulos/olt/redux/OltActions';
import { useDispatch, useSelector } from 'react-redux';
import { getPagePermissions, translateStatus } from '../../../utils/utils';
import CardHeaderButton from '../cardHeaderButton/CardHeaderButton';
import { AttentionIcon, CorrectIcon, InfoIcon, SyncIcon, WanLanIcon, ExclamationIcon } from '../icons';
import { OfflineIcon } from '../../../layouts/components/icons/OfflineIcon';
import Progress from '../../../components/progress/Progress';
/* import { NaoProvisIcon } from '../../../layouts/components/icons/NaoProvisIcon'; */

const OltCard = ({ markerColor = "ok", item, labels }) => {
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../../modulos/olt/nls/${locales}.json`);
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const getOltCardHeaderButton = (val) => {
        const dispatch = useDispatch();
        if (val.status === 'OK' || val.status === 'ONLINE') {
            return <CardHeaderButton icon={<CorrectIcon title={val.status} />} markerColor={markerColor} />;
        }
        else {
            if (val.status.includes("ERRO")) {
                return <CardHeaderButton label={defaultText.btn.reenviar} title={defaultText.mensagem.reenviarComando} disabled={getPagePermissions(permissions, "olts", "update") ? false : true} icon={<AttentionIcon title={translateStatus(val.status, locales)} />} action={() => dispatch(abrirModalErroOlt(val))} markerColor={markerColor} />;
            }
            else if (val.status.includes("OFFLINE")) {
                return <CardHeaderButton icon={<OfflineIcon title={val.status} />} markerColor={markerColor} />;
            }
            else if (val.status.includes("NAO_SUPORTADA")) {
                return <CardHeaderButton icon={<AttentionIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
            else if(val.status.includes("ADAPTER_NAO_CONECTADO")){
                return <CardHeaderButton icon={<SyncIcon className="spin" title={translateStatus(val.status, locales)} color='var(--offline)' />} markerColor={markerColor} />;
            }
            else {
                return <CardHeaderButton icon={<SyncIcon className="spin" title={val.status} />} markerColor={markerColor} />;
            }
        }
    };

    const porcentagemBarra = (item) => {
        return item.barraProgressoLeitura * 8.33;
    };

    return (
        <div className={`cartao ${markerColor}`}>
            <div className="cartao-cabecalho">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p><span className="bold-text">{item[labels[0].field]}</span></p>
                    <CardHeaderButton markerColor={markerColor} icon={getOltCardHeaderButton(item)} />
                </div>
                <div className="section-divisor"></div>
            </div>

            <div className="cartao-corpo">
                {labels.map((label, index) => index > 0 && <p>{label.text}: <span id={"status-olt"} className={`bold-text ${label.field === "status" && markerColor}`}>{Array.isArray(item[label.field]) ? item[label.field].join(", ") : translateStatus(item[label.field], locales)}</span></p>)}
            </div>

            {(item.barraProgressoLeitura === 0 || item.barraProgressoLeitura >= 12) && item.status !== "CARREGANDO" ?
                (<div className="cartao-rodape">
                    <CardButton
                        id="btnVLans"
                        label={defaultText.btn.vlans}
                        action={() => history.push(`/vlans/olts/${item.id}`)}
                        icon={<WanLanIcon />}
                        disabled={(item.status !== "OK") ? true : false}
                    />

                    <div className="vertical-section-divisor"></div>

                    <CardButton
                        id="btnInfo"
                        label={defaultText.btn.informacoes}
                        action={() => { history.push(`/informacoes/olts/${item.id}`); }}
                        icon={<InfoIcon />}
                        disabled={(item.status !== "OK") ? true : false}
                    />

                    <div className="vertical-section-divisor"></div>

                    <CardButton
                        hasAccess={getPagePermissions(permissions, "olts", "update")}
                        id="btnEditar"
                        label={defaultText.btn.editar}
                        action={() => dispatch(abrirModalEditarOlt(item))}
                        icon={<IcoEditar />}
                        disabled={
                            (item.status === "CONECTANDO" ||
                                item.status === "EDITANDO" ||
                                item.status === "REINICIANDO" ||
                                item.status === "DELETADO" ||
                                !item.status === "ERRO_DE_CREDENCIAIS")
                                ? true : false
                        }
                    />

                    <div className="vertical-section-divisor"></div>

                    <CardButton
                        hasAccess={getPagePermissions(permissions, "olts", "delete")}
                        id="btnRemover"
                        label={defaultText.btn.remover}
                        action={() => dispatch(abrirModalDeletarOlt(item))}
                        icon={<IcoRemoveLine />}
                        disabled={(item.status !== "OK" && !item.status.includes("ERRO") && !item.status.includes("NAO_SUPORTADA") && !item.status.includes("ADAPTER_NAO_CONECTADO") && !item.status.includes("OFFLINE")) ? true : false}
                    />
                </div>)
                :
                <div className="cartao-progresso">
                    <p>{defaultText.mensagem.dadosBarraProgresso}</p>
                    <Progress
                        text={defaultText.informacoes.barraProgresso}
                        type="progress-bar"
                        progressVale={porcentagemBarra(item)}
                    />
                </div>
            }
        </div>
    );
};

export default OltCard;
