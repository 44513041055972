import React from 'react';
import { BrazilIcon, UsaIcon } from '../components/icons';
import {
    SIDEBAR_LEFT,
    SIDEBAR_RIGHT,
    CARREGANDO_IDIOMAS,
    IDIOMAS_CARREGADOS_COM_SUCESSO,
    ATUALIZANDO_PREFERENCIA,
    PREFERENCIA_ATUALIZADA_COM_SUCESSO,
    SET_TIPO_MODAL,
    ajustIdioma,
    ATUALIZAR_TEMA,
    SET_MODAL_HEADER_TYPE,
    ABRIR_TOOLTIP,
    FECHAR_TOOLTIP
} from './LayoutActions';

const lightTheme = { id: "light-theme", nome: "Tema Claro" };
const darkTheme = { id: "dark-theme", nome: "Tema Escuro" };
const storedTheme = localStorage.getItem("tema");

const portuguese = { id: "PT_BR", nome: "Português", use: "pt-BR", resource: <BrazilIcon /> };
const english = { id: "EN_US", nome: "English", use: "en-US", resource: <UsaIcon /> };

const initialStateTooltip = {
    open: false,
    posicao: {
        top: 0,
        left: 0
    },
    local: {
        nome: '',
        descricao: ''
    }
};
const initialState = {
    statusModal: [],
    modalHeaderType: "",
    sidebarRight: false,
    sidebarLeft: true,
    linguagens: [portuguese, english],
    linguagem: 'pt-BR',
    tema: 'ESCURO_CINZA',
    themes: [lightTheme, darkTheme],
    tooltipLocais: initialStateTooltip,
    selectedTheme: storedTheme !== null ? JSON.parse(storedTheme) : darkTheme
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ABRIR_TOOLTIP:
            return {
                ...state, tooltipLocais: {
                    open: true,
                    posicao: payload.posicao,
                    local: payload.local
                }
            };
        case FECHAR_TOOLTIP:
            return { ...state, tooltipLocais: initialStateTooltip };
        case SIDEBAR_LEFT:
            return { ...state, sidebarLeft: payload };
        case SIDEBAR_RIGHT:
            return { ...state, sidebarRight: payload };

        case CARREGANDO_IDIOMAS:
            return { ...state };
        case IDIOMAS_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                /* linguagens: payload.map(item => ajustIdioma(item)) */
            };

        case ATUALIZANDO_PREFERENCIA:
            return { ...state };
        case PREFERENCIA_ATUALIZADA_COM_SUCESSO:
            return {
                ...state,
                linguagem: payload.preferencia.linguagem ? ajustIdioma(payload.preferencia.linguagem).use : payload.preferencia.language,
                //selectedTheme: payload.preferencia.tema
            };
        case SET_TIPO_MODAL:
            if (payload === '' && state["statusModal"].length === 1) {
                return { ...state, statusModal: [] };
            }
            else if (payload === '') {
                return {
                    ...state,
                    statusModal: state.statusModal.filter((value) => value !== state.statusModal[state.statusModal.length - 1])
                };
            }
            else {
                return { ...state, statusModal: [...state.statusModal, payload] };
            }
        case ATUALIZAR_TEMA:
            return { ...state, selectedTheme: payload };
        case SET_MODAL_HEADER_TYPE:
            return {
                ...state,
                modalHeaderType: payload
            };
        default:
            return state;
    }
};
