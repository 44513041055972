const typePatern = {
    phone: /^\+\d{1,}(?:\s\d{1,})*$/g,
    //\((10)|(?[1-9][1-9])\) [2-9][0-9]{3}-[0-9]{4}
    email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
    ip: /^((?:(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))+$/gm,
    ipStruct: /^((?:\d{1,}\.){3}\d{1,})+$/g,
    urlHostname: /^[a-z0-9./?$:\-#]+$/gmi,
    number: /[0-9]+$/,
    cep: /((^\d{5}-?\d{3})|(^\d{8}))$/g,
    cpf: /((^\d{3}\.?\d{3}\.?\d{3}\-?\d{2}$)|(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}\-?\d{2}$)|(^\d{14})|(^\d{11}))$/gmi,
    ssid: /^[0-9a-zA-Z]{1,25}$/g,
    wifiPassword: /^[0-9a-zA-Z]{8,25}$/g,
    identificadorCpe: /^[A-Z]{4}\-?[0-9a-fA-Z]{8}/gm
};

export const validacaoCampo = ({ valor, name, pattern }) => {
    const erro = {};

    if (valor !== "" && valor.search(typePatern[pattern] ? typePatern[pattern] : new RegExp(pattern)) === -1) {
        erro.campo = name;
        erro.mensagem = "pattern";

        if (pattern === "ip" && valor.search(typePatern['ipStruct']) === -1) {

            var result = valor.search(typePatern['urlHostname']);

            if (result === -1) result = "pattern";
            else result = false;

            erro.campo = name;
            erro.mensagem = result;
        }

    } else {
        erro.campo = name;
        erro.mensagem = false;

    }
    return erro;
};

export const validacaoDoCampo = (e, onlyPattern) => {
    const valor = e.target.value;
    const { name, pattern } = e.target;

    if (!valor && !onlyPattern) {
        return { campo: name, mensagem: true };
    }

    return validacaoCampo({ valor, name, pattern });
};

export const validacaoDosCampos = e => {
    let erro = {};

    for (const name in e.formValues) {
        if (e.formPatternRequired && e.formPatternRequired[name] && typeof e.formValues[name] === "string" && e.formPatternRequired[name] !== "" && e.formValues[name] !== "") {
            const valForm = validacaoCampo({
                valor: e.formValues[name],
                name,
                pattern: e.formPatternRequired[name]
            });

            erro = { ...erro, [name]: valForm.mensagem };
        }
    }

    for (const name in e.formRequired) {

        if (e.formRequired[name]) {
            if (e.formValues[name] === undefined || e.formValues[name] === null) {
                erro = { ...erro, [name]: true };
                continue;
            }
        }


        //verifica se o valor dentro da chave e.formRequired[name] é um array
        if (Array.isArray(e.formRequired[name])) {
            //caso seja um array, verifica se está marcado
            if (e.formValues[name]) {
                // Se algum estiver marcado, não terá erro de ausência de protocolo
                erro['erroProtocolo'] = false;
                // Percorre os itens relacionados àquela chave para saber se estão preenchidos
                for (const i in e.formRequired[name]) {
                    if (!e.formValues[e.formRequired[name][i]]) {
                        erro = { ...erro, [e.formRequired[name][i]]: true };
                    }
                    else {
                        erro = { ...erro, [e.formRequired[name][i]]: false };
                    }
                }
            }
        }
        else if (typeof (e.formRequired[name]) === "string") {
            //STRING
            //Verifica se há algum padrão a ser verificado
            if (e.formRequired[name] && e.formValues[name] !== "") {
                const valForm = validacaoCampo({
                    valor: e.formValues[name],
                    name,
                    pattern: e.formRequired[name]
                });

                erro = { ...erro, [name]: valForm.mensagem };
            }
            else {
                //Se não houver padrão a ser verificado, verifica se o campo está preenchido
                erro = { ...erro, [name]: !e.formValues[name] };
            }
        }
        //Verifica se é um objeto e se o objeto recebido está vazio
        else if (e.formRequired[name].constructor === Object) {
            if (Object.keys(e.formValues[name]).length === 0) erro = { ...erro, [name]: true };
            for (const i in e.formRequired[name]) {
                if (e.formValues[name][i] === '') {
                    erro = { ...erro, [i]: true };
                }
            }

        }
    }

    //Verifica se não existe espaço em branco no início ou no fim do campo
    for (const name in e.formValues) {
        /* if (typeof e.formValues[name] !== "object" && e.formValues[name] !== undefined && e.formValues[name] !== null) {
          if (e.formValues[name][0] === " " || e.formValues[name][(e.formValues[name].length - 1)] === " ") {
            erro = { ...erro, [name]: true }
          }
        } */

        if (e.formValues[name] !== null && e.formValues[name] !== undefined) {
            if (e.formValues[name][0] === " " || e.formValues[name][(e.formValues[name].length - 1)] === " ") {
                erro = { ...erro, [name]: true };
            }
        }


        if (typeof e.formValues[name] === "object") {
            if (e.formValues[name] !== null) {
                if (Object.keys(e.formValues[name]).length === 0) {
                    Object.keys(e.formRequired).forEach((value) => {
                        if (name === value) {
                            erro = { ...erro, [name]: true };
                        }
                    });
                }
                else {
                    Object.keys(e.formValues[name]).forEach((value) => {
                        //console.log(value + ": " + e.formValues[name][value][0])
                        if (e.formValues[name][value] !== null && e.formValues[name][value] !== undefined) {
                            if (e.formValues[name][value][0] === " " || e.formValues[name][value][(e.formValues[name][value].length - 1)] === " ") {
                                erro = { ...erro, [value]: true };
                            }
                        }
                    });
                }
            }
        }

    }

    //retorna o objeto de erros, true para caso tenha dado algo divergente
    return erro;
};


