import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from '../../layouts/components/Select/Select';
import { atualizarPreferencia, atualizarTema } from '../../layouts/redux/LayoutActions';
import { SelectInBox } from '../form';

export default (props) => {
    const themes = useSelector(state => state.layoutState.themes);
    const selectedTheme = useSelector(state => state.layoutState.selectedTheme);
    const user = useSelector((state) => state.authentication.user);
    const dispatch = useDispatch();

    /*     const mudarTema = e => {
            localStorage.setItem('tema', JSON.stringify(e.target.value));
            dispatch(atualizarTema(e.target.value));
            if (user && user.preferencia && user.preferencia.tema) {
                let updatedUser = {
                    ...user,
                    preferencia: {
                        ...user.preferencia,
                        tema: e.target.value
                    }
                };
                dispatch(atualizarPreferencia(updatedUser));
            }
        }; */

    const handleThemeChange = (e) => {
        dispatch(atualizarTema(e.target.value));
        let storedUser = localStorage.getItem("user");
        if (storedUser) {
            storedUser = JSON.parse(storedUser);
            let updatedUser = {
                ...storedUser,
                preferencia: {
                    ...storedUser.preferencia,
                    tema: e.target.value
                }
            };
            dispatch(atualizarPreferencia(updatedUser));
        }
    };

    return (
        <SelectInBox
            id='tema'
            label={props.label}
            selectDefault={{
                id: 'id',
                label: 'nome',
                minWidth: '100%',
            }}
            select={{
                action: (e) => handleThemeChange(e),
                options: themes,
                selected: selectedTheme,
            }}
        />

    );
};