import React from 'react';

export function IcoIntelbras({ title = '', height = '1em', className }) {
    return (
        <svg
            height={height}
            viewBox="0 0 5408 1024"
            className={className ? className : ""}
        >
            <title>{title}</title>
            <path d="M4323.749 232.095c-219.056 0-396.387 177.331-396.387 396.387s177.331 396.387 396.387 396.387c72.149 0 139.952-19.124 198.194-53.025v53.025h198.194v-396.387c0-219.056-177.331-396.387-396.387-396.387zM4521.942 628.482v1.739c-0.869 108.659-89.535 195.586-198.194 195.586-109.528 0-198.194-88.666-198.194-198.194s88.666-198.194 198.194-198.194c109.528 0.869 198.194 89.535 198.194 199.063v0z" />
            <path d="M1471.674 811.029c-19.993 9.562-42.594 14.778-66.065 14.778-85.188 0-153.861-68.672-153.861-153.861v0-242.526h150.384c0 0 25.209-86.927 25.209-198.194h-174.723v-231.226c-110.397 0-198.194 29.555-198.194 29.555s0 119.090 0 202.54v0 439.851c0 0 0 0 0 0 0 194.716 157.338 352.054 352.054 352.054 66.934 0 128.652-18.255 181.677-50.418-49.548-44.333-89.535-99.966-116.482-162.553z" />
            <path d="M4012.55 282.513c-53.025-32.163-115.613-50.418-181.677-50.418-194.716 0-352.054 157.338-352.054 352.054 0 0 0 0 0 0v439.851h198.194v-440.72c0-85.188 68.672-153.861 153.861-153.861 23.47 0 46.071 5.216 66.065 14.778 26.947-61.718 66.065-117.351 115.613-161.684z" />
            <path d="M217.317 91.273c0 60.011-48.648 108.659-108.659 108.659s-108.659-48.648-108.659-108.659c0-60.011 48.648-108.659 108.659-108.659s108.659 48.648 108.659 108.659z" />
            <path d="M634.567 232.095c-194.716 0-352.054 157.338-352.054 352.054 0 0 0 0 0 0v439.851h198.194v-439.851c0-85.188 68.672-153.861 153.861-153.861s153.861 68.672 153.861 153.861v440.72h198.194v-440.72c0-194.716-157.338-352.054-352.054-352.054z" />
            <path d="M9.562 1024h198.194v-791.905c-111.267 0-198.194 28.686-198.194 28.686v763.219z" />
            <path d="M2343.552 1024h198.194v-1024c-108.659 0-194.716 27.817-198.194 29.555v994.445z" />
            <path d="M3018.105 232.095c-72.149 0-139.952 19.124-198.194 53.025v-285.121c-107.789 0-194.716 27.817-197.324 29.555 0 0 0 598.058 0 598.927 0 219.056 177.331 396.387 396.387 396.387s396.387-177.331 396.387-396.387c-0.869-219.056-178.2-396.387-397.256-396.387zM3018.105 825.806c-109.528 0-198.194-88.666-198.194-198.194v0-1.739c0.869-108.659 89.535-196.455 198.194-196.455 109.528 0 198.194 88.666 198.194 198.194s-88.666 198.194-198.194 198.194z" />
            <path d="M2283.572 721.494c-102.574-40.856-191.239-43.463-191.239-43.463-25.209 95.62-117.351 159.946-217.317 146.037-22.601-2.608-43.463-9.562-62.587-19.124l447.674-343.362c-55.633-119.090-167.769-207.756-307.722-226.010-217.317-28.686-415.511 124.306-444.197 340.754-28.686 217.317 124.306 415.511 340.754 444.197 199.932 26.947 385.087-107.789 434.635-299.029v0zM1703.769 602.404c13.908-108.659 113.874-185.154 222.533-170.377 22.601 2.608 43.463 9.562 62.587 19.124l-282.513 217.317c-4.346-21.732-5.216-43.463-2.608-66.065z" />
            <path d="M5166.941 542.424h-130.39c-31.294 0-56.503-25.209-56.503-56.503s25.209-56.503 56.503-56.503h284.251c0.869-3.477 29.555-88.666 29.555-197.324 0 0-308.591 0-327.715 0-132.998 0-240.788 107.789-240.788 240.788s107.789 240.788 240.788 240.788h130.39c31.294 0 56.503 25.209 56.503 56.503s-25.209 56.503-56.503 56.503h-322.499c-0.869 3.477-29.555 88.666-29.555 197.324 0 0 360.747 0 365.963 0 132.998 0 240.788-107.789 240.788-240.788s-107.789-240.788-240.788-240.788z" />
        </svg>
    );
}
