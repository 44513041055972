import React, { useEffect } from 'react';
import { abrirModalCriarServico, abrirModalDeletarServico, abrirModalDesvincularServico, abrirModalEditarServico, abrirModalVincularServico } from '../../servicos/redux/ServicoActions';
import { adicionarServico, atualizarServico, deletarServico, desvincularServico, listarAssinante, vincularServico } from '../redux/AssinanteActions';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/button/Button';
import CriarEditar from '../../servicos/criarEditar/criarEditar';
import ListaConteudoServico from './listaServicosAssinante';
import Remover from '../../servicos/remover/remover';
import Vincular from '../../servicos/vincular/vincular';
import history from '../../../history';
import Row from '../../../components/row/row';
import './servicosAssinantes.css';
import { getPagePermissions } from '../../../utils/utils';
import SigCloudLoader from '../../../layouts/components/Loader/SigCloudLoader';
import Table from '../../../layouts/components/Table/Table';
import { IcoEditar, IcoRemoveLine, IcoSearch } from '../../../components/icones';
import TitleContainer from '../../../layouts/components/TitleContainer/TitleContainer';
import { DesvincularIcon } from '../../../components/icones/desvincular';
import Desvincular from '../desvincular/desvincular';


const ServicosAssinante = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);

    const assinante = useSelector(state => state.assinantesState.assinante);
    const servico = useSelector(state => state.servicosState.servico);
    const servicos = useSelector(state => state.assinantesState.assinante.servicos);

    const statusModal = useSelector(state => state.servicosState.statusModal);
    const isLoading = useSelector((state) => state.assinantesState.isLoading);

    const defaltText = require(`./../nls/${locales}.json`);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const urlId = window.location.pathname.split('/');
    const idAssinante = urlId[2] === 'assinantes' ? urlId[3] : '';

    useEffect(() => {
        dispatch(listarAssinante(idAssinante));
    }, [dispatch]);

    const action = servico.id ? atualizarServico : adicionarServico;

    const labels = [
        { text: defaltText.inputs.servico, field: "nome" },
        { text: defaltText.inputs.onu, field: "cpe.identificador" },
        { text: defaltText.inputs.plano, field: "plano.nome" },
        { text: defaltText.inputs.status, field: "status" },
        { text: defaltText.inputs.acoes, field: "actions" }
    ];

    const listActions = (val) => {
        switch (val.item) {
            case 'actions':
                return (
                    <>
                        <Button
                            hasAccess={permissions ? getPagePermissions(permissions, "assinantes", "update") : false}
                            id="btnEditar"
                            titulo={defaltText.btn.editar}
                            action={() => dispatch(abrirModalEditarServico(val.itens))}
                            children={<IcoEditar title={defaltText.btn.editar} />}
                            color=""
                            type="btn circle"
                            disabled={(val.itens.status === "DELETANDO") ? true : false}
                        />

                        <div className="table-divisor"></div>

                        <Button
                            hasAccess={permissions ? getPagePermissions(permissions, "assinantes", "delete") : false}
                            id="btnRemover"
                            titulo={defaltText.btn.desvincular}
                            action={() => dispatch(abrirModalDesvincularServico(val.itens))}
                            children={<DesvincularIcon title={defaltText.btn.desvincular} />}
                            color=""
                            type="btn circle"
                            disabled={(val.itens.status === "DELETANDO") ? true : false}
                        />

                        <div className="table-divisor"></div>

                        <Button
                            hasAccess={permissions ? getPagePermissions(permissions, "assinantes", "delete") : false}
                            id="btnRemover"
                            titulo={defaltText.btn.remover}
                            action={() => dispatch(abrirModalDeletarServico(val.itens))}
                            children={<IcoRemoveLine title={defaltText.btn.remover} />}
                            color=""
                            type="btn circle"
                            disabled={(val.itens.status === "DELETANDO") ? true : false}
                        />

                    </>
                );
            default:
                return;
        }
    };

    const modalServico = (e, i) => {
        switch (e[i]) {
            case "criando":
                return [<CriarEditar action={() => dispatch(servico.id ? atualizarServico(servico) : adicionarServico({ ...servico, idAssinante: idAssinante }))} />, modalServico(e, i + 1)];
            case "editando":
                return [<CriarEditar action={() => dispatch(servico.id ? atualizarServico(servico) : adicionarServico({ ...servico, idAssinante: idAssinante }))} />, modalServico(e, i + 1)];
            case "vinculando":
                return [<Vincular action={() => dispatch([vincularServico(servico)])} />, modalServico(e, i + 1)];
            case "deletando":
                return [<Remover action={() => dispatch(deletarServico(servico))} />, modalServico(e, i + 1)];
            case "desvincular":
                return [<Desvincular action={() => dispatch(desvincularServico(servico))} />, modalServico(e, i + 1)];
            default:
                return;
        }
    };

    return (
        <>
            <SigCloudLoader visible={isLoading} />
            <div className='box-servicos-assinante'>
                <TitleContainer
                    label={defaltText.titleServicosVinculados}
                />
                <div className='box-conteudo'>
                    <Row>
                        <div className='col-md-3'>
                            <label htmlFor='*'>{defaltText.inputs.assinante}</label>
                            <p><span className="bold-text">{assinante.nome}</span></p>
                        </div>

                        <div className="section-divisor"></div>

                        <div className='col-md-2'>
                            <label htmlFor='*'>{defaltText.inputs.nContrato}</label>
                            <p><span className="bold-text">{assinante.nContrato}</span></p>
                        </div>

                        <div className="section-divisor"></div>

                        <div className='col-md-2'>
                            <Button
                                hasAccess={permissions ? getPagePermissions(permissions, "assinantes", "update") : false}
                                titulo={defaltText.btn.vincularServico}
                                action={() => dispatch(abrirModalVincularServico(servico))}
                                color='primary'
                                children={defaltText.btn.vincularServico}
                                size='medium'
                            />
                        </div>

                        <div className="section-divisor"></div>

                        <div className='col-md-2'>
                            <Button
                                hasAccess={permissions ? getPagePermissions(permissions, "assinantes", "create") : false}
                                titulo={defaltText.btn.novoServico}
                                action={() => dispatch(abrirModalCriarServico())}
                                color='primary'
                                children={defaltText.btn.novoServico}
                                size='medium'
                            />
                        </div>

                        {/*  <div className='col-md-12'>
                            <div className='box-btn-group'>
                                <Button
                                    hasAccess={permissions ? getPagePermissions(permissions, "assinantes", "update") : false}
                                    titulo={defaltText.btn.vincularServico}
                                    action={() => dispatch(abrirModalVincularServico(servico))}
                                    color='primary'
                                    children={defaltText.btn.vincularServico}
                                    size='medium'
                                />
                                <Button
                                    hasAccess={permissions ? getPagePermissions(permissions, "assinantes", "create") : false}
                                    titulo={defaltText.btn.novoServico}
                                    action={() => dispatch(abrirModalCriarServico())}
                                    color='primary'
                                    children={defaltText.btn.novoServico}
                                    size='medium'
                                />
                            </div>
                        </div> */}
                    </Row>
                </div>

                {servicos && servicos.length > 0 ? (
                    <>
                        <p><span className="bold-text">Serviços:</span></p>
                        <Table
                            itens={servicos}
                            labels={labels}
                            listActions={(val) => listActions(val)}
                        />
                    </>
                ) : (
                    <div>{defaltText.mensagem.semRegistros}</div>
                )}

                <div>
                    <Row>
                        <div className='col-md-12'>
                            <div className='flex-end'>
                                <Button
                                    titulo={defaltText.btn.voltar}
                                    action={() => history.push(`/assinantes`)}
                                    color='secondary'
                                    children={defaltText.btn.voltar}
                                    size='medium'
                                />
                            </div>
                        </div>
                    </Row>
                </div>
                {/* {statusModal === 'criando' || statusModal === 'editando' ? (
                    <CriarEditar action={() => dispatch(servico.id ? atualizarServico(servico) : adicionarServico({ ...servico, idAssinante: idAssinante }))} />
                ) : null}
                {statusModal === 'vinculando' ? (
                    <Vincular action={() => dispatch([vincularServico(servico)])} />
                ) : null}
                {statusModal === 'deletando' ? <Remover action={() => dispatch(deletarServico(servico))} /> : null} */}
            </div>
            {modalServico(statusModal, 0)}
        </>
    );
};

export default ServicosAssinante;
