import React from 'react';

import { useSelector } from 'react-redux';


const ConteudoLimiares = () => {

    const locales = useSelector(state => state.layoutState.linguagem);

    const defaltText = require(`../nls/${locales}.json`);

    return (
        <div>{defaltText.mensagem.apagarHistorico}</div>
    );
};

export default ConteudoLimiares;
