import React from 'react';

export function IcoFecharNovo(props) {
    return (
        <div className="modal-btn-close">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                {/*  <path id="icon-close-circle" data-name="Icon ionic-md-close-circle" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.574,14.574,0,0,0,18,3.375Z" transform="translate(-3.375 -3.375)" /> */}
                <g id="x-simbol" data-name="Grupo 137" transform="translate(-1262.984 -110.031)">
                    <path id="Caminho_141" data-name="Caminho 141" d="M-.232-.684,2.751-.75,2.729,17.126H-.187Z" transform="translate(1270.929 119.757) rotate(-45)" />
                    <path id="Caminho_142" data-name="Caminho 142" d="M-.232-.684,2.751-.75,2.729,17.126H-.187Z" transform="translate(1272.711 131.337) rotate(-135)" />
                </g>
            </svg>
        </div>
    );
}