import { margin } from 'polished';
import React from 'react';
import { IcoFechar } from '../../../components/icones';
import "./input.css";

export default ({
    type = 'text',
    label = '',
    name = '',
    id = '',
    value = '',
    changeInput,
    validate,
    length,
    search,
    placeholder = false,
    className,
    disabled,
    onPressEnter = () => { },
    leftButton,
    reference,
    onKeyDown = () => { },
    clearField
}) => {
    return (
        <div className={`box-form ${disabled ? "disabled" : ""} ${value ? 'filled' : ''} ${validate && validate.erro ? 'erro' : ''} ${className === 'hide' ? className : 'show'}`}>
            {leftButton && !disabled && (
                <button onClick={leftButton.action} name={name} type='button' title={leftButton.text} className='field-left-icon'>
                    {leftButton.icon}
                </button>
            )}
            <input
                ref={reference}
                disabled={disabled ? disabled : false}
                type={type}
                name={name}
                value={value}
                onChange={changeInput}
                id={id}
                required={!!validate}
                onBlur={validate && validate.action}
                pattern={validate && validate.pattern}
                maxLength={length && length.max}
                minLength={length && length.min}
                placeholder={label && label}
                onKeyPress={onPressEnter}
                onKeyDown={onKeyDown}
            />
            {/*             <label htmlFor={id}>
                {validate && type !== 'only-see' ? <sup className='text-danger'>✻ </sup> : ''}
                {label}
            </label> */}
            {clearField && value && !disabled && (
                <button name={name} id="btnLimpar" type='button' onClick={clearField.action} title={clearField.text} className='bt-input'>
                    <IcoFechar title={clearField.text} />
                </button>
            )}
            <div className={`invalid-feedback ${validate && validate.erro && "show-feedback"}`}>{validate && validate.erro ? validate.erro : ''}</div>
        </div>
    );
};
