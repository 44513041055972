import React from 'react';

export function IcoUserCircle(props) {
    return (
        <svg
            id={props.id}
            xmlns="http://www.w3.org/2000/svg"
            width="26.667"
            height="26.667"
            viewBox="0 -1.5 28.667 28.667"
        >
            <title>{props.title ? props.title : null}</title>
            <path id="Icon_awesome-user-circle" data-name="Icon awesome-user-circle" d="M13.333.562A13.333,13.333,0,1,0,26.667,13.9,13.331,13.331,0,0,0,13.333.562Zm0,5.161A4.731,4.731,0,1,1,8.6,10.455,4.731,4.731,0,0,1,13.333,5.724Zm0,18.495a10.3,10.3,0,0,1-7.876-3.667,5.994,5.994,0,0,1,5.3-3.215,1.316,1.316,0,0,1,.382.059,7.118,7.118,0,0,0,2.2.371,7.091,7.091,0,0,0,2.2-.371,1.315,1.315,0,0,1,.382-.059,5.994,5.994,0,0,1,5.3,3.215A10.3,10.3,0,0,1,13.333,24.218Z" transform="translate(0 -0.562)" fill='var(--menu-icon-color)' />
        </svg>
    );
}
