import React from 'react';

export function IcoChamadas(props) {
  return (
    <svg className='icon' viewBox='0 0 55 55'>
      <title>{props.title ? props.title : null}</title>
      <path d='M33 30c-3 3-3 6-6 6s-6-3-9-6-6-6-6-9 3-3 6-6-6-12-9-12-9 9-9 9c0 6 6.164 18.164 12 24s18 12 24 12c0 0 9-6 9-9s-9-12-12-9z' />
    </svg>
  );
}
