import React from 'react';
import Card from '../card/Card';
import Row from '../row/row';

export function CardList({ data, buttons, labels, headerTitle, getCardActions, cardClasses, getHeaderButton }) {
    return (
        <Row>
            {data.map((item, index) => (
                <div className={cardClasses ? cardClasses : "col-md-4 col-sm-6"}>
                    <Card
                        getHeaderButton={getHeaderButton}
                        content={item}
                        buttons={buttons}
                        labels={labels}
                        headerTitle={headerTitle}
                        getCardActions={getCardActions}
                        backgroundColor={`${item.status && (item.status.includes("ERRO") ? "development" : (item.status !== "OK" ? "warning" : ""))}`}
                    />
                </div>
            ))}
        </Row>
    );
}
