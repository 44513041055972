import React, { useEffect } from 'react';
import {
    listarCpes,
    abrirModalErroCpe,
    resetCpeState,
    handleIsLoadingCpeStatus,
    pesquisaCpes,
} from './redux/CpeActions';
import { useDispatch, useSelector } from 'react-redux';

import BarraPesquisa from './barraDePesquisa';
import Informacoes from './informacoes/informacoes';
import List from '../../components/list/List';
import Paginate from '../../components/pagination/Pagination';
import ServicosCpe from './servicos/servicosCpe';
import CriarEditar from './criarEditar/criarEditarCpe';
import GerenciarCPE from './gerenciarCpe/gerenciarCpe';
import Remover from './remover/remover';
import Erro from './erro/erro';
import { getCpeCardActions, getCpeCardHeaderButton } from '../../components/card/getCardButtons';
import { CardList } from '../../components/CardList/CardList';
import TitleContainer from '../../layouts/components/TitleContainer/TitleContainer';
import SigCloudLoader from '../../layouts/components/Loader/SigCloudLoader';
import CpeCard from '../../layouts/components/cards/CpeCard';
import Row from '../../components/row/row';
import AddService from '../servicos/criarEditar/criarEditar';
import { listarOlts } from '../olt/redux/OltActions';

const Cpe = () => {
    const dispatch = useDispatch();
    const cpes = useSelector((state) => state.cpesState.cpes);
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);
    const paginacao = useSelector(state => state.cpesState.paginacao);
    const statusModal = useSelector((state) => state.cpesState.statusModal);
    const formPesquisa = useSelector((state) => state.cpesState.formPesquisa);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const isLoading = useSelector((state) => state.cpesState.isLoading);

    useEffect(() => {
        dispatch(pesquisaCpes());
        dispatch(listarOlts());

        return () => {
            dispatch(handleIsLoadingCpeStatus(false));
            dispatch(resetCpeState());
        };
    }, [dispatch]);

    const labels = [
        { field: 'identificador', text: defaltText.inputs.identificador },
        { field: 'modelo', text: defaltText.inputs.modelo },
        { field: 'nomeOlt', text: defaltText.inputs.oltVinculada },
        { field: 'porta', text: defaltText.inputs.porta },
        { field: 'slot', text: defaltText.inputs.slot },
        { field: 'status', text: defaltText.inputs.status },
        { field: 'descricao', text: defaltText.inputs.descricao}
    ];
    const buttons = ["btnInternet", "btnGerenciar", "btnServicos", "btnRemover"];

    const atualizarLista = (value, formPesquisa) => {
        /* dispatch(listarCpes({ ...formPesquisa, pagina: value })); */
        dispatch(pesquisaCpes({ pesquisa: { ...formPesquisa }, pagina: value }));
    };

    const modalNovoCpe = (e, i) => {
        switch (e[i]) {
            case 'criando':
                return [<CriarEditar />, modalNovoCpe(e, i + 1)];
            case 'editando':
                return [<CriarEditar />, modalNovoCpe(e, i + 1)];
            case 'gerenciando':
                return [<GerenciarCPE />, modalNovoCpe(e, i + 1)];
            case 'deletando':
                return [<Remover />, modalNovoCpe(e, i + 1)];
            case 'erro':
                return [<Erro />, modalNovoCpe(e, i + 1)];
            case 'informacoes':
                return [<Informacoes />, modalNovoCpe(e, i + 1)];
            case 'servicos':
                return [<ServicosCpe />, modalNovoCpe(e, i + 1)];
            case 'addService':
                return [<AddService />, modalNovoCpe(e, i + 1)];
            default:
                return;
        }
    };


    return (
        <>
            <SigCloudLoader visible={isLoading} />
            <div className="box-cpe">
                <TitleContainer
                    label={defaltText.title}
                />
                <div>
                    <BarraPesquisa />
                    {/* <List data={{ head: listHead, content: cpes }} listActions={(value) => listActions(value)} /> */}

                    {/*  <CardList
                        getHeaderButton={(val) => getCpeCardHeaderButton(val)}
                        data={cpes}
                        buttons={buttons}
                        labels={labels}
                        headerTitle="identificador"
                        getCardActions={(val) => getCpeCardActions(val, defaltText, permissions)}
                    /> */}
                </div>
                {/* {statusModal === 'informacoes' ? <Informacoes /> : null}
                <Informacoes />
                {statusModal === 'servicos' ? <ServicosCpe /> : null} */}

                {modalNovoCpe(statusModal, 0)}

                <Row>
                    {cpes.map((item) => (
                        <div className="col-md-3">
                            <CpeCard
                                item={item}
                                labels={labels}
                                markerColor={
                                    (item.status.toUpperCase() === "ONLINE") ? "ok" :
                                        item.status.toUpperCase().includes("ERRO") ? "error" :
                                            (item.status.toUpperCase() === "OFFLINE") ? "offline" :
                                                (item.status.toUpperCase() === "NAO_PROVISIONADA") ? "nao-provisionada" :
                                                    item.status.toUpperCase() === "PROBLEMA_OPTICO" ? "problema-optico" :
                                                        item.status.toUpperCase() === "DELETANDO" ? "delete" :
                                                            "updating"
                                }
                            />
                        </div>
                    ))}
                </Row>
                {paginacao && paginacao.totalPages > 1 ? (
                    <Paginate data={paginacao} action={val => atualizarLista(val, formPesquisa)} />
                ) : null}
            </div>
        </>
    );
};

export default Cpe;
