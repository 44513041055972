import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/button/Button';
import { SelectInBox } from '../../../../components/form';
import Checkbox from '../../../../components/form/checkbox/Checkbox';
import RadioButton from '../../../../components/form/radioButton/RadioButton';
import { validarCampoAction } from '../../../../validation/validationActions';
import { atualizarConteudoBridgeModal, cancelServiceType, clearBridgeField, listarDba, salvarBridge } from '../../redux/PlanosActions';
import DropdownList from '../../../../components/dropdownList/dropdownList';
import ConteudoDropdownContentBridge from '../dropdownContent/conteudoDropdownContentBridge';
import { MaskVlanId } from '../../../../components/mask/Mask';
import Input from '../../../../layouts/components/Input/Input';

const ConteudoModalBridge = (props) => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../nls/${locales}.json`);
    const plano = useSelector((state) => state.planosState.plano);
    const mensagemErro = useSelector((state) => state.validacaoState);
    const vlanModes = useSelector((state) => state.planosState.vlanModes);
    const downloadControl = useSelector((state) => state.planosState.downloadControl);
    const uploadControl = useSelector((state) => state.planosState.uploadControl);

    const [dropdownState, setDropdownState] = useState({
        advanced: false
    });

    const handleDropdownState = (e) => {
        let actualStatus = dropdownState[e.currentTarget.value];
        let newArray = {
            ...dropdownState
        };
        newArray[e.currentTarget.value] = actualStatus === null ? true : !actualStatus;
        setDropdownState(newArray);
        console.log(actualStatus);
    };

    return (
        <div className={`brigdeContent ${!props.visivel ? 'hidePlan' : ''}`}>
            <h5>{defaultText.titleBridge}</h5>
            <div className="firstLine">
                <div className="col-md-6">
                    <Input
                        id="indexVlan"
                        name='indexVlan'
                        label={defaultText.inputs.vlanId}
                        value={plano.bridge.indexVlan}
                        changeInput={e => [
                            e.target.value = MaskVlanId(e.target.value),
                            dispatch(atualizarConteudoBridgeModal(e))
                        ]}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.indexVlan ? defaultText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '4'
                        }}
                        clearField={{
                            action: (e) => dispatch(clearBridgeField(e)),
                            text: defaultText.btn.limparCampo
                        }}
                    />

                </div>
                <div className="col-md-6">
                    <Checkbox
                        name="advancedOptionBridge"
                        text={defaultText.inputs.advanced}
                        checked={plano.bridge.advancedOptionBridge}
                        value={plano.bridge.advancedOptionBridge}
                        changeInput={(e) => dispatch(atualizarConteudoBridgeModal(e))}
                    />
                </div>
            </div>
            <div className={`col-md-6 ${plano.bridge.advancedOptionBridge ? '' : 'hideAdvancedOption'}`}>
                <RadioButton
                    name='vlanTaggedChecked'
                    changeInput={e => dispatch(atualizarConteudoBridgeModal(e))}
                    selected={plano.bridge.vlanTaggedChecked}
                    options={vlanModes}
                    validate={{
                        erro: mensagemErro.vlanTaggedChecked ? defaultText.mensagem.obrigatorio : ''
                    }}
                />
            </div>
            <div className={`secondLine ${plano.bridge.advancedOptionBridge ? '' : 'hideAdvancedOption'}`}>
                <div className={`col-md-12 invalid-feedback ${mensagemErro && mensagemErro.mapaPortasLan ? "show-feedback" : "hide-feedback"}`}>{defaultText.mensagem.selecionePeloMenosUmaLan}</div>
                <div className="checkLanLine">
                    {[1, 2, 3, 4].map((lanNumber) => (
                        <div className="col-md-6">
                            <Checkbox
                                name={`eth${lanNumber}`}
                                text={`${defaultText.inputs.lan} ${lanNumber}`}
                                checked={plano.bridge["eth" + lanNumber.toString()]}
                                value={plano.bridge["eth" + lanNumber.toString()]}
                                changeInput={(e) => { dispatch(atualizarConteudoBridgeModal(e)); }}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className={`col-md-12 ${plano.bridge.advancedOptionBridge ? '' : 'hideAdvancedOption'}`}>
                <Checkbox
                    name="controleDeBandaBridge"
                    text={defaultText.inputs.controleDeBanda}
                    checked={plano.bridge.controleDeBandaBridge}
                    value={plano.bridge.controleDeBandaBridge}
                    changeInput={(e) => dispatch(atualizarConteudoBridgeModal(e))}
                />
            </div> */}
            <div className={`bandwidthControl ${plano.bridge.advancedOptionBridge ? '' : 'hideAdvancedOption'}`}>
                <div className="col-md-6">
                    {/* <SelectInBox
                        disabled={!plano.bridge.controleDeBandaBridge}
                        id='upload'
                        label={defaultText.inputs.upload}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                        }}
                        validate={{
                            erro: mensagemErro.upload ? defaultText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => dispatch(atualizarConteudoBridgeModal(e)),
                            options: uploadControl.dbas,
                            selected: plano.bridge.upload
                        }}
                    /> */}
                </div>
                <div className="col-md-6">
                    {/* <SelectInBox
                         DESCOMENTAR QUANDO TIVER A FUNCIONALIDADE DO BACK
                        disabled={!plano.bridge.controleDeBandaBridge} 
                        disabled={true}
                        id='download'
                        label={defaultText.inputs.download}
                        selectDefault={{
                            id: 'id',
                            label: 'perfilName',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.download ? defaultText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => dispatch(atualizarConteudoBridgeModal(e)),
                            options: downloadControl,
                            selected: plano.bridge.download
                        }}
                    /> */}
                </div>
            </div>

            {/* <DropdownList
                title={defaultText.inputs.advanced}
                button={
                    <Button
                        type="btn circle"
                        children={dropdownState.advanced ? <IcoMenos title="Fechar" /> : <IcoMais title="Abrir" />}
                        value="advanced"
                        action={(e) => { handleDropdownState(e); }}
                        color="transparent"
                    />
                }
                dropdownContent={
                    <ConteudoDropdownContentBridge
                        dropdownState={dropdownState}
                        mensagemErro={mensagemErro}
                    />
                }
                validate={{
                    erro: (mensagemErro.perfilDeControleDeBanda || mensagemErro.perfilDeControleDeBanda) ? defaultText.mensagem.obrigatorio : ''
                }}
            /> */}

            <div className="planBtn">
                <Button
                    id="btnCancelarBridge"
                    titulo={defaultText.btn.cancelar}
                    action={() => dispatch(cancelServiceType())}
                    children={defaultText.btn.cancelar}
                    color='secondary'
                    type='btn'
                />
                <Button
                    id="btnSalvarBridge"
                    titulo={defaultText.btn.adicionar}
                    action={() => dispatch(salvarBridge(plano.bridge))}
                    children={defaultText.btn.adicionar}
                    color='success'
                    type='btn'
                />
            </div>


        </div>
    );
};

export default ConteudoModalBridge;