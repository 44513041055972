import React from 'react';

export function IconLocation(props) {
    return (
        <svg
            id="Icon_material-location-on"
            data-name="Icon material-location-on"
            xmlns="http://www.w3.org/2000/svg"
            width="18.2"
            height="27"
            viewBox="0 -2.5 21.6 28"
            fill={props.fill}
        >
            <title>{props.title ? props.title : null}</title>
            <path fill={props.fill} id="Icon_material-location-on-2" data-name="Icon material-location-on" d="M17.3,3a9.793,9.793,0,0,0-9.8,9.8c0,7.35,9.8,18.2,9.8,18.2s9.8-10.85,9.8-18.2A9.793,9.793,0,0,0,17.3,3Zm0,13.3a3.5,3.5,0,1,1,3.5-3.5A3.5,3.5,0,0,1,17.3,16.3Z" transform="translate(-7.5 -3)" />
        </svg>);
}
