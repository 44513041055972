import React from 'react';

const UploadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.75" height="17.747" viewBox="0 0 17.75 17.747">
            <path id="Icon_awesome-upload" data-name="Icon awesome-upload" d="M10.262,13.315H7.488a.83.83,0,0,1-.832-.832V6.659H3.616a.692.692,0,0,1-.489-1.182L8.4.2a.672.672,0,0,1,.946,0l5.276,5.276a.692.692,0,0,1-.489,1.182h-3.04v5.824A.83.83,0,0,1,10.262,13.315Zm7.488-.277v3.883a.83.83,0,0,1-.832.832H.832A.83.83,0,0,1,0,16.921V13.038a.83.83,0,0,1,.832-.832H5.547v.277a1.943,1.943,0,0,0,1.941,1.941h2.773A1.943,1.943,0,0,0,12.2,12.483v-.277h4.715A.83.83,0,0,1,17.75,13.038Zm-4.3,3.051a.693.693,0,1,0-.693.693A.7.7,0,0,0,13.451,16.089Zm2.219,0a.693.693,0,1,0-.693.693A.7.7,0,0,0,15.67,16.089Z" transform="translate(0 -0.005)" fill="#fff" />
        </svg>
    );
};

export default UploadIcon;