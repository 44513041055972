import React from 'react';
import "./cards.css";
import { IcoWifi, IcoRemoveLine, ServicoIcon, } from '../../../components/icones';
import CardButton from '../cardButton/CardButton';
import history from '../../../history';
import { useDispatch, useSelector } from 'react-redux';
import { getPagePermissions, translateStatus } from '../../../utils/utils';
import CardHeaderButton from '../cardHeaderButton/CardHeaderButton';
import { AttentionIcon, CorrectIcon, InfoIcon, SyncIcon, WanLanIcon } from '../icons';
import { abrirModalAddServico, abrirModalDeletarCpe, abrirModalGerenciarCpe, abrirModalInformacaoCpe } from '../../../modulos/cpe/redux/CpeActions';
import { OfflineIcon } from '../../../layouts/components/icons/OfflineIcon';
import { NaoProvisIcon } from '../icons/NaoProvisIcon.js';

const CpeCard = ({ markerColor = "ok", item, labels }) => {
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../../modulos/cpe/nls/${locales}.json`);
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const olts = useSelector(state => state.oltsState.olts);

    const handleIsOnu = (item) => {
        const onu = ["R1", "R1v2", "2301", "110Gb", "110Gi"];
        if (onu.includes(item)) {
            return true;
        }
        else {
            return false;
        }
    };

    const getCpeCardHeaderButton = (val) => {
        if (item.status.toUpperCase() === "ONLINE") {
            return <CardHeaderButton icon={<CorrectIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
        }
        else {
            if (val.status.includes("ERRO")) {
                return <CardHeaderButton icon={<AttentionIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
            else if (val.status.toUpperCase().includes("OFFLINE")) {
                return <CardHeaderButton icon={<OfflineIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
            else if (val.status.includes("NAO_PROVISIONADA")) {
                return <CardHeaderButton icon={<NaoProvisIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
            if (val.status.includes("PROBLEMA_OPTICO")) {
                return <CardHeaderButton icon={<AttentionIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
            else {
                return <CardHeaderButton icon={<SyncIcon className="spin" title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
        }
    };

    const descriptionCpe = (texto) => {
        if (texto == null){
            return <p><span >{defaultText.inputs.semDescricao}</span></p>
        }
        if (texto.length <= 78) {
          return  <p>{labels.find((label) => label.field === 'descricao').text}: <span className={`bold-text-descricao`}>{texto} </span></p>
        }
      
        const textoResumido = texto.slice(0, 77) + '...';
      
        return  <p>{labels.find((label) => label.field === 'descricao').text}:<span className={`bold-text-descricao`}> {textoResumido} </span></p>
      };
    return (
        <div className={`cartao ${markerColor}`}>
            <div className="cartao-cabecalho">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p><span className="bold-text">{item[labels[0].field]}</span></p>
                    <CardHeaderButton markerColor={markerColor} icon={getCpeCardHeaderButton(item)} />
                </div>
                <div className="section-divisor"></div>
            </div>

            <div className="cartao-corpo">
                {labels.map((label, index) => index > 0 && label.field !== 'descricao' && <p>{label.text}: <span className={`bold-text ${label.field === "status" && markerColor}`}>{translateStatus(item[label.field], locales)}</span></p>)}
                {descriptionCpe(item['descricao'])}
            </div>
            <div className="cartao-rodape">
                <CardButton
                    hasAccess={getPagePermissions(permissions, "cpes", "create")}
                    id="btnAdService"
                    label={defaultText.btn.adicionarServico}
                    action={() => dispatch(abrirModalAddServico(item, olts.find(olt => olt.id === item.idOlt)))}
                    icon={<ServicoIcon />}
                    disabled={
                        (item.status.toUpperCase() === "ONLINE" ||
                            item.status.toUpperCase() === "PROBLEMA_OPTICO" ||
                            item.status.toUpperCase() === "PROVISIONANDO" ||
                            item.status.toUpperCase() === "EDITANDO" ||
                            item.status.toUpperCase() === "REINICIANDO" ||
                            item.status.toUpperCase().includes('ERRO') ||
                            item.status.toUpperCase() === "OFFLINE") ? true : false}
                />

                <div className="vertical-section-divisor"></div>

                <CardButton
                    id="btnInfo"
                    label={defaultText.btn.informacoes}
                    action={() => dispatch(abrirModalInformacaoCpe(item))}
                    icon={<InfoIcon />}
                    disabled={(item.status.toUpperCase() === "NAO_PROVISIONADA" || item.status.toUpperCase() === "PROBLEMA_OPTICO")}
                />

                <div className="vertical-section-divisor"></div>

                <CardButton
                    hasAccess={getPagePermissions(permissions, "cpes", "update")}
                    id="btnGerenciar"
                    label={"WiFi"}
                    action={() => dispatch(abrirModalGerenciarCpe(item))}
                    icon={<IcoWifi />}
                    disabled={(item.status.toUpperCase() === "ERRO" || item.status.toUpperCase() === "PROBLEMA_OPTICO" || item.status.toUpperCase() === "NAO_PROVISIONADA" || handleIsOnu(item.modelo) === true) ? true : false}
                />

                <div className="vertical-section-divisor"></div>

                {/* <CardButton
                    id="btnServicos"
                    label={defaultText.btn.servicosVinculados}
                    action={() => history.push(`/servicos/cpes/${item.id}`)}
                    icon={<ServicoVinculado />}
                    disabled={(item.status.toUpperCase() === "NAO_PROVISIONADA" || item.status.toUpperCase() === "PROBLEMA_OPTICO")}
                /> */}

                <div className="vertical-section-divisor"></div>

                <CardButton
                    hasAccess={getPagePermissions(permissions, "cpes", "delete")}
                    id="btnRemover"
                    label={defaultText.btn.remover}
                    action={() => dispatch(abrirModalDeletarCpe(item))}
                    icon={<IcoRemoveLine />}
                    disabled={
                        (item.status.toUpperCase() === "NAO_PROVISIONADA" ||
                            item.status.toUpperCase() === "REINICIANDO" ||
                            item.status.toUpperCase() === "PROBLEMA_OPTICO") ? true : false}
                />
            </div>
        </div>
    );
};

export default CpeCard;
