import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stomp from 'stompjs';
import { assinanteAtualizadoComSucesso, assinanteDeletadoComSucesso } from '../modulos/assinantes/redux/AssinanteActions';
import { cpeDeletadoComSucesso, cpesAtualizadosComSucesso, fetchCpeStatus } from '../modulos/cpe/redux/CpeActions';
import { notificaoAtualizadosComSucesso } from '../modulos/notificacoes/redux/NotificacoesActions';
import { oltsAtualizadasComSucesso, atualizarVlanComSucesso } from '../modulos/olt/redux/OltActions';
import { planoAtualizadoComSucesso } from '../modulos/planos/redux/PlanosActions';
import { servicoDeletadoComSucesso, servicosAtualizadosComSucesso } from '../modulos/servicos/redux/ServicoActions';
import { userAplicacaoAtualizadosComSucesso } from '../modulos/usuario/login/redux/UserActions';
import { aplicacaoAtualizadosComSucesso } from '../modulos/locais/redux/LocaisActions';
export default () => {
    const user = useSelector((state) => state.authentication.user);
    const dispatch = useDispatch();

    useEffect(() => {
        var sock = new WebSocket(process.env.REACT_APP_WEBSOCKET_ENDPOINT + "/stomp/websocket");
        let stompClient = Stomp.over(sock);

        const websocketConnect = async () => {
            stompClient.debug = null;
            await stompClient.connect({
                "Authorization": `Bearer ${user.token || ""}`
            }, function (frame) {
                stompClient.subscribe('/user/broker/olt', function (olt) {
                    console.log("Recebeu da OLT!----------------------------------------------------------------");
                    //dispatch(oltsAtualizadasComSucesso(JSON.parse(olt.body)))
                    try {
                        var obj = JSON.parse(olt.body);
                        dispatch(oltsAtualizadasComSucesso(JSON.parse(olt.body)));
                        dispatch(fetchOltStatus());
                    } catch (e) {
                        dispatch(oltsAtualizadasComSucesso(olt.body));
                    }
                });

                stompClient.subscribe('/user/broker/servico', function (servico) {
                    console.log("Recebeu do Serviço!----------------------------------------------------------------");
                    //dispatch(servicoAtualizadoComSucesso(JSON.parse(servico.body)))
                    try {
                        var obj = JSON.parse(servico.body);
                        dispatch(servicosAtualizadosComSucesso(JSON.parse(servico.body)));
                        dispatch(fetchServicosOlt());
                    } catch (e) {
                        dispatch(servicoDeletadoComSucesso(servico.body));
                    }
                });

                stompClient.subscribe('/user/broker/assinante', function (assinante) {
                    console.log("Recebeu do Assinante!----------------------------------------------------------------");
                    try {
                        var obj = JSON.parse(assinante.body);
                        dispatch(assinanteAtualizadoComSucesso(JSON.parse(assinante.body)));
                    } catch (e) {
                        dispatch(assinanteDeletadoComSucesso(assinante.body));
                    }
                });

                stompClient.subscribe('/user/broker/cpe', function (cpe) {
                    console.log("Recebeu do CPE!----------------------------------------------------------------");
                    //dispatch(cpesAtualizadosComSucesso(JSON.parse(cpe.body)))
                    try {
                        var obj = JSON.parse(cpe.body);
                        dispatch(cpesAtualizadosComSucesso(JSON.parse(cpe.body)));
                        dispatch(fetchCpeStatus());
                    } catch (e) {
                        dispatch(cpeDeletadoComSucesso(cpe.body));
                    }
                });

                stompClient.subscribe('/user/broker/notificacao', function (notificacao) {
                    console.log("Recebeu da Notificação!----------------------------------------------------------------");
                    console.log(notificacao);
                    /* try {
                        var obj = JSON.parse(notificacao.body);
                        dispatch(notificaoAtualizadosComSucesso(obj));
                    } catch (e) {
                        dispatch(notificaoAtualizadosComSucesso(notificacao.body));
                    } */
                    var obj = JSON.parse(notificacao.body);
                    dispatch(notificaoAtualizadosComSucesso(obj));
                });

                stompClient.subscribe('/user/broker/plano', function (plano) {
                    console.log("Recebeu do PLANO!----------------------------------------------------------------");
                    //dispatch(cpesAtualizadosComSucesso(JSON.parse(cpe.body)))
                    try {
                        var obj = JSON.parse(plano.body);
                        dispatch(planoAtualizadoComSucesso(JSON.parse(plano.body)));
                    } catch (e) {
                        dispatch(planoAtualizadoComSucesso(plano.body));
                    }
                });

                stompClient.subscribe('/user/broker/aplicacao', function (aplicacao) {
                    console.log("Recebeu da Aplicação!----------------------------------------------------------------");
                    try {
                        var obj = JSON.parse(aplicacao.body);
                        user.aplicacaoLogado = obj;
                        localStorage.setItem('aplicacao', JSON.stringify(obj));
                        dispatch(aplicacaoAtualizadosComSucesso(obj))
                    } catch (e) {
                        dispatch(aplicacaoAtualizadosComSucesso(aplicacao.body));
                    }
                });

                stompClient.subscribe('/user/broker/vlan', function (vlan) {
                    console.log("Recebeu da VLAN!----------------------------------------------------------------");
                    try {
                        var obj = JSON.parse(vlan.body);
                        dispatch(atualizarVlanComSucesso(obj))
                    } catch (e) {
                        dispatch(atualizarVlanComSucesso(vlan.body))
                    }
                });

            });
        };

        const websocketDisconnect = async () => {
            try {
                await stompClient.disconnect();
            } catch (e) {
                console.log(e);
            }
        };

        if (user.token) {
            websocketConnect();

            return async () => {
                websocketDisconnect();
            };
        }

    }, [user]);

    return <></>;
};
