import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IcoEditar, IcoRemoveLine, IcoChamadas, IcoWanLan } from '../../components/icones';
import BarraPesquisa from './barraDePesquisa';
import CriarEditar from './criarEditar/criarEditar';
import List from '../../components/list/List';
import Paginate from '../../components/pagination/Pagination';
import Remover from './remover/remover';
import Erro from './erro/erro';

import {
    listarServicos,
    abrirModalEditarServico,
    abrirModalDeletarServico,
    abrirModalErroServico,
    reiniciarServico,
    adicionarServico,
    atualizarServico,
    handleIsLoadingServicesStatus,
    resetServiceState
} from './redux/ServicoActions';
import Button from '../../components/button/Button';
import { getServicoCardActions, getServicoCardHeaderButton } from '../../components/card/getCardButtons';
import { CardList } from '../../components/CardList/CardList';
import TitleContainer from '../../layouts/components/TitleContainer/TitleContainer';
import SigCloudLoader from '../../layouts/components/Loader/SigCloudLoader';
import "./servicos.css";
import ServiceCard from '../../layouts/components/cards/ServiceCard';
import Row from '../../components/row/row';
import ServiceInfo from './informacoes/informacoes';
import { listarOlts } from '../olt/redux/OltActions';

const Servicos = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);

    const servicos = useSelector(state => state.servicosState.servicos);
    const servico = useSelector(state => state.servicosState.servico);
    const paginacao = useSelector(state => state.servicosState.paginacao);

    const statusModal = useSelector(state => state.servicosState.statusModal);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const isLoading = useSelector((state) => state.servicosState.isLoading);
    const formPesquisa = useSelector((state) => state.servicosState.formPesquisa);
    const olts = useSelector(state => state.oltsState.olts);

    useEffect(() => {
        dispatch(listarServicos());
        dispatch(listarOlts());

        return () => {
            dispatch(handleIsLoadingServicesStatus(false));
            dispatch(resetServiceState());
        };
    }, [dispatch]);

    const listHead = [
        {
            colunm: 'nome',
            text: defaltText.inputs.nome,
            className: 'text-center'
        },
        {
            colunm: 'actions',
            text: defaltText.inputs.acoes,
            className: 'actions'
        },
        {
            colunm: 'statusList',
            text: defaltText.inputs.status,
            className: 'text-center'
        },
        {
            colunm: 'actionsService',
            text: defaltText.inputs.servicos,
            className: 'actions'
        }
    ];

    const listActions = val => {
        switch (val.item) {
            case 'statusList':
                return val.content.status === 'ERRO' ? (
                    <Button
                        titulo={val.content.status}
                        action={() => dispatch(abrirModalErroServico(val.content))}
                        children={val.content.status}
                        color="success"
                        type='btn circle'
                    />
                ) : (
                    val.content.status
                );
            case 'actions':
                return (
                    <>
                        <Button
                            id="btnEditar"
                            titulo={defaltText.btn.editar}
                            action={() => dispatch(abrirModalEditarServico(val.content))}
                            type='btn circle'
                            color='secondary'
                            children={<IcoEditar title={defaltText.btn.editar} />}
                            size='small'
                            disabled={val.itens.status === "OK" ? false : true}
                        />
                        <Button
                            id="btnDeletar"
                            titulo={defaltText.btn.remover}
                            action={() => dispatch(abrirModalDeletarServico(val.content))}
                            type='btn circle'
                            color="success"
                            children={<IcoRemoveLine title={defaltText.btn.remover} />}
                            size='small'
                            disabled={val.itens.status === "ERRO" || val.itens.status === "OK" ? false : true}
                        />
                    </>
                );
            case 'actionsService':
                return (
                    <>
                        <Button
                            id="btnServicosTelefone"
                            titulo={defaltText.btn.telefone}
                            action={() => null}
                            color={val.content.plano && val.content.plano.servicoDeTelefone ? 'secondary' : 'secondary'}
                            type='btn circle'
                            children={<IcoChamadas title={defaltText.btn.telefone} />}
                            size='small'
                        />
                        <Button
                            id="btnServicosInternet"
                            titulo={defaltText.btn.internet}
                            action={() => null}
                            color={val.content.plano && val.content.plano.servicoDeInternet ? 'secondary' : 'secondary'}
                            type='btn circle'
                            children={<IcoWanLan title={defaltText.btn.internet} />}
                            size='small'
                        />
                    </>
                );
            default:
                return;
        }
    };

    const atualizarLista = (val, formPesquisa) => {
        dispatch(listarServicos({ pesquisa: { formPesquisa: { ...formPesquisa } }, pagina: val }));
    };

    //Daqui pra baixo código do card novo
    const labels = [
        { field: "nome", text: defaltText.inputs.nome },
        { field: "modePlanos", text: defaltText.inputs.plano, optionalValue: defaltText.inputs.planoCustomizado },
        { field: "olt.nome", text: defaltText.inputs.olt },
        { field: "cpe.identificador", text: defaltText.inputs.cpe },
        { field: "vlans", text: defaltText.inputs.vlans },
        { field: "type", text: defaltText.inputs.tipo },
    ];

    function verificaLabel(item) {
        const olt = olts.filter(olts => olts.id === item.idOlt)[0];
        if (olt?.modelo === "OLT_8820I") {
            return labels;
        }
        labels.splice(5);
        return labels;
    }
    const buttons = ["btnEditar", "btnRemover", "btnServicosTelefone", "btnServicosInternet"];

    const modalServico = (e, i) => {
        switch (e[i]) {
            case 'criando':
                return [<CriarEditar action={() => dispatch(servico.id ? atualizarServico(servico, paginacao.pageNumber) : adicionarServico(servico, ((paginacao.totalElements + 1) / (12 * paginacao.totalPages)) <= 1 ? paginacao.totalPages - 1 : paginacao.totalPages))} />, modalServico(e, i + 1)];
            case 'editando':
                return [<CriarEditar action={() => dispatch(servico.id ? atualizarServico(servico, paginacao.pageNumber) : adicionarServico(servico, ((paginacao.totalElements + 1) / (12 * paginacao.totalPages)) <= 1 ? paginacao.totalPages - 1 : paginacao.totalPages))} />, modalServico(e, i + 1)];
            case 'deletando':
                return [<Remover />, modalServico(e, i + 1)];
            case 'erro':
                return [<Erro />, modalServico(e, i + 1)];
            case 'serviceInfo':
                return [<ServiceInfo />, modalServico(e, i + 1)];
            default:
                return;
        }
    };

    return (
        <>
            <SigCloudLoader visible={isLoading} />
            <div className="box-servicos">
                <TitleContainer
                    label={defaltText.title}
                />
                <div>
                    <BarraPesquisa />
                </div>
                <Row>
                    {servicos.map((item) => (
                        <div className="col-md-3 col-sm-6">
                            <ServiceCard
                                item={item}
                                labels={verificaLabel(item)}
                                markerColor={item.status === "OK" ? "ok" : item.status === "ERRO" ? "error" : item.status.toUpperCase() === "DELETANDO" ? "delete" : "updating"}
                            />
                        </div>
                    ))}
                </Row>
                {paginacao && <Paginate data={paginacao} action={value => atualizarLista(value, formPesquisa)} />}
            </div>
            {modalServico(statusModal, 0)}
        </>
    );
};

export default Servicos;
