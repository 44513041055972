import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from "../../../../components/row/row";
import { obterSFPDaInterface } from '../../redux/OltActions';
import "./modalSFP.css";

export default () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../nls/${locales}.json`);
    const selectedInterface = useSelector((state) => state.oltsState.selectedInterface);
    const oltId = window.location.pathname.split('/')[3];
    const sfpInfo = useSelector((state) => state.oltsState.sfpInfo);

    useEffect(() => {
        dispatch(obterSFPDaInterface(selectedInterface, oltId));
    }, []);

    return (
        <section>
            <Row>
                <div className='col-md-12 list-topic'>
                    <p style={{ fontWeight: "bold" }}>{defaultText.inputs.generalInformation}</p>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.opticalModuleStatus}:</div>
                        <div className='list-topic-item'>{sfpInfo.statusOptModule ? (
                            sfpInfo.statusOptModule === 'Online' ?
                                <div className='online-box'>{sfpInfo.statusOptModule}</div>
                                : sfpInfo.statusOptModule
                        ) : "---"}</div>
                    </div>

                    <div className='list-item'>

                        <div className='list-topic-item'>{defaultText.inputs.portState}:</div>
                        <div className='list-topic-item '>{sfpInfo.portState ? sfpInfo.portState : "---"}</div>
                    </div>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.transceiverType}:</div>
                        <div className='list-topic-item'>{sfpInfo.transceiverType ? sfpInfo.transceiverType : "---"}</div>
                    </div>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.moduleType}:</div>
                        <div className='list-topic-item '>{sfpInfo.moduleType ? sfpInfo.moduleType : "---"}</div>
                    </div>

                </div>

                <div className='col-md-12 list-topic'>

                    <p style={{ fontWeight: "bold" }} className='label-modal'>{defaultText.inputs.diagnosticInformation}</p>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.temperature}:</div>
                        <div className='list-topic-item'>{sfpInfo.temperature ? sfpInfo.temperature : "---"}</div>

                    </div>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.voltage}:</div>
                        <div className='list-topic-item'>{sfpInfo.voltage ? sfpInfo.voltage : "---"}</div>
                    </div>


                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.current}:</div>
                        <div className='list-topic-item'>{sfpInfo.biasCurrent ? sfpInfo.biasCurrent : "---"}</div>
                    </div>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.rxPower}:</div>
                        <div className='list-topic-item'>{sfpInfo.rxPower ? sfpInfo.rxPower : "---"}</div>
                    </div>


                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.txPower}:</div>
                        <div className='list-topic-item'>{sfpInfo.txPower ? sfpInfo.txPower : "---"}</div>
                    </div>

                </div>

                <div className='col-md-12 list-topic'>
                    <p style={{ fontWeight: "bold" }} className='label-modal'>{defaultText.inputs.manufactureInformation}</p>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.vendorName}:</div>
                        <div className='list-topic-item'>{sfpInfo.vendorName ? sfpInfo.vendorName : "---"}</div>
                    </div>


                    <div className='list-item'>
                        <div className='list-topic-item'>{defaultText.inputs.vendorPN}:</div>
                        <div className='list-topic-item'>{sfpInfo.vendorPN ? sfpInfo.vendorPN : "---"}</div>
                    </div>

                </div>
            </Row>
        </section>
    );
};
