import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/button/Button';
import DropdownList from '../../../components/dropdownList/dropdownList';
import { InputInboxSenha } from '../../../components/form';
import InputInbox from '../../../components/form/inputInBox/InputInbox';
import { IcoMais } from '../../../components/icones';
import { IcoMenos } from '../../../components/icones/menos';
import Row from '../../../components/row/row';
import Input from '../../../layouts/components/Input/Input';
import { validarCampoAction } from '../../../validation/validationActions';
import { clearCpeField, updateModalManageCpeContent } from '../redux/CpeActions';
import ConteudoDropdownContentAdvancedRouterSettings from './dropdownContent/dropdownContentAdvancedPPPoE';
import InputPassword from '../../../layouts/components/Input/InputPassword';
import { handleOnKeyDown } from '../../../utils/utils';

const ConteudoGerenciarCpe = () => {
    const [dropdownsState, setDropdownsState] = useState({
        advancedRouterSettings: null,
        wifiSettings: null
    });

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const cpe = useSelector((state) => state.cpesState.cpe);
    const mensagemErro = useSelector(state => state.validacaoState);
    const dispatch = useDispatch();

    const handleDropdownChange = (event) => {
        //let actualStatus = dropdownsState[event.target.value];
        let actualStatus = dropdownsState[event.currentTarget.value];
        let newArray = {
            ...dropdownsState
        };
        //newArray[event.target.value] = actualStatus === true ? false : true;
        newArray[event.currentTarget.value] = actualStatus === true ? false : true;
        setDropdownsState(newArray);
    };

    const [cpeInputsRefs, setCpeInputsRefs] = useState([
        useRef(),
        useRef()
    ]);

    return (
        <Row>
            {/*           <div className="col-md-12" style={{ marginBottom: "20px" }}>
                <h4>Esta funcionalidade está em desenvolvimento</h4>
            </div> */}

            {/*             <div className="col-md-6">
                <p>Router PPPoE</p>
                <InputInbox
                    name='usuarioPPPoE'
                    label={defaultText.inputs.usuarioPPPoE}
                    value={cpe.usuarioPPPoE}
                    changeInput={e => dispatch(updateModalManageCpeContent(e))}
                    validate={{
                        //action: e => dispatch(validarCampoAction(e)),
                        erro: mensagemErro.usuarioPPPoE ? defaultText.mensagem.obrigatorio : ''
                    }}
                    length={{
                        max: '100'
                    }}
                />

                <InputInboxSenha
                    name='senhaPPPoE'
                    label={defaultText.inputs.senhaPPPoE}
                    value={cpe.senhaPPPoE}
                    changeInput={e => dispatch(updateModalManageCpeContent(e))}
                    validate={{
                        //action: e => dispatch(validarCampoAction(e)),
                        erro: mensagemErro.senhaPPPoE ? defaultText.mensagem.obrigatorio : ''
                    }}
                    length={{
                        max: '100'
                    }}
                    seeText={defaultText.btn.ver}
                />

                <DropdownList
                    title={defaultText.advancedRouterSettings}
                    button={
                        <Button
                            type="btn circle"
                            children={dropdownsState.advancedRouterSettings ? <IcoMenos title="Fechar" /> : <IcoMais title="Abrir" />}
                            value="advancedRouterSettings"
                            action={(e) => { handleDropdownChange(e); }}
                            color="transparent"
                        />
                    }
                    dropdownContent={
                        <ConteudoDropdownContentAdvancedRouterSettings
                            dropdownState={dropdownsState}
                        />
                    }
                />
            </div> */}

            <div className="col-md-12">
                <p>WiFi (SSID)</p>
                <Input
                    reference={cpeInputsRefs[0]}
                    id="ssid"
                    name='ssid'
                    label={defaultText.inputs.ssid}
                    value={cpe.ssid}
                    changeInput={e => dispatch(updateModalManageCpeContent(e))}
                    validate={{
                        pattern: "ssid",
                        action: e => dispatch(validarCampoAction(e)),
                        erro: mensagemErro.ssid && mensagemErro.ssid === "pattern" ? defaultText.mensagem.padraoObrigatorioSSID : (mensagemErro.ssid ? defaultText.mensagem.obrigatorio : '')
                    }}
                    length={{
                        max: '25'
                    }}
                    clearField={{
                        action: (e) => dispatch(clearCpeField(e)),
                        text: defaultText.btn.limparCampo
                    }}
                    onKeyDown={(e) => handleOnKeyDown(e, cpeInputsRefs[0], cpeInputsRefs)}
                />

                <InputPassword
                    reference={cpeInputsRefs[1]}
                    name='senhaWiFi'
                    label={defaultText.inputs.senhaWiFi}
                    value={cpe.senhaWiFi}
                    changeInput={e => dispatch(updateModalManageCpeContent(e))}
                    validate={{
                        pattern: "wifiPassword",
                        action: e => dispatch(validarCampoAction(e)),
                        erro: mensagemErro.senhaWiFi && mensagemErro.senhaWiFi === "pattern" ? defaultText.mensagem.padraoObrigatorioSenhaWifi : (mensagemErro.senhaWiFi ? defaultText.mensagem.obrigatorio : '')
                    }}
                    length={{
                        max: '25'
                    }}
                    seeText={defaultText.btn.ver}
                    onKeyDown={(e) => handleOnKeyDown(e, cpeInputsRefs[1], cpeInputsRefs)}
                />
            </div>
        </Row>
    );
};

export default ConteudoGerenciarCpe;