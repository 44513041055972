import React from 'react';
import "./cards.css";
import { IcoEditar, IcoInfo, IcoRemoveLine, } from '../../../components/icones';
import CardButton from '../cardButton/CardButton';
import { useDispatch, useSelector } from 'react-redux';
import { getPagePermissions, translateStatus } from '../../../utils/utils';
import CardHeaderButton from '../cardHeaderButton/CardHeaderButton';
import { AttentionIcon, CorrectIcon, SyncIcon, InfoIcon } from '../icons';
import { abrirModalDeletarServico, abrirModalEditarServico, abrirModalErroServico, openServiceInfoModal } from '../../../modulos/servicos/redux/ServicoActions';
import _ from 'lodash';

const ServiceCard = ({ markerColor = "ok", item, labels }) => {
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../../modulos/servicos/nls/${locales}.json`);
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const getServiceCardHeaderButton = (val) => {
        if (val.status === 'OK') {
            return <CardHeaderButton icon={<CorrectIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
        }
        else {
            if (val.status.includes("ERRO")) {
                return <CardHeaderButton label={defaultText.btn.reenviar} title={defaultText.mensagem.reenviarComando} disabled={getPagePermissions(permissions, "olts", "update") ? false : true} icon={<AttentionIcon title={translateStatus(val.status, locales)} />} action={() => dispatch(abrirModalErroServico(val))} markerColor={markerColor} />;
            }
            else {
                return <CardHeaderButton icon={<SyncIcon className="spin" title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
        }
    };

    function getItem(item, field){
        if(field === "modePlanos"){
            return item?.modePlanos?.length > 0 ? item.modePlanos.join(", ") : defaultText.inputs.planoCustomizado;
        }else if(field === "vlans"){
            return item?.vlans?.length > 0 ? item.vlans.join(", ") : "--"
        }
        return _.get(item, field);
    }
    return (
        <div className={`cartao ${markerColor}`}>
            <div className="cartao-cabecalho">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p><span className="bold-text">{item[labels[0].field]}</span></p>
                    <CardHeaderButton markerColor={markerColor} icon={getServiceCardHeaderButton(item)} />
                </div>
                <div className="section-divisor"></div>
            </div>

            <div className="cartao-corpo">
                {labels.map((label, index) => index > 0 && <p>{label.text}: <span className={`bold-text ${label.field === "status" && markerColor}`}>{getItem(item, label.field) || label.optionalValue}</span></p>)}
            </div>

            <div className="cartao-rodape">

                <CardButton
                    hasAccess={getPagePermissions(permissions, "servicos", "read")}
                    id="btnInfo"
                    label={defaultText.btn.informacoes}
                    action={() => dispatch(openServiceInfoModal(item))}
                    icon={<InfoIcon />}
                />

                <div className="vertical-section-divisor"></div>

                <CardButton
                    hasAccess={getPagePermissions(permissions, "servicos", "update")}
                    id="btnEditar"
                    label={defaultText.btn.editar}
                    action={() => dispatch(abrirModalEditarServico(item))}
                    disabled={(item.status !== "OK") ? true : false}
                    icon={<IcoEditar />}
                />

                <div className="vertical-section-divisor"></div>

                <CardButton
                    hasAccess={getPagePermissions(permissions, "servicos", "delete")}
                    id="btnRemover"
                    label={defaultText.btn.remover}
                    action={() => dispatch(abrirModalDeletarServico(item))}
                    disabled={(item.status === "DELETANDO") ? true : false}
                    icon={<IcoRemoveLine />}
                />
            </div>
        </div>
    );
};

export default ServiceCard;
