import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from '../../../components/row/row';
import Table from '../../../layouts/components/Table/Table';

const ListaConteudoServico = () => {
    const locales = useSelector((state) => state.layoutState.linguagem);

    const defaltText = require(`../nls/${locales}.json`);
    const defaltTextServico = require(`../../servicos/nls/${locales}.json`);
    const servicos = useSelector((state) => state.cpesState.cpe.servicos);

    const labels = [
        /* { text: defaltText.inputs.cpe, field: "cpe" }, */
        /* { text: defaltTextServico.inputs.plano, field: "plano" }, */
        { text: defaltText.inputs.nome, field: "nome" },
        { text: defaltText.inputs.status, field: "status" }
    ];


    return (
        <>
            {/* {servicos && servicos.length ? (
                servicos.map((servico) => {
                    return (
                        <div key={`${servico.id}`} className="box-servicos-lista">
                            <Row>
                                <div className="col-md-12">
                                    <h6>
                                        {defaltText.inputs.servicos}: {servico.nome}
                                    </h6>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-3">
                                    <label htmlFor="*">{defaltText.inputs.cpe}</label>
                                    <p>{servico.cpe ? servico.cpe : ''}</p>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="*">{defaltTextServico.inputs.plano}</label>
                                    <p>{servico.plano ? servico.plano.nome : ''}</p>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="*">{defaltText.inputs.status}</label>
                                    <p>{servico.status ? servico.status : ''}</p>
                                </div>
                            </Row>
                        </div>
                    );
                })
            ) : (
                <p>{defaltText.semServico}</p>
            )} */}

            {servicos && servicos.length > 0 ? (
                <Table
                    itens={servicos}
                    labels={labels}
                />
            ) : (
                <div>{defaltText.mensagem.semRegistros}</div>
            )}

        </>
    );

};

export default ListaConteudoServico;
