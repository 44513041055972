import React from 'react';
import './iconeSideBar.css';

import { OltIcon, DarkOlt, CpeIcon, ServicoIcon, PlanoIcon, AssinanteIcon } from '../../../components/icones';
import { useSelector } from 'react-redux';
import { RefreshIcon } from '../icons';
import { DashboardIcon } from '../../../components/icones/dashboard';

const IconeSideBar = (props) => {

    const userTheme = useSelector((state) => state.layoutState.selectedTheme.id);

    const handleIcoShowing = (name) => {
        switch (name) {
            case 'dashboard':
                return (
                    <DashboardIcon title={(!props.open && props.title && props.title)} className={(!props.open ? "closed " + (props.active && "active") : " opened")} />
                );
            case 'olts':
                if (userTheme === "dark-theme") {
                    return (
                        <OltIcon title={(!props.open && props.title && props.title)} className={(!props.open ? "closed " + (props.active && "active") : " opened")} />
                    );
                }
                else {
                    return (
                        <DarkOlt title={(!props.open && props.title && props.title)} className={(!props.open ? "closed " + (props.active && "active") : " opened")} />
                    );
                }

            case 'planos':
                return (
                    <PlanoIcon title={(!props.open && props.title && props.title)} className={(!props.open ? "closed " + (props.active && "active") : " opened")} />
                );
            case 'servicos':
                return (
                    <ServicoIcon title={(!props.open && props.title && props.title)} className={(!props.open ? "closed " + (props.active && "active") : " opened")} />
                );
            case 'assinantes':
                return (
                    <AssinanteIcon title={(!props.open && props.title && props.title)} className={(!props.open ? "closed " + (props.active && "active") : " opened")} />
                );
            case 'cpes':
                return (
                    <CpeIcon title={(!props.open && props.title && props.title)} className={(!props.open ? "closed " + (props.active && "active") : " opened")} />
                );
            case 'refresh':
                return (
                    <RefreshIcon title={(!props.open && props.title && props.title)} className={(!props.open ? "closed " + (props.active && "active") : " opened")} />
                );
        }

    };

    return (
        <div className={(!props.open ? "closed " + (props.active && "active") : " opened") + " menuIcon"}>
            {handleIcoShowing(props.icoType)}
        </div>
    );
};

export default IconeSideBar;