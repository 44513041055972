import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IcoInfo } from '../components/icones';

export const getPagePermissions = (permissions, pageName, permissionType) => {
    if (permissions) {
        return permissions.filter((permission) => permission.name === pageName)[0][permissionType];
    }
    else {
        return false;
    }
};

export const loadMenuItens = (permissions, defaultText) => {
    let menuItens = [];
    if (permissions && permissions.length > 0) {
        defaultText.filter((item) => {
            return permissions.map((permission) => {
                if (item.item === permission.name) {
                    menuItens = [...menuItens, item];
                }
            });
        });
    }
    return menuItens;
};

export const checkResponseErrorType = (error, message) => {
    if (error.response.data.message !== "Access is denied") {
        return toast.error((message), {
            icon: <div className='divIconToastError'><IcoInfo /></div>
        });
    }
};

export const handleOnKeyDown = (event, activeReference, references) => {
    var indexOfActiveElement = references.indexOf(activeReference);
    if (event.nativeEvent.shiftKey && event.nativeEvent.keyCode === 9) {
        if ((indexOfActiveElement - 1) >= 0) {
            event.nativeEvent.preventDefault();
            references[indexOfActiveElement - 1].current.focus();
        }
        event.nativeEvent.preventDefault();
    }
    else {
        if (event.nativeEvent.keyCode === 9) {
            if ((indexOfActiveElement + 1) <= (references.length - 1)) {
                event.nativeEvent.preventDefault();
                references[indexOfActiveElement + 1].current.focus();
            }
        }
    }
};

export const cnpjValidate = (cnpj) => {
    let digit = 1;
    let splitedCnpj = cnpj.split("").map((num) => parseInt(num)).reverse();
    let valuesMatched = 0;

    if (splitedCnpj.filter((num, index) => splitedCnpj.indexOf(num) == index).length < 2) {
        return false;
    }

    for (let j = 0; j < 2; j++) {
        let index = 2;
        let totalSum = 0;
        for (let i = digit === 1 ? 2 : 1; i < splitedCnpj.length; i++) {
            totalSum = totalSum + (splitedCnpj[i] * index);
            if (index === 9) {
                index = 2;
            }
            else {
                index++;
            }
        }

        let checkValue = ((totalSum * 10) % 11) === 10 ? 0 : ((totalSum * 10) % 11);
        valuesMatched = (checkValue === (digit === 1 ? parseInt(cnpj[12]) : parseInt(cnpj[13]))) ? valuesMatched + 1 : valuesMatched;
        digit++;
    }
    return valuesMatched === 2;
};

export const cpfValidate = (cpf) => {
    let digit = 1;
    let splitedCpf = cpf.split("").map((num) => parseInt(num)).reverse();
    let valuesMatched = 0;

    if (splitedCpf.filter((num, index) => splitedCpf.indexOf(num) == index).length < 2) {
        return false;
    }

    for (let j = 0; j < 2; j++) {
        let index = 2;
        let totalSum = 0;
        for (let i = digit === 1 ? 2 : 1; i < splitedCpf.length; i++) {
            totalSum = totalSum + (splitedCpf[i] * index);
            index++;
        }
        let checkValue = ((totalSum * 10) % 11) === 10 ? 0 : ((totalSum * 10) % 11);
        valuesMatched = (checkValue === (digit === 1 ? parseInt(cpf[9]) : parseInt(cpf[10]))) ? valuesMatched + 1 : valuesMatched;
        digit++;
    }
    return valuesMatched === 2;
};

export const translateStatus = (item, locales) => {
    const itemField = item;
    const defaultText = require(`../layouts/nls/${locales}.json`);
    if (typeof itemField === 'string' && itemField.toUpperCase().includes("ERRO_PROCESSAMENTO")) {
        return defaultText.status.erroProcessamento.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("CRIANDO")) {
        return defaultText.status.criando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("ERRO_AO_DELETAR")) {
        return defaultText.status.erroDeletar.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("CARREGANDO")) {
        return defaultText.status.carregando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("DELETANDO")) {
        return defaultText.status.deletando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("EDITANDO")) {
        return defaultText.status.editando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("REINICIANDO")) {
        return defaultText.status.reiniciando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("RECONECTANDO")) {
        return defaultText.status.reconectando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("BAIXANDO")) {
        return defaultText.status.baixando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("ERRO_AO_BAIXAR")) {
        return defaultText.status.erroDownload.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("ATUALIZANDO")) {
        return defaultText.status.atualizando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("ERRO_AO_ATUALIZAR")) {
        return defaultText.status.erroAtualizar.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("NAO_PROVISIONADA")) {
        return defaultText.status.naoProvisionada.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("ONLINE")) {
        return defaultText.status.online.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("OFFLINE")) {
        return defaultText.status.offline.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("PROBLEMA_OPTICO")) {
        return defaultText.status.problemaOptico.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("ADAPTER_NAO_CONECTADO")) {
        return defaultText.status.adapterNaoConectado.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("NAO_SUPORTADA")) {
        return defaultText.status.naoSuportada.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("PROVISIONANDO")) {
        return defaultText.status.provisionando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("INSERT")) {
        return defaultText.status.ativa.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("EMPTY")) {
        return defaultText.status.desativada.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("CONECTANDO")) {
        return defaultText.status.conectando.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("ERRO_DE_CREDENCIAIS")) {
        return defaultText.status.erroCredenciais.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("ERRO_IP_INVALIDO")) {
        return defaultText.status.erroIpInvalido.toUpperCase();
    } else if (typeof itemField === 'string' && itemField.toUpperCase().includes("ERRO")) {
        return defaultText.status.erro.toUpperCase();
    }
    else {
        return itemField;
    }
};

export const validacaoSenha = (senha) => {

    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

    if (strongRegex.test(senha)) {
        return 'forte';
    } else if (mediumRegex.test(senha)) {
        return 'media';
    } else {
        return 'fraca';
    }
};

//Função para pegar o tamanho da janela
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const validacaoIdentificadorCpe = (identificador) => {
    var identificadorTratado = identificador.includes('--') ? identificador.replace('--', '-') : identificador;
    var identificadorRegex = new RegExp("^[A-Z]{4}-[0-9a-fA-F]{8}$");
    return identificadorRegex.test(identificadorTratado);
};

export const dataTimeZoneZero = (horario) => {
    const sfpHorario = new Date(horario.replace(/Z\[UTC]/, ''));
    const dateNow = new Date();

    const timeZone = (dateNow.getHours() - sfpHorario.getHours());
    const addHours = (hora, data) => new Date(data.getTime() + hora * 60 * 60 * 1000);

    return `${addHours(timeZone, sfpHorario).getHours()}:${(addHours(timeZone, sfpHorario).getMinutes() < 10 ? '0' : '') + sfpHorario.getMinutes()} ${sfpHorario.getDate()}/${sfpHorario.getMonth() + 1}/${sfpHorario.getFullYear()}`;
};

export const validacaoIp = (ip) => {
    var ipRegex = new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/);
    return ipRegex.test(ip);
};