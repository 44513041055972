import { useDispatch, useSelector } from 'react-redux';

import { InputInboxSenha, SelectInBox } from '../../../components/form';
import React, { useEffect, useRef, useState } from 'react';
import { atualizarConteudoOltModal, clearCriarEditarOltField } from '../redux/OltActions';
import { validarCampoAction } from '../../../validation/validationActions';
import Row from '../../../components/row/row';
import DropdownList from '../../../components/dropdownList/dropdownList';

import Checkbox from '../../../components/form/checkbox/Checkbox';
import '../olt.css';

import { listarPlanos } from '../../planos/redux/PlanosActions';
import { atualizarOperationMode } from '../redux/OltActions';
import Button from '../../../components/button/Button';
import { IcoMenos } from '../../../components/icones/menos';
import { IcoMais, ExclamationIcon } from '../../../components/icones';
import CheckboxSwitch from '../../../components/form/checkboxSwitch/CheckboxSwitch';
import Input from '../../../layouts/components/Input/Input';
import OperationModeChangeMessage from './operationModeConfirmChange/changeToAutoserviceConfirm';
import InputPassword from '../../../layouts/components/Input/InputPassword';
import { handleOnKeyDown } from '../../../utils/utils';
import { maskIp } from '../../../components/mask/Mask';
import RadioButton from '../../../components/form/radioButton/RadioButton';

const ConteudoOltModal = () => {
    const [oltInputsRefs, setOltInputsRefs] = useState([
        useRef(),
        useRef(),
    ]);

    const [telnetInputsRefs, setTelnetInputsRefs] = useState([
        useRef(),
        useRef(),
        useRef(),
    ]);

    const objectEquals = (firstObject, secondObject) => {
        return JSON.stringify(firstObject) === JSON.stringify(secondObject);
    };

    const [dropdownsState, setDropdownsState] = useState({
        telnetProtocol: true,
        sshProtocol: false,
        snmpProtocol: false
    });
    /* const [disableWhenEditing, setDisableWhenEditing] = useState(false); */

    const handleDropdownChange = (event) => {
        //let actualStatus = dropdownsState[event.target.value];
        let actualStatus = dropdownsState[event.currentTarget.value];
        let newArray = {
            ...dropdownsState
        };
        //newArray[event.target.value] = actualStatus === true ? false : true;
        newArray[event.currentTarget.value] = actualStatus === true ? false : true;
        setDropdownsState(newArray);
    };
    function searchOperationMode(operationModeId) {
        let index = -1;
        operationModes.map((operationMode, i) => {
            if (operationMode.id === operationModeId) {
                index = i;
            }
        });
        //return index;
        if (index > -1) {
            dispatch(atualizarOperationMode(operationModes[index]));
        }
        return operationModes[index];
        //searchOperationMode(olt.operationMode) > -1 ? operationModes[searchOperationMode(olt.operationMode)] : olt.operationMode
    }

    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const olt = useSelector(state => state.oltsState.olt);
    const [oldOlt, setOldOlt] = useState({});
    const [oltMode, setOldMode] = useState();
    const mensagemErro = useSelector(state => state.validacaoState);
    const planos = useSelector(state => state.planosState);
    const servico = useSelector(state => state.servicosState.servico);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const [seed, setSeed] = useState(false);
    const protocols = [
        { id: "telnet", text: "TELNET" },
        // { id: "ssh", text: "SSH" },
    ]
    const operationModes = [
        {
            id: "MANUALLY",
            nome: defaltText.inputs.operationModes.manual
        },
        {
            id: "AUTOSERVICE",
            nome: defaltText.inputs.operationModes.autoService
        },
        {
            id: "AUTOPROV",
            nome: defaltText.inputs.operationModes.autoProv
        },
        {
            id: "VLANPORPON",
            nome: defaltText.inputs.operationModes.interfacePon
        }
    ];

    function resetProgress() {
        setSeed(true)
    }
    function filterOperModesAvailable() {
        let operModes = [];
        if (olt.operModeAvailable2Olt) {
            operationModes.map((opMode) => {
                olt.operModeAvailable2Olt.map((op) => {
                    if (opMode.id === op) {
                        operModes.push(opMode);
                    }
                });
            });
        }

        return operModes;
    }

    useEffect(() => {
        listarPlanos(0);
        setOldOlt({ ...olt, operationMode: searchOperationMode(olt.operationMode) });
        searchOperationMode(olt.operationMode);
        setOldMode(olt.operationMode)
        /* setDisableWhenEditing(olt.id && true); */
    }, [dispatch]);

    function changeOldMode() {
        setOldMode(olt.operationMode.id);
    }
    const getMessage = (opMode) => {
        switch (opMode) {
            case "AUTOSERVICE":
                return defaltText.mensagem.alertaTrocaModoOperacaoParaAutoservico;
            case "MANUALLY":
                return defaltText.mensagem.alertaTrocaModoOperacaoParaManual;
            case "VLANPORPON":
                return defaltText.mensagem.alertaTrocaModoOperacaoParaVLANPORPON;
            case "AUTOPROV":
                return defaltText.mensagem.alertaTrocaModoOperacaoParaAutoprovisionamento;
            default:
                return;
        }
    };

    return (
        <section>
            <Row>
                <div className='col-md-6'>
                    <Input
                        reference={oltInputsRefs[0]}
                        id="nome"
                        name='nome'
                        label={defaltText.inputs.nome}
                        value={olt.nome}
                        changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.nome ? defaltText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '100'
                        }}
                        clearField={{
                            action: (e) => dispatch(clearCriarEditarOltField(e)),
                            text: defaltText.btn.limparCampo
                        }}
                        onKeyDown={(e) => handleOnKeyDown(e, oltInputsRefs[0], oltInputsRefs)}
                    />
                </div>
                <div className='col-md-6'>
                    <Input
                        reference={oltInputsRefs[1]}
                        id="ip"
                        name='ip'
                        label={defaltText.inputs.ip}
                        value={maskIp(olt.ip)}
                        changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.ip && mensagemErro.ip === "invalid" ? defaltText.mensagem.padraoObrigatorio : (mensagemErro.ip ? defaltText.mensagem.obrigatorio : ''),
                            pattern: 'ip'
                        }}
                        length={{
                            max: '20'
                        }}
                        /* disabled={disableWhenEditing} */
                        disabled={olt.state === "OK" && olt.state === "ERRO_PROCESSAMENTO" ? true : false}

                        clearField={{
                            action: (e) => dispatch(clearCriarEditarOltField(e)),
                            text: defaltText.btn.limparCampo
                        }}
                        onKeyDown={(e) => handleOnKeyDown(e, oltInputsRefs[1], oltInputsRefs)}
                    />
                </div>
            </Row>

            {mensagemErro.erroProtocolo && <Row><div className='col-md-12'>{defaltText.mensagem.selectProtocol}</div></Row>}

            <Row>
                <div className="col-md-12">
                    <DropdownList
                        title={defaltText.inputs.protocol}
                        titleComponent={
                            <div className="dropdownComponent" style={{ paddingRight: '50px', margin: '12px' }}>
                                <RadioButton
                                    id='protocol'
                                    name='protocol'
                                    // text={defaltText.mensagem.avisoProtocol}
                                    defaultChecked={protocols[0].id}
                                    changeInput={(e) => dispatch(atualizarConteudoOltModal(e))}
                                    selected={olt?.telnetChecked ? protocols[0].id : protocols[1].id}
                                    options={protocols}
                                    // disabled={true}
                                />
                            </div>
                        }
                        button={
                            <Button
                                type="btn circle"
                                children={dropdownsState.telnetProtocol ? <IcoMenos title="Fechar" /> : <IcoMais title="Abrir" />}
                                value="telnetProtocol"
                                action={(e) => { handleDropdownChange(e); }}
                                color="transparent"
                            />
                        }
                        dropdownContent={
                            <div name="telnetProtocol" className={dropdownsState.telnetProtocol ? "show" : "hide"}>
                                <Input
                                    reference={telnetInputsRefs[0]}
                                    id="telnetUser"
                                    name='telnetUser'
                                    label={defaltText.inputs.usuario}
                                    value={olt.telnetUser}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                                    validate={{
                                        action: e => dispatch(validarCampoAction(e)),
                                        erro: mensagemErro.telnetUser ? defaltText.mensagem.obrigatorio : ''
                                    }}
                                    length={{
                                        max: '30'
                                    }}
                                    clearField={{
                                        action: (e) => dispatch(clearCriarEditarOltField(e)),
                                        text: defaltText.btn.limparCampo
                                    }}
                                    onKeyDown={(e) => handleOnKeyDown(e, telnetInputsRefs[0], telnetInputsRefs)}
                                />

                                <InputPassword
                                    reference={telnetInputsRefs[1]}
                                    name='telnetPassword'
                                    label={defaltText.inputs.senha}
                                    value={olt.telnetPassword}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                                    validate={{
                                        action: e => dispatch(validarCampoAction(e)),
                                        erro: mensagemErro.telnetPassword ? defaltText.mensagem.obrigatorio : ''
                                    }}
                                    seeText={defaltText.btn.ver}
                                    length={{
                                        max: '20'
                                    }}
                                    onKeyDown={(e) => handleOnKeyDown(e, telnetInputsRefs[1], telnetInputsRefs)}
                                />

                                <Input
                                    reference={telnetInputsRefs[2]}
                                    id="telnetPort"
                                    type='number'
                                    name='telnetPort'
                                    label={defaltText.inputs.porta}
                                    value={olt.telnetPort}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                                    validate={{
                                        action: e => dispatch(validarCampoAction(e)),
                                        erro: mensagemErro.telnetPort ? defaltText.mensagem.obrigatorio : ''
                                    }}
                                    length={{
                                        max: '4'
                                    }}
                                    /* disabled={disableWhenEditing} */
                                    disabled={olt.state === "OK" && olt.state === "ERRO_PROCESSAMENTO" ? true : false}
                                    clearField={{
                                        action: (e) => dispatch(clearCriarEditarOltField(e)),
                                        text: defaltText.btn.limparCampo
                                    }}
                                    onKeyDown={(e) => handleOnKeyDown(e, telnetInputsRefs[2], telnetInputsRefs)}
                                />
                            </div>
                        }
                    />
                </div>

                {/* <div className="col-md-6">
                    <DropdownList
                        title={defaltText.sshProtocol}
                        expandMenuColor="development"
                        titleComponent={
                            <div className="dropdownComponent">
                                <CheckboxSwitch
                                    id={`${defaltText.inputs.sshChecked}`}
                                    name={defaltText.inputs.sshChecked}
                                    //label={`${defaltText.inputs.status} ${olt.sshChecked === true ? "ativo" : "inativo"}`}
                                    checked={olt.sshChecked}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))
                                    }
                                />
                            </div>
                        }
                        button={
                            <Button
                                type="btn circle"
                                children={dropdownsState.sshProtocol ? <IcoMenos title="Fechar" /> : <IcoMais title="Abrir" />}
                                value="sshProtocol"
                                action={(e) => { handleDropdownChange(e); }}
                                color="transparent"
                            />
                        }
                        dropdownContent={
                            <div name="sshProtocol" className={dropdownsState.sshProtocol ? "show" : "hide"}>
                                <Input
                                    id="sshUser"
                                    name='sshUser'
                                    label={defaltText.inputs.usuario}
                                    value={olt.sshUser}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                                    validate={{
                                        action: e => dispatch(validarCampoAction(e)),
                                        erro: mensagemErro.sshUser ? defaltText.mensagem.obrigatorio : ''
                                    }}
                                    length={{
                                        max: '30'
                                    }}
                                    clearField={{
                                        action: (e) => dispatch(clearCriarEditarOltField(e)),
                                        text: defaltText.btn.limparCampo
                                    }}
                                />

                                <InputInboxSenha
                                    name='sshPassword'
                                    label={defaltText.inputs.senha}
                                    value={olt.sshPassword}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                                    validate={{
                                        action: e => dispatch(validarCampoAction(e)),
                                        erro: mensagemErro.sshPassword ? defaltText.mensagem.obrigatorio : ''
                                    }}
                                    seeText={defaltText.btn.ver}
                                    length={{
                                        max: '20'
                                    }}
                                />

                                <Input
                                    id="sshPort"
                                    type='number'
                                    name='sshPort'
                                    label={defaltText.inputs.porta}
                                    value={olt.sshPort}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                                    validate={{
                                        action: e => dispatch(validarCampoAction(e)),
                                        erro: mensagemErro.sshPort ? defaltText.mensagem.obrigatorio : ''
                                    }}
                                    length={{
                                        max: '4'
                                    }}
                                    disabled={disableWhenEditing}
                                    clearField={{
                                        action: (e) => dispatch(clearCriarEditarOltField(e)),
                                        text: defaltText.btn.limparCampo
                                    }}
                                />
                            </div>
                        }
                    />
                </div>
 */}
                {/* <div className="col-md-6">
                    <DropdownList
                        title={defaltText.snmpProtocol}
                        expandMenuColor="development"
                        titleComponent={
                            <div className="dropdownComponent">
                                <CheckboxSwitch
                                    id={`${defaltText.inputs.snmpChecked}`}
                                    name={defaltText.inputs.snmpChecked}
                                    //label={`${defaltText.inputs.status} ${olt.snmpChecked === true ? "ativo" : "inativo"}`}
                                    checked={olt.snmpChecked}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))
                                    }
                                />
                            </div>
                        }
                        button={
                            <Button
                                type="btn circle"
                                children={dropdownsState.snmpProtocol ? <IcoMenos title="Fechar" /> : <IcoMais title="Abrir" />}
                                value="snmpProtocol"
                                action={(e) => { handleDropdownChange(e); }}
                                color="transparent"
                            />
                        }
                        dropdownContent={
                            <div name="snmpProtocol" className={dropdownsState.snmpProtocol ? "show" : "hide"}>
                                <Input
                                    id="snmpReadingCommunity"
                                    name='snmpReadingCommunity'
                                    label={defaltText.inputs.comunidadeLeitura}
                                    value={olt.snmpReadingCommunity}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                                    validate={{
                                        action: e => dispatch(validarCampoAction(e)),
                                        erro: mensagemErro.snmpReadingCommunity ? defaltText.mensagem.obrigatorio : ''
                                    }}
                                    length={{
                                        max: '30'
                                    }}
                                    clearField={{
                                        action: (e) => dispatch(clearCriarEditarOltField(e)),
                                        text: defaltText.btn.limparCampo
                                    }}
                                />

                                <InputInboxSenha
                                    name='snmpWritingCommunity'
                                    label={defaltText.inputs.comunidadeEscrita}
                                    value={olt.snmpWritingCommunity}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                                    validate={{
                                        action: e => dispatch(validarCampoAction(e)),
                                        erro: mensagemErro.snmpWritingCommunity ? defaltText.mensagem.obrigatorio : ''
                                    }}
                                    seeText={defaltText.btn.ver}
                                    length={{
                                        max: '20'
                                    }}
                                />

                                <Input
                                    id="snmpReadingCommunity"
                                    type='number'
                                    name='snmpPort'
                                    label={defaltText.inputs.porta}
                                    value={olt.snmpPort}
                                    changeInput={e => dispatch(atualizarConteudoOltModal(e))}
                                    validate={{
                                        action: e => dispatch(validarCampoAction(e)),
                                        erro: mensagemErro.snmpPort ? defaltText.mensagem.obrigatorio : ''
                                    }}
                                    length={{
                                        max: '4'
                                    }}
                                    disabled={disableWhenEditing}
                                    clearField={{
                                        action: (e) => dispatch(clearCriarEditarOltField(e)),
                                        text: defaltText.btn.limparCampo
                                    }}
                                />
                            </div>
                        }
                    />
                </div> */}
            </Row>

            {statusModal.includes("editando") ? (
                <div className="box-conteudo-criar-editar-olt">
                    <Row show={`${olt.status === "OK" ? "show" : "hide"}`}>
                        <div className="col-md-12">
                            <SelectInBox
                                position="relative"
                                name="operationMode"
                                id='operationMode'
                                disabled={statusModal.includes("editando") ? (olt.status === "OK" ? (filterOperModesAvailable().length > 0 ? false : true) : true) : true}
                                label={defaltText.inputs.operationMode}
                                selectDefault={{
                                    id: 'id',
                                    label: 'nome',
                                    /* text: defaltText.inputs.select.selecionado.nenhum */
                                }}
                                select={{
                                    action: e => dispatch(atualizarConteudoOltModal(e)),
                                    options: filterOperModesAvailable(),
                                    selected: olt.operationMode,
                                }}
                                oltMode={oltMode}
                                versionSoftware={olt.softwareVersion}
                                validate={{
                                    erro: mensagemErro.operationMode ? defaltText.mensagem.obrigatorio : ''
                                }}
                            />
                        </div>
                    </Row>

                    <Row show={`${(oldOlt.operationMode && !objectEquals(olt.operationMode, oldOlt.operationMode) && olt.status === 'OK') ? "show" : "hide"}`}>
                        <div className="col-md-12">
                            <div className="change-alert">
                                <OperationModeChangeMessage
                                    key={seed}
                                    resetProgress={resetProgress}
                                    changeOldMode={changeOldMode}
                                    message={olt.status === "OK" ? getMessage(olt.operationMode?.id) : ''}
                                    confirmText={defaltText.mensagem.termosAceiteMudancaModoOperacao}
                                    restMessage={olt.status === "OK" ? (olt.operationMode?.id === 'AUTOSERVICE' ? defaltText.mensagem.alertaTrocaModoOperacaoParaAutoservicoRest : '') : ''}
                                    operationMode={olt.status === "OK" ? olt.operationMode?.id : ''}
                                />
                            </div>
                        </div>

                    </Row>
                </div>
            ) : null}
        </section>
    );
};

export default ConteudoOltModal;
