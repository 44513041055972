import React, { useState } from 'react'
import { IcoVer, IcoVerNao } from '../../icones'

export function InputInboxSenha({
  label = '',
  name = '',
  value = '',
  changeInput,
  validate,
  length,
  seeText = 'Visível',
  onPressEnter = () => { },
  disabled
}) {
  const [verSenha, setVerSenha] = useState(false)
  return (
    <div className={`box-form ${disabled ? "disabled" : ""} ${value ? 'filled' : ''} ${validate && validate.erro ? 'erro' : ''}`}>
      <input
        disabled={disabled ? disabled : false}
        autocomplete="new-password"
        onKeyPress={onPressEnter}
        type={verSenha ? 'text' : 'password'}
        name={name}
        value={value}
        onChange={changeInput}
        id={name}
        required={!!validate}
        onBlur={validate ? validate.action : null}
        pattern={validate ? validate.pattern : null}
        maxLength={length ? length.max : null}
        minLength={length ? length.min : null}
        placeholder={label && label}
      />
      <label htmlFor={name}>
        {validate ? <sup className='text-danger'>✻ </sup> : ''}
        {label}
      </label>
      <button
        type='button'
        onClick={e => {
          e.preventDefault()
          setVerSenha(!verSenha)
        }}
        title={seeText}
        className='bt-input'
      >
        {verSenha ? <IcoVerNao title={seeText} /> : <IcoVer title={seeText} />}
      </button>
      {validate && validate.erro ? <div className='invalid-feedback'>{validate.erro}</div> : null}
    </div>
  )
}
