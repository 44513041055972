import React from 'react';

export function BrazilIcon(props) {
    return (
        <svg id={props.id} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 18 18">
            <g transform="translate(0.333 0.333)">
                <circle id="Elipse_2" data-name="Elipse 2" cx="9" cy="9" r="9" transform="translate(-0.333 -0.333)" fill="#00c85f" />
                <path id="Caminho_45" data-name="Caminho 45" d="M32.482,101.03l-7.6,5.068a.309.309,0,0,0,0,.513l7.6,5.068a.617.617,0,0,0,.684,0l7.6-5.068a.309.309,0,0,0,0-.513l-7.6-5.068A.617.617,0,0,0,32.482,101.03Z" transform="translate(-24.104 -97.626)" fill="#fff046" />
                <circle id="Elipse_3" data-name="Elipse 3" cx="3.702" cy="3.702" r="3.702" transform="translate(5.018 5.018)" fill="#4b82e1" />
                <path id="Caminho_46" data-name="Caminho 46" d="M157.063,200.984a3.683,3.683,0,0,0-.306.633,8.639,8.639,0,0,1,6.9,2.663,3.669,3.669,0,0,0,.2-.672A9.2,9.2,0,0,0,157.063,200.984Z" transform="translate(-151.505 -194.181)" fill="#fff" />
            </g>
        </svg>
    );
}