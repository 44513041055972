import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import { fecharModalPerfil } from '../redux/PerfilActions';

const Remover = ({ action }) => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const perfil = useSelector(state => state.perfisState.perfil);

    const statusModal = useSelector(state => state.perfisState.statusModal);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalPerfil())}
            containerModal={{
                title: defaltText.tituloModalRemover,
                size: 'medium',
                content: (
                    <>
                        <p>{defaltText.mensagem.removerConfirmacao}</p>
                        <p style={{ fontWeight: "bold" }}>{perfil.nome}</p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    acao: action,
                    texto: defaltText.btn.remover,
                    class: "success"
                }
            }}
        />
    );
};

export default Remover;
