import React from 'react';

export function PlanoIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="46.053"
            viewBox="0 0 35 46.053"
            className={props.className ? props.className : ""}
        >
            <title>{props.title}</title>
            <g id="Icon_ionic-ios-document" data-name="Icon ionic-ios-document" transform="translate(-147.313 -13.938)">
                <g id="Grupo_180" data-name="Grupo 180" transform="translate(147.313 13.938)">
                    <path id="Caminho_156" data-name="Caminho 156" d="M23.014,16.5H34.569a.629.629,0,0,0,.635-.635h0a3.758,3.758,0,0,0-1.359-2.908L24.157,4.884a4.078,4.078,0,0,0-2.616-.94h0a.937.937,0,0,0-.94.94V14.09A2.411,2.411,0,0,0,23.014,16.5Z" transform="translate(-0.204 -3.932)" />
                    <path id="Caminho_157" data-name="Caminho 157" d="M26.079,13.148V3.938H11A3.7,3.7,0,0,0,7.313,7.622V46.306A3.7,3.7,0,0,0,11,49.99H38.628a3.7,3.7,0,0,0,3.684-3.684V18.329H31.26A5.189,5.189,0,0,1,26.079,13.148Z" transform="translate(-7.313 -3.938)" />
                    <rect id="Retângulo_276" data-name="Retângulo 276" width="26.385" height="2.13" rx="1.065" transform="translate(4.695 23.096)" />
                    <rect id="Retângulo_281" data-name="Retângulo 281" width="26.385" height="2.13" rx="1.065" transform="translate(4.695 27.951)" />
                    <rect id="Retângulo_282" data-name="Retângulo 282" width="26.385" height="2.13" rx="1.065" transform="translate(4.695 32.805)" />
                    <rect id="Retângulo_283" data-name="Retângulo 283" width="26.385" height="2.13" rx="1.065" transform="translate(4.695 37.472)" />
                    <rect id="Retângulo_280" data-name="Retângulo 280" width="14.385" height="2.13" rx="1.065" transform="translate(4.695 18.242)" />
                </g>
            </g>
        </svg>
    );
}