import React from 'react';

export function IcoMais(props) {
    return (
        <span className={`btn-icon ${props.className && props.className}`}>
            <svg
                className="icon"
                viewBox="0 0 48 48"
                style={props.style ? props.style : {}}
            >
                <title>{props.title ? props.title : null}</title>
                <path d="M10 26h12v12c0 1.104 0.896 2 2 2s2-0.896 2-2v-12h12c1.104 0 2-0.896 2-2s-0.896-2-2-2h-12v-12c0-1.104-0.896-2-2-2s-2 0.896-2 2v12h-12c-1.104 0-2 0.896-2 2s0.896 2 2 2z" />
            </svg>
        </span>
    );
}
