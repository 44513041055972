import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/button/Button';
import { SelectInBox } from '../components/form';
import SideBarMenu from '../components/sidebarMenu/sidebarMenu';
import { abrirModalSair, listarIdiomas, atualizarPreferencia, sidebarRight, ajustIdioma } from './redux/LayoutActions';
import useOutsideClick from '../components/useOusideClick/useoutsideclick';
import ThemeSelector from '../components/ThemeSelector/ThemeSelector';
import { loadMenuItens } from '../utils/utils';

const SidebarRight = ({ refRight }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authentication.user);
    const preferencia = useSelector((state) => state.layoutState);

    const defaltMenu = require(`./menu-configuracao-json/menu-${preferencia.linguagem}.json`);
    const defaltText = require(`./nls/${preferencia.linguagem}.json`);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const handleChangePreferencia = (e) => {
        const userPreference = {
            ...user,
            preferencia: { ...user.preferencia, [e.target.name]: e.target.value.id },
        };
        dispatch(atualizarPreferencia(userPreference));
    };

    useEffect(() => {
        dispatch([listarIdiomas()]);
    }, [dispatch]);

    const ref = useRef();
    useOutsideClick(ref, (e) => {
        dispatch(sidebarRight(e));
    });

    return (
        <div className='box-sidebar-right' ref={ref}>
            <SideBarMenu MenuJson={loadMenuItens(permissions, defaltMenu)} action={() => dispatch(sidebarRight(false))} />
            <div>
                <ThemeSelector
                    label={defaltText.tema}
                />
                <SelectInBox
                    id='linguagem'
                    label={defaltText.linguagem}
                    selectDefault={{
                        id: 'id',
                        label: 'nome',
                        minWidth: '100%',
                    }}
                    select={{
                        action: (e) => handleChangePreferencia(e),
                        options: preferencia.linguagens,
                        selected: ajustIdioma(preferencia.linguagem),
                    }}
                />
                <Button
                    id="btnSair"
                    titulo={defaltText.sair}
                    action={() => dispatch(abrirModalSair())}
                    color='secondary'
                    children={defaltText.sair}
                    size='block'
                />
                <small className='version-sistem'>{defaltText.versao}</small>
            </div>
        </div>
    );
};

export default SidebarRight;
