import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import { IcoFilter, IcoMais, IcoSearch } from '../../components/icones';
import { IcoFilterClose } from '../../components/icones/filter-close';
import Row from '../../components/row/row';
import Input from '../../layouts/components/Input/Input';
import AdvancedFilter from '../../layouts/components/AdvancedFilter/AdvancedFilter';
import { getPagePermissions } from '../../utils/utils';
import { AdvancedFilterCpe } from './advancedFilter/advancedFilterCpe';
import { abrirModalCriarCpe, atualizarFormularioDePesquisaCpe, isSearch, limparCampoFormularioPesquisaCpe, pesquisaCpes, resetarCamposFormularioPesquisaCpe, setModalAdvancedFilterCPE, buscarCpesNaoProvisionada } from './redux/CpeActions';
import { listarOlts } from '../olt/redux/OltActions';
import { listarPlanos } from '../planos/redux/PlanosActions';

export default () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const cpe = useSelector((state) => state.cpesState);
    const formPesquisa = useSelector((state) => state.cpesState.formPesquisa);
    const defaultText = require(`./nls/${locales}.json`);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const modalAdvancedFilter = useSelector((state) => state.cpesState.modalAdvancedFilter);

    const [dropdownsState, setDropdownsState] = useState({
        campoFiltro: false,
    });

    const handleChangeModalAdvancedFilter = (modalAdvancedFilter) => {
        dispatch(setModalAdvancedFilterCPE(modalAdvancedFilter));
        dispatch(listarOlts(0));
        dispatch(listarPlanos(0));
    };
    
    const handleBuscarNaoProvisionadas = () => {
        dispatch(buscarCpesNaoProvisionada())
        setTimeout(function(){
            dispatch(pesquisaCpes())
        }, 2000);
    }
    const getModalAdvancedFilterOpen = (modalAdvancedFilter) => {
        switch (modalAdvancedFilter) {
            case 'advancedFilter':
                return <AdvancedFilter classAnchor='modal-Advanced-Services' children={<AdvancedFilterCpe />} />;
            default:
                return;
        }
    };

    const handlePressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(pesquisaCpes({ pesquisa: formPesquisa })),
                dispatch(isSearch());
        }
    };

    useEffect(() => {
        /* dispatch(listarOlts(0));
        dispatch(listarPlanos(0)); */
        return () => { dispatch(resetarCamposFormularioPesquisaCpe()); };
    }, [dispatch]);

    let pesquisaItens = {
        ...(cpe.formPesquisa.pesquisaGeral !== '' && { pesquisa: ` "${cpe.formPesquisa.pesquisaGeral}"` }),
        ...(cpe.formPesquisa.olt !== '' && { oltNome: ` "${cpe.formPesquisa.olt.nome}"` }),
        ...(cpe.formPesquisa.identificador !== '' && { identificador: ` "${cpe.formPesquisa.identificador}"` }),
        ...(cpe.formPesquisa.modelo !== '' && { modelo: ` "${cpe.formPesquisa.modelo}"` }),
        ...(cpe.formPesquisa.porta !== '' && { porta: ` "${cpe.formPesquisa.porta}"` }),
        ...(cpe.formPesquisa.status !== '' && { status: ` "${cpe.formPesquisa.status.statusCpe}"` }),
        ...(cpe.formPesquisa.descricao !== '' && { descricao: ` "${cpe.formPesquisa.descricao}"` }),
        ...(cpe.formPesquisa.plano !== '' && { plano: ` "${cpe.formPesquisa.plano.nome}"` })
    };

    return (
        <div className="box-cpe">
            <Row style={{ justifyContent: 'space-between' }}>
                <div className="col-md-10">
                    <div className="campo-pesquisa" name="campoPesquisa">
                        <Input
                            id="pesquisaGeral"
                            name='pesquisaGeral'
                            label={defaultText.pesquisar.text}
                            value={formPesquisa.pesquisaGeral}
                            changeInput={e => dispatch(atualizarFormularioDePesquisaCpe(e))}
                            onPressEnter={(e) => handlePressEnter(e)}
                            clearField={{
                                action: (e) => dispatch(limparCampoFormularioPesquisaCpe(e)),
                                text: defaultText.btn.limparCampo
                            }}
                            leftButton={{
                                action: () => { dispatch(pesquisaCpes({ pesquisa: formPesquisa })), dispatch(isSearch()); },
                                text: defaultText.pesquisar.text,
                                icon: <IcoSearch title={defaultText.pesquisar.text} />
                            }}
                        />
                        <Button
                            value="campoPesquisa"
                            titulo={defaultText.pesquisar.advancedFilter}
                            action={() => handleChangeModalAdvancedFilter('advancedFilter')}
                            texto={defaultText.pesquisar.advancedFilter}
                            children={modalAdvancedFilter === 'advancedFilter' ? <IcoFilterClose title={defaultText.pesquisar.advancedFilter} /> : <IcoFilter title={defaultText.pesquisar.advancedFilter} />}
                            color="primary"
                            type='btn'
                        />
                    <Button
                        // hasAccess={getPagePermissions(permissions, "cpes", "create")}
                        id="btnBuscarNaoProv"
                        titulo={defaultText.btn.buscarNaoProv}
                        action={() => handleBuscarNaoProvisionadas()}
                        texto={defaultText.btn.buscarNaoProv}
                        color="primary"
                        type='btn'
                    />
                    </div>
                </div>
                <div className="col-md-2 div-new-button">
                    <Button
                        hasAccess={getPagePermissions(permissions, "cpes", "create")}
                        id="btnAdicionar"
                        titulo={defaultText.btn.adicionarCpe}
                        action={() => dispatch(abrirModalCriarCpe())}
                        texto={defaultText.btn.adicionarCpe}
                        children={<IcoMais className="btn-border" title={defaultText.btn.adicionarCpe} />}
                        color="success"
                        type="btn"
                    />
                </div>
            </Row>
            {getModalAdvancedFilterOpen(modalAdvancedFilter)}
            {cpe.isSearch === true && JSON.stringify(pesquisaItens) !== '{}' ?
                <p className='qntRegisters'>
                    <span className='boldText'>
                        {cpe.paginacao.totalElements}
                    </span> {defaultText.itens}
                    <span className='boldText'>{Object.values(pesquisaItens).join(", ")}</span>
                </p> : null}
            {cpe.isSearch === true && JSON.stringify(pesquisaItens) !== '{}' ? <div className='divisorLine'></div> : null}
        </div >
    );
};