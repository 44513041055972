import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from '../../../components/row/row';
import InputInbox from '../../../components/form/inputInBox/InputInbox';
import { atualizarConteudoCpeModal, atualizarCpe } from '../redux/CpeActions';
import { validarCampoAction } from '../../../validation/validationActions';
import './informacoes.css';
import Input from '../../../layouts/components/Input/Input';
import { getPagePermissions } from '../../../utils/utils';
import { TextareaInbox } from '../../../components/form';
import Button from '../../../components/button/Button';
import { IcoEditar } from '../../../components/icones';
import { CorrectIcon } from '../../../layouts/components/icons';
import curriedTint from 'polished/lib/color/tint';
import { MaskItemDescricaoCpe } from '../../../components/mask/Mask';

const InformacoesConteudo = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const cpe = useSelector((state) => state.cpesState.cpe);
    const cpes = useSelector((state) => state.cpesState.cpes);
    const mensagemErro = useSelector(state => state.validacaoState);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const [editMode, setEditMode] = useState({
        cto: false,
        descricao: false
    });

    const handleEditMode = (e) => {
        setEditMode({
            ...editMode,
            [e]: !editMode[e]
        });
    };

    return (
        <section>
            <Row>
                <div className='col-md-12 list-topic'>
                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.cpeFirmware}:</div>
                        <div className='list-topic-item'>{cpe.firmwareVersao == '' ? '---' : cpe.firmwareVersao}</div>
                    </div>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.modelo}:</div>
                        <div className='list-topic-item'>{cpe.modelo == '' ? '---' : cpe.modelo}</div>
                    </div>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.identificador}:</div>
                        <div className='list-topic-item'>{cpe.identificador == '' ? '---' : cpe.identificador}</div>
                    </div>
                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.slot}:</div>
                        <div className='list-topic-item'>{cpe.slot == '' ? '---' : cpe.slot}</div>
                    </div>

                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.porta}:</div>
                        <div className='list-topic-item'>{cpe.porta == '' ? '---' : cpe.porta}</div>
                    </div>
                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.status}:</div>
                        <div className='list-topic-item'>{cpe.status == '' ? '---' : cpe.status}</div>
                    </div>
                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.oltVinculada}:</div>
                        <div className='list-topic-item'>{cpe.nomeOlt == '' ? '---' : cpe.nomeOlt}</div>
                    </div>
                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.cpeSoftware}:</div>
                        <div className='list-topic-item'>{cpe.softwareVersao == '' ? '---' : cpe.softwareVersao}</div>
                    </div>
                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.rxPotencia}:</div>
                        <div className='list-topic-item'>{cpe.rx == '' ? '---' : cpe.rx}</div>
                    </div>
                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.txPotencia}:</div>
                        <div className='list-topic-item'>{cpe.tx == '' ? '---' : cpe.tx}</div>
                    </div>
                    <div className='list-item'>
                        <div className='list-topic-item'>{defaltText.inputs.cto}:</div>
                        <div className='list-topic-item'>
                            <Input
                                id="inputCTO"
                                name='ctos'
                                label={defaltText.inputs.cto}
                                value={cpe.ctos == '' ? '' : cpe.ctos}
                                changeInput={e => dispatch(atualizarConteudoCpeModal(e))}
                                disabled={getPagePermissions(permissions, "olts", "update") && editMode.cto ? false : true}
                                length={{
                                    max: '30'
                                }}
                            />
                            {
                                !editMode.cto ?
                                    <Button
                                        id="defaultBtnEditar"
                                        titulo={defaltText.btn.editar}
                                        action={() => handleEditMode('cto')}
                                        children={<IcoEditar title={defaltText.btn.editar} />}
                                        color='modal-close'
                                        type='btn circle'
                                        disabled={getPagePermissions(permissions, "olts", "update") ? false : true}
                                    />
                                    :
                                    <Button
                                        id="defaultBtnEditar"
                                        titulo={defaltText.btn.salvar}
                                        action={() => [handleEditMode('cto'), dispatch(atualizarCpe(cpe))]}
                                        children={<CorrectIcon title={defaltText.btn.salvar} />}
                                        color='modal-close'
                                        type='btn circle'
                                        disabled={getPagePermissions(permissions, "olts", "update") ? false : true}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </Row>
            <div className="section-divisor"></div>
            <Row>
                <div className='col-md-12 descricao'>
                    <TextareaInbox
                        /* reference={assinanteInputsRefs[11]} */
                        id="cpeDescricao"
                        name='descricao'
                        label={defaltText.inputs.descricao}
                        value={cpe.descricao == '' ? '' : cpe.descricao}
                        changeInput={e => dispatch(atualizarConteudoCpeModal(e))}
                        length={{
                            max: '128'
                        }}
                        disabled={getPagePermissions(permissions, "olts", "update") && editMode.descricao ? false : true}
                    />
                    {
                        !editMode.descricao ?
                            <Button
                                id="defaultBtnEditar"
                                titulo={defaltText.btn.editar}
                                action={() => handleEditMode('descricao')}
                                children={<IcoEditar title={defaltText.btn.editar} />}
                                color='modal-close'
                                type='btn circle'
                                disabled={getPagePermissions(permissions, "olts", "update") ? false : true}
                            />
                            :
                            <Button
                                id="defaultBtnEditar"
                                titulo={defaltText.btn.salvar}
                                action={() => [handleEditMode('descricao'), dispatch(atualizarCpe(cpe))]}
                                children={<CorrectIcon title={defaltText.btn.salvar} />}
                                color='modal-close'
                                type='btn circle'
                                disabled={getPagePermissions(permissions, "olts", "update") ? false : true}
                            />
                    }
                </div>
            </Row>
        </section >
    );
};

export default InformacoesConteudo;
