import React from 'react';

export function IconBell(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28.69"
            height="28.7"
            viewBox="0 -3 32.69 35.7"
        >
            <title>{props.title ? props.title : null}</title>
            <g id="Icon_ionic-ios-notifications" data-name="Icon ionic-ios-notifications" transform="matrix(0.883, 0.469, -0.469, 0.883, 6.706, -6.644)" >
                <path id="Caminho_116" data-name="Caminho 116" d="M17.993,32.063c2.187,0,3.382-1.547,3.382-3.727H14.6C14.6,30.516,15.8,32.063,17.993,32.063Z" />
                <path id="Caminho_117" data-name="Caminho 117" d="M28.969,24.764c-1.083-1.427-3.213-2.264-3.213-8.655,0-6.56-2.9-9.2-5.6-9.83-.253-.063-.436-.148-.436-.415v-.2a1.716,1.716,0,1,0-3.431,0v.2c0,.26-.183.352-.436.415-2.707.64-5.6,3.27-5.6,9.83,0,6.391-2.13,7.221-3.213,8.655A1.4,1.4,0,0,0,8.163,27H27.858A1.4,1.4,0,0,0,28.969,24.764Z" />
            </g>
        </svg>

    );
}