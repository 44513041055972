import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BasicModal from '../../../../components/modal/BasicModal';
import { dataTimeZoneZero } from '../../../../utils/utils';
import { fecharModalSFPInfo } from '../../redux/OltActions';
import ConteudoSFPModal from './conteudoModalSFP';

const ExibirModalSFP = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../../nls/${locales}.json`);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const selectedInterface = useSelector((state) => state.oltsState.selectedInterface);
    const olt = useSelector((state) => state.oltsState.olt);
    const sfpInfo = useSelector((state) => state.oltsState.sfpInfo);

    //condição para mostrar ultima atualização, caso tenha informação da SFP
    const modalTitle = sfpInfo && sfpInfo.ultimaModificacao && sfpInfo.portState !== null ? (<><p>
        {defaltText.tituloModalSFPInfo + " : " + olt.nome + ": " + selectedInterface.nomeInterface}</p><p> {defaltText.inputs.ultimaModificacao + ": " + dataTimeZoneZero(sfpInfo.ultimaModificacao)}</p></>)
        : <p>{defaltText.tituloModalSFPInfo + " : " + olt.nome + ": " + selectedInterface.nomeInterface}</p>;



    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalSFPInfo())}
            containerModal={{
                title: modalTitle,
                size: 'medium',
                content: <ConteudoSFPModal />,
                closeText: defaltText.btn.fechar,
            }}
        />
    );
};

export default ExibirModalSFP;
