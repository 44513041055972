import React from 'react';

export function IcoArrowRight(props) {
  return (
    <svg id={props.id}
      xmlns="http://www.w3.org/2000/svg"
      width="6.944"
      height="10.754"
      viewBox="0 0 6.944 10.754"
      className={props.className}
    >
      <path id="Icon_awesome-angle-left" data-name="Icon awesome-angle-left" d="M1.97,11.552,6.539,6.983a.8.8,0,0,1,1.139,0l.759.759a.8.8,0,0,1,0,1.139L5.2,12.123l3.238,3.238a.8.8,0,0,1,0,1.139l-.759.763a.8.8,0,0,1-1.139,0L1.974,12.694a.8.8,0,0,1,0-1.142Z" transform="translate(8.678 17.5) rotate(180)" />
    </svg >

  );
}
