import React ,{ useEffect }from "react";
import { useSelector, useDispatch} from "react-redux";
import Row from "../../../components/row/row";
import _ from 'lodash';
import {listarOlt} from '../../olt/redux/OltActions';
import {listarCpe} from '../../cpe/redux/CpeActions';

const InformacoesConteudo = () => {
  const dispatch = useDispatch();
  const locales = useSelector(state => state.layoutState.linguagem);
  const defaltText = require(`../nls/${locales}.json`);
  const servico = useSelector(state => state.servicosState.servico);
  const olt = useSelector((state) => state.oltsState.olt);
  const cpe = useSelector(state => state.cpesState.cpe);
  useEffect(()=>{
    dispatch(listarOlt(servico.idOlt));
    dispatch(listarCpe(servico.idCpe));
  },[])
  const getVlans = (servico) => {
    
    return servico?.vlans?.length > 0 ? servico.vlans.join(", ") : "--";
  };

  const getPlano = (servico) => {
    
    return servico?.modePlanos?.length > 0 ? servico.modePlanos.join(", ") : "Customizado";
  };

  const getConfiguracoes = (servico) => {
    let configs = [];
    servico && servico.plano && servico.plano.listaConfiguracoes.map((config) => {
      if (!configs.includes(config.tipoServico)) {
        configs.push(config.tipoServico);
      }
    });
    return configs.length > 0 ? configs.join(", ") : "--";
  };

  return (
    <section className="box-servicos-informacoes">
      <Row>
        <div className='col-md-12 list-topic'>
          <div className='list-item'>
            <div className='list-topic-item'>{defaltText.inputs.nome}:</div>
            <div className='list-topic-item'>{_.get(servico, "nome")}</div>
          </div>
          <div className='list-item'>
            <div className='list-topic-item'>{defaltText.inputs.plano}:</div>
            <div className='list-topic-item'>{getPlano(servico)|| defaltText.inputs.planoCustomizado}</div>
          </div>
          <div className='list-item'>
            <div className='list-topic-item'>{defaltText.inputs.vlans}:</div>
            <div className='list-topic-item'>{getVlans(servico)}</div>
          </div>
          <div className='list-item'>
            <div className='list-topic-item'>{defaltText.inputs.olt}:</div>
            <div className='list-topic-item'>{olt.nome || defaltText.inputs.planoCustomizado}</div>
          </div>
          <div className='list-item'>
            <div className='list-topic-item'>{defaltText.inputs.porta}:</div>
            <div className='list-topic-item'>{servico.porta || defaltText.inputs.planoCustomizado}</div>
          </div>
          <div className='list-item'>
            <div className='list-topic-item'>{defaltText.inputs.slot}:</div>
            <div className='list-topic-item'>{servico.slot || defaltText.inputs.planoCustomizado}</div>
          </div>
          <div className='list-item'>
            <div className='list-topic-item'>{defaltText.inputs.identificador}:</div>
            <div className='list-topic-item'>{_.get(cpe, "identificador") || defaltText.inputs.planoCustomizado}</div>
          </div>
          <div className='list-item'>
            <div className='list-topic-item'>{defaltText.inputs.servicos}:</div>
            <div className='list-topic-item'>{getConfiguracoes(servico)}</div>{/* criar função para obter valores */}
          </div>
        </div>
      </Row>
    </section>
  );
};

export default InformacoesConteudo;
