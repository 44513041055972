import React from 'react';
import "./cards.css";
import { IcoRemoveLine } from '../../../components/icones';
import CardButton from '../cardButton/CardButton';
import { useDispatch, useSelector } from 'react-redux';
import { dataTimeZoneZero, getPagePermissions, translateStatus } from '../../../utils/utils';
import CardHeaderButton from '../cardHeaderButton/CardHeaderButton';
import { AttentionIcon, CorrectIcon, InfoIcon, SyncIcon, WanLanIcon } from '../icons';
import { abrirModalDeletar, abrirModalInformacao } from '../../../modulos/notificacoes/redux/NotificacoesActions';

const NotificationCard = ({ markerColor = "ok", item, labels }) => {
    const novoItem = {...item, dataCriacao: dataTimeZoneZero(item.dataCriacao)}
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../../modulos/notificacoes/nls/${locales}.json`);
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const getNotificationCardHeaderButton = (val) => {
        if (val.status === 'OK') {
            return <CardHeaderButton icon={<CorrectIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
        }
        else {
            if (val.descricao.includes("Erro")) {
                return <CardHeaderButton disabled={getPagePermissions(permissions, "olts", "update") ? false : true} icon={<AttentionIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
            else {
                return <CardHeaderButton icon={<SyncIcon className="spin" title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
        }
    };

    return (
        <div className={`cartao ${markerColor}`}>
            <div className="cartao-cabecalho">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <p><span className="bold-text">{item[labels[0].field]}</span></p> */}
                    <p><span className="bold-text">
                        {novoItem[labels[2].field].toLowerCase().includes('erro') ? defaultText.erro + novoItem[labels[1].field] :  ''}
                    </span></p>
                    <CardHeaderButton markerColor={markerColor} icon={getNotificationCardHeaderButton(novoItem)} />
                </div>
                <div className="section-divisor"></div>
            </div>

            <div className="cartao-corpo">
                {labels.map((label, index) => index > 0 && 
                <p>{label.text}: <span className={`bold-text ${label.field === "status" && markerColor}`}> {novoItem[label.field] === true ? defaultText.inputs.sim : novoItem[label.field] === false ? defaultText.inputs.nao : novoItem[label.field]}</span></p>)}
            </div>

            <div className="cartao-rodape">
                {/* <CardButton
                    id="btnInfo"
                    label={defaultText.btn.informacoes}
                    action={() => dispatch(abrirModalInformacao(item))}
                    icon={<InfoIcon />}
                /> */}

                {item.descricao.includes("Erro") ? (
                    <>
                        {/* <div className="vertical-section-divisor"></div> */}

                        <CardButton
                            hasAccess={getPagePermissions(permissions, "notificacoes", "delete")}
                            id="btnRemover"
                            label={defaultText.btn.deletar}
                            action={() => dispatch(abrirModalDeletar(novoItem))}
                            icon={<IcoRemoveLine />}
                        />
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default NotificationCard;