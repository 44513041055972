import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import { fecharModalServico, reenviarServico, reiniciarServico } from '../redux/ServicoActions';

const Erro = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const servico = useSelector(state => state.servicosState.servico);
    const statusModal = useSelector(state => state.servicosState.statusModal);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalServico())}
            containerModal={{
                title: `Erro - ${servico.nome}`,
                size: 'medium',
                content: (
                    <>
                        {/* <p>{servico && servico.descricaoErro ? servico.descricaoErro : defaltText.mensagem.adicionarErro}</p> */}
                        <p>{defaltText.mensagem.adicionarErroModal}</p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    acao: () => dispatch(reenviarServico(servico)),
                    texto: defaltText.btn.tentarNovamente,
                    class: "success"
                }
            }}
        />
    );
};

export default Erro;