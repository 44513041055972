import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from './barraDePesquisa';
import ListaPlanos from './ListaPlanos';
import Paginate from '../../components/pagination/Pagination';
import { handleIsLoadingPlansStatus, listarPlanos, resetarCamposPlano } from './redux/PlanosActions';
import { CardList } from '../../components/CardList/CardList';
/* import CardList from '../../layouts/components/cardList/CardList'; */
import { getPlanoCardHeaderButton } from '../../components/card/getCardButtons';
import TitleContainer from '../../layouts/components/TitleContainer/TitleContainer';
import SigCloudLoader from '../../layouts/components/Loader/SigCloudLoader';
import "./planos.css";
import PlanCard from '../../layouts/components/cards/PlanCard';
import Row from '../../components/row/row';
import ModalPlano from './criarEditar/modalPlano';
import RemoverServicoOuConfiguracaoModal from './remover/RemoverServicoOuConfiguracao';
import InfoPlano from './informacoes/informacoes';
import ModalPortasLan from './informacoes/portasLan';
import RemoverPlanoModal from './remover/RemoverPlanoModal';

const Planos = () => {
    const dispatch = useDispatch();
    const planos = useSelector((state) => state.planosState.planos);
    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);
    const paginacao = useSelector((state) => state.planosState.paginacao);
    const atualizarLista = (val) => {
        dispatch(listarPlanos({ pagina: val }));
    };
    const isLoading = useSelector((state) => state.planosState.isLoading);
    const statusModal = useSelector((state => state.planosState.statusModal));

    useEffect(() => {
        dispatch(listarPlanos());

        return () => {
            dispatch(resetarCamposPlano());
            dispatch(handleIsLoadingPlansStatus(false));
        };
    }, [dispatch]);

    const modalPlano = (e, i) => {
        switch (e[i]) {
            case 'criando':
                return [<ModalPlano />, modalPlano(e, i + 1)];
            case 'excluindo':
                return [<RemoverServicoOuConfiguracaoModal style={"z-index: 2"} />, modalPlano(e, i + 1)];
            case 'informacao':
                return [<InfoPlano />, modalPlano(e, i + 1)];
            case 'modalPortaLan':
                return [<ModalPortasLan />, modalPlano(e, i + 1)];
            case 'deletando':
                return [<RemoverPlanoModal />, modalPlano(e, i + 1)];
            default:
                return;
        }
    };

    //Daqui pra baixo código do card novo
    const labels = [{ field: "nome", text: defaltText.inputs.nome }, { field: "status", text: defaltText.inputs.status }, { field: "descricao", text: defaltText.inputs.descricao }];


    return (<>
        <SigCloudLoader visible={isLoading} />
        <div className="box-plano">
            <TitleContainer
                label={defaltText.title}
            />
            <div>
                <SearchBar />
            </div>
            {/* <CardList
                getHeaderButton={(val) => getPlanoCardHeaderButton(val)}
                data={planos}
                labels={labels}
                headerTitle="nome"
            /> */}

            <Row>
                {planos.map((item) => (
                    <div className="col-md-3 col-sm-6">
                        <PlanCard
                            item={item}
                            labels={labels}
                            markerColor={
                                item.status === "OK" ? "ok" :
                                    item.status === "ERRO" ? "error" :
                                        item.status.toUpperCase() === "DELETANDO" ? "delete" : "updating"}
                        />
                    </div>
                ))}
            </Row>
            {paginacao && <Paginate data={paginacao} action={(val) => atualizarLista(val)} />}
        </div>

        {modalPlano(statusModal, 0)}

    </>
    );
};

export default Planos;
