import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validarCampoAction } from '../../../../validation/validationActions';
import { fecharModalAttention, fecharModalSelLoc } from '../redux/UserActions';
import {
    criarLoc,
    limparCamposNovaLoc,
    atualizarCamposNovaLoc,
    limparCampoSelect
} from '../../../locais/redux/LocaisActions';
import { TextareaInbox } from '../../../../components/form//textareaInBox/TextareaInbox';
import Button from '../../../../components/button/Button';
import Row from '../../../../components/row/row';
import '../../usuario.css';
import InputInbox from '../../../../components/form/inputInBox/InputInbox';

const ConteudoSelLocModal = ({ preferencia, inputs }) => {
    const dispatch = useDispatch();

    const locaisState = useSelector(state => state.locaisState);
    const selecionado = useSelector(state => state.locaisState.local);
    const credenciais = inputs;
    const mensagemErro = useSelector(state => state.validacaoState);
    const defaltText = preferencia.linguagem
        ? require(`../../nls/${preferencia.linguagem}.json`)
        : require(`../../nls/pt-BR.json`);

    useEffect(() => {
        dispatch(limparCamposNovaLoc());
        dispatch(limparCampoSelect());
    }, [dispatch]);

    return (
        <div>
            <div className='createLocationText'>
                <p>
                    {defaltText.mensagem.infoNewLoc}
                    <span className="hintAdapter">
                        {defaltText.mensagem.hintAdapter}
                        <span className="tooltip">{defaltText.mensagem.tooltip}</span>
                    </span>
                    Ex: CPD de Florianópolis
                </p>
            </div>
            <div className='col-md-14 scroll'>
                <InputInbox
                    name='nomeLocal'
                    label={defaltText.localizacao.nome}
                    value={locaisState.novaLoc.nomeLocal}
                    changeInput={e => dispatch(atualizarCamposNovaLoc(e))}
                    validate={{
                        action: e => dispatch(validarCampoAction(e)),
                        erro: mensagemErro.nomeLocal ? defaltText.mensagem.obrigatorio : ''
                    }}
                    length={{
                        max: '30'
                    }}
                />

                <TextareaInbox
                    name='descricaoLocal'
                    label={defaltText.localizacao.descricao}
                    value={locaisState.novaLoc.descricaoLocal}
                    changeInput={e => dispatch(atualizarCamposNovaLoc(e))}
                    length={{
                        max: '50'
                    }}
                />
            </div>

            <div className='col-md-12 botoes'>
                <Button
                    value='campoNovaLoc'
                    id='btnCancelar'
                    titulo={defaltText.localizacao.cancelar}
                    action={() => dispatch(fecharModalSelLoc())}
                    children={defaltText.localizacao.cancelar}
                    color='secondary'
                    size='medium'
                    variant='modal'
                    type='btn'
                />
                <Button
                    value='campoCriar'
                    id='btnCriar'
                    titulo={defaltText.localizacao.criar}
                    action={() => { dispatch(criarLoc({ ...credenciais, aplicacao: { ...locaisState.novaLoc } })), localStorage.setItem('firstLogin', JSON.stringify(true)); }}
                    children={defaltText.localizacao.criar}
                    color='success'
                    size='medium'
                    variant='modal'
                    type='btn'
                />
            </div>
        </div >
    );
};

export default ConteudoSelLocModal;
