import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IcoIntelbras, SigCloudLogo } from '../../../../components/icones';
import { InputInboxSenha, SelectInBox } from '../../../../components/form';
import Row from '../../../../components/row/row';
import Button from '../../../../components/button/Button';

import { ajustIdioma, listarIdiomas } from '../../../../layouts/redux/LayoutActions';
import { enviarAlteracaoSenha, reconhecerHash } from '../../login/redux/UserActions';
import { resetarCamposAction, validarCampoAction } from '../../../../validation/validationActions';


import '../../usuario.css';
import '../esqueciSenha.css';
import CustomSelect from '../../../../layouts/components/Select/Select';
import ThemeSelector from '../../../../components/ThemeSelector/ThemeSelector';
import Input from '../../../../layouts/components/Input/Input';
import InputPassword from '../../../../layouts/components/Input/InputPassword';
import { InfoIcon, LockIcon } from '../../../../layouts/components/icons';
function temaCustom(theme) {
    return 'temaCinzaEscuro';
}
const RedefinirSenha = ({ history }) => {
    const dispatch = useDispatch();

    const preferencias = localStorage.theme ? JSON.parse(localStorage.theme) : [];
    const [preferencia, setPreferencia] = useState(preferencias);
    const listarPreferencias = useSelector(state => state.layoutState);
    const hashValidado = useSelector(state => state.authentication.hashValidado);
    const mensagemErro = useSelector(state => state.validacaoState);
    const defaltText = preferencia.linguagem
        ? require(`../../nls/${preferencia.linguagem}.json`)
        : require(`../../nls/pt-BR.json`);
    const tema = useSelector(state => state.layoutState.tema);
    require(`../../../../layouts/temas/${temaCustom(tema)}.css`);
    const hash = window.location.pathname.split('/')[3];
    const [inputs, setInputs] = useState({
        senha: '',
        confirmarSenha: '',
        hashUsuario: hash
    });

    const [recoverRefs, setRecoverRefs] = useState([
        useRef(),
        useRef(),
        useRef()
    ]);

    const handleChange = e => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    };
    const handleChangePreferencia = e => {
        preferencias[e.target.name] = e.target.value.use;
        setPreferencia({
            ...preferencia,
            [e.target.name]: e.target.value.use
        });
        localStorage.setItem('theme', JSON.stringify(preferencias));
    };

    const checkInputs = () => {
        return ((inputs.senha !== "") && inputs.senha === inputs.confirmarSenha && inputs.hashUsuario !== "");
    };

    const handlePressEnter = (e) => {
        if (e.key === 'Enter' && checkInputs(inputs)) {
            dispatch(enviarAlteracaoSenha(inputs));
        }
    };

    const handleOnKeyDown = (event, activeReference, references) => {
        if (event.nativeEvent.keyCode === 9) {
            var indexOfActiveElement = references.indexOf(activeReference);
            if ((indexOfActiveElement + 1) <= (references.length - 1)) {
                event.nativeEvent.preventDefault();
                references[indexOfActiveElement + 1].current.focus();
                console.log(references[indexOfActiveElement + 1]);
            }
        }
    };

    useEffect(() => {
        dispatch(resetarCamposAction());
        dispatch(listarIdiomas());
        dispatch(reconhecerHash(hash));
    }, [dispatch]);

    const [passwordStrength, setPasswordStrength] = useState({
        className: null,
        text: null
    });


    const forcaSenha = (senha) => {

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

        if (strongRegex.test(senha)) {
            return setPasswordStrength({ className: 'strongPassword', text: `${defaltText.inputs.strongPassword}` });
        } else if (mediumRegex.test(senha)) {
            return setPasswordStrength({ className: 'mediumPassword', text: `${defaltText.inputs.mediumPassword}` });
        } else {
            return setPasswordStrength({ className: 'weakPassword', text: `${defaltText.inputs.weekPassword}` });
        }
    };

    return (
        <div id="login-bg" className="auth-recover-container">
            <div className="content first-content">

                <div className="content-header">
                    <div className="logo">
                        <IcoIntelbras style={{ width: "165", height: "35" }} />
                    </div>

                    <div className="configs">
                        <div className="language-config">
                            <SelectInBox
                                id='linguagem'
                                /* label={defaltText.linguagem} */
                                selectDefault={{
                                    id: 'id',
                                    label: 'nome',
                                    minWidth: '100%',
                                }}
                                select={{
                                    action: (e) => handleChangePreferencia(e),
                                    options: listarPreferencias.linguagens,
                                    selected: ajustIdioma(preferencia.linguagem),
                                }}
                            />
                        </div>
                        <div className="theme-config">
                            <ThemeSelector
                                label={defaltText.inputs.tema}
                            />
                        </div>
                    </div>
                </div>

                <div className="second-column">
                    {hashValidado === "hashValidada" && (
                        <div className="form">
                            <SigCloudLogo />
                            <h5>{defaltText.titleEsqueciSenha}</h5>

                            <InputPassword
                                id="recoverPassword"
                                reference={recoverRefs[0]}
                                fieldIcon={{
                                    icon: <LockIcon />
                                }}
                                name='senha'
                                label={defaltText.inputs.senha}
                                value={inputs.senha}
                                changeInput={e => [forcaSenha(e.target.value), handleChange(e)]}
                                onPressEnter={e => handlePressEnter(e)}
                                validate={{
                                    action: e => dispatch(validarCampoAction(e)),
                                    erro: mensagemErro.senha && (mensagemErro.senha === "invalid" ? "" : defaultText.mensagem.obrigatorio)
                                }}
                                seeText={defaltText.btn.ver}
                                length={{
                                    max: '20'
                                }}
                                onKeyDown={(e) => handleOnKeyDown(e, recoverRefs[0], recoverRefs)}
                            />

                            <div className={`passwordValidation ${inputs.senha === '' ? 'hidePassValidation' : ''}`}>
                                <p className={`${passwordStrength.className}`}>
                                    {passwordStrength.text}
                                </p>
                                {passwordStrength.className === 'weakPassword' ?
                                    <div className='infoPassword'>
                                        <InfoIcon />
                                        <span className="tooltip">{defaltText.mensagem.tooltipPassword}</span>
                                    </div>
                                    : ''}
                            </div>

                            <InputPassword
                                id="recoverPasswordConfirm"
                                reference={recoverRefs[1]}
                                fieldIcon={{
                                    icon: <LockIcon />
                                }}
                                name='confirmarSenha'
                                label={defaltText.inputs.confirmarSenha}
                                value={inputs.confirmarSenha}
                                changeInput={e => handleChange(e)}
                                onPressEnter={e => handlePressEnter(e)}
                                validate={{
                                    action: e => dispatch(validarCampoAction(e)),
                                    erro: mensagemErro && mensagemErro.confirmarSenha ? defaltText.mensagem.obrigatorio : ''
                                }}
                                seeText={defaltText.btn.ver}
                                length={{
                                    max: '20'
                                }}
                                onKeyDown={(e) => handleOnKeyDown(e, recoverRefs[1], recoverRefs)}
                            />

                            <button ref={recoverRefs[2]} onClick={() => dispatch(enviarAlteracaoSenha(inputs))} className="btn">{defaltText.btn.enviar}</button>

                            <div>
                                <p className="texto-fora">{defaltText.temLoginSenha}
                                    <span onClick={() => history.push('/login')} className='link-logar bold-text'>
                                        {defaltText.entreAqui}
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}

                    {hashValidado === "hashNaoReconhecido" && (
                        <div className="form">
                            <h3>Oooops...</h3>
                            <div className="texto-fora">
                                <p>{defaltText.linkInvalido}</p>
                            </div>

                            <div>
                                <p className="texto-fora">{defaltText.temLoginSenha}
                                    <span onClick={() => history.push('/login')} className='link-logar bold-text'>
                                        {defaltText.entreAqui}
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {/* <div className='box-idioma'>
                    <SelectInBox
                        id='linguagem'
                        label={defaltText.linguagem}
                        selectDefault={{
                            id: 'id',
                            label: 'nome'
                        }}
                        select={{
                            action: e => handleChangePreferencia(e),
                            options: listarPreferencias.linguagens,
                            selected: ajustIdioma(preferencia.linguagem)
                        }}
                    />
                </div>
                <div>
                    {hashValidado === "hashValidada" &&
                        <div>
                            <h3>{defaltText.titleEsqueciSenha}</h3>
                            <Row>
                                <div className='col-md-12'>
                                    <InputInboxSenha
                                        name='senha'
                                        label={defaltText.inputs.senha}
                                        value={inputs.senha}
                                        changeInput={e => handleChange(e)}
                                        onPressEnter={e => handlePressEnter(e)}
                                        validate={{
                                            action: e => dispatch(validarCampoAction(e)),
                                            erro: mensagemErro && mensagemErro.senha ? defaltText.mensagem.obrigatorio : ''
                                        }}
                                        seeText={defaltText.btn.ver}
                                        length={{
                                            max: '20'
                                        }}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className='col-md-12'>
                                    <InputInboxSenha
                                        name='confirmarSenha'
                                        label={defaltText.inputs.confirmarSenha}
                                        value={inputs.confirmarSenha}
                                        changeInput={e => handleChange(e)}
                                        onPressEnter={e => handlePressEnter(e)}
                                        validate={{
                                            action: e => dispatch(validarCampoAction(e)),
                                            erro: mensagemErro && mensagemErro.senha ? defaltText.mensagem.obrigatorio : ''
                                        }}
                                        seeText={defaltText.btn.ver}
                                        length={{
                                            max: '20'
                                        }}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className='col-md-12 borda'>
                                    <Button
                                        disabled={((inputs.senha === inputs.confirmarSenha) && inputs.senha !== "") ? false : true}
                                        id='btnEnviar'
                                        titulo={defaltText.btn.enviar}
                                        //NOVO
                                        action={() => dispatch(enviarAlteracaoSenha(inputs))}
                                        color='primary'
                                        texto={defaltText.btn.enviar}
                                    />
                                </div>
                            </Row>
                        </div>
                    }
                    {hashValidado === "hashNaoReconhecido" ?
                        <div>
                            <h3>Oooops...</h3>
                            <div className="texto-fora">{defaltText.linkInvalido}</div>
                        </div> : <></>}

                </div> */}
            </div>
        </div>
    );
};
export default RedefinirSenha;