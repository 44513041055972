import React from 'react';

export function IcoRemoveLine(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="34.425" height="36.772" viewBox="7 4 21.425 22.772">
            <title>{props.title ? props.title : null}</title>
            <defs>
                <filter id="Icon_awesome-trash-alt" x="0" y="0" width="34.425" height="36.772" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Icon_awesome-trash-alt)">
                <path id="Icon_awesome-trash-alt-2" data-name="Icon awesome-trash-alt" d="M1.173,17.012a1.76,1.76,0,0,0,1.76,1.76H13.492a1.76,1.76,0,0,0,1.76-1.76V4.693H1.173Zm9.972-9.386a.587.587,0,0,1,1.173,0v8.213a.587.587,0,0,1-1.173,0Zm-3.52,0a.587.587,0,0,1,1.173,0v8.213a.587.587,0,1,1-1.173,0Zm-3.52,0a.587.587,0,0,1,1.173,0v8.213a.587.587,0,1,1-1.173,0ZM15.838,1.173h-4.4L11.094.488A.88.88,0,0,0,10.306,0H6.115a.87.87,0,0,0-.785.488l-.345.686H.587A.587.587,0,0,0,0,1.76V2.933a.587.587,0,0,0,.587.587H15.838a.587.587,0,0,0,.587-.587V1.76A.587.587,0,0,0,15.838,1.173Z" transform="translate(9 6)" />
            </g>
        </svg>
    );
}
