import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/button/Button";
import Row from "../../../components/row/row";
import Table from "../../../layouts/components/Table/Table";
import { abrirModalPortasLan } from "../redux/PlanosActions";

const InfoPlanosConteudo = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);

    const plano = useSelector((state) => state.planosState.plano);

    const labels = [
        { text: defaultText.inputs.tipoServico, field: "tipoServico" },
        { text: defaultText.inputs.indexVlan, field: "indexVlan" },
        //{ text: defaultText.inputs.pppoeUsuario, field: "pppoeUsuario" },
        { text: defaultText.inputs.mapaPortasLan, field: "actions" }
    ];

    const listActions = (val) => {
        switch (val.item) {
            case 'actions':
                return (
                    <>
                        <Button
                            id="abrirModalPortasLan"
                            titulo={defaultText.btn.portasLan}
                            action={() => dispatch(abrirModalPortasLan(val.itens))}
                            children={defaultText.btn.portasLan}
                            color="secondary"
                            type="btn"
                            disabled={val.itens.tipoServico.toUpperCase().includes("PPPOE") ? true : false}
                        />
                    </>
                );
            default:
                return;
        }
    };

    return (
        <section>
            <div className='col-md-12'>
                <Table
                    itens={plano.listaConfiguracoes}
                    labels={labels}
                    listActions={(val) => listActions(val)}
                />
            </div>
        </section>
    );
};

export default InfoPlanosConteudo;