import React, { useEffect } from 'react';
import { listarCpe } from '../redux/CpeActions';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/button/Button';
import ListaConteudoServico from './listaServicosCpe';
import history from '../../../history';
import Row from '../../../components/row/row';
import './servicosCpe.css';
import SigCloudLoader from '../../../layouts/components/Loader/SigCloudLoader';

const ServicosCpes = () => {
    const dispatch = useDispatch();

    const locales = useSelector((state) => state.layoutState.linguagem);

    const cpe = useSelector((state) => state.cpesState.cpe);
    const defaltText = require(`./../nls/${locales}.json`);
    const isLoading = useSelector((state) => state.cpesState.isLoading);

    useEffect(() => {
        const urlId = window.location.pathname.split('/');
        const idCpe = urlId[3] ? urlId[3] : '';
        dispatch(listarCpe(idCpe));
    }, [dispatch]);

    return (<>
        <SigCloudLoader visible={isLoading} />
        <div className="box-servicos-cpe">
            <h2>{defaltText.titleServicos} </h2>
            <div className='box-conteudo'>
                <Row>
                    <div className="col-md-2">
                        <label htmlFor="*">{defaltText.inputs.identificador}</label>
                        <p>{cpe.identificador}</p>
                    </div>

                    <div className="section-divisor"></div>

                    <div className="col-md-2">
                        <label htmlFor="*">{defaltText.inputs.modelo}</label>
                        <p>{cpe.modelo}</p>
                    </div>

                    <div className="section-divisor"></div>

                    <div className="col-md-2">
                        <label htmlFor="*">{defaltText.inputs.oltVinculada}</label>
                        <p>{cpe.nomeOlt ? cpe.nomeOlt : "---"}</p>
                    </div>

                    <div className="section-divisor"></div>

                    <div className="col-md-2">
                        <label htmlFor="*">{defaltText.inputs.estado}</label>
                        <p>{cpe.status}</p>
                    </div>
                </Row>
            </div>
            <ListaConteudoServico />
            <div>
                <Row>
                    <div className="col-md-12">
                        <div className="flex-end">
                            <Button
                                titulo={defaltText.btn.voltar}
                                action={() => history.push(`/cpes`)}
                                color="secondary"
                                children={defaltText.btn.voltar}
                                size="medium"
                            />
                        </div>
                    </div>
                </Row>
            </div>
        </div>
    </>
    );
};

export default ServicosCpes;
