import React from 'react';
import { useDispatch } from 'react-redux';
import history from '../../history';
import { abrirModalDeletarAssinante, abrirModalEditarAssinante } from '../../modulos/assinantes/redux/AssinanteActions';
import { abrirModalDeletarCpe, abrirModalGerenciarCpe, abrirModalInformacaoCpe } from '../../modulos/cpe/redux/CpeActions';
import { atualizaSelect } from '../../modulos/locais/redux/LocaisActions';
import { abrirModalDeletar, abrirModalInformacao } from '../../modulos/notificacoes/redux/NotificacoesActions';
import { abrirModalDeletarOlt, abrirModalEditarOlt, abrirModalErroOlt } from '../../modulos/olt/redux/OltActions';
import { abrirModalDeletarServico, abrirModalEditarServico, abrirModalErroServico } from '../../modulos/servicos/redux/ServicoActions';
import Button from '../button/Button';
import Checkbox from '../form/checkbox/Checkbox';
import { IcoAttention, IcoChamadas, IcoCorrect, IcoEditar, IcoGear, IcoGlobal, IcoInfo, IcoRemoveLine, IcoSync, IcoWanLan } from '../icones';
import { getPagePermissions } from '../../utils/utils';

// Botões do header do card
export const getAssinanteCardHeaderButton = (val) => {
    const dispatch = useDispatch();
    if (val.status === 'OK') {
        return <Button type="btn circle" children={<IcoCorrect title={val.status} />} />;
    }
    else {
        if (val.status === "ERRO") {
            return <Button type="btn circle" color="success" children={<IcoAttention title={val.status} />} />;
            //return getOltCardActions({ content, buttonId: "btnStatus" });
        }
        else {
            return <Button type="btn circle" children={<IcoSync className="spin" title={val.status} />} />;
        }
    }
};

export const getOltCardHeaderButton = (val) => {
    const dispatch = useDispatch();
    if (val.status === 'OK') {
        return <Button type="btn circle" children={<IcoCorrect title={val.status} />} />;
    }
    else {
        if (val.status.includes("ERRO")) {
            return <Button type="btn circle" color="success" children={<IcoAttention markerColor="red" title={val.status} />} action={() => dispatch(abrirModalErroOlt(val))} />;
            //return getOltCardActions({ content, buttonId: "btnStatus" });
        }
        else {
            return <Button type="btn circle" children={<IcoSync className="spin" title={val.status} />} />;
        }
    }
};

export const getCpeCardHeaderButton = (val) => {
    const dispatch = useDispatch();
    if (val.status === 'OK') {
        return <Button type="btn circle" children={<IcoCorrect title={val.status} />} />;
    }
    else {
        if (val.status === "ERRO") {
            return <Button type="btn circle" color="success" children={<IcoAttention title={val.status} />} />;
            //return getOltCardActions({ content, buttonId: "btnStatus" });
        }
        else {
            return <Button type="btn circle" children={<IcoSync className="spin" title={val.status} />} />;
        }
    }
};

export const getServicoCardHeaderButton = (val) => {
    const dispatch = useDispatch();
    if (val.status === 'OK') {
        return <Button type="btn circle" children={<IcoCorrect title={val.status} />} />;
    }
    else {
        if (val.status === "ERRO") {
            return <Button type="btn circle" color="success" children={<IcoAttention title={val.status} />} action={() => dispatch(abrirModalErroServico(val))} />;
            //return getOltCardActions({ content, buttonId: "btnStatus" });
        }
        else {
            return <Button type="btn circle" children={<IcoSync className="spin" title={val.status} />} />;
        }
    }
};

export const getPlanoCardHeaderButton = (val) => {
    const dispatch = useDispatch();
    if (val.status === 'OK') {
        return <Button type="btn circle" children={<IcoCorrect title={val.status} />} />;
    }
    else {
        if (val.status === "ERRO") {
            return <Button type="btn circle" color="success" children={<IcoAttention title={val.status} />} />;
            //return getOltCardActions({ content, buttonId: "btnStatus" });
        }
        else {
            return <Button type="btn circle" children={<IcoSync className="spin" title={val.status} />} />;
        }
    }
};




// Botões do footer do card
export const getAssinanteCardActions = (val, defaultText, permissions) => {
    const dispatch = useDispatch();
    if (val.buttonId.toLowerCase().includes("servicos")) {
        return (
            <Button
                id="btnServicos"
                titulo={defaultText.btn.servicos}
                action={() => history.push(`/servicos/assinantes/${val.content.id}`)}
                color={val.content.planos ? 'secondary' : 'success'}
                type="btn circle"
                children={<IcoGlobal title={defaultText.btn.servicos} />}
                size="medium"
            />
        );
    }
    else {
        if (val.buttonId.toLowerCase().includes("editar")) {
            return (
                <Button
                    hasAccess={getPagePermissions(permissions, "assinantes", "update")}
                    id="btnEditar"
                    titulo={defaultText.btn.editar}
                    action={() => dispatch(abrirModalEditarAssinante(val.content))}
                    color="secondary"
                    type="btn circle"
                    children={<IcoEditar title={defaultText.btn.editar} />}
                />
            );
        }
        else {
            if (val.buttonId.toLowerCase().includes("remover")) {
                return (
                    <Button
                        hasAccess={getPagePermissions(permissions, "assinantes", "delete")}
                        id="btnRemover"
                        titulo={defaultText.btn.remover}
                        action={() => dispatch(abrirModalDeletarAssinante(val.content))}
                        color="success"
                        type="btn circle"
                        children={<IcoRemoveLine title={defaultText.btn.remover} />}
                    />
                );
            }
        }
    }
};

export const getOltCardActions = (val, defaultText, permissions) => {
    const dispatch = useDispatch();
    if (val.buttonId.toLowerCase().includes("vlans")) {
        return (
            <Button
                id="btnVLans"
                titulo={defaultText.btn.informacoes}
                action={() => history.push(`/vlans/olts/${val.content.id}`)}
                children={<IcoWanLan title={defaultText.btn.vlans} />}
                disabled={(val.content.status !== "OK") ? true : false}
                color="secondary"
                type="btn circle"
            />
        );
    }
    else {
        if (val.buttonId.toLowerCase().includes("info")) {
            return (
                <Button
                    id="btnInfo"
                    titulo={defaultText.btn.informacoes}
                    action={() => { history.push(`/informacoes/olts/${val.content.id}`); }}
                    children={<IcoInfo title={defaultText.btn.informacoes} />}
                    disabled={(val.content.status !== "OK") ? true : false}
                    color="secondary"
                    type="btn circle"
                />
            );
        }
        else {
            if (val.buttonId.toLowerCase().includes("editar")) {
                return (
                    <Button
                        hasAccess={getPagePermissions(permissions, "olts", "update")}
                        id="btnEditar"
                        titulo={defaultText.btn.editar}
                        action={() => dispatch(abrirModalEditarOlt(val.content))}
                        children={<IcoEditar title={defaultText.btn.editar} />}
                        disabled={(val.content.status === "CRIANDO" || val.content.status === "EDITANDO" || val.content.status === "REINICIANDO" || val.content.status === "RECONECTANDO") ? true : false}
                        color="secondary"
                        type="btn circle"
                    />
                );
            }
            else {
                if (val.buttonId.toLowerCase().includes("remover")) {
                    return (
                        <Button
                            hasAccess={getPagePermissions(permissions, "olts", "delete")}
                            id="btnRemover"
                            titulo={defaultText.btn.remover}
                            action={() => dispatch(abrirModalDeletarOlt(val.content))}
                            children={<IcoRemoveLine title={defaultText.btn.remover} />}
                            color="success"
                            disabled={(val.content.status === "CRIANDO" || val.content.status === "EDITANDO" || val.content.status === "REINICIANDO" || val.content.status === "RECONECTANDO") ? true : false}
                            type="btn circle"
                        />
                    );
                }
                else {
                    if (val.buttonId.toLowerCase().includes("status")) {
                        return (
                            <Button
                                id="btnStatus"
                                titulo={status}
                                type="btn circle"
                                color="success"
                                children={<IcoAttention title={status} />}
                                action={() => dispatch(abrirModalErroOlt(val.content))}
                            />
                        );
                    }
                }
            }
        }
    }
};

export const getCpeCardActions = (val, defaultText, permissions) => {
    const dispatch = useDispatch();
    if (val.buttonId.toLowerCase().includes("internet")) {
        return (
            <Button
                id="btnInternet"
                titulo={defaultText.btn.informacoes}
                action={() => dispatch(abrirModalInformacaoCpe(val.content))}
                children={<IcoInfo title={defaultText.btn.informacoes} />}
                color="secondary"
                type="btn circle"
            />
        );
    }
    else {
        if (val.buttonId.toLowerCase().includes("gerenciar")) {
            return (
                <Button
                    hasAccess={getPagePermissions(permissions, "cpes", "update")}
                    id="btnGerenciar"
                    titulo={defaultText.btn.gerenciar}
                    action={() => dispatch(abrirModalGerenciarCpe(val.content))}
                    children={<IcoGear title={defaultText.btn.gerenciar} />}
                    disabled={(val.content.status !== "OK" || val.content.status === "ERRO") ? true : false}
                    color="secondary"
                    type="btn circle"
                />
            );
        }
        else {
            if (val.buttonId.toLowerCase().includes("servicos")) {
                return (
                    <Button
                        id="btnServicos"
                        titulo={defaultText.btn.servicos}
                        action={() => history.push(`/servicos/cpes/${val.content.id}`)}
                        color={val.content.planos ? 'secondary' : 'success'}
                        type="btn circle"
                        children={<IcoGlobal title={defaultText.btn.servicos} />}
                        size="medium"
                    />
                );
            }
            else {
                if (val.buttonId.toLowerCase().includes("remover")) {
                    return (
                        <Button
                            hasAccess={getPagePermissions(permissions, "cpes", "delete")}
                            id="btnRemover"
                            titulo={defaultText.btn.remover}
                            action={() => dispatch(abrirModalDeletarCpe(val.content))}
                            children={<IcoRemoveLine title={defaultText.btn.remover} />}
                            color="success"
                            type="btn circle"
                            disabled={(val.content.status !== "ERRO" || val.content.status.toUpperCase() === "NAO_PROVISIONADA") ? true : false}
                        />
                    );
                }
            }
        }
    }
};

export const getLocalCardActions = (val, idSelecionado, permissions) => {
    const dispatch = useDispatch();
    if (val.buttonId.toLowerCase().includes("checkList".toLowerCase())) {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    changeInput={() => dispatch(atualizaSelect(val.content))}
                    checked={val.content.id === idSelecionado ? true : false}
                    value={val.content.id === idSelecionado ? true : false}
                    name={JSON.stringify(val.content)}
                />
            </div>
        );
    }
};

export const getNotificacaoCardActions = (val, defaultText, permissions) => {
    const dispatch = useDispatch();
    if (val.buttonId.toLowerCase().includes("info")) {
        return (
            <Button
                id="btnInfo"
                titulo={defaultText.btn.informacao}
                action={() => dispatch(abrirModalInformacao(val.content))}
                color='secondary'
                type='btn circle'
                children={<IcoInfo title={defaultText.btn.informacao} />}
                size='small'
            />
        );
    }
    else {
        if (val.buttonId.toLowerCase().includes("remover") && val.content.status === "ERRO") {
            return (
                <Button
                    hasAccess={getPagePermissions(permissions, "notificacoes", "delete")}
                    id="btnRemover"
                    titulo={defaultText.btn.deletar}
                    action={() => dispatch(abrirModalDeletar(val.content))}
                    children={<IcoRemoveLine title={defaultText.btn.remover} />}
                    color="success"
                    type="btn circle"
                />
            );
        }
    }
};

export const getServicoCardActions = (val, defaultText, permissions) => {
    const dispatch = useDispatch();
    if (val.buttonId.toLowerCase().includes("editar")) {
        return (
            <Button
                hasAccess={getPagePermissions(permissions, "servicos", "update")}
                id="btnEditar"
                titulo={defaultText.btn.editar}
                action={() => dispatch(abrirModalEditarServico(val.content))}
                type='btn circle'
                color='secondary'
                children={<IcoEditar title={defaultText.btn.editar} />}
                disabled={(val.content.status !== "OK") ? true : false}
                size='small'
            />
        );
    }
    else {
        if (val.buttonId.toLowerCase().includes("remover")) {
            return (
                <Button
                    hasAccess={getPagePermissions(permissions, "servicos", "delete")}
                    id="btnRemover"
                    titulo={defaultText.btn.remover}
                    action={() => dispatch(abrirModalDeletarServico(val.content))}
                    color="success"
                    type="btn circle"
                    children={<IcoRemoveLine title={defaultText.btn.remover} />}
                    disabled={(val.content.status !== "OK" && val.content.status !== "ERRO") ? true : false}
                />
            );
        }
        else {
            if (val.buttonId.toLowerCase().includes("status".toLowerCase())) {
                return getHeaderIcon(val.content.status);
            }
            /* if (val.buttonId.toLowerCase().includes("servicosTelefone".toLowerCase())) {
                return (
                    <Button
                        id="btnServicosTelefone"
                        titulo={defaultText.btn.telefone}
                        action={() => null}
                        color={val.content.plano && val.content.plano.servicoDeTelefone ? 'secondary' : 'secondary'}
                        type='btn circle'
                        children={<IcoChamadas title={defaultText.btn.telefone} />}
                        size='small'
                    />
                );
            }
            else {
                if (val.buttonId.toLowerCase().includes("servicosInternet".toLowerCase())) {
                    return (
                        <Button
                            id="btnServicosInternet"
                            titulo={defaultText.btn.internet}
                            action={() => null}
                            color={val.content.plano && val.content.plano.servicoDeInternet ? 'secondary' : 'secondary'}
                            type='btn circle'
                            children={<IcoWanLan title={defaultText.btn.internet} />}
                            size='small'
                        />
                    );
                }

            } */
        }
    }
};
