import React from 'react';

const RebootIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.75" height="20.613" viewBox="0 0 18.75 20.613">
            <g id="Icon_feather-power" data-name="Icon feather-power" transform="translate(-3.493 -2)">
                <path id="Caminho_119" data-name="Caminho 119" d="M18.79,9.96a8.375,8.375,0,1,1-11.846,0" transform="translate(0 -2.642)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Caminho_120" data-name="Caminho 120" d="M18,3v9.305" transform="translate(-5.128)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>
    );
};

export default RebootIcon;