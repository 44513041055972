import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditarInterfaceConteudo from './editarInterfaceConteudo';
import Button from '../../../../components/button/Button';
import Row from '../../../../components/row/row';
import { IcoMais } from '../../../../components/icones';
import { abrirModalCriarVlan, setModoEscolhidoInterface } from '../../redux/OltActions';
import { SelectInBox } from '../../../../components/form';
import { getPagePermissions } from '../../../../utils/utils';

const EditarInterface = ({ action, abrirModal }) => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../../nls/${locales}.json`);
    const selectedInterface = useSelector(state => state.oltsState.selectedInterface);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const olt = useSelector((state) => state.oltsState.olt);
    
    const modes = [{ id: 2, mode: "Hybrid" }, { id: 3, mode: "Trunk" }, { id: 4, mode: "Access"}];

    /*     useEffect(() => {
            dispatch(listarOlt(oltId));
        }, [dispatch]); */

    return (
        <div className="col-md-12">
            <Row style={{"justify-content": "flex-end"}}>
                {olt.modelo !== "OLT_8820I" ?
                    <div className="card-interface">
                        <h5>{defaltText.inputs.modoInterface}</h5>
                        <div className='selectInterfaceMode'>
                            <SelectInBox
                                disabled={permissions ? !getPagePermissions(permissions, "vlans", "update") : false}
                                id='mode'
                                label={defaltText.inputs.modoInterface}
                                selectDefault={{
                                    id: 'id',
                                    label: 'mode',
                                    minWidth: '100%',
                                }}
                                select={{
                                    action: (e) => { dispatch(setModoEscolhidoInterface(e.target.value)); },
                                    options: modes,
                                    selected: modes.filter((mode) => mode.mode === selectedInterface.mode)[0]
                                }}
                            />
                        </div>
                    </div>
                    : null
                }
                <div className='col-md-8 btn-interface flex-end flex-max'>
                    <Button
                        hasAccess={permissions ? getPagePermissions(permissions, "vlans", "create") : false}
                        id="btnAdicionar"
                        titulo={defaltText.btn.adicionarVlan}
                        action={() => dispatch(abrirModalCriarVlan())}
                        texto={defaltText.btn.adicionarVlan}
                        children={<IcoMais className="btn-border" title={defaltText.btn.adicionarVlan} />}
                        color='success'
                        type='btn'
                    />
                </div>
            </Row>
            <EditarInterfaceConteudo />
        </div>
    );
};

export default EditarInterface;
