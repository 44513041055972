import React from 'react';

export function AttentionIcon(props) {
    return (
        <svg
            width="22.5"
            height="20"
            viewBox="0 0 22.5 20"
            style={props.style ? props.style : {}}
        >
            <title>{props.title ? props.title : null}</title>
            <path d="M22.247,17.188A1.876,1.876,0,0,1,20.623,20H1.877A1.876,1.876,0,0,1,.253,17.188L9.626.937a1.876,1.876,0,0,1,3.248,0Zm-11-3.36a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,11.25,13.828ZM9.544,7.369l.29,5.313a.469.469,0,0,0,.468.443h1.9a.469.469,0,0,0,.468-.443l.29-5.312a.469.469,0,0,0-.468-.494H10.012a.469.469,0,0,0-.468.494Z" />
        </svg>
    );
}