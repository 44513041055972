import React from 'react';

export function CpeIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="42.5"
            viewBox="0 0 40 42.5"
            className={props.className ? props.className : ""}>
            <title>{props.title}</title>
            <path id="Icon_material-router" data-name="Icon material-router" d="M42.722,11.347,44.5,9.458a14.929,14.929,0,0,0-22.222,0l1.778,1.889a13.473,13.473,0,0,1,9.333-4.014A13.473,13.473,0,0,1,42.722,11.347Zm-2,1.889a10.163,10.163,0,0,0-7.333-3.306,10.163,10.163,0,0,0-7.333,3.306l1.778,1.889a7.717,7.717,0,0,1,11.111,0l1.778-1.889Zm-.667,14.875H35.611V18.667H31.167v9.444H8.944A4.6,4.6,0,0,0,4.5,32.833v9.444A4.6,4.6,0,0,0,8.944,47H40.056A4.6,4.6,0,0,0,44.5,42.277V32.833A4.6,4.6,0,0,0,40.056,28.111ZM15.611,39.916H11.167V35.194h4.444Zm7.778,0H18.944V35.194h4.444Zm7.778,0H26.722V35.194h4.444Z" transform="translate(-4.5 -4.5)" />
        </svg>
    );
}