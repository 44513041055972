import {
  ATUALIZAR_CAMPO_PESQUISA_PERFIL,
  LIMPAR_CAMPO_PESQUISA_PERFIL,
  ATUALIZAR_CONTEUDO_PERFIL_MODAL,
  CARREGANDO_PERMISSOES,
  PERMISSOES_CARREGADAS_COM_SUCESSO,
  PERMISSOES_CARREGADAS_COM_ERRO,
  PERFIS_CARREGADOS_COM_ERRO,
  PERFIS_CARREGADOS_COM_SUCESSO,
  CARREGANDO_PERFIS,
  ADICIONANDO_PERFIL,
  PERFIL_NAO_ADICIONADO,
  PERFIL_ADICIONADO_COM_SUCESSO,
  ATUALIZANDO_PERFIL,
  PERFIL_NAO_ATUALIZADO,
  PERFIL_ATUALIZADO_COM_SUCESSO,
  DELETANDO_PERFIL,
  PERFIL_NAO_DELETADO,
  PERFIL_DELETADO_COM_SUCESSO,
  SET_TIPO_MODAL_PERFIL,
  SET_PERFIL,
  SET_PERFIL_INICIAL,
  ajustModulos
} from './PerfilActions'

const perfilInicial = {
  perfilStatus: true,
  nome: '',
  email: '',
  permissoes: []
}

const initialState = {
  campoPesquisa: '',
  statusModal: '',
  perfis: [],
  perfil: {},
  telas: []
}

const filtrarPermissoes = payload =>
  payload.filter(modulo => modulo.id !== 'ADMINISTRADOR' && modulo.id !== 'APLICACAO')

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CARREGANDO_PERFIS:
      return { ...state }
    case PERFIS_CARREGADOS_COM_ERRO:
      return { ...state }
    case PERFIS_CARREGADOS_COM_SUCESSO:
      return {
        ...state,
        perfis: payload.content
          ? payload.content.filter(perfil => perfil.nome !== 'Administrador')
          : payload.filter(perfil => perfil.nome !== 'Administrador'),
        paginacao: {
          totalPages: payload.totalPages,
          totalElements: payload.totalElements,
          pageNumber: payload.number
        }
      }

    case CARREGANDO_PERMISSOES:
      return { ...state }
    case PERMISSOES_CARREGADAS_COM_ERRO:
      return { ...state }
    case PERMISSOES_CARREGADAS_COM_SUCESSO:
      return {
        ...state,
        telas: filtrarPermissoes(payload.map(modulo => ajustModulos(modulo)))
      }

    case ADICIONANDO_PERFIL:
      return { ...state }
    case PERFIL_NAO_ADICIONADO:
      return { ...state }
    case PERFIL_ADICIONADO_COM_SUCESSO:
      return { ...state, perfis: [...state.perfis, payload] }

    case ATUALIZANDO_PERFIL:
      return { ...state }
    case PERFIL_NAO_ATUALIZADO:
      return { ...state }
    case PERFIL_ATUALIZADO_COM_SUCESSO:
      return {
        ...state,
        perfis: state.perfis.map(perfil => (perfil.id === payload.id ? payload : perfil))
      }

    case DELETANDO_PERFIL:
      return { ...state }
    case PERFIL_NAO_DELETADO:
      return { ...state }
    case PERFIL_DELETADO_COM_SUCESSO:
      return {
        ...state,
        perfis: state.perfis.filter(perfil => perfil.id !== payload)
      }
    case SET_TIPO_MODAL_PERFIL:
      return { ...state, statusModal: payload }

    case SET_PERFIL:
      return { ...state, perfil: payload }

    case SET_PERFIL_INICIAL:
      return { ...state, perfil: perfilInicial }

    case ATUALIZAR_CAMPO_PESQUISA_PERFIL:
      return { ...state, campoPesquisa: payload }

    case LIMPAR_CAMPO_PESQUISA_PERFIL:
      return { ...state, campoPesquisa: '' }

    case ATUALIZAR_CONTEUDO_PERFIL_MODAL:
      return {
        ...state,
        perfil: {
          ...state.perfil,
          [payload.target.name]: payload.target.type === 'checkbox' ? payload.target.checked : payload.target.value
        }
      }
    default:
      return state
  }
}
