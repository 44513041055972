import React from 'react';

export function IcoWanLan(props) {
    return (
        <svg
            width="26"
            height="19.703"
            viewBox="0 0 26 19.703"
            style={props.style ? props.style : {}}
        >
            <title>{props.title ? props.title : null}</title>
            <g id="diagrama" transform="translate(0 0)">
                <path d="M9.979-449.9a.95.95,0,0,0-.508.523,16.658,16.658,0,0,0-.076,3v2.813l.117.224a1.384,1.384,0,0,0,.335.376l.218.152,1.214.015,1.214.015v2.128H7.968c-3.143,0-4.55.015-4.626.056-.213.117-.244.315-.244,1.742v1.346l-1.209.015-1.2.015-.213.147a1.075,1.075,0,0,0-.345.4L0-436.675v5.54l.122.264a.964.964,0,0,0,.34.406l.218.142,2.818.015c1.914.01,2.884,0,3.011-.041a1,1,0,0,0,.6-.533c.086-.188.1-.4.1-3.011,0-3.026,0-3.011-.269-3.306-.254-.279-.421-.31-1.671-.31H4.113v-2.184h8.379v2.178l-1.214.015-1.214.015-.218.152a1.314,1.314,0,0,0-.335.376l-.117.223v2.813a16.658,16.658,0,0,0,.076,3,.951.951,0,0,0,.508.523c.188.086.391.1,3.021.1s2.834-.01,3.021-.1a.95.95,0,0,0,.508-.523,16.658,16.658,0,0,0,.076-3v-2.813l-.117-.223a1.314,1.314,0,0,0-.335-.376l-.218-.152-1.214-.015-1.214-.015v-2.178h8.379v2.184H20.744a4.69,4.69,0,0,0-1.336.076.949.949,0,0,0-.523.508c-.086.188-.1.391-.1,3.022s.01,2.833.1,3.021a1,1,0,0,0,.6.533c.127.041,1.1.051,3.011.041l2.818-.015.218-.142a.964.964,0,0,0,.34-.406l.122-.264V-433.9c0-2.585-.01-2.783-.1-2.971a1.276,1.276,0,0,0-.239-.34c-.289-.269-.427-.295-1.645-.295H22.9v-1.346c0-1.432-.03-1.63-.244-1.747-.076-.041-1.483-.056-4.626-.056H13.508v-2.179l1.214-.015,1.214-.015.218-.152a1.385,1.385,0,0,0,.335-.376l.117-.224V-446.4a16.39,16.39,0,0,0-.076-2.976.95.95,0,0,0-.508-.523c-.188-.086-.391-.1-3.021-.1S10.166-449.99,9.979-449.9Zm5.611,3.514v2.595l-2.3-.03c-1.264-.02-2.432-.046-2.59-.061l-.295-.03v-5.068h5.18Zm-9.963,9.933.62.03v5.114H1.016v-5.18h2C4.113-436.492,5.291-436.477,5.627-436.457Zm9.344,0,.62.03v5.114H10.41v-5.18h1.97C13.467-436.492,14.635-436.477,14.97-436.457Zm10.014,2.5v2.534l-.112.03c-.066.015-1.244.031-2.615.031h-2.5v-5.129h5.23Z"
                    transform="translate(0 450)"
                />
            </g>
        </svg>
    );
}
