import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import { fecharModalColaborador } from '../redux/ColaboradorActions';

const Remover = ({ action }) => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const colaborador = useSelector(state => state.colaboradoresState.colaborador);

    const statusModal = useSelector(state => state.colaboradoresState.statusModal);
    const local = useSelector(state => state.locaisState.local.nomeLocal);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalColaborador())}
            containerModal={{
                title: defaltText.tituloModalRemover,
                size: 'medium',
                content: (
                    <>
                        <p>{defaltText.mensagem.removerConfirmacao}
                            <span style={{ fontWeight: "bold" }}>{colaborador.nome} </span>
                            {defaltText.mensagem.removerConfirmacaoLocalizacao}
                            <span style={{ fontWeight: "bold" }}>{local}</span> ?
                        </p>
                        {/*  <p style={{ fontWeight: "bold" }}>{colaborador.nome}</p> */}
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    acao: action,
                    texto: defaltText.btn.remover,
                    class: "success"
                }
            }}
        />
    );
};

export default Remover;
