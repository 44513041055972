import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CpeIcon, DarkOlt, OltIcon, ServicoIcon } from '../../components/icones';
import { dataTimeZoneZero } from '../../utils/utils';
import Button from '../../components/button/Button';
import { IcoRemoveLine } from '../../components/icones';
import { deletarNotificacao } from './redux/NotificacoesActions';
import { getPagePermissions } from '../../utils/utils';
import { abrirModalDeletar } from '../../modulos/notificacoes/redux/NotificacoesActions';

const NotificacaoBox = (props) => {
    const dispatch = useDispatch();
    const notification = props.notification;
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../modulos/notificacoes/nls/${locales}.json`);
    const userTheme = useSelector((state) => state.layoutState.selectedTheme.id);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    // const novoItem = {...props, dataCriacao: dataTimeZoneZero(props.dataCriacao)}

    const iconNotification = notification => {
        switch (notification['tipo']) {
            case 'OLT':
                if (userTheme === 'dark-theme') {
                    return <OltIcon />;
                } else {
                    return <DarkOlt />;
                }
            case 'SERVICO':
                return <ServicoIcon />;
            case 'CPE':
                return <CpeIcon />;
            default:
                return;
        }
    };

    return <div className='notificationBox'>
        <div className="iconeTexto-notificacao">
            <div className="icone-notificacao">
                {iconNotification(notification)}
            </div>
            <div className="texto-notificacao">
                <p id='notificationName'>{notification['nome']}</p>
                <p id='notificationContent'>{defaultText.inputs.tipo}: {notification['tipo']}</p>
                <p id='notificationContent'>{notification['descricao']}</p>
            </div>
        </div>
        <div className="deletar-notificacao">
            < Button 
                hasAccess={getPagePermissions(permissions, "notificacoes", "delete")}
                id="deleteLocales"
                action={() => dispatch(abrirModalDeletar(notification))}
                children={<IcoRemoveLine />}
                
            />
            
        </div>
    </div>
}



export default NotificacaoBox;
