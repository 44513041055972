import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AjustData } from '../../../components/mask/Mask';
import BasicModal from '../../../components/modal/BasicModal';
import { getPagePermissions } from '../../../utils/utils';
import { reenviarCpe } from '../../cpe/redux/CpeActions';
import { reenviarOlt } from '../../olt/redux/OltActions';
import { reenviarServico } from '../../servicos/redux/ServicoActions';
import { fecharModalNotificacoes } from '../redux/NotificacoesActions';

const Erro = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const statusModal = useSelector(state => state.notificacoesState.statusModal);
    const notificacao = useSelector(state => state.notificacoesState.notificacao);
    const pageNumber = useSelector(state => state.notificacoesState.paginacao.pageNumber);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const reenviar = (notificacao) => {
        switch (notificacao.tipo) {
            case "SERVICO":
                return dispatch([fecharModalNotificacoes(pageNumber), reenviarServico(notificacao, pageNumber)]);
            case "OLT":
                return dispatch([fecharModalNotificacoes(pageNumber), reenviarOlt(notificacao, pageNumber)]);
            case "CPE":
                return dispatch([fecharModalNotificacoes(pageNumber), reenviarCpe(notificacao, pageNumber)]);

            default:
                return dispatch(fecharModalNotificacoes());
        }
    };

    const acaoReenviar = notificacao.status === "ERRO" && getPagePermissions(permissions, "olts", "update") ? {
        acao: () => reenviar(notificacao),
        texto: defaltText.btn.reenviar,
        class: "success"
    } : false;

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalNotificacoes())}
            containerModal={{
                title: `${notificacao.nome}`,
                size: 'medium',
                // direction: 'center',
                content: (
                    <>
                        <div className='notificacao-erro'>
                            <small>{`${notificacao.tipo ? notificacao.tipo : 'Sistema'} - ${notificacao.status}`}</small>
                            <small>{AjustData(notificacao.ultimaModificacao, locales)}</small>
                            <p>{notificacao.descricaoErro}</p>
                        </div>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: acaoReenviar
            }}
        />
    );
};

export default Erro;
