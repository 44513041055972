import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './alterarModoInterface.css';
import { fecharModalInterface, alterarModoInterface, setModoEscolhidoInterface } from '../../redux/OltActions';
import BasicModal from '../../../../components/modal/BasicModal';


const AlterarModoInterface = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const selectedInterface = useSelector(state => state.oltsState.selectedInterface);
    const previousSelectedInterface = useSelector(state => state.oltsState.previousSelectedInterface);
    const defaltText = require(`../../nls/${locales}.json`);

    const olt = useSelector((state) => state.oltsState.olt);

    const statusModal = useSelector(state => state.oltsState.statusModal);

    return (
        <BasicModal
            open={statusModal}
            close={() => [dispatch(setModoEscolhidoInterface(previousSelectedInterface)), dispatch(fecharModalInterface())]}
            containerModal={{
                title: defaltText.tituloModalAlterarInterface,
                size: 'medium',
                content: (
                    <>
                        <p>{defaltText.mensagem.alterarModoVlanConfirmacao}</p>
                    </>

                ),
                closeText: defaltText.btn.fechar,
                action: {
                    acao: () => [dispatch(alterarModoInterface(selectedInterface)), dispatch(fecharModalInterface())],
                    texto: defaltText.btn.alterar,
                    class: 'success'
                }
            }}
        />
    );
};

export default AlterarModoInterface;
