import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BasicModal from '../../../../components/modal/BasicModal';
import { translateStatus } from '../../../../utils/utils';
import { fecharModalSFPInfo } from '../../redux/OltActions';

const ExibirModalInfoUtilizacaoOlt = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../../nls/${locales}.json`);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const olt = useSelector(state => state.oltsState.olt);
    const [uptime, setUptime] = useState(convertUpTime());

    function convertUpTime(){
        if(olt.modelo === "OLT_8820I"){
            return olt.upTime?.replace(":", ' ');
        }
        return olt.upTime;
    }
    useEffect(() => {
        setUptime(convertUpTime());
    }, [olt])
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalSFPInfo())}
            containerModal={{
                title: defaultText.tituloModalInfoUtilizacao,
                size: 'medium',
                content: (
                    <>
                        <div className='col-md-12 list-topic'>
                            <div className='list-item'>
                                <div className='list-topic-item'>{defaultText.inputs.cpuUtilization}:</div>
                                <div className='list-topic-item'>{olt.cpuUtilization ? olt.cpuUtilization + "%" : "---"}</div>
                            </div>
                            <div className='list-item'>
                                <div className='list-topic-item'>{defaultText.inputs.totalMemory}:</div>
                                <div className='list-topic-item'>{olt.totalMemory ? olt.totalMemory + " MB" : "---"}</div>
                            </div>
                            <div className='list-item'>
                                <div className='list-topic-item'>{defaultText.inputs.freeMemory}:</div>
                                <div className='list-topic-item'>{olt.freeMemory ? olt.freeMemory + " MB" : "---"}</div>
                            </div>
                            <div className='list-item'>
                                <div className='list-topic-item'>{defaultText.inputs.upTime}:</div>
                                <div className='list-topic-item'>{uptime ? uptime.replace(/\s/g, defaultText.inputs.dias) : "---"}</div>
                            </div>
                            {olt.modelo !== "OLT_8820I" ?
                            <div className='list-item'>
                                <div className='list-topic-item'>{defaultText.inputs.oltBootrom}:</div>
                                <div className='list-topic-item'>{olt.hardwareVersion ? olt.bootromVersion : "---"}</div>
                            </div>
                            : null}
                            {olt.power?.length > 0 && olt.power.map((oltPower, index) => {
                                return (
                                    <div className='list-item'>
                                        <div className='list-topic-item'>{defaultText.inputs.power + (index + 1)}:</div>
                                        <div className='list-topic-item'>{oltPower ? translateStatus(oltPower, locales) : "---"}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                ),
                closeText: defaultText.btn.fechar,
            }}
        />
    );
};

export default ExibirModalInfoUtilizacaoOlt;