import React from 'react';
import { resetarCamposAction, validarAction, validarCamposAction, validarIdentificadorCpe } from "../../../validation/validationActions";

import axios from "axios";
import { toast } from "react-toastify";
import { ajustIdioma } from "../../../layouts/redux/LayoutActions";
import { checkResponseErrorType } from "../../../utils/utils";
import { CorrectIcon } from "../../../layouts/components/icons";
import { atualizarConteudoServicoModal, setServicoInicial } from '../../servicos/redux/ServicoActions';
import { listarPlanosFiltrado, setListPlanoInicial, setPlanoInicial } from '../../planos/redux/PlanosActions';

const cpeRequired = {
    porta: '',
    slot: '',
    modelo: '',
    identificador: '',
    plano: {}
};


const idiomaText = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userLocales = user.preferencia.linguagem;
    const locales = userLocales ? (userLocales === 'EN_US' ? 'en-US' : 'pt-BR') : null;
    const defaltText = require(`../nls/${locales ? locales : 'pt-BR'}.json`);
    return defaltText.mensagem;
};

export const CPE_NAO_ADICIONADO = 'CPE_NAO_ADICIONADO';
const cpeNaoAdicionado = (erro) => ({
    type: CPE_NAO_ADICIONADO,
    payload: erro,
});

export const ATUALIZAR_CONTEUDO_CPE_MODAL = 'ATUALIZAR_CONTEUDO_CPE_MODAL';
export const atualizarConteudoCpeModal = (evento) => ({
    type: ATUALIZAR_CONTEUDO_CPE_MODAL,
    payload: evento,
});
export const ATUALIZAR_CONTEUDO_CPE_EXISTENTE_MODAL =
    'ATUALIZAR_CONTEUDO_CPE_EXISTENTE_MODAL';
export const atualizarConteudoCpeExistenteModal = (evento, assinante) => {
    const conteudo = {
        target: { ...evento.target, value: { ...evento.target.value, assinante } },
    };
    return {
        type: ATUALIZAR_CONTEUDO_CPE_EXISTENTE_MODAL,
        payload: conteudo,
    };
};

export const SET_TIPO_MODAL_CPE = 'SET_TIPO_MODAL_CPE';
const setTipoModalCpe = (tipo) => ({
    type: SET_TIPO_MODAL_CPE,
    payload: tipo,
});

export const SET_CPE = 'SET_CPE';
const setCpe = (cpe) => ({
    type: SET_CPE,
    payload: cpe,
});

export const SET_CPE_INICIAL = 'SET_CPE_INICIAL';
const setCpeInicial = () => ({
    type: SET_CPE_INICIAL,
});

export const ATUALIZAR_CONTEUDO_CPE_SERVICO_MODAL =
    'ATUALIZAR_CONTEUDO_CPE_SERVICO_MODAL';
export const atualizarServicoCpeModal = (evento, plano) => ({
    type: ATUALIZAR_CONTEUDO_CPE_SERVICO_MODAL,
    payload: { evento, plano },
});

export const ABRIR_MODAL_CRIAR_CPE = 'ABRIR_MODAL_CRIAR_CPE';
export const abrirModalCriarCpe = () => [
    resetarCamposAction(),
    setCpeInicial(),
    setTipoModalCpe('criando'),
];

export const ABRIR_MODAL_EDITAR_CPE = 'ABRIR_MODAL_EDITAR_CPE';
export const abrirModalEditarCpe = (cpe) => [
    resetarCamposAction(),
    setCpe(cpe),
    setTipoModalCpe('editando'),
];

export const ABRIR_MODAL_GERENCIAR_CPE = 'ABRIR_MODAL_GERENCIAR_CPE';
export const abrirModalGerenciarCpe = (cpe) => [
    resetarCamposAction(),
    setCpe(cpe),
    setTipoModalCpe('gerenciando'),
];

export const ABRIR_MODAL_VINCULAR_CPE = 'ABRIR_MODAL_VINCULAR_CPE';
export const abrirModalVincularCpe = (cpe) => [
    resetarCamposAction(),
    setCpe(cpe),
    setTipoModalCpe('vinculando'),
];

export const ABRIR_MODAL_INFORMACAO = 'ABRIR_MODAL_INFORMACAO';
export const abrirModalInformacaoCpe = (cpe) => [
    setCpe(cpe),
    setTipoModalCpe('informacoes'),
];

export const ABRIR_MODAL_ERRO_CPE = 'ABRIR_MODAL_ERRO_CPE';
export const abrirModalErroCpe = (cpe) => [
    setCpe(cpe),
    setTipoModalCpe('erro'),
];

export const FECHAR_MODAL_CPE = 'FECHAR_MODAL_CPE';
export const fecharModalCpe = () => [setCpeInicial(), setTipoModalCpe('')];
export const fecharModalVincServico = () => [setCpeInicial(), setTipoModalCpe(''), resetarCamposFormularioPesquisaCpe(), setServicoInicial(), setListPlanoInicial()];

export const CARREGANDO_CPES = 'CARREGANDO_CPES';
const carregandoCpes = () => ({
    type: CARREGANDO_CPES,
});

export const CPES_CARREGADOS_COM_SUCESSO = 'CPES_CARREGADOS_COM_SUCESSO';
const cpesCarregadosComSuceso = (cpes) => ({
    type: CPES_CARREGADOS_COM_SUCESSO,
    payload: cpes,
});

export const CPES_CARREGADOS_COM_ERRO = 'CPES_CARREGADOS_COM_ERRO';
const cpesCarregadosComErro = (erro) => ({
    type: CPES_CARREGADOS_COM_ERRO,
    payload: erro,
});

export const listarCpes = (formPesquisa) => {
    const params = {
        pagina: `pagina=${formPesquisa && formPesquisa.pagina ? formPesquisa.pagina : '0'}`,
        pesquisaNome: `&nome=${formPesquisa && formPesquisa.nome ? formPesquisa.nome : ''}`,
        pesquisaIdentificador: `&identificador=${formPesquisa && formPesquisa.identificador ? formPesquisa.identificador : ''}`,
        pesquisaModelo: `&modelo=${formPesquisa && formPesquisa.modelo ? formPesquisa.modelo : ''}`,
        pesquisaNPorta: `&nPorta=${formPesquisa && formPesquisa.porta ? formPesquisa.porta : ''}`,
        pesquisaDescricao: `&descricao=${formPesquisa && formPesquisa.descricao ? formPesquisa.descricao : ''}`,
    };
    return (dispatch) => {
        dispatch(handleIsLoadingCpeStatus(true));
        dispatch(carregandoCpes());
        axios
            .get(`/api/cpe?${params.pagina + params.pesquisaNome + params.pesquisaIdentificador + params.pesquisaModelo + params.pesquisaNPorta}`)
            .then((response) => {
                dispatch(cpesCarregadosComSuceso(response.data));
            })
            .catch((error) => {
                dispatch(cpesCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
                dispatch(handleIsLoadingCpeStatus(false));
            });
    };
};

export const buscarCpesNaoProvisionada = () => {
    return (dispatch) => {
        dispatch(handleIsLoadingCpeStatus(true));
        axios
            .get(`/api/cpe/botaoBuscarCpesNaoProvisionada`)
            .then((response) => {
                if (response.data.enviado) {
                    toast.success((idiomaText().comandoEnviado), {
                        icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                    });
                    dispatch(handleIsLoadingCpeStatus(false));
                }
            })
            .catch((error) => {
            });
    };
};
/* 
const cpeRequired = {
  ctos: ''
} */

export const ATUALIZANDO_CPE = "ATUALIZANDO_CPE";
const atualizandoCpe = () => ({
    type: ATUALIZANDO_CPE
});

export const CPE_ATUALIZADO_COM_SUCESSO = "CPE_ATUALIZADO_COM_SUCESSO";
const cpeAtualizadoComSucesso = cpe => ({
    type: CPE_ATUALIZADO_COM_SUCESSO,
    payload: cpe
});

export const FETCH_CPE_DASHBOARD = "FETCH_CPE_DASHBOARD";
const fetchCpeDashboard = data => ({
    type: FETCH_CPE_DASHBOARD,
    payload: data
});

export const CPE_NAO_ATUALIZADO = "CPE_NAO_ATUALIZADO";
const cpeNaoAtualizado = erro => ({
    type: CPE_NAO_ATUALIZADO,
    payload: erro
});

export const CPE_ADICIONADO_COM_SUCESSO = 'CPE_ADICIONADO_COM_SUCESSO';
const cpeAdicionadoComSucesso = (cpe) => ({
    type: CPE_ADICIONADO_COM_SUCESSO,
    payload: cpe,
});

export const adicionarCpe = (cpe, olt, page) => {

    /*   var cpeJson = {
        identificador: cpe.identificador ? cpe.identificador : '',
        modelo: cpe.modelo ? cpe.modelo.id : '',
        slot: cpe.slot ? cpe.slot.id : '',
        olt: cpe.olt ? cpe.olt.id : '',
        porta: cpe.porta ? cpe.porta.identifier : ''
      } */
    /* var cpeJson = {
      identificador: cpe.identificador,
      modelo: cpe.modelo.nome,
      porta: cpe && cpe.porta ? parseInt(cpe.porta.nome.split("/")[1]) : 0,
      slot: parseInt(cpe.slot.nome),
      olt: cpe.olt,
      idOlt: cpe.olt && cpe.olt.id,
      estado: "No_Provisioned"
    } */
    // cpe && cpe.porta && cpe.porta.nome ? parseInt(cpe.porta.nome.split("/")[1]) : ""
    const handlePortByOltModel = (olt) => {
        switch (olt.modelo) {
            case "OLT_8820I":
                return (cpe && cpe.porta && cpe.porta.nome ? parseInt(cpe.porta.nome.split(" ")[1]) : "");
            case "OLT_G16":
                return (cpe && cpe.porta && cpe.porta.nome ? parseInt(cpe.porta.nome.split("/")[1]) : "");
            case "OLT_G8":
                return (cpe && cpe.porta && cpe.porta.nome ? parseInt(cpe.porta.nome.split("/")[1]) : "");
            default:
                return '';
        }
    };

    const routerCounter = cpe &&
        cpe.plano &&
        cpe.plano.listaConfiguracoes &&
        (cpe.plano.listaConfiguracoes.filter((config) => config.tipoServico === "PPPOE").length > 0) ?
        cpe.plano.listaConfiguracoes.filter((config) => config.tipoServico === "PPPOE").length : 0;

    var identificadorTratado = cpe.identificador.includes('--') ? cpe.identificador.replace('--', '-') : cpe.identificador;
    var cpeJson = {
        identificador: cpe.identificador.includes('--') ? identificadorTratado.substring(0, 4) + '-' + identificadorTratado.substring(4) : cpe.identificador,
        modelo: cpe.modelo.model,
        porta: cpe.porta ? handlePortByOltModel(olt) : {},
        slot: parseInt(cpe.slot.number),
        idOlt: olt.id,
        status: "NAO_PROVISIONADA",
        plano: cpe.plano,
        senhaPPPoE: cpe.senhaPPPoE,
        usuarioPPPoE: cpe.usuarioPPPoE,
        conexao: cpe.conexao
    };
    const cpePatternRequired = {
        identificador: "identificadorCpe"
    };
    const form8820i = olt.modelo === 'OLT_8820I' ? { ...cpeRequired } : { ...cpeRequired }
    const cpeVal = {
        formPatternRequired: cpePatternRequired,
        formRequired: routerCounter > 0 ? form8820i : cpeRequired,
        formValues: cpeJson,
    };
    return (dispatch) => {
        let mensagemDeErro = validarCamposAction(cpeVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        mensagemDeErro = validarIdentificadorCpe(cpeJson.identificador);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalCpe());
        dispatch(adicionandoCpe());
        axios
            .post('/api/cpe', cpeJson)
            .then((response) => {
                //dispatch(cpeAdicionadoComSucesso(response.data))
                dispatch(pesquisaCpes({ pesquisa: {}, pagina: page }));
                toast.success((idiomaText().adicionarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(cpeNaoAdicionado(erro));
                checkResponseErrorType(erro, idiomaText().adicionarErro);
            });
    };
};

export const reenviarCpe = (cpe, page) => {
    return (dispatch) => {
        dispatch(fecharModalCpe());
        axios
            .post('/api/cpe/reenviar', cpe)
            .then(() => {
                dispatch(pesquisaCpes({ pagina: page }));
                toast.success((idiomaText().reenviarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((error) => {
                checkResponseErrorType(error, idiomaText().reenviarErro);
            });
    };
};

export const atualizarCpe = (cpe, olt, page) => {

    var cpeJson = {
        ...cpe,
        id: cpe.id,
        identificador: cpe.identificador,
        modelo: typeof cpe.modelo === "string" ? cpe.modelo : (cpe.modelo && cpe.modelo.nome ? cpe.modelo.nome : ""),
        slot: typeof cpe.slot === "number" ? cpe.slot : (cpe.slot && cpe.slot.nome ? parseInt(cpe.slot.nome) : null),
        idOlt: cpe.idOlt,
        ctos: cpe.ctos ? cpe.ctos : ""
    };

    let cpeJsonRequired = { ...cpeRequired };

    if (typeof cpe.porta === "number") {
        cpeJson.porta = cpe.porta;
    }

    if (cpeJson.slot === null) {
        delete cpeJsonRequired.slot;
    }

    const cpeVal = {
        formRequired: cpeJsonRequired,
        formValues: cpeJson
    };

    return dispatch => {
        const mensagemDeErro = validarCamposAction(cpeVal);
        dispatch(atualizandoCpe());
        axios.put('/api/cpe', cpeJson)
            .then(response => {
                dispatch(cpeAtualizadoComSucesso(response.data));
                toast.success((idiomaText().atualizarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(cpeNaoAtualizado(erro));
                checkResponseErrorType(erro, idiomaText().atualizarErro);
            });
    };
};

export const ABRIR_MODAL_SERVICOS_CPE = 'ABRIR_MODAL_SERVICOS_CPE';
export const abrirModalServicosCpe = (cpe) => [
    resetarCamposAction(),
    setCpe(cpe),
    setTipoModalCpe('servicos'),
];

export const CARREGANDO_CPE = 'CARREGANDO_CPE';
const carregandoCpe = () => ({
    type: CARREGANDO_CPE,
});

export const CPE_NAO_DELETADO = 'CPE_NAO_DELETADO';
const cpeNaoDeletado = (erro) => ({
    type: CPE_NAO_DELETADO,
    payload: erro,
});

export const CPE_CARREGADOS_COM_SUCESSO = 'CPE_CARREGADOS_COM_SUCESSO';
const cpeCarregadosComSuceso = (cpe) => ({
    type: CPE_CARREGADOS_COM_SUCESSO,
    payload: cpe,
});

export const CPE_CARREGADOS_COM_ERRO = 'CPE_CARREGADOS_COM_ERRO';
const cpeCarregadosComErro = (erro) => ({
    type: CPE_CARREGADOS_COM_ERRO,
    payload: erro,
});

export const SERVICOS_CPE_CARREGADOS_COM_SUCESSO =
    'SERVICOS_CPE_CARREGADOS_COM_SUCESSO';
const servicosCpeCarregadosComSuceso = (servicos) => ({
    type: SERVICOS_CPE_CARREGADOS_COM_SUCESSO,
    payload: servicos,
});

export const SERVICOS_CPE_CARREGADOS_COM_ERRO =
    'SERVICOS_CPE_CARREGADOS_COM_ERRO';
const servicosCpeCarregadosComErro = (erro) => ({
    type: SERVICOS_CPE_CARREGADOS_COM_ERRO,
    payload: erro,
});

export const ADICIONANDO_CPE = 'ADICIONANDO_CPE';
const adicionandoCpe = () => ({
    type: ADICIONANDO_CPE,
});

export const listarCpe = (idCpe) => {
    return (dispatch) => {
        dispatch(carregandoCpe());
        dispatch(handleIsLoadingCpeStatus(true));
        Promise.allSettled([
            axios.get(`/api/cpe/${idCpe}`),
            axios.get(`/api/servico/cpe/${idCpe}`)
        ])
            .then(([cpe, servicosDoCpe]) => {
                if (cpe.status !== "rejected" && servicosDoCpe.status !== "rejected") {
                    dispatch(cpeAndServicesLoadedSuccessfully({ cpe: cpe.value.data, servicosDoCpe: servicosDoCpe.value.data }));
                }
            })
            .catch((error) => {
                checkResponseErrorType(error, idiomaText().listarDadosErro);
            });
    };
};

export const ATUALIZANDO_CPE_SERVICO = 'ATUALIZANDO_CPE_SERVICO';
const atualizandoCpeServico = () => ({
    type: ATUALIZANDO_CPE_SERVICO,
});

export const CPE_SERVICO_ATUALIZADO_COM_SUCESSO =
    'CPE_SERVICO_ATUALIZADO_COM_SUCESSO';
const cpeServicoAtualizadoComSucesso = (cpe) => ({
    type: CPE_SERVICO_ATUALIZADO_COM_SUCESSO,
    payload: cpe,
});

export const CPE_SERVICO_NAO_ATUALIZADO = 'CPE_SERVICO_NAO_ATUALIZADO';
const cpeServicoNaoAtualizado = (erro) => ({
    type: CPE_SERVICO_NAO_ATUALIZADO,
    payload: erro,
});
const servicoRequired = {
    nome: '',
};

export const CPES_ATUALIZADOS_COM_SUCESSO = 'CPES_ATUALIZADOS_COM_SUCESSO';
export const cpesAtualizadosComSucesso = (cpe) => ({
    type: CPES_ATUALIZADOS_COM_SUCESSO,
    payload: cpe,
});

export const ABRIR_MODAL_DELETAR_CPE = 'ABRIR_MODAL_DELETAR_CPE';
export const abrirModalDeletarCpe = (cpe) => [
    setCpe(cpe),
    setTipoModalCpe('deletando'),
];

export const CARREGAR_OLT_DA_CPE_SELECIONADA = 'CARREGAR_OLT_DA_CPE_SELECIONADA';
export const carregarOltDaCpeSelecionada = (oltDaCpe) => ({
    type: CARREGAR_OLT_DA_CPE_SELECIONADA,
    payload: oltDaCpe
});

export const deletarCpe = (cpe, page) => {
    return (dispatch) => {
        dispatch(fecharModalCpe());
        dispatch(deletandoCpe());
        axios
            .delete(`/api/cpe/${cpe.id}`)
            .then(() => {
                //dispatch(cpeDeletadoComSucesso(cpe.id))
                //dispatch(pesquisaCpes({ pagina: page }));
                toast.success((idiomaText().deletarSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(cpeNaoDeletado(erro));
                checkResponseErrorType(erro, idiomaText().deletarErro);
            });
    };
};

export const DELETANDO_CPE = 'DELETANDO_CPE';
const deletandoCpe = () => ({
    type: DELETANDO_CPE,
});

export const CPE_DELETADO_COM_SUCESSO = 'CPE_DELETADO_COM_SUCESSO';
export const cpeDeletadoComSucesso = (cpe) => ({
    type: CPE_DELETADO_COM_SUCESSO,
    payload: cpe,
});

export const REINICIANDO_CPE = 'REINICIANDO_CPE';
const reiniciandoCpe = () => ({
    type: REINICIANDO_CPE,
});


export const CPE_REINICIADA_COM_SUCESSO = "CPE_REINICIADA_COM_SUCESSO";
export const cpeReiniciadaComSucesso = (cpe) => ({
    type: CPE_REINICIADA_COM_SUCESSO,
    payload: cpe
});

export const CPE_NAO_REINICIADA = 'CPE_NAO_REINICIADA';
const cpeNaoReiniciada = (erro) => ({
    type: CPE_NAO_REINICIADA,
    payload: erro,
});

export const reiniciarCpe = (cpe) => {
    return (dispatch) => {
        dispatch(fecharModalCpe());
        dispatch(reiniciandoCpe());
        axios
            .post(`/api/cpe/reiniciar?idCpe=${cpe.id}`)
            .then((response) => {
                dispatch(cpeReiniciadaComSucesso(response.data));
                toast.success((idiomaText().reiniciarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(cpeNaoReiniciada(erro));
                checkResponseErrorType(erro, idiomaText().reiniciarErro);
            });
    };
};

export const RESETAR_TODAS_COMBOS_NOVO_CPE = "RESETAR_TODAS_COMBOS_NOVO_CPE";
export const resetarTodasAsCombosNovoCpe = (e) => ({
    type: RESETAR_TODAS_COMBOS_NOVO_CPE,
    payload: e
});
export const RESETAR_SLOT_PON_NOVO_CPE = "RESETAR_SLOT_PON_NOVO_CPE";
export const resetarSlotPonNovoCpe = (e) => ({
    type: RESETAR_SLOT_PON_NOVO_CPE,
    payload: e
});

//Refatoração de Pesquisa de CPE
export const ATUALIZAR_FORMULARIO_PESQUISA_DE_CPE = "ATUALIZAR_FORMULARIO_PESQUISA_DE_CPE";
export const atualizarFormularioDePesquisaCpe = (e) => ({
    type: ATUALIZAR_FORMULARIO_PESQUISA_DE_CPE,
    payload: e
});

export const LIMPAR_CAMPO_FORMULARIO_PESQUISA_CPE = "LIMPAR_CAMPO_FORMULARIO_PESQUISA_CPE";
export const limparCampoFormularioPesquisaCpe = (e) => {
    return ({
        type: LIMPAR_CAMPO_FORMULARIO_PESQUISA_CPE,
        payload: e
    }
    /* pesquisaCpes(formPesquisa) */);
};

export const RESETAR_CAMPOS_FORMULARIO_PESQUISA_CPE = "RESETAR_CAMPOS_FORMULARIO_PESQUISA_CPE";
export const resetarCamposFormularioPesquisaCpe = () => [
    {
        type: RESETAR_CAMPOS_FORMULARIO_PESQUISA_CPE
    },
    pesquisaCpes()
];

export const pesquisaCpes = (formPesquisa) => {

    let params = {
        pagina: `pagina=${formPesquisa && formPesquisa.pagina ? formPesquisa.pagina : '0'}`,
        pesquisaGeral: `&pesquisaGeral=${formPesquisa && formPesquisa.pesquisa.pesquisaGeral ?
            formPesquisa.pesquisa.pesquisaGeral.toUpperCase() === "NÃO PROVISIONADA" || formPesquisa.pesquisa.pesquisaGeral.toUpperCase() === "UNPROVISIONED" ? "NAO_PROVISIONADA" :
                formPesquisa.pesquisa.pesquisaGeral.toUpperCase() === "PROBLEMA ÓPTICO" || formPesquisa.pesquisa.pesquisaGeral.toUpperCase() === "OPTICAL PROBLEM" ? "PROBLEMA_OPTICO" :
                    formPesquisa.pesquisa.pesquisaGeral.toUpperCase() === "ERROR" ? "ERRO" : formPesquisa.pesquisa.pesquisaGeral : ''}`,
        qtdRegistros: `&qtdRegistros=${formPesquisa && formPesquisa.pesquisa.qtdRegistros ? formPesquisa.pesquisa.qtdRegistros : ''}`,
        nomeOlt: `&nomeOlt=${formPesquisa && formPesquisa.pesquisa.olt ? formPesquisa.pesquisa.olt.nome : ''}`,
        pesquisaIdentificador: `&identificador=${formPesquisa && formPesquisa.pesquisa.identificador ? formPesquisa.pesquisa.identificador : ''}`,
        pesquisaModelo: `&modelo=${formPesquisa && formPesquisa.pesquisa.modelo ? formPesquisa.pesquisa.modelo : ''}`,
        pesquisaDescricao: `&descricao=${formPesquisa && formPesquisa.pesquisa.descricao ? formPesquisa.pesquisa.descricao : ''}`,
        pesquisaNPorta: `&nPorta=${formPesquisa && formPesquisa.pesquisa.porta ? formPesquisa.pesquisa.porta : ''}`,
        pesquisaStatus: `&status=${formPesquisa && formPesquisa.pesquisa.status ?
            formPesquisa.pesquisa.status.statusCpe === "NÃO PROVISIONADA" || formPesquisa.pesquisa.status.statusCpe === "UNPROVISIONED" ? "NAO_PROVISIONADA" :
                formPesquisa.pesquisa.status.statusCpe === "PROBLEMA ÓPTICO" || formPesquisa.pesquisa.status.statusCpe === "OPTICAL PROBLEM" ? "PROBLEMA_OPTICO" :
                    formPesquisa.pesquisa.status.statusCpe === "ERROR" ? "ERRO" : formPesquisa.pesquisa.status.statusCpe : ''}`,
        pesquisaPlano: `&plano=${formPesquisa && formPesquisa.pesquisa.plano ? formPesquisa.pesquisa.plano.nome : ''}`
    };

    return (dispatch) => {

        dispatch(carregandoCpes());
        axios
            .get(`/api/cpe?${Object.values(params).join("")}`)
            .then((response) => dispatch(cpesCarregadosComSuceso(response.data)))
            .catch((error) => {
                dispatch(cpesCarregadosComErro(error));
                toast.error(idiomaText().listarErro);
            });
    };
};

export const UPDATE_MODAL_MANAGE_CPE_CONTENT = "UPDATE_MODAL_MANAGE_CPE_CONTENT";
export const updateModalManageCpeContent = (e) => ({
    type: UPDATE_MODAL_MANAGE_CPE_CONTENT,
    payload: e
});

export const saveCpeConfigs = (cpe, page) => {
    const cpeConfigRequired = {
        ssid: "ssid",
        senhaWiFi: "wifiPassword"
    };

    const cpeVal = {
        formRequired: cpeConfigRequired,
        formValues: cpe,
    };
    delete cpeVal.formRequired.olt;

    if (cpe.ssid || cpe.senhaWiFi) {
        cpeVal.formRequired.ssid = "ssid";
        cpeVal.formRequired.senhaWiFi = "wifiPassword";
    }

    if (cpe.usuarioPPPoE || cpe.senhaPPPoE) {
        cpeVal.formRequired.usuarioPPPoE = "";
        cpeVal.formRequired.senhaPPPoE = "";
        cpeVal.formRequired.mtu = "";
        cpeVal.formRequired.serviceName = "";
        cpeVal.formRequired.vlanId = "";
        cpeVal.formRequired.mppe = "";
    }

    return (dispatch) => {
        const mensagemDeErro = validarCamposAction(cpeVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalCpe());

        axios
            .post('/api/cpe/config', cpe)
            .then((response) => {
                dispatch(pesquisaCpes({ pesquisa: {}, pagina: page }));
                toast.success((idiomaText().atualizarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(cpeNaoAtualizado(erro));
                checkResponseErrorType(erro, idiomaText().atualizarErro);
            });
    };
};

export const RESET_CPE_STATE = "RESET_CPE_STATE";
export const resetCpeState = () => ({
    type: RESET_CPE_STATE,
});

export const HANDLE_IS_LOADING_CPES_STATUS = "HANDLE_IS_LOADING_CPES_STATUS";
export const handleIsLoadingCpeStatus = (status) => ({
    type: HANDLE_IS_LOADING_CPES_STATUS,
    payload: status
});

export const CPE_AND_SERVICES_LOADED_SUCCESSFULLY = "CPE_AND_SERVICES_LOADED_SUCCESSFULLY";
export const cpeAndServicesLoadedSuccessfully = (data) => ({
    type: CPE_AND_SERVICES_LOADED_SUCCESSFULLY,
    payload: data
});

export const CLEAR_CPE_FIELD = "CLEAR_CPE_FIELD";
export const clearCpeField = (e) => ({
    type: CLEAR_CPE_FIELD,
    payload: e
});

export const SET_MODAL_ADVANCED_FILTER_CPE = 'SET_MODAL_ADVANCED_FILTER_CPE';
export const setModalAdvancedFilterCPE = (type) => ({
    type: SET_MODAL_ADVANCED_FILTER_CPE,
    payload: type
});

export const SET_FECHAR_MODAL_ADVANCED_FILTER = 'cpe.SET_FECHAR_MODAL_ADVANCED_FILTER';
export const setFecharModalAdvancedFilter = () => [
    {
        type: SET_FECHAR_MODAL_ADVANCED_FILTER,
    },
    setListPlanoInicial()
];

export const IS_SEARCH = 'cpe.IS_SEARCH';
export const isSearch = () => ({
    type: IS_SEARCH
});

export const abrirModalAddServico = (cpe, olt) => [
    resetarCamposAction(),
    setCpe(cpe),
    setTipoModalCpe('addService'),
    atualizarConteudoServicoModal({
        target: { name: 'cpe', value: cpe },
    }),
    atualizarConteudoServicoModal({
        target: { name: 'olt', value: olt },
    }),
    cpe.modelo === null ? null : listarPlanosFiltrado({ modelo: cpe.modelo })
];

export const atualizarInfoCpe = (cpe) => {
    const params = {
        idCpe: `idCpe=${cpe && cpe.id ? cpe.id : ''}`
    };
    return (dispatch) => {
        axios
            .get(`/api/cpe/botaoAtualizarInfo?${params.idCpe}`)
            .then((response) => {
                dispatch(cpeAtualizadoComSucesso(response.data));
                toast.success((idiomaText().atualizarInfo), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((error) => {
                dispatch(cpeNaoAtualizado(error));
                checkResponseErrorType(error, idiomaText().atualizarErro);
            });
    };
};

export const fetchCpeStatus = (cpe) => {
   
    return (dispatch) => {
        dispatch(carregandoCpes());
        axios
            .get('/api/cpe/dashboard/fetchCpeStatus')
            .then((response) => {
                dispatch(fetchCpeDashboard(response.data));
            })
            .catch((error) => {
                dispatch(cpesCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
            });
    };
};
