import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';
import { AssinanteIcon } from '../../components/icones';
import history from '../../history';
import { abrirModalSair, setModalHeaderType } from '../redux/LayoutActions';

const UserContent = () => {
    const dispatch = useDispatch();
    const sidebar = useSelector(state => state.layoutState);
    const defaultText = require(`../nls/${sidebar.linguagem}.json`);
    const user = useSelector((state) => state.authentication.user);

    return (
        <div className='userModal'>
            <div className='userModalContent'>
                <AssinanteIcon />
                <p>{user && user.nome}</p>
                {user.perfil && <p>{defaultText.typeUser}{user.perfil.nome}</p>}             
                <Button
                    id="btnModal"
                    titulo={defaultText.manageAccount}
                    action={() => [history.push(`/meusDados`), dispatch(setModalHeaderType(""))]}
                    color='secondary'
                    children={defaultText.manageAccount}
                    size='block'
                />
                <Button
                    id="btnModal"
                    titulo={defaultText.collaborators}
                    action={() => [history.push(`/colaboradores`), dispatch(setModalHeaderType(""))]}
                    color='secondary'
                    children={defaultText.collaborators}
                    size='block'
                />
            </div>
            <div className='btnModal'>
                <Button
                    id="btnSair"
                    titulo={defaultText.sair}
                    action={() => dispatch(abrirModalSair())}
                    color='secondary'
                    children={defaultText.sair}
                    size='block'
                />
            </div>
        </div>
    );
};

export default UserContent;