import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BarraPesquisa from './barraDePesquisa';

import { listarLocais, atualizaSelect, setTipoModal, baixarAdapter, downloadAdapterWindow} from './redux/LocaisActions';

import List from '../../components/list/List';
import Paginate from '../../components/pagination/Pagination';
import Button from '../../components/button/Button';
import Progress from '../../components/progress/Progress';

import AdicionarLoc from './adicionarLoc/adicionarLoc';

import './locais.css';
import { getLocalCardActions } from '../../components/card/getCardButtons';
import { CardList } from '../../components/CardList/CardList';
import RemoveLoc from './remover/removerLoc';

export default () => {
    const dispatch = useDispatch();
    const locales = useSelector(state => state.layoutState.linguagem);
    const paginacao = useSelector(state => state.locaisState.paginacao);
    const statusModal = useSelector(state => state.locaisState.statusModal);
    const statusDownload = useSelector(state => state.locaisState.statusDownload);
    const defaultText = require(`./nls/${locales}.json`);
    const locaisState = useSelector(state => state.locaisState);
    const user = useSelector((state) => state.authentication.user);
    const idSelecionado = user && user.aplicacaoLogado && user.aplicacaoLogado.id ? user.aplicacaoLogado.id : selecionado.id;
    console.log(locaisState.locais);
    const locais = locaisState.locais.map(elem => {
        return { ...elem, status: 'OK' };
    });

    const selecionado = locaisState.local;
    const [stateData, setStateData] = useState({
        tipo: '',
        data: ''
    });

    useEffect(() => {
        dispatch(listarLocais());
    }, [dispatch]);

    const diferencaDatas = (locais) => {

        const dataLogin = new Date(locais.ultimaDataLogin);
        const dataEscrita = new Date(locais.ultimaDataOrdensEscrita);
        const dataLeitura = new Date(locais.ultimaDataOrdensLeitura);
        var dataTipoRecente = {};

        console.log(dataEscrita.getTime(), dataLeitura.getTime(), dataLogin.getTime());

        if (dataLogin.getTime() !== 0 || dataEscrita.getTime() !== 0 || dataLeitura.getTime() !== 0) {
            if (dataLogin.getTime() > dataEscrita.getTime() && dataLogin.getTime() > dataLeitura.getTime()) {
                dataTipoRecente = { tipo: defaultText.inputs.login, data: dataLogin };
            }
            else if ((dataEscrita.getTime() > dataLogin.getTime() && dataEscrita.getTime() > dataLeitura.getTime())) {
                dataTipoRecente = { tipo: defaultText.inputs.escrita, data: dataEscrita };
            }
            else {
                dataTipoRecente = { tipo: defaultText.inputs.leitura, data: dataLeitura };
            }

            const dataAgora = new Date();
            const dataPast = new Date(dataTipoRecente.data);
            const calcDatas = Math.abs(dataAgora.getTime() - dataPast.getTime());
            var calcMinutos = Math.round(calcDatas / (1000 * 60));
            var calcHoras = Math.floor(calcMinutos / 60);
            console.log(locais);
            if (calcMinutos <= 5) {
                return (dataTipoRecente.tipo + defaultText.inputs.algunsMinutos);
            }
            else if (calcMinutos < 60) {
                return (dataTipoRecente.tipo + calcMinutos + defaultText.inputs.minutos);
            }
            else if ((calcMinutos % 60) == 0) {
                return (dataTipoRecente.tipo + calcHoras + defaultText.inputs.horas);
            }
            else {
                calcMinutos = (calcMinutos % 60);
                return (dataTipoRecente.tipo + calcHoras + defaultText.inputs.horasE + calcMinutos + defaultText.inputs.minutos);
            }
        } else {
            return defaultText.inputs.pendenteDeConexao;
        }
    };

    const modalSelLoc = (e, i) => {
        switch (e[i]) {
            case 'abrindoNovaLoc':
                return [<AdicionarLoc />, modalSelLoc(e, i + 1)];
            case 'removeLoc':
                return [<RemoveLoc />, modalSelLoc(e, i + 1)];
            default:
                return;
        }
    };

    const atualizarLista = val => {
        //dispatch(listarOlts({ pagina: val }));
    };

    const labels = [
        { field: 'nomeLocal', text: defaultText.inputs.local },
        { field: 'descricaoLocal', text: defaultText.inputs.descricao },
        { field: 'ultimaData', text: defaultText.inputs.ultimaData },
    ];
    const buttons = ["checkList"];

    return (
        <div className='box-olt'>
            <h2>{defaultText.title}</h2>
            <BarraPesquisa />
            <CardList
                data={locais}
                buttons={buttons}
                labels={labels}
                headerTitle="nomeLocal"
                getCardActions={(val) => getLocalCardActions(val, idSelecionado)}
            />

            {/* <List data={{ head: listHead, content: locais }} listActions={val => listActions(val)} /> */}
            {/* A paginação foi desativada temporariamente até o backend fornecer os dados necessários */}
            {/* <Paginate data={paginacao} action={val => atualizarLista(val)} /> */}
            {statusDownload.status != 'downloadEmProgresso' ? (
                <div className='col-md-12 download'>
                    <div className="download-message">
                        <p>{defaultText.inputs.downloadAdapterMessage}</p>
                    </div>
                    <div className="download-buttons">
                        <Button
                            value='campoDownload'
                            titulo={defaultText.downloadWindows}
                            action={() => dispatch(downloadAdapterWindow('windows'))}
                            children={defaultText.downloadWindows}
                            color='primary'
                            type='btn'
                        />
                        <Button
                            value='campoDownload'
                            titulo={defaultText.downloadLinux}
                            action={() => dispatch(downloadAdapterWindow('linux'))}
                            children={defaultText.downloadLinux}
                            color='primary'
                            type='btn'
                        />
                    </div>

                </div>
            ) : (
                <Progress
                    text="Baixando adapter..."
                    type="progress-bar"
                    progressVale={statusDownload.progress}
                />
            )}
            {modalSelLoc(statusModal, 0)}
        </div>
    );
};