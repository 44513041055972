import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputInbox from '../../../../components/form/inputInBox/InputInbox';
import { atualizarConteudoServicoModal, clearServicoField } from '../../redux/ServicoActions';
import Checkbox from '../../../../components/form/checkbox/Checkbox';
import { validarCampoAction } from '../../../../validation/validationActions';
import Row from '../../../../components/row/row';
import { InputInboxSenha } from '../../../../components/form/inputInBox/InputInboxSenha';
import { MaskVlanId } from '../../../../components/mask/Mask';
import Input from '../../../../layouts/components/Input/Input';
import InputPassword from '../../../../layouts/components/Input/InputPassword';
import { handleOnKeyDown } from '../../../../utils/utils';
import { atualizarConteudoCpeModal } from '../../../cpe/redux/CpeActions';
import RadioButton from '../../../../components/form/radioButton/RadioButton';

export default function ConteudoDropdownContent({ dropdownState, mensagemErro }) {
    const dispatch = useDispatch();
    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../../nls/${locales}.json`);
    const servico = useSelector((state) => state.servicosState.servico);
    const cpe = useSelector((state) => state.cpesState.cpe);
    const cpeModal = useSelector((state) => state.cpesState.statusModal);

    const [pppoeRefs, setPppoeInputsRefs] = useState([
        useRef(),
        useRef()
    ]);
    return (
        <div name="advancedSettings" className={dropdownState && dropdownState.advancedSettings === true ? "expand-body" : "expand-body hide-expand-body"}>
            <p>Router PPPoE</p>
            <Input
                reference={pppoeRefs[0]}
                id="usuarioPPPoE"
                name='usuarioPPPoE'
                label={defaltText.inputs.usuarioPPPoE}
                value={cpeModal.includes('criando') ? cpe.usuarioPPPoE : servico.usuarioPPPoE}
                changeInput={e => cpeModal.includes('criando') ? dispatch(atualizarConteudoCpeModal(e)) : dispatch(atualizarConteudoServicoModal(e))}
                validate={{
                    action: e => dispatch(validarCampoAction(e)),
                    erro: mensagemErro.usuarioPPPoE ? defaltText.mensagem.obrigatorio : ''
                }}
                length={{
                    max: '25'
                }}
                clearField={{
                    action: (e) => dispatch(clearServicoField(e)),
                    text: defaltText.btn.limparCampo
                }}
                onKeyDown={(e) => handleOnKeyDown(e, pppoeRefs[0], pppoeRefs)}
            />

            <InputPassword
                reference={pppoeRefs[1]}
                id="senhaPPPoE"
                name='senhaPPPoE'
                label={defaltText.inputs.senhaPPPoE}
                value={cpeModal.includes('criando') ? cpe.senhaPPPoE : servico.senhaPPPoE}
                changeInput={e => cpeModal.includes('criando') ? dispatch(atualizarConteudoCpeModal(e)) : dispatch(atualizarConteudoServicoModal(e))}
                validate={{
                    action: e => dispatch(validarCampoAction(e)),
                    erro: mensagemErro.senhaPPPoE ? defaltText.mensagem.obrigatorio : ''
                }}
                length={{
                    max: '25'
                }}
                seeText={defaltText.btn.ver}
                onKeyDown={(e) => handleOnKeyDown(e, pppoeRefs[1], pppoeRefs)}
            />
        </div>
    );
}