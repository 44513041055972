import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import useOutsideClick from '../../../components/useOusideClick/useoutsideclick';
import { setModalAdvancedFilterCPE } from '../../../modulos/cpe/redux/CpeActions';
import { setModalAdvancedFilterService } from '../../../modulos/servicos/redux/ServicoActions';
import './AdvancedFilter.css';

const AdvancedFilter = ({ children, classAnchor }) => {
    const dispatch = useDispatch();
    const referencia = useRef();

    useOutsideClick(referencia, (e) => {
        dispatch(setModalAdvancedFilterService(''));
        dispatch(setModalAdvancedFilterCPE(''));
    });



    return (
        <div
            ref={referencia}
            className={`modal-search ${classAnchor}`}
        >
            {children}
        </div>
    );
};

export default AdvancedFilter;