import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../button/Button';
import { IcoArrowLeft, IcoArrowRight, IcoReload } from '../icones';
import './pagination.css';

const Paginate = ({ data, action }) => {

  const locales = useSelector(state => state.layoutState.linguagem);
  const defaltText = require(`./nls/${locales}.json`);

  //const [paginateTemp, setPaginateTemp] = useState(String(data.pageNumber ? data.pageNumber : 0 + 1))
  const [paginateTemp, setPaginateTemp] = useState(data.pageNumber + 1);

  useEffect(() => {
    setPaginateTemp(data.pageNumber + 1);
  }, [data]);

  const changePaginate = event => {
    setPaginateTemp(event.target.value);
  };

  const reloadPaginate = event => {
    if (event || event === 0) {
      if (event <= data.totalPages) {
        setPaginateTemp(event + 1);
        action(event);
      }
    } else if (paginateTemp >= 1 && paginateTemp <= data.totalPages) {
      action(Number(paginateTemp - 1));
    } else {
      setPaginateTemp(data.pageNumber + 1);
    }
  };

  const handlePressEnter = event => {
    if (event.key === 'Enter') {
      changePaginate(event);
      reloadPaginate();
    }
  };

  if (data.totalPages <= 1) {
    return <></>;
  } else {
    return (
      <div className='pagination-custom'>
        {/* <span className='pagination-info'>
          {`${data.pageNumber + 1} ${defaltText.labels.de} ${data.totalPages} ${defaltText.labels.paginas}`}
          {` - ${data.totalElements} ${defaltText.labels.itens}`}
        </span> */}
        <p>
          <span>{`${data.pageNumber + 1}`}</span>
          {` ${defaltText.labels.de} ${data.totalPages} ${defaltText.labels.paginas}`}
          {` - ${data.totalElements} ${defaltText.labels.itens}`}
        </p>
        <Button
          id='btnPrevious'
          titulo={defaltText.labels.anterior}
          action={() => reloadPaginate(data.pageNumber - 1)}
          color='secondary'
          type='btn circle'
          children={<IcoArrowLeft className='btn-pagina' title={defaltText.labels.anterior} />}
          disabled={data.pageNumber === 0}
          size='small'
        />

        <Button
          id='btnNext'
          titulo={defaltText.labels.proximo}
          action={() => reloadPaginate(data.pageNumber + 1)}
          color='secondary'
          type='btn circle'
          children={<IcoArrowRight className='btn-pagina' title={defaltText.labels.proximo} />}
          disabled={data.pageNumber >= data.totalPages - 1}
          size='small'
        />

        <div className='page-item'>
          <input type='number' name='paginate' placeholder={defaltText.labels.goPage} onChange={event => changePaginate(event)} onKeyPress={(event) => handlePressEnter(event)} />
          <Button
            titulo={defaltText.labels.go}
            action={() => reloadPaginate()}
            color='secondary'
            type='btn circle'
            children={defaltText.labels.go}
            size='small'
            id='go'
          />
        </div>
      </div>
    );
  }
};

export default Paginate;
