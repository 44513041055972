import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '../../../layouts/components/icons';
import Input from '../../../layouts/components/Input/Input';
import { IcoSearch } from '../../icones';
import Tooltip from '../../tooltip/Tooltip';

export const SelectInBox = ({ id, label, name, selectDefault, selectFilter, select, moreOptions, validate, position, disabled, title = "", oltMode, versionSoftware }) => {
    const listSelect = select.options;
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../../modulos/olt/nls/${locales}.json`)
    const [selectOpen, setSelectOpen] = useState(false);
    const [inputsSearch, setInputsSearch] = useState('');
    const olt = useSelector((state) => state.oltsState.olt);
    const lastSoftware = olt.modelo === "OLT_8820I" ? "2.106" : "V100R001B01D002P003SP6_intelbras"
    const [optionsFilter, setOptionsFilter] = useState([]);
    const [statusTooltip, setStatusTooltip] = useState(false);
    const [posicaoMessage, setPosicaoMessage] = useState({ top: 0, left: 0, right: 0, mensagem: null })
    const listVersionsAvailable = olt.modelo === "OLT_8820I" ? [lastSoftware] : [lastSoftware, "V100R001B01D002P003SP2_intelbras"];
    const versionSP2 = "V100R001B01D002P003SP2_intelbras"


    useEffect(() => {
        setOptionsFilter(listSelect);
    }, [listSelect]);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setSelectOpen(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    function optionModeCase(option) {
        //BLOCK SÓ AUTOSERVICO -> VLAN POR INTERFACE PON versionSoftware
        if ((oltMode === "AUTOSERVICE" && option.id === "VLANPORPON" && !(versionSoftware === lastSoftware) && !(versionSoftware === versionSP2))
            //BLOCK SÓ VLAN POR INTERFACE PON -> AUTOSERVICO
            || (oltMode === "VLANPORPON" && option.id === "AUTOSERVICE")
            //BLOCK MANUAL -> INTERFACEPON NAO SENDO ULTIMA VERSAO
            || ((oltMode === "MANUALLY" && option.id === "VLANPORPON") && !(listVersionsAvailable.some((version) => version === versionSoftware)))
            //BLOCK VLANPORPON -> MANUAL NAO SENDO ULTIMA VERSAO
            || ((oltMode === "VLANPORPON" && option.id === "MANUALLY") && !(listVersionsAvailable.some((version) => version === versionSoftware)))
        ) {
            return true;
        }
        return false;
    }
    function openTooltip(e, mensagem) {
        const pos = e.currentTarget.getBoundingClientRect()
        mensagem ? setStatusTooltip(true) : setStatusTooltip(false);
        setPosicaoMessage({ top: pos?.top - 400, left: e.clientX - pos?.left, right: pos?.right, mensagem: mensagem, width:'200px' })
    }
    function closeTooltip() {
        setStatusTooltip(false);
    }
    function tooltipMessage(opcao) {
        if (optionModeCase(opcao)) {
            return defaultText.informacoes.VLANPORPONDESATUALIZADA;
        } else if (optionModeCase(opcao) && oltMode === "AUTOSERVICE") {
            // setMessage(defaultText.informacoes.OPCAOSERVICOPARAVLAN)
            return defaultText.informacoes.OPCAOSERVICOPARAVLAN;
        } else if (optionModeCase(opcao) && oltMode === "VLANPORPON") {
            // setMessage(defaultText.informacoes.OPCAOVLANPARASERVICO)
            return defaultText.informacoes.OPCAOVLANPARASERVICO;
        }
        return null;
    }
    const searchAction = () => { };

    const apagar = () => {
        setInputsSearch('');
        setOptionsFilter(select.options);
    };

    const changeOption = option => {
        const InfoDefault = {
            target: {
                type: 'select',
                name: id,
                data: name,
                value: {}
            }
        };
        if (optionModeCase(option)) {
            //do nothing
        }
        else {
            InfoDefault.target.value = option;
            select.action(InfoDefault);
            setSelectOpen(false);
        }
    };

    document.getElementsByTagName('div')[0].focus();
    return (
        <>
            <Tooltip caso={1} estado={statusTooltip} posicaoMessage={posicaoMessage} />
            <div
                ref={wrapperRef}
                className={`box-form-select ${disabled ? "disabled" : ""} ${validate && validate.erro ? 'erro' : ''} ${!!selectOpen ? 'open' : ''}`}
            >
                <button title={title} type='button' disabled={disabled ? disabled : false} id={id} name={name} onClick={() => setSelectOpen(!selectOpen)}>
                    <div className="div-select-identification">
                        {select.selected && select.selected.resource && <div style={{ width: "22px", height: "22px" }}>{select.selected.resource}</div>}
                        {select.selected && select.selected[selectDefault.label]
                            ? <label>{`${label ? (label + ": ") : ""} ${select.selected[selectDefault.label]}`}</label>
                            : <label>{`${label ? (label + ": ") : ""} ${selectDefault.text}`}</label>
                        }
                    </div>
                    <div className={`div-select-icon ${selectOpen ? "rotate" : ""}`}>
                        <ChevronDownIcon />
                    </div>
                </button>
                <div
                    className={`select-options ${selectOpen ? "show" : "hidden"}`}
                >
                    {selectFilter ? (
                        <div className='select-search'>
                            <Input
                                id="search-select"
                                name='search-select'
                                placeholder={selectFilter.texto}
                                value={inputsSearch}
                                changeInput={e => setInputsSearch(e.target.value)}
                                clearField={{
                                    action: (e) => [apagar(), selectFilter.actionLimpar ? selectFilter.actionLimpar() : null],
                                    text: "Limpar campo"
                                }}
                                leftButton={{
                                    action: () => (selectFilter.action ? selectFilter.action(inputsSearch) : searchAction()),
                                    text: selectFilter.texto,
                                    icon: <IcoSearch />
                                }}
                            />
                        </div>
                    ) : null}
                    {selectDefault.text ? (
                        <div
                            className="select-option"
                            onClick={e => changeOption({})}
                        >
                            {selectDefault.text}
                        </div>
                    ) : null}

                    {optionsFilter.length
                        ? optionsFilter.map((opcao, index) => (
                            <div
                                id={opcao.nomeLocal}
                                key={opcao[selectDefault.id]}
                                onMouseOver={e => openTooltip(e, tooltipMessage(opcao))}
                                onMouseOut={_ => closeTooltip()}
                                className="select-option"
                                style={{
                                    cursor: optionModeCase(opcao) ? "not-allowed" : "pointer",
                                    opacity: optionModeCase(opcao) ? "0.5" : ""
                                }}
                                onClick={_ => changeOption(opcao)}
                                tabIndex={index}
                            >
                                {select.optionCustom ? select.optionCustom(opcao) : (
                                    <div className="div-select-identification">
                                        {opcao && opcao.resource && <div className="div-select-option-identification-img">{opcao.resource}</div>}
                                        <label style={{ cursor: optionModeCase(opcao) ? "not-allowed" : "pointer" }}>{opcao[selectDefault.label]}</label>
                                    </div>
                                )}
                            </div>
                        ))
                        : null}
                    {moreOptions &&
                        moreOptions.page &&
                        moreOptions.page.totalPages > 1 &&
                        moreOptions.page.pageNumber < moreOptions.page.totalPages - 1 ? (
                        <div
                            id="btnLoadMore"
                            className="select-option"
                            onClick={() => moreOptions.action(inputsSearch)}
                        >
                            <label>{moreOptions && moreOptions.texto}</label>
                        </div>
                    ) : null}
                </div>
                <div className="feedback">
                    {validate && validate.erro ? <div className='invalid-feedback'>{validate.erro}</div> : null}
                </div>
            </div>
        </>
    );
};
