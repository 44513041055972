import {
    ATUALIZAR_CAMPOS_LIMIARES,
    ATUALIZANDO_LIMIARES,
    ATUALIZANDO_TIPOS_ALARMES,
    TIPOS_ALARMES_ATUALIZADOS_COM_SUCESSO,
    SET_TIPO_MODAL,
    FECHAR_MODAL
} from './AlarmesActions'

const initialState = {
    alarmes: [],
    checkAtivo: true,
    checkUplink: false,
    checkONU: true,
    checkPotenciaSinal: false,
    limiares: {
        txMax: '',
        txMin: '',
        rxMax: '',
        rxMin: '',

    },
    statusModal: '',
    campoPesquisa: '',

};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_TIPO_MODAL:
            return { ...state, statusModal: payload };

        case FECHAR_MODAL:
            return { ...state, statusModal: "" };

        case ATUALIZAR_CAMPOS_LIMIARES:
            return {
                ...state,
                limiares: {
                    ...state.limiares,
                    [payload.target.name]: payload.target.value
                }
            };
        case ATUALIZANDO_LIMIARES:
            return { ...state }

        case ATUALIZANDO_TIPOS_ALARMES:
            return { ...state };

        case TIPOS_ALARMES_ATUALIZADOS_COM_SUCESSO:
            console.log('TIPOS DE ALARMES ATUALIZADO')
            if (state[payload.target.name] === true && payload.target.disabled == false) {
                return { ...state, [payload.target.name]: false };
            }
            else if (state[payload.target.name] === false && payload.target.disabled == false) {
                return { ...state, [payload.target.name]: true };
            }
            else {

            }
        default:
            return state;
    }
};
