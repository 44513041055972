import React from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { usuarioNaoLogadoSucesso } from '../login/redux/UserActions';

export default () => {
    const dispatch = useDispatch();
    axios.interceptors.request.use(
        config => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user.token) {
                config.headers.Authorization = `Bearer ${user.token}`;
            }
            return Promise.resolve(config);
        },
        error => {
            // Faça algo em caso de erro
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(function (response) {
        // Do something with response data
        if (response.status === 500 && response.message === "Access is denied") {
            dispatch(usuarioNaoLogadoSucesso());
        }
        return response;
    }, function (error) {
        // Do something with response error
        if (error.response.status === 500 && error.response.data.message === "Access is denied") {
            dispatch(usuarioNaoLogadoSucesso());
        }
        return Promise.reject(error);
    });

    return <></>;
};
