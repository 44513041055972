import { padding } from 'polished';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import { DownloadIcon, IcoMais, IcoRemoveLine, IcoSearch } from '../../components/icones';
import Progress from '../../components/progress/Progress';
import { abrirModalNovaLoc, abrirModalRemoveLoc, atualizarCampoPesquisa, atualizaSelect, baixarAdapter, downloadAdapterWindow, fecharModalNovaLoc, limparCampoPesquisa, listarLocais } from '../../modulos/locais/redux/LocaisActions';
import { getPagePermissions } from '../../utils/utils';
import Input from '../components/Input/Input';
import { abrirTooltip, fecharTooltip, setModalHeaderType } from '../redux/LayoutActions';

const LocalsContent = () => {

    const locales = useSelector(state => state.layoutState.linguagem);
    const locaisState = useSelector(state => state.locaisState);
    const dispatch = useDispatch();
    const defaultText = require(`../../modulos/locais/nls/${locales}.json`);
    const statusModal = useSelector(state => state.locaisState.statusModal);
    const campoPesquisa = useSelector(state => state.locaisState.campoPesquisa);
    const namePage = window.location.pathname.split('/')[1];
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const admin = useSelector((state) => state.authentication.user);
    const statusDownload = useSelector(state => state.locaisState.statusDownload);
    const locais = locaisState.locais.map(elem => {
        return { ...elem, status: 'OK' };
    });


    useEffect(() => {
        dispatch(listarLocais());
        dispatch(fecharModalNovaLoc());
    }, [dispatch]);

    const handleChangeLoc = (local) => {
        dispatch(atualizaSelect(local, namePage));
        localStorage.setItem('aplicacao', JSON.stringify(local));
    };

    const handleOnPressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(listarLocais({ pesquisa: campoPesquisa }));
        }
    };

    const mostrarTooltip = (e, local) => {
        const posicaoDoElemento = e.currentTarget.getBoundingClientRect();
        dispatch(abrirTooltip(posicaoDoElemento, local));
        // abrir tooltip com posicao e local
    };
    const communicationDate = (local) => {

        if (local.ultimaDataDadosProcessados !== null || local.ultimaDataLoginAdapter !== null || local.ultimaDataOrdensEscrita !== null || local.ultimaDataOrdensLeitura !== null) {
            const dataDadosProcessados = local.ultimaDataDadosProcessados !== null ? new Date(local.ultimaDataDadosProcessados.replace(/Z\[UTC]/, '')) : '';
            const dataLogin = local.ultimaDataLoginAdapter !== null ? new Date(local.ultimaDataLoginAdapter.replace(/Z\[UTC]/, '')) : '';
            const dataEscrita = local.ultimaDataOrdensEscrita !== null ? new Date(local.ultimaDataOrdensEscrita.replace(/Z\[UTC]/, '')) : '';
            const dataLeitura = local.ultimaDataOrdensLeitura !== null ? new Date(local.ultimaDataOrdensLeitura.replace(/Z\[UTC]/, '')) : '';
            const dataList = [dataDadosProcessados, dataLogin, dataEscrita, dataLeitura];
            var maxDate = new Date(Math.max(...dataList)); //maior data vinda do backend
            const dateNow = new Date();

            var timeZone = -(maxDate.getTimezoneOffset() / 60); // diferença de timezone
            const addHours = (hora, data) => new Date(data.getTime() + hora * 60 * 60 * 1000);

            return `${addHours(timeZone, maxDate).getHours()}:${(addHours(timeZone, maxDate).getMinutes() < 10 ? '0' : '') + maxDate.getMinutes()} ${defaultText.btn.day} ${maxDate.getDate()}/${maxDate.getMonth() + 1}`;
        } else {
            return defaultText.inputs.pendenteDeConexao;
        }

    };

    return (
        <div className='localModal'>
            <h6>{defaultText.pesquisar.text}</h6>
            <div className='searchBarModal'>
                <div className='searchLocal'>
                    <Input
                        id="pesquisar"
                        name='pesquisar'
                        label={defaultText.pesquisar.label}
                        value={campoPesquisa}
                        changeInput={e => dispatch(atualizarCampoPesquisa(e))}
                        onPressEnter={(e) => handleOnPressEnter(e)}
                        clearField={{
                            action: (e) => dispatch(limparCampoPesquisa(e)),
                            text: defaultText.btn.limparCampo
                        }}
                        leftButton={{
                            action: () => dispatch(listarLocais({ pesquisa: campoPesquisa })),
                            text: defaultText.btn.fechar,
                            icon: <IcoSearch title={defaultText.pesquisar.searchIcon} />
                        }}
                    />
                </div>
                <Button
                    id="btnAdicionar"
                    titulo={defaultText.pesquisar.novo}
                    action={() => dispatch(abrirModalNovaLoc())}
                    texto={defaultText.btn.addLocales}
                    children={<IcoMais title={defaultText.pesquisar.novo} />}
                    color='success'
                    type='btn'
                />
            </div>

            <div className='localContent'  >
                {locais.length > 0 && locais.map((local) => {
                    return (
                        <div className='localBox' >
                            <Button
                                onMouseOver={e => mostrarTooltip(e, local)}
                                onMouseOut={_ => dispatch(fecharTooltip())}
                                id='selectLocales'
                                value='selectLocales'
                                action={() => [handleChangeLoc(local), dispatch(setModalHeaderType(""), dispatch(fecharTooltip()))]}
                                texto={local['nomeLocal']}
                                children={[defaultText.btn.lastCommunication, communicationDate(local)]}
                                color='primary'
                                type='btn'
                            />
                            <Button
                                id='deleteLocales'
                                titulo={defaultText.btn.delete}
                                action={() => dispatch(abrirModalRemoveLoc(local))}
                                children={<IcoRemoveLine />}
                                color='danger'
                                type='btn'
                                disabled={(admin.email !== local.emailProprietario) ? true : false}
                            />
                        </div>
                    );
                })}

            </div>
            <span className='divisor'></span>
            <div className='downloadMessage'>
                <p>{defaultText.inputs.downloadAdapterMessage2}</p>
            </div>
            <div className='downloadBox'>
                <div>
                    <Button
                        id='btnDownload'
                        value='campoDownload'
                        titulo={defaultText.downloadWindows}
                        action={() => downloadAdapterWindow('windows')}
                        color='primary'
                        type='btn'
                        children={<DownloadIcon />}
                    />
                    <p>Windows</p>
                    {statusDownload.status === 'downloadEmProgresso' ?
                        <Progress
                            /* text="Baixando adapter..." */
                            type="progress-bar"
                            progressVale={statusDownload.progress}
                        /> : ''
                    }
                </div>
                {/* <div id='divisor'></div>
                <div>
                    <Button
                        id='btnDownload'
                        value='campoDownload'
                        titulo={defaultText.downloadLinux}
                        action={() => dispatch(baixarAdapter('linux'))}
                        type='btn'
                        children={<DownloadIcon />}
                    />
                    <p>Linux</p>
                </div> */}
            </div>
            
            <div className='downloadVersion'> 
                <p>{defaultText.inputs.lastVersionAdapter}4.1.1-ST</p>
            </div>
        </div >
    );
};

export default LocalsContent;