import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from '../../../components/modal/BasicModal';
import { deletarCpe } from '../../cpe/redux/CpeActions';
import { deletarOlt } from '../../olt/redux/OltActions';
import { deletarServico } from '../../servicos/redux/ServicoActions';
import { deletarNotificacao, fecharModalNotificacoes, listarNotificacoes } from '../redux/NotificacoesActions';

const Deletar = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const statusModal = useSelector(state => state.notificacoesState.statusModal);
    const notificacao = useSelector(state => state.notificacoesState.notificacao);
    const notificacoes = useSelector(state => state.notificacoesState.notificacoes);

    const paginaAtual = useSelector(state => state.notificacoesState.paginacao.pageNumber);

    const deletar = (notificacao) => {
        switch (notificacao.tipo) {
            case "SERVICO":
                return deletarNotificacao(deletarServico, notificacao, notificacoes.length > 1 ? paginaAtual : (paginaAtual > 0 ? paginaAtual - 1 : paginaAtual));
            case "OLT":
                return deletarNotificacao(deletarOlt, notificacao, notificacoes.length > 1 ? paginaAtual : (paginaAtual > 0 ? paginaAtual - 1 : paginaAtual));
            case "CPE":
                return deletarNotificacao(deletarCpe, notificacao, notificacoes.length > 1 ? paginaAtual : (paginaAtual > 0 ? paginaAtual - 1 : paginaAtual));
            default:
                return dispatch(fecharModalNotificacoes());
        }
    };

    const mensagemDaNotificacao = (tipo) => {
        switch (tipo) {
            case 'OLT':
                return <p>{defaultText.mensagem.mensagemDeletarOlt}</p>;
            case 'SERVICO':
                return <p>{defaultText.mensagem.mensagemDeletarServico}</p>;
            case 'CPE':
                return <p>{defaultText.mensagem.mensagemDeletarCpe}</p>;

        }

    };
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalNotificacoes())}
            containerModal={{
                title: `${defaultText.title} ` + `${notificacao.tipo}`,
                size: 'medium',
                content: (
                    <>
                        <div className='notificacao-erro'>
                            {mensagemDaNotificacao(notificacao.tipo)}
                        </div>
                    </>
                ),
                closeText: defaultText.btn.fechar,
                action: {
                    acao: () => [dispatch(deletarNotificacao(notificacao)), dispatch(fecharModalNotificacoes())],
                    texto: defaultText.btn.deletar,
                    class: "success"
                }
            }}
        />
    );
};

export default Deletar;