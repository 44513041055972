import React from 'react';

export function RefreshIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="53.1"
            viewBox="0 0 40 42.5"
            className={props.className ? props.className : ""}>
            <title>{props.title}</title>
            <path id="Icon_material-refresh" data-name="Icon material-refresh" d="M32.277,10.525a15.406,15.406,0,1,0,4,14.729h-4A11.547,11.547,0,1,1,21.4,9.851a11.388,11.388,0,0,1,8.125,3.427l-6.2,6.2H36.8V6Z" />
        </svg>
    );
}