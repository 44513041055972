import App from './App'
import { Provider, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import Store from './Store'
import ThemeProvider from './layouts/ThemeProvider';
ReactDOM.render(
  <Provider store={Store}>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
