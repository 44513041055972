import React from 'react';

export function AssinanteIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="39.829"
            viewBox="0 0 34 39.829"
            className={props.className ? props.className : ""}>
            <title>{props.title}</title>
            <path id="Icon_awesome-user" data-name="Icon awesome-user" d="M17,19.914a9.837,9.837,0,0,0,9.714-9.957A9.837,9.837,0,0,0,17,0,9.837,9.837,0,0,0,7.286,9.957,9.837,9.837,0,0,0,17,19.914ZM23.8,22.4H22.533a12.919,12.919,0,0,1-11.065,0H10.2A10.333,10.333,0,0,0,0,32.859v3.236a3.69,3.69,0,0,0,3.643,3.734H30.357A3.69,3.69,0,0,0,34,36.095V32.859A10.333,10.333,0,0,0,23.8,22.4Z" transform="translate(0)" />
        </svg>
    );
}