import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import InputInbox from '../../components/form/inputInBox/InputInbox'
import { IcoMais, IcoSearch } from '../../components/icones'

import Button from '../../components/button/Button'
import { abrirModalCriarPerfil, atualizarCampoPesquisa, limparCampoPesquisa, listarPerfis } from './redux/PerfilActions'
import Row from '../../components/row/row'

export default () => {
  const dispatch = useDispatch()

  const locales = useSelector(state => state.layoutState.linguagem)
  const defaltText = require(`./nls/${locales}.json`)
  const campoPesquisa = useSelector(state => state.perfisState.campoPesquisa)

  return (
    <div>
      <Row style={{ justifyContent: "space-between" }}>
        <div className="col-md-6 div-filters-button">
          <div className='campo-pesquisa'>
            <InputInbox
              name='pesquisar'
              label={defaltText.pesquisar.text}
              value={campoPesquisa}
              changeInput={e => dispatch(atualizarCampoPesquisa(e))}
              search={{
                action: e => dispatch(limparCampoPesquisa(e)),
                text: defaltText.btn.fechar
              }}
            />

            <Button
              titulo={defaltText.pesquisar.text}
              action={() => dispatch(listarPerfis({ pesquisa: campoPesquisa }))}
              texto={<IcoSearch title={defaltText.pesquisar.text} />}
              color='secondary'
              type='btn'
            />
          </div>
        </div>
        <div className="col-md-2 div-new-button">
          <Button
            titulo={defaltText.pesquisar.novo}
            action={() => dispatch(abrirModalCriarPerfil())}
            texto={<IcoMais title={defaltText.pesquisar.novo} />}
            color='success'
            type='btn circle'
          />
        </div>
      </Row>
    </div>
  )
}
