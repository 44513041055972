import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from '../../../components/modal/BasicModal';
import { fecharModal, updateOltFirmware } from '../redux/OltActions';

const UpdateFirmware = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const olt = useSelector(state => state.oltsState.olt);
    const statusModal = useSelector(state => state.oltsState.statusModal);

    const ContentConfirmUpdateModal = () => {
        let message = defaltText.mensagem.atualizarFirmware.split(" ");
        let index = message.indexOf("OLT");
        return (
            <>
                <p>{message.slice(0, index + 1).join(" ")} <span style={{ fontWeight: "900" }}>{olt.nome}</span> {message.slice(index + 1).join(" ")}</p>
            </>
        );
    };

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModal())}
            containerModal={{
                title: defaltText.atualizarFirmware,
                size: 'medium',
                content: ContentConfirmUpdateModal(),
                closeText: defaltText.btn.cancelar,
                action: {
                    acao: () => dispatch(updateOltFirmware(olt)),
                    texto: defaltText.btn.confirmarAtualizacao,
                    class: "success"
                }
            }}
        />
    );
};

export default UpdateFirmware;