import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicModal from '../../../../components/modal/BasicModal';
import { fecharModal, salvarConfig } from '../../redux/OltActions';

const ExibirModalConfirmar = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../../nls/${locales}.json`);

    const olt = useSelector(state => state.oltsState.olt);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const idOlt = window.location.pathname.split('/')[3];
    const ContentConfirmUpdateModal = () => {
        return (
            <>
                <p>{defaultText.mensagem.confirmarSalvarConfig}
                    <span style={{ fontWeight: "bold" }}> {olt.nome}?</span>
                </p>
            </>
        );
    };

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModal())}
            containerModal={{
                title: defaultText.salvarConfig,
                size: 'medium',
                content: ContentConfirmUpdateModal(),
                closeText: defaultText.btn.cancelar,
                action: {
                    acao: () => dispatch(salvarConfig(idOlt)),
                    texto: defaultText.btn.salvar,
                    class: "success"
                }
            }}
        />
    );
};

export default ExibirModalConfirmar;