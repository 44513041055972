import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/button/Button';
import { SelectInBox } from '../../../components/form';
import { IcoRemoveLine } from '../../../components/icones';
import { resetarCamposAction, validarCampoAction } from '../../../validation/validationActions';
import { atualizarConteudoPlanoModal, abrirModalDeletarServicoOuConfiguracao, resetarCamposPlano, clearPlanoField, resetarCamposModais, listarDba } from '../redux/PlanosActions';
import { listarOltsOk } from '../../olt/redux/OltActions';
import Input from '../../../layouts/components/Input/Input';
import Table from '../../../layouts/components/Table/Table';
import ConteudoModalBridge from './bridge/conteudoModalBridge.js';
import ConteudoPPPoEModal from './pppoe/conteudoModalPPPoE';
import { maskNomePlano } from '../../../components/mask/Mask';

const ConteudoCriarEditarPlano = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const plano = useSelector((state) => state.planosState.plano);
    const title = plano.id ? defaultText.tituloModalEditar : defaultText.tituloModalNovo;
    const statusModal = useSelector((state) => state.planosState.statusModal);
    const mensagemErro = useSelector(state => state.validacaoState);
    const olts = useSelector(state => state.oltsState.olts);
    const paginacaoOlt = useSelector(state => state.oltsState.paginacao);


    const serviceTypes = useSelector((state) => state.planosState.serviceTypes);
    const configurationOptions = useSelector((state) => state.planosState.configurationOptions);

    const quantidadePorPagina = 3;
    useEffect(() => {
        dispatch(listarOltsOk({ quantidadePorPagina }));
        return () => dispatch(resetarCamposPlano());
    }, [dispatch]);

    /* 
    
    TABELA ANTIGA
 
    const listHead = [
 
         {
             colunm: 'servico',
             text: defaultText.inputs.servicos,
             className: 'text-center table-id',
         },
         {
             colunm: 'indexVlan',
             text: defaultText.inputs.vlan,
             className: 'text-center',
         },
         {
             colunm: 'tipoServico',
             text: defaultText.inputs.tipo,
             className: 'text-center',
         },
         {
             colunm: 'actions',
             text: defaultText.btn.acoes,
             className: 'actions',
         },
     ]; */

    const labels = [
        { text: defaultText.inputs.vlan, field: 'indexVlan' },
        { text: defaultText.inputs.tipo, field: 'tipoServico' },
        { text: defaultText.btn.acoes, field: 'actions' },
    ];

    const listActions = (val) => {
        switch (val.item) {
            case 'actions':
                return (
                    <>
                        <Button
                            id="btnRemoverPlan"
                            titulo={defaultText.btn.deletar}
                            action={() => dispatch(abrirModalDeletarServicoOuConfiguracao(val.itens))}
                            //action={() => dispatch(deletarServicoOuConfiguracao(val.itens))}
                            children={<IcoRemoveLine title={defaultText.btn.remover} />}
                            color="success"
                            type='btn circle'
                        />
                    </>
                );
            default:
                return;
        }
    };

    return (
        <div className='modalPlanoNovo'>
            <div className='planCreate'>
                <div>
                    <Input
                        id="nome"
                        name='nome'
                        label={defaultText.inputs.nome}
                        value={plano.nome}
                        changeInput={e => [
                            e.target.value = maskNomePlano(e.target.value),
                            dispatch(atualizarConteudoPlanoModal(e))
                        ]}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.nome ? defaultText.mensagem.obrigatorio : ''
                        }}
                        clearField={{
                            action: (e) => dispatch(clearPlanoField(e)),
                            text: defaultText.btn.limparCampo
                        }}
                        length={{
                            max: '80'
                        }}
                    />
                </div>

                <div>
                    <SelectInBox
                        id='olt'
                        label={defaultText.inputs.olt}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            minWidth: '100%',
                        }}
                        validate={{
                            erro: mensagemErro.olt ? defaultText.mensagem.obrigatorio : ''
                        }}
                        selectFilter={{
                            action: e =>
                                dispatch(
                                    listarOltsOk({
                                        pesquisa: e,
                                        quantidadePorPagina
                                    })
                                ),
                            actionLimpar: () => dispatch(listarOltsOk({ quantidadePorPagina })),
                            texto: defaultText.select.pesquisar.texto,
                            limpar: defaultText.select.pesquisar.limpar
                        }}

                        select={{
                            action: e => dispatch(atualizarConteudoPlanoModal(e)),
                            options: olts.filter(olt => olt.status === "OK"),

                            optionCustom: e => (
                                <>
                                    <p>{e.nome}</p>
                                </>
                            ),
                            selected: plano.olt ? plano.olt : { id: "selecione", nome: defaultText.select.selecionado.nenhum }
                        }}
                        moreOptions={{
                            page: paginacaoOlt,
                            action: e =>
                                dispatch(
                                    listarOltsOk({
                                        pagina: paginacaoOlt.pageNumber + 1,
                                        pesquisa: e,
                                        quantidadePorPagina
                                    })
                                ),
                            texto: defaultText.btn.maisItens

                        }}
                        position="absolute"
                    />
                </div>



                <div>
                    <SelectInBox
                        id='serviceType'
                        label={defaultText.inputs.tipoServico}
                        selectDefault={{
                            id: 'id',
                            label: 'serviceType',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.serviceType ? defaultText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => [dispatch(atualizarConteudoPlanoModal(e)), dispatch(resetarCamposAction(), dispatch(resetarCamposModais())), dispatch(listarDba({ idOlt: plano.olt.id, pagina: 0, qtdRegistros: 10 }))],
                            options: serviceTypes.filter((service) => service.quantidade < service.quantidadeMaxima),
                            selected: plano.serviceType
                        }}
                        disabled={plano.olt ? false : true}
                    />

                </div>

                {/* <div className='col-md-8 flex-start'>
                    <Button
                        id="btnAdicionarServico"
                        titulo={defaultText.btn.adicionar}
                        action={() => dispatch(abrirModalServicoPlano(plano))}
                        children={defaultText.btn.adicionar}
                        color='primary'
                        type='btn'
                    />
                </div> */}
            </div>

            {/* Colocar de volta quando o WiFi estiver disponível */}
            {/* <div>
                    <div className='col-md-4'>
                        <SelectInBox
                            id='configuration'
                            label={defaultText.inputs.configuracoes}
                            selectDefault={{
                                id: 'id',
                                label: 'configurationName',
                                text: defaultText.select.selecionado.nenhum
                            }}
                            validate={{
                                erro: mensagemErro.configuration ? defaultText.mensagem.obrigatorio : ''
                            }}
                            select={{
                                action: e => dispatch(atualizarConteudoPlanoModal(e)),
                                options: configurationOptions.filter((configuration) => configuration.quantidade < configuration.quantidadeMaxima),
                                selected: plano.configuration
                            }}
                        />
                    </div>

                    <div className='col-md-8 flex-start'>
                        <Button
                            id="btnAdicionarConfiguracao"
                            titulo={defaultText.btn.adicionar}
                            action={() => dispatch(abrirModalConfiguracaoPlano(plano))}
                            texto={defaultText.btn.adicionar}
                            color='primary'
                            type='btn'
                        />
                    </div>
                </div> */}

            {/* <div>
                    <div className='col-md-12 flex-end'>
                        <Button
                            id="btnSalvarPlano"
                            titulo={defaultText.btn.salvar}
                            action={() => dispatch(adicionarPlano(plano))}
                            children={defaultText.btn.salvar}
                            color='primary'
                            type='btn'
                        />
                        <Button
                            id="btnCancelar"
                            titulo={defaultText.btn.cancelar}
                            action={() => [dispatch(resetarCamposPlano()), history.goBack()]}
                            children={defaultText.btn.cancelar}
                            color='primary'
                            type='btn'
                        />
                    </div>
                </div> */}

            <div className='advancedConfig'>
                <ConteudoPPPoEModal visivel={(plano.serviceType.serviceType === "PPPOE") ? true : false} />
                <ConteudoModalBridge visivel={(plano.serviceType.serviceType === "BRIDGE") ? true : false} />
            </div>

            <div className='planTable'>
                <div className={`col-md-12 invalid-feedback ${mensagemErro && mensagemErro.listaConfiguracoes ? "show-feedback" : "hide-feedback"}`}>{defaultText.mensagem.adicionePeloMenosUmServicoOuConfiguracao}</div>
                {/* <List
                        data={{ head: listHead, content: plano.listaConfiguracoes }}
                        listActions={(val) => listActions(val)}
                    /> */}
                <Table
                    itens={plano.listaConfiguracoes}
                    labels={labels}
                    listActions={(val) => listActions(val)}
                />
            </div>
        </div>
    );
};

export default ConteudoCriarEditarPlano;