import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import maskList from './MaskList.js';
/* const dispatch = useDispatch();
 */

export function MaskTel(valor) {
  let matrix = '+###############';

  maskList.forEach(item => {
    let code = item.code.replace(/[\s#]/g, ''),
      phone = valor.replace(/[\s#-)(]/g, '');

    if (phone.includes(code)) {
      console.log(phone, code);
      matrix = item.code;
    }
  });

  let i = 0,
    val = valor.replace(/\D/g, '');

  return matrix.replace(/(?!\+)./g, function (a) {
    return /[#\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a;
  });
}

export function MaskTelefone(valor) {
  //valor = valor || ''
  valor = valor.replace(/\D/g, "");
  valor = valor.replace(/^0/, "");
  if (valor.length > 10) {
    valor = valor.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1)$2-$3');
  } else if (valor.length > 6) {
    valor = valor.replace(/^(\d\d)(\d{4})(\d{0,4})/, "($1)$2-$3");
  } else if (valor.length > 2) {
    valor = valor.replace(/^(\d\d)(\d{0,4})/, "($1)$2");
  } else if (valor.lenght !== 1) {
    valor = valor.replace(/^(\d)/, "($1");
  }
  return valor;
}
export function RemoveMaskTelefone(valor) {

  valor = valor.replace(/\D/g, '');
  return valor;
}

export const MaskItemCep = valor => {
  let valueNew = '';
  valueNew = valor.replace(/\D/g, '').replace(/(\d{5})(\d{3}).*/, '$1-$2');
  return valueNew;
};
let last;

export const MaskItemIdentificador = valor => {
  let valueNew = '';
  if (valor.length <= 4) {
    valueNew = valor.replace(/[^A-Z0-9-]+/g, "");
    if (valor.length === 4 && last < valor.length) {
      valueNew = valueNew + '-'
    }
  }
  else if (valor.length <= 5) {
    valueNew = valor.replace(/(\w{4})(\w{1})/, '$1-$2');
  }
  else if (valor.length > 5) {
    let part = valor.substring(0, 4);
    valueNew = part + valor.substring(4).replace(/[^A-Fa-f0-9-]+/, "");
  }
  else {
    valueNew = valor.replace(/(\w{})(\w{})/, '$1$2');
  }
  last = valor.length
  return valueNew;
};

export const MaskItemDescricaoCpe = valor => {
  let valueNew = '';
  if (valor != null && valor.length > 0) {
    valueNew = valor.replace(/[^A-Za-z0-9-_\s]+/gm, '');
  } else {
    valueNew = ''
  }
  return valueNew;
};

export function RemoveMaskCep(valor) {

  valor = valor.replace(/\D/g, '');
  return valor;
}

export const MaskData = valor => {
  let valueNew = '';
  valueNew = valor
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2');
  return valueNew;
};

export const AjustData = (d, l) => {
  const date = new Date(d);
  switch (l) {
    case "en-US":
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} - ${date.getHours()}:${date.getMinutes()}`;

    default:
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`;
  }
};

export const MaskValor = valor => {
  valor = valor || '';
  let valueNew = '';
  valueNew = valor
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2');
  return valueNew;
};

export const MaskValorMoeda = valor => {
  valor = valor || '';
  let valueNew = '';
  valueNew = valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  return valueNew;
};

export const MaskCpfCnpj = valor => {

  //Remove tudo o que não é dígito
  valor = valor.replace(/\D/g, "");

  if (valor.length < 12) { //CPF
    valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  } else { //CNPJ

    valor = valor.replace(/^(\d{2})(\d)/, "$1.$2");
    valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    valor = valor.replace(/\.(\d{3})(\d)/, ".$1/$2");
    valor = valor.replace(/(\d{4})(\d)/, "$1-$2");
  }
  return valor;
};


export const maskIp = (valor) => {
  if ((/^[0-9.]*$/).test(valor)) {
    return valor;
  } else {
    let newValor = valor.substr(0, (valor.length - 1));
    /*     dispatch(atualizarConteudoOltModal(); */
    return newValor;
  }
};

export function RemoveMaskCpfCnpj(valor) {

  valor = valor.replace(/\D/g, '');
  return valor;
}

export const MaskVlanId = valor => {
  valor = valor || '';
  const regex = /^0?([1-9]\d{0,2}|[123]\d{3}|40[0-8][0-9]|409[0-4])$/;
  if (regex.test(valor)) {
    return valor;
  } else {
    return valor.substr(0, (valor.length - 1));
  }
};

export function MaskNome(valor) {
  return valor = valor.replace(/[^abcdefghijklmnopqrstuvwxyzçABCDEFGHIJKLMNOPQRSTUVWXYZÇáÁéÉíÍóÓuÚ\s]/g, "");
}

export function maskNomePlano(valor) {
  return valor = valor.replace(/[^A-Za-z0-9-_ ]*/g, "");
}