import React from 'react';
import { resetarCamposAction, validarAction, validarCamposAction, validarIp } from '../../../validation/validationActions';

import axios from 'axios';
import { toast } from 'react-toastify';
import { IcoInfo } from '../../../components/icones';
import history from '../../../history';
import { CorrectIcon } from '../../../layouts/components/icons';
import { checkResponseErrorType } from '../../../utils/utils';

const idiomaText = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userLocales = user.preferencia.linguagem;
    const locales = userLocales ? (userLocales === 'EN_US' ? 'en-US' : 'pt-BR') : null;
    const defaltText = require(`../nls/${locales ? locales : 'pt-BR'}.json`);
    return defaltText.mensagem;
};
export const FETCH_OLT_DASHBOARD = "FETCH_OLT_DASHBOARD";
const fetchOltDashboard = data => ({
    type: FETCH_OLT_DASHBOARD,
    payload: data
});
export const ATUALIZAR_CONTEUDO_OLT_MODAL = 'ATUALIZAR_CONTEUDO_OLT_MODAL';
export const atualizarConteudoOltModal = (evento) => ({
    type: ATUALIZAR_CONTEUDO_OLT_MODAL,
    payload: evento,
});

export const SET_TIPO_MODAL = 'olt.SET_TIPO_MODAL';
const setTipoModal = (tipo) => ({
    type: SET_TIPO_MODAL,
    payload: tipo,
});

export const SET_OLT = 'SET_OLT';
export const setOlt = (olt) => ({
    type: SET_OLT,
    payload: olt,
});

export const SET_OLT_INICIAL = 'SET_OLT_INICIAL';
const setOltInicial = () => ({
    type: SET_OLT_INICIAL,
});

export const SET_VLAN_INICIAL = 'SET_VLAN_INICIAL';
const setVlanInicial = () => ({
    type: SET_VLAN_INICIAL,
});

export const SET_OLT_E_INTERFACE = 'SET_OLT_E_INTERFACE';
export const setOltEInterface = (OlteInterface) => ({
    type: SET_OLT_E_INTERFACE,
    payload: OlteInterface
});

export const SET_MODE_TAG_ACCESS = 'SET_MODE_TAG_ACCESS';
export const setModeTagAccess = () => ({
    type: SET_MODE_TAG_ACCESS
});

export const ABRIR_MODAL_EDITAR_OLT = 'ABRIR_MODAL_EDITAR_OLT';
export const abrirModalEditarOlt = (olt) => [resetarCamposAction(), setOlt(olt), setTipoModal('editando')];

export const ABRIR_MODAL_DELETAR_OLT = 'ABRIR_MODAL_DELETAR_OLT';
export const abrirModalDeletarOlt = (olt) => [setOlt(olt), setTipoModal('deletando')];

export const ABRIR_MODAL_CONFIRMA_DELETAR = 'ABRIR_MODAL_CONFIRMA_DELETAR';
export const abrirModalConfirmaDeletar = (olt) => [setOlt(olt), setTipoModal('confirmaDeletar')];

export const ABRIR_MODAL_ERRO_OLT = 'ABRIR_MODAL_ERRO_OLT';
export const abrirModalErroOlt = (olt) => [setOlt(olt), setTipoModal('erro')];

export const ABRIR_MODAL_CRIAR_OLT = 'ABRIR_MODAL_CRIAR_OLT';
export const abrirModalCriarOlt = () => [resetarCamposAction(), setOltInicial(), setTipoModal('criando')];

export const abrirModalCriarVlan = (oltInterface) => [setTipoModal('abrindoCriarVlan')];

export const abrirModalEditarVlan = (vlan) => [setVlan(vlan), setTipoModal('editar'), setInterface(vlan)];

export const atualizarVlan = (modelo, vlan, nomeInterface, vlans, caso) => {

    const vlanJson = vlan;

    const vlanVal = {
        formRequired: caso === "editar" ? descricaoVlanRequired : vlanRequired,
        formValues: vlanJson,
    };

    return (dispatch) => {

        const mensagemDeErro = validarCamposAction(vlanVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalVlan());
        dispatch(atualizandoVlan());
        axios
            .put('/api/vlan', vlanJson)
            .then((response) => {
                dispatch(vlanAtualizadaComSucesso(response.data));
                toast.success((idiomaText().atualizarVlanSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(vlanNaoAtualizada(erro));
                checkResponseErrorType(erro, idiomaText().atualizarVlanErro);
            });
    };
};

export const SET_VLAN = "SET_VLAN";
export const setVlan = (vlan) => ({
    type: SET_VLAN,
    payload: vlan
});

export const FECHAR_MODAL_OLT = 'FECHAR_MODAL_OLT';
export const fecharModalOlt = () => [setOltInicial(), setTipoModal(''), handleAcceptedModeChangeTermsState(false)];
export const fecharModal = () => [setTipoModal('')];

export const FECHAR_SEGUNDA_MODAL = 'FECHAR_SEGUNDA_MODAL';
export const fecharSegundaModal = (statusModal) => {
    if (statusModal.includes('confirmaDeletar')) {
        return [{
            type: FECHAR_SEGUNDA_MODAL
        }, setOltInicial()];
    } else {
        return ({
            type: FECHAR_SEGUNDA_MODAL
        });
    }
};

export const fecharModalVlan = () => [setVlanInicial(), setTipoModal('')];

export const LIMPAR_CAMPO_PESQUISA_OLT = 'LIMPAR_CAMPO_PESQUISA_OLT';
export const limparCampoPesquisa = () => [
    {
        type: LIMPAR_CAMPO_PESQUISA_OLT,
    },
    /* listarOlts(), */
];

export const ATUALIZAR_CAMPO_PESQUISA_OLT = 'ATUALIZAR_CAMPO_PESQUISA_OLT';
export const atualizarCampoPesquisa = (e) => ({
    type: ATUALIZAR_CAMPO_PESQUISA_OLT,
    payload: e.target.value,
});


export const ATUALIZAR_CAMPOS_VLAN = 'ATUALIZAR_CAMPOS_VLAN';
export const atualizarCamposVlan = (e, nomePorta) => ({
    type: ATUALIZAR_CAMPOS_VLAN,
    payload: {
        e,
        nomePorta
    }
});


export const CARREGANDO_OLTS = 'CARREGANDO_OLTS';
const carregandoOlts = () => ({
    type: CARREGANDO_OLTS,
});

export const OLTS_CARREGADOS_COM_SUCESSO = 'OLTS_CARREGADOS_COM_SUCESSO';
const oltsCarregadosComSucesso = (olts) => ({
    type: OLTS_CARREGADOS_COM_SUCESSO,
    payload: olts,
});

export const OLTS_CARREGADOS_COM_ERRO = 'OLTS_CARREGADOS_COM_ERRO';
const oltsCarregadosComErro = (erro) => ({
    type: OLTS_CARREGADOS_COM_ERRO,
    payload: erro,
});

export const VLANS_CARREGADAS_COM_ERRO = 'VLANS_CARREGADAS_COM_ERRO';
const vlanCarregadasComErro = (erro) => ({
    type: VLANS_CARREGADAS_COM_ERRO,
    payload: erro,
});

export const listarOlts = (e) => {
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        pesquisa: `&nome=${e && e.pesquisa ? e.pesquisa : ''}`
    };
    return (dispatch) => {
        dispatch(handleIsLoadingOltStatus(true));
        dispatch(carregandoOlts());
        axios
            .get(`/api/olt?${params.pagina + params.pesquisa}`)
            .then((response) => {
                e && e.quantidadePorPagina && e.pagina >= 1
                    ? dispatch(maisOltsCarregadosComSucesso(response.data))
                    : dispatch(oltsCarregadosComSucesso(response.data));

            })
            .catch((error) => {
                dispatch(oltsCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
            });

    };
};

export const listarOltsOk = (e) => {
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        pesquisa: `&nome=${e && e.pesquisa ? e.pesquisa : ''}`,
        quantidadePorPagina: `&quantidadePorPagina= ${e && e.quantidadePorPagina ? e.quantidadePorPagina : '10'}`
    };
    return (dispatch) => {
        dispatch(carregandoOlts());
        axios
            .get(`/api/olt/ok?${params.pagina + params.pesquisa + params.quantidadePorPagina}`)
            .then((response) => {
                e && e.quantidadePorPagina && e.pagina >= 1
                    ? dispatch(maisOltsCarregadosComSucesso(response.data))
                    : dispatch(oltsCarregadosComSucesso(response.data));
            }
            )

            .catch((error) => {
                dispatch(oltsCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
            });
    };
};



export const listarOlt = (idOlt) => {
    return (dispatch) => {
        dispatch(handleIsLoadingOltStatus(true));
        dispatch(carregandoOlts());
        axios
            .get(`/api/olt/${idOlt}`)
            .then((response) => {
                dispatch(oltCarregadaComSucesso(response.data));
            })
            .catch((error) => {
                dispatch(oltCarregadaComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
                dispatch(handleIsLoadingOltStatus(true));
            });
    };
};

/* 
export const listarInterfaces = (idOlt, e) => {
  //Colocar código para requisitar ao back a lista de vlans cadastradas
  return (dispatch) => {
    dispatch(carregandoOlts())
    axios
      .get(`/api/olt/${idOlt}/${e}`)
      .then((response) => dispatch(vlansCarregadasComSucesso(response.data)))
      .catch((error) => {
        dispatch(vlanCarregadasComErro(error))
        toast.error('Erro ao carregar vlans')
      })
  }
} */

export const listarVlans = (oltInterface) => {
    return (dispatch) => {
        dispatch(carregandoOlts());
        axios
            .get(`/api/vlan?idOlt=${oltInterface.idOlt}&portaNome=${oltInterface.nome}`)
            .then((response) => {
                dispatch(vlansCarregadasComSucesso(response.data));
            })
            .catch((error) => {
                dispatch(vlanCarregadasComErro(error));
                checkResponseErrorType(error, idiomaText().listarVlansError);
                dispatch(handleIsLoadingOltStatus(false));
            });

    };

};

export const VLANS_CARREGADAS_COM_SUCESSO = "VLANS_CARREGADAS_COM_SUCESSO";
export const vlansCarregadasComSucesso = (vlans) => ({
    type: VLANS_CARREGADAS_COM_SUCESSO,
    payload: vlans
});

export const ATUALIZAR_VLAN_COM_SUCESSO = "ATUALIZAR_VLAN_COM_SUCESSO";
export const atualizarVlanComSucesso = (vlan) => ({
    type: ATUALIZAR_VLAN_COM_SUCESSO,
    payload: vlan
});


export const OLT_CARREGADA_COM_SUCESSO = "OLT_CARREGADA_COM_SUCESSO";
export const oltCarregadaComSucesso = (olt) => ({
    type: OLT_CARREGADA_COM_SUCESSO,
    payload: olt
});

export const OLT_CARREGADA_COM_ERRO = "OLT_CARREGADA_COM_ERRO";
export const oltCarregadaComErro = (erro) => ({
    type: OLT_CARREGADA_COM_ERRO
});

export const CARREGANDO_CPES = 'CARREGANDO_CPES';
const carregandoCpes = () => ({
    type: CARREGANDO_OLTS,
});

export const CPES_CARREGADOS_COM_SUCESSO = 'CPES_CARREGADOS_COM_SUCESSO';
const cpesCarregadosComSuceso = (cpes) => ({
    type: CPES_CARREGADOS_COM_SUCESSO,
    payload: cpes,
});

export const CPES_CARREGADOS_COM_ERRO = 'CPES_CARREGADOS_COM_ERRO';
const cpesCarregadosComErro = (erro) => ({
    type: CPES_CARREGADOS_COM_ERRO,
    payload: erro,
});

export const MAIS_CPES_CARREGADOS_COM_SUCESSO = 'MAIS_CPES_CARREGADOS_COM_SUCESSO';
const maisCpesCarregadosComSuceso = (cpes) => ({
    type: MAIS_CPES_CARREGADOS_COM_SUCESSO,
    payload: cpes,
});

export const MAIS_OLTS_CARREGADOS_COM_SUCESSO = 'MAIS_OLTS_CARREGADOS_COM_SUCESSO';
const maisOltsCarregadosComSucesso = (olts) => ({
    type: MAIS_OLTS_CARREGADOS_COM_SUCESSO,
    payload: olts,
});


export const listarCpes = (e) => {
    const params = {
        pagina: `pagina=${e.pagina ? e.pagina : '0'}`,
        olt: `&idOlt=${e.olt ? e.olt : ''}`,
        identificador: `&identificador=${e.identificador ? e.identificador : ''}`,
    };

    return (dispatch) => {
        dispatch(carregandoCpes());
        axios
            .get(`/api/cpe?${params.pagina + params.olt + params.identificador}`)
            .then((response) =>
                e.pagina >= 1
                    ? dispatch(maisCpesCarregadosComSuceso(response.data))
                    : dispatch(cpesCarregadosComSuceso(response.data))
            )
            .catch((error) => {
                dispatch(cpesCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarCpesErro);
            });
    };
};

export const ADICIONANDO_OLT = 'ADICIONANDO_OLT';
const adicionandoOlt = () => ({
    type: ADICIONANDO_OLT,
});

export const ADICIONANDO_VLAN = 'ADICIONANDO_VLAN';
const adicionandoVlan = () => ({
    type: ADICIONANDO_VLAN,
});

export const OLT_ADICIONADO_COM_SUCESSO = 'OLT_ADICIONADO_COM_SUCESSO';
const oltAdicionadoComSucesso = (olt) => ({
    type: OLT_ADICIONADO_COM_SUCESSO,
    payload: olt,
});

export const VLAN_ADICIONADA_COM_SUCESSO = 'VLAN_ADICIONADA_COM_SUCESSO';
const vlanAdicionadaComSucesso = (vlan) => ({
    type: VLAN_ADICIONADA_COM_SUCESSO,
    payload: vlan,
});

export const OLT_NAO_ADICIONADO = 'OLT_NAO_ADICIONADO';
const oltNaoAdicionado = (erro) => ({
    type: OLT_NAO_ADICIONADO,
    payload: erro,
});

export const VLAN_NAO_ADICIONADA = 'VLAN_NAO_ADICIONADA';
const vlanNaoAdicionada = (erro) => ({
    type: VLAN_NAO_ADICIONADA,
    payload: erro,

});

const oltRequired = {
    nome: '',
    ip: 'ip',
    //chave para veririfcar se ao menos um protocolo foi checkado
    erroProtocolo: '',
    // chave para, caso telnet seja checkado, quais os campos a serem verificados
    telnetChecked: ['telnetUser', 'telnetPassword', 'telnetPort'],
    sshChecked: ['sshUser', 'sshPassword', 'sshPort'],
    snmpChecked: ['snmpReadingCommunity', 'snmpWritingCommunity', 'snmpPort'],
    //Caso o IndexVlan da olt venha vazio
    indexVlan: ''
};

export const adicionarOlt = (olt, aplicacao, page) => {
    const oltJson = olt;
    delete oltJson.operationMode;

    const oltPatternRequired = {
        ip: "ip"
    };

    const oltVal = {
        formPatternRequired: oltPatternRequired,
        formRequired: oltRequired,
        formValues: oltJson,
    };
    return (dispatch) => {
        if (!aplicacao.statusSincronizacao) {
            toast.error((idiomaText().adapterDesconectado), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
        } else {
            let mensagemDeErro = validarCamposAction(oltVal);
            if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
            mensagemDeErro = validarIp(oltJson.ip);
            if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
            dispatch(adicionandoOlt());
            dispatch(fecharModalOlt());
            axios
                .post('/api/olt', oltJson)
                .then((response) => {
                    //dispatch(oltAdicionadoComSucesso(response.data));
                    dispatch(listarOlts({ pagina: page }));
                    toast.success((idiomaText().adicionarSuccess), {
                        icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                    });
                })
                .catch((erro) => {
                    dispatch(oltNaoAdicionado(erro));
                    checkResponseErrorType(erro, idiomaText().adicionarErro);
                });
        }
    };
};

const vlanRequired = {
    numeroVlan: ''
};

const descricaoVlanRequired = {
    descricao: ''
};

export const adicionarVlan = (modelo, vlan, nomeInterface, vlans, caso) => {

    const vlanJson = vlan;
    delete vlanJson.operationMode;

    const vlanVal = {
        formRequired: vlanRequired,
        formValues: vlanJson,
    };

    return (dispatch) => {
        const mensagemDeErro = validarCamposAction(vlanVal);

        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        const allVlans = vlans.utVlans.concat(vlans.tagVlans)
        const filteredByNumero = allVlans.find((obj) => obj?.numeroVlan === vlan.numeroVlan);
        const filteredByUplink = allVlans.find((obj) => obj?.type === "uplink");
        const filteredByTls = allVlans.find((obj) => obj?.type === "tls");
        dispatch(fecharModalVlan());
        dispatch(adicionandoVlan());
        const numeroVlan = parseInt(vlanJson.numeroVlan)
        if ((modelo === "OLT_8820I") && (numeroVlan === 1 || numeroVlan === 7 || (numeroVlan >= 128 && numeroVlan <= 159))) {
            toast.error((idiomaText().adicionarVlanReservada), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
        } else if (modelo === "OLT_8820I" && vlan.untaggedVlan && vlans.utVlans?.length > 0) {
            toast.error((idiomaText().adicionarVlanUntagged8820i), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
        } else if ((modelo === "OLT_8820I") && vlan.numeroVlan === filteredByNumero?.numeroVlan) {
            toast.error((idiomaText().adicionarVlan8820iErro), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
        } else if (modelo === "OLT_8820I" && filteredByUplink && vlan?.type === "intralink") {
            toast.error((idiomaText().adicionarIntralinkErro), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
        } else if (modelo === "OLT_8820I" && (filteredByUplink || filteredByTls) && vlan?.type === "intralink") {
            toast.error((idiomaText().adicionarUplinkErro), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
        } else {
            axios
                .post('/api/vlan', vlanJson)
                .then((response) => {
                    if (response.data === '') {
                        toast.error((idiomaText().adicionarVlanErro), {
                            icon: <div className='divIconToastError'><IcoInfo /></div>
                        });
                    } else {
                        dispatch(atualizarDadosDaInterface(nomeInterface));
                        toast.success((idiomaText().adicionarVlanSuccess), {
                            icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                        });
                    }
                })
                .catch((erro) => {
                    dispatch(vlanNaoAdicionada(erro));
                    checkResponseErrorType(erro, idiomaText().adicionarVlanErro);
                });
        }
    };
};

export const ATUALIZANDO_OLT = 'ATUALIZANDO_OLT';
const atualizandoOlt = () => ({
    type: ATUALIZANDO_OLT,
});

export const ATUALIZANDO_VLAN = 'ATUALIZANDO_VLAN';
const atualizandoVlan = () => ({
    type: ATUALIZANDO_VLAN,
});

export const VLAN_ATUALIZADA_COM_SUCESSO = 'VLAN_ATUALIZADA_COM_SUCESSO';
const vlanAtualizadaComSucesso = (vlan) => ({
    type: VLAN_ATUALIZADA_COM_SUCESSO,
    payload: vlan,
});

export const VLAN_NAO_ATUALIZADA = 'VLAN_NAO_ATUALIZADA';
const vlanNaoAtualizada = (erro) => ({
    type: VLAN_NAO_ATUALIZADA,
    payload: erro,
});
export const MODO_INTERFACE_ALTERADO_COM_SUCESSO = 'MODO_INTERFACE_ALTERADO_COM_SUCESSO';
const modoInterfaceAlteradoComSucesso = (interfaceOlt) => ({
    type: MODO_INTERFACE_ALTERADO_COM_SUCESSO,
    payload: interfaceOlt,
});
export const MODO_INTERFACE_ALTERADO_COM_ERRO = 'MODO_INTERFACE_ALTERADO_COM_ERRO';
const modoInterfaceAlteradoComErro = (erro) => ({
    type: MODO_INTERFACE_ALTERADO_COM_ERRO,
    payload: erro,
});

export const OLT_ATUALIZADO_COM_SUCESSO = 'OLT_ATUALIZADO_COM_SUCESSO';
const oltAtualizadoComSucesso = (olt) => ({
    type: OLT_ATUALIZADO_COM_SUCESSO,
    payload: olt,
});

export const OLT_NAO_ATUALIZADO = 'OLT_NAO_ATUALIZADO';
const oltNaoAtualizado = (erro) => ({
    type: OLT_NAO_ATUALIZADO,
    payload: erro,
});
export const atualizarOlt = (olt, aplicacao, page) => {
    let oltJson = olt;

    const oltVal = {
        //formRequired: olt.status === "OK" ? { ...oltRequired, operationMode: {} } : { ...oltRequired },
        formRequired: oltRequired,
        formValues: oltJson,
    };
    return (dispatch) => {
        if (!aplicacao.statusSincronizacao) {
            toast.error(("É necessario estar com o adapter conectado para realizar essa operação."), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
        } else {
            const mensagemDeErro = validarCamposAction(oltVal);
            if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
            if (!(olt.operationMode?.id === "VLANPORPON" || olt.operationMode?.id === "AUTOSERVICE")) {
                dispatch(fecharModalOlt());
            }
            dispatch(atualizandoOlt());

            oltJson = {
                ...oltJson,
                operationMode: oltJson.operationMode ? oltJson.operationMode.id : null,
                indexVlan: olt.indexVlan ? olt.indexVlan : "1000",
                configCustomVlanPorPon: oltJson.operationMode?.id === "VLANPORPON" ? oltJson.configCustomVlanPorPon : null
            };

            axios
                .put('/api/olt', oltJson)
                .then((response) => {
                    //dispatch(oltAtualizadoComSucesso(response.data))
                    dispatch(listarOlts({ pagina: page }));
                    toast.success((idiomaText().atualizarSuccess), {
                        icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                    });
                })
                .catch((erro) => {
                    dispatch(oltNaoAtualizado(erro));
                    checkResponseErrorType(erro, idiomaText().atualizarErro);
                });
        }
    };
};

export const DELETANDO_OLT = 'DELETANDO_OLT';
const deletandoOlt = () => ({
    type: DELETANDO_OLT,
});

export const OLT_DELETADO_COM_SUCESSO = 'OLT_DELETADO_COM_SUCESSO';
const oltDeletadoComSucesso = (olt) => ({
    type: OLT_DELETADO_COM_SUCESSO,
    payload: olt,
});

export const OLT_NAO_DELETADO = 'OLT_NAO_DELETADO';
const oltNaoDeletado = (erro) => ({
    type: OLT_NAO_DELETADO,
    payload: erro,
});

export const deletarOlt = (olt, page) => {
    return (dispatch) => {
        dispatch(fecharModalOlt());
        dispatch(deletandoOlt());
        axios
            .delete(`/api/olt/${olt.id}`)
            .then(() => {
                //dispatch(oltDeletadoComSucesso(olt.id));
                //dispatch(listarOlts({ pagina: page }));
                toast.success((idiomaText().deletarSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(oltNaoDeletado(erro));
                checkResponseErrorType(erro, idiomaText().deletarErro);
            });
    };
};

export const abrirModalReiniciarOlt = (olt) => (setOlt(olt), setTipoModal('modalReiniciarOLT'));
export const abrirModalConfirmarReiniciar = (olt) => (setOlt(olt), setTipoModal('modalConfirmaReiniciar'));
export const abrirModalInfoUtilizacaoOlt = (olt) => (setOlt(olt), setTipoModal('modalInfoUtilizacaoOlt'));
export const abrirModalConfirmarSalvar = (olt) => (setOlt(olt), setTipoModal('modalSalvarConfig'));
export const fecharModalReiniciarOlt = () => (setOltInicial(), setTipoModal(''));

export const REINICIANDO_OLT = 'REINICIANDO_OLT';
const reiniciandoOlt = () => ({
    type: REINICIANDO_OLT,
});

export const OLT_REINICIADO_COM_SUCESSO = 'OLT_REINICIADO_COM_SUCESSO';
const oltReiniciadaComSucesso = (olt) => ({
    type: OLT_REINICIADO_COM_SUCESSO,
    payload: olt,
});

export const OLTS_ATUALIZADAS_COM_SUCESSO = 'OLTS_ATUALIZADAS_COM_SUCESSO';
export const oltsAtualizadasComSucesso = (olt) => ({
    type: OLTS_ATUALIZADAS_COM_SUCESSO,
    payload: olt,
});

export const OLT_NAO_REINICIADA = 'OLT_NAO_REINICIADA';
const oltNaoReiniciada = (erro) => ({
    type: OLT_NAO_REINICIADA,
    payload: erro,
});

export const reiniciarOlt = (olt) => {
    return (dispatch) => {
        dispatch(fecharModalReiniciarOlt());
        dispatch(reiniciandoOlt());
        axios
            .post("/api/olt/reiniciar", olt)
            .then(() => {
                toast.success((idiomaText().reiniciarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
                history.push("/olts");
            })
            .catch((erro) => {
                dispatch(oltNaoReiniciada(erro));
                checkResponseErrorType(erro, idiomaText().reiniciarErro);
            });
    };
};
export const salvarConfig = (idOlt) => {
    return (dispatch) => {
        dispatch(fecharModal());
        axios
            .put(`/api/olt/salvarConfig?idOlt=${idOlt}`)
            .then(() => {
                toast.success((idiomaText().salvarConfigSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
                history.push("/olts");
            })
            .catch((erro) => {
                checkResponseErrorType(erro, idiomaText().salvarConfigErro);
            });
    };
};

export const reenviarOlt = (olt, page) => {
    return (dispatch) => {
        dispatch(fecharTodasModal());
        dispatch(reiniciandoOlt());
        axios
            .post('/api/olt/reenviar', olt)
            .then(() => {
                dispatch(listarOlts({ pagina: page }));
                toast.success((idiomaText().reenviarSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(oltNaoReiniciada(erro));
                checkResponseErrorType(erro, idiomaText().reenviarErro);
            });
    };
};

export const ATUALIZAR_OPERATION_MODE_OLT = "ATUALIZAR_OPERATION_MODE_OLT";
export const atualizarOperationMode = operationMode => ({
    type: ATUALIZAR_OPERATION_MODE_OLT,
    payload: operationMode
});

export const abrirModalSFPInfo = (oltInterface) => [resetarCamposAction(), setInterface(oltInterface), setTipoModal('exibindoModalSFP')];

export const SET_INTERFACE = "SET_INTERFACE";
export const setInterface = (oltInterface) => ({
    type: SET_INTERFACE,
    payload: oltInterface
});

export const SET_MODO_ESCOLHIDO_INTERFACE = "SET_MODO_ESCOLHIDO_INTERFACE";
export const setModoEscolhidoInterface = (modoEscolhido) => ([{
    type: SET_MODO_ESCOLHIDO_INTERFACE,
    payload: modoEscolhido
}, abrirModalAlterarModoInterface()]);


export const fecharModalSFPInfo = () => [setSFPInicial(), setTipoModal('')];

export const SET_SFP_INICIAL = "SET_SFP_INICIAL";
export const setSFPInicial = () => ({
    type: SET_SFP_INICIAL
});

export const obterInterfaceDaOlt = (interfaceName, idOlt) => {
    return (dispatch) => {
        dispatch(handleIsLoadingVlansStatus(true));
        axios
            .get(`/api/olt/vlan?idOlt=${idOlt}&nomeInterface=${interfaceName}`)
            .then((response) => {
                dispatch(listarVlans({ ...response.data, idOlt }));
                dispatch(setInterface({ ...response.data, idOlt }));
                toast.success((idiomaText().obterVlansSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(vlansDaInterfaceCarregadasComErro(erro));
                checkResponseErrorType(erro, idiomaText().obterVlansError);
            });
    };
};

export const VLANS_DA_INTERFACE_CARREGADAS_COM_SUCESSO = "VLANS_DA_INTERFACE_CARREGADAS_COM_SUCESSO";
export const vlansDaInterfaceCarregadasComSucesso = (vlans) => ({
    type: VLANS_DA_INTERFACE_CARREGADAS_COM_SUCESSO,
    payload: vlans
});
export const LIMPAR_VLANS_DA_INTERFACE = "LIMPAR_VLANS_DA_INTERFACE";
export const limparVlansDaInterface = () => ({
    type: LIMPAR_VLANS_DA_INTERFACE
});

export const VLANS_DA_INTERFACE_CARREGADAS_COM_ERRO = "VLANS_DA_INTERFACE_CARREGADAS_COM_ERRO";
export const vlansDaInterfaceCarregadasComErro = (erro) => ({
    type: VLANS_DA_INTERFACE_CARREGADAS_COM_ERRO,
    payload: erro
});

export const obterSFPDaInterface = (interfaceName, oltId) => {
    return (dispatch) => {
        axios
            .get(`/api/olt/sfp?idOlt=${oltId}&nomeInterface=${interfaceName.nomeInterface}`)
            .then((response) => {
                dispatch(sfpDaInterfaceCarregadaComSucesso(response.data));
                toast.success((idiomaText().obterSFPSucess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(sfpDaInterfaceCarregadaComErro(erro));
                checkResponseErrorType(erro, idiomaText().obterSFPError);
            });
    };
};

export const SFP_DA_INTERFACE_CARREGADA_COM_ERRO = "SFP_DA_INTERFACE_CARREGADA_COM_ERRO";
export const sfpDaInterfaceCarregadaComErro = () => ({
    type: SFP_DA_INTERFACE_CARREGADA_COM_ERRO,
});

export const SFP_DA_INTERFACE_CARREGADA_COM_SUCESSO = "SFP_DA_INTERFACE_CARREGADA_COM_SUCESSO";
export const sfpDaInterfaceCarregadaComSucesso = (sfp) => ({
    type: SFP_DA_INTERFACE_CARREGADA_COM_SUCESSO,
    payload: sfp
});

export const obterInterfacesDaOlt = (oltId) => {
    return (dispatch) => {
        axios
            .post('/api/olt/obterInterfacesDaOlt', oltId)
            .then((response) => {
                dispatch(interfacesCarregadasComSucesso(response.data));
                toast.success((idiomaText().obterInterfacesSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                dispatch(interfacesCarregadasComErro(erro));
                checkResponseErrorType(erro, idiomaText().obterInterfacesError);
            });
    };
};

export const INTERFACES_CARREGADAS_COM_ERRO = "INTERFACES_CARREGADAS_COM_ERRO";
export const interfacesCarregadasComErro = () => ({
    type: INTERFACES_CARREGADAS_COM_ERRO,
});

export const INTERFACES_CARREGADAS_COM_SUCESSO = "INTERFACES_CARREGADAS_COM_SUCESSO";
export const interfacesCarregadasComSucesso = (interfaces) => ({
    type: INTERFACES_CARREGADAS_COM_SUCESSO,
    payload: interfaces
});

export const ABRIR_MODAL_DELETAR_INTERFACE = 'ABRIR_MODAL_DELETAR_INTERFACE';
export const abrirModalDeletarInterface = () => [
    setTipoModalInterface('deletar'),
];

export const ABRIR_MODAL_EDITAR_INTERFACE = 'ABRIR_MODAL_EDITAR_INTERFACE';
export const abrirModalEditarInterface = () => [
    setTipoModalInterface('editar'),
];
export const abrirModalAlterarModoInterface = () => [
    setTipoModal('alterarModoInterface'),
];


export const FECHAR_MODAL_DELETAR_INTERFACE = 'FECHAR_MODAL_DELETAR_INTERFACE';
export const fecharModalInterface = () => [setTipoModal('')];

export const SET_TIPO_MODAL_INTERFACE = 'SET_TIPO_MODAL_INTERFACE';
const setTipoModalInterface = (tipo) => {
    return (dispatch) => {
        dispatch({ type: SET_TIPO_MODAL, payload: tipo });
    };
};

export const DELETANDO_VLAN_INTERFACE = 'DELETANDO_VLAN_INTERFACE';
const deletandoVlanInterface = () => ({
    type: DELETANDO_VLAN_INTERFACE,
});

export const VLAN_INTERFACE_DELETADO_COM_SUCESSO = 'VLAN_INTERFACE_DELETADO_COM_SUCESSO';
const vlanInterfaceDeletadoComSucesso = (vlan) => ({
    type: VLAN_INTERFACE_DELETADO_COM_SUCESSO,
    payload: vlan
});

export const VLAN_INTERFACE_NAO_DELETADO = 'VLAN_INTERFACE_NAO_DELETADO';
const vlanInterfaceNaoDeletado = (erro) => ({
    type: VLAN_INTERFACE_NAO_DELETADO,
    payload: erro,
});

export const deletarVlanInterface = (olt, vlans, vlan, nomeInterface) => {
    return (dispatch) => {
        dispatch(fecharModalInterface());
        dispatch(deletandoVlanInterface());
        if (vlan !== undefined && vlan !== null) {
            const pvid = (vlans.utVlans?.length > 0 && !(olt.modelo === "OLT_8820I")) ? vlans.utVlans[0].numeroVlan : -1
            //se for untagged(nao for tagged) e é a vlan 1, não deletar
            if (vlan.numeroVlan === "1" && !vlan.taggedVlan) {
                toast.error((idiomaText().deletarUtVlan), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
            }
            else if (pvid === vlan.numeroVlan && vlan.taggedVlan) {
                toast.error((idiomaText().deletarVlanPVID), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
            }
            else {
                axios
                    .delete(`/api/vlan?idVlan=${vlan.id}&idOlt=${vlan.idOlt}`)
                    .then((response) => {
                        dispatch(atualizarDadosDaInterface(nomeInterface));
                        toast.success((idiomaText().deletarVlanSuccess), {
                            icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                        });
                    })
                    .catch((erro) => {
                        dispatch(vlanInterfaceNaoDeletado(erro));
                        checkResponseErrorType(erro, idiomaText().deletarVlanErro);
                    });
            }
        }
        else {
            toast.error((idiomaText().deletarVlanErroBanco), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
        }
    };
};

export const alterarModoInterface = (modo) => {
    return (dispatch) => {
        axios
            .post(`/api/olt/porta?idOlt=${modo.idOlt}&portaNome=${modo.nome}&modo=${modo.mode}`)
            .then((response) => {
                //dispatch(modoInterfaceAlteradoComSucesso(response.data));
                toast.success((idiomaText().alterarModoInterfaceSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((erro) => {
                //dispatch(modoInterfaceAlteradoComErro(erro));
                checkResponseErrorType(erro, idiomaText().alterarModoInterfaceError);
            });
    };

};

export const SET_SELECTED_VLAN = "SET_SELECTED_VLAN";
export const setSelectedVlan = (vlan) => ({
    type: SET_SELECTED_VLAN,
    payload: vlan
});

const atualizarDadosDaInterface = (nomeInterface) => {

    return (dispatch) => {
        const idOlt = window.location.pathname.split('/')[3];
        dispatch(obterInterfaceDaOlt(nomeInterface, idOlt));
    };
};

export const RESET_OLT_STATE = "RESET_OLT_STATE";
export const resetOltState = () => ({
    type: RESET_OLT_STATE,
});

export const HANDLE_IS_LOADING_OLTS_STATUS = "HANDLE_IS_LOADING_OLTS_STATUS";
export const handleIsLoadingOltStatus = (status) => ({
    type: HANDLE_IS_LOADING_OLTS_STATUS,
    payload: status
});

export const HANDLE_IS_LOADING_VLANS_STATUS = "HANDLE_IS_LOADING_VLANS_STATUS";
export const handleIsLoadingVlansStatus = (status) => ({
    type: HANDLE_IS_LOADING_VLANS_STATUS,
    payload: status
});

export const listarCpesNaoProvisionadas = (e) => {
    const params = {
        pagina: `pagina=${e.pagina ? e.pagina : '0'}`,
        olt: `&idOlt=${e.olt ? e.olt : ''}`,
        qtdRegistros: `&qtdRegistros=${e.qtdRegistros ? e.qtdRegistros : '3'}`,
        identificador: `&identificador=${e.identificador ? e.identificador : ''}`
    };


    return (dispatch) => {
        dispatch(carregandoCpes());
        axios
            .get(`/api/cpe/listarcpesnaoprov?${params.pagina + params.qtdRegistros + params.olt + params.identificador}`)
            .then((response) =>
                e.pagina >= 1
                    ? dispatch(maisCpesCarregadosComSuceso(response.data))
                    : dispatch(cpesCarregadosComSuceso(response.data))
            )
            .catch((error) => {
                dispatch(cpesCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarCpesErro);
            });
    };
};

export const openConfirmUpdateModal = (olt) => [resetarCamposAction(), setOlt(olt), setTipoModal('atualizandoFirmware')];

export const updateOltFirmware = (e) => {
    return (dispatch) => {
        dispatch(carregandoCpes());
        console.log(e);
        axios
            .put('/api/atualizarOltFirmwareCronos', e)
            .then((response) => {
                /* Setar mensagens de erro e sucesso nos modelos novos */
                toast.success((idiomaText().atualizarFirmwareSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
                history.push(`/olts`);
            })
            .catch((error) => {
                /* Setar mensagens de erro e sucesso nos modelos novos */
                toast.error((idiomaText().atualizarFirmwareError), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
            });
    };
};

export const limparConfigsModoDeOperacao = (idOlt) => {
    return (dispatch) => {
        axios
            .put(`/api/olt/limparConfigsModoDeOperacao?idOlt=${idOlt}`)
            .then((response) => {
                /* Setar mensagens de erro e sucesso nos modelos novos */
                toast.success((idiomaText().continuarModoDeOperacaoProgresso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((error) => {
                /* Setar mensagens de erro e sucesso nos modelos novos */
                toast.error((idiomaText().continuarModoDeOperacaoProgressoErro), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
            });
    };
};
export const CLEAR_CRIAR_EDITAR_OLT_FIELD = "CLEAR_CRIAR_EDITAR_OLT_FIELD";
export const clearCriarEditarOltField = (e) => ({
    type: CLEAR_CRIAR_EDITAR_OLT_FIELD,
    payload: e
});

export const RESET_ACCEPTED_MODE_CHANGE_TERMS = "RESET_ACCEPTED_MODE_CHANGE_TERMS";
export const resetAcceptedModeChangeTerms = () => ({
    type: RESET_ACCEPTED_MODE_CHANGE_TERMS
});

export const HANDLE_ACCEPTED_MODE_CHANGE_TERMS_STATE = "HANDLE_ACCEPTED_MODE_CHANGE_TERMS_STATE";
export const handleAcceptedModeChangeTermsState = () => ({
    type: HANDLE_ACCEPTED_MODE_CHANGE_TERMS_STATE
});

export const IS_SEARCH = 'olt.IS_SEARCH';
export const isSearch = () => ({
    type: IS_SEARCH
});

export const setTipoModalAdapter = () => [
    setTipoModal('modalAdapter')
];
export const setTipoModalWarning = () => [
    setTipoModal('modalWarning')
];

export const fecharModalAdapter = () => [setTipoModal(''), localStorage.setItem('firstLogin', false)];


//Remover após encontrar solução do travamento do websocket do adapter
export const sendSyncRequest = () => {
    return (dispatch) => {
        axios
            .post('/api/sincronizar-escrita')
            .then((response) => {
                toast.success((idiomaText().syncSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((error) => {
                toast.error(idiomaText().syncError);
            });
        toast.success((idiomaText().syncSuccess), {
            icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
        });
        console.log('SINCRONIZOU');
    };
};
export const fetchOltStatus = (cpe) => {

    return (dispatch) => {
        dispatch(carregandoOlts());
        axios
            .get('/api/olt/dashboard/fetchOltStatus')
            .then((response) => {
                dispatch(fetchOltDashboard(response.data));
            })
            .catch((error) => {
                dispatch(oltsCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
            });
    };
};
export const FECHAR_TODAS_MODAL = 'olt.FECHAR_TODAS_MODAL';
export const fecharTodasModal = (e) => ({
    type: FECHAR_TODAS_MODAL,
    payload: e
});