/* import React, { useState } from 'react';
import "./input.css";
import { LockIcon, SearchIcon, } from '../icons';
import { IcoVer, IcoVerNao } from '../../../components/icones';

const InputPassword = ({ id, value, onChange, label }) => {
    const [textVisible, setTextVisible] = useState(false);
    return (
        <div className="input-container">
            <label htmlFor={id} className="input-left-icon">
                <LockIcon />
            </label>
            <input
                value={value}
                id={id}
                type={textVisible ? "text" : "password"}
                className="input-field"
            />
            <label htmlFor={id}>{label}</label>
            <div className="input-right-icon">
                <button className="input-btn" onClick={() => setTextVisible(!textVisible)}>{textVisible ? <IcoVer /> : <IcoVerNao />}</button>
            </div>
        </div>
    );
};

export default InputPassword; */

import React, { useState } from 'react';
import { IcoVer, IcoVerNao } from '../../../components/icones';

export default ({
    label = '',
    name = '',
    id = '',
    value = '',
    changeInput,
    validate,
    length,
    seeText = 'Visível',
    onPressEnter = () => { },
    disabled,
    fieldIcon,
    reference,
    onKeyDown = () => { }
}) => {
    const [verSenha, setVerSenha] = useState(false);
    return (
        <div className={`box-form ${disabled ? "disabled" : ""} ${value ? 'filled' : ''} ${validate && validate.erro ? 'erro' : ''}`}>
            {fieldIcon && fieldIcon.icon && (
                <button name={name} type='button' title={fieldIcon.text} className='field-left-icon'>
                    {fieldIcon.icon}
                </button>
            )}
            <input
                ref={reference}
                disabled={disabled ? disabled : false}
                autoComplete="new-password"
                onKeyPress={onPressEnter}
                type={verSenha ? 'text' : 'password'}
                name={name}
                value={value}
                onChange={changeInput}
                id={id}
                required={!!validate}
                onBlur={validate ? validate.action : null}
                pattern={validate ? validate.pattern : null}
                maxLength={length ? length.max : null}
                minLength={length ? length.min : null}
                placeholder={label && label}
                onKeyDown={onKeyDown}
            />
            {/* <label htmlFor={id}>
                {validate ? <sup className='text-danger'>✻ </sup> : ''}
                {label}
            </label> */}
            <span></span>
            <button
                type='button'
                onClick={e => {
                    e.preventDefault();
                    setVerSenha(!verSenha);
                }}
                title={seeText}
                className='bt-input'
            >
                {verSenha ? <IcoVerNao title={seeText} /> : <IcoVer title={seeText} />}
            </button>
            <div className={`invalid-feedback ${validate && validate.erro && "show-feedback"}`}>{validate && validate.erro ? validate.erro : ""}</div>
        </div>
    );
};
