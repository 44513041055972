import React from 'react';
import { resetarCamposAction, validarAction, validarCamposAction } from '../../../validation/validationActions';
import history from '../../../history';

import axios from 'axios';
import { toast } from 'react-toastify';
import { checkResponseErrorType } from '../../../utils/utils';
import { CorrectIcon } from '../../../layouts/components/icons';

const idiomaText = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userLocales = user.preferencia.linguagem;
    const locales = userLocales ? (userLocales === 'EN_US' ? 'en-US' : 'pt-BR') : null;
    const defaltText = require(`../nls/${locales ? locales : 'pt-BR'}.json`);
    return defaltText.mensagem;
};

export const ATUALIZAR_CONTEUDO_PLANO_MODAL = 'ATUALIZAR_CONTEUDO_PLANO_MODAL';
export const atualizarConteudoPlanoModal = evento => ({
    type: ATUALIZAR_CONTEUDO_PLANO_MODAL,
    payload: evento
});

export const ATUALIZAR_CONTEUDO_SERVICOS_PLANO_MODAL = 'ATUALIZAR_CONTEUDO_SERVICOS_PLANO_MODAL';
export const atualizarConteudoServicosPlanoModal = evento => ({
    type: ATUALIZAR_CONTEUDO_SERVICOS_PLANO_MODAL,
    payload: evento
});

export const SET_TIPO_MODAL_PLANO = 'SET_TIPO_MODAL_PLANO';
const setTipoModalPlano = tipo => ({
    type: SET_TIPO_MODAL_PLANO,
    payload: tipo
});

export const SET_PLANO = 'SET_PLANO';
const setPlano = plano => ({
    type: SET_PLANO,
    payload: plano
});

export const SET_PLANO_CONFIG = 'SET_PLANO_CONFIG';
const setPlanoConfig = plano => ({
    type: SET_PLANO_CONFIG,
    payload: plano
});

export const SET_PLANO_INICIAL = 'SET_PLANO_INICIAL';
export const setPlanoInicial = () => ({
    type: SET_PLANO_INICIAL
});

export const ABRIR_MODAL_EDITAR_PLANO = 'ABRIR_MODAL_EDITAR_PLANO';
export const abrirModalEditarPlano = plano => [resetarCamposAction(), setPlano(plano), setTipoModalPlano('editando')];

export const ABRIR_MODAL_VINCULAR_PLANO = 'ABRIR_MODAL_VINCULAR_PLANO';
export const abrirModalVincularPlano = plano => [
    resetarCamposAction(),
    setPlano(plano),
    setTipoModalPlano('vinculando')
];

export const ABRIR_MODAL_DELETAR_PLANO = 'ABRIR_MODAL_DELETAR_PLANO';
export const abrirModalDeletarPlano = plano => [setPlano(plano), setTipoModalPlano('deletando')];

export const ABRIR_MODAL_CRIAR_PLANO = 'ABRIR_MODAL_CRIAR_PLANO';
export const abrirModalCriarPlano = () => [resetarCamposAction(), setPlanoInicial(), setTipoModalPlano('criando')];

export const FECHAR_MODAL_PLANO = 'FECHAR_MODAL_PLANO';
export const fecharModalPlano = () => [setPlanoInicial(), setTipoModalPlano(''), resetarCamposAction(), resetarCamposPlano()];

export const fecharModalPortasLan = () => [setTipoModalPlano(''), setPlanoConfig([])];

export const LIMPAR_CAMPO_PESQUISA_PLANO = 'LIMPAR_CAMPO_PESQUISA_PLANO';
export const limparCampoPesquisa = () => [
    {
        type: LIMPAR_CAMPO_PESQUISA_PLANO
    },
    /* listarPlanos() */
];

export const ATUALIZAR_CAMPO_PESQUISA_PLANO = 'ATUALIZAR_CAMPO_PESQUISA_PLANO';
export const atualizarCampoPesquisa = e => ({
    type: ATUALIZAR_CAMPO_PESQUISA_PLANO,
    payload: e.target.value
});

export const CARREGANDO_PLANOS = 'CARREGANDO_PLANOS';
const carregandoPlanos = () => ({
    type: CARREGANDO_PLANOS
});

export const PLANOS_CARREGADOS_COM_SUCESSO = 'PLANOS_CARREGADOS_COM_SUCESSO';
const planosCarregadosComSuceso = planos => ({
    type: PLANOS_CARREGADOS_COM_SUCESSO,
    payload: planos
});

export const PLANOS_CARREGADOS_COM_ERRO = 'PLANOS_CARREGADOS_COM_ERRO';
const planosCarregadosComErro = erro => ({
    type: PLANOS_CARREGADOS_COM_ERRO,
    payload: erro
});

export const listarPlanos = e => {
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        pesquisa: `&nome=${e && e.pesquisa ? e.pesquisa : ''}`,
        idCpe: `&idCpe=${e && e.cpe ? e.cpe : ''}`
    };
    return dispatch => {
        dispatch(handleIsLoadingPlansStatus(true));
        dispatch(carregandoPlanos());
        axios
            .get(`/api/plano?${params.pagina + params.pesquisa + params.idCpe}`)
            .then(response => {
                dispatch(planosCarregadosComSuceso(response.data));
            })
            .catch(error => {
                dispatch(planosCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarPlanosError);
                dispatch(handleIsLoadingPlansStatus(false));
            });
    };
};

export const ADICIONANDO_PLANO = 'ADICIONANDO_PLANO';
const adicionandoPlano = () => ({
    type: ADICIONANDO_PLANO
});

export const PLANO_ADICIONADO_COM_SUCESSO = 'PLANO_ADICIONADO_COM_SUCESSO';
const planoAdicionadoComSucesso = plano => ({
    type: PLANO_ADICIONADO_COM_SUCESSO,
    payload: plano
});

export const PLANO_NAO_ADICIONADO = 'PLANO_NAO_ADICIONADO';
const planoNaoAdicionado = erro => ({
    type: PLANO_NAO_ADICIONADO,
    payload: erro
});

const planoRequired = {
    nome: '',
    listaConfiguracoes: [],
    olt: {}
    //descricao: '',
};
const pppoeRequired = {
    indexVlan: '',
};
const bridgeRequired = {
    indexVlan: '',
    mapaPortasLan: [],
    vlanTaggedChecked: '',
};
const wifiRequired = {
    ssid: '',
    senha: '',
    perfilWifi: ''
};
export const adicionarPlano = (plan, page) => {
    const plano = {
        idOlt: plan && plan.olt && plan.olt.id,
        nome: plan.nome,
        listaConfiguracoes: plan.listaConfiguracoes,
        olt: plan.olt
    };
    const planoVal = {
        formRequired: planoRequired,
        formValues: plano
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(planoVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalPlano());
        dispatch(adicionandoPlano());
        axios
            .post('/api/plano', plano)
            .then(response => {
                dispatch(listarPlanos({ pagina: page || 0 }));
                toast.success((idiomaText().adicionarSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
                history.push("/planos");
            })
            .catch(erro => {
                dispatch(resetarCamposPlano());
                dispatch(planoNaoAdicionado(erro));
                checkResponseErrorType(erro, idiomaText().adicionarError);
            });
    };
};

export const ATUALIZANDO_PLANO = 'ATUALIZANDO_PLANO';
const atualizandoPlano = () => ({
    type: ATUALIZANDO_PLANO
});

export const PLANO_ATUALIZADO_COM_SUCESSO = 'PLANO_ATUALIZADO_COM_SUCESSO';
export const planoAtualizadoComSucesso = plano => ({
    type: PLANO_ATUALIZADO_COM_SUCESSO,
    payload: plano
});

export const PLANO_NAO_ATUALIZADO = 'PLANO_NAO_ATUALIZADO';
const planoNaoAtualizado = erro => ({
    type: PLANO_NAO_ATUALIZADO,
    payload: erro
});

export const atualizarPlano = plan => {
    const plano = plan;
    const planoVal = {
        formRequired: planoRequired,
        formValues: plano
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(planoVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalPlano());
        dispatch(atualizandoPlano());
        axios
            .put('/api/plano', plano)
            .then(response => dispatch(planoAtualizadoComSucesso(response.data)))
            .catch(erro => dispatch(planoNaoAtualizado(erro)));
    };
};

export const DELETANDO_PLANO = 'DELETANDO_PLANO';
const deletandoPlano = () => ({
    type: DELETANDO_PLANO
});

export const PLANO_DELETADO_COM_SUCESSO = 'PLANO_DELETADO_COM_SUCESSO';
const planoDeletadoComSucesso = plano => ({
    type: PLANO_DELETADO_COM_SUCESSO,
    payload: plano
});

export const PLANO_NAO_DELETADO = 'PLANO_NAO_DELETADO';
const planoNaoDeletado = erro => ({
    type: PLANO_NAO_DELETADO,
    payload: erro
});

export const deletarPlano = plano => {
    return dispatch => {
        dispatch(fecharModalPlano());
        dispatch(deletandoPlano());
        axios
            .delete(`/api/plano/${plano.id}`)
            .then(response => {
                dispatch(planoDeletadoComSucesso(response.data));
                toast.success((idiomaText().deletarSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })

            .catch(erro => dispatch(planoNaoDeletado(erro)));
    };
};

export const SET_TIPO_MODAL = "SET_TIPO_MODAL";
export const setTipoModal = (statusModal) => ({
    type: "SET_TIPO_MODAL",
    payload: statusModal
});


export const abrirModalServicoPlano = (plano) => {
    const planoJson = plano;
    const formRequired = {
        serviceType: {}
    };

    const formVal = {
        formRequired: formRequired,
        formValues: planoJson
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(formVal);
        if (validarAction(mensagemDeErro)) {
            return dispatch(mensagemDeErro);
        }
        dispatch(resetarCamposAction());
        dispatch(setTipoModal(plano.serviceType.serviceType.toLowerCase()));
    };
};
export const abrirModalConfiguracaoPlano = (plano) => {
    const planoJson = plano;
    const formRequired = {
        configuration: {}
    };

    const formVal = {
        formRequired: formRequired,
        formValues: planoJson
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(formVal);
        if (validarAction(mensagemDeErro)) {
            return dispatch(mensagemDeErro);
        }
        dispatch(resetarCamposAction());
        dispatch(setTipoModal(plano.configuration.configurationName.toLowerCase()));
    };
};

export const SET_USUARIO_E_SENHA_PADRAO_PPPOE = "SET_USUARIO_E_SENHA_PADRAO_PPPOE";
export const setUsuarioESenhaPadrao = () => ({
    type: SET_USUARIO_E_SENHA_PADRAO_PPPOE,
});
export const SET_SSID_E_SENHA_PADRAO_WIFI = "SET_SSID_E_SENHA_PADRAO_WIFI";
export const setSSIDESenhaPadrao = () => ({
    type: SET_SSID_E_SENHA_PADRAO_WIFI,
});

export const ATUALIZAR_CONTEUDO_PPPOE_MODAL = "ATUALIZAR_CONTEUDO_PPPOE_MODAL";
export const atualizarConteudoPPPoEModal = (evento) => ({
    type: ATUALIZAR_CONTEUDO_PPPOE_MODAL,
    payload: evento
});
export const ATUALIZAR_CONTEUDO_BRIDGE_MODAL = "ATUALIZAR_CONTEUDO_BRIDGE_MODAL";
export const atualizarConteudoBridgeModal = (evento) => ({
    type: ATUALIZAR_CONTEUDO_BRIDGE_MODAL,
    payload: evento
});
export const ATUALIZAR_CONTEUDO_WIFI_MODAL = "ATUALIZAR_CONTEUDO_WIFI_MODAL";
export const atualizarConteudoWifiModal = (evento) => ({
    type: ATUALIZAR_CONTEUDO_WIFI_MODAL,
    payload: evento
});

export const ATUALIZAR_SERVICE_TYPES = "ATUALIZAR_SERVICE_TYPES";
export const atualizarServiceTypes = (serviceType, operationType) => ({
    type: ATUALIZAR_SERVICE_TYPES,
    payload: { serviceType, operationType }
});
export const ATUALIZAR_CONFIGURATION_OPTIONS = "ATUALIZAR_CONFIGURATION_OPTIONS";
export const atualizarConfigurationOptions = (configurationName, operationType) => ({
    type: ATUALIZAR_CONFIGURATION_OPTIONS,
    payload: { configurationName, operationType }
});

export const ADICIONAR_SERVICO_AO_PLANO = "ADICIONAR_SERVICO_AO_PLANO";
export const adicionarServicoAoPlano = (servico) => ({
    type: ADICIONAR_SERVICO_AO_PLANO,
    payload: servico
});
export const ADICIONAR_CONFIGURACAO_AO_PLANO = "ADICIONAR_CONFIGURACAO_AO_PLANO";
export const adicionarConfiguracaoAoPlano = (configuracao) => ({
    type: ADICIONAR_CONFIGURACAO_AO_PLANO,
    payload: configuracao
});

export const salvarPPPoE = (pppoe) => {
    const pppoeVal = {
        formRequired: pppoe.controleDeBandaPPPoE ? { ...pppoeRequired, upload: {} } : pppoe.usuarioESenhaPadrao ? { ...pppoeRequired, pppoeUsuario: '', pppoeSenha: '' } : pppoeRequired,
        formValues: pppoe
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(pppoeVal);
        if (validarAction(mensagemDeErro)) {
            return dispatch(mensagemDeErro);
        }
        else {
            dispatch(adicionarServicoAoPlano({ ...pppoe, dba: "256" }));
            /* dispatch(setTipoModal("")); */
            dispatch(atualizarServiceTypes("PPPoE", "+"));
        }
    };
};
export const salvarBridge = (bridge) => {
    let bridgeJson = {
        ...bridge,
        mapaPortasLan: Object.keys(bridge).filter((chave) => { if (chave.includes("eth") && bridge[chave] === true) return ({ [chave]: bridge[chave] }); })
    };
    //console.log(Object.fromEntries(Object.entries(bridge).filter((registro) => registro[0].includes("eth"))))
    const bridgeVal = {
        formRequired: bridge.controleDeBandaBridge ? { ...bridgeRequired, upload: {} } : bridgeRequired,
        formValues: bridgeJson
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(bridgeVal);
        if (validarAction(mensagemDeErro)) {
            return dispatch(mensagemDeErro);
        }
        else {
            bridgeJson = { ...bridgeJson, dba: "256", mapaPortasLan: Object.fromEntries(Object.entries(bridge).filter((registro) => registro[0].includes("eth"))), vlanTaggedChecked: bridgeJson.vlanTaggedChecked === "TAGGED" ? true : false };
            dispatch(adicionarServicoAoPlano(bridgeJson));
            /* dispatch(setTipoModal("")); */
            dispatch(atualizarServiceTypes("BRIDGE", "+"));
        }
    };
};
export const salvarWiFi = (wifi) => {
    const wifiJson = wifi;
    const wifiVal = {
        formRequired: wifiRequired,
        formValues: wifiJson
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(wifiVal);
        if (validarAction(mensagemDeErro)) {
            return dispatch(mensagemDeErro);
        }
        else {
            dispatch(adicionarConfiguracaoAoPlano(wifi));
            dispatch(setTipoModal(""));
            dispatch(atualizarConfigurationOptions("WiFi", "+"));
        }
    };
};

export const DELETAR_SERVICO_OU_CONFIGURACAO = "DELETAR_SERVICO_OU_CONFIGURACAO";
export const deletarServicoOuConfiguracao = (item) => ({
    type: DELETAR_SERVICO_OU_CONFIGURACAO,
    payload: item
});
export const SET_SERVICO_OU_CONFIGURACAO_SELECIONADO = "SET_SERVICO_OU_CONFIGURACAO_SELECIONADO";
export const setServicoOuConfiguracaoSelecionado = (servicoOuConfiguracao) => ({
    type: SET_SERVICO_OU_CONFIGURACAO_SELECIONADO,
    payload: servicoOuConfiguracao
});

export const RESETAR_CAMPOS_MODAIS = "RESETAR_CAMPOS_MODAIS";
export const resetarCamposModais = () => ({
    type: RESETAR_CAMPOS_MODAIS,
});
export const fecharModal = () => [resetarCamposModais(), setTipoModal("")];

export const ABRIR_MODAL_DELETAR_PLANO_CONFIGURACAO = "ABRIR_MODAL_DELETAR_PLANO_CONFIGURACAO";
export const abrirModalDeletarServicoOuConfiguracao = (servicoOuConfiguracao) => [setServicoOuConfiguracaoSelecionado(servicoOuConfiguracao), setTipoModal("excluindo")];

export const RESETAR_CAMPOS_PLANO = "RESETAR_CAMPOS_PLANO";
export const resetarCamposPlano = () => ({
    type: RESETAR_CAMPOS_PLANO
});

export const salvarPlano = (plano) => {

};

export const HANDLE_IS_LOADING_PLANS_STATUS = "HANDLE_IS_LOADING_PLANS_STATUS";
export const handleIsLoadingPlansStatus = (status) => ({
    type: HANDLE_IS_LOADING_PLANS_STATUS,
    payload: status
});

export const CLEAR_PLANO_FIELD = "CLEAR_PLANO_FIELD";
export const clearPlanoField = (e) => ({
    type: CLEAR_PLANO_FIELD,
    payload: e
});

export const CLEAR_BRIDGE_FIELD = "CLEAR_BRIDGE_FIELD";
export const clearBridgeField = (e) => ({
    type: CLEAR_BRIDGE_FIELD,
    payload: e
});

export const CLEAR_PPPOE_FIELD = "CLEAR_PPPOE_FIELD";
export const clearPPPoEField = (e) => ({
    type: CLEAR_PPPOE_FIELD,
    payload: e
});

export const CANCEL_SERVICE_TYPE = "CANCEL_SERVICE_TYPE ";
export const cancelServiceType = (e) => ({
    type: CANCEL_SERVICE_TYPE,
    payload: e
});

export const IS_SEARCH = 'plano.IS_SEARCH';
export const isSearch = () => ({
    type: IS_SEARCH
});

export const DBA_LISTADO_COM_SUCESSO = 'DBA_LISTADO_COM_SUCESSO';
export const dbaListadoComSucesso = (dbas) => ({
    type: DBA_LISTADO_COM_SUCESSO,
    payload: dbas
});

export const DBA_LISTADO_COM_ERRO = 'DBA_LISTADO_COM_ERRO';
export const dbaListadoComErro = (error) => ({
    type: DBA_LISTADO_COM_ERRO,
    payload: error
});

export const MAIS_DBA_LISTADO_COM_SUCESSO = 'MAIS_DBA_LISTADO_COM_SUCESSO';
export const maisDbaListadoComSucesso = (dbas) => ({
    type: MAIS_DBA_LISTADO_COM_SUCESSO,
    payload: dbas
});

export const listarDba = e => {
    const params = {
        idOlt: `idOlt=${e && e.idOlt ? e.idOlt : ''}`,
        pagina: `&pagina=${e && e.pagina ? e.pagina : '0'}`,
        qtdRegistros: `&qtdRegistros=${e && e.qtdRegistros ? e.qtdRegistros : '10'}`
    };
    return dispatch => {
        axios
            .get(`/api/listarDbas?${params.idOlt + params.pagina + params.qtdRegistros}`)
            .then(response => {
                e && e.pagina >= 1
                    ? dispatch(maisDbaListadoComSucesso(response.data))
                    : dispatch(dbaListadoComSucesso(response.data));
            })
            .catch((error) => {
                dispatch(dbaListadoComErro(error));
                checkResponseErrorType(error, idiomaText().listarDbaError);
            });
    };
};

export const ABRIR_MODAL_INFORMACAO = 'ABRIR_MODAL_INFORMACAO';
export const abrirModalInformacaoPlano = (plano) => [
    setPlano(plano),
    setTipoModalPlano('informacao')
];

export const abrirModalPortasLan = (planoPortaLan) => [setPlanoConfig(planoPortaLan), setTipoModal('modalPortaLan')];

export const MAIS_PLANOS_CARREGADOS_COM_SUCESSO = 'MAIS_PLANOS_CARREGADOS_COM_SUCESSO';
const maisPlanosCarregadosComSuceso = (planos) => ({
    type: MAIS_PLANOS_CARREGADOS_COM_SUCESSO,
    payload: planos,
});

export const listarPlanosFiltrado = e => {
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        modelo: `&modelo=${e && e.modelo ? e.modelo : ''}`,
        qtdRegistros: `&qtdRegistros=${e.qtdRegistros ? e.qtdRegistros : '3'}`
    };
    return dispatch => {
        dispatch(handleIsLoadingPlansStatus(true));
        dispatch(carregandoPlanos());
        axios
            .get(`/api/plano/filtro?${params.pagina + params.modelo + params.qtdRegistros}`)
            .then((response) =>
                e.pagina >= 1
                    ? dispatch(maisPlanosCarregadosComSuceso(response.data))
                    : dispatch(planosCarregadosComSuceso(response.data))
            )
            .catch((error) => {
                dispatch(planosCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarPlanosError);
                dispatch(handleIsLoadingPlansStatus(false));
            });
    };
};

export const SET_LIST_PLANO_INICIAL = 'SET_LIST_PLANO_INICIAL';
export const setListPlanoInicial = () => ({
    type: SET_LIST_PLANO_INICIAL
});