import React, { useEffect, useState } from 'react';

const PieChart = ({ data }) => {
    const [chartWidth, setChartWidth] = useState(200);
    useEffect(() => {
        const handleResize = () => {
            const containerWidth = document.getElementById('pie-chart-container').clientWidth;
            setChartWidth(containerWidth + (chartWidth - containerWidth));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const total = data.reduce((acc, item) => acc + item.value, 0);
    let currentAngle = -90;

    const [tooltip, setTooltip] = useState({ show: false, x: 0, y: 0, name: '', value: 0 });

    const handleMouseEnter = (name, value, x, y) => {
        setTooltip({ show: true, x, y, name, value });
    };

    const handleMouseLeave = () => {
        setTooltip({ ...tooltip, show: false });
    };
    const classesWithValue = data.filter((item) => item.value > 0);
    function CompletePieChart() {
        return (
            <div id="pie-chart-container" style={{ maxWidth: '800px', margin: '0 auto' }}>
                <svg width={chartWidth} height={chartWidth}>
                    <circle cx="100" cy="100" r="95" fill={data[0].color} />
                    <text
                        x="100"
                        y="100"
                        fill="black"
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{ fontSize: '12px', fontWeight: 'bold' }}
                    >
                        {((data[0].value / total) * 100).toFixed(1)}%
                    </text>
                    <text
                        x="100"
                        y="120"
                        fill="black"
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{ fontSize: '10px', fontWeight: 'normal' }}
                    >
                        {data[0].value}
                    </text>
                </svg>
                <div style={{ marginLeft: '20px' }}>
                    {data.map((item) => (
                        <div key={item.name} style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                            <div
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: item.color,
                                    marginRight: '8px',
                                }}
                            />
                            <span>{item.name}</span>
                        </div>
                    ))}
                </div>
                {tooltip.show && (
                    <div
                        style={{
                            position: 'absolute',
                            left: tooltip.x - 30,
                            top: tooltip.y + 70,
                            background: 'rgba(0, 0, 0, 0.8)',
                            color: 'white',
                            padding: '4px',
                            borderRadius: '4px',
                        }}
                    >
                        <div>{tooltip.name}</div>
                        <div>{tooltip.value}</div>
                    </div>
                )}
            </div>
        );

    }
    function NormalPieChart() {
        return (
            <div id="pie-chart-container" style={{ maxWidth: '800px', margin: '0 auto' }}>
                <svg width={chartWidth} height={chartWidth}>
                    {data.map((item) => {
                        const { name, label, value, color } = item;
                        const angle = (value / total) * 360;
                        const endAngle = currentAngle + angle;
                        const largeArcFlag = angle > 180 ? 1 : 0;
                        const x1 = 100 + 95 * Math.cos((currentAngle * Math.PI) / 180);
                        const y1 = 100 + 95 * Math.sin((currentAngle * Math.PI) / 180);
                        const x2 = 100 + 95 * Math.cos((endAngle * Math.PI) / 180);
                        const y2 = 100 + 95 * Math.sin((endAngle * Math.PI) / 180);

                        const tooltipY = 100 + 80 * Math.sin(((currentAngle + angle / 2) * Math.PI) / 180);
                        const percentageX = 100 + 50 * Math.cos(((currentAngle + angle / 2) * Math.PI) / 180);
                        const percentageY = 100 + 50 * Math.sin(((currentAngle + angle / 2) * Math.PI) / 180);
                        currentAngle = endAngle;

                        return (
                            <g key={name}>
                                <path
                                    d={`M 100 100 L ${x1} ${y1} A 95 95 0 ${largeArcFlag} 1 ${x2} ${y2} Z`}
                                    fill={color}
                                    // onMouseEnter={(e) => handleMouseEnter(name, value, x1, e.clientY)}
                                    // onMouseLeave={handleMouseLeave}
                                />
                                {value > 0 ?
                                    <text
                                        x={percentageX}
                                        y={percentageY}
                                        fill="black"
                                        textAnchor="middle"
                                        dominantBaseline="central"
                                        style={{ fontSize: '12px', fontWeight: 'bold' }}
                                    >
                                        {((value / total) * 100).toFixed(1)}%
                                    </text>
                                    : null}
                                <line x1={100} y1={100} x2={x1} y2={y1} stroke="#ccc" />
                            </g>

                        );
                    })}
                </svg>
                <div style={{ marginLeft: '20px' }}>
                    {data.map((item) => (
                        item.value > 0 ?
                            <div key={item.name} style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: item.color,
                                        marginRight: '8px',
                                    }}
                                />
                                <span>{item.name}</span>
                            </div>
                            : null
                    ))}
                </div>
                {
                    tooltip.show && (
                        <div
                            style={{
                                position: 'absolute',
                                right: tooltip.x - (tooltip.x),
                                top: tooltip.y - (tooltip.y / 2),
                                background: 'rgba(0, 0, 0, 0.8)',
                                color: 'white',
                                padding: '4px',
                                borderRadius: '4px',
                            }}
                        >
                            <div>{tooltip.name}</div>
                            <div>{tooltip.value}</div>
                        </div>
                    )
                }
            </div >
        )
    }
    return (
        classesWithValue.length === 1 ? <CompletePieChart /> : <NormalPieChart />
    );
};

export default PieChart;
