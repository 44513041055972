import React, { useEffect, useState } from 'react';
import { listarCpes, listarCpesNaoProvisionadas, listarOltsOk } from '../../olt/redux/OltActions';
import { useDispatch, useSelector } from 'react-redux';

import CheckboxSwitch from '../../../components/form/checkboxSwitch/CheckboxSwitch';
import InputInbox from '../../../components/form/inputInBox/InputInbox';
import { SelectInBox } from '../../../components/form';
import { atualizarConteudoServicoModal, resetarCpeSelecionada, loadOkOlts, clearServicoField, } from '../redux/ServicoActions';
import { listarPlanosFiltrado } from '../../planos/redux/PlanosActions';
import { validarCampoAction } from '../../../validation/validationActions';
import DropdownList from '../../../components/dropdownList/dropdownList';
import Row from '../../../components/row/row';
import Button from '../../../components/button/Button';
import { IcoMenos } from '../../../components/icones/menos';
import { IcoMais } from '../../../components/icones';
import ConteudoDropdownContent from './dropdownContent/conteudoDropdownContent';
import ConteudoDropdownContentWiFi from './dropdownContent/conteudoDropdownContentWiFi';
import Checkbox from '../../../components/form/checkbox/Checkbox';
import Input from '../../../layouts/components/Input/Input';
import ConteudoDropdown8820i from './dropdownContent/conteudoDropdown8820i';
import RadioButton from '../../../components/form/radioButton/RadioButton';

const ConteudoServicoModal = () => {
    const dispatch = useDispatch();

    const [dropdownState, setDropdownState] = useState({
        advancedSettings: null,
        wifiSettings: null
    });

    const [disableWhenEditing, setDisableWhenEditing] = useState(false);

    const [modelOption, setModelOption] = useState('hideAdvancedOption');

    const locales = useSelector(state => state.layoutState.linguagem);
    const servico = useSelector(state => state.servicosState.servico);
    const olts = useSelector(state => state.servicosState.okOlts.olts);
    const allOlts = useSelector(state => state.oltsState.olts);
    const paginacaoOlt = useSelector(state => state.servicosState.okOlts.paginacao);
    const paginacaoPlano = useSelector(state => state.planosState.paginacao);
    const servicoModal = useSelector(state => state.servicosState.statusModal);

    const cpes = useSelector(state => state.oltsState.cpes);
    const planos = useSelector(state => state.planosState.planos);
    const urlId = window.location.pathname.split('/');
    const idAssinante = urlId[1] === 'assinante' ? urlId[2] : '';
    const assinante = useSelector(state => state.assinantesState.assinante);
    const planoSelecionado = useSelector((state) => state.servicosState.servico.plano);
    const oltSelecionada = useSelector((state) => state.servicosState.servico.olt);

    const paginacaoCpes = useSelector(state => state.oltsState.paginacaoCpes);
    const cpeModal = useSelector((state) => state.cpesState.statusModal);
    const cpeSelected = useSelector((state) => state.cpesState.cpe);

    const defaultText = require(`../nls/${locales}.json`);
    const mensagemErro = useSelector(state => state.validacaoState);
    const [oltSelected, setOltSelected] = useState(allOlts.find(olt => olt.id === cpeSelected.idOlt));

    const quantidadePorPagina = 3;
    let modalFromCpe = cpeModal.includes('addService') ? true : false;
    const [pppoeStatus, setPppoeStatus] = useState("ativar");
    const [isPPPoe, setIsPPPoe] = useState([
        { id: "ativar", text: 'Ativar' },
        { id: "desativar", text: 'Desativar' }])
    useEffect(() => {
        /* dispatch(listarPlanos(0)); */
        //modalFromCpe ? dispatch(listarPlanosFiltrado({ modelo: servico.cpe.modelo })) : dispatch(listarPlanosFiltrado());
        dispatch(loadOkOlts({ quantidadePorPagina }));
        modalFromCpe ? handleAdvancedOption() : '';
        setDisableWhenEditing(servico.id && true || cpeSelected.id && true);
        servicoModal.includes('editando') ? dispatch(listarCpesNaoProvisionadas({ olt: servico.idOlt })) : '';
    }, [dispatch]);

    if (idAssinante && !servico.assinante.id) {
        dispatch(
            atualizarConteudoServicoModal({
                target: { name: 'assinante', value: assinante }
            })
        );
    }

    const handleDropdownState = (e) => {
        let actualStatus = dropdownState[e.currentTarget.value];
        let newArray = {
            ...dropdownState
        };
        newArray[e.currentTarget.value] = actualStatus === null ? true : !actualStatus;
        setDropdownState(newArray);
    };

    const handleDropdownOpen = (e) => {
        let plano = e.target.value;
        let pppoeConfig = plano.listaConfiguracoes?.filter(config => config.tipoServico === "PPPOE");
        pppoeConfig?.map((config) => {
            if (config.pppoeSenha === null && config.pppoeUsuario === null) {
                let openDropdown = {
                    advancedSettings: true
                };
                setDropdownState(openDropdown);
            } else {
                let closeDropdown = {
                    advancedSettings: false
                };
                setDropdownState(closeDropdown);
            }
        });
    };

    const ListCpes = e => {
        setOltSelected(e.target.value);
        dispatch([atualizarConteudoServicoModal(e), resetarCpeSelecionada(), listarCpesNaoProvisionadas({ olt: e.target.value.id })]);
    };

    const ListPlanos = e => {
        dispatch([
            atualizarConteudoServicoModal(e),
            listarPlanosFiltrado({ modelo: e.target.name === 'modelo' ? e.target.value.model : e.target.value.modelo })
        ]);
    };

    const handleAdvancedOption = (e) => {
        let listModel = modalFromCpe ? oltSelected.modeloCpes.map((cpe) => cpe.model) : oltSelecionada.modeloCpes.map((cpe) => cpe.model);
        let cpeModel = modalFromCpe ? cpeSelected.modelo : e.target.value.modelo;
        const showOptionModel = listModel.includes(cpeModel);
        if (showOptionModel === false && Object.keys(servico.cpe).length !== 0) {
            setModelOption('');
        } else {
            setModelOption('hideAdvancedOption');
        }
    };

    return (
        <section className='box-criar-editar-servicos'>
            {servico.assinante && servico.assinante.id ? (
                <>
                    <Row key='assinante'>
                        <div className='col-md-12'>
                            <p>
                                {defaultText.inputs.assinante}: {servico.assinante.nome}
                            </p>
                            <p>
                                {servico.assinante.nContrato && defaultText.inputs.nContrato + ': ' + servico.assinante.nContrato}
                            </p>
                        </div>
                    </Row>
                </>
            ) : null}
            {/* <Row>
                <div className='col-md-12'>
                    <CheckboxSwitch
                        id={`servicoStatus-${servico.id}`}
                        name='servicoStatus'
                        label={`${defaultText.inputs.status} ${defaultText.inputs[servico.servicoStatus ? 'ativo' : 'inativo']}`}
                        checked={servico.servicoStatus}
                        changeInput={e => dispatch(atualizarConteudoServicoModal(e))}
                    />
                </div>
            </Row> */}
            <Row>
                <div className='col-md-12'>
                    <Input
                        id="nome"
                        name='nome'
                        label={defaultText.inputs.nome}
                        value={servico.nome}
                        changeInput={e => dispatch(atualizarConteudoServicoModal(e))}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.nome ? defaultText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '80'
                        }}
                        clearField={{
                            action: (e) => dispatch(clearServicoField(e)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className='col-md-12'>
                    <SelectInBox
                        id='olt'
                        label={defaultText.inputs.olt}
                        disabled={disableWhenEditing}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            minWidth: '100%',

                        }}
                        validate={{
                            erro: mensagemErro.olt ? defaultText.mensagem.obrigatorio : ''
                        }}
                        selectFilter={{
                            action: (e) => dispatch(loadOkOlts({ pesquisa: e, quantidadePorPagina })),
                            actionLimpar: () => dispatch(listarOltsOk({ quantidadePorPagina })),
                            texto: defaultText.select.pesquisar.texto,
                            limpar: defaultText.select.pesquisar.limpar
                        }}

                        select={{
                            action: e => ListCpes(e),
                            options: olts.filter(olt => olt.operationMode !== "AUTOSERVICE"),

                            optionCustom: e => (
                                <>
                                    <p>{e.nome}</p>
                                </>
                            ),
                            selected: modalFromCpe ? oltSelected : Object.keys(servico.olt).length !== 0 ? servico.olt : { id: "selecione", nome: defaultText.select.selecionado.nenhum }
                        }}
                        moreOptions={{
                            page: paginacaoOlt,
                            action: e =>
                                dispatch(
                                    loadOkOlts({
                                        pagina: paginacaoOlt.pageNumber + 1,
                                        pesquisa: e,
                                        quantidadePorPagina
                                    })
                                ),
                            texto: defaultText.btn.maisItens

                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className='col-md-12'>
                    <SelectInBox
                        id='cpe'
                        label={defaultText.inputs.cpe}
                        disabled={modalFromCpe ? true : (!servico.olt.id || servicoModal.includes('editando'))}
                        selectDefault={{
                            id: 'id',
                            label: 'identificador',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        selectFilter={{
                            action: e =>
                                dispatch(
                                    listarCpesNaoProvisionadas({
                                        olt: servico.olt.id,
                                        identificador: e
                                    })
                                ),
                            actionLimpar: () => dispatch(listarCpesNaoProvisionadas({ olt: servico.olt.id })),
                            texto: defaultText.select.pesquisar.texto,
                            limpar: defaultText.select.pesquisar.limpar
                        }}
                        validate={{
                            erro: mensagemErro.cpe ? defaultText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => [ListPlanos(e), handleAdvancedOption(e)],
                            options: cpes,
                            optionCustom: e => (
                                <>
                                    <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>
                                            {defaultText.inputs.identificador}: {e.identificador}
                                        </span>
                                        {servico.id && e.slot && <span>
                                            {defaultText.inputs.nSlot}: {e.slot}
                                        </span>}
                                        <span>
                                            {defaultText.inputs.modelo}: {e.modelo}
                                        </span>

                                        <span>
                                            {defaultText.inputs.porta}: {e.porta}
                                        </span>

                                    </p>
                                </>
                            ),
                            selected: modalFromCpe ? cpeSelected : servico.cpe
                        }}
                        moreOptions={{
                            page: paginacaoCpes,
                            action: e =>
                                dispatch(
                                    listarCpesNaoProvisionadas({
                                        pagina: paginacaoCpes.pageNumber + 1,
                                        olt: servico.olt.id
                                    })
                                ),
                            texto: defaultText.btn.maisItens
                        }}
                    />
                </div>
            </Row>

            <Row>
                <div className={`col-md-12 ${modelOption}`}>
                    <SelectInBox
                        id='modelo'
                        label={defaultText.inputs.modelo}
                        selectDefault={{
                            id: 'type',
                            label: 'model',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.modelo ? defaultText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => [dispatch(atualizarConteudoServicoModal(e)), ListPlanos(e)],
                            options: modalFromCpe ? oltSelected.modeloCpes : servico.olt && servico.olt.modeloCpes ? servico.olt.modeloCpes : [],
                            selected: servico.cpe.modelo
                        }}
                    />
                </div>
            </Row>

            <Row>
                <div className='col-md-12'>
                    <SelectInBox
                        id='plano'
                        label={defaultText.inputs.plano}
                        disabled={modalFromCpe ? false : (servico.id || !servico.cpe.id)}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.plano ? defaultText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => [dispatch(atualizarConteudoServicoModal(e)), handleDropdownOpen(e)],
                            options: planos,
                            selected: servico.plano
                        }}
                        moreOptions={{
                            page: paginacaoPlano,
                            action: e =>
                                dispatch(
                                    listarPlanosFiltrado({
                                        pagina: paginacaoPlano.pageNumber + 1,
                                        modelo: Object.keys(servico.cpe.modelo).length === 2 ? servico.cpe.modelo.model : servico.cpe.modelo
                                    })
                                ),
                            texto: defaultText.btn.maisItens
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className={`col-md-12 ${planoSelecionado && planoSelecionado.listaConfiguracoes && (planoSelecionado.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE").length > 0 || oltSelected?.modelo === "OLT_8820I") && pppoeStatus === "ativar" ? "show" : "hide"}`}>
                    <DropdownList
                        title={defaultText.tituloConfiguracoesAvancadas}
                        button={
                            <Button
                                type="btn circle"
                                children={dropdownState.advancedSettings ? <IcoMenos title="Fechar" /> : <IcoMais title="Abrir" />}
                                value="advancedSettings"
                                action={(e) => { handleDropdownState(e); }}
                                color="transparent"
                                disabled={servicoModal.includes('editando') ? true : false}
                            />
                        }
                        dropdownContent={
                            oltSelected?.modelo === "OLT_8820I" ?
                                <ConteudoDropdown8820i
                                    dropdownState={dropdownState}
                                />
                                :
                                <ConteudoDropdownContent dropdownState={dropdownState}
                                    mensagemErro={mensagemErro} />
                        }
                        validate={{
                            erro: (mensagemErro.usuarioPPPoE || mensagemErro.senhaPPPoE) ? defaultText.mensagem.obrigatorio : ''
                        }}
                    />
                </div>
            </Row>


            <Row>
                <div style={{ paddingLeft: '3px', marginTop: '5px' }} className={`col-md-12 ${planoSelecionado && planoSelecionado.listaConfiguracoes && (planoSelecionado.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE").length > 0 || oltSelected.modelo === "OLT_8820I") ? "show" : "hide"}`} >
                    <div className='col-md-12'>
                        <p style={{ paddingBottom: '5px' }}>{defaultText.inputs.pppoeDesativar} </p>

                        <RadioButton
                            id="pppoeActive"
                            name='pppoeActive'
                            changeInput={e => [dispatch(atualizarConteudoServicoModal(e)), setPppoeStatus(e.target.value)]}
                            selected={pppoeStatus}
                            options={isPPPoe}
                        />
                    </div>
                </div>
            </Row>
        </section>
    );
};

export default ConteudoServicoModal;
