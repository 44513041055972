import React from "react"
import { useDispatch, useSelector } from 'react-redux'
import ReleaseNotesConteudo from "../releaseNotes/releaseNotesConteudo"
import BasicModal from "../../components/modal/BasicModal"
import { fecharModal } from '../../layouts/redux/LayoutActions'

const ReleaseNotes = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`../releaseNotes/nls/${locales}.json`)

    return (
        <BasicModal
            close={() => dispatch(fecharModal())}
            containerModal={{
                title: defaltText.tituloModalReleaseNotes,
                size: "large",
                content: (<ReleaseNotesConteudo />),
                closeText: defaltText.btn.fecharReleaseNotes
            }}
        />
    )
}

export default ReleaseNotes