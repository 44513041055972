import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import InformacoesConteudo from './informacoesConteudo';

import { fecharModalServico } from '../redux/ServicoActions';

const ServiceInfo = () => {
  const dispatch = useDispatch();

  const locales = useSelector(state => state.layoutState.linguagem);
  const defaltText = require(`../nls/${locales}.json`);
  const statusModal = useSelector(state => state.servicosState.statusModal);

  return (
    <BasicModal
      open={statusModal}
      close={() => dispatch(fecharModalServico())}
      containerModal={{
        title: defaltText.tituloModalInformacoes,
        size: 'medium',
        content: (
          <>
            <InformacoesConteudo />
          </>
        ),
        closeText: defaltText.btn.fechar
      }}
    />
  );
};

export default ServiceInfo;
