import React from 'react';
import { validarAction, validarCamposAction, validarSenha } from '../../../../validation/validationActions';

import axios from 'axios';
import history from '../../../../history';
import { toast } from 'react-toastify';
import { CorrectIcon } from '../../../../layouts/components/icons';
import { IcoInfo } from '../../../../components/icones';
import { RemoveMaskTelefone } from '../../../../components/mask/Mask';

const idiomaText = () => {
    const preferencia = null;
    try {
        preferencia = JSON.parse(localStorage.getItem('theme'));
    } finally {
        const defaltText = require(`../../nls/${preferencia !== null ? preferencia.linguagem : 'pt-BR'}.json`);
        return defaltText.mensagem;
    }
};

export const ATUALIZAR_CONTEUDO_REGISTRAR = 'ATUALIZAR_CONTEUDO_REGISTRAR';
export const atualizarConteudoRegistrar = e => ({
    type: ATUALIZAR_CONTEUDO_REGISTRAR,
    payload: e
});

export const ADICIONANDO_REGISTRO = 'ADICIONANDO_REGISTRO';
const adicionandoRegistro = () => ({
    type: ADICIONANDO_REGISTRO
});

export const REGISTRO_ADICIONADO_COM_SUCESSO = 'REGISTRO_ADICIONADO_COM_SUCESSO';
const registroAdicionadoComSucesso = registro => ({
    type: REGISTRO_ADICIONADO_COM_SUCESSO,
    payload: registro
});

export const REGISTRO_NAO_ADICIONADO = 'REGISTRO_NAO_ADICIONADO';
const registroNaoAdicionado = erro => ({
    type: REGISTRO_NAO_ADICIONADO,
    payload: erro
});

const registroRequired = {
    nome: '',
    email: 'email',
    senha: '',
    confirmsenha: '',
    telefone: ''
};

export const adicionarRegistro = (registro, externalAction) => {
    const registroJson = { ...registro, telefone: RemoveMaskTelefone(registro.telefone) };

    const registroPatternRequired = {
        telefone: "phone"
    };

    const registroVal = {
        formPatternRequired: registroPatternRequired,
        formRequired: registroRequired,
        formValues: registroJson
    };
    return dispatch => {
        let mensagemDeErro = validarCamposAction(registroVal);
        mensagemDeErro = validarSenha(registro.senha);
        if (registro.senha !== registro.confirmsenha) {
            toast.error((idiomaText().senhaConfirmarDiferenteErro), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
            return dispatch(mensagemDeErro);
        }
        if (validarAction(mensagemDeErro)) {
            toast.error((idiomaText().preenchaErro), {
                icon: <div className='divIconToastError'><IcoInfo /></div>
            });
            return dispatch(mensagemDeErro);
        }
        dispatch(adicionandoRegistro());
        delete registroJson.confirmsenha;
        axios
            .post('/api/autorizacao/cadastrar', registroJson)
            .then(response => {
                dispatch(registroAdicionadoComSucesso(response.data));
                toast.success((idiomaText().registrarSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
                /* history.push('/login'); */
                externalAction();
            })
            .catch(erro => {
                dispatch(registroNaoAdicionado(erro));
                toast.error((idiomaText().registrarErro), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
            });
    };
};

export const ATUALIZANDO_REGISTRO = 'ATUALIZANDO_REGISTRO';
const atualizandoRegistro = () => ({
    type: ATUALIZANDO_REGISTRO
});

export const REGISTRO_ATUALIZADO_COM_SUCESSO = 'REGISTRO_ATUALIZADO_COM_SUCESSO';
const registroAtualizadoComSucesso = registro => ({
    type: REGISTRO_ATUALIZADO_COM_SUCESSO,
    payload: registro
});

export const REGISTRO_NAO_ATUALIZADO = 'REGISTRO_NAO_ATUALIZADO';
const registroNaoAtualizado = erro => ({
    type: REGISTRO_NAO_ATUALIZADO,
    payload: erro
});

export const RESET_REGISTER_FIELDS = "RESET_REGISTER_FIELDS";
export const resetRegisterFields = () => ({
    type: RESET_REGISTER_FIELDS,
});

export const CLEAR_REGISTRAR_FIELD = "CLEAR_REGISTRAR_FIELD";
export const clearRegistrarField = (e) => ({
    type: CLEAR_REGISTRAR_FIELD,
    payload: e
});