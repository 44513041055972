import { applyMiddleware, createStore } from "redux";

import notificacoesState from "./modulos/notificacoes/redux/NotificacoesReducer";
import cpesState from "./modulos/cpe/redux/CpeReducer";
import AssinanteReducer from "./modulos/assinantes/redux/AssinanteReducer";
import ColaboradorReducer from "./modulos/colaboradores/redux/ColaboradorReducer";
import LayoutReducer from "./layouts/redux/LayoutReducer";
import OltReducer from "./modulos/olt/redux/OltReducer";
import PagamentoReducer from "./modulos/pagamentos/redux/PagamentoReducer";
import PerfilReducer from "./modulos/perfis/redux/PerfilReducer";
import PlanoReducer from "./modulos/planos/redux/PlanosReducer";
import RegistrarUsuario from "./modulos/usuario/registrar/redux/RegistrarReducer";
import ServicoReducer from "./modulos/servicos/redux/ServicoReducer";
import UserReducer from "./modulos/usuario/login/redux/UserReducer";
import ValidarReducer from "./validation/validationReducer";
import LocaisReducer from "./modulos/locais/redux/LocaisReducer";
import AlarmesReducer from './modulos/alarmes/redux/AlarmesReducer';
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import multi from "redux-multi";
import thunkMiddleware from "redux-thunk";

const Reducer = combineReducers({
  notificacoesState,
  cpesState,
  colaboradoresState: ColaboradorReducer,
  perfisState: PerfilReducer,
  oltsState: OltReducer,
  assinantesState: AssinanteReducer,
  servicosState: ServicoReducer,
  planosState: PlanoReducer,
  pagamentosState: PagamentoReducer,
  validacaoState: ValidarReducer,
  authentication: UserReducer,
  registrarState: RegistrarUsuario,
  layoutState: LayoutReducer,
  locaisState: LocaisReducer,
  alarmesState: AlarmesReducer
});

let store = createStore(Reducer, composeWithDevTools(applyMiddleware(thunkMiddleware, multi)));

if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger();
  store = createStore(Reducer, composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware, multi)));
}

export default store;
/* export default createStore(Reducer, composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware, multi))); */

