import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/button/Button";
import { SelectInBox } from "../../../components/form";
import Input from "../../../layouts/components/Input/Input";
import { listarCpes } from "../../olt/redux/OltActions";
import { atualizarFormularioDePesquisa, isSearch, limparCampoPesquisaGenerico, limparFormPesquisa, listarServicos, resetarCpeSelecionada, setFecharModalAdvancedFilter, setModalAdvancedFilterService } from "../redux/ServicoActions";

const AdvancedFilterServices = () => {

    const dispatch = useDispatch();
    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const planos = useSelector(state => state.planosState.planos);
    const olts = useSelector(state => state.oltsState.olts);
    const cpes = useSelector(state => state.oltsState.cpes);
    const paginacaoCpes = useSelector(state => state.oltsState.paginacaoCpes);
    const formularioPesquisa = useSelector(state => state.servicosState);


    return (
        <>
            <div className='titleAdvancedFilter'>
                <p>{defaultText.filters}</p>
                <Button
                    value="limparFiltos"
                    titulo={defaultText.pesquisar.cleanFilter}
                    action={() => dispatch(limparFormPesquisa())}
                    texto={defaultText.pesquisar.cleanFilter}
                    color="primary"
                    type='btn'
                />
            </div>
            <div className='advancedFilterServices'>
                <div className="advancedItem">
                    <SelectInBox
                        id='plano'
                        label={defaultText.inputs.plano}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        select={{
                            action: e => dispatch(atualizarFormularioDePesquisa(e)),
                            options: planos,
                            selected: formularioPesquisa.formPesquisa.plano
                        }}
                        position="absolute"
                    />
                </div>

                <div className="advancedItem">
                    <SelectInBox
                        id='olt'
                        label={defaultText.inputs.olt}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        select={{
                            action: e => dispatch([atualizarFormularioDePesquisa(e), resetarCpeSelecionada(), listarCpes({ olt: e.target.value.id })]),
                            options: olts,
                            selected: formularioPesquisa.formPesquisa.olt
                        }}
                        position="absolute"
                    />
                </div>

                <div className="advancedItem">
                    <SelectInBox
                        title={defaultText.hints.oltRequerida}
                        disabled={(formularioPesquisa.formPesquisa.olt && JSON.stringify(formularioPesquisa.formPesquisa.olt) !== "{}") ? false : true}
                        id='cpe'
                        label={defaultText.inputs.cpe}
                        selectDefault={{
                            id: 'id',
                            label: 'identificador',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        selectFilter={{
                            action: e =>
                                dispatch(
                                    listarCpes({
                                        olt: formularioPesquisa.formPesquisa.olt.id,
                                        identificador: e
                                    })
                                ),
                            actionLimpar: () => dispatch(listarCpes({ olt: formularioPesquisa.formPesquisa.olt.id })),
                            texto: defaultText.select.pesquisar.texto,
                            limpar: defaultText.select.pesquisar.limpar
                        }}
                        select={{
                            action: e => dispatch(atualizarFormularioDePesquisa(e)),
                            options: cpes,
                            optionCustom: e => (
                                <>
                                    <p>
                                        {defaultText.inputs.identificador}: {e.identificador}
                                    </p>
                                    <p>
                                        <span>
                                            {defaultText.inputs.porta}: {e.porta}, {defaultText.inputs.nSlot}: {e.slot}
                                        </span>
                                        {/* <span>
                      {defaultText.inputs.nSlot}: {e.slot}
                    </span> */}
                                    </p>
                                </>
                            ),
                            selected: formularioPesquisa.formPesquisa.cpe
                        }}
                        moreOptions={{
                            page: paginacaoCpes,
                            action: e =>
                                dispatch(
                                    listarCpes({
                                        pagina: paginacaoCpes.pageNumber + 1,
                                        olt: formularioPesquisa.formPesquisa.olt.id,
                                        identificador: e
                                    })
                                ),
                            texto: defaultText.btn.maisItens
                        }}
                    />
                </div>
                <div className="advancedItem">
                    <Input
                        id="nome"
                        name='nome'
                        label={defaultText.inputs.nomeAssinante}
                        value={formularioPesquisa.formPesquisa.nome}
                        changeInput={e => dispatch(atualizarFormularioDePesquisa(e))}
                        length={{
                            max: '50'
                        }}
                        clearField={{
                            action: (e) => dispatch(limparCampoPesquisaGenerico(e)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>
                <div className="advancedItem">
                    <Input
                        id="cpf"
                        name='cpf'
                        label={defaultText.inputs.CPF}
                        value={formularioPesquisa.formPesquisa.cpf}
                        changeInput={e => dispatch(atualizarFormularioDePesquisa(e))}
                        length={{
                            max: '50'
                        }}
                        clearField={{
                            action: (e) => dispatch(limparCampoPesquisaGenerico(e)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>

                <div className="advancedItem">
                    <Input
                        id="nContrato"
                        name='nContrato'
                        label={defaultText.inputs.nContrato}
                        value={formularioPesquisa.formPesquisa.nContrato}
                        changeInput={e => dispatch(atualizarFormularioDePesquisa(e))}
                        length={{
                            max: '50'
                        }}
                        clearField={{
                            action: (e) => dispatch(limparCampoPesquisaGenerico(e)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>
                <div className='btnAdvancedFilter'>
                    <Button
                        titulo={defaultText.pesquisar.text}
                        action={() => dispatch(setFecharModalAdvancedFilter())}
                        texto='Cancelar'
                        color='secondary'
                        type='btn'
                    />
                    <Button
                        titulo={defaultText.pesquisar.text}
                        action={() => {
                            dispatch(listarServicos({ pesquisa: formularioPesquisa })),
                                dispatch(setModalAdvancedFilterService('')),
                                dispatch(isSearch());
                        }}
                        texto='Pesquisar'
                        color='secondary'
                        type='btn'
                    />
                </div>
            </div>
        </>
    );
};

export default AdvancedFilterServices;