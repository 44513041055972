import React from 'react';

export function ChevronDownIcon(props) {
    return (
        <svg id={props.id}
            xmlns="http://www.w3.org/2000/svg"
            width="16.662" height="9.331"
            viewBox="0 0 16.662 9.331"
            className={props.className}
        >
            <path id="Icon_feather-chevron-down" data-name="Icon feather-chevron-down" d="M9,13.5l6.917,6.917L22.833,13.5" transform="translate(-7.586 -12.086)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </svg >
    );
}