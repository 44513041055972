import React from 'react'
import { connect } from 'react-redux'

import BasicModal from '../../../components/modal/BasicModal.js'

import { fecharModalPagamento, deletarPagamento } from '../redux/PagamentoActions.js'

const Remover = props => {
  const defaltText = require(`../nls/${props.locales}.json`)

  return (
    <BasicModal
      open={props.statusModal}
      close={props.fecharModalPagamento}
      containerModal={{
        title: defaltText.tituloModalRemover,
        size: 'modal-sm',
        content: (
          <>
            <p>{defaltText.mensagem.removerConfirmacao}</p>
            <p>{props.pagamento.nome}</p>
          </>
        ),
        closeText: defaltText.btn.fechar,
        action: {
          acao: () => props.deletarPagamento(props.pagamento),
          texto: defaltText.btn.remover,
          class: 'btn-danger'
        }
      }}
    />
  )
}

const mapStateToProps = store => {
  return {
    statusModal: store.pagamentosState.statusModal,
    locales: store.authentication.user.preferencia.language,
    pagamento: store.pagamentosState.pagamento
  }
}
const mapDispatchToProps = {
  fecharModalPagamento,
  deletarPagamento
}

export default connect(mapStateToProps, mapDispatchToProps)(Remover)
