import React from 'react';

export function SigCloudLogo(props) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="176.27" height="92.167" viewBox="0 0 1813 772" preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,772.000000) scale(0.100000,-0.100000)"
                stroke="none">
                <path d="M13285 7633 c-475 -23 -958 -156 -1365 -376 -102 -55 -359 -221 -431
                -278 l-27 -21 -93 35 c-115 43 -349 102 -484 122 -582 85 -1158 -7 -1666 -266
                -687 -350 -1189 -978 -1379 -1723 -21 -83 -44 -188 -51 -233 -11 -74 -15 -83
                -33 -83 -38 0 -258 -58 -381 -100 -235 -81 -514 -236 -720 -403 -97 -78 -284
                -266 -361 -362 -149 -187 -297 -448 -379 -670 -47 -125 -99 -328 -122 -476
                -25 -160 -24 -498 1 -664 79 -522 310 -980 681 -1350 371 -372 822 -599 1355
                -682 109 -17 313 -18 4080 -18 3777 0 3971 1 4085 18 513 78 976 310 1340 672
                357 355 589 804 676 1310 27 154 37 477 20 625 -94 819 -578 1521 -1301 1890
                -132 67 -120 49 -135 195 -18 179 -69 416 -131 606 -121 377 -292 694 -554
                1031 -83 107 -380 405 -490 492 -544 430 -1143 663 -1815 706 -153 10 -186 10
                -320 3z m375 -423 c791 -72 1508 -474 1987 -1117 329 -441 516 -953 548 -1498
                4 -60 11 -125 17 -144 18 -58 71 -106 156 -141 474 -196 838 -532 1055 -973
                363 -737 228 -1626 -337 -2225 -308 -326 -723 -539 -1162 -597 -101 -13 -593
                -15 -4009 -15 -4276 0 -3997 -4 -4241 59 -347 91 -626 247 -883 496 -180 174
                -299 338 -406 560 -145 300 -203 573 -192 919 9 308 68 541 205 811 298 585
                863 983 1519 1069 128 16 178 39 218 96 26 37 33 72 55 245 97 784 621 1478
                1350 1788 274 116 527 172 835 184 349 13 676 -45 972 -173 110 -48 168 -55
                226 -30 18 8 75 48 127 91 271 219 585 384 934 489 177 54 357 87 586 109 82
                8 334 6 440 -3z"/>
                <path d="M8487 3644 c-424 -76 -722 -374 -819 -819 -28 -127 -35 -423 -14
                -560 46 -301 179 -551 374 -706 304 -239 785 -292 1177 -128 90 37 241 135
                279 180 47 56 56 142 21 209 -47 89 -121 99 -237 30 -134 -80 -232 -128 -308
                -152 -65 -20 -96 -23 -225 -23 -168 0 -248 18 -355 78 -117 66 -229 216 -274
                367 -60 201 -60 559 0 760 28 95 94 210 159 276 67 68 161 121 266 150 91 25
                328 26 419 1 86 -23 197 -77 278 -133 67 -47 128 -74 164 -74 72 0 132 78 133
                170 0 73 -23 111 -110 177 -126 96 -296 167 -473 198 -106 19 -350 18 -455 -1z"/>
                <path d="M9965 3631 c-48 -22 -69 -44 -90 -94 -13 -32 -15 -159 -15 -1033 0
                -732 3 -1007 12 -1036 37 -124 225 -164 329 -71 22 20 43 50 49 71 6 24 10
                391 10 1037 0 949 -1 1002 -19 1040 -10 22 -27 48 -37 57 -55 49 -167 62 -239
                29z"/>
                <path d="M15646 3640 c-37 -12 -85 -56 -102 -97 -11 -26 -14 -116 -14 -435
                l-1 -403 -21 30 c-74 104 -195 188 -318 221 -79 21 -240 23 -325 5 -80 -17
                -208 -78 -272 -130 -106 -86 -197 -236 -241 -400 -22 -84 -25 -116 -25 -261
                -1 -182 11 -254 63 -387 56 -146 164 -280 283 -351 184 -111 442 -123 629 -29
                61 30 174 130 211 187 16 25 16 25 17 -30 2 -100 34 -158 105 -191 92 -42 212
                -13 263 64 l27 41 3 990 c2 693 0 1006 -8 1042 -23 109 -150 171 -274 134z
                m-415 -984 c196 -46 299 -216 299 -496 0 -244 -79 -409 -229 -478 -73 -34
                -219 -43 -304 -18 -112 33 -198 126 -244 265 -26 79 -28 98 -28 236 0 157 12
                221 57 308 77 152 263 227 449 183z"/>
                <path d="M11280 2970 c-102 -12 -188 -38 -280 -83 -117 -57 -248 -186 -308
                -303 -77 -149 -110 -338 -92 -521 39 -399 272 -655 653 -714 103 -16 303 -6
                397 21 268 76 447 260 522 538 17 62 21 109 22 237 1 197 -15 277 -86 424 -43
                89 -62 116 -132 186 -125 125 -274 193 -471 215 -100 11 -130 11 -225 0z m292
                -332 c162 -74 237 -248 225 -523 -8 -189 -47 -297 -137 -379 -74 -67 -118 -81
                -260 -81 -115 0 -130 2 -181 26 -155 73 -222 218 -222 479 -1 147 13 222 58
                309 72 143 183 203 360 198 83 -3 111 -8 157 -29z"/>
                <path d="M12647 2956 c-48 -18 -74 -41 -99 -89 -18 -37 -19 -58 -15 -550 4
                -562 5 -571 68 -703 88 -186 277 -283 528 -272 191 9 329 79 431 220 l27 37 5
                -64 c3 -35 14 -81 25 -102 60 -116 270 -115 338 1 l25 43 0 677 c0 549 -3 683
                -14 709 -47 113 -229 143 -324 54 -58 -54 -57 -44 -62 -537 l-5 -455 -29 -63
                c-74 -160 -251 -243 -426 -198 -82 21 -122 52 -157 123 l-28 58 -5 495 c-4
                341 -9 503 -17 521 -16 35 -66 82 -105 96 -44 16 -115 15 -161 -1z"/>
                <path d="M2357 3679 c-21 -5 -50 -15 -65 -23 -119 -62 -137 -251 -33 -339 35
                -29 116 -57 166 -57 50 0 131 28 166 57 96 81 90 249 -11 322 -61 44 -143 59
                -223 40z"/>
                <path d="M771 3639 c-124 -26 -234 -70 -323 -128 -202 -132 -307 -316 -308
                -538 0 -266 132 -438 426 -553 59 -23 221 -70 358 -105 437 -109 528 -161 543
                -308 12 -115 -53 -229 -160 -281 -98 -48 -168 -60 -337 -60 -227 1 -397 45
                -545 139 -140 90 -198 108 -255 78 -48 -25 -80 -84 -80 -151 0 -75 27 -122
                102 -175 222 -157 579 -243 905 -216 164 13 278 42 398 102 245 121 374 317
                375 567 0 160 -42 262 -151 365 -125 118 -279 183 -647 276 -322 81 -429 126
                -485 201 -99 133 -36 344 126 422 95 46 169 60 322 59 205 -1 324 -39 506
                -159 120 -79 172 -91 229 -51 46 31 70 81 70 145 0 63 -16 97 -67 143 -127
                115 -310 198 -513 233 -134 23 -367 21 -489 -5z"/>
                <path d="M3889 3644 c-334 -60 -586 -237 -734 -517 -208 -392 -187 -987 48
                -1340 178 -267 461 -419 835 -446 287 -22 698 45 843 137 92 59 101 115 97
                626 -3 370 -4 386 -24 412 -11 15 -33 37 -48 48 -27 20 -40 21 -381 21 -342 0
                -354 -1 -382 -21 -85 -62 -82 -193 6 -242 36 -21 52 -22 250 -22 l211 0 0
                -290 0 -289 -37 -11 c-122 -34 -214 -44 -388 -44 -139 0 -196 4 -250 17 -237
                60 -402 227 -464 468 -52 204 -54 472 -5 679 9 36 36 107 61 159 104 216 286
                325 568 338 205 9 363 -31 521 -132 149 -96 200 -111 258 -76 48 30 71 76 71
                146 0 76 -25 118 -106 181 -126 97 -297 169 -473 199 -110 19 -372 19 -477 -1z"/>
                <path d="M2335 2951 c-46 -21 -79 -54 -94 -94 -8 -19 -11 -249 -11 -703 0
                -655 1 -676 20 -714 30 -58 94 -92 173 -92 109 0 176 48 196 143 15 67 15
                1269 1 1337 -6 27 -22 61 -37 76 -58 63 -169 84 -248 47z"/>
            </g>
        </svg>
    );
}

//Logo antiga
{/* <svg xmlns="http://www.w3.org/2000/svg" width="166.27" height="82.167" viewBox="0 0 166.27 82.167">
            <title>{props.title ? props.title : null}</title>
            <g id="Grupo_26" data-name="Grupo 26" transform="translate(-1239 -555.333)">
                <text id="SiG" transform="translate(1239 621.104)" font-size="30" font-family="Nunito-Regular, Nunito"><tspan x="0" y="0">SiG</tspan></text>
                <path id="Icon_awesome-cloud" data-name="Icon awesome-cloud" d="M91.4,35.335a16.341,16.341,0,0,0-15.233-22.2A16.238,16.238,0,0,0,67.1,15.885,27.2,27.2,0,0,0,16.321,29.452c0,.459.017.918.034,1.377a24.489,24.489,0,0,0,8.127,47.587H87.048A21.761,21.761,0,0,0,91.4,35.335Z" transform="translate(1293.461 556.083)" fill="none" stroke-width="6" />
                <text id="Cloud" transform="translate(1303.445 620.336)" font-size="30" font-family="Nunito-SemiBold, Nunito" font-weight="600"><tspan x="0" y="0">Cloud</tspan></text>
            </g>
        </svg> */}