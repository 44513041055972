import React from 'react'
import './progress.css'

const Progress = ({ text, type, progressVale, border, progressColor = "#00a335" }) => {
  return (
    <div className='progress' style={{ border: border}} title={text}>
      <div className={type ? `progress-bar ${type}` : 'progress-bar'} style={{ width: `${progressVale}%`, backgroundColor: progressColor }} />
    </div>
  )
}

export default Progress
