import { cnpjValidate, cpfValidate, validacaoIdentificadorCpe, validacaoIp, validacaoSenha } from '../utils/utils';
import { validacaoDosCampos, validacaoDoCampo } from './Validation';

export const VALIDAR_CAMPO = 'VALIDAR_CAMPO';
export const VALIDAR_CAMPOS = 'VALIDAR_CAMPOS';
export const RESETAR_VALIDACAO = 'RESETAR_VALIDACAO';
export const INICIALIZAR_VALIDACAO = 'INICIALIZAR_VALIDACAO';

export const validarCampoAction = (e, onlyPattern) => ({
  type: VALIDAR_CAMPO,
  payload: validacaoDoCampo(e, onlyPattern)
});

export const inicializarValidacao = (lista) => {
  console.log(lista)
  const objetoValidacao = lista.map(item => item.id).reduce((acumulador, name) => {
    const copia = {... acumulador}
    copia[name] = true
    return copia
  }, {})
  return {type: INICIALIZAR_VALIDACAO, payload: objetoValidacao}
} 

export const validarCamposAction = e => ({
  type: VALIDAR_CAMPOS,
  payload: validacaoDosCampos(e)
});

export const resetarCamposAction = () => ({
  type: RESETAR_VALIDACAO
});

export const validarAction = e => {
  let erro = false;
  for (const name in e.payload) {
    if (e.payload[name]) {
      erro = true;
    }
  }
  return erro;
};

export const validarCpfCnpj = (documento) => ({
  type: VALIDAR_CAMPOS,
  payload: { documento: documento.length === 11 ? (cpfValidate(documento.toString()) ? false : "invalid") : (cnpjValidate(documento.toString()) ? false : "invalid") }
});

export const validarSenha = (senha) => ({
  type: VALIDAR_CAMPOS,
  payload: { senha: validacaoSenha(senha) === 'media' ? false : validacaoSenha(senha) === 'forte' ? false : "invalid" }
});

export const validarIdentificadorCpe = (identificador) => ({
  type: VALIDAR_CAMPOS,
  payload: { identificador: validacaoIdentificadorCpe(identificador) === true ? false : "invalid" }
});

export const validarIp = (ip) => ({
  type: VALIDAR_CAMPOS,
  payload: { ip: validacaoIp(ip) ? false : "invalid" }
});