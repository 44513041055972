import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import ConteudoApagarHistorico from './conteudoApagarHistorico';

import { fecharModal, atualizarLimiares, apagarHistoricoAlarme } from '../redux/AlarmesActions';



const ApagarHistorico = () => {

    const dispatch = useDispatch();

    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const statusModal = useSelector((state) => state.alarmesState.statusModal);

    const limiares = useSelector((state) => state.alarmesState.limiares);

    const title = defaltText.inputs.limiares;
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModal())}
            containerModal={{
                title,
                size: 'medium',
                content: <ConteudoApagarHistorico />,
                closeText: "Fechar",
                action: {
                    btnId: "btnApagar",
                    acao: () => dispatch(apagarHistoricoAlarme()),
                    texto: defaltText.btn.apagar,
                    class: "success"
                }
            }}

        />
    );


};


export default ApagarHistorico;