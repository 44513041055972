import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CorrectIcon } from '../../../layouts/components/icons';

const idiomaText = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userLocales = user.preferencia.linguagem;
    const locales = userLocales ? (userLocales === 'EN_US' ? 'en-US' : 'pt-BR') : null;
    const defaltText = require(`../nls/${locales ? locales : 'pt-BR'}.json`);
    return defaltText.mensagem;
};

export const SET_TIPO_MODAL = 'SET_TIPO_MODAL';
const setTipoModal = tipo => ({
    type: SET_TIPO_MODAL,
    payload: tipo
});

export const SET_NOTIFICACAO = 'SET_NOTIFICACAO';
const setNotificacao = notificacao => ({
    type: SET_NOTIFICACAO,
    payload: notificacao
});

export const abrirModalInformacao = (notificacao) => [setNotificacao(notificacao), setTipoModal('informacao')];

export const fecharModalNotificacoes = (page) => [setNotificacao(), setTipoModal('')];

export const abrirModalDeletar = (notificacao) => [setNotificacao(notificacao), setTipoModal('deletando')];

export const LIMPAR_CAMPO_PESQUISA_NOTIFICACOES = 'LIMPAR_CAMPO_PESQUISA_NOTIFICACOES';
export const limparCampoPesquisa = () => [
    {
        type: LIMPAR_CAMPO_PESQUISA_NOTIFICACOES
    },
    /* listarNotificacoes() */
];

export const ATUALIZAR_CAMPO_PESQUISA_NOTIFICACOES = 'ATUALIZAR_CAMPO_PESQUISA_NOTIFICACOES';
export const atualizarCampoPesquisa = e => ({
    type: ATUALIZAR_CAMPO_PESQUISA_NOTIFICACOES,
    payload: e.target.value
});

export const CARREGANDO_NOTIFICACOES = 'CARREGANDO_NOTIFICACOES';
const carregandoNotificacoes = () => ({
    type: CARREGANDO_NOTIFICACOES
});

export const NOTIFICACOES_CARREGADAS_COM_SUCESSO = 'NOTIFICACOES_CARREGADAS_COM_SUCESSO';
export const notificacoesCarregadasComSuceso = notificacoes => ({
    type: NOTIFICACOES_CARREGADAS_COM_SUCESSO,
    payload: notificacoes
});

export const NOTIFICACOES_CARREGADAS_COM_ERRO = 'NOTIFICACOES_CARREGADAS_COM_ERRO';
const notificacoesCarregadasComErro = erro => ({
    type: NOTIFICACOES_CARREGADAS_COM_ERRO,
    payload: erro
});

export const listarNotificacoes = (e) => {
    let qntNotificacaoNaoVistas = 0;
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        pesquisa: `&nome=${e && e.pesquisa ? e.pesquisa : ''}`
    };
    return dispatch => {
        dispatch(handleIsLoadingNotificationsStatus(true));
        dispatch(carregandoNotificacoes());
        axios
            .get(`/api/todasNotificacoes`)
            .then(response => {
                dispatch(notificacoesCarregadasComSuceso(response.data));
                response.data.forEach(notificacoes => {
                    notificacoes.visualizado === false && ++qntNotificacaoNaoVistas;
                    return qntNotificacaoNaoVistas;
                });
                dispatch(notificacoesNaoVistas(qntNotificacaoNaoVistas));
            })
            .catch(error => {
                dispatch(notificacoesCarregadasComErro(error));
                dispatch(handleIsLoadingNotificationsStatus(false));
            });
    };
};

export const deletarNotificacao = (notificacao) => {
    return (dispatch) => {
        axios
        .delete(`/api/notificacao/${notificacao.id}`)
        .then(response => {
            dispatch(notificacaoDeletadoComSucesso(response.data))
            dispatch(listarNotificacoes())
            toast.success((idiomaText().deletarSuccess), {
                icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
            });
        })
        .catch(erro => dispatch(notificacaoNaoDeletado(erro)))
    };
};

export const deletarTodasNotificacoes = () => {
    return (dispatch) => {
        axios
        .delete(`/api/todasNotificacoes/`)
        .then(response => {
            dispatch(notificacaoDeletadoComSucesso(response.data))
            dispatch(listarNotificacoes())
            toast.success((idiomaText().deletarSuccess), {
                icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
            });
        })
        .catch(erro => dispatch(notificacaoNaoDeletado(erro)))
    };
};

export const RESET_NOTIFICATIONS_STATE = "RESET_NOTIFICATIONS_STATE";
export const resetNotificationsState = () => ({
    type: RESET_NOTIFICATIONS_STATE,
});

export const HANDLE_IS_LOADING_NOTIFICATIONS_STATUS = "HANDLE_IS_LOADING_NOTIFICATIONS_STATUS";
export const handleIsLoadingNotificationsStatus = (status) => ({
    type: HANDLE_IS_LOADING_NOTIFICATIONS_STATUS,
    payload: status
});

export const IS_SEARCH = 'notificacao.IS_SEARCH';
export const isSearch = () => ({
    type: IS_SEARCH
});

export const NOTIFICACAO_VISUALIZADA_COM_SUCESSO = 'NOTIFICACAO_VISUALIZADA_COM_SUCESSO';
export const notificacaoVisualizadaSucesso = (notificacoes) => ({
    type: NOTIFICACAO_VISUALIZADA_COM_SUCESSO,
    payload: notificacoes
});

export const NOTIFICACAO_VISUALIZADA_ERRO = 'NOTIFICACAO_VISUALIZADA_ERRO';
const notificacaoNaoVisualizada = erro => ({
    type: NOTIFICACAO_VISUALIZADA_ERRO,
    payload: erro
});

export const NOTIFICACOES_NAO_VISTAS = 'NOTIFICACOES_NAO_VISTAS';
export const notificacoesNaoVistas = (qntNotificacoes) => ({
    type: NOTIFICACOES_NAO_VISTAS,
    payload: qntNotificacoes
});

export const setNotificacoesVisualizadas = (notificacoes) => {
    return (dispatch) => {
        axios
            .post(`/api/notificacao`, notificacoes)
            .then(response => {
                dispatch(notificacaoVisualizadaSucesso(response.data));
                dispatch(notificacoesNaoVistas(0));
            })
            .catch(erro => dispatch(notificacaoNaoVisualizada(erro)));
    };
};

export const NOTIFICACAO_ATUALIZADOS_COM_SUCESSO = "NOTIFICACAO_ATUALIZADOS_COM_SUCESSO";
export const notificaoAtualizadosComSucesso = (notificacao) => ({
    type: NOTIFICACAO_ATUALIZADOS_COM_SUCESSO,
    payload: notificacao
});

export const NOTIFICACAO_DELETADO_COM_SUCESSO = 'NOTIFICACAO_DELETADO_COM_SUCESSO';
const notificacaoDeletadoComSucesso = notificacao => ({
    type: NOTIFICACAO_DELETADO_COM_SUCESSO,
    payload: notificacao
});

export const NOTIFICACAO_NAO_DELETADO = 'NOTIFICACAO_NAO_DELETADO'
const notificacaoNaoDeletado = erro => ({
    type: NOTIFICACAO_NAO_DELETADO,
    payload: erro
})