import React, { useEffect } from 'react';
import Router from './router';
import { ToastContainer } from 'react-toastify';
import Websocket from './websockets/Websocket';
import { interceptador } from './modulos/usuario/interceptador/interceptador';
import AxiosInterceptor from './modulos/usuario/interceptador/axiosInterceptor';
import ModalNotificacao from './modulos/notificacoes/modalNotificacao';

import 'react-toastify/dist/ReactToastify.css';
import './app.css';
import './layouts/temas/temas.css';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { preferenciaAtualizadaComSuceso } from './layouts/redux/LayoutActions';
import { usuarioLogadoSucesso } from './modulos/usuario/login/redux/UserActions';

import { toast } from 'react-toastify';
import Button from './components/button/Button';
import { IcoFecharNovo, IcoInfo } from './components/icones';

export default () => {
  const isLogado = useSelector((state) => state.authentication.loggedIn);
  const wasLogged = useSelector((state) => state.authentication.wasLogged);
  const dispatch = useDispatch();

  const locales = useSelector((state) => state.layoutState.linguagem);
  const defaultText = require(`./modulos/usuario/nls/${locales}.json`);

  const CloseToast = ({ closeToast }) => (
    <Button
      id='fecharToast'
      titulo="Fechar"
      action={closeToast}
      children={<IcoFecharNovo title="fechar" />}
    />
  );

  useEffect(() => {
    interceptador();
    if (isLogado !== undefined && isLogado === true) {
      axios
        .get('/api/autorizacao/obterUsuarioLogado')
        .then((response) => {
          const defaultPreferences = {
            contrast: true,
            language: navigator.language
          };
          const userInfo = {
            ...response.data,
            preferencia: response.data.preferencia ? response.data.preferencia : defaultPreferences
          };
          dispatch(usuarioLogadoSucesso(userInfo));
          dispatch(preferenciaAtualizadaComSuceso(userInfo));
        });
    }
    else {
      if (wasLogged && (window.location.pathname === "/login")) {
        toast.error((defaultText.mensagem.sessaoExpirada), {
          icon: <div className='divIconToastError'><IcoInfo /></div>
        });
      }
    }
  }, [isLogado]);

  return (
    <>
      <Websocket />
      <AxiosInterceptor />
      <ToastContainer autoClose={3000} closeButton={CloseToast} pauseOnFocusLoss={true} />
      <Router />
      <ModalNotificacao />
    </>
  );
};
