import React, { useState, useRef, useEffect } from 'react';
import Button from '../../button/Button';

import { IcoFechar, IcoSearch } from '../../icones';
import InputInbox from '../inputInBox/InputInbox';

export const MultiSelectInBox = ({ id, label, selectDefault, select, validate, position }) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [inputsSearch, setInputsSearch] = useState('');
  const [optionsFilter, setOptionsFilter] = useState(select.options);
  const [nSElected, setNSElected] = useState(Object.keys(select.selected).length);
  const InfoDefault = {
    target: {
      type: 'multiselect',
      name: id,
      value: {}
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSelectOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const searchAction = () => {
    if (inputsSearch.length) {
      const listSearch = select.options.filter(
        opt => [selectDefault.label ? opt[selectDefault.label] : opt].indexOf(inputsSearch) !== -1
      );
      setOptionsFilter(listSearch);
    }
  };

  const apagar = () => {
    setInputsSearch('');
    setOptionsFilter(select.options);
  };

  const ChangeSelect = (e, option) => {
    if (e.target.checked) {
      InfoDefault.target.value = [...select.selected, option];
      select.action(InfoDefault);
      setNSElected(InfoDefault.target.value.length);
    } else {
      InfoDefault.target.value = select.selected.filter(o => o !== e.target.name);
      select.action(InfoDefault);
      setNSElected(InfoDefault.target.value.length);
    }
  };

  const selectAllOption = (e) => {
    setSelectAll(e);
    if (e) {
      InfoDefault.target.value = optionsFilter;
      select.action(InfoDefault);
      setNSElected(InfoDefault.target.value.length);
    } else {
      InfoDefault.target.value = {};
      select.action(InfoDefault);
      setNSElected(0);
    }
  };

  const textDefault = () => {
    if (nSElected === 1) {
      return `${selectDefault.text.selecionado.um}(${nSElected})`;
    }
    if (nSElected > 1) {
      return `${selectDefault.text.selecionado.mais}(${nSElected})`;
    }
    return selectDefault.text.selecionado.nenhum;
  };

  const verifySelection = opcao => {
    const lista = select.selected.filter(o => o.indexOf(opcao) !== -1);
    return lista.length > 0;
  };

  return (
    <div
      ref={wrapperRef}
      className={`box-form-select multiselect ${validate && validate.erro ? 'erro' : ''} ${!!selectOpen ? 'open' : ''}`}
    >
      <button onClick={() => setSelectOpen(!selectOpen)}>
        <label htmlFor='*'>{label}</label>
        {textDefault()}
      </button>
      <div
        className='select-options'
        style={{
          position: position ? position : "absolute",
          display: !!selectOpen ? 'block' : 'none',
          maxHeight: selectDefault.maxWidth ? selectDefault.maxWidth : '300px'
        }}
      >
        <div className='select-search'>
          <InputInbox
            name='search-select'
            placeholder={selectDefault.text.pesquisar.texto}
            value={inputsSearch}
            changeInput={e => setInputsSearch(e.target.value)}
            search={{
              action: e => apagar(),
              text: <IcoFechar title={selectDefault.text.pesquisar.limpar} />
            }}
          />
          <div>
            <Button
              titulo={selectDefault.text.pesquisar.texto}
              onClick={() => searchAction()}
              color='secondary'
              size='medium'
            >
              <IcoSearch title={selectDefault.text.pesquisar.texto} />
            </Button>
          </div>
        </div>
        <div className='select-actions select-option'>
          <input
            type='checkbox'
            checked={selectAll}
            onChange={e => selectAllOption(!selectAll)}
            id='select-all'
            name='select-all'
          />
          <label htmlFor='select-all'>
            {selectDefault.text.acoes.selecioneTudo ? selectDefault.text.acoes.selecioneTudo : 'Selecione todos'}
          </label>
        </div>
        {optionsFilter.map(opcao => {
          return (
            <div className='select-option' key={`${id}-${selectDefault.id ? opcao[selectDefault.id] : opcao}`}>
              <input
                type='checkbox'
                checked={verifySelection(selectDefault.id ? opcao[selectDefault.id] : opcao)}
                onChange={e => ChangeSelect(e, opcao)}
                id={`${id}-${selectDefault.id ? opcao[selectDefault.id] : opcao}`}
                name={selectDefault.id ? opcao[selectDefault.id] : opcao}
              />
              <label htmlFor={`${id}-${selectDefault.id ? opcao[selectDefault.id] : opcao}`}>
                {selectDefault.label ? opcao[selectDefault.label] : opcao}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
