import { useDispatch, useSelector } from 'react-redux';

import { SelectInBox } from '../../../components/form';
import React, { useEffect, useState } from 'react';
import { atualizarConteudoCpeModal, clearCpeField, resetarSlotPonNovoCpe } from '../redux/CpeActions';
import { validarCampoAction } from '../../../validation/validationActions';
import Row from '../../../components/row/row';
import { listarOltsOk } from '../../olt/redux/OltActions';
import Button from '../../../components/button/Button';

import '../cpe.css';
import Input from '../../../layouts/components/Input/Input';
import { listarPlanosFiltrado } from '../../planos/redux/PlanosActions';
import DropdownList from '../../../components/dropdownList/dropdownList';
import { IcoMenos } from '../../../components/icones/menos';
import { IcoMais } from '../../../components/icones';
import ConteudoDropdown8820i from '../../servicos/criarEditar/dropdownContent/conteudoDropdown8820i';
import ConteudoDropdownContent from '../../servicos/criarEditar/dropdownContent/conteudoDropdownContent';
import { MaskItemIdentificador } from '../../../components/mask/Mask';
import RadioButton from '../../../components/form/radioButton/RadioButton';

const ConteudoCpeModal = ({ oltSelected, setOltSelected }) => {

    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const cpe = useSelector(state => state.cpesState.cpe);
    const olts = useSelector(state => state.oltsState.olts);
    const mensagemErro = useSelector(state => state.validacaoState);
    const planos = useSelector(state => state.planosState.planos);
    const planoSelecionado = useSelector((state) => state.cpesState.cpe.plano);
    const paginacaoPlano = useSelector(state => state.planosState.paginacao);
    const [pppoeStatus, setPppoeStatus] = useState("ativar");
    const [isPPPoe, setIsPPPoe] = useState([
        { id: "ativar", text: 'Ativar' },
        { id: "desativar", text: 'Desativar' }])
    const [dropdownState, setDropdownState] = useState({
        advancedSettings: null
    });

    const [slotPon, setSlotPon] = useState({
        slotPon: null
    });

    const handleSlotPont = (e) => {
        let porta = e.target.value;
        let NewSlotPon = porta && porta.slots && porta.slots.map((slot, index) => ({ id: index, number: slot }));
        setSlotPon(NewSlotPon);
    };

    const handleDropdownState = (e) => {
        let actualStatus = dropdownState[e.currentTarget.value];
        let newArray = {
            ...dropdownState
        };
        newArray[e.currentTarget.value] = actualStatus === null ? true : !actualStatus;
        setDropdownState(newArray);
    };

    const handleSelectOlt = (e) => {
        setOltSelected(e.target.value)
    }

    useEffect(() => {
        dispatch(listarPlanosFiltrado(0));
        dispatch(listarOltsOk({}));
    }, [dispatch]);

    const listarPlanos = e => {
        dispatch([atualizarConteudoCpeModal(e), listarPlanosFiltrado({ modelo: e.target.value.model })]);
    };

    const handleDropdownOpen = (e) => {
        if (oltSelected?.modelo !== "OLT_8820I") {
            let plano = e.target.value;
            let pppoeConfig = plano.listaConfiguracoes?.filter(config => config.tipoServico === "PPPOE");
            pppoeConfig?.map((config) => {
                if (config.pppoeSenha === null && config.pppoeUsuario === null) {
                    let openDropdown = {
                        advancedSettings: true
                    };
                    setDropdownState(openDropdown);
                } else {
                    let closeDropdown = {
                        advancedSettings: false
                    };
                    setDropdownState(closeDropdown);
                }
            });
        } else {
            let openDropdown = {
                advancedSettings: true
            };
            setDropdownState(openDropdown);
        }

    };

    return (
        <section>
            <Row>
                <div className="col-md-12">
                    <SelectInBox
                        key={'olt'}
                        id='olt'
                        label={defaltText.inputs.olt}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            text: defaltText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.olt ? defaltText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => [/* , dispatch(resetarTodasAsCombosNovoCpe(e)) */, handleSelectOlt(e)],
                            options: olts.filter(olts => olts.operationMode !== "AUTOSERVICE"),
                            selected: oltSelected ? oltSelected : {}
                        }}
                    />
                </div>
            </Row>

            <Row>
                <div className="col-md-12">
                    <SelectInBox
                        id='porta'
                        key={'porta'}
                        label={defaltText.inputs.porta}
                        selectDefault={{
                            id: 'nome',
                            label: 'nomeInterface',
                            text: defaltText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.porta ? defaltText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => [dispatch(atualizarConteudoCpeModal(e)), dispatch(resetarSlotPonNovoCpe(e)), handleSlotPont(e)],
                            options: cpe && oltSelected && oltSelected.portasG ? oltSelected.portasG : {},
                            selected: cpe.porta
                        }}
                        disabled={!oltSelected ? true : false}
                    />
                </div>
            </Row>

            <Row>
                <div className="col-md-12">
                    <SelectInBox
                        id='slot'
                        label={defaltText.inputs.slot}
                        selectDefault={{
                            id: 'id',
                            label: 'number',
                            text: defaltText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.slot ? defaltText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => dispatch(atualizarConteudoCpeModal(e)),
                            options: slotPon ? slotPon : [],
                            selected: cpe.slot
                        }}
                        disabled={cpe.porta === "" ? true : false}
                    />
                </div>
            </Row>

            <Row>
                <div className="col-md-12">
                    <SelectInBox
                        key={'modelo'}
                        id='modelo'
                        label={defaltText.inputs.modelo}
                        selectDefault={{
                            id: 'type',
                            label: 'model',
                            text: defaltText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.modelo ? defaltText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => listarPlanos(e),
                            options: cpe && oltSelected && oltSelected.modeloCpes ? oltSelected.modeloCpes.filter(cpes => cpes.model !== "Outros Fabricantes") : [],
                            selected: cpe.modelo
                        }}
                        disabled={!oltSelected ? true : false}
                    />
                </div>
            </Row>

            <Row>
                <div className='col-md-12'>
                    <SelectInBox
                        key={'plano'}
                        id='plano'
                        label={defaltText.inputs.plano}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            text: defaltText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.plano ? defaltText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => [dispatch(atualizarConteudoCpeModal(e)), handleDropdownOpen(e)],
                            options: planos,
                            selected: cpe.plano
                        }}
                        moreOptions={{
                            page: paginacaoPlano,
                            action: e =>
                                dispatch(
                                    listarPlanosFiltrado({
                                        pagina: paginacaoPlano.pageNumber + 1,
                                        modelo: Object.keys(cpe.modelo).length === 2 ? cpe.modelo.model : cpe.modelo
                                    })
                                ),
                            texto: defaltText.btn.maisItens
                        }}
                        disabled={cpe.modelo === "" ? true : false}
                    />
                </div>
            </Row>

            <Row>
                <div className={`col-md-12 ${planoSelecionado && planoSelecionado.listaConfiguracoes && (planoSelecionado.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE").length > 0 || oltSelected.modelo === "OLT_8820I")  && pppoeStatus === "ativar" ? "show" : "hide"}`}>
                    <DropdownList
                        title={defaltText.tituloConfiguracoesAvancadas}
                        button={
                            <Button
                                type="btn circle"
                                children={dropdownState.advancedSettings ? <IcoMenos title="Fechar" /> : <IcoMais title="Abrir" />}
                                value="advancedSettings"
                                action={(e) => { handleDropdownState(e); }}
                                color="transparent"
                            />
                        }
                        dropdownContent={
                            oltSelected?.modelo === "OLT_8820I" ?
                                <ConteudoDropdown8820i
                                    dropdownState={dropdownState}
                                />
                                :
                                <ConteudoDropdownContent dropdownState={dropdownState}
                                    mensagemErro={mensagemErro} />
                        }
                        validate={{
                            erro: (mensagemErro.usuarioPPPoE || mensagemErro.senhaPPPoE) ? defaltText.mensagem.obrigatorio : ''
                        }}
                    />
                </div>
            </Row>

            <Row>
            <div style={{ paddingLeft: '3px', marginTop: '5px' }} className={`col-md-12 ${planoSelecionado && planoSelecionado.listaConfiguracoes && (planoSelecionado.listaConfiguracoes.filter(config => config.tipoServico === "PPPOE").length > 0 || oltSelected.modelo === "OLT_8820I") ? "show" : "hide"}`} >
                    <div className='col-md-12'>
                        <p style={{ paddingBottom: '5px' }}>{defaltText.inputs.pppoeDesativar} </p>
        
                        <RadioButton
                            id="pppoeActive"
                            name='pppoeActive'
                            changeInput={e => [dispatch(atualizarConteudoCpeModal(e)), setPppoeStatus(e.target.value)]}
                            selected={pppoeStatus}
                            options={isPPPoe}
                        />
                    </div>
                </div>
            </Row>
                
            <Row>
                <div className="col-md-12">
                    <Input
                        id="identificador"
                        name='identificador'
                        label={defaltText.inputs.identificadorEx}
                        value={MaskItemIdentificador(cpe.identificador)}
                        changeInput={e => dispatch(atualizarConteudoCpeModal(e))}
                        validate={{
                            action: e => dispatch(validarCampoAction(e, true)),
                            erro: mensagemErro.identificador && mensagemErro.identificador === "invalid" ? defaltText.mensagem.padraoObrigatorioIdentificador : (mensagemErro.identificador ? defaltText.mensagem.obrigatorio : '')
                        }}
                        length={{
                            max: '13'
                        }}
                        clearField={{
                            action: (e) => dispatch(clearCpeField(e)),
                            text: defaltText.btn.limparCampo
                        }}
                    />
                </div>
            </Row>

        </section >
    );
};

export default ConteudoCpeModal;
