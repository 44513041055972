import React from 'react';

export function SyncIcon(props) {
    return (

        <svg
            className={`${props.className ? `${props.className}` : ""}`}
            width="19.327"
            height="15.563"
            viewBox="0 0 19.327 15.563"
            style={props.style ? props.style : {}}
        >
            <title>{props.title ? props.title : null}</title>
            <g transform="translate(-0.086 -3.47)">
                <path d="M6.845,6v4.5H1.5" transform="translate(11.155 -0.748)" fill="none" stroke={props.color ? props.color : "#389cff"} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path d="M25.5,25.5V21h5.345" transform="translate(-24 -8.248)" fill="none" stroke={props.color ? props.color : "#389cff"} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path d="M3.382,9A6.75,6.75,0,0,1,14.52,6.482L18,9.752m-16.5,3,3.48,3.27A6.75,6.75,0,0,0,16.117,13.5" transform="translate(0)" fill="none" stroke={props.color ? props.color : "#389cff"} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>
    );
}