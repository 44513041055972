import React from 'react';
import "./cards.css";
import { useDispatch, useSelector } from 'react-redux';
import CardHeaderButton from '../cardHeaderButton/CardHeaderButton';
import { AttentionIcon, CorrectIcon, InfoIcon, SyncIcon } from '../icons';
import { getPagePermissions, translateStatus } from '../../../utils/utils';
import CardButton from '../cardButton/CardButton';
import { abrirModalDeletarPlano, abrirModalInformacaoPlano } from '../../../modulos/planos/redux/PlanosActions';
import { IcoRemoveLine } from '../../../components/icones';

const PlanCard = ({ markerColor = "ok", item, labels }) => {
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../../modulos/planos/nls/${locales}.json`);
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const getPlanoCardHeaderButton = (val) => {
        if (val.status === 'OK') {
            return <CardHeaderButton icon={<CorrectIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
        }
        else {
            if (val.status.includes("ERRO")) {
                return <CardHeaderButton icon={<AttentionIcon title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
            else {
                return <CardHeaderButton icon={<SyncIcon className="spin" title={translateStatus(val.status, locales)} />} markerColor={markerColor} />;
            }
        }
    };

    return (
        <div className={`cartao ${markerColor}`}>
            <div className="cartao-cabecalho">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p>
                        <span className="bold-text">{item[labels[0].field]}</span>
                        <span className="tooltip">{item[labels[0].field]}</span>
                    </p>
                    <CardHeaderButton markerColor={markerColor} icon={getPlanoCardHeaderButton(item)} />
                </div>
                <div className="section-divisor"></div>
            </div>

            <div className="cartao-corpo">
                {labels.map((label, index) => index > 0 && <p>{label.text}: <span className={`bold-text ${label.field === "status" && markerColor}`}>{translateStatus(item[label.field], locales)}</span></p>)}
            </div>
            <div className="cartao-rodape">
                <CardButton
                    id="btnInfo"
                    label={defaultText.btn.informacoes}
                    action={() => dispatch(abrirModalInformacaoPlano(item))}
                    icon={<InfoIcon />}
                />

                <div className="vertical-section-divisor"></div>

                <CardButton
                    hasAccess={getPagePermissions(permissions, "planos", "delete")}
                    id="btnRemover"
                    label={defaultText.btn.remover}
                    action={() => dispatch(abrirModalDeletarPlano(item))}
                    icon={<IcoRemoveLine />}
                    disabled={(item.status !== "OK") ? true : false}
                />
            </div>
        </div>
    );
};

export default PlanCard;