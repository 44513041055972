import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../../components/modal/BasicModal';

import ConteudoCriarEditarVlan from './conteudoCriarEditarVlan';

import { adicionarVlan, atualizarVlan, fecharModalVlan } from '../../redux/OltActions';

import '../vlans.css';

const CriarEditarVlan = (props) => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../../nls/${locales}.json`);
    const statusModal = useSelector(state => state.oltsState.statusModal);

    //ajustar para quando fizer a função de editar
    const vlan = useSelector(state => state.oltsState.vlan);
    const selectedVlan = useSelector(state => state.oltsState.selectedVlan);
    const vlans = useSelector(state => state.oltsState.vlans);
    const nomeInterface = useSelector(state => state.oltsState.selectedInterface.nomeInterface);
    const title = props.id === 'abrindoCriarVlan' ? defaltText.tituloModalCriarVlan : defaltText.tituloModalEditarVlan;
    const olt = useSelector(state => state.oltsState.olt);
    const action = props.id === 'abrindoCriarVlan' ? adicionarVlan : atualizarVlan;
    return (

        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalVlan())}
            containerModal={{
                title,
                size: 'medium',
                content: <ConteudoCriarEditarVlan id={props.id} />,
                closeText: defaltText.btn.fechar,
                action: {
                    btnId: "btnSalvarVlan",
                    acao: () => dispatch(action(olt.modelo, props.id === 'abrindoCriarVlan' ? vlan : selectedVlan, nomeInterface, vlans, props.id)),
                    //acao: () => console.log(olt),
                    texto: defaltText.btn.salvar,
                    class: 'success'
                }
            }}
        />

    );
};

export default CriarEditarVlan;
