import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../../components/button/Button';
import BasicModal from '../../../../components/modal/BasicModal';
import { backModalSelLoc, fecharModalAttention, fecharModalSelLoc } from '../redux/UserActions';


const AttentionMessage = ({ preferencia }) => {

    const dispatch = useDispatch();

    const statusModal = useSelector((state) => state.authentication.statusModal);

    const defaultText = preferencia.linguagem
        ? require(`../../nls/${preferencia.linguagem}.json`)
        : require(`../../nls/pt-BR.json`);

    const title = defaultText.localizacao.attentionTitle;
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalAttention())}
            containerModal={{
                title,
                size: 'medium',
                content:
                    <>
                        <p>{defaultText.localizacao.attentionMessage}</p>
                        <div className='col-md-12 botoes'>
                            <Button
                                value='campoVoltar'
                                id='btnCancelar'
                                titulo={defaultText.localizacao.backBtn}
                                action={() => dispatch(backModalSelLoc())}
                                children={defaultText.localizacao.backBtn}
                                color='secondary'
                                size='medium'
                                variant='modal'
                                type='btn'
                            />
                            <Button
                                value='campoExit'
                                id='btnExitModalAttention'
                                titulo={defaultText.localizacao.exitBtn}
                                action={() => dispatch(fecharModalAttention())}
                                children={defaultText.localizacao.exitBtn}
                                color='success'
                                size='medium'
                                variant='modal'
                                type='btn'
                            />
                        </div>
                    </>
                ,

                closeText: "Fechar"
            }}
        />

    );


};


export default AttentionMessage;
