import React from 'react';

export function TextareaInbox({
  type = 'textarea',
  name = '',
  label = '',
  value = '',
  changeInput,
  required = true,
  length = {},
  disabled = false,
  placeholder = false,
  validate
}) {
  return (
    <div className={`box-form ${value ? 'filled' : ''} ${validate && validate.erro ? 'erro' : ''}`}>
      <textarea
        name={name}
        onChange={changeInput}
        id={name}
        required={required}
        value={value}
        maxLength={length && length.max ? length.max : 50}
        minLength={length && length.min ? length.min : 0}
        placeholder={label && label}
        disabled={disabled}
      />
      <label htmlFor={name} className='form-label'>
        <sup>{validate && type !== 'only-see' ? <span className='text-danger'>✻</span> : ''} </sup>
        {label}
      </label>
      {validate && validate.erro ? <div className='invalid-feedback'>{validate.erro}</div> : null}
    </div>
  );
}
