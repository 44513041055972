import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InputInbox from '../../components/form/inputInBox/InputInbox';
import { IcoMais, IcoSearch } from '../../components/icones';

import Button from '../../components/button/Button';
import { abrirModalAdicionarColaborador, atualizarCampoPesquisa, isSearch, limparCampoPesquisa, listarColaboradores } from './redux/ColaboradorActions';
import Row from '../../components/row/row';
import { getPagePermissions } from '../../utils/utils';
import Input from '../../layouts/components/Input/Input';

export default () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);
    const campoPesquisa = useSelector(state => state.colaboradoresState.campoPesquisa);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const colaborador = useSelector((state) => state.colaboradoresState);

    const handleOnPressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(listarColaboradores({ pesquisaGeral: campoPesquisa })), dispatch(isSearch());
        }
    };

    return (
        <div>
            <Row style={{ justifyContent: "space-between" }}>
                <div className="col-md-6">
                    <div className='campo-pesquisa'>
                        <Input
                            id="pesquisar"
                            name='pesquisar'
                            label={defaltText.pesquisar.text}
                            value={campoPesquisa}
                            changeInput={e => dispatch(atualizarCampoPesquisa(e))}
                            onPressEnter={(e) => handleOnPressEnter(e)}
                            clearField={{
                                action: (e) => dispatch(limparCampoPesquisa(e)),
                                text: defaltText.btn.limparCampo
                            }}
                            leftButton={{
                                action: () => { dispatch(listarColaboradores({ pesquisaGeral: campoPesquisa })), dispatch(isSearch()); },
                                text: defaltText.pesquisar.text,
                                icon: <IcoSearch title={defaltText.pesquisar.text} />
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-2 div-new-button">
                    <Button
                        hasAccess={getPagePermissions(permissions, "colaboradores", "create")}
                        titulo={defaltText.btn.adicionarColaborador}
                        action={() => dispatch(abrirModalAdicionarColaborador())}
                        texto={defaltText.btn.adicionarColaborador}
                        children={<IcoMais className="btn-border" title={defaltText.btn.adicionarColaborador} />}
                        color='success'
                        type='btn'
                    />
                </div>
            </Row>
            {colaborador.isSearch === true && campoPesquisa !== '' ?
                <p className='qntRegisters'>
                    <span className='boldText'>
                        {colaborador.paginacao.totalElements}
                    </span> {defaltText.itens} <span className='boldText'>
                        "{campoPesquisa}"
                    </span>
                </p> : null}
            {colaborador.isSearch === true && campoPesquisa !== '' ? <div className='divisorLine'></div> : null}
        </div>
    );
};
