import { resetarCamposAction, validarAction, validarCamposAction } from '../../../validation/validationActions'

import axios from 'axios'

export const ATUALIZAR_CONTEUDO_PAGAMENTO_MODAL = 'ATUALIZAR_CONTEUDO_PAGAMENTO_MODAL'
export const atualizarConteudoPagamentoModal = evento => ({
  type: ATUALIZAR_CONTEUDO_PAGAMENTO_MODAL,
  payload: evento
})

export const SET_TIPO_MODAL_PAGAMENTO = 'SET_TIPO_MODAL_PAGAMENTO'
const setTipoModalPagamento = tipo => ({
  type: SET_TIPO_MODAL_PAGAMENTO,
  payload: tipo
})

export const SET_PAGAMENTO = 'SET_PAGAMENTO'
const setPagamento = pagamento => ({
  type: SET_PAGAMENTO,
  payload: pagamento
})

export const SET_PAGAMENTO_INICIAL = 'SET_PAGAMENTO_INICIAL'
const setPagamentoInicial = _ => ({
  type: SET_PAGAMENTO_INICIAL
})

export const ABRIR_MODAL_EDITAR_PAGAMENTO = 'ABRIR_MODAL_EDITAR_PAGAMENTO'
export const abrirModalEditarPagamento = pagamento => [
  resetarCamposAction(),
  setPagamento(pagamento),
  setTipoModalPagamento('editando')
]

export const ABRIR_MODAL_DELETAR_PAGAMENTO = 'ABRIR_MODAL_DELETAR_PAGAMENTO'
export const abrirModalDeletarPagamento = pagamento => [setPagamento(pagamento), setTipoModalPagamento('deletando')]

export const ABRIR_MODAL_CRIAR_PAGAMENTO = 'ABRIR_MODAL_CRIAR_PAGAMENTO'
export const abrirModalCriarPagamento = _ => [
  resetarCamposAction(),
  setPagamentoInicial(),
  setTipoModalPagamento('criando')
]

export const FECHAR_MODAL_PAGAMENTO = 'FECHAR_MODAL_PAGAMENTO'
export const fecharModalPagamento = _ => [setPagamentoInicial(), setTipoModalPagamento('')]

export const LIMPAR_CAMPO_PESQUISA_PAGAMENTO = 'LIMPAR_CAMPO_PESQUISA_PAGAMENTO'
export const limparCampoPesquisaPagamento = _ => ({
  type: LIMPAR_CAMPO_PESQUISA_PAGAMENTO
})

export const ATUALIZAR_CAMPO_PESQUISA_PAGAMENTO = 'ATUALIZAR_CAMPO_PESQUISA_PAGAMENTO'
export const atualizarCampoPesquisaPagamento = e => ({
  type: ATUALIZAR_CAMPO_PESQUISA_PAGAMENTO,
  payload: e.target.value
})

export const CARREGANDO_PAGAMENTOS = 'CARREGANDO_PAGAMENTOS'
const carregandoPagamentos = _ => ({
  type: CARREGANDO_PAGAMENTOS
})

export const PAGAMENTOS_CARREGADOS_COM_SUCESSO = 'PAGAMENTOS_CARREGADOS_COM_SUCESSO'
const pagamentosCarregadosComSuceso = pagamentos => ({
  type: PAGAMENTOS_CARREGADOS_COM_SUCESSO,
  payload: pagamentos
})

export const PAGAMENTOS_CARREGADOS_COM_ERRO = 'PAGAMENTOS_CARREGADOS_COM_ERRO'
const pagamentosCarregadosComErro = erro => ({
  type: PAGAMENTOS_CARREGADOS_COM_ERRO,
  payload: erro
})

export const listarPagamentos = _ => {
  return dispatch => {
    dispatch(carregandoPagamentos())
    axios
      .get('/api/pagamento')
      .then(response => dispatch(pagamentosCarregadosComSuceso(response.data)))
      .catch(error => dispatch(pagamentosCarregadosComErro(error)))
  }
}

export const ADICIONANDO_PAGAMENTO = 'ADICIONANDO_PAGAMENTO'
const adicionandoPagamento = _ => ({
  type: ADICIONANDO_PAGAMENTO
})

export const PAGAMENTO_ADICIONADO_COM_SUCESSO = 'PAGAMENTO_ADICIONADO_COM_SUCESSO'
const pagamentoAdicionadoComSucesso = pagamento => ({
  type: PAGAMENTO_ADICIONADO_COM_SUCESSO,
  payload: pagamento
})

export const PAGAMENTO_NAO_ADICIONADO = 'PAGAMENTO_NAO_ADICIONADO'
const pagamentoNaoAdicionado = erro => ({
  type: PAGAMENTO_NAO_ADICIONADO,
  payload: erro
})

const pagamentoRequired = {
  nome: '',
  ip: 'ip',
  porta: '',
  usuario: '',
  senha: ''
}

export const adicionarPagamento = pagamento => {
  delete pagamento.actions
  const pagamentoVal = {
    formRequired: pagamentoRequired,
    formValues: pagamento
  }
  return dispatch => {
    const mensagemDeErro = validarCamposAction(pagamentoVal)
    if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro)
    dispatch(fecharModalPagamento())
    dispatch(adicionandoPagamento())
    axios
      .post('/api/pagamento', pagamento)
      .then(response => dispatch(pagamentoAdicionadoComSucesso(response.data)))
      .catch(erro => dispatch(pagamentoNaoAdicionado(erro)))
  }
}

export const ATUALIZANDO_PAGAMENTO = 'ATUALIZANDO_PAGAMENTO'
const atualizandoPagamento = _ => ({
  type: ATUALIZANDO_PAGAMENTO
})

export const PAGAMENTO_ATUALIZADO_COM_SUCESSO = 'PAGAMENTO_ATUALIZADO_COM_SUCESSO'
const pagamentoAtualizadoComSucesso = pagamento => ({
  type: PAGAMENTO_ATUALIZADO_COM_SUCESSO,
  payload: pagamento
})

export const PAGAMENTO_NAO_ATUALIZADO = 'PAGAMENTO_NAO_ATUALIZADO'
const pagamentoNaoAtualizado = erro => ({
  type: PAGAMENTO_NAO_ATUALIZADO,
  payload: erro
})

export const atualizarPagamento = pagamento => {
  delete pagamento.actions
  const pagamentoVal = {
    formRequired: pagamentoRequired,
    formValues: pagamento
  }
  return dispatch => {
    const mensagemDeErro = validarCamposAction(pagamentoVal)
    console.log(mensagemDeErro, 'mensagemDeErro')
    if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro)
    dispatch(fecharModalPagamento())
    dispatch(atualizandoPagamento())
    axios
      .put('/api/pagamento', pagamento)
      .then(response => dispatch(pagamentoAtualizadoComSucesso(response.data)))
      .catch(erro => dispatch(pagamentoNaoAtualizado(erro)))
  }
}

export const DELETANDO_PAGAMENTO = 'DELETANDO_PAGAMENTO'
const deletandoPagamento = _ => ({
  type: DELETANDO_PAGAMENTO
})

export const PAGAMENTO_DELETADO_COM_SUCESSO = 'PAGAMENTO_DELETADO_COM_SUCESSO'
const pagamentoDeletadoComSucesso = pagamento => ({
  type: PAGAMENTO_DELETADO_COM_SUCESSO,
  payload: pagamento
})

export const PAGAMENTO_NAO_DELETADO = 'PAGAMENTO_NAO_DELETADO'
const pagamentoNaoDeletado = erro => ({
  type: PAGAMENTO_NAO_DELETADO,
  payload: erro
})

export const deletarPagamento = pagamento => {
  return dispatch => {
    dispatch(fecharModalPagamento())
    dispatch(deletandoPagamento())
    axios
      .delete(`/api/pagamento/${pagamento.id}`)
      .then(response => dispatch(pagamentoDeletadoComSucesso(response.data)))
      .catch(erro => dispatch(pagamentoNaoDeletado(erro)))
  }
}
