import React from 'react'
import { connect } from 'react-redux'

import BasicModal from '../../../components/modal/BasicModal.js'

import ConteudoPagamentoModal from './ConteudoPagamentoModal'

import { fecharModalPagamento, adicionarPagamento, atualizarPagamento } from '../redux/PagamentoActions'

const CriarEditar = props => {
  const defaltText = require(`../nls/${props.locales}.json`)

  const title = props.pagamento.id ? defaltText.tituloModalEditar : defaltText.tituloModalNovo
  const action = props.pagamento.id ? props.atualizarPagamento : props.adicionarPagamento

  return (
    <BasicModal
      open={props.statusModal}
      close={props.fecharModalPagamento}
      containerModal={{
        title,
        size: 'modal-lg',
        content: <ConteudoPagamentoModal />,
        closeText: defaltText.btn.fechar,
        action: {
          acao: () => action(props.pagamento),
          texto: defaltText.btn.salvar,
          class: 'btn-success'
        }
      }}
    />
  )
}

const mapStateToProps = store => {
  return {
    statusModal: store.pagamentosState.statusModal,
    locales: store.authentication.user.preferencia.language,
    pagamento: store.pagamentosState.pagamento
  }
}
const mapDispatchToProps = {
  fecharModalPagamento,
  adicionarPagamento,
  atualizarPagamento
}

export default connect(mapStateToProps, mapDispatchToProps)(CriarEditar)
