import React from 'react';

export function DesvincularIcon(props) {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="38"
            viewBox="0 0 200 200"
            preserveAspectRatio="xMidYMid meet">
            <title>{props.title}</title>
            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M72 1927 l-72 -72 928 -928 927 -927 73 73 72 72 -285 285 -285 285
                    234 235 c128 129 245 253 258 275 94 153 100 348 15 509 -104 198 -346 306
                    -562 251 -117 -30 -161 -60 -313 -209 l-144 -141 94 -7 c51 -4 106 -12 122
                    -18 28 -10 32 -7 106 65 100 97 149 120 255 120 94 0 150 -22 213 -86 63 -63
                    86 -120 87 -214 0 -128 -10 -144 -275 -409 l-230 -231 -199 199 c-110 110
                    -198 203 -195 208 10 16 123 8 181 -13 67 -26 144 -97 164 -152 7 -20 16 -37
                    19 -37 3 0 39 33 79 74 l73 74 -30 45 c-70 100 -190 180 -313 207 -76 17 -199
                    8 -278 -20 l-63 -22 -292 291 -291 291 -73 -73z"/>
                <path d="M269 983 c-197 -200 -222 -235 -254 -358 -29 -115 -16 -228 42 -345
                    110 -225 378 -334 617 -251 90 32 140 69 281 211 l130 130 -65 0 c-36 0 -92 7
                    -125 14 l-60 15 -76 -75 c-99 -96 -148 -119 -254 -119 -94 0 -150 22 -214 86
                    -62 62 -86 120 -86 209 0 128 13 150 216 355 l179 181 -68 67 c-37 37 -70 67
                    -73 67 -3 0 -89 -84 -190 -187z"/>
                <path d="M651 856 l-63 -64 30 -45 c67 -96 175 -171 293 -202 57 -16 179 -20 179 -7 0 4 -84 92 -188 195 l-187 187 -64 -64z" />
            </g>
        </svg>
    );
}