import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectInBox } from '../../../components/form';
import { resetarCamposAction, validarCampoAction } from '../../../validation/validationActions';
import { deslogar, ListarAplicacoes } from './redux/UserActions';

import { IcoChamadas, IcoIntelbras, SigCloudLogo } from '../../../components/icones';
import ThemeSelector from '../../../components/ThemeSelector/ThemeSelector';
import history from '../../../history';
import { InfoIcon, LockIcon, UserIcon } from '../../../layouts/components/icons';
import Input from '../../../layouts/components/Input/Input';
import InputPassword from '../../../layouts/components/Input/InputPassword';
import { ajustIdioma, listarIdiomas } from '../../../layouts/redux/LayoutActions';
import '../usuario.css';
import SelecionarLoc from './SelecionarLoc/selecionarLoc';

/* Imports para Registrar */
import { MaskTel } from '../../../components/mask/Mask';
import { handleOnKeyDown } from '../../../utils/utils';
import { adicionarRegistro, atualizarConteudoRegistrar, clearRegistrarField, resetRegisterFields } from '../registrar/redux/RegistrarActions';
import AttentionMessage from './AttentionMessage/attentionMessage';
//import ManutencaoMessage from './AttentionMessage/modalManutenção';

const Login = () => {
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        email: '',
        senha: ''
    });

    const [loginRefs, setLoginRefs] = useState([
        useRef(),
        useRef(),
        useRef()
    ]);

    const [registerRefs, setRegisterRefs] = useState([
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ]);

    const [preferencia, setPreferencia] = useState([]);
    const statusModal = useSelector(state => state.authentication.statusModal);
    const mensagemErro = useSelector(state => state.validacaoState);
    const listarPreferencias = useSelector(state => state.layoutState);

    /* Para o registro */
    const registrarUsuario = useSelector(state => state.registrarState);
    /* Para o registro */

    const defaltText = preferencia.linguagem
        ? require(`../nls/${preferencia.linguagem}.json`)
        : require(`../nls/pt-BR.json`);

    const handleChange = e => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    };

    const handleClearField = (e) => {
        setInputs({ ...inputs, [e.currentTarget.name]: "" });
    };

    const handleChangePreferencia = e => {
        setPreferencia({
            ...preferencia,
            [e.target.name]: e.target.value.use
        });
    };

    const handlePressEnter = event => {
        if (event.key === 'Enter') {
            dispatch(ListarAplicacoes(inputs));
        }
    };

    useEffect(() => {
        dispatch(deslogar());
        dispatch(resetarCamposAction());
        dispatch(listarIdiomas());
        localStorage.setItem('aplicacao', null);
    }, [dispatch]);

    const modalSelLoc = e => {
        switch (e) {
            case 'abrindo':
                return <SelecionarLoc preferencia={preferencia} inputs={inputs} />;
            case 'attentionMessage':
                return <AttentionMessage preferencia={preferencia} />;
            /*case 'manutencaoMessage':
                return <ManutencaoMessage preferencia={preferencia} />;*/
            default:
                return;
        }
    };

    const [activeSection, setActiveSection] = useState("");
    const handleChangeScreenType = () => {
        setInputs({ email: "", senha: "" });
        setPasswordStrength({ className: '', text: '' });
        dispatch(resetarCamposAction());
        dispatch(resetRegisterFields());
        setActiveSection(activeSection !== "sign-up-js" ? "sign-up-js" : "sign-in-js");
    };

    const [passwordStrength, setPasswordStrength] = useState({
        className: null,
        text: null
    });


    const forcaSenha = (senha) => {

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

        if (strongRegex.test(senha)) {
            return setPasswordStrength({ className: 'strongPassword', text: `${defaltText.inputs.strongPassword}` });
        } else if (mediumRegex.test(senha)) {
            return setPasswordStrength({ className: 'mediumPassword', text: `${defaltText.inputs.mediumPassword}` });
        } else {
            return setPasswordStrength({ className: 'weakPassword', text: `${defaltText.inputs.weekPassword}` });
        }
    };
    return (
        <div id="login-bg"
            className={`${activeSection}`}>
            <div className="auth-container">
                <div className="content first-content">
                    <div className="content-header">
                        <div className="logo">
                            <IcoIntelbras />
                        </div>

                        <div className="configs">
                            <div className="language-config">
                                <SelectInBox
                                    id='linguagem'
                                    /* label={defaltText.linguagem} */
                                    selectDefault={{
                                        id: 'id',
                                        label: 'nome',
                                        minWidth: '100%',
                                    }}
                                    select={{
                                        action: (e) => handleChangePreferencia(e),
                                        options: listarPreferencias.linguagens,
                                        selected: ajustIdioma(preferencia.linguagem),
                                    }}
                                />
                            </div>
                            <div className="theme-config">
                                <ThemeSelector
                                    label={defaltText.inputs.tema}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="first-column">
                        <h3>{defaltText.novoPorAqui}</h3>
                        <p>{defaltText.criarNovoUsuario}</p>
                        <button className="btn" onClick={() => handleChangeScreenType()}>{defaltText.btn.registrar}</button>
                    </div>
                    <div className="second-column">
                        <div className="form">
                            <div className='warning-div'>
                                <span>{defaltText.avisoDesativacao}</span>
                            </div>

                            <SigCloudLogo />
                            <h5>{defaltText.fazerLogin}</h5>
                            <Input
                                id="loginEmail"
                                reference={loginRefs[0]}
                                name='email'
                                label={defaltText.inputs.email}
                                value={inputs.email}
                                changeInput={e => handleChange(e)}
                                validate={{
                                    action: e => dispatch(validarCampoAction(e)),
                                    erro: mensagemErro && mensagemErro.email ? defaltText.mensagem.obrigatorio : ''
                                }}
                                length={{
                                    max: '50'
                                }}
                                clearField={{
                                    action: (e) => handleClearField(e),
                                    text: "Limpar"
                                }}
                                leftButton={{
                                    action: () => console.log("Pesquisar aqui"),
                                    icon: <UserIcon />
                                }}
                                onKeyDown={(e) => handleOnKeyDown(e, loginRefs[0], loginRefs)}
                            />
                            <InputPassword
                                id="loginSenha"
                                reference={loginRefs[1]}
                                fieldIcon={{
                                    icon: <LockIcon />
                                }}
                                name='senha'
                                label={defaltText.inputs.senha}
                                value={inputs.senha}
                                changeInput={e => handleChange(e)}
                                onPressEnter={e => handlePressEnter(e)}
                                validate={{
                                    action: e => dispatch(validarCampoAction(e)),
                                    erro: mensagemErro && mensagemErro.senha ? defaltText.mensagem.obrigatorio : ''
                                }}
                                seeText={defaltText.btn.ver}
                                length={{
                                    max: '20'
                                }}
                                onKeyDown={(e) => handleOnKeyDown(e, loginRefs[1], loginRefs)}
                            />
                            <button ref={loginRefs[2]} onClick={() => dispatch(ListarAplicacoes(inputs))} className="btn">{defaltText.btn.entrar}</button>
                            <a onClick={() => history.push("/esqueciSenha")}>{defaltText.esqueceuSenha}</a>
                        </div>

                    </div>
                </div>

                <div className="content second-content">
                    <div className="first-column">
                        <h3>{defaltText.jaPossuiConta}</h3>
                        <p>{defaltText.possuiContaMsg1}</p>
                        <p>{defaltText.possuiContaMsg2}</p>
                        <button className="btn" onClick={() => setActiveSection("sign-in-js")}>{defaltText.btn.entrar}</button>
                    </div>
                    <div className="second-column">
                        <div className="form">
                            <SigCloudLogo />
                            <h5>{defaltText.btn.registrar}</h5>
                            <Input
                                id="nomeRegistrar"
                                reference={registerRefs[0]}
                                name='nome'
                                label={defaltText.inputs.nome}
                                value={registrarUsuario.nome}
                                changeInput={e => dispatch(atualizarConteudoRegistrar(e))}
                                validate={{
                                    action: e => dispatch(validarCampoAction(e)),
                                    erro: mensagemErro && mensagemErro.nome ? defaltText.mensagem.obrigatorio : ''
                                }}
                                length={{
                                    max: '50'
                                }}
                                clearField={{
                                    action: (e) => dispatch(clearRegistrarField(e)),
                                    text: defaltText.btn.limparCampo
                                }}
                                leftButton={{
                                    action: () => { },
                                    icon: <UserIcon />
                                }}
                                onKeyDown={(e) => handleOnKeyDown(e, registerRefs[0], registerRefs)}
                            />

                            <Input
                                id="emailRegistrar"
                                reference={registerRefs[1]}
                                name='email'
                                label={defaltText.inputs.email}
                                value={registrarUsuario.email}
                                changeInput={e => dispatch(atualizarConteudoRegistrar(e))}
                                validate={{
                                    action: e => dispatch(validarCampoAction(e)),
                                    erro: mensagemErro && mensagemErro.email ? defaltText.mensagem.obrigatorio : ''
                                }}
                                length={{
                                    max: '50'
                                }}
                                clearField={{
                                    action: (e) => dispatch(clearRegistrarField(e)),
                                    text: "Limpar"
                                }}
                                leftButton={{
                                    action: () => console.log("Pesquisar aqui"),
                                    icon: <UserIcon />
                                }}
                                onKeyDown={(e) => handleOnKeyDown(e, registerRefs[1], registerRefs)}
                            />

                            <Input
                                id="telefoneRegistrar"
                                name="telefone"
                                reference={registerRefs[2]}
                                label={defaltText.inputs.telefone}
                                value={MaskTel(registrarUsuario.telefone)}
                                changeInput={e => dispatch(atualizarConteudoRegistrar(e))}
                                clearField={{
                                    action: (e) => dispatch(clearRegistrarField(e)),
                                    text: defaltText.btn.limparCampo
                                }}
                                validate={{
                                    pattern: "phone",
                                    action: e => dispatch(validarCampoAction(e, true)),
                                    erro: mensagemErro.telefone && mensagemErro.telefone === "pattern" ? defaltText.mensagem.padraoObrigatorio : (mensagemErro.telefone ? defaltText.mensagem.obrigatorio : '')
                                }}
                                leftButton={{
                                    action: () => { },
                                    icon: <IcoChamadas />
                                }}
                                onKeyDown={(e) => handleOnKeyDown(e, registerRefs[2], registerRefs)}
                            />

                            <InputPassword
                                id="senhaRegistrar"
                                reference={registerRefs[3]}
                                fieldIcon={{
                                    icon: <LockIcon />
                                }}
                                name='senha'
                                label={defaltText.inputs.senha}
                                value={registrarUsuario.senha}
                                changeInput={e => [forcaSenha(e.target.value), dispatch(atualizarConteudoRegistrar(e))]}
                                validate={{
                                    action: e => dispatch(validarCampoAction(e)),
                                    /* erro: mensagemErro && mensagemErro.senha ? defaltText.mensagem.obrigatorio : '' */
                                    erro: mensagemErro.senha && (mensagemErro.senha === "invalid" ? "" : defaltText.mensagem.obrigatorio)

                                }}
                                seeText={defaltText.btn.ver}
                                length={{
                                    max: '20'
                                }}
                                onKeyDown={(e) => handleOnKeyDown(e, registerRefs[3], registerRefs)}
                            />

                            {<div className={`passwordValidation ${registrarUsuario.senha === '' || Object.keys(registrarUsuario).includes('initialState') ? 'hidePassValidation' : ''}`}>
                                <p className={`${passwordStrength.className}`}>
                                    {passwordStrength.text}
                                </p>
                                {passwordStrength.className === 'weakPassword' ?
                                    <div className='infoPassword'>
                                        <InfoIcon />
                                        <span className="tooltip">{defaltText.mensagem.tooltipPassword}</span>
                                    </div>
                                    : ''}
                            </div>}

                            <InputPassword
                                id="confirmarSenhaRegistrar"
                                reference={registerRefs[4]}
                                fieldIcon={{
                                    icon: <LockIcon />
                                }}
                                name='confirmsenha'
                                label={defaltText.inputs.confirmarSenha}
                                value={registrarUsuario.confirmsenha}
                                changeInput={e => dispatch(atualizarConteudoRegistrar(e))}
                                validate={{
                                    action: e => dispatch(validarCampoAction(e)),
                                    erro: mensagemErro && mensagemErro.confirmsenha ? defaltText.mensagem.obrigatorio : ''
                                }}
                                seeText={defaltText.btn.ver}
                                length={{
                                    max: '20'
                                }}
                                onKeyDown={(e) => handleOnKeyDown(e, registerRefs[4], registerRefs)}
                            />

                            <button ref={registerRefs[5]} onClick={() => dispatch(adicionarRegistro(registrarUsuario, handleChangeScreenType))} className="btn">
                                {defaltText.btn.registrar}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {modalSelLoc(statusModal)}
        </div>
    );
};

export default Login;
