import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import InputInbox from '../../../components/form/inputInBox/InputInbox';
import Row from '../../../components/row/row';

import { validarCampoAction } from '../../../validation/validationActions';

import { atualizarCamposLimiares } from '../redux/AlarmesActions';

import '../alarmes.css'
const ConteudoLimiares = () => {

    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);

    const mensagemErro = useSelector(state => state.validacaoState);

    const alarmesState = useSelector(state => state.alarmesState)

    const defaltText = require(`../nls/${locales}.json`);


    /*   useEffect(() => {
      dispatch(listarLocalizacoes());
    }, [dispatch]); */
    return (
        <div className='col-md-12'>
            <Row>
                <div className='col-md-6'>
                    <InputInbox
                        type='text'
                        name='txMax'
                        label={defaltText.inputs.txMax}
                        value={alarmesState.limiares.txMax}
                        changeInput={e => dispatch(atualizarCamposLimiares(e))}
                        //VERIFICAR
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.txMax ? defaltText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '6'
                        }}
                    />
                </div>
                <div className='col-md-6'>
                    <InputInbox
                        name='rxMax'
                        label={defaltText.inputs.rxMax}
                        value={alarmesState.limiares.rxMax}
                        changeInput={e => dispatch(atualizarCamposLimiares(e))}
                        //VERIFICAR
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.rxMax ? defaltText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '30'
                        }}
                    />
                </div>
                <div className='col-md-6'>
                    <InputInbox
                        name='txMin'
                        label={defaltText.inputs.txMin}
                        value={alarmesState.limiares.txMin}
                        changeInput={e => dispatch(atualizarCamposLimiares(e))}
                        //VERIFICAR
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.txMin ? defaltText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '30'
                        }}
                    />
                </div>

                <div className='col-md-6'>

                    <InputInbox
                        name='rxMin'
                        label={defaltText.inputs.rxMin}
                        value={alarmesState.limiares.rxMin}
                        changeInput={e => dispatch(atualizarCamposLimiares(e))}
                        //VERIFICAR
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.rxMin ? defaltText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '30'
                        }}
                    />
                </div>
            </Row>
        </div>
    );
};

export default ConteudoLimiares;
