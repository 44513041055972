import React from 'react';
import { resetarCamposAction, validarAction, validarCamposAction } from '../../../validation/validationActions';

import axios from 'axios';
import { toast } from 'react-toastify';
import { IcoInfo } from '../../../components/icones';
import history from '../../../history';
import { CorrectIcon } from '../../../layouts/components/icons';
import { setModalHeaderType } from '../../../layouts/redux/LayoutActions';
import { checkResponseErrorType } from '../../../utils/utils';
import { listarAssinantes } from '../../assinantes/redux/AssinanteActions';
import { pesquisaCpes } from '../../cpe/redux/CpeActions';
import { listarOlts } from '../../olt/redux/OltActions';
import { listarPlanos } from '../../planos/redux/PlanosActions';
import { listarServicos } from '../../servicos/redux/ServicoActions';
import { deslogar, fecharModalAttention, Logar, usuarioLogadoSucesso } from '../../usuario/login/redux/UserActions';

const idiomaText = () => {
    const locales = document.getElementById('idioma');
    const defaltText = require(`../nls/${locales ? locales.value : 'pt-BR'}.json`);
    return defaltText.mensagem;
};

const atributosRequired = {
    aplicacao: {
        nomeLocal: ''
    }
};

export const APLICACAO_ATUALIZADOS_COM_SUCESSO = 'APLICACAO_ATUALIZADOS_COM_SUCESSO';
export const aplicacaoAtualizadosComSucesso = (aplicacao) => ({
    type: APLICACAO_ATUALIZADOS_COM_SUCESSO,
    payload: aplicacao,
});

export const criarLoc = (info) => {
    const LocValue = info;

    const locVal = {
        formRequired: atributosRequired,
        formValues: LocValue
    };

    return dispatch => {
        const mensagemDeErro = validarCamposAction(locVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        if (LocValue.senha) {
            axios
                .post('/api/aplicacao/criar-aplicacao', info)
                .then(response => {
                    dispatch(listarLocais());
                    toast.success((idiomaText().adicionarSuccess), {
                        icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                    });
                    dispatch(Logar({ email: LocValue.email, senha: LocValue.senha, aplicacao: response.data }));
                    dispatch(fecharModalAttention());
                })
                .catch(erro => {
                    checkResponseErrorType(erro, idiomaText().adicionarErro);
                });
        }
        else {
            axios
                .post('/api/aplicacao/criar-aplicacao-local', info)
                .then(response => {
                    // dispatch(localizacaoCriadaSucesso(response.data));
                    dispatch(listarLocais());
                    toast.success((idiomaText().adicionarSuccess), {
                        icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                    });
                })
                .catch(erro => {
                    checkResponseErrorType(erro, idiomaText().adicionarErro);
                });
        }
    };
};

export const LOCALIZACAO_CRIADA_SUCESSO = 'LOCALIZACAO_CRIADA_SUCESSO';
export const localizacaoCriadaSucesso = e => ({
    type: LOCALIZACAO_CRIADA_SUCESSO,
    payload: e
});

export const atualizaSelect = (aplicacao, namePage) => {
    return dispatch => {
        axios
            .post(`/api/autorizacao/trocarAplicacao`, aplicacao)
            .then(response => {
                dispatch(selectAtualizadoComSucesso(aplicacao));
                //Trocar por response.data quando receber resposta do back
                dispatch(usuarioLogadoSucesso(response.data));
                localStorage.setItem('aplicacao', JSON.stringify(aplicacao));
                localStorage.setItem('user', JSON.stringify(response.data));

                //solução enquanto o websocket não funciona
                switch (namePage) {
                    case 'olts':
                        return dispatch(listarOlts());
                    case 'planos':
                        return dispatch(listarPlanos());
                    case 'servicos':
                        return dispatch(listarServicos());
                    case 'assinantes':
                        return dispatch(listarAssinantes());
                    case 'cpes':
                        return dispatch(pesquisaCpes({ pesquisa: {}, pagina: 0 }));
                    default:
                        return history.push(`/olts`);
                }
            })
            .catch(error => {
                checkResponseErrorType(error, idiomaText().listarError);
            });
    };
};
export const SELECT_ATUALIZADO_COM_SUCESSO = 'SELECT_ATUALIZADO_COM_SUCESSO';
export const selectAtualizadoComSucesso = e => ({
    type: SELECT_ATUALIZADO_COM_SUCESSO,
    payload: e
});

export const abrirModalNovaLoc = () => [resetarCamposAction(), setTipoModal('abrindoNovaLoc')];
export const abrirModalRemoveLoc = (local) => [setLocalDeletar(local), resetarCamposAction(), setTipoModal('removeLoc')];
export const abrirModalConfirmaRemover = () => [resetarCamposAction(), setTipoModal('confirmaRemoveLoc')];

export const SET_TIPO_MODAL = 'SET_TIPO_MODAL';
export const setTipoModal = (e) => ({
    type: SET_TIPO_MODAL,
    payload: e
});

export const FECHAR_TODAS_MODAL = 'FECHAR_TODAS_MODAL';
export const fecharTodasModal = (e) => ({
    type: FECHAR_TODAS_MODAL,
    payload: e
});

export const fecharModalNovaLoc = () => [setCampoDeletarInicial(), resetarCamposAction(), setTipoModal('')];

export const fecharModalDeleteLoc = () => [setCampoDeletarInicial(), resetarCamposAction(), fecharTodasModal('')];

export const ATUALIZAR_CAMPOS_NOVA_LOC = 'ATUALIZAR_CAMPOS_NOVA_LOC';
export const atualizarCamposNovaLoc = e => ({
    type: ATUALIZAR_CAMPOS_NOVA_LOC,
    payload: e
});

export const LIMPAR_CAMPOS_NOVA_LOC = 'LIMPAR_CAMPOS_NOVA_LOC';
export const limparCamposNovaLoc = () => ({
    type: LIMPAR_CAMPOS_NOVA_LOC
});

export const LIMPAR_CAMPOS_SELECT = 'LIMPAR_CAMPOS_SELECT';
export const limparCampoSelect = () => ({
    type: LIMPAR_CAMPOS_SELECT
});

export const ATUALIZAR_CAMPO_PESQUISA = 'ATUALIZAR_CAMPO_PESQUISA';
export const atualizarCampoPesquisa = e => ({
    type: ATUALIZAR_CAMPO_PESQUISA,
    payload: e
});

export const LIMPAR_CAMPO_PESQUISA = 'LIMPAR_CAMPO_PESQUISA';
export const limparCampoPesquisa = () => [{
    type: LIMPAR_CAMPO_PESQUISA
},
listarLocais()];

export const LOCALIZACOES_CARREGADAS_COM_SUCESSO = 'LOCALIZACOES_CARREGADAS_COM_SUCESSO';
export const localizacoesCarregadasComSucesso = e => ({
    type: LOCALIZACOES_CARREGADAS_COM_SUCESSO,
    payload: e
});

export const BAIXANDO_ADAPTER = 'BAIXANDO_ADAPTER';
export const baixandoAdapter = (status) => ({
    type: BAIXANDO_ADAPTER,
    payload: status
});

export const listarLocais = e => {
    const usuario = JSON.parse(localStorage.getItem('user'));
    /* const params = {
      id: `${idUsuario && idUsuario.id ? idUsuario.id : '0'}`
    }; */
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        pesquisa: `&nome=${e && e.pesquisa ? e.pesquisa : ''}`,
        email: `&email=${usuario && usuario.email ? usuario.email : ''}`
    };
    return dispatch => {
        axios
            //VERIFICAR
            .get(`/api/aplicacao${e && e.pesquisa ? ("?" + params.pagina + params.pesquisa + params.email) : ""}`)
            .then(response => dispatch(localizacoesCarregadasComSucesso(response.data)))
            .catch(error => {
                checkResponseErrorType(error, idiomaText().listarError);
            });
    };
};

export const downloadAdapterWindow = (os) => {
    var url = `/download_adapter_${os}/`;
    var elem = document.createElement('a');
    elem.href = url;
    elem.target = 'hiddenIframe';
    elem.click();
    elem.remove();
};

export const baixarAdapter = (os) => {
    let progresso = 0;

    const params = {
        os: `os=${os}`
    };
    return dispatch => {
        axios
            .get(`/api/aplicacao/adapter/download-adapter?${params.os}`,
                {
                    responseType: 'arraybuffer',
                    onDownloadProgress(progressEvent) {
                        progresso = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        if (progresso % 20 == 0)
                            dispatch(baixandoAdapter({ status: 'downloadEmProgresso', progress: progresso }));

                    }
                })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.headers.filename);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
                dispatch(baixandoAdapter({ status: 'downloadFinalizado', progress: progresso }));
            })
            .catch(error => {
                dispatch(baixandoAdapter('erroDownload'));
                checkResponseErrorType(error, idiomaText().downloadAdapterError);
            });
    };
};

export const SET_LOCAL_DELETAR = 'SET_LOCAL_DELETAR';
export const setLocalDeletar = (local) => ({
    type: SET_LOCAL_DELETAR,
    payload: local
});

export const ATUALIZAR_CONTEUDO_DELETA_LOC = 'ATUALIZAR_CONTEUDO_DELETA_LOC';
export const atualizarConteudoDeletaLoc = e => ({
    type: ATUALIZAR_CONTEUDO_DELETA_LOC,
    payload: e
});

export const LOCAL_NAO_DELETADO = 'LOCAL_NAO_DELETADO';
export const localNaoDeletado = (erro) => ({
    type: LOCAL_NAO_DELETADO,
    payload: erro
});

const credentialRequired = {
    email: '',
    senha: ''
};

export const LIMPAR_CAMPOS_DELETAR = 'LIMPAR_CAMPOS_DELETAR';
const setCampoDeletarInicial = () => ({
    type: LIMPAR_CAMPOS_DELETAR
});

export const deletarLocalizacao = (user, aplicacaoDeletar, ListaAplicacoes, aplicacaoLogado) => {
    const userCredential = {
        email: user.email,
        senha: user.senha
    };
    const userVal = {
        formRequired: credentialRequired,
        formValues: userCredential
    };

    return (dispatch) => {
        const mensagemDeErro = validarCamposAction(userVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        const novaLista = ListaAplicacoes.filter((a) => {
            return a.id !== aplicacaoDeletar.id;
        });
        const usuarioPossuiAplicacao = novaLista.length;
        if (usuarioPossuiAplicacao) {
            dispatch(atualizaSelect(novaLista[0], 'olts'));
        }
        axios
            .delete(`/api/aplicacao/remover-localidade/${aplicacaoDeletar.id}`, { data: userCredential })
            .then(() => {
                dispatch(fecharModalDeleteLoc());
                dispatch(setModalHeaderType(''));
                if (aplicacaoLogado?.id === aplicacaoDeletar?.id && !usuarioPossuiAplicacao) {
                    deslogar();
                    history.push(`/login`);
                }
                toast.success((idiomaText().deleteSuccess)), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                };
            })
            .catch((erro) => {
                dispatch(localNaoDeletado(erro));
                dispatch(fecharModalDeleteLoc());
                toast.error((idiomaText().deleteErro), {
                    icon: <div className='divIconToastError'><IcoInfo /></div>
                });
            });
    };
};