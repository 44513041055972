import React from 'react'

import './list.css'

const List = ({ listActions, data }) => {
  const colunmNames = data.head

  return (
    <table className={`table`}>
      <thead>
        <tr>
          {colunmNames.map((col, index) => {
            return (
              <th colSpan={col.colSpan ? col.colSpan : 1} key={index} className={col.className ? col.className : ''}>
                {col.text}
              </th>
            )
          })}
        </tr>
      </thead>

      <tbody>
        {data.content.length ? (
          data.content.map((line, i) => {
            return (
              <tr key={i}>
                {colunmNames.map((col, i) => {
                  // listActions(line)
                  return (
                    <td key={i} className={col.className ? col.className : ''}>
                      {/* A linha abaixo era a linha original, antes de precisar exibir os nomes dos protocolos
                      utilizados em cada OLT. Como eles são recebidos do back-end como um objeto
                      foi preciso retrabalhar a condicional para que atendesse 3 casos diferentes, em vez de 2 */}
                      {/* {line[col.colunm] ? line[col.colunm] : listActions({ item: col.colunm, itens: line })} */}
                      
                      {line[col.colunm] ? (
                        <span>{typeof line[col.colunm] !== "object" ? line[col.colunm] : line[col.colunm].map((protocolo) => (<p>{protocolo}</p>))}</span>
                      ) : listActions({ item: col.colunm, itens: line })}


                    </td>
                  )
                })}
              </tr>
            )
          })
        ) : (
            <tr key='semInfo'>
              <td colSpan={colunmNames.length} className='text-center'>
                {data.text ? data.text : '- - - - - -'}
              </td>
            </tr>
          )}
      </tbody>
    </table>
  )
}

export default List
