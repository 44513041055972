import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/button/Button';
import DropdownList from '../../../../components/dropdownList/dropdownList';
import { SelectInBox } from '../../../../components/form';
import Checkbox from '../../../../components/form/checkbox/Checkbox';
import { MaskVlanId } from '../../../../components/mask/Mask';
import Input from '../../../../layouts/components/Input/Input';
import InputPassword from '../../../../layouts/components/Input/InputPassword';
import { validarCampoAction } from '../../../../validation/validationActions';
import { listarOltsOk } from '../../../olt/redux/OltActions';
import { atualizarConteudoPPPoEModal, cancelServiceType, clearPPPoEField, listarDba, resetarCamposPlano, salvarPPPoE, setUsuarioESenhaPadrao } from '../../redux/PlanosActions';
import ConteudoDropdownContentPPPoE from '../dropdownContent/conteudoDropdownContentPPPoE';

const ConteudoPPPoEModal = (props) => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../nls/${locales}.json`);
    const plano = useSelector((state) => state.planosState.plano);
    const mensagemErro = useSelector(state => state.validacaoState);
    const uploadControl = useSelector((state) => state.planosState.uploadControl);
    const downloadControl = useSelector((state) => state.planosState.downloadControl);


    const [dropdownState, setDropdownState] = useState({
        advanced: false
    });

    const handleDropdownState = (e) => {
        let actualStatus = dropdownState[e.currentTarget.value];
        let newArray = {
            ...dropdownState
        };
        newArray[e.currentTarget.value] = actualStatus === null ? true : !actualStatus;
        setDropdownState(newArray);
        console.log(actualStatus);
    };

    return (
        <div className={`pppoeContent ${!props.visivel ? 'hidePlan' : ''}`}>
            <h5>{defaultText.titlePPPoE}</h5>
            <div className="firstLine">
                <div className="col-md-6">
                    <Input
                        id="indexVlan"
                        name='indexVlan'
                        label={defaultText.inputs.vlanId}
                        value={MaskVlanId(plano.pppoe.indexVlan)}
                        changeInput={e => [
                            e.target.value = MaskVlanId(e.target.value),
                            dispatch(atualizarConteudoPPPoEModal(e))
                        ]}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.indexVlan ? defaultText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '4'
                        }}
                        clearField={{
                            action: (e) => dispatch(clearPPPoEField(e)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>
                <div className="col-md-6">
                    {/*  <Checkbox
                   //Quando implementar controle de banda, retirar parte de configuração deste pppoe desta parte somente
                        name="advancedOptionPPPoE"
                        text={defaultText.inputs.advanced}
                        checked={plano.pppoe.advancedOptionPPPoE}
                        value={plano.pppoe.advancedOptionPPPoE}
                        changeInput={(e) => dispatch(atualizarConteudoPPPoEModal(e))}
                    /> */}
                </div>
            </div>
            {/*  <div className={`col-md-12 ${plano.pppoe.advancedOptionPPPoE ? '' : 'hideAdvancedOption'}`}>
                <Checkbox
                    name="controleDeBandaPPPoE"
                    text={defaultText.inputs.controleDeBanda}
                    checked={plano.pppoe.controleDeBandaPPPoE}
                    value={plano.pppoe.controleDeBandaPPPoE}
                    changeInput={(e) => dispatch(atualizarConteudoPPPoEModal(e))}
                />
            </div> */}
            <div className={`bandwidthControl ${plano.pppoe.advancedOptionPPPoE ? '' : 'hideAdvancedOption'}`}>
                <div className="col-md-6">
                    {/* <SelectInBox
                        disabled={!plano.pppoe.controleDeBandaPPPoE}
                        id='upload'
                        label={defaultText.inputs.upload}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.upload ? defaultText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => dispatch(atualizarConteudoPPPoEModal(e)),
                            options: uploadControl.dbas,
                            selected: plano.pppoe.upload
                        }}
                    /> */}
                </div>
                <div className="col-md-6">
                    {/*  <SelectInBox
                        linha comentada- DESCOMENTAR QUANDO TIVER A FUNCIONALIDADE DO BACK
                        linha comentada- disabled={!plano.pppoe.controleDeBandaPPPoE}
                        disabled={true}
                        id='download'
                        label={defaultText.inputs.download}
                        selectDefault={{
                            id: 'id',
                            label: 'perfilName',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        validate={{
                            erro: mensagemErro.download ? defaultText.mensagem.obrigatorio : ''
                        }}
                        select={{
                            action: e => dispatch(atualizarConteudoPPPoEModal(e)),
                            options: downloadControl,
                            selected: plano.pppoe.download
                        }} 
                    />*/}
                </div>
            </div>
            <div className={`col-md-12 ${plano.pppoe.advancedOptionPPPoE ? '' : 'hideAdvancedOption'}`}>
                <Checkbox
                    name="usuarioESenhaPadrao"
                    text={defaultText.inputs.usuarioESenhaPadrao}
                    checked={plano.pppoe.usuarioESenhaPadrao}
                    value={plano.pppoe.usuarioESenhaPadrao}
                    /*  changeInput={(e) => { if (!plano.pppoe.usuarioESenhaPadrao) dispatch(setUsuarioESenhaPadrao()); dispatch(atualizarConteudoPPPoEModal(e)); }} */
                    changeInput={(e) => dispatch(atualizarConteudoPPPoEModal(e))}
                />
            </div>
            <div className={`secondLine ${plano.pppoe.advancedOptionPPPoE ? '' : 'hideAdvancedOption'}`}>
                <div className="col-md-6">
                    <Input
                        id="pppoeUsuario"
                        name='pppoeUsuario'
                        label={defaultText.inputs.usuario}
                        value={plano.pppoe.pppoeUsuario}
                        changeInput={(e) => dispatch(atualizarConteudoPPPoEModal(e))}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.pppoeUsuario ? defaultText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '20'
                        }}
                        disabled={!plano.pppoe.usuarioESenhaPadrao}
                        clearField={{
                            action: (e) => dispatch(clearPPPoEField(e)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <InputPassword
                        name='pppoeSenha'
                        label={defaultText.inputs.senha}
                        value={plano.pppoe.pppoeSenha}
                        changeInput={(e) => dispatch(atualizarConteudoPPPoEModal(e))}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.pppoeSenha ? defaultText.mensagem.obrigatorio : ''
                        }}
                        length={{
                            max: '20'
                        }}
                        seeText={defaultText.btn.ver}
                        disabled={!plano.pppoe.usuarioESenhaPadrao}
                    />
                </div>
            </div>

            {/*  <SelectInBox
                id='uploadDba'
                label={defaultText.inputs.upload}
                selectDefault={{
                    id: 'id',
                    label: 'serviceType',
                    text: defaultText.select.selecionado.nenhum
                }}
                validate={{
                    erro: mensagemErro.serviceType ? defaultText.mensagem.obrigatorio : ''
                }}
                select={{
                    action: e => [dispatch(atualizarConteudoPPPoEModal(e))],
                    options: serviceTypes.filter((service) => service.quantidade < service.quantidadeMaxima),
                    selected: plano.serviceType
                }}
            /> */}

            {/* </Row> */}

            {/* <DropdownList
                title={defaultText.inputs.advanced}
                button={
                    <Button
                        type="btn circle"
                        children={dropdownState.advanced ? <IcoMenos title="Fechar" /> : <IcoMais title="Abrir" />}
                        value="advanced"
                        action={(e) => { handleDropdownState(e); }}
                        color="transparent"
                    />
                }
                dropdownContent={
                    <ConteudoDropdownContentPPPoE
                        dropdownState={dropdownState}
                        mensagemErro={mensagemErro}
                    />
                }
                validate={{
                    erro: (mensagemErro.perfilDeControleDeBanda || mensagemErro.perfilDeControleDeBanda) ? defaultText.mensagem.obrigatorio : ''
                }}
            /> */}

            {/* <Row> */}
            <div className="planBtn">
                <Button
                    id="btnCencelarPPPoE"
                    titulo={defaultText.btn.cancelar}
                    action={() => dispatch(cancelServiceType())}
                    children={defaultText.btn.cancelar}
                    color='secondary'
                    type='btn'
                />
                <Button
                    id="btnSalvarPPPoE"
                    titulo={defaultText.btn.adicionar}
                    action={() => dispatch(salvarPPPoE(plano.pppoe))}
                    children={defaultText.btn.adicionar}
                    color='success'
                    type='btn'
                />
            </div>
            {/* </Row> */}
        </div>
    );
};

export default ConteudoPPPoEModal;