import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './removerVlanInterface.css';
import { fecharModalInterface, deletarVlanInterface, listarOlt, listarVlans, resetVlanForDelete } from '../../redux/OltActions';
import BasicModal from '../../../../components/modal/BasicModal';

const RemoverVlanInterface = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`../../nls/${locales}.json`);
    const selectedVlan = useSelector((state) => state.oltsState.selectedVlan);
    const todasVlans = useSelector((state) => state.oltsState.vlans);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const nomeInterface = useSelector(state => state.oltsState.selectedInterface.nomeInterface);
    const olt = useSelector((state) => state.oltsState.olt);
    return (
        <BasicModal
            open={statusModal}
            close={() => [dispatch(fecharModalInterface())]}
            containerModal={{
                title: defaltText.tituloModalRemoverVlan,
                size: 'small',
                content: (
                    <>
                        <p>{defaltText.mensagem.removerVlanConfirmacao}</p>
                        <p>VLAN: {selectedVlan.numeroVlan}</p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    acao: () => dispatch(deletarVlanInterface(olt, todasVlans, selectedVlan, nomeInterface)),
                    texto: defaltText.btn.remover,
                    class: "success"
                }
            }}
        />
    );
};

export default RemoverVlanInterface;
