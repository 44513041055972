import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../components/form/checkbox/Checkbox';
import BasicModal from "../../../components/modal/BasicModal";
import Table from '../../../layouts/components/Table/Table';
import { fecharModalPortasLan } from '../redux/PlanosActions';

const ModalPortasLan = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const statusModal = useSelector((state) => state.planosState.statusModal);
    const plano = useSelector((state) => state.planosState.planoConfig);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalPortasLan())}
            containerModal={{
                title: (defaultText.inputs.mapaPortasLan + ' - ' + plano.tipoServico),
                size: "medium",
                content: (
                    <section>
                        <div className='col-md-12'>
                            <div className="checkLanLine">
                                {[1, 2, 3, 4].map((lanNumber) => (
                                    <div className="col-md-6">
                                        <Checkbox
                                            name={`eth${lanNumber}`}
                                            text={`${defaultText.inputs.lan} ${lanNumber}`}
                                            checked={plano.mapaPortasLan !== null ? plano.mapaPortasLan["eth" + lanNumber.toString()] : null}
                                            value={plano.mapaPortasLan !== null ? plano.mapaPortasLan["eth" + lanNumber.toString()] : null}
                                            disabled={plano.mapaPortasLan === null ? true : false}
                                        />
                                    </div>
                                ))}
                            </div>

                        </div>
                    </section>
                ),
                closeText: defaultText.btn.fechar,
            }}
        />
    );

};

export default ModalPortasLan;