import { atualizarConteudoPagamentoModal, fecharModalPagamento } from '../redux/PagamentoActions'

import InputInbox from '../../../components/form/inputInBox/InputInbox'
import { InputInboxSenha } from '../../../components/form'
import React from 'react'
import { connect } from 'react-redux'
import { validarCampoAction } from '../../../validation/validationActions'

const ConteudoPagamentoModal = props => {
  const defaltText = require(`../nls/${props.locales}.json`)

  return (
    <section className='box-criar-editar-pagamento'>
      <div className='row'>
        <div className='col-md-6'>
          <InputInbox
            name='nome'
            label={defaltText.inputs.nome}
            value={props.pagamento.nome}
            changeInput={props.atualizarConteudoPagamentoModal}
            validate={{
              action: event => props.validarCampoAction(event),
              erro: props.mensagemErro.nome ? defaltText.mensagem.obrigatorio : ''
            }}
            length={{
              max: '100'
            }}
          />
        </div>
        <div className='col-md-4'>
          <InputInbox
            name='ip'
            label={defaltText.inputs.ip}
            value={props.pagamento.ip}
            changeInput={props.atualizarConteudoPagamentoModal}
            validate={{
              action: event => props.validarCampoAction(event),
              erro: props.mensagemErro.ip ? defaltText.mensagem.ip : '',
              pattern: 'ip'
            }}
            length={{
              max: '20'
            }}
          />
        </div>
        <div className='col-md-2'>
          <InputInbox
            type='number'
            name='porta'
            label={defaltText.inputs.porta}
            value={props.pagamento.porta}
            changeInput={props.atualizarConteudoPagamentoModal}
            validate={{
              action: event => props.validarCampoAction(event),
              erro: props.mensagemErro.porta ? defaltText.mensagem.obrigatorio : ''
            }}
            length={{
              max: '4'
            }}
          />
        </div>
        <div className='col-md-6'>
          <InputInbox
            name='usuario'
            label={defaltText.inputs.usuario}
            value={props.pagamento.usuario}
            changeInput={props.atualizarConteudoPagamentoModal}
            validate={{
              action: event => props.validarCampoAction(event),
              erro: props.mensagemErro.usuario ? defaltText.mensagem.obrigatorio : ''
            }}
            length={{
              max: '30'
            }}
          />
        </div>
        <div className='col-md-6'>
          <InputInboxSenha
            name='senha'
            label={defaltText.inputs.senha}
            value={props.pagamento.senha}
            changeInput={props.atualizarConteudoPagamentoModal}
            validate={{
              action: event => props.validarCampoAction(event),
              erro: props.mensagemErro.senha ? defaltText.mensagem.senha : ''
            }}
            seeText={defaltText.btn.ver}
            length={{
              max: '20'
            }}
          />
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = store => {
  return {
    statusModal: store.pagamentosState.statusModal,
    locales: store.authentication.user.preferencia.language,
    pagamento: store.pagamentosState.pagamento,
    mensagemErro: store.validacaoState
  }
}

const mapDispatchToProps = {
  atualizarConteudoPagamentoModal,
  fecharModalPagamento,
  validarCampoAction
}

export default connect(mapStateToProps, mapDispatchToProps)(ConteudoPagamentoModal)
