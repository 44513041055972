import React from 'react';
import { IcoFechar } from '../../icones';

export default ({
    type = 'text',
    label = '',
    name = '',
    value = '',
    changeInput,
    validate,
    length,
    search,
    placeholder = false,
    className,
    disabled,
    id,
    onPressEnter = () => { }
}) => {
    return (
        <div className={`box-form ${disabled ? "disabled" : ""} ${value ? 'filled' : ''} ${validate && validate.erro ? 'erro' : ''} ${className === 'hide' ? className : 'show'}`}>
            <input
                disabled={disabled ? disabled : false}
                type={type}
                name={name}
                value={value}
                onChange={changeInput}
                id={id ? id : name}
                required={!!validate}
                onBlur={validate && validate.action}
                pattern={validate && validate.pattern}
                maxLength={length && length.max}
                placeholder={label && label}
                onKeyPress={onPressEnter}
            />
            <label htmlFor={name}>
                {validate && type !== 'only-see' ? <sup className='text-danger'>✻ </sup> : ''}
                {label}
            </label>
            {search && value && (
                <button name={name} id="btnLimpar" type='button' onClick={search.action} title={search.text} className='bt-input'>
                    <IcoFechar title={search.text} />
                </button>
            )}
            <div className={`invalid-feedback ${validate ? "show-feedback" : "hide-feedback"}`}>{validate && validate.erro ? validate.erro : ''}</div>
        </div>
    );
};
