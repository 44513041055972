import React from "react";

import { useDispatch, useSelector } from 'react-redux';
import BasicModal from '../../../components/modal/BasicModal';
import { deletarOlt, fecharSegundaModal } from '../redux/OltActions';

const ConfirmaRemover = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const olt = useSelector(state => state.oltsState.olt);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const paginaAtual = useSelector((state) => state.oltsState.paginacao.pageNumber);
    const olts = useSelector((state) => state.oltsState.olts);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharSegundaModal(statusModal))}
            containerModal={{
                title: defaltText.tituloModalRemover,
                size: 'medium',
                content: (
                    <>
                        <p>{defaltText.mensagem.removerConfirmacao}</p>
                        <p style={{ fontWeight: "bold" }}>{defaltText.mensagem.remover}{olt.nome}?</p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                removeConfirmAction: {
                    acao: () => dispatch(deletarOlt(olt, olts.length > 1 ? paginaAtual : (paginaAtual > 0 ? paginaAtual - 1 : paginaAtual))),
                    texto: defaltText.btn.remover,
                    class: "success"
                }
            }}
        />
    );


};

export default ConfirmaRemover;