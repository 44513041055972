import {
    APLICACAO_ATUALIZADOS_COM_SUCESSO, ATUALIZAR_CAMPOS_NOVA_LOC, ATUALIZAR_CAMPO_PESQUISA, ATUALIZAR_CONTEUDO_DELETA_LOC, BAIXANDO_ADAPTER, FECHAR_TODAS_MODAL, LIMPAR_CAMPOS_DELETAR, LIMPAR_CAMPOS_NOVA_LOC, LIMPAR_CAMPOS_SELECT, LIMPAR_CAMPO_PESQUISA, LOCALIZACAO_CRIADA_SUCESSO, LOCALIZACOES_CARREGADAS_COM_SUCESSO, LOCAL_NAO_DELETADO, SELECT_ATUALIZADO_COM_SUCESSO, SET_LOCAL_DELETAR, SET_TIPO_MODAL
} from './LocaisActions';

const initialState = {
    locais: [],
    local: JSON.parse(localStorage.getItem('aplicacao')) !== null ? JSON.parse(localStorage.getItem('aplicacao')) : {},
    novaLoc: { nomeLocal: '', descricaoLocal: '' },
    statusModal: [],
    campoPesquisa: '',
    statusDownload: { status: '', progress: '' },
    localDeletar: {},
    credetial: { email: '', senha: '' }
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LOCALIZACAO_CRIADA_SUCESSO:
            return { ...state, locais: [...state.locais, payload] };

        case ATUALIZAR_CAMPOS_NOVA_LOC:
            return {
                ...state,
                novaLoc: {
                    ...state.novaLoc,
                    [payload.target.name]: payload.target.value
                }
            };
        case ATUALIZAR_CAMPO_PESQUISA:
            return {
                ...state,
                campoPesquisa: payload.target.value
            };

        case LIMPAR_CAMPO_PESQUISA:
            return {
                ...state,
                campoPesquisa: ''
            };

        case LIMPAR_CAMPOS_NOVA_LOC:
            return { ...state, novaLoc: initialState.novaLoc };

        case LIMPAR_CAMPOS_SELECT:
            return { ...state, local: null };

        case SELECT_ATUALIZADO_COM_SUCESSO:
            return { ...state, local: payload };

        case SET_TIPO_MODAL:
            if (payload === '' && state["statusModal"].length === 1) {
                return { ...state, statusModal: [] };
            }
            else if (payload === '') {
                return {
                    ...state,
                    statusModal: state.statusModal.filter((value) => value !== state.statusModal[state.statusModal.length - 1])
                };
            }
            else {
                return { ...state, statusModal: [...state.statusModal, payload] };
            }

        case FECHAR_TODAS_MODAL:
            return { ...state, statusModal: initialState.statusModal };

        case LOCALIZACOES_CARREGADAS_COM_SUCESSO:
            return {
                ...state,
                locais: payload.content ? payload.content : payload,
            };

        case BAIXANDO_ADAPTER:
            return {
                ...state, statusDownload: {
                    status: payload.status,
                    progress: payload.progress
                }
            };

        case SET_LOCAL_DELETAR:
            return { ...state, localDeletar: payload };

        case ATUALIZAR_CONTEUDO_DELETA_LOC:
            return {
                ...state,
                credetial: {
                    ...state.credetial,
                    [payload.target.name]: payload.target.value
                }
            };

        case LOCAL_NAO_DELETADO:
            return { ...state };

        case LIMPAR_CAMPOS_DELETAR:
            return { ...state, credetial: initialState.credetial };

        case APLICACAO_ATUALIZADOS_COM_SUCESSO:
            return {
                ...state,
                locais: state.locais.map((local) => (local.id === payload.id ? payload : local)),
                local: state.local?.id === payload?.id ? payload : state.local
            };
        default:
            return state;
    }
};
