import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import ConteudoOltModal from './conteudoCriarEditarOlt';

import { adicionarOlt, atualizarOlt, fecharModalOlt } from '../redux/OltActions';

const CriarEditar = () => {
    const dispatch = useDispatch();
    const [oldOlt, setOldOlt] = useState({});

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const olt = useSelector(state => state.oltsState.olt);
    const acceptedModeChangeTerms = useSelector((state) => state.oltsState.acceptedModeChangeTerms);
    const validacaoState = useSelector(state => state.validacaoState);
    const title = olt.id ? defaltText.tituloModalEditar : defaltText.tituloModalNovo;
    const aplicacao = useSelector((state => state.authentication.user.aplicacaoLogado));
    const action = olt.id ? atualizarOlt : adicionarOlt;
    const buttonEnabled = olt.id && olt.status === 'OK' ? ((((oldOlt.operationMode !== olt.operationMode?.id) && acceptedModeChangeTerms) || oldOlt.operationMode === olt.operationMode?.id) ? false : true) : false
    const validacao = Object.values(validacaoState).every(value => value === false)
    const [clicked, setClicked] = useState(false);
    useEffect(() => {
        setOldOlt({ ...olt });
    }, []);

    function disableButton() {
        if (olt.operationMode?.id === "VLANPORPON" && olt.progressoModoDeOperacao === null) {
            return (!validacao || buttonEnabled)
        }else if(olt.operationMode?.id === "AUTOSERVICE" && olt.progressoModoDeOperacao?.length < 3){
            return true;
        }
        return buttonEnabled;
    }
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalOlt())}
            containerModal={{
                title,
                size: 'medium',
                content: <ConteudoOltModal />,
                closeText: defaltText.btn.fechar,
                id: "ConteudoOltModal",
                action: {
                    btnId: "btnSalvar",
                    acao: () => [dispatch(action(olt, aplicacao, 0)), setClicked(true)],
                    texto: defaltText.btn.salvar,
                    class: 'success',
                    disabled: disableButton() || clicked
                }
            }}
        />
    );
};

export default CriarEditar;