import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import { fecharModalOlt, reenviarOlt } from '../redux/OltActions';

const Erro = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const olt = useSelector(state => state.oltsState.olt);
    const statusModal = useSelector(state => state.oltsState.statusModal);
    const pageNumber = useSelector(state => state.oltsState.paginacao.pageNumber);
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalOlt())}
            containerModal={{
                title: `Erro - ${olt.nome}`,
                size: 'medium',
                content: (
                    <>
                        <p>{defaltText.mensagem.adicionarErroModal}</p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    btnId: "btnRetry",
                    acao: () => dispatch(reenviarOlt(olt, pageNumber)),
                    texto: defaltText.btn.tentarNovamente,
                    class: "success"
                }
            }}
        />
    );
};

export default Erro;
