export const typeOlt = [
    { name: 'ONLINE', label: 'OK', value: 0, color: 'var(--success)' },
    { name: 'OFFLINE', label: 'OFFLINE', value: 0, color: 'var(--offline)' },
    { name: 'NÃO CONECTADO', label: 'ADAPTER_NAO_CONECTADO', value: 0, color: '#5e646e' },
    { name: 'CARREGANDO', label: 'CARREGANDO', value: 0, color: 'var(--updating)' },
    { name: 'CONECTANDO', label: 'CONECTANDO', value: 0, color: 'var(--problema-optico)' },
    { name: 'RECONECTANDO', label: 'RECONECTANDO', value: 0, color: 'var(--problema-optico)' },
    { name: 'ERRO', label: 'ERRO', value: 0, color: 'var(--error)' },
]
export const getData = (state, setData, statusData) => {
    const statusCounters = {};
    statusData.forEach((item) => {
        statusCounters[item.label] = 0;
    });

    for (const obj of state) {
        const status = obj.status;
        if (status.startsWith('ERRO')) {
            statusCounters['ERRO']++;
        } else if (status.includes('CONECTANDO')) {
            statusCounters['CONECTANDO']++;
        }
        else if (statusCounters.hasOwnProperty(status)) {
            statusCounters[status]++;
        }
    }
    setData((prevData) => {
        return prevData.map((item) => ({
            ...item,
            value: statusCounters[item.label],
        }));
    });
}

export const oltsToUpdate = (olts) => {
    return olts.reduce((accumulator, currentOlt) => {
        if (
            (currentOlt.modelo === 'OLT_8820I' && currentOlt.softwareVersion !== '2.106') ||
            (currentOlt.modelo !== 'OLT_8820I' && currentOlt.softwareVersion !== 'V100R001B01D002P003SP6_intelbras')
        ) {
            return { ...accumulator, [currentOlt.id]: currentOlt.nome };
        }
        return accumulator;
    }, {});
};



export function getInfoServico(olts, servicos) {
    const resultado = [];

    olts.forEach(olt => {
        const id = olt.id;
        const nome = olt.nome;

        const qtd = servicos.reduce((cont, servico) => {
            if (servico.idOlt === id) {
                return cont + 1;
            }
            return cont;
        }, 0);

        resultado.push([nome, qtd]);
    });

    return resultado;
}

export function getDataCpes(dataCpes, defaultText) {
    const statusMap = {
        ONLINE: { name: 'ONLINE', color: 'var(--success)' },
        NAO_PROVISIONADA: { name: defaultText.inputs.NAO_PROVISIONADA, color: 'var(--nao-provisionada)' },
        OFFLINE: { name: 'OFFLINE', color: 'var(--offline)' },
        PROBLEMA_OPTICO: { name: defaultText.inputs.PROBLEMA_OPTICO, color: 'var(--problema-optico)' },
        PROVISIONANDO: { name: defaultText.inputs.PROVISIONANDO, color: 'var(--updating)' },
        ERRO: { name: defaultText.inputs.ERROR, color: 'var(--error)' },
    };

    const result = dataCpes.map(item => {
        const status = item.label;
        const value = item.value;
        const { name, color } = statusMap[status] || { name: status, color: 'var(--default-color)' };

        return { name, label: status, value, color };
    });
    const total = dataCpes.reduce((acc, item) => acc + item.value, 0);
    return [result, total];
}

export function getDataOlts(dataOlts, defaultText) {
    const statusMap = {
        OK: { name: 'ONLINE', color: 'var(--success)' },
        ADAPTER_NAO_CONECTADO: { name: defaultText.inputs.ADAPTER_NAO_CONECTADO, color: 'var(--nao-provisionada)' },
        CONECTANDO: { name: defaultText.inputs.CONECTANDO, color: 'var(--problema-optico)' },
        CARREGANDO: { name: defaultText.inputs.CARREGANDO, color: 'var(--updating)' },
        OFFLINE: { name: 'OFFLINE', color: 'var(--offline)' },
        ERRO: { name: defaultText.inputs.ERRO, color: 'var(--error)' },
    };

    const result = dataOlts.map(item => {
        const status = item.label;
        const value = item.value;
        const { name, color } = statusMap[status] || { name: status, color: 'var(--default-color)' };

        return { name, label: status, value, color };
    });
    const total = dataOlts.reduce((acc, item) => acc + item.value, 0);
    return [result, total];
}