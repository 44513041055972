import React from 'react';
import Row from '../row/row';
import "./dropdownList.css";

export default function DropdownList({ button, title, dropdownContent, titleComponent, validate, titleColor, expandMenuColor }) {
    return (
        <>
            <div className={`expand-menu ${expandMenuColor && expandMenuColor} ${validate && validate.erro ? 'erro' : ''}`}>
                <div className="menu-left">
                    {button}
                </div>
                <div className="menu-right">
                    <p className="menu-title">{title}</p>
                    {titleComponent}
                </div>
            </div>
            <div className={`invalid-feedback ${validate && validate.erro ? "show-feedback" : "hide-feedback"}`}>{validate && validate.erro ? validate.erro : ''}</div>
            {dropdownContent}
        </>
    );
}