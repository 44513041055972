import React from 'react';

export function IcoMenu(props) {
    return (

        <svg className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox="0 0 28.5 19.333"
        >
            <title>{props.title ? props.title : null}</title>
            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                <path id="Caminho_1" data-name="Caminho 1" d="M4.5,18H29" transform="translate(0 -0.833)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                <path id="Caminho_2" data-name="Caminho 2" d="M4.5,9H29" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                <path id="Caminho_3" data-name="Caminho 3" d="M4.5,27H29" transform="translate(0 -1.667)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            </g>
        </svg>
        /* <svg
            className="icon"
            viewBox="0 0 100 100"
            style={props.style ? props.style : {}}
        >
            <title>{props.title ? props.title : null}</title>
            <path d="M95.7,25.53H4.31a2.56,2.56,0,1,1,0-5.12H95.7a2.56,2.56,0,1,1,0,5.12" />
            <path d="M95.7,52.56H4.31a2.57,2.57,0,1,1,0-5.13H95.7a2.57,2.57,0,0,1,0,5.13" />
            <path d="M95.7,79.59H4.31a2.56,2.56,0,1,1,0-5.12H95.7a2.56,2.56,0,1,1,0,5.12" />
        </svg> */
    );
}
