import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BasicModal from '../../../components/modal/BasicModal';
import { fecharModalServico } from '../../servicos/redux/ServicoActions';
import { desvincularServico } from '../redux/AssinanteActions';

const Desvincular = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const servico = useSelector(state => state.servicosState.servico);

    const statusModal = useSelector(state => state.servicosState.statusModal);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalServico())}
            containerModal={{
                title: defaltText.tituloModalDesvincular,
                size: 'medium',
                content: (
                    <>
                        <p>{defaltText.mensagem.desvincularConfirmacao}</p>
                        <p style={{ fontWeight: "bold" }}>{servico.nome}</p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    acao: () => dispatch(desvincularServico(servico)),
                    texto: defaltText.btn.desvincular,
                    class: "success"
                }
            }}
        />
    );
};

export default Desvincular;