import { InputInboxSenha, SelectInBox } from '../../../components/form';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "../usuario.css";
import Button from '../../../components/button/Button';
import { resetarCamposAction, validarCampoAction } from '../../../validation/validationActions';
import Row from '../../../components/row/row';
import { ajustIdioma, atualizarPreferencia, listarIdiomas } from '../../../layouts/redux/LayoutActions';
import TitleContainer from '../../../layouts/components/TitleContainer/TitleContainer';
import { IcoLocked, IcoUserCircle } from '../../../components/icones';
import { EmailIcon } from '../../../layouts/components/icons/EmailIcon';
import { InfoIcon, LockIcon } from '../../../layouts/components/icons';
import InputPassword from '../../../layouts/components/Input/InputPassword';
import { atualizarMeusDadosInput, limparCamposMeusDados, trocarSenhaUsuario } from '../login/redux/UserActions';
import { handleOnKeyDown } from '../../../utils/utils';
import history from '../../../history';



const MeusDados = () => {
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const user = useSelector((state) => state.authentication.user);
    const redefinirSenha = useSelector((state) => state.authentication.redefinirSenha);
    const mensagemErro = useSelector(state => state.validacaoState);
    const dispatch = useDispatch();
    const [meusDadosRefs, setMeusDadosRefs] = useState([
        useRef(),
        useRef(),
        useRef(),
    ]);

    useEffect(() => {
        dispatch(resetarCamposAction());
        dispatch(limparCamposMeusDados());
    }, [dispatch]);

    const [passwordStrength, setPasswordStrength] = useState({
        className: null,
        text: null
    });


    const forcaSenha = (senha) => {

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

        if (strongRegex.test(senha)) {
            return setPasswordStrength({ className: 'strongPassword', text: `${defaultText.inputs.strongPassword}` });
        } else if (mediumRegex.test(senha)) {
            return setPasswordStrength({ className: 'mediumPassword', text: `${defaultText.inputs.mediumPassword}` });
        } else {
            return setPasswordStrength({ className: 'weakPassword', text: `${defaultText.inputs.weekPassword}` });
        }
    };


    return (
        <div className='box-meus-dados'>
            <TitleContainer
                label={defaultText.titleMeusDados}
            />
            <Row>
                <div className='perfil'>
                    <label>{defaultText.tipoDePerfil}: <b>{user.perfil.nome}</b></label>
                </div>
            </Row>
            <div className='col-md-12'>
                <Row style={{ justifyContent: 'center' }}>
                    <div className='dados-usuario col-md-6'>
                        <div className='info-usuario col-md-6'>
                            <IcoUserCircle />
                            <p><b>{user.nome}</b></p>
                        </div>
                        <div className='info-usuario col-md-6'>
                            <EmailIcon
                                fieldIcon={{
                                    icon: <LockIcon />
                                }} />
                            <p><b>{user.email}</b></p>
                        </div>
                    </div>
                </Row>
            </div>

            <Row>
                <div>
                    <label>{defaultText.seguranca}: <b>{defaultText.trocarSenha}</b></label>
                </div>
            </Row>

            <div className='seguranca-usuario col-md-6'>
                <Row style={{ justifyContent: 'center' }}>
                    <div className='col-md-6'>
                        <InputPassword
                            value={redefinirSenha.senhaAtual}
                            fieldIcon={{
                                icon: <LockIcon />
                            }}
                            reference={meusDadosRefs[0]}
                            name='senhaAtual'
                            label={defaultText.inputs.senhaAtual}
                            changeInput={(e) => dispatch(atualizarMeusDadosInput(e))}
                            onKeyDown={(e) => handleOnKeyDown(e, meusDadosRefs[0], meusDadosRefs)}
                            validate={{
                                action: e => dispatch(validarCampoAction(e)),
                                erro: mensagemErro && mensagemErro.senhaAtual ? defaultText.mensagem.obrigatorio : ''
                            }}
                        />
                    </div >
                    <div className='col-md-6 info'>
                        <p>{defaultText.troqueSuaSenha}</p>
                        <p>{defaultText.naoConfieSuaSenha}</p>
                    </div>
                    <div className='col-md-6'>
                        <InputPassword
                            value={redefinirSenha.novaSenha}
                            fieldIcon={{
                                icon: <LockIcon />
                            }}
                            reference={meusDadosRefs[1]}
                            name='novaSenha'
                            label={defaultText.inputs.novaSenha}
                            changeInput={(e) => [forcaSenha(e.target.value), dispatch(atualizarMeusDadosInput(e))]}
                            onKeyDown={(e) => handleOnKeyDown(e, meusDadosRefs[1], meusDadosRefs)}
                            validate={{
                                action: e => dispatch(validarCampoAction(e)),
                                erro: mensagemErro.novaSenha && (mensagemErro.novaSenha === "invalid" ? "" : defaultText.mensagem.obrigatorio)
                            }}
                        />
                    </div>
                    <div className='col-md-6'>
                        <InputPassword
                            value={redefinirSenha.confirmarSenha}
                            fieldIcon={{
                                icon: <LockIcon />
                            }}
                            reference={meusDadosRefs[2]}
                            name='confirmarSenha'
                            label={defaultText.inputs.confirmarSenha}
                            changeInput={(e) => dispatch(atualizarMeusDadosInput(e))}
                            onKeyDown={(e) => handleOnKeyDown(e, meusDadosRefs[2], meusDadosRefs)}
                            validate={{
                                action: e => dispatch(validarCampoAction(e)),
                                erro: mensagemErro && mensagemErro.confirmarSenha ? defaultText.mensagem.obrigatorio : ''
                            }}
                        />
                    </div>

                    <div className={`passwordValidation ${redefinirSenha.novaSenha === '' ? 'hidePassValidation' : ''}`}>
                        <p className={`${passwordStrength.className}`}>
                            {passwordStrength.text}
                        </p>
                        {passwordStrength.className === 'weakPassword' ?
                            <div className='infoPassword'>
                                <InfoIcon />
                                <span className="tooltip">{defaultText.mensagem.tooltipPassword}</span>
                            </div>
                            : ''}
                    </div>

                    <div className='col-md-12 botoes'>
                        <Button titulo='Cancelar' action={() => [history.push(`/olts`), dispatch(limparCamposMeusDados())]} color='secondary' variant='modal' children={defaultText.btn.cancelar} size='small' />
                        <Button titulo='Atualizar Senha' action={() => dispatch(trocarSenhaUsuario(redefinirSenha))} color='primary' children={defaultText.btn.atualizar} size='small' />
                    </div>
                </Row>
            </div>


        </div>




    );
};

export default MeusDados;
