import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { fecharModalPlano, adicionarPlano, atualizarPlano, resetarCamposPlano, listarPlanos } from "../redux/PlanosActions";
import BasicModal from "../../../components/modal/BasicModal";
import ConteudoCriarEditarPlano from "./conteudoPlano";

const ModalPlano = () => {
  const dispatch = useDispatch();
  const locales = useSelector(state => state.layoutState.linguagem);
  const defaltText = require(`../nls/${locales}.json`);
  const statusModal = useSelector(state => state.planosState.statusModal);
  const plano = useSelector(state => state.planosState.plano);

  const title = plano.id ? defaltText.tituloModalEditar : defaltText.tituloModalNovo;
  const action = plano.id ? atualizarPlano : adicionarPlano;

  return (
    <BasicModal
      open={statusModal}
      close={() => dispatch(fecharModalPlano())}
      containerModal={{
        title,
        size: "large",
        content: <ConteudoCriarEditarPlano />,
        closeText: defaltText.btn.fechar,
        action: {
          acao: () => dispatch(adicionarPlano(plano, 0)),
          texto: defaltText.btn.salvar,
          class: "success",
        },
      }}
    />
  );
};

export default ModalPlano;