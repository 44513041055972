
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TitleContainer from '../../layouts/components/TitleContainer/TitleContainer';
import SigCloudLoader from '../../layouts/components/Loader/SigCloudLoader';
import './dashboard.css'
import PieChart from './charts/PieChart';

import {
    fetchCpeStatus,
} from '../cpe/redux/CpeActions';
import DonutChart from './charts/DonutChart';
import { getDataCpes, getDataOlts, oltsToUpdate, getInfoServico } from './dashboardUtils';
import { listarOlts, fetchOltStatus } from '../olt/redux/OltActions';
import TableInfo from './charts/TableInfo';
import { fetchServicosOlt } from '../servicos/redux/ServicoActions';
import history from '../../history';
export default () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const isLoading = useSelector((state) => state.oltsState.isLoading);
    const defaultText = require(`./nls/${locales}.json`);
    const olts = useSelector((state) => state.oltsState.olts);
    const dataCpes = useSelector((state) => state.cpesState.statusDataCpe);
    const dataOlts = useSelector((state) => state.oltsState.statusDataOlt);
    const [chartCpe, setChartCpe] = useState([]);
    const [chartOlt, setChartOlt] = useState([]);
    const [sizeDatas, setSizeDatas] = useState([]);
    const [oltsUpdate, setOltsUpdate] = useState([]);
    const listaServicos = useSelector((state) => state.servicosState.servicosOlt);

    const columns = ['OLT', defaultText.inputs.columnServices];
    useEffect(() => {
        dispatch(listarOlts());
        dispatch(fetchCpeStatus());
        dispatch(fetchOltStatus());
        dispatch(fetchServicosOlt());
    }, [dispatch])
    const updateSizeDataAtIndex = (index, newValue) => {
        setSizeDatas(prevSizeDatas => [
            ...prevSizeDatas.slice(0, index),
            newValue,
            ...prevSizeDatas.slice(index + 1)
        ]);
    };
    useEffect(() => {
        const result = oltsToUpdate(olts);
        setOltsUpdate(result);
        const resultCpe = getDataCpes(dataCpes, defaultText)
        setChartCpe(resultCpe[0])

        const resultOlt = getDataOlts(dataOlts, defaultText)
        setChartOlt(resultOlt[0])

        updateSizeDataAtIndex(0, resultCpe[1])
        updateSizeDataAtIndex(1, resultOlt[1])
        updateSizeDataAtIndex(2, listaServicos.reduce((total, item) => total + item[1], 0))
    }, [dataCpes, dataOlts, listaServicos, defaultText]);
    return (
        <>
            <SigCloudLoader visible={isLoading} />
            {olts?.length > 0 ?
                <div className='container-dashboard'>
                    <TitleContainer label={defaultText.title} />
                    <div className="dashboard-container">
                        <div className="block-olt">
                            <div className='header-dashboard-block'>
                                <h3 style={{ color: 'white' }}>OLT's</h3>
                            </div>
                            <div className='column-dashboard'>

                                <div className="donut-chart-container">
                                    <DonutChart data={chartOlt} />
                                </div>
                                <div className='content-dashboard-block'>

                                    {olts.some(olt => olt.status === "OK") ? <text className='title-dashboard-item-online'>Online: </text> : null}
                                    <div style={{ display: 'flex' }}>
                                        {olts?.map((olt, index) => (
                                            olt.status === "OK" ? <span key={index}>{index !== 0 && ', '}{olt.nome}</span> : null
                                        ))}
                                    </div>
                                    {Object.entries(oltsUpdate)?.length > 0 ?
                                        <text className='title-dashboard-item'>{defaultText.inputs.toUpdate}: </text>
                                        : null}
                                    <div>
                                        {Object.entries(oltsUpdate).map(([id, nome], index) => (
                                            <a
                                                key={index}
                                                onClick={() => history.push(`/informacoes/olts/${id}`)}
                                            >
                                                <span
                                                    style={{
                                                        cursor: 'pointer',
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    {index !== 0 && ', '} {nome}
                                                </span>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className='footer-dashboard-block'>
                                <span>{defaultText.inputs.numberOf} OLT's: {sizeDatas[1]}</span>
                            </div>
                        </div>
                        <div className="block-dashboard">
                            <div className='header-dashboard-block'>
                                <h3 style={{ color: 'white' }}>CPE's</h3>
                            </div>

                            <div className='container-pie-chart'>
                                <PieChart data={chartCpe} />
                            </div>
                            <div className='footer-dashboard-block'>
                                <span>{defaultText.inputs.numberOf} CPEs: {sizeDatas[0]}</span>
                            </div>
                        </div>
                        <div className="block-dashboard">
                            <div className='header-dashboard-block'>
                                <h3 style={{ color: 'white' }}>{defaultText.inputs.services}</h3>
                            </div>

                            <div className='container-pie-chart'>
                                <TableInfo columns={columns} data={listaServicos} />

                            </div>
                            <div className='footer-dashboard-block'>
                                <span>{defaultText.inputs.columnServices}: {sizeDatas[2]}</span>
                            </div>
                        </div>
                    </div>
                </div>
                : <div className='container-dashboard'>
                    <TitleContainer label={defaultText.title} />
                </div>
            }
        </>
    )

};