import React from 'react';

import { useSelector } from 'react-redux';
/* import Header from './header'; */
import SidebarLeft from './sidebarLeft';
import SidebarRight from './sidebarRight';
import Header from './components/Header/Header';

import Footer from './footer';
import Sair from './modalSair';
import './layout.css';
import SideBarMenuNovo from './components/sidebarMenuNovo/SidebarMenuNovo';
import { loadMenuItens } from '../utils/utils';
import ContainerGeral from './components/ContainerGeral/ContainerGeral';
import ReleaseNotes from '../modulos/releaseNotes/releaseNotes';
import AdicionarLoc from '../modulos/locais/adicionarLoc/adicionarLoc';
import RemoveLoc from '../modulos/locais/remover/removerLoc';
import ConfirmaRemoverLoc from '../modulos/locais/remover/confirmaRemover';
import Tooltip from '../components/tooltip/Tooltip';

export default ({ children }) => {
    const sidebar = useSelector(state => state.layoutState);

    const locales = useSelector((state) => state.layoutState.linguagem);

    /* const defaltText = require(`./menu-json/menu-${locales}.json`); */

    const defaltText = require(`./menu-json/menu-${locales}.json`);

    const statusModal = useSelector((state) => state.locaisState.statusModal);

    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const modalLayout = (e, i) => {
        switch (e[i]) {
            case 'sair':
                return [<Sair />, modalLayout(e, i + 1)];
            case 'mostrarAtualizacao':
                return [<ReleaseNotes />, modalLayout(e, i + 1)];
            case 'abrindoNovaLoc':
                return [<AdicionarLoc />, modalLayout(e, i + 1)];
            case 'removeLoc':
                return [<RemoveLoc />, modalLayout(e, i + 1)];
            case 'confirmaRemoveLoc':
                return [<ConfirmaRemoverLoc />, modalLayout(e, i + 1)];
            default:
                return;
        }
    };

    return (
        <>
            <Header />
            <div className='box-app'>
                <Tooltip />
                <div className='background'>
                    {/* {sidebar.sidebarLeft && <SidebarLeft />} */}
                    {permissions && <SideBarMenuNovo MenuJson={loadMenuItens(permissions, defaltText)} open={sidebar.sidebarLeft} />}
                    {/* <div className='box-container'>
                        {children}
                        <Footer />
                    </div> */}
                    <ContainerGeral
                        children={children}
                    />
                    {sidebar.sidebarRight && <SidebarRight />}
                    {/* {sidebar.statusModal === 'sair' ? <Sair /> : null} */}
                    {modalLayout(statusModal, 0)}
                </div>
            </div>
        </>
    );
};
