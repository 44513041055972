import React from 'react';

export function OfflineIcon(props) {
    return (
        <svg id={props.id} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <title>{props.title ? props.title : null}</title>
            <g id="Grupo_434" data-name="Grupo 434" transform="translate(-300.562 -3)">
                <path id="Caminho_260" data-name="Caminho 260" d="M10.5,0A10.5,10.5,0,1,1,0,10.5,10.5,10.5,0,0,1,10.5,0Z" transform="translate(300.562 3)" fill="#787878" />
                <g id="Grupo_403" data-name="Grupo 403" transform="translate(7 3.999)">
                    <g id="plug-disconnected-svgrepo-com" transform="translate(297.562 3.001)">
                        <path id="Caminho_258" data-name="Caminho 258" d="M18.074,4.394A3.042,3.042,0,0,1,17.8,8.382L16.725,9.457a.415.415,0,0,1-.586,0L12.424,5.742a.415.415,0,0,1,0-.587L13.5,4.08a3.042,3.042,0,0,1,3.988-.273l1.684-1.684a.415.415,0,1,1,.587.586ZM16.432,8.577l.782-.782a2.212,2.212,0,0,0-3.128-3.128l-.782.782Z" transform="translate(-6.129 -2.002)" fill="#2d3036" stroke="#2d3036" stroke-width="0.6" />
                        <path id="Caminho_259" data-name="Caminho 259" d="M7.962,11.117a.415.415,0,0,1,0,.587L6.786,12.879l1.629,1.63,1.175-1.175a.415.415,0,1,1,.586.586L9,15.1l.457.457a.415.415,0,0,1,0,.587L8.384,17.214a3.042,3.042,0,0,1-3.988.273L2.712,19.171a.415.415,0,0,1-.587-.586L3.809,16.9a3.042,3.042,0,0,1,.273-3.988l1.075-1.075a.415.415,0,0,1,.587,0l.455.455,1.176-1.176A.415.415,0,0,1,7.962,11.117ZM4.673,16.631a2.212,2.212,0,0,0,3.124,0l.782-.782L5.451,12.718l-.782.782a2.212,2.212,0,0,0,0,3.124l0,0Z" transform="translate(-2.004 -5.543)" fill="#2d3036" stroke="#2d3036" stroke-width="0.6" />
                    </g>
                </g>
            </g>
        </svg>
    );
}
