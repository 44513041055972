import React from 'react';

export function ServicoVinculado(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28.008"
            height="30.483"
            viewBox="0 0 28.008 30.483"
            id={props.id ? props.id : 'icon-servico-vinculado'}
        >

            <title>{props.title ? props.title : null}</title>
            <path id="network-svgrepo-com" d="M25.506,20.8A2.493,2.493,0,0,0,23.2,24.239l-4.1,4.1a6.228,6.228,0,0,0-6.871-.373L9.656,25.006a2.491,2.491,0,1,0-1.469,1.258l2.545,2.922a6.228,6.228,0,0,0-1.32,5.986l-5.6,3.989a2.468,2.468,0,0,0-1.312-.374,2.495,2.495,0,1,0,2.434,1.949l5.345-3.8a6.3,6.3,0,0,0,2.753,2.2l-.6,1.68a2.5,2.5,0,1,0,1.845.586l.646-1.822c.157.012.311.018.461.018a6.175,6.175,0,0,0,2.316-.447l2.68,3.231a2.493,2.493,0,1,0,1.488-1.236l-2.487-3a6.235,6.235,0,0,0,1.078-8.428l4.108-4.108a2.494,2.494,0,1,0,.942-4.8ZM15.382,29.982a3.367,3.367,0,1,1-3.367,3.367A3.371,3.371,0,0,1,15.382,29.982Z" transform="matrix(0.105, 0.995, -0.995, 0.105, 45.771, -2.175)" />
        </svg>
    );
}