import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BasicModal from '../../../components/modal/BasicModal';
import { criarLoc, limparCamposNovaLoc, fecharModalNovaLoc, abrirModalConfirmaRemover } from '../redux/LocaisActions';

const RemoveLoc = () => {

    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const statusModal = useSelector((state) => state.locaisState.statusModal);
    const localDeletar = useSelector((state) => state.locaisState.localDeletar);

    const title = defaultText.localizacao.delete;
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalNovaLoc())}
            containerModal={{
                title,
                size: 'medium',
                content: (
                    <>
                        <p>
                            {defaultText.mensagem.deleteMessage}
                            <span style={{ fontWeight: "bold" }}>{localDeletar.nomeLocal}</span>
                            ?
                        </p>
                    </>
                ),
                closeText: defaultText.btn.fechar,
                action: {
                    btnId: "btnDelete",
                    acao: () => dispatch(abrirModalConfirmaRemover(localDeletar)),
                    texto: defaultText.btn.deletar,
                    class: 'success'
                }
            }}
        />
    );
};


export default RemoveLoc;