import React from 'react';

import AuthLayout from './authLayout';
import { Route, Redirect } from 'react-router-dom';

export default ({ component: Component }) => {
    const storedUser = localStorage.getItem("user");
    return (
        <Route
            exact
            render={props =>
                storedUser ? (
                    <AuthLayout>
                        <Component {...props} />
                    </AuthLayout>
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    );
};
