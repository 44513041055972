import React from 'react';
import "./cardHeaderButton.css";

const CardHeaderButton = ({ label, icon, action, disabled, id, hasAccess = true, markerColor = "ok", title }) => {
    return (
        hasAccess ? (
            <button title={title} id={id} className={`btn-card ${markerColor}`} disabled={disabled} onClick={action}>
                {icon ? icon : label}
                <span>{label}</span>
            </button>
        ) : null
    );
};

export default CardHeaderButton;
