import { Redirect, Route, Router, Switch } from 'react-router-dom';

import Notificacoes from './modulos/notificacoes/notificacoes';
import Colaboradores from './modulos/colaboradores/colaboradores';
import EsqueciSenha from './modulos/usuario/esqueciSenha/esqueciSenha';
import RedefinirSenha from './modulos/usuario/esqueciSenha/redefinirSenha/redefinirSenha';
import Login from './modulos/usuario/login/login';
import MeusDados from './modulos/usuario/meusDados/meusDados';
import Locais from './modulos/locais/locais';
import Olts from './modulos/olt/olt';
import InformacoesOlt from './modulos/olt/informacoes/informacoesOlt';
import Vlans from './modulos/olt/vlans/vlans';
import Cpes from './modulos/cpe/cpe';
import ServicosCpes from './modulos/cpe/servicos/servicosCpe';
import Pagamentos from './modulos/pagamentos/Pagamentos';
import Perfis from './modulos/perfis/perfis';
import Planos from './modulos/planos/planos';
import PrivateRoute from './layouts/privateRoute';
import React, { useEffect } from 'react';
import Registrar from './modulos/usuario/registrar/registrar';
import Servicos from './modulos/servicos/servicos';
import ServicosAssinante from './modulos/assinantes/servicos/servicosAssinante';
import assinantes from './modulos/assinantes/assinantes';
import Alarmes from './modulos/alarmes/alarmes';
import history from './history';
import EditarInterface from './modulos/olt/vlans/editarInterface/editarInterface';
import ConteudoCriarEditarPlano from './modulos/planos/criarEditar/conteudoPlano';
import ReactGA from "react-ga4";
import Dashboard from './modulos/dashboard/dashboard';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);


export default () => {
    return (
        <Router history={history}>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/esqueciSenha" component={EsqueciSenha} />
                <Route path="/usuario/resetPassword/:hash" component={RedefinirSenha} />


                <PrivateRoute path="/" exact component={Olts} />
                <PrivateRoute path="/locais" exact component={Locais} />
                <PrivateRoute path="/olts" exact component={Olts} />
                <PrivateRoute path="/vlans/olts/:id" exact component={Vlans} />
                <PrivateRoute path="/vlans/olts/:id/:nomeInterface" exact component={EditarInterface} />
                <PrivateRoute path="/informacoes/olts/:id" exact component={InformacoesOlt} />
                <PrivateRoute path="/cpes" exact component={Cpes} />
                <PrivateRoute path="/servicos/cpes/:id" exact component={ServicosCpes} />
                {/* <PrivateRoute path="/assinantes" exact component={assinantes} /> */}
                <PrivateRoute path="/servicos/assinantes/:id" exact component={ServicosAssinante}
                />
                <PrivateRoute path="/alarmes" exact component={Alarmes} />
                <PrivateRoute path="/servicos" exact component={Servicos} />

                <PrivateRoute path="/planos" exact component={Planos} />
                <PrivateRoute path="/planos/configuracao" exact component={ConteudoCriarEditarPlano} />
                <PrivateRoute path="/pagamentos" exact component={Pagamentos} />
                <PrivateRoute path="/notificacoes" exact component={Notificacoes} />
                <PrivateRoute path="/colaboradores" exact component={Colaboradores} />
                <PrivateRoute path="/perfis" exact component={Perfis} />
                <PrivateRoute path="/meusDados" exact component={MeusDados} />
                <PrivateRoute path="/dashboard" exact component={Dashboard} />
                <Redirect from="*" to="/" />
            </Switch>
        </Router>
    );
};
