import React from 'react';
import { resetarCamposAction, validarAction, validarCamposAction, validarCpfCnpj } from '../../../validation/validationActions';

import axios from 'axios';
import { fecharModalServico, handleIsLoadingServicesStatus } from '../../servicos/redux/ServicoActions';
import { toast } from 'react-toastify';
import { RemoveMaskTelefone, RemoveMaskCep, RemoveMaskCpfCnpj } from '../../../components/mask/Mask';
import { checkResponseErrorType } from '../../../utils/utils';
import { CorrectIcon } from '../../../layouts/components/icons';

const idiomaText = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userLocales = user.preferencia.linguagem;
    const locales = userLocales ? (userLocales === 'EN_US' ? 'en-US' : 'pt-BR') : null;
    const defaltText = require(`../nls/${locales ? locales : 'pt-BR'}.json`);
    return defaltText.mensagem;
};

export const ATUALIZAR_CONTEUDO_ASSINANTE_MODAL = 'ATUALIZAR_CONTEUDO_ASSINANTE_MODAL';
export const atualizarConteudoAssinanteModal = evento => ({
    type: ATUALIZAR_CONTEUDO_ASSINANTE_MODAL,
    payload: evento
});

export const ATUALIZAR_CONTEUDO_ASSINANTE_SERVICO_MODAL = 'ATUALIZAR_CONTEUDO_ASSINANTE_SERVICO_MODAL';
export const atualizarServicoAssinanteModal = (evento, plano) => ({
    type: ATUALIZAR_CONTEUDO_ASSINANTE_SERVICO_MODAL,
    payload: { evento, plano }
});

export const SET_TIPO_MODAL_ASSINANTE = 'SET_TIPO_MODAL_ASSINANTE';
const setTipoModalAssinante = tipo => ({
    type: SET_TIPO_MODAL_ASSINANTE,
    payload: tipo
});

export const SET_ASSINANTE = 'SET_ASSINANTE';
const setAssinante = assinante => ({
    type: SET_ASSINANTE,
    payload: assinante
});

export const SET_ASSINANTE_INICIAL = 'SET_ASSINANTE_INICIAL';
const setAssinanteInicial = () => ({
    type: SET_ASSINANTE_INICIAL
});

export const ABRIR_MODAL_CRIAR_ASSINANTE = 'ABRIR_MODAL_CRIAR_ASSINANTE';
export const abrirModalCriarAssinante = () => [
    resetarCamposAction(),
    setAssinanteInicial(),
    setTipoModalAssinante('criando')
];

export const ABRIR_MODAL_EDITAR_ASSINANTE = 'ABRIR_MODAL_EDITAR_ASSINANTE';
export const abrirModalEditarAssinante = assinante => [
    resetarCamposAction(),
    setAssinante(assinante),
    setTipoModalAssinante('editando')
];

export const ABRIR_MODAL_DELETAR_ASSINANTE = 'ABRIR_MODAL_DELETAR_ASSINANTE';
export const abrirModalDeletarAssinante = assinante => [setAssinante(assinante), setTipoModalAssinante('deletando')];

export const ABRIR_MODAL_INFORMACAO = 'ABRIR_MODAL_INFORMACAO';
export const abrirModalInformacaoAssinante = assinante => [
    setAssinante(assinante),
    setTipoModalAssinante('informacoes')
];

export const ABRIR_MODAL_INTERNET = 'ABRIR_MODAL_INTERNET';
export const abrirModalInternetAssinante = assinante => [
    resetarCamposAction(),
    setAssinante(assinante),
    setTipoModalAssinante('internet')
];

export const ABRIR_MODAL_SERVICOS_ASSINANTE = 'ABRIR_MODAL_SERVICOS_ASSINANTE';
export const abrirModalServicosAssinante = assinante => [
    resetarCamposAction(),
    setAssinante(assinante),
    setTipoModalAssinante('servicos')
];

export const ABRIR_MODAL_PAGAMENTO_ASSINANTE = 'ABRIR_MODAL_PAGAMENTO_ASSINANTE';
export const abrirModalPagamentosAssinante = assinante => [
    resetarCamposAction(),
    setAssinante(assinante),
    setTipoModalAssinante('pagamentos')
];

export const ABRIR_MODAL_TELEFONE = 'ABRIR_MODAL_TELEFONE';
export const abrirModalTelefoneAssinante = assinante => [
    resetarCamposAction(),
    setAssinante(assinante),
    setTipoModalAssinante('telefone')
];

export const FECHAR_MODAL_ASSINANTE = 'FECHAR_MODAL_ASSINANTE';
export const fecharModalAssinante = () => [setAssinanteInicial(), setTipoModalAssinante('')];

export const LIMPAR_CAMPO_PESQUISA_ASSINANTE = 'LIMPAR_CAMPO_PESQUISA_ASSINANTE';
export const limparCampoPesquisa = () => [
    {
        type: LIMPAR_CAMPO_PESQUISA_ASSINANTE
    },
    /* listarAssinantes() */
];

export const ATUALIZAR_CAMPO_PESQUISA_ASSINANTE = 'ATUALIZAR_CAMPO_PESQUISA_ASSINANTE';
export const atualizarCampoPesquisa = e => ({
    type: ATUALIZAR_CAMPO_PESQUISA_ASSINANTE,
    payload: e.target.value
});

export const CARREGANDO_ASSINANTES = 'CARREGANDO_ASSINANTES';
const carregandoAssinantes = () => ({
    type: CARREGANDO_ASSINANTES
});

export const ASSINANTES_CARREGADOS_COM_SUCESSO = 'ASSINANTES_CARREGADOS_COM_SUCESSO';
const assinantesCarregadosComSuceso = assinantes => ({
    type: ASSINANTES_CARREGADOS_COM_SUCESSO,
    payload: assinantes
});

export const ASSINANTES_CARREGADOS_COM_ERRO = 'ASSINANTES_CARREGADOS_COM_ERRO';
const assinantesCarregadosComErro = erro => ({
    type: ASSINANTES_CARREGADOS_COM_ERRO,
    payload: erro
});

export const listarAssinantes = e => {
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        assinante: e && e.pesquisa ? `&campo=${e.pesquisa}` : ''
    };
    return dispatch => {
        dispatch(handleIsLoadingClientsStatus(true));
        dispatch(carregandoAssinantes());
        axios
            .get(`/api/assinante?${params.pagina + params.assinante}`)
            .then(response => dispatch(assinantesCarregadosComSuceso(response.data)))
            .catch(error => {
                dispatch(assinantesCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
                dispatch(handleIsLoadingClientsStatus(false));
            });
    };
};

export const CARREGANDO_ASSINANTE = 'CARREGANDO_ASSINANTE';
const carregandoAssinante = () => ({
    type: CARREGANDO_ASSINANTE
});

export const ASSINANTE_CARREGADOS_COM_SUCESSO = 'ASSINANTE_CARREGADOS_COM_SUCESSO';
const assinanteCarregadosComSuceso = assinante => ({
    type: ASSINANTE_CARREGADOS_COM_SUCESSO,
    payload: assinante
});

export const ASSINANTE_CARREGADOS_COM_ERRO = 'ASSINANTE_CARREGADOS_COM_ERRO';
const assinanteCarregadosComErro = erro => ({
    type: ASSINANTE_CARREGADOS_COM_ERRO,
    payload: erro
});

export const SERVICOS_ASSINANTE_CARREGADOS_COM_SUCESSO = 'SERVICOS_ASSINANTE_CARREGADOS_COM_SUCESSO';
const servicosAssinanteCarregadosComSuceso = servicos => ({
    type: SERVICOS_ASSINANTE_CARREGADOS_COM_SUCESSO,
    payload: servicos
});

export const SERVICOS_ASSINANTE_CARREGADOS_COM_ERRO = 'SERVICOS_ASSINANTE_CARREGADOS_COM_ERRO';
const servicosAssinanteCarregadosComErro = erro => ({
    type: SERVICOS_ASSINANTE_CARREGADOS_COM_ERRO,
    payload: erro
});

export const listarAssinante = idAssinante => {
    return dispatch => {
        dispatch(handleIsLoadingClientsStatus(true));
        dispatch(carregandoAssinante());
        Promise.allSettled([
            axios.get(`/api/assinante/${idAssinante}`),
            axios.get(`/api/servico/assinante/${idAssinante}`)
        ])
            .then(([assinante, servicosDoAssinante]) => {
                if (assinante.status !== "rejected" && servicosDoAssinante.status !== 'rejected') {
                    dispatch(assinanteEServicosCarregadosComSucesso({ assinante: assinante.value.data, servicosDoAssinante: servicosDoAssinante.value.data }));
                }
                /* else {
                    checkResponseErrorType(error, idiomaText().listarDadosErro);
                } */

                /* if (assinante.status === "rejected") {
                    toast.error(idiomaText().listarDadosErro);
                }
                else {
                    //toast.success(idiomaText().listarSuccess);
                    dispatch(assinanteCarregadosComSuceso(assinante.value.data));
                }

                if (servicosDoAssinante.status === "rejected") {
                    toast.error(idiomaText().listarServicosErro);
                }
                else {
                    dispatch(servicosAssinanteCarregadosComSuceso(servicosDoAssinante.value.data));
                    //toast.success(idiomaText().listarSuccess);
                } */
            })
            .catch((error) => {
                checkResponseErrorType(error, idiomaText().listarDadosErro);
            });
    };
};

export const carregarAssinante = idAssinante => {
    return dispatch => {
        dispatch(carregandoAssinante());
        axios
            .get(`/api/assinante/${idAssinante}`)
            .then(response => dispatch(assinanteCarregadosComSuceso(response.data)))
            .catch(error => {
                dispatch(assinanteCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarDadosErro);
            });
    };
};

export const ADICIONANDO_ASSINANTE = 'ADICIONANDO_ASSINANTE';
const adicionandoAssinante = () => ({
    type: ADICIONANDO_ASSINANTE
});

export const ASSINANTE_ADICIONADO_COM_SUCESSO = 'ASSINANTE_ADICIONADO_COM_SUCESSO';
const assinanteAdicionadoComSucesso = assinante => ({
    type: ASSINANTE_ADICIONADO_COM_SUCESSO,
    payload: assinante
});

export const ASSINANTE_NAO_ADICIONADO = 'ASSINANTE_NAO_ADICIONADO';
const assinanteNaoAdicionado = erro => ({
    type: ASSINANTE_NAO_ADICIONADO,
    payload: erro
});

const assinanteRequired = {
    documento: 'cpf',
    nome: '',
};

export const adicionarAssinante = (assinante, page) => {
    const assinanteJson = {
        nContrato: assinante.nContrato.replace(/\D/g, ""),
        documento: RemoveMaskCpfCnpj(assinante.documento),
        nome: assinante.nome,
        telefone: RemoveMaskTelefone(assinante.telefone),
        email: assinante.email,
        cep: RemoveMaskCep(assinante.cep),
        endereco: assinante.endereco,
        descricao: assinante.descricao,
        complemento: assinante.complemento,
        bairro: assinante.bairro,
        cidade: assinante.cidade,
        estado: assinante.estado,
    };

    const assinantePatternRequired = {
        email: "email",
        cep: "cep",
        telefone: "phone"
    };

    const assinanteVal = {
        formPatternRequired: assinantePatternRequired,
        formRequired: assinanteRequired,
        formValues: assinanteJson
    };
    return dispatch => {
        let mensagemDeErro = validarCamposAction(assinanteVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        mensagemDeErro = validarCpfCnpj(assinanteJson.documento);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalAssinante());
        dispatch(adicionandoAssinante());
        axios
            .post('/api/assinante', assinanteJson)
            .then(response => {
                //dispatch(assinanteAdicionadoComSucesso(response.data)) // Comentar novamente quando o Websocket for implementado
                dispatch(listarAssinantes({ pagina: page }));
                toast.success((idiomaText().adicionarSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(assinanteNaoAdicionado(erro));
                checkResponseErrorType(erro, idiomaText().adicionarErro);
            });
    };
};

export const ATUALIZANDO_ASSINANTE = 'ATUALIZANDO_ASSINANTE';
const atualizandoAssinante = () => ({
    type: ATUALIZANDO_ASSINANTE
});

export const ASSINANTE_ATUALIZADO_COM_SUCESSO = 'ASSINANTE_ATUALIZADO_COM_SUCESSO';
export const assinanteAtualizadoComSucesso = assinante => ({
    type: ASSINANTE_ATUALIZADO_COM_SUCESSO,
    payload: assinante
});

export const ASSINANTE_NAO_ATUALIZADO = 'ASSINANTE_NAO_ATUALIZADO';
const assinanteNaoAtualizado = erro => ({
    type: ASSINANTE_NAO_ATUALIZADO,
    payload: erro
});

export const atualizarAssinante = (assinante, page) => {
    //const assinanteJson = assinante
    const assinanteJson = {
        id: assinante.id,
        nContrato: assinante.nContrato.replace(/\D/g, ""),
        documento: RemoveMaskCpfCnpj(assinante.documento),
        nome: assinante.nome,
        telefone: RemoveMaskTelefone(assinante.telefone),
        email: assinante.email,
        cep: RemoveMaskCep(assinante.cep),
        endereco: assinante.endereco,
        descricao: assinante.descricao,
        complemento: assinante.complemento
    };

    const assinanteVal = {
        formRequired: assinanteRequired,
        formValues: assinanteJson
    };
    return dispatch => {
        let mensagemDeErro = validarCamposAction(assinanteVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        mensagemDeErro = validarCpfCnpj(assinanteJson.documento);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalAssinante());
        dispatch(atualizandoAssinante());
        axios
            .put('/api/assinante', assinanteJson)
            .then(response => {
                //dispatch(assinanteAtualizadoComSucesso(response.data)) //Comentar novamente quando o Websocket for implementado
                dispatch(listarAssinantes({ pagina: page }));
                toast.success((idiomaText().atualizarSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });

            })
            .catch(erro => {
                dispatch(assinanteNaoAtualizado(erro));
                checkResponseErrorType(erro, idiomaText().atualizarErro);
            });
    };
};

export const DELETANDO_ASSINANTE = 'DELETANDO_ASSINANTE';
const deletandoAssinante = () => ({
    type: DELETANDO_ASSINANTE
});

export const ASSINANTE_DELETADO_COM_SUCESSO = 'ASSINANTE_DELETADO_COM_SUCESSO';
export const assinanteDeletadoComSucesso = assinante => ({
    type: ASSINANTE_DELETADO_COM_SUCESSO,
    payload: assinante
});

export const ASSINANTE_NAO_DELETADO = 'ASSINANTE_NAO_DELETADO';
const assinanteNaoDeletado = erro => ({
    type: ASSINANTE_NAO_DELETADO,
    payload: erro
});

export const deletarAssinante = (assinante, page) => {
    return dispatch => {
        dispatch(fecharModalAssinante());
        dispatch(deletandoAssinante());
        axios
            .delete(`/api/assinante/${assinante.id}`)
            .then(() => {
                //dispatch(assinanteDeletadoComSucesso(assinante.id))
                dispatch(listarAssinantes({ pagina: page }));
                toast.success((idiomaText().deletarSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(assinanteNaoDeletado(erro));
                checkResponseErrorType(erro, idiomaText().deletarErro);
            });
    };
};

export const ADICIONANDO_ASSINANTE_SERVICO = 'ADICIONANDO_ASSINANTE_SERVICO';
const adicionandoAssinanteServico = () => ({
    type: ADICIONANDO_ASSINANTE_SERVICO
});

export const ASSINANTE_SERVICO_ADICIONADO_COM_SUCESSO = 'ASSINANTE_SERVICO_ADICIONADO_COM_SUCESSO';
const assinanteServicoAdicionadoComSucesso = assinante => ({
    type: ASSINANTE_SERVICO_ADICIONADO_COM_SUCESSO,
    payload: assinante
});

export const ASSINANTE_SERVICO_NAO_ADICIONADO = 'ASSINANTE_SERVICO_NAO_ADICIONADO';
const assinanteServicoNaoAdicionado = erro => ({
    type: ASSINANTE_SERVICO_NAO_ADICIONADO,
    payload: erro
});
const servicoRequired = {
    nome: '',
    plano: {},
    cpe: {},
    olt: {}
};

export const adicionarServico = servico => {
    const servicoJson = servico;
    const servicoVal = {
        formRequired: servicoRequired,
        formValues: servicoJson
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(servicoVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalServico());
        dispatch(adicionandoAssinanteServico());
        axios
            .post('/api/servico', servicoJson)
            .then(response => {
                dispatch(assinanteServicoAdicionadoComSucesso(response.data));
                toast.success((idiomaText().adicionarServicoSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(assinanteServicoNaoAdicionado(erro));
                checkResponseErrorType(erro, idiomaText().adicionarServicoErro);
            });
    };
};

export const ATUALIZANDO_ASSINANTE_SERVICO = 'ATUALIZANDO_ASSINANTE_SERVICO';
const atualizandoAssinanteServico = () => ({
    type: ATUALIZANDO_ASSINANTE_SERVICO
});

export const ASSINANTE_SERVICO_ATUALIZADO_COM_SUCESSO = 'ASSINANTE_SERVICO_ATUALIZADO_COM_SUCESSO';
const assinanteServicoAtualizadoComSucesso = assinante => ({
    type: ASSINANTE_SERVICO_ATUALIZADO_COM_SUCESSO,
    payload: assinante
});

export const ASSINANTE_SERVICO_NAO_ATUALIZADO = 'ASSINANTE_SERVICO_NAO_ATUALIZADO';
const assinanteServicoNaoAtualizado = erro => ({
    type: ASSINANTE_SERVICO_NAO_ATUALIZADO,
    payload: erro
});

export const atualizarServico = servico => {
    const servicoJson = servico;
    const servicoVal = {
        formRequired: servicoRequired,
        formValues: servicoJson
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(servicoVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalServico());
        dispatch(atualizandoAssinanteServico());
        axios
            .put('/api/servico', servicoJson)
            .then(response => {
                dispatch(listarAssinante(servico.idAssinante));
                dispatch(assinanteServicoAtualizadoComSucesso(response.data));
                toast.success((idiomaText().atualizarServicoSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(assinanteServicoNaoAtualizado(erro));
                checkResponseErrorType(erro, idiomaText().atualizarServicoErro);
            });

    };
};

export const vincularServico = servico => {
    const servicosRequired = servico;
    delete servicosRequired.usuarioPPPoE;
    delete servicosRequired.senhaPPPoE;

    const servicoJson = servico;
    const servicoVal = {
        formRequired: { servico: servicosRequired },
        formValues: { servico: servicoJson }
    };
    const assinanteId = window.location.pathname.split("/")[3];
    const params = {
        idAssinante: `idAssinante=${assinanteId ? assinanteId : ''}`,
        idServico: `&idServico=${servico && servico.id ? servico.id : ''}`
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(servicoVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalServico());
        dispatch(atualizandoAssinanteServico());
        axios
            .post(`/api/assinante/vincular?${params.idAssinante + params.idServico}`)
            .then(response => {
                dispatch(listarAssinante(assinanteId));
                toast.success((idiomaText().vincularServicoSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(assinanteServicoNaoAtualizado(erro));
                checkResponseErrorType(erro, idiomaText().vincularServicoErro);
            });

    };
};

export const DELETANDO_ASSINANTE_SERVICO = 'DELETANDO_ASSINANTE_SERVICO';
const deletandoAssinanteServico = () => ({
    type: DELETANDO_ASSINANTE_SERVICO
});

export const ASSINANTE_SERVICO_DELETADO_COM_SUCESSO = 'ASSINANTE_SERVICO_DELETADO_COM_SUCESSO';
const assinanteServicoDeletadoComSucesso = servico => ({
    type: ASSINANTE_SERVICO_DELETADO_COM_SUCESSO,
    payload: servico
});

export const ASSINANTE_SERVICO_NAO_DELETADO = 'ASSINANTE_SERVICO_NAO_DELETADO';
const assinanteServicoNaoDeletado = erro => ({
    type: ASSINANTE_SERVICO_NAO_DELETADO,
    payload: erro
});

export const deletarServico = servico => {
    return dispatch => {
        dispatch(fecharModalServico());
        dispatch(deletandoAssinanteServico());
        axios
            .delete(`/api/servico/${servico.id}`)
            .then(() => {
                dispatch(assinanteServicoDeletadoComSucesso(servico.id));
                toast.success((idiomaText().deletarServicoSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(assinanteServicoNaoDeletado(erro));
                checkResponseErrorType(erro, idiomaText().deletarServicoErro);
            });
    };
};

export const RESET_CLIENTS_STATE = "RESET_CLIENTS_STATE";
export const resetClientsState = () => ({
    type: RESET_CLIENTS_STATE,
});

export const HANDLE_IS_LOADING_CLIENTSS_STATUS = "HANDLE_IS_LOADING_CLIENTSS_STATUS";
export const handleIsLoadingClientsStatus = (status) => ({
    type: HANDLE_IS_LOADING_CLIENTSS_STATUS,
    payload: status
});


export const CLIENT_AND_SERVICES_LOADED_SUCCESSFULLY = "CLIENT_AND_SERVICES_LOADED_SUCCESSFULLY";
export const assinanteEServicosCarregadosComSucesso = (data) => ({
    type: CLIENT_AND_SERVICES_LOADED_SUCCESSFULLY,
    payload: data
});

export const CLEAR_CRIAR_EDITAR_ASSINANTE_FIELD = "CLEAR_CRIAR_EDITAR_ASSINANTE_FIELD";
export const clearCriarEditarAssinanteField = (e) => ({
    type: CLEAR_CRIAR_EDITAR_ASSINANTE_FIELD,
    payload: e
});

export const IS_SEARCH = 'assinante.IS_SEARCH';
export const isSearch = () => ({
    type: IS_SEARCH
});

export const desvincularServico = servico => {
    const params = {
        idServico: `idServico=${servico && servico.id ? servico.id : ''}`
    };
    return dispatch => {
        dispatch(fecharModalServico());
        dispatch(deletandoAssinanteServico());
        axios
            .post(`/api/assinante/desvincular?${params.idServico}`)
            .then(() => {
                dispatch(assinanteServicoDeletadoComSucesso(servico.id));
                toast.success((idiomaText().desvincularServicoSucesso), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(assinanteServicoNaoDeletado(erro));
                checkResponseErrorType(erro, idiomaText().desvincularServicoErro);
            });
    };
};