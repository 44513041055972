import {
  ATUALIZAR_CAMPO_PESQUISA_PAGAMENTO,
  LIMPAR_CAMPO_PESQUISA_PAGAMENTO,
  ATUALIZAR_CONTEUDO_PAGAMENTO_MODAL,
  PAGAMENTOS_CARREGADOS_COM_ERRO,
  PAGAMENTOS_CARREGADOS_COM_SUCESSO,
  CARREGANDO_PAGAMENTOS,
  ADICIONANDO_PAGAMENTO,
  PAGAMENTO_NAO_ADICIONADO,
  PAGAMENTO_ADICIONADO_COM_SUCESSO,
  ATUALIZANDO_PAGAMENTO,
  PAGAMENTO_NAO_ATUALIZADO,
  PAGAMENTO_ATUALIZADO_COM_SUCESSO,
  DELETANDO_PAGAMENTO,
  PAGAMENTO_NAO_DELETADO,
  PAGAMENTO_DELETADO_COM_SUCESSO,
  SET_TIPO_MODAL_PAGAMENTO,
  SET_PAGAMENTO,
  SET_PAGAMENTO_INICIAL
} from './PagamentoActions'

const pagamentoInicial = {
  nome: '',
  ip: '',
  porta: '',
  usuario: '',
  senha: ''
}
const initialState = {
  campoPesquisaPagamento: '',
  statusModal: '',
  pagamentos: [],
  pagamento: pagamentoInicial
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CARREGANDO_PAGAMENTOS:
      return { ...state }
    case PAGAMENTOS_CARREGADOS_COM_ERRO:
      return { ...state }
    case PAGAMENTOS_CARREGADOS_COM_SUCESSO:
      return { ...state, pagamentos: payload }

    case ADICIONANDO_PAGAMENTO:
      return { ...state }
    case PAGAMENTO_NAO_ADICIONADO:
      return { ...state }
    case PAGAMENTO_ADICIONADO_COM_SUCESSO:
      return { ...state, pagamentos: [...state.pagamentos, payload] }

    case ATUALIZANDO_PAGAMENTO:
      return { ...state }
    case PAGAMENTO_NAO_ATUALIZADO:
      return { ...state }
    case PAGAMENTO_ATUALIZADO_COM_SUCESSO:
      return {
        ...state,
        pagamentos: state.pagamentos.map(pagamento => (pagamento.id === payload.id ? payload : pagamento))
      }

    case DELETANDO_PAGAMENTO:
      return { ...state }
    case PAGAMENTO_NAO_DELETADO:
      return { ...state }
    case PAGAMENTO_DELETADO_COM_SUCESSO:
      return {
        ...state,
        pagamentos: state.pagamentos.filter(pagamento => pagamento.id !== payload)
      }
    case SET_TIPO_MODAL_PAGAMENTO:
      return { ...state, statusModal: payload }

    case SET_PAGAMENTO:
      return { ...state, pagamento: payload }

    case SET_PAGAMENTO_INICIAL:
      return { ...state, pagamento: pagamentoInicial }

    case ATUALIZAR_CAMPO_PESQUISA_PAGAMENTO:
      return { ...state, campoPesquisaPagamento: payload }

    case LIMPAR_CAMPO_PESQUISA_PAGAMENTO:
      return { ...state, campoPesquisaPagamento: '' }

    case ATUALIZAR_CONTEUDO_PAGAMENTO_MODAL:
      return {
        ...state,
        pagamento: {
          ...state.pagamento,
          [payload.target.name]: payload.target.value
        }
      }

    default:
      return state
  }
}
