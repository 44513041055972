import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IcoEditar, IcoRemoveLine } from '../../components/icones';
import BarraPesquisa from './barraDePesquisa';
import CriarEditar from './criarEditar';
import List from '../../components/list/List';
import Paginate from '../../components/pagination/Pagination';
import Remover from './remover';

import {
    listarPerfis,
    abrirModalEditarPerfil,
    abrirModalDeletarPerfil,
    atualizarPerfil,
    adicionarPerfil,
    deletarPerfil,
    ajustModulos
} from './redux/PerfilActions';
import Button from '../../components/button/Button';

const Perfis = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);

    const perfis = useSelector(state => state.perfisState.perfis);
    const perfil = useSelector(state => state.perfisState.perfil);

    const statusModal = useSelector(state => state.perfisState.statusModal);
    const paginacao = useSelector(state => state.perfisState.paginacao);

    useEffect(() => {
        dispatch(listarPerfis(0));
    }, [dispatch]);

    const atualizarLista = val => {
        dispatch(listarPerfis(val));
    };

    const listHead = [
        {
            colunm: 'nome',
            text: defaltText.inputs.nome,
            className: 'text-left'
        },
        {
            colunm: 'permissoesList',
            text: defaltText.inputs.permissoes,
            className: ''
        },
        {
            colunm: 'actions',
            text: defaltText.inputs.acoes,
            className: 'actions'
        }
    ];

    const listActions = val => {
        switch (val.item) {
            case 'actions':
                return (
                    <>
                        <Button
                            titulo={defaltText.btn.editar}
                            action={() => dispatch(abrirModalEditarPerfil(val.itens))}
                            color='secondary'
                            type='btn circle'
                            texto={<IcoEditar title={defaltText.btn.editar} />}
                            size='small'
                        />
                        <Button
                            titulo={defaltText.btn.remover}
                            action={() => dispatch(abrirModalDeletarPerfil(val.itens))}
                            color="success"
                            type='btn circle'
                            texto={<IcoRemoveLine title={defaltText.btn.remover} />}
                            size='small'
                        />
                    </>
                );
            default:
                return;
        }
    };

    const action = perfil.id ? atualizarPerfil : adicionarPerfil;

    return (
        <div className='box-perfis'>
            <h2>{defaltText.title} </h2>
            <div>
                <BarraPesquisa />
                <List data={{ head: listHead, content: perfis }} listActions={val => listActions(val)} />
                {paginacao && paginacao.totalPages > 1 ? (
                    <Paginate data={paginacao} action={val => atualizarLista(val)} />
                ) : null}
            </div>
            {statusModal === 'criando' || statusModal === 'editando' ? (
                <CriarEditar action={() => dispatch(action(perfil))} />
            ) : null}
            {statusModal === 'deletando' ? (
                <Remover action={() => dispatch([deletarPerfil(perfil), listarPerfis()])} />
            ) : null}
        </div>
    );
};

export default Perfis;
