import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BarraPesquisa from './barraDePesquisa';
import CriarEditar from './criarEditar/criarEditarOlt';
import Erro from './erro/erro';
import Remover from './remover/remover';

import Paginate from '../../components/pagination/Pagination';

import Row from '../../components/row/row';
import OltCard from '../../layouts/components/cards/OltCard';
import SigCloudLoader from '../../layouts/components/Loader/SigCloudLoader';
import TitleContainer from '../../layouts/components/TitleContainer/TitleContainer';
import ModalAdapter from './modalAdapter/modalAdapter';
import ModalWarning from './modalWarning/modalWarning';
import { handleIsLoadingOltStatus, listarOlts, resetOltState, setTipoModalWarning } from './redux/OltActions';
import ConfirmaRemover from './remover/confirmaRemover';

export default () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const olts = useSelector((state) => state.oltsState.olts);
    const paginacao = useSelector((state) => state.oltsState.paginacao);
    const statusModal = useSelector((state) => state.oltsState.statusModal);
    const defaltText = require(`./nls/${locales}.json`);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const isLoading = useSelector((state) => state.oltsState.isLoading);
    const firstLogin = localStorage.getItem('firstLogin');
    const [askAgain, setAskAgain] = useState(localStorage.getItem('ask') === '23/04/2024' ? false : true)
    const checkedNovoAdapter = localStorage.getItem('checkedNovoAdapter');

    useEffect(() => {
        dispatch(listarOlts());
        //if (firstLogin === 'true') {
        if (firstLogin === 'true' || checkedNovoAdapter === "false" || (askAgain === true && checkedNovoAdapter === "true")) {
            //descomentar abaixo se quiser voltar a notificar adapter
            // dispatch(setTipoModalAdapter());
            //comentar abaixo se quiser voltar a notificar adapter
            dispatch(setTipoModalWarning());
            setAskAgain(false)
        }
        return () => {
            dispatch(handleIsLoadingOltStatus(false));
            dispatch(resetOltState());
        };
    }, [dispatch]);

    const modalOlt = (e, i) => {
        switch (e[i]) {
            case 'criando':
                return [<CriarEditar />, modalOlt(e, i + 1)];
            case 'editando':
                return [<CriarEditar />, modalOlt(e, i + 1)];
            case 'deletando':
                return [<Remover />, modalOlt(e, i + 1)];
            case 'erro':
                return [<Erro />, modalOlt(e, i + 1)];
            case 'modalAdapter':
                return [<ModalAdapter />, modalOlt(e, i + 1)];
            case 'modalWarning':
                return [<ModalWarning />, modalOlt(e, i + 1)];
            case 'confirmaDeletar':
                return [<ConfirmaRemover />, modalOlt(e, i + 1)];
            default:
                return;
        }
    };

    const atualizarLista = (val) => {
        dispatch(listarOlts({ pagina: val }));
    };

    //Daqui pra baixo código do card novo
    const labels = [
        { field: "nome", text: defaltText.inputs.nome },
        { field: "ip", text: defaltText.inputs.ip },
        { field: "conexoesDisponiveisParaAOlt", text: defaltText.inputs.conexoesDisponiveisParaAOlt },
        { field: "status", text: defaltText.inputs.status }
    ];
    const buttons = ["btnVlans", "btnInfo", "btnEditar", "btnRemover"];

    return (
        <>
            <SigCloudLoader visible={isLoading} />
            <div className="box-olt">
                <TitleContainer
                    label={defaltText.title}
                />
                <div>
                    <BarraPesquisa />
                    {/* <CardList
                    getHeaderButton={(val) => getOltCardHeaderButton(val)}
                    data={olts}
                    buttons={buttons}
                    labels={labels}
                    headerTitle="nome"
                    getCardActions={(val) => getOltCardActions(val, defaltText, permissions)}
                /> */}
                    <Row>
                        {olts.map((item) => (
                            <div className="col-md-3 col-sm-6">
                                <OltCard
                                    item={item}
                                    labels={labels}
                                    markerColor={(
                                        item.status === "ONLINE" || item.status === "OK") ? "ok" :
                                        item.status.includes("ERRO") ? "error" :
                                            item.status.toUpperCase() === "OFFLINE" ? "offline" :
                                                item.status.toUpperCase() === "ADAPTER_NAO_CONECTADO" ? "adapter-nao-conectado" :
                                                    item.status.toUpperCase() === "DELETADO" ? "delete" :
                                                        item.status.toUpperCase() === "NAO_SUPORTADA" ? "nao-suportada" :
                                                            "updating"}
                                />
                            </div>
                        ))}
                    </Row>
                    <Paginate data={paginacao} action={(val) => atualizarLista(val)} />
                </div>

                {modalOlt(statusModal, 0)}

            </div>
        </>
    );
};
