import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import BasicModal from '../../../../components/modal/BasicModal'

import ConteudoSelLocModal from './conteudoSelLoc'

import { fecharModalSelLoc } from '../redux/UserActions'


const SelecionarLoc = ({ preferencia, inputs }) => {

  const dispatch = useDispatch()

  const statusModal = useSelector((state) => state.authentication.statusModal);

  const defaltText = preferencia.linguagem
    ? require(`../../nls/${preferencia.linguagem}.json`)
    : require(`../../nls/pt-BR.json`)

  const title = defaltText.localizacao.titulo
  return (
    <BasicModal
      open={statusModal}
      close={() => dispatch(fecharModalSelLoc())}
      containerModal={{
        title,
        size: 'medium',
        content: <ConteudoSelLocModal
          preferencia={preferencia}
          inputs={inputs}
        />,
        closeText: "Fechar"
      }}
    />

  )


}


export default SelecionarLoc
