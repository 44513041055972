import {
    USUARIO_NAO_LOGADO_COM_SUCESSO,
    USUARIO_LOGADO_COM_SUCESSO,
    SET_TIPO_MODAL_SEL_LOC,
    EMAIL_ENVIADO_COM_SUCESSO,
    EMAIL_ENVIADO_COM_ERRO,
    RESETAR_REDEFINIR_SENHA,
    SET_HASH_VALIDADO,
    RESETAR_USUARIO_LOGADO,
    SET_LOCAL,
    ATUALIZAR_MEUS_DADOS_INPUT,
    LIMPAR_CAMPOS_MEUS_DADOS
} from './UserActions';

const defaultPreferences = {
    perfil: [],
    contrast: true,
    language: navigator.language,
    emailRecuperaSenha: '',
    emailEnviado: false,
    hashValidado: "naoValidado"
};
const user = JSON.parse(localStorage.getItem('user'));
//const user = {};
const initialUser = { user: { preferencia: defaultPreferences }, statusModal: '', };
//const initialUser = { user: { preferencia: defaultPreferences }, statusModal: 'manutencaoMessage', };
const initialState = user ? { loggedIn: true, wasLogged: false, user, redefinirSenha: { senhaAtual: "", novaSenha: "", confirmarSenha: "" } } : { ...initialUser };
const initialRedefinirSenha = {
    senhaAtual: "",
    novaSenha: "",
    confirmarSenha: ""
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case USUARIO_NAO_LOGADO_COM_SUCESSO:
            return {
                ...state,
                loggedIn: false,
                wasLogged: true
            };
        case USUARIO_LOGADO_COM_SUCESSO:
            return {
                ...state,
                wasLogged: true,
                loggedIn: true,
                user: { ...payload, aplicacaoLogado: payload && payload.aplicacaoLogado ? payload.aplicacaoLogado : user.aplicacaoLogado }
            };
        case SET_TIPO_MODAL_SEL_LOC:
            return { ...state, statusModal: payload };
        case EMAIL_ENVIADO_COM_SUCESSO:
            return {
                ...state,
                emailEnviado: true,
                emailRecuperaSenha: payload
            };
        case EMAIL_ENVIADO_COM_ERRO:
            return {
                ...state,
                emailEnviado: false,
                emailRecuperaSenha: ""
            };
        case SET_HASH_VALIDADO:
            return { ...state, hashValidado: payload };
        case RESETAR_REDEFINIR_SENHA:
            return { ...state, emailEnviado: false, emailRecuperaSenha: '', hashValidado: 'naoValidado' };
        case RESETAR_USUARIO_LOGADO:
            return {
                ...state,
                user: initialUser
            };
        case SET_LOCAL:
            return {
                ...state,
                local: payload
            };
        case ATUALIZAR_MEUS_DADOS_INPUT:
            return {
                ...state,
                redefinirSenha: {
                    ...state.redefinirSenha,
                    [payload.target.name]: payload.target.value
                }
            };
        case LIMPAR_CAMPOS_MEUS_DADOS:
            return {
                ...state,
                redefinirSenha: initialRedefinirSenha
            };
        default:
            return state;
    }
};
