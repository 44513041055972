import React, { useEffect, useState } from 'react';

const DonutChart = ({ data }) => {
    const [chartWidth, setChartWidth] = useState(200);
    useEffect(() => {
        const handleResize = () => {
            const containerWidth = document.getElementById('donut-chart-container').clientWidth;
            setChartWidth(containerWidth + (chartWidth - containerWidth));
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const total = data.reduce((acc, item) => acc + item.value, 0);
    let currentAngle = -90;

    const [tooltip, setTooltip] = useState({ show: false, x: 0, y: 0, name: '', value: 0 });

    const handleMouseEnter = (name, value, x, y) => {
        setTooltip({ show: true, x, y, name, value });
    };

    const handleMouseLeave = () => {
        setTooltip({ ...tooltip, show: false });
    };

    const classesWithValue = data.filter((item) => item.value > 0);

    function NormalDonutChart() {
        const singleClassWithValue = classesWithValue[0];
        console.warn('--------------------------------------------------------')
        console.warn(data)
        return (
            <div id="donut-chart-container" style={{ display: 'inline-block' }}>
                {classesWithValue.length > 1 ?
                    <svg width={chartWidth} height={chartWidth}>
                        {data.map((item) => {
                            const { name, label, value, color } = item;
                            const angle = (value / total) * 360;
                            const endAngle = currentAngle + angle;
                            const largeArcFlag = angle > 180 ? 1 : 0;
                            const x1 = 100 + 95 * Math.cos((currentAngle * Math.PI) / 180);
                            const y1 = 100 + 95 * Math.sin((currentAngle * Math.PI) / 180);
                            const x2 = 100 + 95 * Math.cos((endAngle * Math.PI) / 180);
                            const y2 = 100 + 95 * Math.sin((endAngle * Math.PI) / 180);

                            const tooltipY = 100 + 80 * Math.sin(((currentAngle + angle / 2) * Math.PI) / 180);
                            const percentageX = 100 + 78 * Math.cos(((currentAngle + angle / 2) * Math.PI) / 180);
                            const percentageY = 100 + 78 * Math.sin(((currentAngle + angle / 2) * Math.PI) / 180);
                            currentAngle = endAngle;

                            return (
                                <g key={name}>
                                    <path
                                        d={`M 100 100 L ${x1} ${y1} A 95 95 0 ${largeArcFlag} 1 ${x2} ${y2} Z`}
                                        fill={color}
                                        // onMouseEnter={(e) => handleMouseEnter(name, value, e.clientX, tooltipY)}
                                        // onMouseLeave={handleMouseLeave}
                                    />
                                    {value > 0 ?
                                        <text
                                            x={percentageX}
                                            y={percentageY}
                                            fill="black"
                                            textAnchor="middle"
                                            dominantBaseline="central"
                                            style={{ fontSize: '12px', fontWeight: 'bold' }}
                                        >
                                            {value}
                                        </text>
                                        : null}
                                </g>
                            );
                        })}
                        <circle cx="100" cy="100" r="60" fill="white" />

                        <text
                            x="100"
                            y="100"
                            fill="black"
                            textAnchor="middle"
                            dominantBaseline="central"
                            style={{ fontSize: '20px', fontWeight: 'bold' }}
                        >
                            {total}
                        </text>
                    </svg>
                    :
                    <svg width={chartWidth} height={chartWidth}>
                        <circle
                            cx="100"
                            cy="100"
                            r="95"
                            fill={singleClassWithValue?.color}
                            // onMouseEnter={(e) => handleMouseEnter(singleClassWithValue?.name, singleClassWithValue?.value, e.clientX + 30, e.clientY - 180)}
                            // onMouseLeave={handleMouseLeave}
                        />
                        <circle cx="100" cy="100" r="60" fill="white" />
                        <text
                            x="100"
                            y="100"
                            fill="black"
                            textAnchor="middle"
                            dominantBaseline="central"
                            style={{ fontSize: '20px', fontWeight: 'bold' }}
                        >
                            {singleClassWithValue?.value}
                        </text>

                    </svg>

                }
                <div style={{ marginLeft: '10px' }}>
                    {data.map((item) => (
                        item.value > 0 ?
                            <div key={item.name} style={{ display: 'flex', alignItems: 'center', margin: '15px 0' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: item.color,
                                        marginRight: '10px',
                                    }}
                                />
                                <span>{item.name}</span>
                            </div>
                            : null
                    ))}
                </div>
                {tooltip.show && (
                    <div
                        style={{
                            position: 'absolute',
                            left: tooltip.x - 30,
                            top: tooltip.y + 70,
                            background: 'rgba(0, 0, 0, 0.8)',
                            color: 'white',
                            padding: '4px',
                            borderRadius: '4px',
                        }}
                    >
                        <div>{tooltip.name}</div>
                        <div>{tooltip.value}</div>
                    </div>
                )}
            </div>
        )
    }
    return (
        <NormalDonutChart />
    );
};

export default DonutChart;
