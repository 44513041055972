import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/button/Button';
import { IcoEditar, IcoRemoveLine, IcoGear } from '../../../components/icones';
import List from '../../../components/list/List';
import Row from '../../../components/row/row';
import { IcoMais } from '../../../components/icones';
import history from '../../../history';
import CriarEditarVlan from './criarEditarVlan/CriarEditarVlan';
import { abrirModalEditarVlan, fecharModalInterface, limparVlansDaInterface, obterInterfaceDaOlt, setInterface, setOlteInterface } from '../redux/OltActions';
import { abrirModalCriarVlan } from '../redux/OltActions';
import SigCloudLoader from '../../../layouts/components/Loader/SigCloudLoader';

import { listarOlt } from '../redux/OltActions';


import './vlans.css';
import { getPagePermissions } from '../../../utils/utils';
import TitleContainer from '../../../layouts/components/TitleContainer/TitleContainer';
import Table from '../../../layouts/components/Table/Table';
import EditarInterface from './editarInterface/editarInterface';
import AlterarModoInterface from './alterarModoInterface/alterarModoInterface';
import RemoverVlanInterface from './removerVlan/removerVlanInterface';

const InformacoesOlt = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const olt = useSelector((state) => state.oltsState.olt);
    const vlans = useSelector((state) => state.oltsState.vlans);
    const statusModal = useSelector((state) => state.oltsState.statusModal);
    const oltId = window.location.pathname.split('/')[3];
    const isLoading = useSelector((state) => state.oltsState.isLoading);
    
    useEffect(() => {
        dispatch(listarOlt(oltId));
    }, [dispatch, vlans]);

    function getLabels() {
        let labels = [
            { text: defaltText.informacoes.interface, field: "nomeInterface" },
            { text: defaltText.informacoes.trunk, field: "tagVlan" },
            { text: defaltText.informacoes.pvid, field: "pvid" },
            { text: defaltText.informacoes.modo, field: "mode" }
        ];
        if(olt.modelo === "OLT_8820I"){
            labels = labels.filter( el => ( el.field !== "mode" && el.field !==  "pvid" )); 
            labels.push({ text: defaltText.informacoes.untagged, field: "utVlan" })
        }
      
        return labels;
      }
    const interfaceList = [
        {
            colunm: 'nomeInterface',
            text: defaltText.informacoes.interface,
            className: 'text-center',
        },
        {
            colunm: 'tagVlan',
            text: defaltText.informacoes.trunk,
            className: 'text-center',
        },
        {
            colunm: 'pvid',
            text: defaltText.informacoes.pvid,
            className: 'text-center',
        },
        {
            colunm: 'mode',
            text: defaltText.informacoes.modo,
            className: 'text-center',
        },
        {
            colunm: 'actions',
            text: defaltText.inputs.acoes,
            className: 'text-center',
        }
    ];


    const labels = getLabels();
    /* 
    Botões de ações da tabela antiga na tela de VLAN 
    const listActions = (val, table) => {
        if (table === 'vlan') {
            switch (val.item) {
                case 'actions':
                    return (
                        <>
                            <Button
                                hasAccess={permissions ? getPagePermissions(permissions, "vlans", "update") : false}
                                id="btnEditar"
                                titulo={defaltText.btn.editar}
                                action={() => dispatch(abrirModalEditarVlan(val.itens))}
                                children={<IcoEditar title={defaltText.btn.editar} />}
                                color="secondary"
                                type="btn circle"
                            />
                            <Button
                                hasAccess={permissions ? getPagePermissions(permissions, "vlans", "delete") : false}
                                id="btnRemover"
                                titulo={defaltText.btn.remover}
                                action={() => dispatch(abrirModalDeletarVlan(value.itens))}
                                children={<IcoRemoveLine title={defaltText.btn.remover} />}
                                color="success"
                                type="btn circle"
                            />

                        </>
                    );
                default:
                    return;
            }
        } else {
            switch (val.item) {
                case 'actions':
                    return (
                        <>
                            <Button
                                id="btnGear"
                                titulo={defaltText.btn.editar}
                                action={() => [history.push(`/vlans/olts/${oltId}/${val.itens.nomeInterface}`), dispatch(setInterface(val.itens))]}
                                children={<IcoGear title={defaltText.btn.editar} />}
                                color="secondary"
                                type="btn circle"
                            />
                            <Button
                                id="btnAdicionar"
                                hasAccess={permissions ? getPagePermissions(permissions, "vlans", "create") : false}
                                titulo={defaltText.informacoes.criarVlan}
                                //VERIFICAR
                                action={() => dispatch(abrirModalCriarVlan(val.itens))}
                                //action={() => dispatch(abrirModalCriarOlt())}
                                children={<IcoMais title={defaltText.informacoes.criarVlan} />}
                                color='success'
                                type='btn circle'
                            />
                        </>
                    );
                default:
                    return;
            }
        }

    }; */
    /*     const atualizarLista = (val) => {
            dispatch(listarOlt({ pagina: val }));
        }; */
    const modalCriarVlan = (e, i) => {
        switch (e[i]) {
            case 'abrindoCriarVlan':
                return [<CriarEditarVlan id={e[i]}/>, modalCriarVlan(e, i + 1)];
            case 'editar':
                return [<CriarEditarVlan id={e[i]}/>, modalCriarVlan(e, i + 1)];
            case 'alterarModoInterface':
                return [<AlterarModoInterface />, modalCriarVlan(e, i + 1)];
            case 'deletar':
                return [<RemoverVlanInterface />, modalCriarVlan(e, i + 1)];
            default:
                return;
        };
    };

    const handleInterfaceChange = (val) => {
        dispatch(limparVlansDaInterface());
        console.log(val.itens.nomeInterface);
        dispatch(obterInterfaceDaOlt(val.itens.nomeInterface, oltId));
    };

    return (<>
        <SigCloudLoader visible={(isLoading)} />
        <div className="box-vlans">
            <TitleContainer
                label={olt.nome + ' - ' + defaltText.titleInfoVlans}
            />

            <br></br>
            <br></br>
            <h6>{defaltText.titleInterface}</h6>
            <div className="col-md-12">
                <Table
                    itens={olt.portas10GE.concat(olt.portasE)}
                    labels={labels}
                    listActions={(val) => listActions(val)}
                    subTable={{
                        content: <EditarInterface />,
                        action: (val) => handleInterfaceChange(val)

                    }}
                />


                {/*  <List
                    data={{ head: interfaceList, content: portas.filter(porta => !porta.nome.includes("g")) }}
                    listActions={(val) => listActions(val, 'interface')}
                /> */}
                {/* <Paginate data={paginacao} action={(val) => atualizarLista(val)} /> */}
            </div>
            <div>
                <Row>
                    <div className="col-md-12">
                        <div className="flex-end">
                            <Button
                                titulo={defaltText.btn.voltar}
                                action={() => history.push(`/olts`)}
                                color="secondary"
                                children={defaltText.btn.voltar}
                                size="medium"
                            />
                        </div>
                    </div>
                </Row>
            </div>
            {modalCriarVlan(statusModal, 0)}
        </div>
    </>
    );
};

export default InformacoesOlt;
