import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { listarOlts, atualizarCampoPesquisa, abrirModalCriarOlt, limparCampoPesquisa, isSearch } from './redux/OltActions';

import InputInbox from '../../components/form/inputInBox/InputInbox';
import { IcoMais, IcoSearch } from '../../components/icones';

import Button from '../../components/button/Button';
import Row from '../../components/row/row';
import { getPagePermissions } from '../../utils/utils';
import Input from '../../layouts/components/Input/Input';

export default () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);
    const olt = useSelector((state) => state.oltsState);
    const campoPesquisa = useSelector(state => state.oltsState.campoPesquisa);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const handlePressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(listarOlts({ pesquisa: campoPesquisa }));
        }
    };
    return (
        <div>
            <Row style={{ justifyContent: "space-between" }}>
                <div className="col-md-6">
                    <div className='campo-pesquisa'>
                        <Input
                            id="pesquisar"
                            name='pesquisar'
                            label={defaltText.pesquisar.text}
                            value={campoPesquisa}
                            changeInput={e => dispatch(atualizarCampoPesquisa(e))}
                            onPressEnter={(e) => handlePressEnter(e)}
                            clearField={{
                                action: (e) => dispatch(limparCampoPesquisa(e)),
                                text: defaltText.btn.limparCampo
                            }}
                            leftButton={{
                                action: () => { dispatch(listarOlts({ pesquisa: campoPesquisa })), dispatch(isSearch()); },
                                text: defaltText.pesquisar.text,
                                icon: <IcoSearch title={defaltText.pesquisar.text} />
                            }}
                        />
                    </div>
                </div>

                <div className="col-md-2 div-new-button">
                    <Button
                        hasAccess={getPagePermissions(permissions, "olts", "create")}

                        id="btnAdicionar"
                        titulo={defaltText.btn.adicionarOlt}
                        action={() => dispatch(abrirModalCriarOlt())}
                        texto={defaltText.btn.adicionarOlt}
                        children={<IcoMais className="btn-border" title={defaltText.btn.adicionarOlt} />}
                        color='success'
                        type='btn'
                    />
                </div>
            </Row>
            {olt.isSearch === true && campoPesquisa !== '' ?
                <p className='qntRegisters'>
                    <span className='boldText'>
                        {olt.paginacao.totalElements}
                    </span> {defaltText.itens} <span className='boldText'>
                        "{campoPesquisa}"
                    </span>
                </p> : null}
            {olt.isSearch === true && campoPesquisa !== '' ? <div className='divisorLine'></div> : null}
        </div>
    );
};
