import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import ConteudoServicoModal from './conteudoServicoModal';

import { adicionarServico, atualizarServico, fecharModalServico } from '../redux/ServicoActions';
import { fecharModalCpe, fecharModalVincServico } from '../../cpe/redux/CpeActions';

const CriarEditar = () => {
  const dispatch = useDispatch();

  const locales = useSelector(state => state.layoutState.linguagem);
  const defaltText = require(`../nls/${locales}.json`);

  const servico = useSelector(state => state.servicosState.servico);

  const statusModal = useSelector(state => state.servicosState.statusModal);
  const paginacao = useSelector((state) => state.servicosState.paginacao);
  const cpeState = useSelector((state) => state.cpesState.statusModal);

  const title = servico.id ? defaltText.tituloModalEditar : defaltText.tituloModalNovo;
  const action = servico.idOlt ? atualizarServico : adicionarServico;
  const assinanteId = window.location.pathname.split("/")[3];

  return (
    <BasicModal
      open={statusModal}
      close={() => (cpeState.includes('addService') ? dispatch(fecharModalVincServico()) : dispatch(fecharModalServico()))}
      containerModal={{
        title,
        size: 'medium',
        content: <ConteudoServicoModal />,
        closeText: defaltText.btn.fechar,
        action: {
          btnId: "btnSalvar",
          acao: () => [dispatch(action({ ...servico, idAssinante: assinanteId }, 0, cpeState))],
          texto: defaltText.btn.salvar,
          class: 'success'
        }
      }}
    />
  );
};

export default CriarEditar;
