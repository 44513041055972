import {
    ATUALIZAR_CAMPO_PESQUISA_COLABORADOR,
    LIMPAR_CAMPO_PESQUISA_COLABORADOR,
    ATUALIZAR_CONTEUDO_COLABORADOR_MODAL,
    COLABORADORES_CARREGADOS_COM_ERRO,
    COLABORADORES_CARREGADOS_COM_SUCESSO,
    CARREGANDO_COLABORADORES,
    ADICIONANDO_COLABORADOR,
    COLABORADOR_NAO_ADICIONADO,
    COLABORADOR_ADICIONADO_COM_SUCESSO,
    ATUALIZANDO_COLABORADOR,
    COLABORADOR_NAO_ATUALIZADO,
    COLABORADOR_ATUALIZADO_COM_SUCESSO,
    DELETANDO_COLABORADOR,
    COLABORADOR_NAO_DELETADO,
    COLABORADOR_DELETADO_COM_SUCESSO,
    SET_TIPO_MODAL_COLABORADOR,
    SET_COLABORADOR,
    SET_COLABORADOR_INICIAL,
    RESET_COLLABORATOR_STATE,
    HANDLE_IS_LOADING_COLLABORATORS_STATUS,
    CLEAR_ADICIONAR_COLABORADOR_FIELD,
    IS_SEARCH
} from './ColaboradorActions';

const colaboradorInicial = {
    //nome: '',
    email: '',
    permissaoAdm: false,
    perfil: {}
};
const paginacaoDefault = {
    totalPages: 0,
    totalElements: 0,
    pageNumber: 0,
};
const initialState = {
    campoPesquisa: '',
    statusModal: '',
    isLoading: false,
    colaboradores: [],
    paginacao: paginacaoDefault,
    colaborador: colaboradorInicial,
    isSearch: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CARREGANDO_COLABORADORES:
            return { ...state };
        case COLABORADORES_CARREGADOS_COM_ERRO:
            return { ...state };
        case COLABORADORES_CARREGADOS_COM_SUCESSO:
            return {
                ...state,
                colaboradores: payload.content ? payload.content : payload,
                paginacao: {
                    totalPages: payload.totalPages,
                    totalElements: payload.totalElements,
                    pageNumber: payload.number,
                },
                isLoading: false
            };

        case ADICIONANDO_COLABORADOR:
            return { ...state };
        case COLABORADOR_NAO_ADICIONADO:
            return { ...state };
        case COLABORADOR_ADICIONADO_COM_SUCESSO:
            return { ...state, colaboradores: [...state.colaboradores, payload] };

        case ATUALIZANDO_COLABORADOR:
            return { ...state };
        case COLABORADOR_NAO_ATUALIZADO:
            return { ...state };
        case COLABORADOR_ATUALIZADO_COM_SUCESSO:
            return {
                ...state,
                colaboradores: state.colaboradores.map(colaborador => (colaborador.id === payload.id ? payload : colaborador))
            };

        case DELETANDO_COLABORADOR:
            return { ...state };
        case COLABORADOR_NAO_DELETADO:
            return { ...state };
        case COLABORADOR_DELETADO_COM_SUCESSO:
            return {
                ...state,
                colaboradores: state.colaboradores.filter(colaborador => colaborador.id !== payload)
            };
        case SET_TIPO_MODAL_COLABORADOR:
            if (payload === '' && state["statusModal"].length === 1) {
                return { ...state, statusModal: [] };
            }
            else if (payload === '') {
                return {
                    ...state,
                    statusModal: state.statusModal.filter((value) => value !== state.statusModal[state.statusModal.length - 1])
                };
            }
            else {
                return { ...state, statusModal: [...state.statusModal, payload] };
            };

        case SET_COLABORADOR:
            return { ...state, colaborador: payload };

        case SET_COLABORADOR_INICIAL:
            return { ...state, colaborador: colaboradorInicial };

        case ATUALIZAR_CAMPO_PESQUISA_COLABORADOR:
            return { ...state, campoPesquisa: payload };

        case LIMPAR_CAMPO_PESQUISA_COLABORADOR:
            return {
                ...state,
                campoPesquisa: '',
                isSearch: false
            };

        case ATUALIZAR_CONTEUDO_COLABORADOR_MODAL:
            var permission = state.colaborador.permissaoAdm;
            if (payload.target.name === 'permissaoAdm') {
                permission = payload.target.value === 'administrador' ? true : false
            }

            return {
                ...state,
                colaborador: {
                    ...state.colaborador,
                    [payload.target.name]: payload.target.type === 'checkbox' ? payload.target.checked : payload.target.value,
                    ['permissaoAdm']: permission,
                }
            };
        case RESET_COLLABORATOR_STATE:
            return initialState;
        case HANDLE_IS_LOADING_COLLABORATORS_STATUS:
            return {
                ...state,
                isLoading: payload
            };
        case CLEAR_ADICIONAR_COLABORADOR_FIELD:
            return {
                ...state,
                colaborador: {
                    ...state.colaborador,
                    [payload.currentTarget.name]: ""
                }
            };
        case IS_SEARCH:
            return {
                ...state,
                isSearch: state.campoPesquisa !== "" ? true : false
            };
        default:
            return state;
    }
};
