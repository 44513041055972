import React from 'react';

export function IcoGlobal(props) {
  return (
    <svg
      className="icon"
      viewBox="0 0 512 512"
      style={props.style ? props.style : {}}
    >
      <title>{props.title ? props.title : null}</title>
      <path d="m354.728 272h-197.456c-2.806 0-5.073 2.312-5.001 5.117.597 23.146 2.165 45.257 4.55 66.164.343 3.008 3.248 5.04 6.182 4.293 30.188-7.691 61.298-11.574 92.997-11.574s62.809 3.883 92.997 11.574c2.933.747 5.839-1.285 6.182-4.293 2.385-20.908 3.953-43.019 4.55-66.164.072-2.805-2.195-5.117-5.001-5.117z" />
      <path d="m355.179 168.718c-.343-3.008-3.248-5.04-6.182-4.293-30.188 7.692-61.298 11.575-92.997 11.575s-62.809-3.883-92.997-11.574c-2.933-.747-5.839 1.285-6.182 4.293-2.385 20.908-3.953 43.019-4.55 66.164-.072 2.806 2.194 5.117 5.001 5.117h197.455c2.806 0 5.073-2.312 5.001-5.117-.596-23.146-2.165-45.257-4.549-66.165z" />
      <path d="m348.998 126.52c-8.96-49.575-22.693-90.232-38.543-118.964-.706-1.28-1.944-2.183-3.377-2.472-16.635-3.352-33.722-5.084-51.078-5.084s-34.443 1.732-51.078 5.085c-1.433.289-2.671 1.192-3.377 2.472-15.85 28.731-29.583 69.388-38.543 118.964-.459 2.538 1.102 5.024 3.592 5.695 28.52 7.682 58.493 11.784 89.406 11.784s60.886-4.102 89.406-11.785c2.49-.67 4.051-3.157 3.592-5.695z" />
      <path d="m362.409 23.033c-4.074-1.863-8.319 2.205-6.708 6.385.028.074.057.148.085.222 9.732 25.304 17.638 54.031 23.554 85.098.591 3.105 3.897 4.874 6.821 3.674 19.682-8.077 38.453-17.932 56.118-29.356 2.616-1.691 3.086-5.338.964-7.618-2.032-2.183-4.105-4.337-6.225-6.457-21.951-21.953-47.194-39.414-74.609-51.948z" />
      <path d="m396.732 240h109.382c2.932 0 5.229-2.511 4.988-5.433-3.535-42.919-17.659-83.668-41.112-119.197-1.534-2.325-4.65-2.954-6.974-1.419-19.107 12.623-39.361 23.488-60.655 32.494-4.368 1.848-8.762 3.607-13.18 5.282-2.168.822-3.493 3.003-3.204 5.304 3.148 25.099 5.098 51.281 5.764 78.082.067 2.716 2.275 4.887 4.991 4.887z" />
      <path d="m163.002 385.48c8.96 49.575 22.693 90.232 38.543 118.964.706 1.28 1.944 2.183 3.377 2.472 16.635 3.352 33.722 5.084 51.078 5.084s34.443-1.732 51.078-5.085c1.433-.289 2.671-1.192 3.377-2.472 15.85-28.731 29.583-69.388 38.543-118.964.459-2.538-1.102-5.024-3.592-5.695-28.52-7.682-58.493-11.784-89.406-11.784s-60.886 4.102-89.406 11.785c-2.49.67-4.051 3.157-3.592 5.695z" />
      <path d="m109.639 365.555c4.368-1.848 8.762-3.607 13.18-5.282 2.168-.822 3.493-3.003 3.204-5.304-3.148-25.099-5.098-51.281-5.764-78.082-.068-2.715-2.275-4.886-4.991-4.886h-109.382c-2.932 0-5.229 2.511-4.988 5.433 3.535 42.919 17.66 83.668 41.112 119.197 1.534 2.324 4.65 2.954 6.974 1.419 19.107-12.624 39.361-23.489 60.655-32.495z" />
      <path d="m442.28 422.945c-17.665-11.424-36.436-21.28-56.118-29.356-2.924-1.2-6.23.569-6.821 3.674-5.916 31.067-13.821 59.794-23.554 85.098-.028.074-.057.148-.085.222-1.611 4.18 2.634 8.248 6.708 6.385 27.415-12.534 52.658-29.995 74.61-51.948 2.12-2.12 4.193-4.274 6.225-6.457 2.121-2.28 1.65-5.927-.965-7.618z" />
      <path d="m391.741 276.886c-.666 26.801-2.617 52.984-5.764 78.082-.289 2.301 1.036 4.482 3.204 5.304 4.418 1.675 8.812 3.434 13.18 5.282 21.294 9.007 41.548 19.872 60.655 32.494 2.324 1.535 5.439.905 6.974-1.419 23.452-35.528 37.577-76.277 41.112-119.197.241-2.922-2.056-5.433-4.988-5.433h-109.382c-2.716.001-4.924 2.172-4.991 4.887z" />
      <path d="m69.72 89.055c17.665 11.424 36.436 21.28 56.118 29.356 2.924 1.2 6.23-.569 6.821-3.674 5.916-31.067 13.821-59.794 23.554-85.098.028-.074.057-.148.085-.222 1.611-4.18-2.634-8.248-6.708-6.385-27.414 12.535-52.657 29.996-74.61 51.948-2.12 2.12-4.193 4.274-6.225 6.457-2.121 2.28-1.65 5.927.965 7.618z" />
      <path d="m120.152 240c.541-28.505 2.533-56.347 5.871-82.969.289-2.301-1.036-4.482-3.204-5.304-4.418-1.675-8.812-3.434-13.18-5.282-21.294-9.007-41.548-19.872-60.655-32.494-2.324-1.535-5.439-.905-6.974 1.419-23.452 35.529-37.577 76.278-41.112 119.197-.241 2.922 2.056 5.433 4.988 5.433z" />
      <path d="m149.591 488.967c4.074 1.863 8.319-2.205 6.708-6.385-.028-.074-.057-.148-.085-.222-9.732-25.304-17.638-54.031-23.554-85.098-.591-3.105-3.897-4.874-6.821-3.674-19.682 8.077-38.453 17.932-56.118 29.356-2.616 1.691-3.086 5.338-.964 7.618 2.032 2.183 4.105 4.337 6.225 6.457 21.951 21.953 47.194 39.414 74.609 51.948z" />
    </svg>
  );
}
