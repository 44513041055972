import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './editarInterface.css';
import Button from '../../../../components/button/Button';
import { IcoRemoveLine, IcoEditar } from '../../../../components/icones';
import List from '../../../../components/list/List';
import { abrirModalDeletarInterface, abrirModalEditarInterface, setSelectedVlan } from '../../redux/OltActions';
import { getPagePermissions } from '../../../../utils/utils';
import SigCloudLoader from '../../../../layouts/components/Loader/SigCloudLoader';
import Table from '../../../../layouts/components/Table/Table';



const EditarInterfaceConteudo = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`../../nls/${locales}.json`);
    const vlans = useSelector((state) => state.oltsState.vlans);
    const olt = useSelector((state) => state.oltsState.olt);
    const statusModal = useSelector((state) => state.oltsState.statusModal);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const isLoadingVlans = useSelector((state) => state.oltsState.isLoadingVlans);

    function getLabels() {
        const labels = [
            { text: defaltText.informacoes.vlan, field: "numeroVlan" },
            { text: defaltText.informacoes.descricao, field: "descricao" },
            { text: defaltText.informacoes.tipo, field: "type" },
            { text: defaltText.informacoes.status, field: "status" },
            { text: defaltText.inputs.acoes, field: "actions" }
        ];
        if(olt.modelo !== "OLT_8820I"){
            const obj = labels.findIndex((obj) => obj.field === "type");
            labels.splice(obj, 1);
        }
      
        return labels;
      }
    const interfaceListVlans = [
        {
            colunm: 'numeroVlan',
            text: defaltText.informacoes.vlan,
            className: 'text-center',
        },
        {
            colunm: 'descricao',
            text: defaltText.informacoes.descricao,
            className: 'text-center',
        },
        {
            colunm: 'status',
            text: defaltText.informacoes.status,
            className: 'text-center'
        },
        {
            colunm: 'actions',
            text: defaltText.inputs.acoes,
            className: 'text-center',
        }
    ];

    const labels = getLabels()
    const listActions = (val) => {
        switch (val.item) {
            case 'actions':
                return (
                    <div>                        
                        <Button
                            hasAccess={permissions ? getPagePermissions(permissions, "vlans", "delete") : false}
                            id="btnEditar"
                            titulo={defaltText.btn.editar}
                            action={() => [dispatch(abrirModalEditarInterface()), dispatch(setSelectedVlan(val.itens))]}
                            children={<IcoEditar width="20" height="30" title={defaltText.btn.editar} />}
                            color=""
                            type="btn circle"
                            disabled={(val.itens.status === "DELETANDO") ? true : false}
                                
                        />
                        <Button
                            hasAccess={permissions ? getPagePermissions(permissions, "vlans", "delete") : false}
                            id="btnRemover"
                            titulo={defaltText.btn.remover}
                            action={() => [dispatch(abrirModalDeletarInterface()), /* dispatch(setVlanForDelete(val.itens.id)) */, dispatch(setSelectedVlan(val.itens))]}
                            children={<IcoRemoveLine title={defaltText.btn.remover} />}
                            color=""
                            type="btn circle"
                            disabled={(val.itens.status === "DELETANDO") ? true : false}
                                
                        />
                  </div>
                );

            case 'status':
                return (
                    <>
                        {val.itens.status}
                    </>
                );
            default:
                return;
        }
    };

    return (
        <>
            <SigCloudLoader visible={isLoadingVlans} />
            <div className="box-interface">
                <div className="">
                    {vlans.utVlans.filter((vlan) => vlan.status !== "DELETADO").length > 0 && (
                        <>
                            <h5 className="vlan-title">Untagged Vlans</h5>
                            <Table
                                itens={vlans.utVlans.filter((vlan, index) => vlan.status !== "DELETADO" && index === vlans.utVlans?.length - 1)}
                                labels={labels}
                                listActions={(val) => listActions(val)}
                            />
                        </>
                    )}
                    {vlans.tagVlans.filter((vlan) => vlan.status !== "DELETADO").length > 0 && (
                        <>
                            <h5 className="vlan-title">Tagged Vlans</h5>
                            <Table
                                itens={vlans.tagVlans?.sort((a,b)=> (parseInt(a.numeroVlan) - parseInt(b.numeroVlan)))
                                    .filter((vlan) => vlan.status !== "DELETADO")}
                                labels={labels}
                                listActions={(val) => listActions(val)}
                            />
                        </>
                    )}

                    {!isLoadingVlans && (vlans.tagVlans.filter((vlan) => vlan.status !== "DELETADO").length === 0 && vlans.utVlans.filter((vlan) => vlan.status !== "DELETADO").length === 0) && <p>Não existem vlans para esta interface</p>}
                </div>
            </div>

        </>
    );
};

export default EditarInterfaceConteudo;
