import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconLocation } from '../../../components/icones';
import Tooltip from '../../../components/tooltip/Tooltip';
import '../ContainerGeral/containerGeral.css';

const ContainerGeral = props => {

    const user = useSelector((state => state.authentication.user));
    const localLogado = useSelector((state => state.authentication.user.aplicacaoLogado));
    const sidebar = useSelector(state => state.layoutState);
    const defaultText = require(`../../nls/${sidebar.linguagem}.json`);
    const [statusMsg, setStatusMsg] = useState(localLogado.statusSincronizacao ? defaultText.statusApplicationTrue : defaultText.statusApplicationFalse)
    const [aplicacaoMensagem, setAplicacaoMensagem] = useState(localLogado.statusSincronizacao ? defaultText.aplicacaoConectada : defaultText.aplicacaoDesconectada)
    const [statusTooltip, setStatusTooltip] = useState(true);
    const [posicaoMessage, setPosicaoMessage] = useState({ top: 0, left: 0, right: 0, mensagem: null })
    useEffect(() => {
        setStatusMsg(localLogado.statusSincronizacao ? defaultText.statusApplicationTrue : defaultText.statusApplicationFalse)
        setAplicacaoMensagem(localLogado.statusSincronizacao ? defaultText.aplicacaoConectada : defaultText.aplicacaoDesconectada)
        localStorage.setItem('user', JSON.stringify(user));
    }, [localLogado.statusSincronizacao, defaultText]);

    const displayStatus = () => {
        if(!localLogado.statusSincronizacao){
            return (
                <div className='status-adapter'  style={{backgroundColor: "var(--error)"}}>
                    <span className='status-message'>{statusMsg}</span>
                </div>
            )
        }
    }

    function openTooltip(e, mensagem) {
        const pos = e.currentTarget.getBoundingClientRect()
        mensagem ? setStatusTooltip(true) : setStatusTooltip(false);
        setPosicaoMessage({ top: pos?.top - 10, left:( e.clientX - pos?.left) + 200, right: pos?.right, mensagem: mensagem, width: '150px' })
        console.log(statusTooltip)
    }
    function closeTooltip() {
        setStatusTooltip(false);
    }
    return (
        <>
        <Tooltip caso={1} estado={statusTooltip} posicaoMessage={posicaoMessage} />
        <div className='containerGeral'>
            <div className='align-left-side'>
                <div 
                onMouseOver={e => openTooltip(e, aplicacaoMensagem)}
                onMouseOut={_ => closeTooltip()} 
                className='containerLocal' style={{
                    cursor: 'help',
                    borderLeft: localLogado.statusSincronizacao ? "7px solid var(--success)" : " 7px solid var(--error)",
                }}>
                    <IconLocation fill={localLogado.statusSincronizacao ? "var(--success)" : " var(--error)"}/>
                    {localLogado && <p style={{cursor: 'help'}}>{localLogado.nomeLocal}</p>}
                </div>
                {displayStatus()}
            </div>
            {props.children}
        </div>
    </>
    );
};

export default ContainerGeral;