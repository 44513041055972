import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleIsLoadingNotificationsStatus, listarNotificacoes, listarTodasNotificacoes, resetNotificationsState } from './redux/NotificacoesActions';
import './notificacoes.css';
//import Language from '../../components/translate/translate'
import BarraDePesquisa from './barraDePesquisa';
import Paginate from '../../components/pagination/Pagination';

import { getNotificacaoCardActions } from '../../components/card/getCardButtons';
import { CardList } from '../../components/CardList/CardList';
import TitleContainer from '../../layouts/components/TitleContainer/TitleContainer';
import SigCloudLoader from '../../layouts/components/Loader/SigCloudLoader';
import Row from '../../components/row/row';
import NotificationCard from '../../layouts/components/cards/NotificationCard';

const Notificacoes = () => {
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);
    const statusModal = useSelector(state => state.notificacoesState.statusModal);
    const notificacoesState = useSelector(state => state.notificacoesState);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const isLoading = useSelector((state) => state.notificacoesState.isLoading);

    useEffect(() => {
        dispatch(listarNotificacoes());

        return () => {
            dispatch(handleIsLoadingNotificationsStatus(false));
            dispatch(resetNotificationsState());
        };
    }, [dispatch]);

    const atualizarLista = val => {
        dispatch(listarNotificacoes());
    };

    //Daqui pra baixo código do card novo
    const labels = [
        { field: "nome", text: defaltText.inputs.nome }, 
        { field: "tipo", text: defaltText.inputs.tipo }, 
        { field: "descricao", text: defaltText.inputs.descricao },
        { field: "dataCriacao", text: defaltText.inputs.data },
        // { field: "visualizado", text: defaltText.inputs.visualizado }
        //  {/* <p>{dataTimeZoneZero(notification['dataCriacao'])}</p> */}
        //  { field: "data", text: dataTimeZoneZero(notification['dataCriacao'])}
    ];
    const buttons = ["btnInfo", "btnRemover"];

    return (<>
        <SigCloudLoader visible={isLoading} />
        <div className="box-notificacoes">
            <TitleContainer
                label={defaltText.title}
            />

            <BarraDePesquisa />

            <Row>
                {notificacoesState.notificacoes.map((item) => (
                    <div className="col-md-3 col-sm-6">
                        <NotificationCard
                            item={item}
                            labels={labels}
                            markerColor={item.descricao.includes('Erro') ? "error" : "updating"}
                        />
                    </div>
                ))}
            </Row>

            {/* <Paginate data={notificacoesState.paginacao} action={val => atualizarLista(val)} /> */}

        </div>
    </>

    );
};

export default Notificacoes;
