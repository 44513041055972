import React from 'react';

export function IcoEditar(props) {
    return (
        <svg
            id="icon-editar"
            width={props.width ? props.width : "34" && props.heigth ? props.heigth : "36"}
            viewBox="0 0 50 50"
            style={props.style ? props.style : {}}
        >
            <title>{props.title ? props.title : null}</title>
            <path d="M40.5 0c4.142 0 7.5 3.358 7.5 7.5 0 1.688-0.558 3.246-1.5 4.5l-3 3-10.5-10.5 3-3c1.254-0.942 2.811-1.5 4.5-1.5zM3 34.5l-3 13.5 13.5-3 27.75-27.75-10.5-10.5-27.75 27.75zM33.543 17.043l-21 21-2.585-2.585 21-21 2.585 2.585z" />
        </svg>
    );
}
