import React from "react";
import { useDispatch, useSelector } from "react-redux";

import BasicModal from "../../../components/modal/BasicModal";

import InformacoesConteudo from "./informacoesConteudo";

import { fecharModalCpe, reiniciarCpe, atualizarInfoCpe } from "../redux/CpeActions";
import { getPagePermissions } from "../../../utils/utils";

const Informacoes = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const statusModal = useSelector(state => state.cpesState.statusModal);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);

    const cpe = useSelector(state => state.cpesState.cpe);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalCpe())}
            containerModal={{
                title: defaltText.tituloModalInformacoes,
                size: "medium",
                content: (
                    <>
                        <InformacoesConteudo />
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    hasAccess: permissions ? getPagePermissions(permissions, "cpes", "update") : false,
                    btnId: "btnReiniciarCPE",
                    acao: () => dispatch(reiniciarCpe(cpe)),
                    texto: defaltText.btn.reiniciar,
                    disabled: (cpe.status === "ERRO" || cpe.status === "ONLINE") ? false : true,
                    class: 'success'
                },

                //Removmendo até o campo de de CTO estar funcionando
                aditionalAction: {
                    hasAccess: permissions ? getPagePermissions(permissions, "cpes", "update") : false,
                    btnId: "btnAtualizarInfoCPE",
                    //acao: () => dispatch(action(olt)),
                    acao: () => dispatch(atualizarInfoCpe(cpe)),
                    texto: defaltText.btn.atualizarInfo,
                    class: 'success'
                }

            }}
        />
    );
};

export default Informacoes;
