import React from 'react';
import './radioButton.css';

const RadioButton = ({ name, text, options, selected, changeInput, validate, defaultChecked, disabled, style }) => {
    return (
        <div className={`custom-control-radio ${validate && validate.erro && "show-error-hint"}`}>
            <label className="custom-label">{text}</label>
            {options.map(option => (
                <div style={style} key={option.id} className='radio-inline'>
                    <input
                        type='radio'
                        value={option.id}
                        defaultValue={defaultChecked}
                        defaultChecked={defaultChecked}
                        checked={selected === option.id}
                        id={`${name}-${option.id}`}
                        name={name}
                        onChange={changeInput}
                        disabled={disabled}
                    />
                    <label htmlFor={`${name}-${option.id}`}>{option.text}</label>
                </div>
            ))}
            <div className={`invalid-feedback ${validate && validate.erro ? "show-feedback" : "hide-feedback"}`}>{validate && validate.erro ? validate.erro : ''}</div>
        </div>
    );
};

export default RadioButton;
