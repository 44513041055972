import React from 'react';
import { resetarCamposAction, validarAction, validarCamposAction } from '../../../validation/validationActions';

import axios from 'axios';
import { toast } from 'react-toastify';
import { checkResponseErrorType } from '../../../utils/utils';
import { CorrectIcon } from '../../../layouts/components/icons';

const idiomaText = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userLocales = user.preferencia.linguagem;
    const locales = userLocales ? (userLocales === 'EN_US' ? 'en-US' : 'pt-BR') : null;
    const defaltText = require(`../nls/${locales ? locales : 'pt-BR'}.json`);
    return defaltText.mensagem;
};

export const ATUALIZAR_CONTEUDO_COLABORADOR_MODAL = 'ATUALIZAR_CONTEUDO_COLABORADOR_MODAL';
export const atualizarConteudoColaboradorModal = evento => ({
    type: ATUALIZAR_CONTEUDO_COLABORADOR_MODAL,
    payload: evento
});

export const SET_TIPO_MODAL_COLABORADOR = 'SET_TIPO_MODAL_COLABORADOR';
const setTipoModalColaborador = tipo => ({
    type: SET_TIPO_MODAL_COLABORADOR,
    payload: tipo
});

export const SET_COLABORADOR = 'SET_COLABORADOR';
const setColaborador = colaborador => ({
    type: SET_COLABORADOR,
    payload: colaborador
});

export const SET_COLABORADOR_INICIAL = 'SET_COLABORADOR_INICIAL';
const setColaboradorInicial = () => ({
    type: SET_COLABORADOR_INICIAL
});

export const ABRIR_MODAL_CRIAR_COLABORADOR = 'ABRIR_MODAL_CRIAR_COLABORADOR';
export const abrirModalCriarColaborador = () => [
    resetarCamposAction(),
    setColaboradorInicial(),
    setTipoModalColaborador('criando')
];

export const ABRIR_MODAL_EDITAR_COLABORADOR = 'ABRIR_MODAL_EDITAR_COLABORADOR';
export const abrirModalEditarColaborador = colaborador => [
    resetarCamposAction(),
    setColaborador(colaborador),
    setTipoModalColaborador('editando')
];

export const ABRIR_MODAL_DELETAR_COLABORADOR = 'ABRIR_MODAL_DELETAR_COLABORADOR';
export const abrirModalDeletarColaborador = colaborador => [
    setColaborador(colaborador),
    setTipoModalColaborador('deletando')
];

export const ABRIR_MODAL_INFORMACAO = 'ABRIR_MODAL_INFORMACAO';
export const abrirModalInformacaoColaborador = colaborador => [
    setColaborador(colaborador),
    setTipoModalColaborador('informacoes')
];

export const FECHAR_MODAL_COLABORADOR = 'FECHAR_MODAL_COLABORADOR';
export const fecharModalColaborador = () => [setColaboradorInicial(), setTipoModalColaborador('')];

export const LIMPAR_CAMPO_PESQUISA_COLABORADOR = 'LIMPAR_CAMPO_PESQUISA_COLABORADOR';
export const limparCampoPesquisa = () => [
    {
        type: LIMPAR_CAMPO_PESQUISA_COLABORADOR
    },
    /* listarColaboradores() */
];

export const ATUALIZAR_CAMPO_PESQUISA_COLABORADOR = 'ATUALIZAR_CAMPO_PESQUISA_COLABORADOR';
export const atualizarCampoPesquisa = e => ({
    type: ATUALIZAR_CAMPO_PESQUISA_COLABORADOR,
    payload: e.target.value
});

export const CARREGANDO_COLABORADORES = 'CARREGANDO_COLABORADORES';
const carregandoColaboradores = () => ({
    type: CARREGANDO_COLABORADORES
});

export const COLABORADORES_CARREGADOS_COM_SUCESSO = 'COLABORADORES_CARREGADOS_COM_SUCESSO';
const colaboradoresCarregadosComSuceso = colaboradores => ({
    type: COLABORADORES_CARREGADOS_COM_SUCESSO,
    payload: colaboradores
});

export const COLABORADORES_CARREGADOS_COM_ERRO = 'COLABORADORES_CARREGADOS_COM_ERRO';
const colaboradoresCarregadosComErro = erro => ({
    type: COLABORADORES_CARREGADOS_COM_ERRO,
    payload: erro
});

export const listarColaboradores = (e) => {
    const params = {
        pagina: `pagina=${e && e.pagina ? e.pagina : '0'}`,
        pesquisaGeral: `&pesquisaGeral=${e && e.pesquisaGeral || ""}`,

        /* qtdRegistros: `&qtdRegistros=${e && e.qtdRegistros ? e.qtdRegistros : "12"}` */
    };
    return dispatch => {
        dispatch(handleIsLoadingCollabortorsStatus(true));
        dispatch(carregandoColaboradores());
        axios
            .get(`/api/colaborador/pesquisarColaboradores?${params.pagina + params.pesquisaGeral}`)
            .then(response => dispatch(colaboradoresCarregadosComSuceso(response.data)))
            .catch(error => {
                dispatch(colaboradoresCarregadosComErro(error));
                checkResponseErrorType(error, idiomaText().listarErro);
                dispatch(handleIsLoadingCollabortorsStatus(false));
            });
    };
};

export const ADICIONANDO_COLABORADOR = 'ADICIONANDO_COLABORADOR';
const adicionandoColaborador = () => ({
    type: ADICIONANDO_COLABORADOR
});

export const COLABORADOR_ADICIONADO_COM_SUCESSO = 'COLABORADOR_ADICIONADO_COM_SUCESSO';
const colaboradorAdicionadoComSucesso = colaborador => ({
    type: COLABORADOR_ADICIONADO_COM_SUCESSO,
    payload: colaborador
});

export const COLABORADOR_NAO_ADICIONADO = 'COLABORADOR_NAO_ADICIONADO';
const colaboradorNaoAdicionado = erro => ({
    type: COLABORADOR_NAO_ADICIONADO,
    payload: erro
});

const colaboradorRequired = {
    nome: ''
};

export const adicionarColaborador = colaborador => {
    const colaboradorJson = colaborador;
    const colaboradorVal = {
        formRequired: colaboradorRequired,
        formValues: colaboradorJson
    };
    return dispatch => {
        const mensagemDeErro = validarCamposAction(colaboradorVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);
        dispatch(fecharModalColaborador());
        dispatch(adicionandoColaborador());
        axios
            .post('/api/colaborador/adicionarColaborador', colaboradorJson)
            .then(response => {
                dispatch(colaboradorAdicionadoComSucesso(response.data));
                toast.success((idiomaText().adicionarSucccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(colaboradorNaoAdicionado(erro));
                checkResponseErrorType(erro, idiomaText().adicionarErro);
            });
    };
};

export const ATUALIZANDO_COLABORADOR = 'ATUALIZANDO_COLABORADOR';
const atualizandoColaborador = () => ({
    type: ATUALIZANDO_COLABORADOR
});

export const COLABORADOR_ATUALIZADO_COM_SUCESSO = 'COLABORADOR_ATUALIZADO_COM_SUCESSO';
const colaboradorAtualizadoComSucesso = colaborador => ({
    type: COLABORADOR_ATUALIZADO_COM_SUCESSO,
    payload: colaborador
});

export const COLABORADOR_NAO_ATUALIZADO = 'COLABORADOR_NAO_ATUALIZADO';
const colaboradorNaoAtualizado = erro => ({
    type: COLABORADOR_NAO_ATUALIZADO,
    payload: erro
});

export const atualizarColaborador = colaborador => {
    const colaboradorJson = colaborador;
    const colaboradorVal = {
        formRequired: colaboradorRequired,
        formValues: colaboradorJson
    };

    return dispatch => {
        const mensagemDeErro = validarCamposAction(colaboradorVal);
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro);

        dispatch(atualizandoColaborador());
        axios
            .put('/api/colaborador/editarColaborador', colaboradorJson)
            .then(response => {
                dispatch(colaboradorAtualizadoComSucesso(response.data));
                toast.success((idiomaText().atualizarSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
                dispatch(fecharModalColaborador());
            })
            .catch(erro => {
                dispatch(colaboradorNaoAtualizado(erro));
                checkResponseErrorType(erro, idiomaText().atualizarErro);
            });
    };
};

export const DELETANDO_COLABORADOR = 'DELETANDO_COLABORADOR';
const deletandoColaborador = () => ({
    type: DELETANDO_COLABORADOR
});

export const COLABORADOR_DELETADO_COM_SUCESSO = 'COLABORADOR_DELETADO_COM_SUCESSO';
const colaboradorDeletadoComSucesso = colaborador => ({
    type: COLABORADOR_DELETADO_COM_SUCESSO,
    payload: colaborador
});

export const COLABORADOR_NAO_DELETADO = 'COLABORADOR_NAO_DELETADO';
const colaboradorNaoDeletado = erro => ({
    type: COLABORADOR_NAO_DELETADO,
    payload: erro
});

export const deletarColaborador = colaborador => {
    console.log(colaborador.idPerfil);
    return dispatch => {
        dispatch(fecharModalColaborador());
        dispatch(deletandoColaborador());
        axios
            .delete(`/api/colaborador/removerColaborador/${colaborador.idPerfil}`)
            .then(() => {
                dispatch(listarColaboradores());
                dispatch(colaboradorDeletadoComSucesso(colaborador.id));
                toast.success((idiomaText().deletarSucccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch(erro => {
                dispatch(colaboradorNaoDeletado(erro));
                checkResponseErrorType(erro, idiomaText().deletarErro);
            });
    };
};

export const ABRIR_MODAL_ADICIONAR_COLABORADOR = 'ABRIR_MODAL_ADICIONAR_COLABORADOR';
export const abrirModalAdicionarColaborador = () => [
    resetarCamposAction(),
    setColaboradorInicial(),
    setTipoModalColaborador('adicionando')
];

const requiredCollaborator = {
    email: "",
    permissaoAdm: false
    //perfil: {}
};

export const addCollaborator = (collaborator) => {
    const collaboratorValues = {
        formRequired: requiredCollaborator,
        formValues: collaborator
    };
    return (dispatch) => {
        const errorMessage = validarCamposAction(collaboratorValues);
        if (validarAction(errorMessage)) return dispatch(errorMessage);
        axios.post(`api/colaborador/adicionar-colaborador?permissao=${collaborator.permissaoAdm}`, collaborator)
            .then((response) => {
                dispatch(fecharModalColaborador());
                toast.success((idiomaText().adicionarColaboradorSuccess), {
                    icon: <div className='divIconToastSuccess'><CorrectIcon /></div>
                });
            })
            .catch((error) => {
                dispatch(fecharModalColaborador());
                checkResponseErrorType(error, idiomaText().adicionarColaboradorError);
            });
    };
};

export const RESET_COLLABORATOR_STATE = "RESET_COLLABORATOR_STATE";
export const resetCollabortorState = () => ({
    type: RESET_COLLABORATOR_STATE,
});

export const HANDLE_IS_LOADING_COLLABORATORS_STATUS = "HANDLE_IS_LOADING_COLLABORATORS_STATUS";
export const handleIsLoadingCollabortorsStatus = (status) => ({
    type: HANDLE_IS_LOADING_COLLABORATORS_STATUS,
    payload: status
});

export const CLEAR_ADICIONAR_COLABORADOR_FIELD = "CLEAR_ADICIONAR_COLABORADOR_FIELD";
export const clearAdicionarColaboradorField = (e) => ({
    type: CLEAR_ADICIONAR_COLABORADOR_FIELD,
    payload: e
});

export const IS_SEARCH = 'colaborador.IS_SEARCH';
export const isSearch = () => ({
    type: IS_SEARCH
});