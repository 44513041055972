import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BarraPesquisa from './barraDePesquisa';
import AddEditCollaboratorModal from './adicionarColaborador/adicionarColaboradorModal';
import Paginate from '../../components/pagination/Pagination';
import Remover from './remover';
import "./colaboradores.css";
import SigCloudLoader from '../../layouts/components/Loader/SigCloudLoader';

import {
    listarColaboradores,
    deletarColaborador,
    addCollaborator,
    handleIsLoadingCollabortorsStatus,
    resetCollabortorState,
    abrirModalDeletarColaborador
} from './redux/ColaboradorActions';
import TitleContainer from '../../layouts/components/TitleContainer/TitleContainer';
import Table from '../../layouts/components/Table/Table';
import Button from '../../components/button/Button';
import { getPagePermissions } from '../../utils/utils';
import { IcoRemoveLine } from '../../components/icones';

const Colaboradores = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`./nls/${locales}.json`);

    const colaboradores = useSelector(state => state.colaboradoresState.colaboradores);
    const colaborador = useSelector(state => state.colaboradoresState.colaborador);
    const paginacao = useSelector((state) => state.colaboradoresState.paginacao);

    const statusModal = useSelector(state => state.colaboradoresState.statusModal);
    const isLoading = useSelector((state) => state.colaboradoresState.isLoading);
    const permissions = useSelector((state) => state.authentication.user.perfil.telas);
    const user = useSelector(state => state.authentication.user);

    useEffect(() => {
        dispatch(listarColaboradores());

        return () => {
            dispatch(handleIsLoadingCollabortorsStatus(false));
            dispatch(resetCollabortorState());
        };
    }, [dispatch]);

    const labels = [
        { text: defaltText.inputs.nome, field: "nome" },
        { text: defaltText.inputs.email, field: "email" },
        { text: defaltText.inputs.perfil, field: "permissao" },
        { text: defaltText.inputs.acoes, field: "actions" }
    ];

    const atualizarLista = (val) => {
        dispatch(listarColaboradores({ pagina: val }));
    };

    const modalColaborador = (e, i) => {
        switch (e[i]) {
            case 'adicionando':
                return [<AddEditCollaboratorModal action={() => dispatch(addCollaborator(colaborador))} />, modalColaborador(e, i + 1)];
            case 'deletando':
                return [<Remover action={() => dispatch([deletarColaborador(colaborador)])} />];
            default:
                return;
        }
    };

    const listActions = (val) => {
        switch (val.item) {
            case 'actions':
                return (
                    user.aplicacaoLogado.emailProprietario !== val.itens.email?
                        <>
                            <Button
                                hasAccess={getPagePermissions(permissions, "colaboradores", "delete")}
                                id="btnAbrirRemoverColaborador"
                                titulo={defaltText.btn.remover}
                                action={() => dispatch(abrirModalDeletarColaborador(val.itens))}
                                children={<IcoRemoveLine title={defaltText.btn.remover} />}
                                color=""
                                type="btn circle"
                            />
                        </>
                        : null
                );
            default:
                return;
        }
    };

    return (
        <>
            <SigCloudLoader visible={isLoading} />
            <div className='box-colaboradores'>
                <TitleContainer
                    label={defaltText.title}
                />
                <div>
                    <BarraPesquisa />
                    <Table
                        itens={colaboradores}
                        labels={labels}
                        listActions={(val) => listActions(val)}
                    />
                    <Paginate data={paginacao} action={(val) => atualizarLista(val)} />
                </div>
                {/* {statusModal.includes("adicionando") && <AddEditCollaboratorModal action={() => dispatch(addCollaborator(colaborador))} />}
                {statusModal.includes("deletando") ? (
                    <Remover action={() => dispatch([deletarColaborador(colaborador), listarColaboradores()])} />
                ) : null} */}
            </div>
            {modalColaborador(statusModal, 0)}
        </>
    );
};

export default Colaboradores;