import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BasicModal from '../../../components/modal/BasicModal';
import { LockIcon, UserIcon } from '../../../layouts/components/icons';
import Input from '../../../layouts/components/Input/Input';
import InputPassword from '../../../layouts/components/Input/InputPassword';
import { handleOnKeyDown } from '../../../utils/utils';
import { validarCampoAction } from '../../../validation/validationActions';
import { criarLoc, limparCamposNovaLoc, fecharModalNovaLoc, abrirModalConfirmaRemover, atualizarConteudoDeletaLoc, deletarLocalizacao } from '../redux/LocaisActions';

const ConfirmaRemoverLoc = () => {

    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const statusModal = useSelector((state) => state.locaisState.statusModal);
    const localDeletar = useSelector((state) => state.locaisState.localDeletar);
    const locaisCredential = useSelector((state => state.locaisState.credetial));
    const mensagemErro = useSelector(state => state.validacaoState);
    const locais = useSelector(state => state.locaisState.locais);
    const localLogado = useSelector(state => state.locaisState.local);

    const [removerLocalRefs, setRemoverLocalRefs] = useState([
        useRef(),
        useRef(),
    ]);


    const title = defaultText.localizacao.delete;
    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalNovaLoc())}
            containerModal={{
                title,
                size: 'medium',
                content: (
                    <>
                        <p>{defaultText.mensagem.confirmDelete}</p>
                        <p>{defaultText.mensagem.credentials}</p>
                        <Input
                            id="loginEmail"
                            name='email'
                            label={defaultText.inputs.email}
                            changeInput={e => dispatch(atualizarConteudoDeletaLoc(e))}
                            value={locaisCredential.email}
                            validate={{
                                action: e => dispatch(validarCampoAction(e)),
                                erro: mensagemErro.email ? defaultText.mensagem.obrigatorio : ''
                            }}
                            length={{
                                max: '50'
                            }}
                            leftButton={{
                                icon: <UserIcon />
                            }}
                            reference={removerLocalRefs[0]}
                            onKeyDown={(e) => handleOnKeyDown(e, removerLocalRefs[0], removerLocalRefs)}
                        />
                        <InputPassword
                            id="loginSenha"
                            fieldIcon={{
                                icon: <LockIcon />
                            }}
                            name='senha'
                            label={defaultText.inputs.senha}
                            value={locaisCredential.senha}
                            changeInput={e => dispatch(atualizarConteudoDeletaLoc(e))}
                            validate={{
                                action: e => dispatch(validarCampoAction(e)),
                                erro: mensagemErro.senha ? defaultText.mensagem.obrigatorio : ''
                            }}
                            seeText={defaultText.btn.ver}
                            length={{
                                max: '20'
                            }}
                            reference={removerLocalRefs[1]}
                            onKeyDown={(e) => handleOnKeyDown(e, removerLocalRefs[1], removerLocalRefs)}
                        />
                    </>
                ),
                closeText: defaultText.btn.fechar,
                removeConfirmAction: {
                    btnId: "btnDelete",
                    acao: () => dispatch(deletarLocalizacao(locaisCredential, localDeletar, locais, localLogado)),
                    texto: defaultText.btn.deletar,
                    class: 'success'
                }
            }}
        />
    );
};


export default ConfirmaRemoverLoc;