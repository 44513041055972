import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { atualizarConteudoServicoModal, clearServicoField } from '../../redux/ServicoActions';
import { validarCampoAction } from '../../../../validation/validationActions';
import Input from '../../../../layouts/components/Input/Input';
import { handleOnKeyDown } from '../../../../utils/utils';
import { atualizarConteudoCpeModal } from '../../../cpe/redux/CpeActions';
import RadioButton from '../../../../components/form/radioButton/RadioButton';

export default function ConteudoDropdown8820i({ dropdownState }) {
    const dispatch = useDispatch();
    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../../nls/${locales}.json`);
    const cpe = useSelector((state) => state.cpesState.cpe);
    const [configs, setConfigs] = useState([
        { id: "downlink", text: "Downlink" },
        { id: "tls", text: "TLS" }])
    const [isPPPoe, setIsPPPoe] = useState([
        { id: "ativar", text: 'Ativar' },
        { id: "desativar", text: 'Desativar' }])

    return (
        <div name="advancedSettings" className={dropdownState && dropdownState.advancedSettings === true ? "expand-body" : "expand-body hide-expand-body"}>
            {/* <div style={{ padding: '10px' }}>
                <p style={{ paddingBottom: '5px' }}>{defaltText.inputs.pppoeDesativar} </p>
                <RadioButton
                    style={{ marginRight: '37px' }}
                    id="pppoeActive"
                    name='pppoeActive'
                    changeInput={e => { dispatch(atualizarConteudoCpeModal(e)) }}
                    selected={cpe.pppoeActive ? isPPPoe[0].id : isPPPoe[1].id}
                    options={isPPPoe}
                />
            </div> */}

            <div style={{ padding: '10px' }}>
                <RadioButton
                    name={"conexao"}
                    changeInput={(e) => dispatch(atualizarConteudoCpeModal(e))}
                    selected={cpe.conexao}
                    options={configs}
                />
            </div>

        </div>
    );
}