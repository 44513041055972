import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../components/modal/BasicModal';
import React from 'react';
import { fecharModal, sidebarRight } from './redux/LayoutActions';
import history from '../history';

export default () => {
    const dispatch = useDispatch();
    const layoutState = useSelector(state => state.layoutState);
    const defaltText = require(`./nls/${layoutState.linguagem}.json`);

    const sairConfirmacao = () => {
        dispatch(fecharModal());
        history.push(`/login`);
        dispatch(sidebarRight(false));
    };

    return (
        <BasicModal
            open={layoutState.statusModal}
            close={() => dispatch(fecharModal())}
            containerModal={{
                title: defaltText.tituloModalSair,
                size: 'small',
                content: (
                    <>
                        <p>{defaltText.sairConfirmacao}</p>
                    </>
                ),
                closeText: defaltText.cancelar,
                action: {
                    btnId: 'btnModalSair',
                    acao: () => sairConfirmacao(),
                    texto: defaltText.sair,
                    class: 'success'
                }
            }}
        />
    );
};
