import React from 'react';
import { useSelector } from 'react-redux';
import './Tooltip.css';

const Tooltip = ({ caso, estado, posicaoMessage }) => {
    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../../modulos/locais/nls/${locales}.json`);
    const tooltipLocais = useSelector(state => state.layoutState.tooltipLocais);
    const posicao = tooltipLocais.posicao;
    const local = tooltipLocais.local;
    const open = tooltipLocais.open;
    const topTooltip = `${posicao.top + 50}px`;
    const leftTooltip = `${posicao.left + 200}px`;

    return (
        caso === 1 ?
            <div hidden={!estado} class="tooltip-localidade" style={{
                top: `${posicaoMessage.top}px`,
                left: `${posicaoMessage.left}px`,
                zIndex: 20,
                width: posicaoMessage.width
            }}>
                <p>{posicaoMessage.mensagem}</p>
            </div>
            :
            <div hidden={!open} class="tooltip-localidade" style={{
                top: topTooltip,
                left: leftTooltip,
                zIndex: 20,
                width: '200px'
            }}>
                <p>{defaultText.nome} {local.nomeLocal}</p>
                <p>{defaultText.descricao} {local.descricaoLocal || '--'}</p>
            </div>
    );
};

export default Tooltip;