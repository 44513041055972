import axios from 'axios';

export const interceptador = () => {

    const route = window.location.pathname;

    if (route != '/login' && route != '/registrar' && route != '/esqueciSenha' && !route.includes('/resetPassword')) {
        axios.get('/api/autorizacao/isLogado')
            .then((response) => {
                if (!response.data) loginPage();
            })

            .catch((erro) => {
                loginPage();
            });
    }

    function loginPage() {
        window.location.pathname = '/login';
    }
};
