import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IcoCog, IcoIntelbras, IcoMenu, IconBell, IcoUserCircle } from '../../../components/icones';
import { sidebarLeft, setModalHeaderType } from '../../redux/LayoutActions';
import { IconLocation } from '../../../components/icones/location';
import ModalHeader from '../modalHeader/ModalHeader';
import LocalsContent from '../../conteudosModaisHeader/localsContent';
import ConfigContent from '../../conteudosModaisHeader/configContent';
import './header.css';
import NotificationContent from '../../conteudosModaisHeader/notificationContent';
import UserContent from '../../conteudosModaisHeader/userContent';
import { listarNotificacoes, notificacoesNaoVistas } from '../../../modulos/notificacoes/redux/NotificacoesActions';

export default () => {
    const dispatch = useDispatch();
    const sidebar = useSelector(state => state.layoutState);
    const defaultText = require(`../../nls/${sidebar.linguagem}.json`);
    const user = useSelector((state) => state.authentication.user);
    const modalHeaderType = useSelector((state) => state.layoutState.modalHeaderType);
    const notificacoesState = useSelector((state) => state.notificacoesState);
    const handleChangeModalHeaderType = (modalHeaderType) => {
        dispatch(setModalHeaderType(""));
        setTimeout(() => {
            dispatch(setModalHeaderType(modalHeaderType));
        }, 100);
    };
    useEffect(() => {
        dispatch(listarNotificacoes());
    }, [dispatch, defaultText]);

    const exibirQntNotificacoes = notificacoesState.notificacoes.filter(notificacao => !notificacao.visualizado).length

    const getHeaderModalOpened = (modalHeaderType) => {
        switch (modalHeaderType) {
            case 'locals':
                return <ModalHeader classAnchor='modal-header-locals' children={<LocalsContent />} />;
            case 'config':
                return <ModalHeader classAnchor='modal-header-config' children={<ConfigContent />} />;
            case 'notification':
                return <ModalHeader classAnchor='modal-header-notification' children={<NotificationContent />} />;
            case 'user':
                return <ModalHeader classAnchor='modal-header-user' children={<UserContent />} />;
            default:
                return;
        }

    };

    return (
        <header className='headerBox'>
            <button id='btnMenu' onClick={() => dispatch(sidebarLeft(!sidebar.sidebarLeft))} title={defaultText.menu}>
                <IcoMenu
                    className='iconMenu'
                    title={defaultText.menu}
                    width="24.5"
                    height="18.333"
                />
            </button>
            <IcoIntelbras height='1.8em' className="intelbras-icon" />
            <div className='rightAction'>
                <span className='separator'></span>
                <button
                    id='btnLocation'
                    onClick={() => handleChangeModalHeaderType("locals")}
                >
                    <IconLocation title={defaultText.location} />
                </button>
                <span className='separator'></span>
                <button
                    id='btnConfig'
                    onClick={() => handleChangeModalHeaderType('config')}
                >
                    <IcoCog id='btnCog' title={defaultText.config} />
                </button>
                <span className='separator'></span>
                <div>
                    <button
                        id='btnNotification'
                        onClick={() => handleChangeModalHeaderType('notification')}
                    >
                        <IconBell title={defaultText.notification} />
                        <div className={`${exibirQntNotificacoes ? 'hasNotification' : ''}`}>
                            {exibirQntNotificacoes ? <span>{exibirQntNotificacoes}</span> : null}
                        </div>
                    </button>
                </div>
                <span className='separator'></span>
                <div className='userBox'>
                    <button id='btnUser' title={defaultText.user} onClick={() => handleChangeModalHeaderType('user')}>
                        <IcoUserCircle />
                        <div className='userInfo'>
                            {user && user.nome}
                            {user.perfil && <p>{user.perfil.nome}</p>}
                        </div>
                    </button>
                </div>
            </div>
            {getHeaderModalOpened(modalHeaderType)}
        </header>

    );
};