import React from 'react';
import "./cardButton.css";

const CardButton = ({ label, icon, action, disabled, id, hasAccess = true }) => {
    return (
        hasAccess ? (
            <button id={id} className="btn-card" disabled={disabled} onClick={action}>
                {icon && icon}
                {label}
            </button>
        ) : null
    );
};

export default CardButton;