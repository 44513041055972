import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/button/Button";
import { SelectInBox } from "../../../components/form";
import Input from "../../../layouts/components/Input/Input";
import { translateStatus } from "../../../utils/utils";
import { atualizarFormularioDePesquisaCpe, isSearch, limparCampoFormularioPesquisaCpe, pesquisaCpes, resetarCamposFormularioPesquisaCpe, setFecharModalAdvancedFilter, setModalAdvancedFilterCPE } from "../redux/CpeActions";

export const AdvancedFilterCpe = () => {

    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const formPesquisa = useSelector((state) => state.cpesState.formPesquisa);
    const defaultText = require(`../nls/${locales}.json`);
    const modalAdvancedFilter = useSelector((state) => state.cpesState.modalAdvancedFilter);
    const olts = useSelector(state => state.oltsState.olts);
    const planos = useSelector(state => state.planosState.planos);

    const statusOptions = [
        { statusCpe: translateStatus("NAO_PROVISIONADA", locales) },
        { statusCpe: "ONLINE" },
        { statusCpe: "OFFLINE" },
        { statusCpe: translateStatus("PROBLEMA_OPTICO", locales) },
        { statusCpe: translateStatus("ERRO", locales) },
    ];

    return (
        <>
            <div className='titleAdvancedFilter'>
                <p>{defaultText.filters}</p>
                <Button
                    value="limparFiltos"
                    titulo={defaultText.pesquisar.cleanFilter}
                    action={() => dispatch(resetarCamposFormularioPesquisaCpe())}
                    texto={defaultText.pesquisar.cleanFilter}
                    color="primary"
                    type='btn'
                />
            </div>
            <div className='advancedFilterServices'>
                <div className="advancedItem">
                    <SelectInBox
                        id='olt'
                        label={defaultText.inputs.olt}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        select={{
                            action: e => dispatch([atualizarFormularioDePesquisaCpe(e)]),
                            options: olts,
                            selected: formPesquisa.olt
                        }}
                        position="absolute"
                    />
                </div>

                <div className="advancedItem">
                    <Input
                        id="identificador"
                        name="identificador"
                        label={defaultText.pesquisar.identificador}
                        value={formPesquisa.identificador}
                        changeInput={(e) => dispatch(atualizarFormularioDePesquisaCpe(e))}
                        clearField={{
                            action: (e) => dispatch(limparCampoFormularioPesquisaCpe(e, formPesquisa)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>

                <div className="advancedItem">
                    <Input
                        id="modelo"
                        name="modelo"
                        label={defaultText.pesquisar.modelo}
                        value={formPesquisa.modelo}
                        changeInput={(e) => dispatch(atualizarFormularioDePesquisaCpe(e))}
                        clearField={{
                            action: (e) => dispatch(limparCampoFormularioPesquisaCpe(e, formPesquisa)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>

                <div className="advancedItem">
                    <Input
                        id="porta"
                        type="number"
                        name="porta"
                        label={defaultText.inputs.porta}
                        value={formPesquisa.porta}
                        changeInput={(e) => dispatch(atualizarFormularioDePesquisaCpe(e))}
                        clearField={{
                            action: (e) => dispatch(limparCampoFormularioPesquisaCpe(e, formPesquisa)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>
                <div className="advancedItem">
                    <SelectInBox
                        id='status'
                        label={defaultText.inputs.status}
                        selectDefault={{
                            id: 'id',
                            label: 'statusCpe',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        select={{
                            action: e => dispatch([atualizarFormularioDePesquisaCpe(e)]),
                            options: statusOptions,
                            selected: formPesquisa.status
                        }}
                        position="absolute"
                    />
                </div>
                <div className="advancedItem">
                    <SelectInBox
                        id='plano'
                        label={defaultText.inputs.plano}
                        selectDefault={{
                            id: 'id',
                            label: 'nome',
                            text: defaultText.select.selecionado.nenhum
                        }}
                        select={{
                            action: e => dispatch([atualizarFormularioDePesquisaCpe(e)]),
                            options: planos,
                            selected: formPesquisa.plano
                        }}
                        position="absolute"
                    />
                </div>
                <div className="advancedItem" style={{marginRight: '6px'}}>
                    <Input
                        id="descricao"
                        name="descricao"
                        label={defaultText.pesquisar.descricao}
                        value={formPesquisa.descricao}
                        changeInput={(e) => dispatch(atualizarFormularioDePesquisaCpe(e))}
                        clearField={{
                            action: (e) => dispatch(limparCampoFormularioPesquisaCpe(e, formPesquisa)),
                            text: defaultText.btn.limparCampo
                        }}
                    />
                </div>
                <div className='btnAdvancedFilter'>
                    <Button
                        titulo={defaultText.pesquisar.text}
                        action={() => dispatch(setFecharModalAdvancedFilter())}
                        texto={defaultText.btn.cancelar}
                        color='secondary'
                        type='btn'
                    />
                    <Button
                        titulo={defaultText.pesquisar.text}
                        action={() => {
                            dispatch(pesquisaCpes({ pesquisa: formPesquisa })),
                                dispatch(setModalAdvancedFilterCPE('')),
                                dispatch(isSearch());
                        }}
                        texto={defaultText.pesquisar.text}
                        color='secondary'
                        type='btn'
                    />
                </div>
            </div>
        </>
    );

};