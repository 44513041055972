import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import {IconBell } from '../../components/icones';
import history from '../../history';
import { listarNotificacoes, setNotificacoesVisualizadas, deletarTodasNotificacoes, fecharModalNotificacoes } from '../../modulos/notificacoes/redux/NotificacoesActions';
import { setModalHeaderType, abrirModalReleaseNotes, fecharModal } from '../redux/LayoutActions';
import './conteudoModaisHeader.css';
import NotificacaoBox from '../../modulos/notificacoes/notificacaoBox';

const NotificationContent = () => {

    const dispatch = useDispatch();
    const locales = useSelector((state) => state.layoutState.linguagem);
    const defaultText = require(`../../modulos/notificacoes/nls/${locales}.json`);
    const layoutDefaultText = require(`../nls/${locales}.json`);
    const notifications = useSelector(state => state.notificacoesState.notificacoes);
    const userTheme = useSelector((state) => state.layoutState.selectedTheme.id);

    useEffect(() => {
        dispatch(listarNotificacoes());
        dispatch(fecharModal());
        const timer = setTimeout(() => {
            dispatch(setNotificacoesVisualizadas(notifications));
        }, 500);
        return () => clearTimeout(timer);
    }, [dispatch]);

    return (
        <div className='notificationModal'>
            <span>
                <IconBell title={defaultText.notification} />
                <h6>{defaultText.title}</h6>
            </span>
            {notifications.length > 0 &&
            <span id='spanLimparNotificacao' onClick={() => [dispatch(deletarTodasNotificacoes()), dispatch(fecharModalNotificacoes())]}>
                {layoutDefaultText.limparNotificacao}
            </span>
            }
            {notifications.length > 0 && notifications.map((notification => {
                return (<NotificacaoBox notification = {notification}></NotificacaoBox>);
            }))}
            <div className='btnModal'>
                <Button
                    id="btnNotificacoes"
                    titulo={defaultText.btn.btnModal}
                    action={() => [history.push(`/notificacoes`), dispatch(setModalHeaderType(""))]}
                    color=''
                    children={defaultText.btn.btnModal}
                    size='block'
                />
                <Button
                    id="btnReleaseNotes"
                    titulo={layoutDefaultText.releaseNotes}
                    action={() => dispatch(abrirModalReleaseNotes())}
                    color=''
                    children={layoutDefaultText.releaseNotes}
                    size='block'
                />
            </div>
        </div>
    );
};

export default NotificationContent;