import React from "react";

import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../components/modal/BasicModal";

import { fecharModalPlano, deletarPlano, setTipoModal, atualizarConfigurationOptions, atualizarServiceTypes, deletarServicoOuConfiguracao, fecharModal } from "../redux/PlanosActions";

const RemoverServicoOuConfiguracaoModal = () => {
    const dispatch = useDispatch();
    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const plano = useSelector(state => state.planosState.plano);

    return (
        <BasicModal
            close={() => dispatch(fecharModal())}
            containerModal={{
                title: defaultText.tituloModalRemover,
                size: "medium",
                content: (
                    <>
                        <p>{defaultText.mensagem.removerConfirmacao}</p>
                        <p style={{ fontWeight: "bold" }}> {plano.nome}</p>
                    </>
                ),
                closeText: defaultText.btn.fechar,
                action: {
                    acao: () => [
                        dispatch(plano.servico.categoria === "servico" ? atualizarServiceTypes(plano.servico.tipoServico, "-") : atualizarConfigurationOptions(plano.servico.tipoServico, "-")),
                        dispatch(deletarServicoOuConfiguracao(plano.servico)),
                        dispatch(setTipoModal(""))
                    ],
                    texto: defaultText.btn.remover,
                    class: "success",
                },
            }}
        />
    );
};

export default RemoverServicoOuConfiguracaoModal;