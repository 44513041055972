import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import ConteudoGerenciarCpe from './conteudoGerenciarCpe';

import { fecharModalCpe, saveCpeConfigs } from '../redux/CpeActions';

const GerenciarCPE = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../nls/${locales}.json`);
    const statusModal = useSelector(state => state.cpesState.statusModal);
    const cpe = useSelector(state => state.cpesState.cpe);
    const paginacao = useSelector((state) => state.cpesState.paginacao);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalCpe())}
            containerModal={{
                title: "WiFi CPE",
                size: 'medium',
                content: <ConteudoGerenciarCpe />,
                closeText: defaultText.btn.fechar,
                action: {
                    btnId: "btnSalvar",
                    acao: () => dispatch(saveCpeConfigs(cpe, 0)),
                    //acao: () => console.log(olt),
                    texto: defaultText.btn.salvar,
                    class: 'success'
                }
            }}
        />
    );
};

export default GerenciarCPE;
