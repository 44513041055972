import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BasicModal from '../../../components/modal/BasicModal'

import Conteudo from './conteudo'

import { fecharModalPerfil } from '../redux/PerfilActions'

const CriarEditar = ({ action }) => {
  const dispatch = useDispatch()

  const locales = useSelector(state => state.layoutState.linguagem)
  const defaltText = require(`../nls/${locales}.json`)

  const perfil = useSelector(state => state.perfisState.perfil)

  const statusModal = useSelector(state => state.perfisState.statusModal)

  const title = perfil.id ? defaltText.tituloModalEditar : defaltText.tituloModalNovo

  return (
    <BasicModal
      open={statusModal}
      close={() => dispatch(fecharModalPerfil())}
      containerModal={{
        title,
        size: 'medium',
        content: <Conteudo />,
        closeText: defaltText.btn.fechar,
        action: {
          acao: action,
          texto: defaltText.btn.salvar,
          class: 'success'
        }
      }}
    />
  )
}

export default CriarEditar
