import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import { fecharModalCpe, reenviarCpe } from '../redux/CpeActions';

const Erro = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const pageNumber = useSelector(state => state.cpesState.paginacao.pageNumber);
    const cpe = useSelector(state => state.cpesState.cpe);
    const statusModal = useSelector(state => state.cpesState.statusModal);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalCpe())}
            containerModal={{
                title: `Erro - ${cpe.identificador}`,
                size: 'medium',
                content: (
                    <>
                        <p>{defaltText.mensagem.mensagemReenviar}</p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    btnId: "btnRetry",
                    acao: () => dispatch(reenviarCpe(cpe, pageNumber)),
                    texto: defaltText.btn.tentarNovamente,
                    class: "success"
                }
            }}
        />
    );
};

export default Erro;
