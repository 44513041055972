import { resetarCamposAction, validarAction, validarCamposAction } from '../../../validation/validationActions'

import axios from 'axios'
import { toast } from 'react-toastify'


export const SET_TIPO_MODAL = 'SET_TIPO_MODAL'
const setTipoModal = (tipo) => ({
    type: SET_TIPO_MODAL,
    payload: tipo,
})

export const abrirModalLimiares = () => setTipoModal('abrirLimiares')

export const abrirModalApagarHistorico = () => setTipoModal('apagar')

export const FECHAR_MODAL = 'FECHAR_MODAL'
export const fecharModal = () => ({
    type: FECHAR_MODAL
})

export const ATUALIZAR_CAMPOS_LIMIARES = 'ATUALIZAR_CAMPOS_LIMIARES'
export const atualizarCamposLimiares = (e) => ({
    type: ATUALIZAR_CAMPOS_LIMIARES,
    payload: e

})


export const TIPOS_ALARMES_ATUALIZADOS_COM_SUCESSO = 'TIPOS_ALARMES_ATUALIZADOS_COM_SUCESSO'
export const tiposAlarmesAtualizadosComSucesso = (tipo) => ({
    type: TIPOS_ALARMES_ATUALIZADOS_COM_SUCESSO,
    payload: tipo

})

export const ATUALIZANDO_LIMIARES = 'ATUALIZANDO_LIMIARES'
export const atualizandoLimiares = () => ({
    type: ATUALIZANDO_LIMIARES
})

export const ATUALIZANDO_TIPOS_ALARMES = 'ATUALIZANDO_TIPOS_ALARMES'
export const atualizandoTiposAlarmes = () => ({
    type: ATUALIZANDO_TIPOS_ALARMES

})

const LimiaresRequired = {
    txMax: '',
    txMin: '',
    rxMax: '',
    rxMin: '',
}

export const atualizarLimiares = (limiares) => {
    const backPronto = false
    alert('Os limiares serão salvos!')

    const LimiaresVal = {
        formRequired: LimiaresRequired,
        formValues: limiares,
    }
    return (dispatch) => {
        const mensagemDeErro = validarCamposAction(LimiaresVal)
        if (validarAction(mensagemDeErro)) return dispatch(mensagemDeErro)
        dispatch(atualizandoLimiares())
        //TIRANDO QUANDO INTEGRAR
        dispatch(fecharModal());
        if (backPronto) {
            dispatch(atualizandoTiposAlarmes())
            axios
                .post(`/api/alarmes/limiares`, limiares)
                .then((response) => {
                    dispatch(limiaresAtualizadosComSucesso(response.data));
                    dispatch(fecharModal());
                })
                .catch((error) => {
                    dispatch(fecharModal());
                    dispatch(limiares(error))
                    idiomaText
                    dispatch(limiaresNaoAtualizados())
                    toast.success('Erro ao atualizar limiares de potência de sinal. Tente Novamente')
                })
        }
    }
}


export const atualizarTiposAlarmes = (tipo) => {
    const backPronto = false
    console.log('atualizar tipo de alarme')
    return (dispatch) => {
        dispatch(tiposAlarmesAtualizadosComSucesso(tipo));
        if (backPronto) {
            axios
                .post(`/api/alarmes`, tipo)
                .then((response) => {
                    dispatch(TiposAlarmesAtualizadosComSucesso(response.data));
                    dispatch(listarAlarmes());
                    dispatch(fecharModal());
                })
                .catch((error) => {
                    dispatch(fecharModal());
                    dispatch(limiares(error))
                    idiomaText
                    dispatch(limiaresNaoAtualizados())
                    toast.success('Erro ao atualizar limiares de potência de sinal. Tente Novamente')
                })
        }
    }

}

export const listarAlarmes = () => {
    const backPronto = false
    console.log('Listar Alarmes')


    return (dispatch) => {
        if (backPronto) {
            axios
                .get(`/api/alarmes`)
                .then((response) => {
                    dispatch(alarmesListadosComSucesso(response.data));
                    dispatch(fecharModal());
                })
                .catch((error) => {
                    dispatch(fecharModal());
                    dispatch(alarmesNaoListados())
                    toast.success('Erro ao lista os alarmes. Tente Novamente.')
                })
        }
    }

}

export const apagarHistoricoAlarme = () => {

    const backPronto = false
    console.log('Apagar Alarmes')
    alert('O Histórico de alarmes será apagado!');

    return (dispatch) => {
        //TIRA DEPOIS DA INTEGRAÇÃO COM O BACK
        dispatch(fecharModal());
        if (backPronto) {
            axios
                .get(`/api/alarmes/deletar`)
                .then((response) => {
                    dispatch(alarmesApagadosComSucesso(response.data));
                    dispatch(fecharModal());
                })
                .catch((error) => {
                    dispatch(fecharModal());
                    dispatch(alarmesNaoApagados())
                    toast.success('Erro ao apagar os alarmes. Tente Novamente.')
                })
        }
    }


}