import React from 'react';

export function ServicoIcon(props) {
    return (
        /* <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42.001" viewBox="0 0 42 42.001">
            <path id="repairing-service-svgrepo-com" d="M41.87,18.668a1.368,1.368,0,0,0-1.35-1.034,4.637,4.637,0,0,1-3.174-8.07,1.167,1.167,0,0,0,.128-1.584,20.79,20.79,0,0,0-3.327-3.36,1.17,1.17,0,0,0-1.6.129,4.84,4.84,0,0,1-5.238,1.176,4.664,4.664,0,0,1-2.836-4.559A1.165,1.165,0,0,0,23.446.139,20.957,20.957,0,0,0,18.721.127a1.169,1.169,0,0,0-1.04,1.2,4.673,4.673,0,0,1-2.876,4.48,4.853,4.853,0,0,1-5.2-1.184,1.17,1.17,0,0,0-1.585-.133,20.876,20.876,0,0,0-3.4,3.359,1.168,1.168,0,0,0,.125,1.6,4.654,4.654,0,0,1,1.174,5.24,4.854,4.854,0,0,1-4.569,2.839A1.143,1.143,0,0,0,.142,18.557a21.051,21.051,0,0,0-.009,4.775A1.39,1.39,0,0,0,1.51,24.365a4.606,4.606,0,0,1,4.321,2.877,4.672,4.672,0,0,1-1.175,5.193,1.166,1.166,0,0,0-.127,1.582,20.863,20.863,0,0,0,3.322,3.361,1.168,1.168,0,0,0,1.6-.127,4.833,4.833,0,0,1,5.235-1.177,4.658,4.658,0,0,1,2.842,4.558,1.166,1.166,0,0,0,1.03,1.227,20.91,20.91,0,0,0,4.725.013,1.169,1.169,0,0,0,1.039-1.2,4.668,4.668,0,0,1,2.874-4.478,4.847,4.847,0,0,1,5.2,1.183,1.173,1.173,0,0,0,1.585.133,20.9,20.9,0,0,0,3.4-3.359,1.166,1.166,0,0,0-.125-1.6,4.653,4.653,0,0,1-1.176-5.238,4.7,4.7,0,0,1,4.3-2.847l.26.007a1.169,1.169,0,0,0,1.229-1.03A20.959,20.959,0,0,0,41.87,18.668ZM21,33.106a12.106,12.106,0,1,1,10.394-5.9l-5.3-5.3A7.427,7.427,0,0,0,17.3,11.847a.841.841,0,0,0-.581.586.82.82,0,0,0,.233.8s2.617,2.637,3.493,3.513a.543.543,0,0,1,.079.389l-.008.058a16.738,16.738,0,0,1-.4,2.562l-.056.054-.058.06a16.5,16.5,0,0,1-2.6.4V20.27l-.044.011-.042,0a.559.559,0,0,1-.4-.171C16,19.2,13.489,16.7,13.489,16.7a.855.855,0,0,0-.611-.272.831.831,0,0,0-.783.632,7.427,7.427,0,0,0,10.062,8.779L27.516,31.2A12.046,12.046,0,0,1,21,33.106Z" transform="translate(-0.002 0.001)" />
        </svg> */
        <svg xmlns="http://www.w3.org/2000/svg"
            width="38.319"
            height="38.321"
            viewBox="0 0 38.319 38.321"
            className={props.className ? props.className : ""}>
            <title>{props.title}</title>
            <g id="Grupo_179" data-name="Grupo 179" transform="translate(-234.681 -616.681)">
                <path id="Icon_awesome-tools" data-name="Icon awesome-tools" d="M37.5,29.613,28.738,20.85a5.557,5.557,0,0,0-6.391-1.04l-7.978-7.978V7.184L4.79,0,0,4.79l7.184,9.579h4.647l7.978,7.978a5.569,5.569,0,0,0,1.04,6.391L29.613,37.5a2.782,2.782,0,0,0,3.944,0L37.5,33.557a2.8,2.8,0,0,0,0-3.944ZM24.824,16.838a7.874,7.874,0,0,1,5.605,2.32l1.452,1.452A10.593,10.593,0,0,0,35.159,18.4,10.763,10.763,0,0,0,38,8.172a.9.9,0,0,0-1.5-.412l-5.568,5.568-5.081-.846L25,7.4l5.568-5.568A.9.9,0,0,0,30.137.322,10.78,10.78,0,0,0,19.914,3.158a10.605,10.605,0,0,0-3.083,7.753l6.144,6.144A8.153,8.153,0,0,1,24.824,16.838Zm-7.776,6.137L12.8,18.732,1.4,30.145a4.789,4.789,0,0,0,6.773,6.773l9.25-9.25a8.036,8.036,0,0,1-.374-4.692ZM4.79,35.323a1.8,1.8,0,1,1,1.8-1.8A1.8,1.8,0,0,1,4.79,35.323Z" transform="translate(234.685 616.681)" />
            </g>
        </svg>
    );
}