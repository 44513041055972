import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasicModal from '../../../components/modal/BasicModal';

import { fecharModalCpe, deletarCpe, listarCpes } from '../redux/CpeActions';

const Remover = () => {
    const dispatch = useDispatch();

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);

    const cpe = useSelector(state => state.cpesState.cpe);
    const cpes = useSelector((state) => state.cpesState.cpes);
    const paginaAtual = useSelector((state) => state.cpesState.paginacao.pageNumber);
    const statusModal = useSelector(state => state.cpesState.statusModal);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalCpe())}
            containerModal={{
                title: defaltText.tituloModalRemover,
                size: 'medium',
                content: (
                    <>
                        <p>{defaltText.mensagem.removerConfirmacao}</p>
                        <p style={{ fontWeight: "bold" }}>{cpe.identificador}</p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    acao: () => dispatch(deletarCpe(cpe, cpes.length > 1 ? paginaAtual : (paginaAtual > 0 ? paginaAtual - 1 : paginaAtual))),
                    texto: defaltText.btn.remover,
                    class: "success"
                }
            }}
        />
    );
};

export default Remover;
