import React from "react";

import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../components/modal/BasicModal";

import { fecharModalPlano, deletarPlano } from "../redux/PlanosActions";

const RemoverPlanoModal = () => {
    const dispatch = useDispatch();
    const locales = useSelector(state => state.layoutState.linguagem);
    const defaltText = require(`../nls/${locales}.json`);
    const plano = useSelector(state => state.planosState.plano);
    const statusModal = useSelector(state => state.planosState.statusModal);

    return (
        <BasicModal
            open={statusModal}
            close={() => dispatch(fecharModalPlano())}
            containerModal={{
                title: defaltText.tituloModalRemover,
                size: "medium",
                content: (
                    <>
                        <p>{defaltText.mensagem.removerConfirmacao}</p>
                        <p style={{ fontWeight: "bold" }}>{plano.nome}</p>
                    </>
                ),
                closeText: defaltText.btn.fechar,
                action: {
                    acao: () => dispatch(deletarPlano(plano)),
                    texto: defaltText.btn.remover,
                    class: "success",
                },
            }}
        />
    );
};

export default RemoverPlanoModal;
