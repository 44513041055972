import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectInBox } from '../../../components/form';
import InputInbox from '../../../components/form/inputInBox/InputInbox';
import Row from '../../../components/row/row';
import Input from '../../../layouts/components/Input/Input';
import { validarCampoAction } from '../../../validation/validationActions';
import { atualizarConteudoColaboradorModal, clearAdicionarColaboradorField } from '../redux/ColaboradorActions';
import CheckboxSwitch from '../../../components/form/checkboxSwitch/CheckboxSwitch';
import RadioButton from '../../../components/form/radioButton/RadioButton';

export default () => {
    const dispatch = useDispatch();
    const locales = useSelector(state => state.layoutState.linguagem);
    const colaborador = useSelector(state => state.colaboradoresState.colaborador);
    const defaltText = require(`../nls/${locales}.json`);
    const errorMessage = useSelector(state => state.validacaoState);
    const [permissao, setPermissao] = useState([
        { id: "administrador", text: defaltText.inputs.administrador },
        { id: "visualizador", text: defaltText.inputs.visualizador }])

    return (
        <section className='box-criar-editar-colaboradores'>
            <Row>
                <div className='col-md-12'>
                    <Input
                        id="email"
                        name='email'
                        label={defaltText.inputs.email}
                        value={colaborador.email}
                        changeInput={e => dispatch(atualizarConteudoColaboradorModal(e))}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: errorMessage.email ? defaltText.mensagem.obrigatorio : ''
                        }}
                        clearField={{
                            action: (e) => dispatch(clearAdicionarColaboradorField(e)),
                            text: defaltText.btn.limparCampo
                        }}
                    />
                    <RadioButton
                        id="permissaoAdm"
                        name='permissaoAdm'
                        changeInput={e => { dispatch(atualizarConteudoColaboradorModal(e)) }}
                        selected={colaborador.permissaoAdm ? permissao[0].id : permissao[1].id}
                        options={permissao}
                    />
                </div>
            </Row>
        </section>
    );
};
