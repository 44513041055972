import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './menu.css';

const SubMenu = props => {
    return props.map(item => {
        return (
            <li key={item.item}>
                <Link to={`/${item.item}`}>
                    {item.icone}
                    {item.text}
                </Link>
            </li>
        );
    });
};

const SideBarMenu = props => {
    const { MenuJson, action } = props;
    const [menuNav, setMenuNav] = useState({});
    const location = useLocation();

    const isActive = address => location.pathname === address;

    const changeMenu = item => {
        setMenuNav({ ...menuNav, [item]: !menuNav[item] });
    };

    const user = useSelector(state => state.authentication.user);

    return (
        <ul className='box-menu'>
            {MenuJson.map(item => {
                if (item.subMenu) {
                    return (
                        <li key={item.item} className={menuNav[item.item] ? 'sub-nav active' : 'sub-nav'}>
                            <button type='button' onClick={() => changeMenu(item.item)}>
                                {item.icone}
                                {item.text}
                            </button>
                            <ul>{SubMenu(item.subMenu)}</ul>
                        </li>
                    );
                }
                return (
                    <li key={item.item} className={isActive(`/${item.item}`) ? `active ${item.itemStatus}` : `${item.itemStatus}`}>
                        <Link onClick={action} to={`/${item.item}`}>{item.text}</Link>
                    </li>
                );
            })}
        </ul>
    );
};

export default SideBarMenu;
