import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectInBox } from '../../components/form';
import { IcoCog } from '../../components/icones';
import ThemeSelector from '../../components/ThemeSelector/ThemeSelector';
import { ajustIdioma, atualizarPreferencia, listarIdiomas } from '../redux/LayoutActions';

const ConfigContent = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authentication.user);
    const preferencia = useSelector((state) => state.layoutState);
    const defaultText = require(`../nls/${preferencia.linguagem}.json`);

    const handleChangePreferencia = (e) => {
        const userPreference = {
            ...user,
            preferencia: { ...user.preferencia, [e.target.name]: e.target.value.id },
        };
        dispatch(atualizarPreferencia(userPreference));
    };

    useEffect(() => {
        dispatch([listarIdiomas()]);
    }, [dispatch]);

    return (
        <div className='userConfig'>
            <IcoCog />
            <h6>{defaultText.config}</h6>
            <div>
                <ThemeSelector
                    label={defaultText.tema}
                />
                <SelectInBox
                    id='linguagem'
                    /* label={defaultText.linguagem} */
                    selectDefault={{
                        id: 'id',
                        label: 'nome',
                        minWidth: '100%',
                    }}
                    select={{
                        action: (e) => handleChangePreferencia(e),
                        options: preferencia.linguagens,
                        selected: ajustIdioma(preferencia.linguagem),
                    }}
                />
            </div>
        </div>
    );
};

export default ConfigContent;