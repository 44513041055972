import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../../components/form/checkbox/Checkbox';
import RadioButton from '../../../../components/form/radioButton/RadioButton';
import { ExclamationIcon, IcoClose } from '../../../../components/icones';
import { MaskVlanId } from '../../../../components/mask/Mask';
import Input from '../../../../layouts/components/Input/Input';
import { inicializarValidacao, validarCampoAction } from '../../../../validation/validationActions';
import { atualizarConteudoOltModal, handleAcceptedModeChangeTermsState, limparConfigsModoDeOperacao, fecharModalOlt} from '../../redux/OltActions';
import Progress from '../../../../components/progress/Progress'

const OperationModeChangeMessage = ({ resetProgress, changeOldMode, confirmText = "", message = "", restMessage = "", operationMode = "" }) => {

    const locales = useSelector(state => state.layoutState.linguagem);
    const defaultText = require(`../../nls/${locales}.json`);
    const acceptedModeChangeTerms = useSelector((state) => state.oltsState.acceptedModeChangeTerms);
    const mensagemErro = useSelector(state => state.validacaoState);
    const olt = useSelector((state) => state.oltsState.olt);
    const dispatch = useDispatch();
    const [listInputs, setlistInputs] = useState([]);
    const [stepStatus, setStepStatus] = useState("");

    const [onus, setOnus] = useState([
        { id: 'onuR1', value: "ETH1" },
        { id: 'onuR1v2', value: "ETH1" }])
    const [fabricantes, setFabricantes] = useState([
        { id: 'modelo', value: "ONU" },
        { id: 'line', value: "ETH1" }
    ]);
    function itensInput() {
        var itens = []
        olt.vlans = []
        for (let i = 1; i <= olt.portasG.length; i++) {
            itens.push({ id: `GPON${i}`, name: `GPON ${i}`, number: i })
        }
        let ItensForm = []
        itens.map((item) => {
            ItensForm = [...ItensForm, item]
        })
        return ItensForm;
    }
    useEffect(() => {
        olt.configCustomVlanPorPon = ["ETH1", "ETH1", "ONU", "ETH1"]
        olt.uplinkAutoConfig = false;
        const novaLista = itensInput()
        setlistInputs(novaLista)
        dispatch(inicializarValidacao(novaLista))
    }, [])
    useEffect(() => {
        const escolhas = onus.concat(fabricantes)
        escolhas.map((esc, index) =>
            olt.configCustomVlanPorPon[index] = esc.value
        )
    }, [onus, fabricantes])
    
    function getStepStatusText(hasError, step){
        if(!hasError){
            if(olt.operationMode?.id === "AUTOSERVICE"){
                if(olt.progressoModoDeOperacao?.length === 0){
                    setStepStatus(defaultText.informacoes.VERIFICANDO_LINES);
                }else if(olt.progressoModoDeOperacao?.length === 1){
                    setStepStatus(defaultText.informacoes.VERIFICANDO_CONFIGS);
                }else if(olt.progressoModoDeOperacao?.length === 2){
                    setStepStatus(defaultText.informacoes.ATIVANDO_CONFIG);
                    changeOldMode();
                }
            }else{
                if(olt.progressoModoDeOperacao?.length === 0){
                    setStepStatus(defaultText.informacoes.VERIFICANDO_VLAN);
                }else if(olt.progressoModoDeOperacao?.length === 1){
                    setStepStatus(defaultText.informacoes.VERIFICANDO_LINES);
                }else if(olt.progressoModoDeOperacao?.length === 2){
                    setStepStatus(defaultText.informacoes.VERIFICANDO_CONFIGS);
                }else if(olt.progressoModoDeOperacao?.length === 3){
                    setStepStatus(defaultText.informacoes.ATIVANDO_VLAN);
                }else if(olt.progressoModoDeOperacao?.length === 4){
                    setStepStatus(defaultText.informacoes.ATIVANDO_LINES);
                }else if(olt.progressoModoDeOperacao?.length === 5){
                    setStepStatus(defaultText.informacoes.ATIVANDO_CONFIG);
                    changeOldMode();
                }
            }
        }else{
            setStepStatus(eval("defaultText.informacoes.".concat(step)))
        }
    }

    const ProgressAutoService = () => {
        const step = olt.progressoModoDeOperacao[olt.progressoModoDeOperacao?.length - 1];
        const hasError = step?.includes("ERRO")
        const message = hasError ? eval("defaultText.mensagem.".concat(step)) : null

        if(olt.progressoModoDeOperacao[olt.progressoModoDeOperacao?.length-1] === "ATIVADO"){
            return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
                    <span style={{ color: "var(--success)" }}>{defaultText.informacoes.MODOALTERADO}</span>
                </div>
            </>
        );
        }
        getStepStatusText(hasError, step)
        return (
            <div class='progressbar-steps'>
                <div class= 'progressbar-content'>
                    <p>{olt.progressoModoDeOperacao?.length} {defaultText.inputs.de} 3</p>
                    <Progress
                        text="Barra de progresso"
                        type="progress-bar"
                        progressVale={33.33 * olt.progressoModoDeOperacao?.length}
                        border="1px solid var(--modal-header-line)"
                        progressColor= {hasError ? 'red' : "#00a335"}
                        />
                    <div style={{paddingTop: '5px', display:'inline-flex'}}>
                        <span>{stepStatus}</span>
                        {hasError? <IcoClose style={{color: 'red', marginLeft: '5px', width: '13px', marginTop: '3px'}} /> : <div class="dots-1"></div>}
                    </div>
                </div>
                <hr style={{ marginBottom: '10px' }} />
                {hasError ? 
                <>
                    <div class="progressbar-step-message">
                        <p>
                        {message}
                        </p>
                    </div> 
                    <hr style={{ marginBottom: '4px' }} />
                    <span>{defaultText.informacoes.CONTINUAR_ERRO}</span><br></br>
                   <div class="button-continue" onClick={() => resetChangeAgainVlan()}>
                    <div>
                        <span>{defaultText.btn.continuar}</span>
                    </div>
                   </div>
                </>
                    : null}     
            </div>
        )
    }

    const ProgressVlanPorPon = () => {
    
        const step = olt.progressoModoDeOperacao[olt.progressoModoDeOperacao?.length - 1];
        const hasError = step?.includes("ERRO")
        const message = hasError ? eval("defaultText.mensagem.".concat(step)) : null
        getStepStatusText(hasError, step)
        
        if(olt.progressoModoDeOperacao[olt.progressoModoDeOperacao?.length-1] === "ATIVADO"){
            return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
                    <span style={{ color: "var(--success)" }}>{defaultText.informacoes.MODOALTERADO}</span>
                </div>
            </>
        );
        }
        return (
            <div class='progressbar-steps'>
                <div class= 'progressbar-content'>
                    <p>{olt.progressoModoDeOperacao?.length}{defaultText.inputs.de} 7</p>
                    <Progress
                        text="Barra de progresso"
                        type="progress-bar"
                        progressVale={14.29 * olt.progressoModoDeOperacao?.length}
                        border="1px solid var(--modal-header-line)"
                        progressColor= {hasError ? 'red' : "#00a335"}
                        />
                    <div style={{paddingTop: '5px', display:'inline-flex'}}>
                        <span>{stepStatus}</span>
                        {hasError? <IcoClose style={{color: 'red', marginLeft: '5px', width: '13px', marginTop: '3px'}} /> : <div class="dots-1"></div>}
                    </div>
                </div>
                <hr style={{ marginBottom: '10px' }} />
                {hasError ? 
                <>
                    <div class="progressbar-step-message">
                        <p>
                        {message}
                        </p>
                    </div> 
                    <hr style={{ marginBottom: '4px' }} />
                    <span>{defaultText.informacoes.CONTINUAR_ERRO}</span><br></br>
                   <div class="button-continue" onClick={() => resetChangeAgainVlan()}>
                    <div>
                        <span>{defaultText.btn.continuar}</span>
                    </div>
                   </div>
                </>
                    : null}     
            </div>
        )
    }
    const onuModes = [
        { id: "ETH1", text: "ETH1" },
        { id: "VEIP", text: "VEIP" },
    ]
    const modeloModes = [
        { id: "ONU", text: "ONU" },
        { id: "ONT", text: "ONT" },
    ]

    const resetChangeAgainVlan = () => {
      olt.progressoModoDeOperacao = null;
      dispatch(limparConfigsModoDeOperacao(olt.id));
      resetProgress();
      };
    function handleOptionsChange(e, name) {
        if (name === 'modelo') {
            setFabricantes(
                fabricantes.map((fab) =>
                    fab.id === name
                        ? { ...fab, value: e.target.value }
                        : { ...fab, value: e.target.value === 'ONT' ? 'VEIP' : 'ETH1' }
                )
            )
        } else if (name === 'line') {
            setFabricantes(
                fabricantes.map((fab) =>
                    fab.id === name
                        ? { ...fab, value: e.target.value }
                        : { ...fab }
                )
            )
        }
        else {
            setOnus(
                onus.map((onu) =>
                    onu.id === name
                        ? { ...onu, value: e.target.value }
                        : { ...onu }
                )
            )
        }
    }
    const OptionRadioButton = ({ text, name, modes }) => {
        const onuSelected = name !== 'modelo' && name !== 'line' ? onus.find(onu => onu.id === name) : fabricantes.find(fab => fab.id === name)
        return (
            <>
                <div class="col-sm-4">
                    <span id="spanRadioButtom">{text}</span>
                </div>
                <div class="col-sm-8">
                    <RadioButton
                        name={name}
                        changeInput={e => handleOptionsChange(e, name)}
                        selected={onuSelected.value}
                        options={modes}
                        disabled={name === 'line' && fabricantes[0].value === 'ONT' ? true : false}
                        validate={{
                            action: e => dispatch(validarCampoAction(e)),
                            erro: mensagemErro.indexVlan ? defaultText.mensagem.obrigatorio : ''
                        }}

                    />
                </div>
            </>
        );
    }
    const VlanPorPon = () => {
        return (
            <>
                <div className="alert-message">
                    <p>{defaultText.mensagem.vlanPorPonMessage}</p>
                </div>
                <hr style={{ marginBottom: '10px' }} />
                <div class="row">
                    {listInputs.map((item, index) => {
                        return (
                            <div class="col-6">
                                <div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <span>{listInputs[index].name}</span>
                                    </div>
                                    <Input
                                        id='input-vlan'
                                        name={listInputs[index].id}
                                        label={listInputs[index].id}
                                        value={olt.vlans[index]}
                                        changeInput={e => [
                                            olt.vlans[index] = MaskVlanId(e.target.value),
                                            setlistInputs([...listInputs], listInputs),
                                        ]}
                                        validate={{
                                            action: e => dispatch(validarCampoAction(e)),
                                            erro: mensagemErro.indexVlan ? defaultText.mensagem.obrigatorio : ''
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
                <hr style={{ marginBottom: '10px' }} />
                <div class="row" style={{ rowGap: '3px' }}>
                    <OptionRadioButton name="onuR1" text="ONU R1" modes={onuModes} />
                    <OptionRadioButton name="onuR1v2" text="ONU R1v2" modes={onuModes} />
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '5px' }}>
                    <span id='spanFabricantes'>{defaultText.informacoes.OUTROSFABRICANTES}</span>
                </div>
                <div class="row">

                    <OptionRadioButton name="modelo" text={defaultText.informacoes.MODELO} modes={modeloModes} />
                    <OptionRadioButton name="line" text="Line" modes={onuModes} />

                </div>
                <hr style={{ marginBottom: '10px' }} />
                <div className="alert-message">
                    <div className="alert-icon">
                        <ExclamationIcon />
                    </div>
                    <p>{message}</p>
                </div>
                <hr />
                <div className="alert-actions">
                    <Checkbox
                        changeInput={() => dispatch(handleAcceptedModeChangeTermsState())}
                        checked={acceptedModeChangeTerms}
                        value={acceptedModeChangeTerms}
                        name="aceiteDeTermos"
                        text={confirmText}
                    />
                </div>
            </>
        );
    }
    const AutoProvisionamento = () => {
        return (
            <>
                <div className="alert-message">
                    <div className="alert-icon">
                        <ExclamationIcon />
                    </div>
                    <p>{message}</p>
                </div>
                <hr />
                <div className="alert-actions">
                    <Checkbox
                        changeInput={() => dispatch(handleAcceptedModeChangeTermsState())}
                        checked={acceptedModeChangeTerms}
                        value={acceptedModeChangeTerms}
                        name="aceiteDeTermos"
                        text={confirmText}
                    />
                </div>
            </>
        )
    }
    
    const Manually = () => {
        return (
            <>
                <div className="alert-message">
                    <div className="alert-icon">
                        <ExclamationIcon />
                    </div>
                    <p>{message}</p>
                </div>
                <hr />
                <div className="alert-actions">
                    <Checkbox
                        changeInput={() => dispatch(handleAcceptedModeChangeTermsState())}
                        checked={acceptedModeChangeTerms}
                        value={acceptedModeChangeTerms}
                        name="aceiteDeTermos"
                        text={confirmText}
                    />
                </div>
            </>
        )
    }
    const AutoService = () => {
        return (
            <>
                <div className="alert-message">
                    <div className="alert-icon">
                        <ExclamationIcon />
                    </div>
                    <p>
                        {message}
                        <span>"{olt.indexVlan ? olt.indexVlan : "1000"}"</span>
                        {restMessage}
                    </p>
                </div>
                <Input
                    id="indexVlan"
                    name='indexVlan'
                    label={defaultText.informacoes.vlan}
                    value={olt.indexVlan}
                    changeInput={e => [
                        e.target.value = MaskVlanId(e.target.value),
                        dispatch(atualizarConteudoOltModal(e))
                    ]}
                    validate={{
                        action: e => dispatch(validarCampoAction(e)),
                        erro: mensagemErro.indexVlan ? defaultText.mensagem.obrigatorio : ''
                    }}
                />
                <div className='uplinkConfig'>
                    <div className='uplinkCheck'>
                        <p>
                            {defaultText.mensagem.configuracaoUplink}
                            <span>{olt.indexVlan ? olt.indexVlan : "1000"}</span>
                        </p>
                        <Checkbox
                            name="uplinkAutoConfig"
                            text="Uplink"
                            checked={olt.uplinkAutoConfig}
                            value={olt.uplinkAutoConfig}
                            changeInput={(e) => dispatch(atualizarConteudoOltModal(e))}
                        />
                    </div>
                    <p>
                        <span>{defaultText.informacoes.atencao}</span>
                        {defaultText.mensagem.attentionUplinkConfig}
                    </p>
                </div>
                <hr />
                <div className="alert-actions">
                    <Checkbox
                        changeInput={() => dispatch(handleAcceptedModeChangeTermsState())}
                        checked={acceptedModeChangeTerms}
                        value={acceptedModeChangeTerms}
                        name="aceiteDeTermos"
                        text={confirmText}
                    />
                </div>
            </>
        )
    }

    return (
        <>
            {(() => {
                if (operationMode === "VLANPORPON" && olt.progressoModoDeOperacao != null && (olt.progressoModoDeOperacao[0] === "CHECKED_VLAN" || olt.progressoModoDeOperacao[0] === "ERRO_VLAN")) {
                    return <ProgressVlanPorPon />
                }else if(operationMode === "AUTOSERVICE" && olt.progressoModoDeOperacao != null && !(olt.progressoModoDeOperacao[0] === "CHECKED_VLAN" || olt.progressoModoDeOperacao[0] === "ERRO_VLAN")){
                    return <ProgressAutoService />
                }else if (operationMode === "AUTOSERVICE") {
                    return AutoService()
                } else if (operationMode === "MANUALLY") {
                    return <Manually />;
                } else if(operationMode === "AUTOPROV") {
                    return <AutoProvisionamento />
                }else {
                    return VlanPorPon()
                }
            })()}
        </>
    );
};

export default OperationModeChangeMessage;