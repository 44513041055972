import React from 'react';
import "./SigCloudLoader.css";

const SigCloudLoader = ({ visible }) => {
    return (
        <div className={`loader-container ${visible ? "show" : "hide"}`}>
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default SigCloudLoader;